import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomConfirmationPopup from '../../components/common/CustomConfirmationPopup/index.js';
import { ignWorkbenchDataApi } from '../../services/ApiService.js';
import { POST } from '../../services/constantService.js';
import AddQueryModal from './Components/AddQueryModal/index.js';
import AddContactFromExisting from './Components/AddToWorkbench/AddContactFromExisting.js';
import AddProjectFromExisting from './Components/AddToWorkbench/AddProjectFromExisting.js';
import AddWorkBenchModal from './Components/AddWorkBenchModal';
import ListComponent from './Components/ListComponent/ListComponent.jsx';
import WorkbenchComponent from './Components/WorkbenchComponent';
import { WorkBenchContext } from './Context';
import './index.scss';

export default function WorkbenchContainer() {
  const [addWorkBenchModalOpen, setAddWorkBenchModalOpen] = useState(false);
  const [addQueryModalOpen, setAddQueryModalOpen] = useState(false);
  const [deleteWorkBenchModalOpen, setDeleteWorkBenchModalOpen] = useState(false);

  const [addContactFromExistingModal, setAddContactFromExistingModal] = useState(false);
  const [quickAddModal, setQuickAddModal] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [areYouSureData, setAreYouSureData] = useState(null);

  const [addProjectFromExistingModal, setAddProjectFromExistingModal] = useState(false);

  const [noMyList, setNoMyList] = useState(false);

  const types = {
    PROJECT: 'project',
    CONTACT: 'contact',
    COMPANY: 'company',
    CANDIDATE: 'candidate'
  };
  const [workbenchId, setWorkbenchId] = useState();
  const [queryId, setQueryId] = useState();
  const [archiveId, setArchiveId] = useState();
  const [refetch, setRefetch] = useState(false);
  const refetchFunc = () => {
    setRefetch(!refetch);
  };

  const { t } = useTranslation();

  const addRecord = async (contact_id, project_id) => {
    try {
      const payload = {
        contact_id,
        project_id,
        workbench_id: workbenchId
      };
      await ignWorkbenchDataApi(POST, '', payload, 'add_record');
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const handleAreYouSureOpen = data => {
    setAreYouSureData(data);
    setAreYouSure(true);
  };

  const handleAreYouSureDone = () => {
    if (areYouSureData) {
      areYouSureData.func(areYouSureData.data);
    }
    setAreYouSure(false);
  };

  const handleAreYouSureCancel = () => {
    setAreYouSure(false);
    setAreYouSureData(null);
  };
  return (
    <WorkBenchContext.Provider
      value={{
        addWorkBenchModalOpen,
        setAddWorkBenchModalOpen,
        addQueryModalOpen,
        setAddQueryModalOpen,
        deleteWorkBenchModalOpen,
        setDeleteWorkBenchModalOpen,
        workbenchId,
        setWorkbenchId,
        queryId,
        setQueryId,
        archiveId,
        setArchiveId,
        refetch,
        refetchFunc,
        quickAddModal,
        setQuickAddModal,
        addRecord,
        addContactFromExistingModal,
        setAddContactFromExistingModal,
        addProjectFromExistingModal,
        setAddProjectFromExistingModal,
        noMyList,
        setNoMyList,
        types,
        handleAreYouSureOpen
      }}
    >
      <div className='w-100 workbench-container mt-4 pr-4'>
        <Grid container spacing={2}>
          <Grid item xs={2.5}>
            <ListComponent />
          </Grid>
          <Grid item xs={9.5}>
            <WorkbenchComponent />
          </Grid>
        </Grid>
        <AddWorkBenchModal />
        <AddQueryModal />
        {/* <DeleteWorkBenchModal /> */}
        <AddContactFromExisting />
        <AddProjectFromExisting />
        <CustomConfirmationPopup
          open={areYouSure}
          onClose={handleAreYouSureCancel}
          type={`${t('utils.deletion')}`}
          cancelText={`${t('actions.cancel')}`}
          confirmText={`${t('actions.ok')}`}
          onConfirm={handleAreYouSureDone}
          customMessage={`${t('utils.removeFromWorkbenchList')}`}
          setOpen={setAreYouSure}
        />
        {/* <CustomModalWithHeader style={{ minHeight: '20vh', minWidth: '20vw !important' }} label={''} isOpen={areYouSure} onClose={handleAreYouSureCancel}>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='fs-18'>Are You Sure?</div>
            <div className='d-flex mt-2'>
              <CustomButton buttonText={'Yes'} onClick={handleAreYouSureDone} />
              <CustomButton buttonText={'No'} type='tertiary ml-3' onClick={handleAreYouSureCancel} />
            </div>
          </div>
        </CustomModalWithHeader> */}
      </div>
    </WorkBenchContext.Provider>
  );
}
