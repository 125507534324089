import { Button, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { createIgnState, getMethodWithCancelTokenApi } from '../../services/ApiService';
import { IGN_API } from '../../services/constantService';
import CustomMuiDropdown from '../common/CustomMuiDropdown';

const StateTypeSelection = props => {
  // forked from LevelOfEducationTypeSelection
  const { onChange = () => {}, label = '', value, size = 'small', callback = () => {}, countryName, countryId, ...res } = props;

  const [searchValue, setSearchValue] = React.useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);

  const createState = async (name, countryId) => {
    if (!name || !countryId) return;
    const response = await createIgnState({ name, country_id: countryId });
    return response;
  };
  const onAddEducationFunction = async value => {
    setIsLoaded(true);
    if (value === '' || value === null || options.find(option => option.name === value)) {
      setIsLoaded(false);
      enqueueSnackbar(`'${value}' state already exists`, { variant: 'error' });
      return;
    }
    const data = await createState(value, countryId);
    await callback();
    if (data?.id && data?.name) {
      enqueueSnackbar('State created successfully', { variant: 'success' });
    }
    onChange(null, {
      id: data?.id,
      name: data?.name
    });
    setIsLoaded(false);
  };
  const url = `${IGN_API.picklists}/countries/states?countryName=${countryName}&name=${searchValue}`;
  const { data: options, isLoading } = useQuery({
    queryKey: ['states', searchValue],
    queryFn: () =>
      getMethodWithCancelTokenApi(
        url,
        {
          limit: 50
        },
        {}
      ).then(res => {
        return res?.data || [];
      }),
    enabled: !!countryName && countryName.length > 0
  });
  const PaperComponent = props => {
    return (
      <Paper {...props}>
        {props.children}
        <Button onMouseDown={() => onAddEducationFunction(searchValue)} fullWidth variant='text'>
          + Add State
        </Button>
      </Paper>
    );
  };
  PaperComponent.propTypes = {
    children: PropTypes.node
  };

  return (
    <CustomMuiDropdown
      isLoading={isLoading || isLoaded}
      label={label}
      options={options} // options in the form of {title: ..., value: ...}
      value={value}
      getOptionLabel={option => option.name} // Display the title field in the dropdown
      onChange={(e, d) => {
        onChange(e, d);
      }}
      name={label}
      autoCompleteProps={{
        disableCloseOnSelect: true,
        fullWidth: true,
        // eslint-disable-next-line no-use-before-define
        PaperComponent: PaperComponent
      }}
      textFieldProps={{
        // fullWidth: true,
        // required: required,
        size: size,
        fullWidth: true,
        value: searchValue,
        onChange: e => {
          setSearchValue(e.target.value);
        }
      }}
      {...res}
    />
  );
};

StateTypeSelection.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  callback: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object,
  countryName: PropTypes.string,
  countryId: PropTypes.number
};

export default StateTypeSelection;

// const createLanguage = async (name, code) => {
//     const reqBody = {
//       name: name || '',
//       language_code: code || name?.slice(0, 2) || ''
//     };
//     const response = await createIgnLanguage(reqBody);
//     console.log(response);
//     return response;
//   };
//   const createCountry = async name => {
//     if (!name) return;
//     const reqBody = {
//       name: name,
//       iso_2: name?.slice(0, 2) || '',
//       iso_3: name?.slice(0, 3) || ''
//     };
//     const response = await createIgnCountry(reqBody);
//     console.log(response);
//     return response;
//   };
//   const createState = async (name, countryId) => {
//     if (!name || !countryId) return;
//     const response = await createIgnState({ name, country_id: countryId });
//     console.log(response);
//     return response;
//   };
