import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

export const PAGE_LIMIT_OPTIONS = [10, 15, 25, 50];
export const STATUS_COLORS = {
  INITIATED: 'blue',
  PROCESSING: '#ffae14',
  COMPLETED: '#23cea7',
  FAILED: 'red'
};
const defaultColumnOptions = {
  resizable: true,
  flex: 1,
  cellStyle: { display: 'block !important', 'text-overflow': 'ellipsis', 'white-space': 'nowrap !important', overflow: 'hidden' }
};

const defaultTextFilter = {
  filter: 'agTextColumnFilter',
  filterParams: {
    buttons: ['reset', 'apply'],
    closeOnApply: true
  }
};

export const COMMON_TOAST_CONFIG = {
  preventDuplicate: true,
  autoHideDuration: 3000
};

export const showToast = (msg, variant) => {
  enqueueSnackbar(msg, { ...COMMON_TOAST_CONFIG, variant });
};

export const ANALYSIS_TYPE_LIST = ['Consultant Interview', 'Recruiting Call', 'Discussion', 'Discovery Call', 'Reference Call'];

export const getDefaultDecisionOptions = () => {
  return [
    {
      label: 'Summary',
      value: 'summary'
    },
    {
      label: 'Sentiment',
      value: 'sentiment'
    },
    {
      label: 'Key Takeaways',
      value: 'key_takeaways'
    },
    {
      label: 'Next Steps',
      value: 'next_steps'
    }
  ];
};

export const getInterviewDecisionOptions = () => {
  return [
    {
      label: 'Career Story',
      value: 'career_story'
    },
    {
      label: 'Relevance to role',
      value: 'relevance'
    },
    {
      label: 'Interview Summarization',
      value: 'interviewsummary'
    },
    {
      label: 'Gaps/Observations',
      value: 'gaps'
    }
  ];
};

export const MOCK_SPEAKER_DATA = [
  {
    rowIndex: 1,
    originalSpeaker: 'spk_0',
    speaker: 'spk_0',
    candidate: false,
    firstUtterance:
      "The cloud. Ok. It's a pleasure to meet you. Thank you for taking your time on a Sunday. Um, now, how hot is it in Atlanta? Because I got to tell you in Dallas, Texas, it's 100 and five.",
    startTime: '0.009',
    endTime: '14.779'
  },
  {
    rowIndex: 2,
    originalSpeaker: 'spk_1',
    speaker: 'spk_1',
    candidate: false,
    firstUtterance: "It's hot. Not that hot but it's hot.",
    startTime: '15.18',
    endTime: '17.25'
  }
];

const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const cellDate = dayjs(cellValue);
    const filterDate = dayjs(filterLocalDateAtMidnight);
    if (cellDate.isSame(filterDate, 'day')) {
      return 0;
    }
    return cellDate.isBefore(filterDate, 'day') ? -1 : 1;
  },
  buttons: ['reset', 'apply'],
  closeOnApply: true
};

export const EchoSenseTranscribeTableColumnDefinition = [
  {
    field: 'id',
    headerName: 'Task ID',
    minWidth: 200,
    // filter: 'agMultiColumnFilter',
    // headerComponentFramework: CustomHeader,
    visible: true,
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'project.name',
    headerName: 'Job Title',

    minWidth: 200,
    tooltip: params => params.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'friendly_name',
    headerName: 'Friendly Name',
    minWidth: 200,
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    cellRenderer: 'statusRender',
    ...defaultColumnOptions,
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'no_of_speakers',
    headerName: 'Number of speakers',
    minWidth: 200,
    ...defaultColumnOptions,
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'created_at',
    headerName: 'Transcribe Start Time',
    minWidth: 200,
    valueFormatter: p => {
      return dayjs(p?.value).format('lll');
    },
    ...defaultColumnOptions,
    filterParams: dateFilterParams,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'audio_file_s3_key',
    headerName: 'File Name',
    minWidth: 200,
    valueFormatter: p => {
      return p?.value ? p?.value?.split('/')?.pop() : '-';
    },
    tooltipValueGetter: p => (p?.value ? p?.value?.split('/')?.pop() : '-'),
    ...defaultColumnOptions,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'email',
    headerName: 'Requestor',
    minWidth: 200,
    valueFormatter: p => {
      return `${p?.data?.owner?.first_name} ${p?.data?.owner?.last_name}`;
    },
    tooltipValueGetter: p => `${p?.data?.owner?.first_name} ${p?.data?.owner?.last_name}`,
    ...defaultColumnOptions,
    filter: 'agMultiColumnFilter',
    valueGetter: p => {
      return p?.data?.owner?.first_name ? `${p?.data?.owner?.first_name} ${p?.data?.owner?.last_name}` : '-';
    }
  },
  {
    field: 'transcript_completed_at',
    headerName: 'Completed At',
    ...defaultColumnOptions,
    valueFormatter: p => {
      return !p?.value ? '-' : dayjs(p?.value).format('lll');
    },
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    minWidth: 200,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'error_reason',
    headerName: 'Error Reason',
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    minWidth: 200,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'action',
    headerName: 'Action',
    cellRenderer: 'actionCellRender',
    pinned: 'right',
    minWidth: 170,
    maxWidth: 170,
    filter: false,
    suppressHeaderMenuButton: true
  }
];

export const EchoSenseAnalyzeTableColumnDefinition = [
  {
    field: 'id',
    headerName: 'Task ID',
    minWidth: 200,
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'project.name',
    headerName: 'Job Title',
    minWidth: 200,
    tooltip: params => params.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'friendly_name',
    headerName: 'Friendly Name',
    minWidth: 200,
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: 'statusRender',
    minWidth: 150,
    ...defaultColumnOptions,
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'analysis_type',
    headerName: 'Analysis Type',
    minWidth: 200,
    ...defaultColumnOptions,
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'speakers',
    headerName: 'Speakers',
    minWidth: 200,
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'candidate_name',
    headerName: 'Candidate',
    minWidth: 200,
    ...defaultColumnOptions,
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    ...defaultTextFilter,
    filter: 'agMultiColumnFilter'
  },

  {
    field: 'created_at',
    headerName: 'Submitted At',
    minWidth: 200,
    valueFormatter: p => {
      return dayjs(p?.value).format('lll');
    },
    ...defaultColumnOptions,
    filterParams: dateFilterParams,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'report_language',
    headerName: 'Language',
    minWidth: 200,
    ...defaultColumnOptions,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'transcript_file_s3_key',
    headerName: 'Transcript File',
    minWidth: 200,
    valueFormatter: p => {
      return p?.value ? p?.value?.split('/')?.pop() : '-';
    },
    tooltipValueGetter: p => (p?.value ? p?.value?.split('/')?.pop() : '-'),
    ...defaultColumnOptions,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'decision_summary_completed_at',
    headerName: 'Completed At',
    ...defaultColumnOptions,
    valueFormatter: p => {
      return !p?.value ? '-' : dayjs(p?.value).format('lll');
    },
    cellStyle: { display: 'flex !important', 'justify-content': 'center' },
    minWidth: 200,
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams
  },
  {
    field: 'email',
    headerName: 'Requestor',
    minWidth: 200,
    valueFormatter: p => {
      return `${p?.data?.owner?.first_name} ${p?.data?.owner?.last_name}`;
    },
    tooltipValueGetter: p => `${p?.data?.owner?.first_name} ${p?.data?.owner?.last_name}`,
    ...defaultColumnOptions,
    filter: 'agMultiColumnFilter',
    valueGetter: p => {
      return p?.data?.owner?.first_name ? `${p?.data?.owner?.first_name} ${p?.data?.owner?.last_name}` : '-';
    }
  },
  {
    field: 'error_reason',
    headerName: 'Error Reason',
    tooltip: params => params?.value,
    ...defaultColumnOptions,
    minWidth: 200,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'action',
    headerName: 'Action',
    cellRenderer: 'analyzeActionCellRender',
    pinned: 'right',
    minWidth: 150,
    maxWidth: 150,
    filter: false,
    suppressHeaderMenuButton: true
  }
];
