//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, DialogContentText } from '@mui/material';

export default function AlertDialog(props) {
  const { open, handleClose, handleSubmit, title, description, cancelText, submitText } = props;
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {cancelText}
        </Button>
        <Button onClick={handleSubmit} variant='contained' color='primary' autoFocus>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
};
