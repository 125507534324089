import { startTransition, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../services/axios';
import { PUBLIC_ROUTES } from '../../services/constantService';
import { commonActions } from '../../store/commonSlice';
import { getDynamicUrl } from '../common';

// devtenant.non-prod.ignyteai-test.com/login?url=https://devtenant.app.non-prod.ignyteai-test.com/api/v1/health
export const UseUserSecurityHook = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const path = window.location.pathname;

    if (PUBLIC_ROUTES.includes(path.split('/')[1])) {
      setIsLoading(false);
      return;
    }

    const checkUserSecurity = async () => {
      const url = window.location.origin;
      const subdomain = url.split('.')[0];
      const restOfDomain = url.substring(url.indexOf('.') + 1);
      const dynamicUrl = getDynamicUrl(subdomain, restOfDomain);

      try {
        setIsLoading(true);
        const res = await axios.get(dynamicUrl, { withCredentials: true });

        if ((res?.status === 200 || res?.status === 404) && res.data.alive === true) {
          startTransition(() => {
            dispatch(
              commonActions.userSecurityData({
                appSecurity: res.data.appSecurity,
                isSecurityEnabled: res.data.isSecurityEnabled
              })
            );
          });
        } else if (!dynamicUrl.includes('localhost') && !process.env.REACT_APP_IS_LOCAL) {
          window.location.href = `${subdomain}.app.${restOfDomain}/login?url=${path}`;
        }
      } catch (error) {
        console.error('Error in UseUserSecurityHook:', error);
        if (!dynamicUrl.includes('localhost') && !process.env.REACT_APP_IS_LOCAL) {
          window.location.href = `${subdomain}.app.${restOfDomain}/login?url=${path}`;
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkUserSecurity();
  }, [dispatch]);

  if (isLoading) {
    return null; // or return a loading spinner if needed
  }

  return null;
};
