import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import propTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../services/constantService';
function NameComponent(props) {
  return (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start' width={'100%'}>
      <AccountCircleIcon />
      {props.value}
    </Stack>
  );
}
function ProjectName(props) {
  const { value } = props;
  const nav = useNavigate();
  return (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' onClick={() => nav(`${ROUTES.projectDetails}/${value?.id}`)} textDecoration='underline'>
      {value?.job_number}
    </Stack>
  );
}

function AttendeesComponent(props) {
  const { value } = props;
  return (
    <div style={{ display: 'flex' }}>{value ? value?.map((item, index) => <NameComponent value={item?.contact_user?.name || 'N/A'} key={index} />) : <NameComponent value={'N/A'} key={0} />}</div>
  );
}
function TimeComponent(props) {
  const { value } = props;
  const getTime = date => {
    return value ? dayjs(date).format('LT') : 'N/A';
  };
  return <div>{getTime(value)}</div>;
}
function DateComponent(props) {
  const { value } = props;
  const getDate = date => {
    return value ? new Date(date).toLocaleDateString() : 'N/A';
  };
  return <div>{getDate(value)}</div>;
}
const tableProps = {
  value: propTypes.object,
  dateType: propTypes.string
};

NameComponent.propTypes = tableProps;
AttendeesComponent.propTypes = tableProps;
TimeComponent.propTypes = tableProps;
DateComponent.propTypes = tableProps;
ProjectName.propTypes = tableProps;
export { AttendeesComponent, DateComponent, NameComponent, ProjectName, TimeComponent };
