import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import DrawerComponent from '../../../components/common/Drawer';
import Footer from '../../../components/common/Footer';
import TagForm from './TagForm';
import { capitalizeFirstLetter } from './utils';

const ViewTagDrawer = props => {
  const { isDrawerOpen, setIsDrawerOpen, onSubmit, setValue, register, unregister, watch, data, handledeleteTag, handleChangeStatus, isEdited, setIsEdited } = props;

  const { t } = useTranslation();

  const tagStatus = data?.status ? 'Deactivate' : 'Activate';
  function getAllModuleNames(data) {
    if (!data || !data.tag_module || !Array.isArray(data.tag_module) || data?.tag_module?.length === 0) {
      return '';
    }

    const moduleNames = data?.tag_module.map(module => capitalizeFirstLetter(module.module_name));
    return moduleNames.join(', ');
  }
  const statusChange = () => {
    handleChangeStatus(data, tagStatus);
  };
  const deleteTag = () => {
    handledeleteTag(data, data.id, 'Delete');
  };
  return (
    <DrawerComponent
      id={'view-tag-drawer'}
      anchor={'right'}
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      width='42vw'
      drawerCloseIconTop='0px'
      top={52}
    >
      <Box className='d-flex flex-column tag-section p-8'>
        <Box className='header-section d-flex flex-column gap-4'>
          <Box className='d-flex p-2 align-items-center'>
            <Box className='fs-18 tag-text'>{t('common.tagsSetting')}</Box>
            {!isEdited && (
              <Box className=''>
                <CustomButton variant='contained' type='primary p-1 m-1' onClick={() => setIsEdited(true)} customWidth={0} buttonText={<EditIcon fontSize='small' />} />
              </Box>
            )}
          </Box>
        </Box>
        {isEdited === false ? (
          <Box className='body-section px-8 pb-8'>
            <Box className='view-body-section d-flex flex-column'>
              <Box className='d-flex justify-content-end align-items-center'>
                <Box>
                  {' '}
                  <CustomButton type={'tertiary-error'} size={'small'} buttonText={t('actions.delete')} customWidth={90} onClick={() => deleteTag()} variant={'text'}></CustomButton>
                </Box>
                <Box className='tag-status' onClick={() => statusChange()}>
                  {tagStatus}
                </Box>
              </Box>
              <Box>
                <Box className='pb-1 pt-1'>{t('utils.tagName')}</Box>
                <Box className='fs-18 tag-text'>{data?.name || ''}</Box>
              </Box>

              <Box>
                <Box className='pb-1'>{t('utils.colourLabel')}</Box>
                <Box className='color-label' sx={{ backgroundColor: data?.color }} />
              </Box>

              <Box>
                <Box className='pb-1'>{t('utils.status')}</Box>
                <Box className='fs-18 tag-text'>{data?.status ? 'Active' : 'Inactive'}</Box>
              </Box>
              <Box>
                <Box className='pb-1'>{t('utils.module')}</Box>
                <Box className='fs-18 tag-text'>{getAllModuleNames(data)}</Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className='body-section px-8 pb-8'>
            <Box className='view-body-section d-flex flex-column'>
              <TagForm watch={watch} unregister={unregister} register={register} setValue={setValue} data={data} isEdit={isEdited}></TagForm>
              <Box>
                <Footer
                  onSubmit={onSubmit}
                  data={data}
                  onClose={() => {
                    setIsEdited(false);
                  }}
                ></Footer>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </DrawerComponent>
  );
};
ViewTagDrawer.propTypes = {
  data: PropTypes.object,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  saveData: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  onSubmit: PropTypes.func,
  handledeleteTag: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  isEdited: PropTypes.bool,
  setIsEdited: PropTypes.func
};

export default ViewTagDrawer;
