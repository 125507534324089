import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Box, Button, Checkbox, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import { enqueueSnackbar } from 'notistack';
function AddContactDrawerSubHeader(props) {
  const {
    uploadResumeTitle,
    linkedinURLTitle,
    getProfileData,
    expandAll,
    collapseAll,
    onExpandAll,
    onCollapseAll,
    onGetProfileData,
    allExpanded,
    allCollapsed,
    onOpenCreateContactFromResume,
    duplicateContact,
    setDuplicateContact,
    linkedinURL,
    setLinkedinURL
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  return (
    <Stack direction={'column'} width={'100%'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        sx={{
          padding: '16px',
          bgcolor: 'background.white',
          borderBottom: '1px solid',
          borderColor: 'background.main'
        }}
      >
        <Stack direction={'row'} alignItems={'center'} width={'100%'}>
          <Button variant='outlined' onClick={onOpenCreateContactFromResume}>
            {uploadResumeTitle}
          </Button>
          <Box
            sx={{
              margin: '0 14px'
            }}
            className='fs-14'
          >
            /
          </Box>
          <Box>
            <TextField
              value={linkedinURL}
              onChange={e => setLinkedinURL(e.target.value)}
              InputProps={{
                startAdornment: (
                  <LinkedInIcon
                    sx={{
                      color: '#0077b5'
                    }}
                  />
                ),
                sx: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                }
              }}
              sx={{
                width: '300px'
              }}
              placeholder={linkedinURLTitle}
              variant='outlined'
              size='small'
            />
            <Button
              variant='contained'
              color='primary'
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              endIcon={isLoading ? <CircularProgress size={16} sx={{ color: 'white' }} /> : null}
              onClick={async () => {
                try {
                  setIsLoading(true);
                  await onGetProfileData(linkedinURL);
                  setIsLoading(false);
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              {getProfileData}
            </Button>
          </Box>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} minWidth={'260px'}>
          <Stack direction={'row'} alignItems={'center'} onClick={() => onExpandAll()}>
            <Checkbox checked={allExpanded} onClick={() => onExpandAll()} />
            <Typography className='fs-14'>{expandAll}</Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} onClick={() => onCollapseAll()}>
            <Checkbox checked={allCollapsed} onClick={() => onCollapseAll()} />
            <Typography className='fs-14'>{collapseAll}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {duplicateContact?.isDuplicate && (
        <Stack direction={'row'} justifyContent={'space-between'} padding={'8px'} px={'16px'} width={'100%'} bgcolor={'background.white'}>
          <Stack direction={'row'} alignItems={'center'} width={'100%'} bgcolor={'#fffbde'} borderRadius={'4px'} px={'8px'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} width={'100%'}>
              <WarningOutlinedIcon sx={{ color: '#615c39' }} fontSize='small' />
              <Typography className='fs-12' pl={'4px'}>
                {duplicateContact?.string || 'Duplicate Contact Found'} :{' '}
              </Typography>
              {duplicateContact?.data?.map((contact, index) => (
                <Typography
                  className='fs-12'
                  pl={'4px'}
                  key={index}
                  textTransform={'capitalize'}
                  sx={{ color: '#c15193', textDecoration: 'underline', cursor: 'pointer', fontWeight: '600' }}
                  onClick={() => navigate(ROUTES.contactListWithoutCurrentTab + '/' + contact.id)}
                >
                  {contact.first_name} {contact.last_name} {index < duplicateContact?.data?.length - 1 ? ',' : ''}
                </Typography>
              ))}
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Button variant='text' onClick={() => setDuplicateContact()}>
                <CloseOutlinedIcon fontSize='small' />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default AddContactDrawerSubHeader;

AddContactDrawerSubHeader.propTypes = {
  uploadResumeTitle: PropTypes.string,
  linkedinURLTitle: PropTypes.string,
  getProfileData: PropTypes.string,
  expandAll: PropTypes.string,
  collapseAll: PropTypes.string,
  onExpandAll: PropTypes.func,
  onCollapseAll: PropTypes.func,
  onGetProfileData: PropTypes.func,
  allExpanded: PropTypes.bool,
  allCollapsed: PropTypes.bool,
  onOpenCreateContactFromResume: PropTypes.func,
  duplicateContact: PropTypes.object,
  setDuplicateContact: PropTypes.func,
  linkedinURL: PropTypes.string,
  setLinkedinURL: PropTypes.func
};
