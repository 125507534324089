import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import CustomDropdown from '../../../components/common/CustomDropdown';
import RichText from '../../../components/common/RichText';
import CustomModalWithHeader from '../../Commons/CustomModalWithHeader';
const modalStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '8px',
  width: '70vw'
};
function ReviewAndPublishModal(props) {
  const { dropDownOptions = [], isOpen, description, onCancel, onPublish, handleHideOptions = () => {}, getUniversalValue = () => {} } = props;
  const { t } = useTranslation();
  const [richText, setRichtext] = useState('');

  useEffect(() => {
    setRichtext(description);
  }, [description]);

  return (
    //   Todo Translate
    <CustomModalWithHeader
      isOpen={isOpen}
      onClose={onCancel}
      label='Review Position Profile'
      style={{
        ...modalStyle,
        minWidth: '80vw',
        height: 'auto'
      }}
    >
      <Stack direction={'column'} component={'div'}>
        <CustomDropdown
          options={dropDownOptions ?? []}
          multiple={true}
          disableCloseOnSelect={true}
          isCheckBox={true}
          onChange={(event, newValue) => {
            handleHideOptions(newValue);
          }}
          label={t('utils.hideInJobPosting')}
          value={getUniversalValue('hideOption') && getUniversalValue('hideOption').length ? getUniversalValue('hideOption') : []}
          className={'mt-1 mb-1 w-50'}
        />

        <RichText defaultValue={richText} onChange={setRichtext} style={{ height: '40vh', maxHeight: '40vh', marginBottom: '6vh' }} />
        <Stack direction='row' justifyContent={'flex-end'}>
          <CustomButton buttonText={'Cancel'} variant={'text'} type='btn btn-outlined-secondary' onClick={onCancel} />
          <CustomButton buttonText={'Publish'} variant={'text'} onClick={() => onPublish(richText)} />
        </Stack>
      </Stack>
    </CustomModalWithHeader>
  );
}

ReviewAndPublishModal.propTypes = {
  description: PropTypes.string,
  onCancel: PropTypes.func,
  onPublish: PropTypes.func,
  isOpen: PropTypes.func,
  dropDownOptions: PropTypes.array,
  handleHideOptions: PropTypes.func,
  getUniversalValue: PropTypes.func
};

export default ReviewAndPublishModal;
