import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import AddButton from "../../../AddButton";
import security_icon from '../../../../assets/icons/security_icon.svg';
import AddDocuments from './AddDocuments';
// import CardSlider from '../../../common/CardsSlider';
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import DocumentCard from '../../../../../src/components/common/FunctionalComponents/DocumentCard';
import { ERROR, IGN_API, PATCH, POST, SUCCESS } from '../../../../services/constantService';
import CustomButton from '../../../common/CustomButton';
import FluidLayoutComponent from '../../../common/FluidLayoutComponent';

import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AllDocumentsApi } from '../../../../services/ApiService';
import * as AttachmentsService from '../../../../services/AttachmentsService';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { splitLastIfMultiple } from '../../../../utils/common';
import Loader from '../../../common/Loader';

const Documents = props => {
  const { register = () => {}, unregister = () => {}, setValue = () => {}, currentValues, getContact = () => {}, contactId } = props;

  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translateMessage } = useCustomMessageHook();

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setIsEdit(false);
  };
  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };

  const getData = async () => {
    if (contactId) {
      try {
        setLoading(true);
        await getContact(contactId, 'DOCUMENTS_TAB');
        setLoading(false);
      } catch (error) {
        console.log('Error', error);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   const convertData = () => {

  //     let newData = [];
  //     if (currentValues) {
  //       setAttachmentData(currentValues);
  //     }
  //     return newData
  //   }
  //   setAttachmentData(convertData())
  // }, [currentValues])

  const download = async index => {
    let attachmentId = currentValues[index].id;
    try {
      const url = await AttachmentsService.documentDownload(attachmentId);
      // const url = await ignContactDataApi(GET, null, null, sub_route)
      if (url) {
        window.open(url);
      } else {
        const message = translateMessage('UnableMessage', false, 'Document', 'download');

        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
    }
  };

  const uploadDocuments = async (document, item) => {
    if (!document) {
      enqueueSnackbar('Attachment data not fill', { variant: ERROR });
      return;
    }

    if (!document.file_type && !document.file_name) {
      const message = translateMessage('Required', ...splitLastIfMultiple(['Type', 'Name']));
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!document.file_type) {
      const message = translateMessage('Required', false, 'Type');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!document.file_name) {
      const message = translateMessage('Required', false, 'Name');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!isEdit) {
      if (!document.file) {
        const message = translateMessage('SelectFile', false, '');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    let payload = {};
    if (isEdit) {
      payload = {
        attachment: {
          id: item.id,
          contact_id: item.contact_id,
          file_type: document.file_type,
          file_name: document.file_name,
          is_confidential: document.is_confidential,
          ign_contact_attachment_visibilities: document.ign_contact_attachment_visibilities
        }
      };
      const sub_route = `${IGN_API.contact}/${contactId}`;
      const { status, data } = await AllDocumentsApi(PATCH, sub_route, payload);
      console.log(status, data);
      if (status === 200) {
        const message = translateMessage('Successfully', false, 'Attachment', 'uploaded');
        enqueueSnackbar(message, { variant: SUCCESS });
        getData();
        setIsPopupOpen(false);
      } else {
        enqueueSnackbar(data?.message || 'Attachment', { variant: ERROR });
      }
    } else {
      let fileNameSplit = document.file?.name?.split('.');
      let bodyFormData = new FormData();
      bodyFormData.append('is_confidential', document.is_confidential);
      bodyFormData.append('file_name', `${document.file_name}.${fileNameSplit[fileNameSplit.length - 1]}`);
      bodyFormData.append('file_type', document.file_type);
      bodyFormData.append('file', document.file);
      bodyFormData.append('ign_contact_attachment_visibilities', JSON.stringify(document.ign_contact_attachment_visibilities));
      // document.ign_contact_attachment_visibilities.forEach(item => {
      //   bodyFormData.append(`ign_contact_attachment_visibilities[]`, JSON.stringify(item));
      // });
      const sub_route = `${IGN_API.add_document}/${contactId}/attachments`;
      const { status, data } = await AllDocumentsApi(POST, sub_route, bodyFormData);
      console.log(status, data);
      processUpload(status, data, document.file.name);
    }
  };

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      //getContact(contactId)
      getData();
      const message = translateMessage('Successfully', false, `${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsPopupOpen(false);
    } else {
      const message = translateMessage('UnableMessage', false, `${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const colSize = currentValues?.length > 9 ? 3 : 4;
  return (
    <>
      <Loader show={!currentValues ? true : false} />
      {currentValues ? (
        <>
          <Loader show={loading} />
          <Box id='document' className='final-page-layout'>
            <Typography className='compensation-info text-label mb-3'>{t('utils.documents')}</Typography>
            <Box className='second-page-layout h-auto custom-scroll'>
              <Box className='card-container d-flex pl-3 pr-3 doc-container'>
                <FluidLayoutComponent>
                  {currentValues?.map((card, cardIndex) => (
                    <>
                      <Col key={cardIndex} xs={12} sm={12} md={6} lg={4} xl={colSize} className='p-3'>
                        <DocumentCard
                          data={card}
                          index={cardIndex}
                          editItems={openPopupEditing}
                          download={download}
                          downloadIcon={<CloudDownloadIcon />}
                          addIcon={card.is_confidential ? security_icon : ''}
                          styleData={{
                            titleSize: 13,
                            textSize: 11,
                            height: 125,
                            width: colSize === 3 ? 400 : 300
                          }}
                          getContact={getData}
                          setLoading={setLoading}
                        ></DocumentCard>
                      </Col>
                    </>
                  ))}
                </FluidLayoutComponent>
              </Box>
              <Box className='add-document ml-3 pl-3 pt-2'>
                <Box className='add-another pl-0'>
                  {/* <AddButton title="Add another" /> */}
                  <CustomButton
                    variant='outlined'
                    type={'secondary'}
                    size={'medium'}
                    buttonText={currentValues?.length === 0 ? t('actions.add') : t('actions.addAnotherActivity')}
                    customWidth={120}
                    iconLeft={<AddIcon sx={{ width: 14 }} />}
                    onClick={handleOpenPopup}
                  />
                </Box>
                {isPopupOpen && (
                  <AddDocuments
                    title={`${isEdit ? `${t('actions.updateDocument')}` : `${t('actions.addDocument')}`} `}
                    setIsPopupOpen={setIsPopupOpen}
                    isPopupOpen={isPopupOpen}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    register={register}
                    unregister={unregister}
                    handleSave={uploadDocuments}
                    setValue={setValue}
                    data={isEdit ? currentValues[indexValue] : null}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};

Documents.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  getContact: PropTypes.func,
  contactId: PropTypes.string,
  currentValues: PropTypes.object
};

export default Documents;
