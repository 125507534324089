import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const MultiSelectCountry = ({ placeholder = 'Select Countries', disabled = false, label = '', onChange, defaultValues = [], ...rest }) => {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadAllCountries = async () => {
      try {
        setIsLoading(true);
        const { status, data } = await picklistDropDownApis(GET, 'countries');
        if (status === 200 && data.data?.length > 0) {
          const formattedOptions = data.data.map(country => ({
            label: country.name,
            name: country.name,
            id: country.id || null
          }));
          setOptions(formattedOptions);

          if (defaultValues?.length > 0) {
            const defaultSelected = formattedOptions.filter(option => defaultValues.some(defaultValue => defaultValue.toLowerCase() === option.name.toLowerCase()));
            setSelectedValues(defaultSelected);
            onChange?.(null, defaultSelected);
          }
        }
      } catch (error) {
        console.error('Error loading countries:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAllCountries();
  }, []);

  const handleChange = (e, value) => {
    let newSelectedValues;

    if (Array.isArray(value)) {
      newSelectedValues = value;
    } else {
      const isExisting = selectedValues.find(item => item.name === value.name);
      if (isExisting) {
        newSelectedValues = selectedValues.filter(item => item.name !== value.name);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
    }

    setSelectedValues(newSelectedValues);
    onChange?.(e, newSelectedValues);
  };

  return (
    <CustomDropdown
      {...rest}
      multiple={true}
      isCheckBox={true}
      options={options}
      value={selectedValues}
      filterOptions={(options, { inputValue }) => {
        return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
      }}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled || isLoading}
      label={label}
    />
  );
};

MultiSelectCountry.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValues: PropTypes.arrayOf(PropTypes.string)
};

export default MultiSelectCountry;
