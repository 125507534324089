import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../../../../components/common/CustomButton';

const ListHeaderComponent = ({ name, isShowing, onClick, showAddList = false, buttonName, onClickAddList = () => {} }) => {
  return (
    <div className='p-2 mt-4 background-color-header d-flex justify-content-between align-items-center rounded' key={name}>
      <div className='fs-14 cursor-pointer' onClick={onClick}>
        {isShowing ? <ChevronRightOutlinedIcon className='fs-14' /> : <KeyboardArrowDownOutlinedIcon className='fs-14' />}
        <span>{name}</span>
      </div>
      {showAddList == true ? <CustomButton variant={'outlined'} type='tertiary custom-color-white' size={'small'} buttonText={buttonName || '+ Add List'} onClick={onClickAddList} /> : ''}
    </div>
  );
};

ListHeaderComponent.propTypes = {
  name: PropTypes.string,
  isShowing: PropTypes.bool,
  onClick: PropTypes.func,
  showAddList: PropTypes.bool,
  onClickAddList: PropTypes.func,
  buttonName: PropTypes.string
};

export default ListHeaderComponent;
