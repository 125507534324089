import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../src/utils/common.scss';
import { ignContactDataApi } from '../../../services/ApiService';
import { DELETE, SUCCESS } from '../../../services/constantService';
import { successMessage } from '../../../services/MessageService';
import { logAnActivityActions } from '../../../store/logAnActivitySlice';
import { ContactList, FilterMenu } from '../../AddContact/Components/Personality';
import BioAndRecommendation from '../../AddContact/Components/Personality/BioAndRecommendation';
import CustomConfirmationPopup from '../../common/CustomConfirmationPopup';

const ViewPersonality = props => {
  const {
    currentValues,
    updateField = () => {},
    isContactView = false,
    register = () => {},
    unregister = () => {},
    setValue = () => {},
    getContactDetails = () => {},
    isHeaderNav,
    setPersonalityField,
    setIsLogAnActivityOpen,
    project_id
  } = props;
  const activityType = useSelector(state => state.commonReducer.activityType);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterByProject, setFilterByProject] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [filterContactList, setFilterContactList] = useState([]);
  const [projectFilterOptions, setProjectFilterOptions] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const { t } = useTranslation();
  const [isPublished, setIsPublished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [filterOptions, setFilterOptions] = useState({
    professional_notes: { label: t('utils.professionalNotes'), value: false },
    personal_notes: { label: t('utils.personalNotes'), value: false },
    strength: { label: t('utils.strength'), value: false },
    concerns: { label: t('utils.concern'), value: false },
    screening_notes: { label: t('actions.screeningNotes'), value: false },
    recommendations: { label: t('utils.recommendation'), value: false }
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const PersonalityTabCreateOptions = [
    {
      label: t('utils.professionalNotes'),
      value: 'professional_notes'
    },
    {
      label: t('utils.personalNotes'),
      value: 'personal_notes'
    },
    {
      label: t('utils.strength'),
      value: 'strength'
    },
    {
      label: t('utils.concern'),
      value: 'concerns'
    },
    {
      label: t('actions.screeningNotes'),
      value: 'screening_notes'
    },
    {
      label: t('utils.recommendation'),
      value: 'recommendations'
    }
  ];
  const groupByOptions = [
    {
      label: t('module.project'),
      value: 'project'
    },
    {
      label: t('utils.notesType'),
      value: 'notes_type'
    }
  ];
  const [groupBy, setGroupBy] = useState(groupByOptions[0]);

  const handlePersonalityField = type => {
    const noteActivityType = activityType?.find(item => item.activity_type_code === 'NOTE');
    if (noteActivityType.activity_type_code === 'NOTE') {
      setValue('activity_type', { ...noteActivityType, notes_type: type, isEditable: false });
      setPersonalityField(true);
      setIsLogAnActivityOpen(true);
    }
  };

  const onEditHandler = (e, item) => {
    e.stopPropagation();
    const noteActivityType = activityType?.find(item => item.activity_type_code === 'NOTE');
    if (noteActivityType.activity_type_code === 'NOTE') {
      setValue('activity_type', { ...noteActivityType, notes_type: item?.notes_type, isEditable: true });
      setPersonalityField(true);
      setIsLogAnActivityOpen(true);
      dispatch(logAnActivityActions.updateEditId(item?.id));
    }
  };
  const onPublishHandler = async (item, isCheckApproved) => {
    setIsLoading(true);
    let contactNotesPayload = {
      notes_type: item?.notes_type,
      notes: item?.notes,
      is_partner_approved: isCheckApproved,
      id: item?.id,
      activity_type: 'NOTE'
    };
    if (item?.project_id) {
      contactNotesPayload['project_id'] = item?.project_id;
    }
    setValue('contact_notes_all', [contactNotesPayload]);
    updateField('contact_notes_all', false);
    setIsLoading(false);
  };

  const onConfirm = async () => {
    try {
      let contactNotesPayload = {
        notes_type: null,
        notes: selectedItem?.notes,
        project_id: selectedItem?.project_id,
        id: selectedItem?.id,
        contact_id: currentValues?.id
      };
      if (!contactNotesPayload?.project_id) {
        const { status } = await ignContactDataApi(DELETE, null, null, `contact-notes?id=${contactNotesPayload?.id}`);
        if (status === 200) {
          const message = successMessage('Notes', 'Deleted');
          enqueueSnackbar(message, { variant: SUCCESS });
          getContactDetails(contactNotesPayload?.contact_id);
        }
        return;
      }
      setValue('contact_notes_all', [contactNotesPayload]);
      updateField('contact_notes_all', false);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    } finally {
      setIsConfirmPopupOpen(false);
      setSelectedItem(null);
    }
  };

  const handleFilterClick = event => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterModalOpen(true);
  };

  const handleFilterUpdate = newFilterOptions => {
    setFilterOptions(newFilterOptions);
  };
  const handleFilterModalClose = () => {
    setIsFilterModalOpen(false);
    setFilterAnchorEl(null);
  };

  const filterByProjectCallback = filterValues => {
    const filterByProjectIds = filterValues.map(item => item.value);

    const filteredProjects = projectFilterOptions.filter(project => filterByProjectIds.includes(project.value));
    if (filteredProjects.length > 0) {
      setProjectOptions(filteredProjects);
    } else {
      setProjectOptions(projectFilterOptions);
      setFilterContactList(projectFilterOptions);
    }
  };

  useEffect(() => {
    if (currentValues?.contact_notes_all?.length) {
      const data = [];
      const filterNotesType = currentValues.contact_notes_all.filter(
        item =>
          item.notes_type &&
          (item.notes_type === 'professional_notes' ||
            item.notes_type === 'personal_notes' ||
            item.notes_type === 'strength' ||
            item.notes_type === 'concerns' ||
            item.notes_type === 'screening_notes' ||
            item.notes_type === 'recommendations')
      );
      setFilterContactList(filterNotesType);
      if (!filterNotesType.length) {
        setProjectOptions(data);
        setProjectFilterOptions(data);
        return;
      }
      filterNotesType.forEach(item => {
        const project = data.find(p => p.project_id === item.project_id);
        if (project) {
          project.contact_notes_all.push(item);
        } else {
          data.push({
            project_id: item.project_id,
            projectName: item.project?.name,
            label: item.project?.name,
            value: item.project_id,
            name: item.project?.name,
            contact_notes_all: [item]
          });
        }
      });

      setProjectOptions(data);
      setProjectFilterOptions(data);
    }
  }, [currentValues]);

  return (
    <div id='view-contact' className={'custom-scrollbar'}>
      <Box className={`${isHeaderNav === 'closed' ? 'details-container-close' : 'details-container'} scroll-content`}>
        <Box className={`scroll-content ${isHeaderNav === 'closed' ? 'details-container-subtab-close' : 'details-container-subtab'}`}>
          <Box className='m-2 my-1'>
            <Box className='d-flex flex-column row-gap'>
              <BioAndRecommendation
                currentValues={currentValues.contact_notes_all}
                isContactView={isContactView}
                handleSave={updateField}
                setValue={setValue}
                register={register}
                unregister={unregister}
                getContactDetails={getContactDetails}
                contact_id={currentValues?.id}
                setPersonalityField={setPersonalityField}
                onPublishHandler={onPublishHandler}
                setSelectedItem={setSelectedItem}
                setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                isLoading={isLoading}
                project_id={project_id}
              />
            </Box>
          </Box>
          <FilterMenu
            filterAnchorEl={filterAnchorEl}
            filterByProject={filterByProject}
            filterByProjectCallback={filterByProjectCallback}
            filterOptions={filterOptions}
            groupBy={groupBy}
            groupByOptions={groupByOptions}
            handleFilterClick={handleFilterClick}
            handleFilterModalClose={handleFilterModalClose}
            handleFilterUpdate={handleFilterUpdate}
            handlePersonalityField={handlePersonalityField}
            isFilterModalOpen={isFilterModalOpen}
            isPublished={isPublished}
            PersonalityTabCreateOptions={PersonalityTabCreateOptions}
            projectFilterOptions={projectFilterOptions}
            setFilterByProject={setFilterByProject}
            setGroupBy={setGroupBy}
            setIsFilterApplied={setIsFilterApplied}
            setIsPublished={setIsPublished}
            setFilterOptions={setFilterOptions}
            isFilterApplied={isFilterApplied}
          />
          <ContactList
            projectOptions={projectOptions}
            filterContactList={filterContactList}
            groupBy={groupBy}
            filterOptions={filterOptions}
            props={props}
            filterByProject={filterByProject}
            handlePersonalityField={handlePersonalityField}
            setSelectedItem={setSelectedItem}
            setIsConfirmPopupOpen={setIsConfirmPopupOpen}
            onPublishHandler={onPublishHandler}
            PersonalityTabCreateOptions={PersonalityTabCreateOptions}
            isPublished={isPublished}
            isFilterApplied={isFilterApplied}
            currentValues={currentValues}
            onEditHandler={onEditHandler}
          />
          <CustomConfirmationPopup
            open={isConfirmPopupOpen}
            onClose={() => {
              setIsConfirmPopupOpen(false);
            }}
            type='delete'
            cancelText='No'
            confirmText='Yes'
            onConfirm={onConfirm}
            setOpen={setIsConfirmPopupOpen}
          />
        </Box>
      </Box>
    </div>
  );
};

ViewPersonality.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  updateField: PropTypes.func,
  currentValues: PropTypes.object,
  reset: PropTypes.func,
  isHeaderNav: PropTypes.string,
  isContactView: PropTypes.bool,
  getContactDetails: PropTypes.func,
  popupClose: PropTypes.bool,
  watch: PropTypes.func,
  setPersonalityField: PropTypes.func,
  setIsLogAnActivityOpen: PropTypes.func,
  project_id: PropTypes.number
};

export default ViewPersonality;
