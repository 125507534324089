import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../../components/common/CustomButton';
import MultipleUserSelection from '../../../../../../components/common/FunctionalComponents/AddedBy/MultipleUserSelection';
import MultiSelectCompanySelectionDebounce from '../../../../../../components/common/FunctionalComponents/CompanySelection/MultiSelectCompanyDebounce';
import MultiSelectJobTitle from '../../../../../../components/common/FunctionalComponents/ContactJobTtitleSelection/MultipleJobTitleSelection';
import MultiSelectContactStatus from '../../../../../../components/common/FunctionalComponents/ContactStatus/MultiSelectContactStatus';
import MultiSelectCountry from '../../../../../../components/common/FunctionalComponents/CountrySelection/MultiSelectCountry';
import CustomDateRangePicker from '../../../../../../components/common/FunctionalComponents/CustomDateRangePicker';
import IndustrySelection from '../../../../../../components/common/FunctionalComponents/IndustrySelection';
import JobFunctionSelection from '../../../../../../components/common/FunctionalComponents/JobFunctionSelection';
import RecordTypeSelection from '../../../../../../components/common/FunctionalComponents/RecordType/RecordType';
import ContactMultiStateSelection from '../../../../../../components/common/FunctionalComponents/StateSelection/ContactMultiSelectStates';
import CustomTagSelection from '../../../../../../components/common/FunctionalComponents/TagSelection/CustomTagSelection';
import CustomInputField from '../../../../../../components/common/StyledComponents/CustomInputField';
import { ignWorkbenchQueryApi } from '../../../../../../services/ApiService';
import { PUT } from '../../../../../../services/constantService';
import { getInputLabelPropsBasicDetails } from '../../../../../../utils/common';
import '../../index.scss';

export function ContactFilterModal({ data, setFilterOptions, setQueryResponse }) {
  const { t } = useTranslation();

  const [contactName, setContactName] = useState(data?.name || null);
  const [createdBy, setCreatedBy] = useState([]);
  const [currentCompany, setCurrentCompany] = useState([]);
  const [previousCompany, setPreviousCompany] = useState([]);
  const [currentJobTitle, setCurrentJobTitle] = useState([]);
  const [previousJobTitle, setPreviousJobTitle] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [jobFunctions, setJobFunctions] = useState([]);
  const [industries, setIndustries] = useState(data?.industry || []);
  const [contactRecordType, setContactRecordType] = useState([]);
  const [range, setRange] = useState([]);
  const [status, setStatus] = useState(data?.status || []);
  const [lastActivityAt, setLastActivityAt] = useState({
    startDate: null,
    endDate: null
  });
  const [tags, setTags] = useState([]);

  const handleCreatedByChange = data => {
    const recordData = data?.map(elem => {
      return elem?.id;
    });
    setCreatedBy(recordData);
  };

  const handleCompanyChange = (stateFunc, data) => {
    stateFunc(data);
  };

  const handleTagChange = val => {
    setTags(val);
  };

  const handleJobFunctionChange = value => {
    const jobFunction = value?.map(item => {
      return item?.id;
    });
    setJobFunctions(jobFunction);
  };

  const handleIndustryChange = value => {
    setIndustries(value);
  };

  const handleRecordTypeChange = data => {
    const recordData = data?.map(elem => {
      return elem?.field_value;
    });
    setContactRecordType(recordData);
  };

  const handleStatusChange = data => {
    const statusData = data?.map(elem => {
      return elem?.field_value;
    });
    setStatus(statusData);
  };

  const handleJobTitleChange = (stateFunc, selected) => {
    stateFunc(selected);
  };

  const handleDateChange = value => {
    setRange(value);
  };

  useEffect(() => {
    setLastActivityAt({
      startDate: range?.[0] ? dayjs.utc(range[0]) : null,
      endDate: range?.[1] ? dayjs.utc(range[1]) : null
    });
  }, [range]);

  const handleSaveAndSearch = async () => {
    const filterOptionPayload = {
      name: contactName,
      contactCreatedBy: createdBy,
      currentCompanyName: currentCompany?.map(item => item?.name),
      previousCompanyName: previousCompany?.map(item => item?.name),
      country: country?.map(item => item?.name),
      state: state?.map(item => item?.label),
      city,
      jobFunction: jobFunctions,
      industry: industries?.map(item => item.id),
      type: contactRecordType,
      lastActivity: lastActivityAt,
      status,
      currentJobTitle: currentJobTitle?.map(item => item?.name),
      previousJobTitle: previousJobTitle?.map(item => item?.name),
      tags: tags?.map(item => item?.name)
    };
    setFilterOptions(filterOptionPayload);
    const updateResponse = await ignWorkbenchQueryApi(PUT, filterOptionPayload, `${data?.id}`);
    setQueryResponse(updateResponse?.data);
  };

  return (
    <Accordion className='query-accordion-container'>
      <AccordionSummary className='query-accordion-summary' expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body2'>{t('utils.startQuery')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={3}>
            <CustomInputField label={'Name'} defaultValue={data?.name} color={null} onChange={event => setContactName(event.target.value)} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <MultipleUserSelection
              label={'Created By'}
              onChange={data => {
                handleCreatedByChange(data);
              }}
              defaultValue={data?.contactCreatedBy || []}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectCompanySelectionDebounce
              label='Current Company Name'
              defaultValues={data?.currentCompanyName}
              onChange={(e, data) => {
                handleCompanyChange(setCurrentCompany, data);
              }}
              className='w-100'
              size='small'
              disableCloseOnSelect={true}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectCompanySelectionDebounce
              label='Previous Company Name'
              defaultValues={data?.previousCompanyName}
              onChange={(e, data) => {
                handleCompanyChange(setPreviousCompany, data);
              }}
              className='w-100'
              size='small'
              disableCloseOnSelect={true}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectJobTitle
              label={'Current Job Title'}
              multiple={true}
              isCheckBox={true}
              onChange={selected => handleJobTitleChange(setCurrentJobTitle, selected)}
              defaultValues={data?.currentJobTitle}
              disableCloseOnSelect
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectJobTitle
              label={'Previous Job Title'}
              multiple={true}
              isCheckBox={true}
              onChange={selected => handleJobTitleChange(setPreviousJobTitle, selected)}
              defaultValues={data?.previousJobTitle}
              disableCloseOnSelect
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectCountry
              defaultValues={data?.country}
              label={'Country'}
              isMultiSelect={true}
              onChange={(e, data) => {
                setCountry(data);
              }}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <ContactMultiStateSelection
              multiple={true}
              label={'State'}
              isCheckBox={true}
              countries={country?.map(item => item?.name)}
              defaultValues={data?.state}
              onChange={(e, data) => {
                setState(data);
              }}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInputField defaultValue={data?.city} label={'City'} color={null} onChange={event => setCity(event.target.value)} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <JobFunctionSelection
              multiple={true}
              onChange={value => {
                handleJobFunctionChange(value);
              }}
              defaultValue={
                data?.jobFunction?.map(item => {
                  return { job_functions: item };
                }) || []
              }
              label={t('utils.jobFunction')}
              isCheckBox={true}
              disableCloseOnSelect={true}
              className={'w-100'}
              InputLabelProps={{
                style: getInputLabelPropsBasicDetails()
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <IndustrySelection
              multiple={true}
              onChange={value => {
                handleIndustryChange(value);
              }}
              defaultValue={industries}
              selectedList={industries}
              disableCloseOnSelect
              isCheckBox={true}
              label={'Industry Selection'}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectContactStatus
              label={'Status'}
              multiple={true}
              isCheckBox={true}
              onChange={(e, data) => {
                handleStatusChange(data);
              }}
              defaultValues={status}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <RecordTypeSelection
              label={t('contacts.addContact.recordType')}
              defaultValue={data?.type?.map(item => {
                return { type: item };
              })}
              onChange={data => {
                handleRecordTypeChange(data);
              }}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <CustomDateRangePicker placeholder={t('Last Activity')} onChange={handleDateChange} range={range} setRange={setRange} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <CustomTagSelection label={'Tags'} className='w-100' size='small' optionType={'contact'} onChange={handleTagChange} defaultValues={data?.tags} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2' justifyContent='flex-end'>
          <Grid item>
            <CustomButton variant='outlined' buttonText={t('actions.apply')} onClick={handleSaveAndSearch} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

ContactFilterModal.propTypes = {
  data: PropTypes.object,
  setFilterOptions: PropTypes.func,
  setQueryResponse: PropTypes.func
};
