import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';

const capitalizeProperCase = string => {
  if (!string) return '';
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const RouteOpener = ({ type, id, label, capitalize = false }) => {
  let path = '#';
  switch (type) {
    case 'company':
      path = ROUTES.viewCompanyWithoutCurrentTab.replace(':id', id);
      break;
    case 'contact':
      path = ROUTES.allContactGridWithDrawerOpen.replace(':id', id);
      break;
    case 'project':
      path = ROUTES.vProject.replace(':id', id);
      break;
    default:
      console.error('Invalid type provided');
      return;
  }

  return (
    <Link to={path} target='_blank' style={{ color: 'inherit !important' }}>
      <u>{capitalize ? capitalizeProperCase(label) : label}</u>
    </Link>
  );
};

RouteOpener.propTypes = {
  type: PropTypes.oneOf(['company', 'contact', 'project']).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  capitalize: PropTypes.bool.isRequired
};

export default RouteOpener;
