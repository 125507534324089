import { Visibility, VisibilityOff } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const DynamicTableWithHeader = props => {
  const { toggledOn = true, columnDefs = [], title = '', rowData = [], onSortHideAndDrag } = props;

  const [isExpanded, setIsExpanded] = useState(true);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    const handleResize = () => gridApi?.sizeColumnsToFit();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [gridApi]);

  useEffect(() => {
    setIsExpanded(toggledOn);
  }, [toggledOn]);

  const TextRenderer = React.memo(params => {
    const text = params?.value?.toString()?.trim() || '--';
    return (
      <Tooltip title={text} placement='top'>
        <Box sx={{ opacity: params?.node?.data?.hide ? 0.5 : 1, maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{text}</Box>
      </Tooltip>
    );
  });

  const ActionsRenderer = React.memo(params => {
    const handleHideRow = () => {
      const currNode = params.node.data;
      const updatedData = { ...currNode, hide: !currNode.hide };
      params.node.setData(updatedData);
      onRowDragEnd(params);
      params.api.redrawRows({ rowNodes: [params.node] });
    };

    const Icon = params?.node?.data?.hide ? VisibilityOff : Visibility;

    return (
      <Box display='flex' alignItems='center' gap={1} justifyContent='flex-start'>
        <IconButton onClick={handleHideRow} sx={{ padding: 0, background: 'transparent', border: 'none' }}>
          <Icon sx={{ fontSize: 18, opacity: params?.node?.data?.hide ? 0.5 : 1 }} />
        </IconButton>
      </Box>
    );
  });

  const onSortChanged = params => {
    const sortedData = [];
    params.api.forEachNodeAfterFilterAndSort(node => sortedData.push(node.data));
    onSortHideAndDrag?.(sortedData);
  };

  const onRowDragEnd = params => {
    const updatedData = [];
    params.api.forEachNode(node => updatedData.push(node.data));
    onSortHideAndDrag?.(updatedData);
  };

  return (
    <Box display='flex' flexDirection='column' className='ag-theme-alpine' style={{ marginBottom: isExpanded ? '2rem' : 0, minHeight: isExpanded ? '300px' : 0, width: '100%' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography sx={{ color: 'primary.main', fontSize: 16, fontWeight: 700 }}>{title}</Typography>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? <ExpandLessIcon style={{ fontSize: 40 }} /> : <ExpandMoreIcon style={{ fontSize: 40 }} />}</IconButton>
      </Box>
      {isExpanded && (
        <AgGridReact
          frameworkComponents={{ ActionsRenderer, TextRenderer }}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            cellStyle: { display: 'flex', justifyContent: 'center' }
          }}
          rowData={rowData}
          rowDragManaged={true}
          animateRows={true}
          onGridReady={onGridReady}
          rowDragMultiRow={true}
          onSortChanged={onSortChanged}
          onRowDragEnd={onRowDragEnd}
          disableStaticMarkup
          overlayNoRowsTemplate={`<span style='font-weight: bold;'>${toggledOn ? 'No Candidates found' : 'Stage Disabled on Report'}</span>`}
        />
      )}
    </Box>
  );
};

DynamicTableWithHeader.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  title: PropTypes.string,
  rowData: PropTypes.array.isRequired,
  onSortHideAndDrag: PropTypes.func,
  toggledOn: PropTypes.bool
};

export default DynamicTableWithHeader;
