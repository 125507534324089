import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { fetchTagByModule } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const CustomTagSelection = props => {
  const { label, defaultValues = [], onChange = () => {}, optionType = '', ...rest } = props;
  const [values, setValues] = useState([]);
  const [options, setOptions] = useState([]);
  const isInitialLoad = useRef(true);

  const fetchCompanyTags = async () => {
    try {
      const { data } = await fetchTagByModule(GET, '?module=companies&name=');
      setOptions(data);
    } catch (error) {
      console.error('Failed to fetch company tags:', error);
    }
  };

  const fetchContactTags = async () => {
    try {
      const { data } = await fetchTagByModule(GET, '?module=contacts&name=');
      setOptions(data);
    } catch (error) {
      console.error('Failed to fetch contact tags:', error);
    }
  };

  const fetchProjectTags = async () => {
    try {
      const { data } = await fetchTagByModule(GET, '?module=projects&name=');
      setOptions(data);
    } catch (error) {
      console.error('Failed to fetch project tags:', error);
    }
  };

  useEffect(() => {
    if (optionType === 'project') {
      fetchProjectTags();
    }
    if (optionType === 'company') {
      fetchCompanyTags();
    }
    if (optionType === 'contact') {
      fetchContactTags();
    }
  }, [optionType]);

  useEffect(() => {
    if (isInitialLoad.current && options?.length > 0 && defaultValues?.length > 0) {
      const defaultSelected = options?.filter(option => defaultValues.includes(option?.name));
      setValues(defaultSelected);
      onChange(null, defaultSelected);
      isInitialLoad.current = false;
    }
  }, [options, defaultValues, onChange]);

  const handleChange = (event, selected) => {
    setValues(selected);
    onChange(selected);
  };

  return <CustomDropdown {...rest} options={options} isCheckBox={true} label={label} disableCloseOnSelect value={values} onChange={handleChange} multiple />;
};

CustomTagSelection.propTypes = {
  label: PropTypes.string,
  optionType: PropTypes.string,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func
};
export default CustomTagSelection;
