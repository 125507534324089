/* eslint-disable no-unused-vars */
import { Box, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import Competency from './Competency';
import Decision from './Decision';
import Experience from './Experience';
import ProductOne from './ProductOne';
// import QuickMatch from "./QuickMatch";
import { ProjectDataContext } from './Context';
import Scope from './Scope/Scope';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BDBillingSchema, IgnBDSchema, cleanup, prepareSearchData } from '../../../components/AddBD/utils';
import BasicDetails from '../../../components/CreateBD/BasicDetails';
import ClientContacts from '../../../components/CreateBD/ClientContacts';
import TeamInfo from '../../../components/CreateBD/TeamInfo';
import { checkForError, validateCalculationAmount, validatePercentageFields } from '../../../components/ViewSearch/utils';
import CustomButton from '../../../components/common/CustomButton';
import CustomConfirmationPopup from '../../../components/common/CustomConfirmationPopup';
import FileDragUpload from '../../../components/common/FileUpload';
import Loader from '../../../components/common/Loader';
import ValidatePermit from '../../../security/ValidatePermit';
import {
  BDDataApi,
  BillingInfoApi,
  BulkSaveTargetDataApi,
  PositionProfileDataApi,
  ProjectDataApi,
  SFPADataApi,
  SFPASaveDataApi,
  StatusApprovalApi,
  TargetTitleDataApi,
  downloadHeliaReports,
  picklistsDataApis
} from '../../../services/ApiService';
import { VALIDATION_MESSAGE, requireMessage, successMessage, unableMessage, validateMessage } from '../../../services/MessageService';
import {
  BILLING_VALIDATIONS,
  DELETE,
  ERROR,
  FEE_TYPES,
  GET,
  INVOICE_VALIDATION,
  PATCH,
  PENDING_ACKNOWLEDGMENT,
  PICKLISTS,
  POSITION_PROFILE_ROUTE,
  POST,
  PROJECTS_LOGS_MESSAGE,
  PUT,
  ROUTES,
  SUCCESS
} from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import checkForChanges from '../../../utils/checkForChanges';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import { readFile } from '../../../utils/processTranscript';
import CustomModal from '../AddProject/CustomModal';
import LOEPage from '../LOE/LOEPage';
import ApproveRejectAction from './ApproveRejectionAction';
import BillingInfo from './BillingInfo';
import CandidatePipeline from './CandidatePipeline';
import ClientPortalMenuSetup from './ClientPortalMenuSetup';
import ExperienceAndCompetency from './ExperienceAndCompetency/ExperienceAndCompetency';
import InviteToClientPortal from './InviteToClientPortal';
import InvoiceInfo from './InvoiceInfo';
import ProjectActivities from './ProjectActivities';
import ProjectDocuments from './ProjectDocument';
import Sourced from './Sourced';
import StageGrouping from './StageGrouping';
import ViewPositionProfile from './ViewPositionProfile';

const defaultValues = {
  // currency: "",
  stage: 'BD',
  stage_desc: 'Business Development',
  retainers: [{ field: 'Retainer 1' }, { field: 'Retainer 2' }, { field: 'Retainer 3' }, { field: 'Final Retainer' }, { field: 'Contingent Fee' }, { field: 'Monthly Fee' }],
  partners: [],
  basic_partners: [],
  notes: [{}],
  recruiters: [{}],
  researchers: [{}],
  project_admin: [{}],
  estimated_percentage_bases: [{}],
  indirect_fees_searches: [{}],
  actual_percentage_bases: [{}],
  term_type: 'milestone',
  fee_type: 'fixed_fee',
  fee_type_desc: FEE_TYPES.ONE_THIRD,
  fee_percentage: 0,
  vendor_portal: '',
  framework_type: ''
};

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const originalTabData = [
  {
    label: 'Overview',
    subTabs: [
      {
        label: 'Details'
      },
      {
        label: 'Team'
      },
      {
        label: 'Client Contacts'
      }
    ]
  },
  {
    label: 'Roadmap',
    subTabs: [
      {
        label: 'Position Profile'
      },
      {
        label: 'Scope'
      },
      {
        label: 'Experience and Competency'
      },
      {
        label: 'Experience'
      },
      {
        label: 'Competency'
      },
      {
        label: 'Decisions'
      }
    ]
  },
  {
    label: 'Research',
    subTabs: [
      {
        label: 'Intelligent Search'
      },
      {
        label: 'Applicants'
      }
    ]
  },
  {
    label: 'Candidate Pipeline',
    subTabs: []
  },
  {
    label: 'Billing',
    subTabs: [
      {
        label: 'LOE'
      }
    ]
  },
  {
    label: 'Client Portal',
    subTabs: [
      {
        label: 'Invite to Client Portal'
      },
      {
        label: 'General Setup'
      },
      {
        label: 'Stage Grouping'
      }
    ]
  },
  {
    label: 'Documents',
    subTabs: []
  },
  {
    label: 'Activity History',
    subTabs: []
  }
];

function ProjectTabLayout(props) {
  const { projectData, tabIndexSet, handleTabChanges = () => {}, fetchProjectData = () => {} } = useContext(ProjectDataContext);
  const {
    openPipelineTab,
    refreshDataCbRef,
    handleUniversalState,
    getValueFromUniversalState,
    data,
    DBData,
    getBD = () => {},
    setCurrentData = () => {},
    PositionProfileData,
    paramsProjectId
  } = props;

  const { setValue, watch, register, handleSubmit, getValues, control, reset } = useForm({ ...defaultValues });
  // const [currentValues, setCurrentValues] = useState({ DBData })
  const [options /* , setOptions */] = useState([]);
  const [approveRejectData, setApproveRejectData] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [skipCheckThresholdValue, setSkipCheckThresholdValue] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(false);
  const [thresholdPopup, setThresholdPopup] = useState(false);
  const [positionProfile, setPositionProfile] = useState();
  const [lastUploadedTranscript, setLastUploadedTranscript] = useState(null);
  const [isHeliaTitleChanged, setIsHeliaTitleChanged] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [tabData, setTabData] = useState(originalTabData);
  let location = useLocation();
  let query = useQuery();
  const { id, parentTab, subTab } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [isPositionProfileLoading, setIsPositionProfileLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [clientContacts, setClientContacts] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (openPipelineTab) handleActiveTab(3);
  }, [openPipelineTab]);

  useEffect(() => {
    if (watch('heliaTitle') === undefined) {
      setValue('heliaTitle', projectData?.helia_title);
    }
  }, [projectData]);

  useEffect(() => {
    setValue('framework_type', projectData?.framework_type);
  }, [projectData?.framework_type]);

  useEffect(() => {
    register('stage');
    register('stage_desc');
    register('ign_companies');
    register('company_id');
    register('client_team');
    register('job_title');
    register('country');
    register('projected_start_date');
    register('basic_partners');
    register('probability');
    register('bd_status');
    register('brand');
    register('markets');
    // register('market')
    register('competition');
    register('bd_currency_values.code');
    //billing form
    register('currency');
    register('bd_currency');
    register('billing_region');
    register('fee_cap_amount');
    register('term_type');
    register('intervals_other');
    register('intervals');
    register('fee_type');
    register('fee_percentage');
    register('billing_notes');
    register('framework_type');
    // register('estimated_percentage_bases')
    // register('project_indirect_fees')
    // register('estimated_percentage_base')
    // register('estimated_indirect_total_fee')
    // register('estimated_revenue')
    register('projected_fee');
    register('description');
  }, [register]);

  useEffect(() => {
    setPositionProfile(PositionProfileData);
    let lastTranscript = null;
    if (PositionProfileData?.data?.ign_project_position_transcripts && Array.isArray(PositionProfileData?.data?.ign_project_position_transcripts)) {
      lastTranscript = PositionProfileData?.data?.ign_project_position_transcripts[PositionProfileData?.data?.ign_project_position_transcripts?.length - 1];
    }
    if (lastTranscript) {
      setFileUploaded(true);
      setLastUploadedTranscript(lastTranscript);
      // setValue('file', lastTranscript);
    } else {
      setFileUploaded(false);
      setLastUploadedTranscript(null);
      setValue('file', null);
    }
  }, [PositionProfileData]);
  const searchStatusApproval = async payload => {
    setUpdateLoader(true);
    const { status, ...rest } = await StatusApprovalApi(PATCH, payload, `${DBData.id}/status`);

    if (status === 200) {
      if (location?.state?.isFromApproval) {
        window.history.pushState(null, null, location?.previousPath);
      }
      if (rest?.data?.data?.billing_status?.includes('REJ')) {
        enqueueSnackbar('Request Rejected', { variant: SUCCESS });
      } else if (rest?.data?.data?.billing_status?.includes('APR')) {
        enqueueSnackbar('Request Approved', { variant: SUCCESS });
      } else if (rest?.data?.data?.billing_status?.includes('ACK_DONE')) {
        enqueueSnackbar('Acknowledged Successfully', { variant: SUCCESS });
      } else if (rest?.data?.data?.billing_status?.includes('PND_ACK')) {
        enqueueSnackbar('Billing Desk Notified Successfully', {
          variant: SUCCESS
        });
      }
      await getBD(DBData.id);
      setUpdateLoader(false);
      // enqueueSnackbar('Billing status updated successfully', { variant: SUCCESS })
    } else {
      enqueueSnackbar(rest.data, { variant: ERROR });
      setUpdateLoader(false);
    }
  };
  const triggerBillingWorkflow = async () => {
    const payload = {
      projectId: DBData?.id,
      market: DBData?.market
    };
    const response = await BillingInfoApi(POST, payload, 'trigger-billing-workflow');
    if (response?.status === 200) {
      enqueueSnackbar('Billing workflow triggered successfully', { variant: SUCCESS });
      await getBD(DBData.id);
    } else {
      enqueueSnackbar('Failed to trigger billing workflow', { variant: ERROR });
    }
  };
  const submitForApproval = async (payload, confirm) => {
    if (!confirm) {
      setConfirmPopup(true);
    }
    const body = {
      billing_status: DBData.billing_status,
      stage: PENDING_ACKNOWLEDGMENT,
      estimated_revenue: payload.estimated_revenue,
      markets: DBData.markets,
      service_offering: DBData.service_offering ? DBData.service_offering : null,
      approval: true,
      action: 'submitForApproval'
    };
    setUpdateLoader(true);
    const { status } = await BDDataApi(PATCH, DBData.id, body, 'update');
    if (status === 200) {
      enqueueSnackbar('Billing Desk Notified Successfully', {
        variant: SUCCESS
      });
      await getBD(DBData.id);
      setUpdateLoader(false);
    } else {
      setUpdateLoader(false);
    }
  };

  let tabToHideByRecordType = {
    Project: [],
    'Business Development': [
      // {
      //   label: 'Candidate Pipeline'
      // },
      {
        label: 'Approvals'
      },
      {
        label: 'Research',
        subTabs: [
          {
            label: 'Applicants'
          }
        ]
      }
      // {
      //   label: 'Client Portal'
      // }
    ]
  };

  useEffect(() => {
    setTabData(filterTabByRecordType(data?.record_type, tabData));
  }, [data?.record_type]);

  const filterTabByRecordType = (recordType, tabData) => {
    tabData = tabData.map(tab => {
      let hideTab = tabToHideByRecordType[recordType]?.find(hideTab => hideTab.label === tab.label);

      if (hideTab) {
        if (!hideTab?.subTabs) return null;

        tab.subTabs = tab.subTabs.filter(subTab => !hideTab.subTabs.find(hideSubTab => subTab.label === hideSubTab.label));
        return tab;
      }
      return tab;
    });

    tabData = tabData.filter(data => data);
    return tabData;
  };

  const handleConfirmPopup = async () => {
    setConfirmPopup(false);
    submitForApproval(approveRejectData, true);
  };

  const handleCancelPopup = () => {
    setConfirmPopup(false);
    return;
  };
  const validateKgpInfo = formData => {
    let isError = false;
    if (formData.partners && formData.partners.length > 0) {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      };
      isError = checkForError(formData.partners, fields);
    }
    if (!isError && formData.recruiters && formData.recruiters.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.recruiters, fields);
    }
    if (!isError && formData.researchers && formData.researchers.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.researchers, fields);
    }
    if (!isError && formData.eas && formData.eas.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.eas, fields);
    }
    if (!isError && formData.project_admin && formData.project_admin?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.project_admin, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  };
  // this api call temporary after some time create another component for api update bd for all bd tab
  const validateBillingInfo = async formData => {
    const isValid = await BDBillingSchema.isValid(formData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'bd_currency', label: 'Currency Code', type: String },
        { fieldName: 'billing_region', label: 'Billing Region', type: String },
        { fieldName: 'geography', label: 'Geography', type: String }
      ];
      let dirtyField = customFormValidator(formData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    }
    const isValidIndirectFee = validatePercentageFields(formData);
    if (!isValidIndirectFee) {
      enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, {
        variant: ERROR
      });
      return;
    }
    const isCalculationAmount = validateCalculationAmount(formData);
    if (!isCalculationAmount) {
      enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, {
        variant: ERROR
      });
      return;
    }

    return true;
  };
  const updateBD = async (businessDevelopment, currentTabValue) => {
    const isValid = await IgnBDSchema.isValid(businessDevelopment);
    // setHandleSaveArgs({ businessDevelopment, currentTabValue, save, newTabValue })
    if (!isValid) {
      let requiredField = [
        { fieldName: 'ign_companies', label: 'Company', type: {} },
        { fieldName: 'job_title', label: 'Job Title', type: String },
        // { fieldName: 'basic_partners', label: 'Partners', type: [] },
        { fieldName: 'probability', label: 'Probability', type: String },
        { fieldName: 'framework_type', label: 'Framework Type', type: String },
        // { fieldName: "brand", label: "Service Offering", type: String },
        { fieldName: 'stage', label: 'Stage', type: String },
        { fieldName: 'markets', label: 'Geography', type: String },
        { fieldName: 'service_offering', label: 'Service Offering', type: String },
        { fieldName: 'location_place_id', label: 'Location', type: String },
        {
          fieldName: 'projected_start_date',
          label: 'Projected Start Date',
          type: String
        },
        {
          fieldName: 'industries',
          label: 'Industry',
          type: []
        }
      ];
      let dirtyField = customFormValidator(businessDevelopment, requiredField);
      if (dirtyField && currentTabValue == 0) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    }
    if (currentTabValue === 1) {
      const isValidKgpInfo = validateKgpInfo(businessDevelopment);
      if (!isValidKgpInfo) return;
    }
    if (currentTabValue === 3) {
      const isValidBillingInfo = await validateBillingInfo(businessDevelopment);
      if (!isValidBillingInfo) return;
    }
    if (businessDevelopment.projected_start_date === 'Invalid date') {
      const message = validateMessage('valid date', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (businessDevelopment.by_product_fee && businessDevelopment.by_product_fee > 100) {
      const message = validateMessage('by product fee less than 100', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (currentTabValue === 1 && businessDevelopment.partners) {
      if (!businessDevelopment.partners.length || (businessDevelopment.partners.length && !businessDevelopment.partners.find(ele => ele?.user))) {
        const message = requireMessage('Consultant', 'is');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
      if (businessDevelopment?.partners?.length === 1) {
        const leadConsultantIndex = businessDevelopment.partners.findIndex(par => par.is_lead === true);
        if (leadConsultantIndex === -1) {
          businessDevelopment.partners[0].is_lead = true;
        }
      }
    }
    //Checking duplication in client contact.
    if (businessDevelopment.project_client_team) {
      let team_list_id = new Set();
      for (let client of businessDevelopment.project_client_team) {
        if (client.contact_id) team_list_id.add(client.contact_id);
      }
      if (team_list_id.size != businessDevelopment.project_client_team.length) {
        enqueueSnackbar('Client contact detail not found, please add.', { variant: ERROR });
        return;
      }
    }
    // if (businessDevelopment.estimated_revenue > 0 && !businessDevelopment.market) {
    //   enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
    //   return
    // }
    const payload = cleanup(businessDevelopment, currentTabValue);
    const isDataequal = checkForChanges(DBData, businessDevelopment);
    let est_value, curr_value;
    if (!Number.isInteger(businessDevelopment?.estimated_revenue)) {
      est_value = businessDevelopment?.estimated_revenue && parseFloat(businessDevelopment?.estimated_revenue.toFixed(2));
      curr_value = DBData?.estimated_revenue && parseFloat(DBData?.estimated_revenue.toFixed(2));
    } else {
      est_value = businessDevelopment?.estimated_revenue && parseInt(businessDevelopment?.estimated_revenue);
      curr_value = DBData?.estimated_revenue && parseInt(DBData?.estimated_revenue);
    }
    if (!isDataequal) {
      if (skipCheckThresholdValue) {
        setSkipCheckThresholdValue(false);
        if (thresholdValue) {
          payload['isBelowThreshold'] = true;
        } else {
          payload['isBelowThreshold'] = false;
        }
      }
      let modifiedPayload = null;
      if (currentTabValue == 3) {
        modifiedPayload = {
          projectId: payload.id,
          project_indirect_fees: payload.project_indirect_fees.map(fee => ({
            ...fee,
            project_id: payload.id
          })),
          estimated_percentage_bases: payload.estimated_percentage_bases.map(fee => ({
            ...fee,
            project_id: payload.id
          })),
          ...(payload?.actual_percentage_bases?.length > 0 && {
            actual_percentage_bases: payload.actual_percentage_bases.map(fee => ({
              ...fee,
              project_id: payload.id
            }))
          }),
          estimations: {
            estimated_percentage_base: payload.estimated_percentage_base,
            actual_percentage_base: payload.actual_percentage_base,
            estimated_indirect_total_fee: payload.estimated_indirect_total_fee,
            actual_indirect_total_fee: payload.actual_indirect_total_fee,
            estimated_revenue: payload.estimated_revenue,
            actual_revenue: payload.actual_revenue,
            actual_overridden_revenue: payload.actual_overridden_revenue
          },
          fee_information: {
            bd_currency: payload.bd_currency,
            by_product_fee: payload.by_product_fee !== null ? parseFloat(payload.by_product_fee) : null,
            is_fee_cap: payload.is_fee_cap,
            fee_cap_amount: payload.fee_cap_amount,
            term_type: payload.term_type,
            intervals: payload.intervals,
            market: payload.markets.id,
            invoice_terms: payload.invoice_terms,
            fee_type: payload.fee_type,
            fee_percentage: payload.fee_percentage,
            intervals_other: payload.intervals_other
          },
          billing_information: {
            billable: payload.billable,
            billing_region: payload.billing_region,
            is_po_direct: payload.is_po_direct,
            po_number: payload.po_number,
            billing_notes: payload.billing_notes,
            billing_address: payload.billing_address,
            email_invoice_to: payload.email_invoice_to,
            vendor_portal: businessDevelopment.vendor_portal,
            vendor_portal_url: businessDevelopment.vendor_portal_url
          }
        };
      }
      const obj =
        currentTabValue === 3
          ? await BillingInfoApi(POST, modifiedPayload, 'update-billing-info')
          : payload.id
            ? await BDDataApi(PATCH, payload.id, payload, 'update')
            : await BDDataApi(POST, '', payload);
      setUpdateLoader(false);
      // const obj = payload.id ? await BDDataApi(PATCH, payload.id, payload, 'update') : await BDDataApi(POST, '', payload);
      let { status, data: res } = obj;
      if (status && status === 201) {
        const jobNumber = res.result.job_number;
        setCurrentData(prevState => ({
          ...prevState,
          job_number: jobNumber,
          id: res.result.id
        }));
        const message = obj?.data?.message || successMessage(DBData?.record_type || 'Project', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else if (status && status === 200) {
        let { status, data: searchResult } = await BDDataApi(GET, payload.id ? payload.id : res.result.id);
        if (status && status === 200) {
          const searchData = prepareSearchData(searchResult);
          setCurrentData(searchData);
        }
        const message = obj?.data?.message || successMessage(DBData?.record_type || 'Project', 'updated');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = obj?.data?.message || unableMessage(DBData?.record_type || 'Project', `${payload.id ? 'update' : 'create'}`);
        enqueueSnackbar(res.message || message, { variant: ERROR });
        return false;
      }

      setUpdateLoader(false);
      return true;
    }
  };
  const handleSave = async currentTabValue => {
    setUpdateLoader(true);
    try {
      const getCurrentData = getValues();
      if (currentTabValue == 3) {
        // setSkipCheckThresholdValue(true);
        if (
          !DBData.billing_status &&
          getCurrentData.estimated_percentage_bases.every(data => data.calculated_estimated_amt === 0 || isNaN(data.calculated_estimated_amt) || !data.calculated_estimated_amt)
        ) {
          enqueueSnackbar('Please enter estimated value', { variant: 'error' });
          return;
        }
        if (
          DBData.billing_status === 'APR_EST' &&
          getCurrentData?.actual_percentage_bases?.every(data => data.calculated_actual_amt === 0 || isNaN(data.calculated_actual_amt) || !data.calculated_actual_amt)
        ) {
          enqueueSnackbar('Please enter actual value', { variant: 'error' });
          return;
        }
      }
      const formValues = { ...DBData, ...getCurrentData };

      reset({ ...formValues });
      if (skipCheckThresholdValue) setCurrentData(formValues);

      const result = await updateBD(formValues, currentTabValue);
      if (result) {
        fetchProjectData();
      }
      return result;
    } catch (error) {
      enqueueSnackbar(error?.message || 'Failed to save BD', { variant: ERROR });
    } finally {
      setUpdateLoader(false);
    }
  };

  const handleSavePositionProfile = async () => {
    try {
      setUpdateLoader(true);
      const description = getValues()?.description?.length > 0 ? getValues()?.description : positionProfile?.data?.description;
      const aiResponseData = getValueFromUniversalState('sfpaPosition')?.length > 0 ? getValueFromUniversalState('sfpaPosition') : positionProfile?.data?.ai_response_data;
      let positionProfilePayload;
      if (positionProfile?.data?.id) {
        positionProfilePayload = { ...positionProfile?.data, description: description, ai_response_data: aiResponseData };
      } else {
        positionProfilePayload = {
          project_id: data?.id,
          description: description,
          hide_options: [],
          job_description: '',
          ai_response_data: aiResponseData
        };
      }

      const fileInfo = getValues()['file'];

      if (fileInfo) {
        let extn = fileInfo?.name ? fileInfo?.name?.split('.')[1] : '';
        if (extn == 'vtt' || extn == 'json') {
          setFileUploaded(true);
          const reader = new FileReader();
          reader.onload = async e => {
            const parsedFileContent = await readFile(e.target.result, extn);
            await handlePositionProfileSubmit({
              ...positionProfilePayload,
              transcript: {
                transcript_filename: fileInfo.name,
                parsed_data: parsedFileContent
                // raw_data: e.target.result
              }
            });
          };
          reader.onerror = e => {
            console.log(e);
          };
          reader.readAsText(fileInfo);
        } else {
          setFileUploaded(false);
          enqueueSnackbar('Unsupported file type, json or vtt file are supported', { variant: ERROR });
        }
      } else if (lastUploadedTranscript) {
        await handlePositionProfileSubmit({
          ...positionProfilePayload,
          transcript: {
            transcript_filename: lastUploadedTranscript.transcript_filename,
            parsed_data: lastUploadedTranscript.parsed_data
            // raw_data: e.target.result
          }
        });
      } else {
        await handlePositionProfileSubmit(positionProfilePayload);
      }
    } catch (e) {
      console.log(PROJECTS_LOGS_MESSAGE.POSITION_PROFILE_UPDATE_ERROR, e);
      setUpdateLoader(false);
    }
  };

  const handlePositionProfileSubmit = async positionProfilePayload => {
    const response = await PositionProfileDataApi(positionProfile?.data?.id ? PUT : POST, '', positionProfilePayload, '', '');
    if (response.status === 201 || response.status === 200) {
      const getPositionProfileData = await PositionProfileDataApi(GET, data.id, null, '', null);
      setPositionProfile(getPositionProfileData);
      setValue('description', getPositionProfileData?.data?.description);
      setValue('sfpaPosition', getPositionProfileData?.data?.ai_response_data);
      let lastTranscript = getPositionProfileData?.data?.ign_project_position_transcripts[getPositionProfileData?.data?.ign_project_position_transcripts?.length - 1];
      if (lastTranscript) {
        setFileUploaded(true);
        setLastUploadedTranscript(() => lastTranscript);
        // setValue('file', lastTranscript);
      } else {
        setFileUploaded(false);
        setLastUploadedTranscript(null);
        setValue('file', null);
      }
      setUpdateLoader(false);
      const message = successMessage('Position Profile', response.status === 201 ? 'created' : 'updated');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      const message = 'Somethig Went Wrong';
      enqueueSnackbar(message, { variant: ERROR });
      setUpdateLoader(false);
    }
  };

  const handleConfirmThresholdPopup = () => {
    setThresholdValue(true);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(true);
  };

  const handleThresholdCancelPopup = () => {
    setThresholdValue(false);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(false);
  };
  const handleNextTab = (currentTab, nextTab) => {
    handleTabChanges(currentTab, nextTab);
  };
  useEffect(() => {
    const handleUpdate = async () => {
      if (skipCheckThresholdValue) {
        const getCurrentData = getValues();
        const formValues = { ...DBData, ...getCurrentData };
        reset({ ...formValues });
        if (skipCheckThresholdValue) setCurrentData(formValues);

        const result = await updateBD(formValues, 3);
        return result;
      }
    };
    handleUpdate();
  }, [skipCheckThresholdValue]);

  const tabRefArray = {
    SCOPE_TAB: useRef(),
    // EXPERIENCE_TAB: useRef(),
    // COMPETENCY_TAB: useRef(),
    // PRODUCT_ONE_TAB: useRef(),
    // SOURCED_TAB: useRef(),
    // PIPELINE_TAB: useRef(),
    // BASIC_INFO_TAB: useRef(),
    // TEAM_INFO_TAB: useRef(),
    // POSITION_PROFILE_TAB: useRef(),
    // BILLING_INFO_TAB: useRef(),
    // INVOICE_INFO_TAB: useRef(),
    // XYZ_TAB: useRef(),
    // EXPERIENCE_AND_LEADERSHIP_TAB: useRef(),
    DOCUMENTS_TAB: useRef()
    // PENDING_APPROVAL_TAB: useRef(),
    // REJECTED_TAB: useRef(),
  };

  const onTabChange = async () => {
    console.log('tab change');
    if (tabRefArray?.SCOPE_TAB?.current) {
      const result = await tabRefArray?.SCOPE_TAB?.current?.saveTabData();
      return result;
    }
    // if (tabRefArray?.EXPERIENCE_TAB?.current) {
    //   const result =
    //     await tabRefArray?.EXPERIENCE_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.COMPETENCY_TAB?.current) {
    //   const result =
    //     await tabRefArray?.COMPETENCY_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.PRODUCT_ONE_TAB?.current) {
    //   const result =
    //     await tabRefArray?.PRODUCT_ONE_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.SOURCED_TAB?.current) {
    //   const result =
    //     await tabRefArray?.SOURCED_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.PIPELINE_TAB?.current) {
    //   const result =
    //     await tabRefArray?.PIPELINE_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.BASIC_INFO_TAB?.current) {
    //   const result =
    //     await tabRefArray?.BASIC_INFO_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.TEAM_INFO_TAB?.current) {
    //   const result =
    //     await tabRefArray?.TEAM_INFO_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.POSITION_PROFILE_TAB?.current) {
    //   const result =
    //     await tabRefArray?.POSITION_PROFILE_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.BILLING_INFO_TAB?.current) {
    //   const result =
    //     await tabRefArray?.BILLING_INFO_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.INVOICE_INFO_TAB?.current) {
    //   const result =
    //     await tabRefArray?.INVOICE_INFO_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.XYZ_TAB?.current) {
    //   const result =
    //     await tabRefArray?.XYZ_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.EXPERIENCE_AND_LEADERSHIP_TAB?.current) {
    //   const result =
    //     await tabRefArray?.EXPERIENCE_AND_LEADERSHIP_TAB?.current?.saveTabData();
    //   return result;
    // }
    if (tabRefArray?.DOCUMENTS_TAB?.current) {
      const result = await tabRefArray?.DOCUMENTS_TAB?.current?.saveTabData();
      return result;
    }
    // if (tabRefArray?.PENDING_APPROVAL_TAB?.current) {
    //   const result =
    //     await tabRefArray?.PENDING_APPROVAL_TAB?.current?.saveTabData();
    //   return result;
    // }
    // if (tabRefArray?.REJECTED_TAB?.current) {
    //   const result =
    //     await tabRefArray?.REJECTED_TAB?.current?.saveTabData();
    //   return result;
    // }
    return true;
  };
  const { state } = useLocation();

  useEffect(() => {
    if (state?.tabIndex) {
      handleNextTab(3, state.tabIndex);
    }
  }, [state]);
  const getDynamicUrlIndex = (parent, child) => {
    const parentIndex = tabData.findIndex(tab => tab.label === parent);
    if (parentIndex === -1) return { parentIndex: 0, childIndex: 0 };
    const childIndex = tabData[parentIndex].subTabs.findIndex(tab => tab.label === child);
    if (childIndex === -1 || !childIndex) return { parentIndex, childIndex: 0 };
    return { parentIndex, childIndex };
  };
  // this function is used to set the active tab and sub tab based on the url [navigation based on url]
  useEffect(() => {
    if (parentTab || subTab) {
      const { parentIndex, childIndex } = getDynamicUrlIndex(parentTab, subTab);
      setActiveTab(parentIndex);
      setTabIndex(childIndex);
    } else {
      navigate(`${ROUTES.projectDetails}/${id}/${tabData[0].label}/${tabData[0].subTabs[0].label}`);
    }
  }, [parentTab, subTab, tabData]);

  const handleActiveTab = value => {
    const pt = tabData[value].label || tabData[0].label;
    if (tabData[value].subTabs.length === 0) {
      navigate(`${ROUTES.projectDetails}/${id}/${pt}/null`);
    } else {
      const st = tabData[value].subTabs[0]?.label || 'null';
      navigate(`${ROUTES.projectDetails}/${id}/${pt}/${st}`);
    }
  };

  const handleCreateProject = async () => {
    try {
      setUpdateLoader(true);
      const projectId = id;
      const body = {
        template_name: 'SFPA-Search',
        mode: 'initial',
        search_id: projectId
      };
      const sfpaResponse = await SFPADataApi(POST, projectId, body);

      if (sfpaResponse.data) {
        //todo project post api
        const saveResponse = await SFPASaveDataApi(POST, '', {
          ...sfpaResponse.data,
          project_id: projectId
        });
        if (saveResponse) {
          setUpdateLoader(false);
        } else {
          enqueueSnackbar(t('utils.somethingWentWrong'), { variant: ERROR });
          setUpdateLoader(false);
        }
      } else {
        enqueueSnackbar(t('utils.somethingWentWrong'), { variant: ERROR });
        setUpdateLoader(false);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(t('utils.somethingWentWrong'), { variant: ERROR });
    }
  };

  const saveTargetTitle = async heliaTitle => {
    try {
      const { data: targetTitleOptionResponse } = await TargetTitleDataApi(POST, '', '', 'all', '');
      const targetData = targetTitleOptionResponse?.data;
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('utils.somethingWentWrong'), { variant: ERROR });
    }
  };

  const addHeliaTitle = async heliaTitle => {
    try {
      setUpdateLoader(true);
      const bulkPayload = [
        {
          title_name: heliaTitle,
          project_id: projectData.id,
          weight: 0,
          employee_range_from: 0,
          employee_range_to: 0,
          revenue_range_from: 0,
          revenue_range_to: 0
        }
      ];
      await BulkSaveTargetDataApi(POST, '', { target_titles: bulkPayload });
      enqueueSnackbar(`${t('project.internal_title')} added successfully.`, { variant: SUCCESS });
      fetchProjectData();
      setUpdateLoader(false);
    } catch (error) {
      enqueueSnackbar(t('utils.somethingWentWrong'), { variant: ERROR });
    }
  };

  const handleSaveHeliaTitle = async () => {
    try {
      setUpdateLoader(true);
      const heliaTitle = watch('heliaTitle');
      let payload = {
        id,
        helia_title: heliaTitle
      };
      await ProjectDataApi(PUT, '', payload, '', '');
      enqueueSnackbar(`${t('project.internal_title')} updated successfully.`, {
        variant: SUCCESS
      });
      setIsHeliaTitleChanged(false);
      await saveTargetTitle(heliaTitle);
      setUpdateLoader(false);
      const heliaExists = projectData?.targetTitles?.find(target => target?.title_name?.toLowerCase() === heliaTitle?.toLowerCase());
      if (!heliaExists) {
        addHeliaTitle(heliaTitle);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(t('utils.somethingWentWrong'), 'error');
      setUpdateLoader(false);
    }
  };

  const handleCloseHeliaTitle = async () => {
    try {
      setValue('heliaTitle', projectData?.helia_title);
      setIsHeliaTitleChanged(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(t('utils.somethingWentWrong'), 'error');
    }
  };
  const generate = async (parsedFileContent = null, previousPositionProfile = null) => {
    try {
      let incomingDescription = '';
      const projectId = id;
      const body = {
        template_name: 'POSITION-PROFILE',
        mode: 'initial',
        search_id: projectId,
        transcript: parsedFileContent,
        previousPositionProfile: previousPositionProfile,
        project_id: projectId,
        id: positionProfile?.data?.id
      };

      const sfpaResponse = await ProjectDataApi(POST, projectId, body, POSITION_PROFILE_ROUTE);
      if (sfpaResponse.status !== 200) {
        enqueueSnackbar(sfpaResponse.data.message, { variant: ERROR });
        setIsPositionProfileLoading(false);
        return;
      }
      const event = {
        target: {
          name: 'sfpaPosition',
          value: sfpaResponse?.data
        }
      };
      handleUniversalState(event);
      setIsPositionProfileLoading(false);
      return;
    } catch (err) {
      console.log(err);
      setIsPositionProfileLoading(false);
      enqueueSnackbar(err.message || t('utils.somethingWentWrong'), 'error');
    }
  };
  const handleRegenerate = async () => {
    try {
      setIsPositionProfileLoading(true);
      // setUpdateLoader(true);
      // Taking current position profile to use as previous position profile
      const currentPositionProfile = watch('description') || positionProfile?.data?.description || null;
      const fileInfo = getValues()['file'];
      let extn = fileInfo?.name ? fileInfo?.name?.split('.')[1] : '';
      if (extn == 'vtt' || extn == 'json') {
        setFileUploaded(true);
        const reader = new FileReader();
        reader.onload = async e => {
          const parsedFileContent = await readFile(e.target.result, extn);
          await generate({ transcript_filename: fileInfo?.name, parsed_data: parsedFileContent }, currentPositionProfile);
          const output = await getValueFromUniversalState('sfpaPosition')?.output;
          setValue('description', output);
          setUpdateLoader(false);
        };
        reader.onerror = e => {
          enqueueSnackbar(e.message, { variant: ERROR });
          setUpdateLoader(false);
        };
        reader.readAsText(fileInfo);
      } else if (lastUploadedTranscript) {
        await generate({ transcript_filename: lastUploadedTranscript?.transcript_filename, parsed_data: lastUploadedTranscript?.parsed_data }, currentPositionProfile);
        setValue('description', getValueFromUniversalState('sfpaPosition')?.output);
        setUpdateLoader(false);
      } else if (extn == '') {
        await generate(null, currentPositionProfile);
        setValue('description', getValueFromUniversalState('sfpaPosition')?.output);
        setUpdateLoader(false);
      } else {
        setFileUploaded(false);
        enqueueSnackbar('Unsupported filFe type, json or vtt file are supported', { variant: ERROR });
      }
    } catch (err) {
      console.log(err);
      setUpdateLoader(false);
      enqueueSnackbar(t('utils.somethingWentWrong'), { variant: ERROR });
    }
  };
  const handleChangeDocument = data => {
    let extn = data?.name ? data?.name?.split('.')[1] : '';
    if (extn == 'vtt' || extn == 'json') {
      setValue('file', data);
      setFileUploaded(true);
    } else {
      setFileUploaded(false);
      enqueueSnackbar('Unsupported file type, json or vtt file are supported', { variant: ERROR });
    }
  };
  const handleTabIndex = value => {
    const st = tabData[activeTab].subTabs[value].label || 'null';
    const pt = tabData[activeTab].label || tabData[0].label;
    navigate(`${ROUTES.projectDetails}/${id}/${pt}/${st}`);
  };
  const downloadPositionProfile = async () => {
    try {
      setUpdateLoader(true);
      const projectId = id;
      const payload = {
        project_id: projectId || '',
        language: 'en',
        report_code: 'IGN_PPR',
        file_Type: 'docx'
      };
      const res = await downloadHeliaReports(payload);

      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', `${projectId}_${payload.report_code}_report.docx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setUpdateLoader(false);
    } catch (err) {
      setUpdateLoader(false);
      console.error(err);
    }
  };

  const showTab = (index, childIndex) => {
    const tab = tabData.find(tab => tab.label === index);

    if (tab && tab?.subTabs?.length === 0) return true;

    if (tab && tab?.subTabs?.length > 0 && tab.subTabs.find(subTab => subTab.label === childIndex)) return true;

    return false;
  };

  useEffect(() => {
    const index = tabData.findIndex(tab => tab.label === 'Billing');
    const tabDataCopy = tabData;
    if (DBData.record_type !== 'Business Development') {
      tabDataCopy[index].subTabs = [
        {
          label: 'Billing Info'
        },

        {
          label: 'Invoice Info'
        }
      ];
    } else {
      tabDataCopy[index].subTabs = [
        {
          label: 'Billing Info'
        },
        {
          label: 'LOE'
        }
      ];
    }
    setTabData(tabDataCopy);
  }, [data?.record_type]);

  useEffect(() => {
    if (!data?.framework_type) return;
    setTabData(() =>
      originalTabData.map(tab => {
        if (tab.label === 'Roadmap') {
          return {
            ...tab,
            subTabs: tab.subTabs.filter(subTab => {
              if (data?.framework_type === 'ignyte_intelligence_assisted') {
                return subTab.label !== 'Experience' && subTab.label !== 'Competency';
              } else {
                return subTab.label !== 'Experience and Competency';
              }
            })
          };
        }
        return tab;
      })
    );
  }, [data?.framework_type]);

  const { data: positionProfileInfoText } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.POSITION_PROFILE_INFO_TEXT);
    }
  });

  const fetchPositionProfile = async () => {
    const fetchPositionProfileData = await PositionProfileDataApi(GET, id, null, '', null);
    setPositionProfile(fetchPositionProfileData);
    setValue('description', fetchPositionProfileData?.data?.description);
    setValue('sfpaPosition', fetchPositionProfileData?.data?.ai_response_data);
    let lastTranscript = fetchPositionProfileData?.data?.ign_project_position_transcripts[fetchPositionProfileData?.data?.ign_project_position_transcripts?.length - 1];
    if (lastTranscript) {
      setFileUploaded(true);
      setLastUploadedTranscript(() => lastTranscript);
      // setValue('file', lastTranscript);
    } else {
      setFileUploaded(false);
      setLastUploadedTranscript(null);
      setValue('file', null);
    }
  };

  const handleDeleteFile = async () => {
    setUpdateLoader(true);
    if (lastUploadedTranscript) {
      const { status, data } = await PositionProfileDataApi(DELETE, null, { id: lastUploadedTranscript.id }, 'transcript');
      if (status === 200) {
        const message = successMessage('File', 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
        setLastUploadedTranscript(null);
        await fetchPositionProfile();
      } else {
        enqueueSnackbar(data?.message, { variant: ERROR });
      }
    }
    setUpdateLoader(false);
  };
  return (
    <Box className={'mt-3'}>
      <Loader show={updateLoader} />
      {confirmPopup && (
        <CustomConfirmationPopup
          open={confirmPopup}
          onClose={handleCancelPopup}
          cancelText='Cancel'
          confirmText='Ok'
          onConfirm={handleConfirmPopup}
          customMessage={INVOICE_VALIDATION.UNSAVED_DATA}
          setOpen={setConfirmPopup}
        />
      )}
      {thresholdPopup && (
        <div className='confirmation-popup-bd-search'>
          <CustomConfirmationPopup
            open={thresholdPopup}
            customMessage={VALIDATION_MESSAGE.threshold_confirmation}
            onConfirm={handleConfirmThresholdPopup}
            setOpen={setThresholdPopup}
            onClose={handleThresholdCancelPopup}
            cancelText='No'
            confirmText='Save & Continue'
          />
        </div>
      )}
      <DynamicTabLayout
        navItems={tabData}
        style
        value={'Research'}
        onTabChange={onTabChange}
        tabIndexSet={tabIndexSet}
        isDrawer={true}
        isDrawerCss={false}
        drawerLayoutClass=''
        baseRoute={`/projects/view-project/${query.get('id')}`}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setActiveTab={handleActiveTab}
        setTabIndex={handleTabIndex}
      >
        {showTab('Roadmap', 'Scope') && (
          <Box index='Roadmap' childIndex='Scope'>
            <Scope />
          </Box>
        )}
        {showTab('Roadmap', 'Experience and Competency') && (
          <Box index='Roadmap' childIndex='Experience and Competency' height={'100%'} width={'100%'}>
            <ExperienceAndCompetency projectId={paramsProjectId} positionProfile={positionProfile} />
          </Box>
        )}
        {showTab('Roadmap', 'Experience') && (
          <Box index='Roadmap' childIndex='Experience'>
            <Experience data={data} />
          </Box>
        )}
        {showTab('Roadmap', 'Competency') && (
          <Box index='Roadmap' childIndex='Competency'>
            <Competency />
          </Box>
        )}
        {showTab('Roadmap', 'Decisions') && (
          <Box index='Roadmap' childIndex='Decisions'>
            <Decision getValueFromUniversalState={getValueFromUniversalState} handleUniversalState={handleUniversalState} />
          </Box>
        )}
        {showTab('Research', 'Intelligent Search') && (
          <Box index='Research' childIndex='Intelligent Search'>
            <ProductOne projectData={data} />
          </Box>
        )}
        {showTab('Research', 'Applicants') && (
          <Box index='Research' childIndex='Applicants' sx={{ height: '100%' }}>
            <Sourced />
          </Box>
        )}

        {showTab('Candidate Pipeline', 'Candidate Pipeline') && (
          <Box index='Candidate Pipeline' childIndex='Candidate Pipeline'>
            <CandidatePipeline refreshDataCbRef={refreshDataCbRef} />
          </Box>
        )}
        {/* {showTab('Billing', 'LOE') && ( */}
        <Box index='Billing' childIndex='LOE' height={'100%'} width={'100%'}>
          <ValidatePermit path={DBData?.record_type === 'Business Development' ? 'bd_billing' : 'project_billing'} parent={'project'} type='message-only' permissionType={'read'}>
            <LOEPage heliaTitle={data?.helia_title} />
          </ValidatePermit>
        </Box>
        {/* )} */}
        {showTab('Overview', 'Details') && (
          <Box index='Overview' childIndex='Details'>
            <Stack direction='row' justifyContent={'flex-end'}>
              <CustomButton
                variant={'outlined'}
                type='tertiary'
                buttonText={t('actions.save')}
                onClick={() => {
                  handleSave(0);
                }}
              />
              <CustomButton
                variant={'contained'}
                buttonText={t('actions.saveAndNext')}
                onClick={async () => {
                  const response = await handleSave(0);
                  if (response === false) return;
                  handleNextTab(0, 1);
                }}
              ></CustomButton>
            </Stack>
            <BasicDetails
              register={register}
              setValue={setValue}
              // companyRef={companyRef}
              // userRef={userRef}
              watch={watch}
              currentValues={DBData}
              projectData={data}
              isProject={true}
              isEditing={true}
              clientContacts={clientContacts}
              setClientContacts={setClientContacts}
              // currencyDetails={currencyCodeDetails}
              // isHide={isHide}
            />
            ,
          </Box>
        )}

        {showTab('Overview', 'Team') && (
          <Box index='Overview' childIndex='Team'>
            <Stack direction='row' justifyContent={'flex-end'}>
              <CustomButton
                variant={'outlined'}
                type='tertiary'
                buttonText={t('actions.save')}
                onClick={() => {
                  handleSave(1);
                }}
              />
              <CustomButton
                variant={'contained'}
                buttonText={t('actions.saveAndNext')}
                onClick={async () => {
                  const isSaveSuccessful = await handleSave(1);
                  if (isSaveSuccessful) {
                    handleNextTab(0, 2);
                  }
                }}
              ></CustomButton>
            </Stack>
            <TeamInfo
              register={register}
              setValue={setValue}
              // userRef={userRef}
              watch={watch}
              control={control}
              currentValues={DBData}
              option={options}
              defaultStyle={{}}
            />
          </Box>
        )}

        {showTab('Overview', 'Client Contacts') && (
          <Box index='Overview' childIndex='Client Contacts'>
            <ClientContacts register={register} setValue={setValue} projectData={DBData} updateField={() => handleSave(2)} />
          </Box>
        )}

        {showTab('Roadmap', 'Position Profile') && (
          <Box index='Roadmap' childIndex='Position Profile'>
            <CustomModal
              open={isPositionProfileLoading}
              handleOpen={() => setIsPositionProfileLoading(false)}
              projectId={projectData.id || ''}
              isLoading={true}
              isMessage={true}
              isLive={true}
              title={t('project.generatingPositionProfile')}
            />
            <Stack direction='row' justifyContent={'space-between'} alignItems={'baseline'}>
              <div
                className='fs-12 my-2 info-panel'
                dangerouslySetInnerHTML={{
                  __html: positionProfileInfoText?.data && Array.isArray(positionProfileInfoText?.data) ? positionProfileInfoText?.data[0]?.long_desc : ''
                }}
              />
            </Stack>
            <Stack direction='row' justifyContent={'space-between'} alignItems={'baseline'}>
              <Stack direction='row' alignItems={'baseline'}>
                <TextField
                  className='my-3'
                  style={{ width: '30vw' }}
                  size='small'
                  label={t('project.internal_title')}
                  variant='outlined'
                  color='secondary'
                  type='text'
                  name='heliaTitle'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={watch('heliaTitle')}
                  defaultValue={watch('heliaTitle')}
                  onChange={e => {
                    setIsHeliaTitleChanged(true);
                    handleUniversalState(e);
                    setValue('heliaTitle', e.target.value);
                  }}
                />
                {watch('heliaTitle')?.length != 0 && isHeliaTitleChanged && (
                  <Box component='span' class='mx-2'>
                    <Box component='span'>
                      <DoneIcon className='table-edit-icon' htmlColor={'#23cea7'} onClick={handleSaveHeliaTitle}></DoneIcon>
                    </Box>
                    <Box component='span'>
                      <Box component='span' onClick={handleCloseHeliaTitle} className='delete-icon-pointer'>
                        <CloseIcon className='table-editclose-icon' htmlColor={'#c3423f'}></CloseIcon>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Stack>

              <Stack direction='row' justifyContent={'flex-end'}>
                <CustomButton
                  variant='outlined'
                  type='tertiary'
                  buttonText={t('actions.save')}
                  onClick={() => {
                    handleSavePositionProfile();
                  }}
                ></CustomButton>
                <CustomButton
                  variant='contained'
                  buttonText={t('actions.saveAndGenerateRoadmap')}
                  onClick={async () => {
                    await handleSavePositionProfile();
                    await handleCreateProject();
                    await handleTabChanges(1, 1);
                  }}
                ></CustomButton>
              </Stack>
            </Stack>
            <Stack direction='row' justifyContent={'space-between'}>
              <Box className='custom-personality-input'>
                <Box className='formParent flex justify-center align-center resume'>
                  <FileDragUpload
                    className={'position-profile-transcript'}
                    label={t('utils.clientIntakeCall')}
                    handleChangeDocument={handleChangeDocument}
                    width={'12vw'}
                    showFileName={fileUploaded}
                    horizontal={true}
                    customFileName={lastUploadedTranscript?.transcript_filename}
                    isDeleteConfirmation={true}
                    handleDeleteFile={handleDeleteFile}
                  />
                </Box>
              </Box>
              <Box>
                <Stack direction='row' justifyContent={'flex-end'}>
                  <CustomButton
                    variant='contained'
                    type='tertiary'
                    buttonText={watch('description') || positionProfile?.data?.description ? t('actions.reGenerate') : t('actions.generate')}
                    onClick={() => {
                      handleRegenerate();
                    }}
                  ></CustomButton>
                  <CustomButton
                    variant='outlined'
                    onClick={() => {
                      downloadPositionProfile();
                    }}
                    buttonText={'Download'}
                    startIcon={<FileDownloadOutlinedIcon color='primary.main' />}
                  ></CustomButton>
                </Stack>
              </Box>
            </Stack>
            <ViewPositionProfile data={data} positionProfile={positionProfile} setValue={setValue} watch={watch} />
          </Box>
        )}
        {showTab('Billing', 'Billing Info') && (
          <Box index='Billing' childIndex='Billing Info'>
            <ValidatePermit path={DBData?.record_type === 'Business Development' ? 'bd_billing' : 'project_billing'} parent={'project'} permissionType={'read'} type='message-only'>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} className='' style={{ marginBottom: '10px' }}>
                <ApproveRejectAction
                  handleApproval={searchStatusApproval}
                  triggerBillingWorkflow={triggerBillingWorkflow}
                  handleSave={handleSubmit(data => {
                    submitForApproval(data);
                    setApproveRejectData(data);
                  })}
                  isEditing={true}
                  location={location}
                  billingStatus={DBData?.billing_status}
                  search={DBData}
                  enqueueSnackbar={enqueueSnackbar}
                />

                <Stack direction='row' spacing={2}>
                  <CustomButton
                    variant='outlined'
                    type='tertiary'
                    buttonText={t('actions.save')}
                    onClick={() => {
                      handleSave(3);
                    }}
                    disabled={DBData.billing_status === 'PND_RHEAD'}
                  />
                  <CustomButton
                    variant='contained'
                    buttonText={t('actions.saveAndNext')}
                    onClick={async () => {
                      await handleSave(3);
                      handleNextTab(5, 1);
                    }}
                    disabled={DBData.billing_status === 'PND_RHEAD'}
                  />
                </Stack>
              </Stack>

              <BillingInfo
                register={register}
                setValue={setValue}
                // userRef={userRef}
                watch={watch}
                control={control}
                currentValues={DBData}
                getValues={getValues}
                isEditing={true}
                isDisabled={DBData?.billing_status && DBData.billing_status === 'PND_RHEAD'}
              />
            </ValidatePermit>
          </Box>
        )}
        {showTab('Billing', 'Invoice Info') && (
          <Box index='Billing' childIndex='Invoice Info'>
            <ValidatePermit path={DBData?.record_type === 'Business Development' ? 'bd_billing' : 'project_billing'} parent={'project'} permissionType={'read'} type='message-only'>
              <InvoiceInfo
                register={register}
                setValue={setValue}
                // userRef={userRef}
                watch={watch}
                control={control}
                currentValues={DBData}
                setCurrentData={setCurrentData}
                getValues={getValues}
                isEditing={true}
              />
            </ValidatePermit>
          </Box>
        )}

        {showTab('Documents', 'Documents') && (
          <Box index='Documents' childIndex='Documents'>
            <ValidatePermit path='project_doc' type='message-only' parent={'project'} permissionType={'read'}>
              <ProjectDocuments />
            </ValidatePermit>
          </Box>
        )}
        {showTab('Activity History', 'Activity History') && (
          <Box index='Activity History' childIndex='Activity History'>
            <ProjectActivities />
          </Box>
        )}
        {/* {showTab('Approvals', 'Pending Approval') && (
          <Box index='Approvals' childIndex='Pending Approval'>
            <ProjectApprovalPending />
          </Box>
        )}
        {showTab('Approvals', 'IRejected') && (
          <Box index='Approvals' childIndex='Rejected'>
            <ProjectRejected />
          </Box>
        )} */}
        {showTab('Client Portal', 'Invite to Client Portal') && (
          <Box index='Client Portal' childIndex='Invite to Client Portal'>
            <InviteToClientPortal />
          </Box>
        )}
        {showTab('Client Portal', 'General Setup') && (
          <Box index='Client Portal' childIndex='General Setup'>
            <ClientPortalMenuSetup />
          </Box>
        )}
        {showTab('Client Portal', 'Stage Grouping') && (
          <Box index='Client Portal' childIndex='Stage Grouping'>
            <StageGrouping />
          </Box>
        )}
      </DynamicTabLayout>
    </Box>
  );
}

ProjectTabLayout.propTypes = {
  getValueFromUniversalState: PropTypes.func,
  handleUniversalState: PropTypes.func,
  data: PropTypes.object,
  DBData: PropTypes.object,
  getBD: PropTypes.func,
  setCurrentData: PropTypes.func,
  PositionProfileData: PropTypes.object,
  refreshDataCbRef: PropTypes.object,
  paramsProjectId: PropTypes.string,
  openPipelineTab: PropTypes.bool
};

export default React.memo(ProjectTabLayout);
