//----------------------------------------------// In-built Imports //-------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, DialogContent, Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertToLocalTimeAndDate } from '../../../../utils/date';
import CustomButton from '../../../common/CustomButton';
import CustomPopup from '../../../common/CustomPopup';
import '../../index.scss';
import NotesDrawer from './NotesDrawer';

const notesData = {
  noteType: 'published_bio',
  approved: 'is_bio_approved_by_partner'
};

const BioAndRecommendation = props => {
  const {
    register = () => {},
    setValue = () => {},
    handleSave = () => {},
    currentValues,
    isContactView = false,
    unregister,
    setPersonalityField,
    setSelectedItem,
    setIsConfirmPopupOpen,
    onPublishHandler,
    isLoading
  } = props;

  const [isBioPopupOpen, setIsBioPopupOpen] = useState(false);
  const [bioData, setBioData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isInlineEditing, setIsInLineEditing] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const isInlineEditingRef = useRef();
  isInlineEditingRef.current = isInlineEditing;
  const { t } = useTranslation();

  const notesTypeLabel = { label: t('utils.publishedBio') };

  const handleBioOpenPopup = () => {
    unregister('activity_type');
    setPersonalityField(false);
    setIsBioPopupOpen(true);
  };

  useEffect(() => {
    const convertData = () => {
      let newData = [];
      if (currentValues && currentValues.length) {
        currentValues.forEach(itemEntry => {
          if (itemEntry.notes_type === notesData.noteType || itemEntry.notes_type === 'candidate_snapshot') {
            newData.push({
              [notesData.noteType]: itemEntry.notes,
              [notesData.approved]: itemEntry.is_partner_approved,
              is_partner_approved: itemEntry.is_partner_approved,
              notes_type: itemEntry.notes_type,
              notes: itemEntry.notes,
              note_id: itemEntry?.id || null,
              id: itemEntry?.id || null,
              seq: itemEntry?.seq,
              updated_at: itemEntry?.updated_at,
              contact_id: itemEntry?.contact_id
            });
          }
        });
      }
      return newData;
    };
    setBioData(convertData());
    setIsBioPopupOpen(false);
  }, [currentValues]);

  const handleBioSubmit = async () => {
    if (isEditing || isInlineEditingRef.current) {
      await handleSave(isContactView ? 'contact_notes_all' : undefined, false);
      setIsEditing(false);
      setIsInLineEditing(false);
    } else {
      await handleSave(isContactView ? 'contact_notes_all' : undefined, false);
    }
  };

  const editItems = () => {
    unregister('activity_type');
    setPersonalityField(false);
    setIsEditing(true);
  };

  const handleDelete = item => {
    setSelectedItem(item);
    setIsConfirmPopupOpen(true);
  };

  return (
    <Box className='personality_sub flex flex-column w-100'>
      <Grid container className='d-flex justify-content-between align-items-center'>
        <Typography className='compensation-info' variant='body1' sx={{ color: 'var(--palette-primary-main)', fontWeight: '700' }}>
          {notesTypeLabel.label}
        </Typography>
        {bioData.length > 0 && (
          <Grid item xs={3}>
            <Box className='top-level personality-action-icons cursor-pointer'>
              <EditIcon onClick={editItems} className='pr-2 pt-1' sx={{ width: 24, height: 24 }} />
              <DeleteOutlineIcon className='pr-2 pt-1' color='error' sx={{ width: 24, height: 24 }} onClick={() => handleDelete(bioData[0])} />
            </Box>
          </Grid>
        )}
      </Grid>
      {bioData.length > 0 &&
        bioData.map((text, index) => (
          <span key={index}>
            <div className='w-full max-w-sm'>
              <Box
                className='text-break fs-12 overflow-hidden text-ellipsis whitespace-nowrap'
                sx={{
                  display: '-webkit-box',
                  flex: 1,
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  marginLeft: '8px'
                }}
                dangerouslySetInnerHTML={{
                  __html: text?.published_bio.substring(0, 300) || '-'
                }}
              />
              {text?.published_bio.length > 300 && (
                <Link component='button' variant='body2' className='show-more-link fs-12' sx={{ whiteSpace: 'nowrap', ml: 1 }} onClick={() => setIsViewMore(true)}>
                  {t('common.viewMore')}
                </Link>
              )}
            </div>
            <Box className='personality-timestamp'>
              <Typography className='timestamp-text'>{text?.updated_at && convertToLocalTimeAndDate(text?.updated_at)}</Typography>
              <Button className='personality-publish-button' disabled={isLoading} onClick={() => onPublishHandler(text, !text?.is_partner_approved)}>
                <Typography className='button-text'>{text?.is_partner_approved ? t('project.projectUnPublish') : t('project.publish')}</Typography>
                <InfoOutlinedIcon className='personality-info-icon' />
              </Button>
            </Box>
          </span>
        ))}
      <Box className='d-flex w-100'>
        {bioData.length === 0 && (
          <Box className='add-another-buttons'>
            <CustomButton
              type='tertiary'
              variant='outlined'
              size='small'
              buttonText={`${t('actions.add')} ${notesTypeLabel.label}`}
              customWidth={96}
              onClick={handleBioOpenPopup}
              buttonWrapperClassName='font-medium underline'
              sx={{ textDecoration: 'underline' }}
              fontWeight='600'
              underLine
              iconLeft={<AddIcon sx={{ width: 22 }} />}
            />
          </Box>
        )}
        {isBioPopupOpen && (
          <NotesDrawer
            title={notesTypeLabel.label}
            setIsPopupOpen={setIsBioPopupOpen}
            isPopupOpen={isBioPopupOpen}
            onSubmit={handleBioSubmit}
            notesData={notesData}
            setValue={setValue}
            register={register}
          />
        )}
        {isEditing && (
          <NotesDrawer
            title={notesTypeLabel.label}
            setIsPopupOpen={setIsEditing}
            isPopupOpen={isEditing}
            onSubmit={handleBioSubmit}
            isEditing
            data={bioData[0]}
            defaultValueString={bioData[0]?.published_bio}
            notesData={notesData}
            setValue={setValue}
            register={register}
          />
        )}
      </Box>
      {isViewMore && (
        <CustomPopup onClose={() => setIsViewMore(false)} aria-labelledby='customized-dialog-title' open={isViewMore} title={t('utils.notes')}>
          <DialogContent className='content-value'>
            <Typography variant='body2' className='card-containt view-text' dangerouslySetInnerHTML={{ __html: bioData[0]?.published_bio || '-' }}></Typography>
          </DialogContent>
        </CustomPopup>
      )}
    </Box>
  );
};

BioAndRecommendation.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  currentValues: PropTypes.array,
  isContactView: PropTypes.bool,
  setLoading: PropTypes.func,
  unregister: PropTypes.func,
  setPersonalityField: PropTypes.func,
  setSelectedItem: PropTypes.func,
  setIsConfirmPopupOpen: PropTypes.func,
  onPublishHandler: PropTypes.func,
  isLoading: PropTypes.bool
};

export default BioAndRecommendation;
