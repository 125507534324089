//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { picklistsDataApi } from '../../services/ApiService';
import { GET } from '../../services/constantService';
import { SEARCH_TIMEOUT } from '../../utils/common';
import InputField from '../common/InputField';

export default function CompanySelection(props) {
  const { label = 'Company', placeholder = 'Company', InputLabelProps, required, disabled = false, variant = 'standard', ...rest } = props;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (value?.length <= 2) {
      setOptions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    const timer = setTimeout(() => {
      const name = encodeURIComponent(value);
      const sub_route = `companies?name=${name}`;
      picklistsDataApi(GET, '', sub_route).then(response => {
        const { status, data } = response;
        if (status === 200 && data?.data?.length > 0) {
          setOptions(data.data);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            {option.name}
            {/* {option.inputValue && (
              <div style={{ width: '100%', borderTop: '1px solid #dfdfdf' }}>
              <Button
                variant={"text"}
                // startIcon={<AddCircleIcon />}
                color='primary'>
                ADD NEW COMPANY
              </Button>
            </div>
            )} */}
          </li>
        );
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      filterOptions={options => options}
      disabled={disabled}
      renderInput={params => (
        <InputField
          {...params}
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          required={required}
          variant={variant}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

CompanySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string
};
