import AccessTimeIcon from '@mui/icons-material/History';
import { Box, Checkbox, CircularProgress, Divider, Link, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import FlipCard from '../Commons/FlipCard';
// internal imports
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/common/CustomButton';
import { PROJECT_SCREEN, ROUTES } from '../../services/constantService';
import convert from '../../utils/currencyConvertor';
import { calculateNumberOfDays, formatDateWithMomentTimeZone } from '../../utils/date';
import styles from '../../variables.scss';
import Pipeline from './../../assets/icons/pipeline.svg';
import fbPicture from './../../assets/images/fb.png';
import CompanyImageAvatar from './CompanyImageAvatar';
import { PAGE_LIMIT } from './Utils';
import { PROJECT_CONSTANTS } from './ViewProject/Constant';

const CardViewContainer = props => {
  const { projectsLoader, initialFetch, setPage, allProjects = [], viewButton } = props;
  const loaderRef = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLinkView = project => {
    const { id, stage } = project;
    let pathWithId = ROUTES.viewProject.replace(':id', id);
    if (viewButton == PROJECT_SCREEN.PROJECT) {
      if (stage == PROJECT_CONSTANTS.DRAFT) {
        let pathWithTab = ROUTES.viewProjectWithDeepLink;
        pathWithTab = pathWithTab.replace(':id', id);
        pathWithTab = pathWithTab.replace(':parentTab', 'Roadmap');
        pathWithTab = pathWithTab.replace(':subTab', 'Position Profile');
        navigate({ pathname: pathWithTab }, { state: { draft: true } });
      } else {
        let pathWithTab = ROUTES.viewProjectWithDeepLink;
        pathWithTab = pathWithTab.replace(':id', id);
        pathWithTab = pathWithTab.replace(':parentTab', 'Candidate Pipeline');
        pathWithTab = pathWithTab.replace(':subTab', 'null');
        navigate(pathWithTab);
      }
    } else {
      navigate(pathWithId);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (projectsLoader) return;
      if (entries?.[0]?.isIntersecting && !initialFetch) {
        setPage(prevPage => prevPage + 1); // Trigger loading more projects
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, initialFetch, projectsLoader]);

  const formatLocation = location => {
    if (!location) return '';
    return location.replace(/^, +|, +$/g, '').trim();
  };

  return (
    <>
      <Stack
        direction='row'
        alignItems='flex-start'
        flexWrap='wrap'
        display={'flex'}
        gap={1}
        ml={'15px'}
        sx={{
          my: 0
        }}
      >
        {allProjects.map((project, index) => (
          <FlipCard
            key={index}
            style={{
              width: '32%',
              maxWidth: '32%',
              minWidth: '400px',
              // minHeight: "420px",
              padding: '0'
            }}
            className='border shadow rounded'
          >
            {/* Front Card */}
            <Stack direction='column' alignItems='stretch'>
              {/* top */}

              <Box display='flex' flexDirection='row' className='p-1 pb-2'>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' className='w-100'>
                  <Box display='flex' flexDirection='row' alignItems='center' gap={1} sx={{ height: '100%', width: '100%' }}>
                    <CompanyImageAvatar
                      alt={project?.ign_companies?.name}
                      sx={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '10%'
                      }}
                      id={project?.ign_companies?.id}
                    />

                    <Box display='flex' overflow={'hidden'} flexDirection='column' width={'100%'}>
                      <Box display='flex' justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='caption' lineHeight='1' sx={{ cursor: 'pointer' }} onClick={() => handleLinkView(project)}>
                          {project?.ign_companies?.name}
                        </Typography>
                        <Box bgcolor={'var(--palette-primary-main)'} borderRadius={'4px'} px={0.6} py={0.2}>
                          <Typography color='white' fontSize={11} fontWeight={600} whiteSpace='nowrap'>
                            {project?.record_type === 'Project' ? project?.stage || 'NA' : project?.bd_status_value?.short_desc || 'NA'}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          maxWidth: '100%',
                          marginY: 0.5,
                          cursor: 'pointer',
                          fontWeight: 600,
                          fontSize: 16,
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          textOverflow: 'ellipsis'
                        }}
                        onClick={() => handleLinkView(project)}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <Tooltip title={project?.job_title}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: 16,
                                maxWidth: '80%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {project?.job_title}
                            </Typography>
                          </Tooltip>
                          <Link
                            sx={{
                              color: 'var(--palette-heading-main)',
                              cursor: 'pointer',
                              fontSize: 14,
                              width: '50px',
                              textDecoration: 'none',
                              textAlign: 'right',
                              '&:hover': {
                                textDecoration: 'none',
                                color: 'var(--palette-heading-main)'
                              }
                            }}
                            onClick={event => {
                              event.stopPropagation(); // Prevent parent's onClick from firing
                              handleLinkView(project);
                            }}
                          >
                            <span
                              style={{
                                textUnderlineOffset: '4px',
                                color: 'var(--palette-heading-main)',
                                cursor: 'pointer',
                                fontSize: 13,
                                textDecoration: 'underline'
                              }}
                            >
                              ({project?.job_number})
                            </span>
                          </Link>
                        </Box>
                      </Box>
                      <Tooltip title={formatLocation(project?.location)}>
                        <Typography width={'100%'} lineHeight='1' variant='caption' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                          {formatLocation(project?.location) || '\u00A0'}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Middle  */}
              <Stack direction='column' fullWidth sx={{ paddingX: 0.5 }}>
                <Divider />
                <Stack direction='row' marginY={0.5} justifyContent='space-between' alignItems='center'>
                  <Box fullWidth>
                    <AccessTimeIcon sx={{ fontSize: '20px', color: 'var(--palette-primary-main)', marginRight: 0.5 }} />
                    <Typography variant='caption' fontSize={13}>
                      {t('project.started')}:
                      <Typography fontWeight={600} fontSize={12.5} component='span' variant='caption'>
                        {' '}
                        {formatDateWithMomentTimeZone(project?.projected_start_date) || '--'}
                      </Typography>
                    </Typography>
                  </Box>
                  {project.record_type === 'Project' ? (
                    <Box display={'flex'} alignItems={'center'} gap={0.6}>
                      <img
                        src={Pipeline}
                        style={{
                          width: '14px',
                          height: '14px'
                        }}
                      />
                      <Typography variant='caption' fontSize={13}>
                        {t('project.in_pipeline')}:
                        <Typography fontWeight={'bold'} fontSize={13} component='span' variant='caption'>
                          {' '}
                          {project?.candidate_count || 0}
                        </Typography>
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant='caption' fontSize={13}>
                      {t('project.estimated_revenue')}:
                      <Typography component='span' fontSize={13} fontWeight={'bold'} variant='caption'>
                        {' '}
                        {`${project?.bd_currency_values?.currency_icon && project?.estimated_revenue ? project?.bd_currency_values?.currency_icon + parseInt(project?.estimated_revenue, 10) : '--'}`}
                      </Typography>
                    </Typography>
                  )}
                </Stack>
                <Divider />
              </Stack>
              {/* Bottom */}
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1} sx={{ marginY: 0.5 }}>
                <Typography variant='caption' fontSize={12} color={styles.borderColor} sx={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className='ml-1'
                  >
                    {t('project.updated')} {calculateNumberOfDays(project?.updated_at)} {t('project.days_ago')}
                    {project?.updater?.name && (
                      <>
                        <Typography variant='caption' fontSize={12} color={styles.borderColor} component='span' className='ml-1 project-user-sub-footer-text'>
                          {t('project.by')}
                        </Typography>
                        <Typography variant='subtitle2' fontSize={12} color={styles.borderColor} fontWeight={600} component={'span'} className='ml-1 project-user-sub-footer-text'>
                          {project?.updater?.name}
                        </Typography>
                      </>
                    )}
                  </div>
                </Typography>
              </Stack>
            </Stack>

            {/* Back Card */}
            <Stack direction='column' justifyContent='space-between' alignItems='stretch'>
              <Stack direction='row' className='p-1'>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} className='w-100'>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <img
                      src={fbPicture}
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%'
                      }}
                    />

                    <Stack sx={{ marginLeft: '4px !important' }} spacing={0} rowGap={0}>
                      <Typography variant='caption' display='block' lineHeight='1'>
                        {project?.ign_companies?.name}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        display='block'
                        lineHeight='1.25'
                        // sx={{ fontWeight: "900" }}
                      >
                        {project?.job_title}
                      </Typography>
                      <Typography lineHeight='1' variant='caption' display='block'>
                        {project?.location}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction='column' alignItems={'center'}>
                  <Checkbox />
                  <Link sx={{ cursor: 'pointer' }} onClick={() => handleLinkView(project)} className=''>
                    {project?.job_number}
                  </Link>
                </Stack>
              </Stack>

              {/* Middle  */}
              <Stack direction='column' justifyContent='space-between' alignItems='stretch' sx={{ width: '100%' }}>
                <Divider />
                <Box className='p-1'>
                  <Typography variant='caption'>Pending Billables</Typography>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={0.5}>
                      <CustomButton variant='contained' size='small' disabled buttonText={'Outstanding'} />
                      <Typography variant='caption' color='error.main'>
                        {/* 
                      <LocalAtmOutlinedIcon fontSize="small" />
                      <b>USD {""}</b> */}
                        ${convert(30000)}{' '}
                      </Typography>
                    </Stack>
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={0.5}>
                      <CustomButton variant='contained' size='small' disabled buttonText={'Next Billable'} />
                      <Typography variant='caption' color='error.main'>
                        ${convert(14000)}{' '}
                      </Typography>
                    </Stack>
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={0.5}>
                      <CustomButton disabled variant='contained' size='small' buttonText={'Overdue'} />
                      <Typography variant='caption' color='error.main'>
                        ${convert(3400)}{' '}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                {/* <Box>
                <Box>Milestone</Box>
                <Stepper activeStep={1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box> */}
                <Divider />
              </Stack>

              {/* Bottom */}
              <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1} sx={{ margin: '4px' }}>
                <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                  <AccessTimeIcon sx={{ fontSize: '20px', color: 'var(--palette-primary-main)' }} />
                  Posted {calculateNumberOfDays(project?.created_at)} Days
                  <Typography variant='caption' component='span' className='ml-1'>
                    Ago by
                  </Typography>
                </Typography>
                <Typography variant='subtitle2' color='primary.main'>
                  Anirudh Gupta
                </Typography>
              </Stack>
            </Stack>
          </FlipCard>
        ))}
      </Stack>
      <Box
        ref={loaderRef}
        my={4}
        sx={{
          height: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {projectsLoader && !initialFetch && allProjects?.length >= PAGE_LIMIT && (
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <CircularProgress size={'1.5rem'} />
            <Typography fontWeight={600} fontSize={14}>
              {t('project.loading_more_projects')}
            </Typography>
          </Box>
        )}
        {!projectsLoader && allProjects.length === 0 && (
          <Typography variant='caption' color='text.secondary'>
            {t('project.no_projects_available')}
          </Typography>
        )}
      </Box>
    </>
  );
};

CardViewContainer.propTypes = {
  allProjects: PropTypes.array,
  viewButton: PropTypes.string,
  page: PropTypes.number,
  setPage: PropTypes.func,
  projectsLoader: PropTypes.bool,
  initialFetch: PropTypes.bool
};
export default CardViewContainer;
