import propTypes from 'prop-types';

import { Box } from '@mui/material';
import React from 'react';
import GaugeScale from './GaugeScale';
import Pointer from './Pointer';

const calculateRotation = value => {
  const minValue = 0;
  const maxValue = 5;
  if (value == minValue || value <= minValue) {
    return 270;
  } else if (value >= maxValue) {
    return 90;
  }
  if (value >= 1 && value <= 3) {
    // Map range [1, 3] to [290, 0]
    return 290 + ((value - 1) * (360 - 290)) / (3 - 1);
  } else if (value > 3 && value <= 5) {
    // Map range [3, 5] to [0, 75]
    return 0 + ((value - 3) * (75 - 0)) / (5 - 3);
  }
};

const ScoreCardGauge = ({ value = 0, width = '150px' }) => {
  let valueToUse = value;
  if (value < 1) {
    valueToUse = 0;
  }
  const rotation = calculateRotation(valueToUse);
  return (
    <Box display='flex' flexDirection='column' alignItems='center' width={width} position='relative'>
      <GaugeScale />
      <Box
        position='absolute'
        bottom={0}
        sx={{
          transformOrigin: 'bottom center',
          transform: `rotate(${rotation}deg)`
        }}
      >
        <Pointer />
      </Box>
    </Box>
  );
};

ScoreCardGauge.propTypes = {
  value: propTypes.number,
  width: propTypes.string
};
export default ScoreCardGauge;
