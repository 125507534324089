//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
// import { makeStyles, withStyles } from '@mui/styles';
// import StepConnector from '@mui/material/StepConnector';
import Button from '@mui/material/Button';

// ----------------------------------------------// Internal Imports // -------------------------------------------------
import { Box, StepConnector } from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomButton from '../common/CustomButton/index.js';
import CustomStepper from '../common/CustomStepper.js';
import './index.scss';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    //   top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#009d81 !important'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#009d81 !important'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#c3c3c35c',
    borderRadius: 1
  }
})(StepConnector);

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index && <React.Fragment>{children}</React.Fragment>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const TabLayoutWithSteppers = props => {
  const {
    children,
    navItems,
    tabChange,
    onCancel,
    onClose,
    fieldItem = '',
    handleTabValue = () => {},
    showTab,
    editPopup = '',
    bd,
    tabValueNumber,
    setTabValueNumber = () => {},
    steppersProps = {}
  } = props;

  const [tabValue, setTabValue] = useState(showTab ? showTab : 0);

  const handleNext = () => {
    if (tabValue < 2) {
      handleChange(tabValue + 1);
    }
    if (tabValue === 2) {
      handleChange(tabValue + 1, 'saveButton');
    }
  };
  const handleBack = () => {
    handleChange(tabValue - 1, '', true);
  };

  const handleChange = async (newValue, saveButton = '', isBack = false) => {
    try {
      if (tabValue === newValue) return;
      if (fieldItem !== 'ViewBd') {
        // const result = await tabChange(tabValue, newValue, saveButton)
        const result = await tabChange(tabValue, newValue, saveButton, isBack);
        if (result && newValue !== navItems?.length) {
          setTabValue(newValue);
          handleTabValue(newValue);
        }
        if (result) {
          if (tabValue === 2 && saveButton === 'saveButton') {
            onClose();
          }
          return;
        }
      } else {
        setTabValue(newValue);
        handleTabValue && handleTabValue(newValue);
      }
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  const buildTabDetails = () => {
    return children
      ?.filter(item => item)
      .map((item, index) => {
        return (
          <TabPanel key={index} index={index} value={tabValue}>
            {item}
          </TabPanel>
        );
      });
  };

  useEffect(() => {
    if (tabValueNumber || tabValueNumber === 0) {
      setTabValue(tabValueNumber);
      setTabValueNumber(null);
    }
  }, [tabValueNumber]);
  return (
    <>
      <Box sx={{ width: '80vw' }} className='mt-2'>
        <Box className='container d-flex align-items-center justify-content-between tab-info-container'>
          <Box className={`${tabValue === 0 ? 'invisible' : null} align-values w-25 text-start`}>
            <CustomButton disabled={tabValue === 0} type={'secondary'} onClick={handleBack} buttonText={'< Back'} width='10vw' />
          </Box>
          <Box sx={{ width: '40vw' }}>
            {bd && bd.billing_status !== null && bd.billing_status !== 'None' && <Box className='status-message'>{bd?.billing_status_desc?.short_desc}</Box>}
            <CustomStepper {...steppersProps} navItems={navItems} tabValue={tabValue} handleChange={handleChange} connector={<ColorlibConnector />}></CustomStepper>
          </Box>
          <Box className='d-flex align-values w-25 text-end'>
            {editPopup === 'editPopup' && (
              <Button onClick={onCancel} className={''}>
                <b className='cancel-button'>Cancel</b>
              </Button>
            )}
            <Box className={`${fieldItem === 'ViewBd' && tabValue === navItems.length - 1 && 'invisible'}`}>
              <CustomButton
                onClick={handleNext}
                //   variant="text"
                buttonText={fieldItem === 'ViewBd' ? tabValue < 2 && 'Next >' : tabValue === navItems?.length - 1 ? 'Save' : 'Save & Next >'}
                width='10vw'
                //   style={{ background: tabValue === navItems?.length - 1 && fieldItem !== "ViewBd" ? variables.primary : "transparent", color: tabValue === navItems?.length - 1 ? variables.titleBarBackground : variables.text, border: tabValue === navItems?.length - 1 && fieldItem !== "ViewBd" ? null : "none"}}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <div className='add-bd-content w-100'>{buildTabDetails(children)}</div>
    </>
  );
};

TabLayoutWithSteppers.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.array,
  tabChange: PropTypes.func,
  handleTabValue: PropTypes.func,
  onCancel: PropTypes.func,
  setTabValueOfBusinessDev: PropTypes.func,
  onClose: PropTypes.func,
  statusMessage: PropTypes.string,
  bd: PropTypes.object,
  fieldItem: PropTypes.string,
  tabNumber: PropTypes.number,
  showTab: PropTypes.number,
  editPopup: PropTypes.string,
  steppersProps: PropTypes.object,
  tabValueNumber: PropTypes.number,
  setTabValueNumber: PropTypes.func
};

export default TabLayoutWithSteppers;
