import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, IconButton, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import TotalCount from '../../../components/common/TotalCount';
import { dashboardDataApi } from '../../../services/ApiService';
import { API, ERROR, GET, POST, PUT, SUCCESS, WARNING } from '../../../services/constantService';
import { notFoundMessage } from '../../../services/MessageService';
import AgGridWrapper from '../../../utils/AgGridWrapper';
import '../index.scss';
import AddTranslateValue from './AddTranslateValue';
import { PAGE_LIMIT, columnDefs, getFilterParamStringForTranslateValue } from './utils';
import ViewTranslateValueDrawer from './ViewTranslateValueDrawer';

const TranslateValueSetupList = () => {
  const gridApi = useRef(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const { getValues, setValue, register, unregister, watch } = useForm({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backMenu = () => {
    navigate('/productSetup');
  };

  const { enqueueSnackbar } = useSnackbar();
  const [translateData, setTranslateData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [deboucedSearchValue, setDebouncedSearchValue] = useState('');
  const previousSearchRef = useRef('');

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
    gridApi.current = params;
  };

  const timerRef = useRef();
  const paramsRef = useRef();
  const dataToFilter = useRef();

  const handleSearchChange = event => {
    const { value } = event.target;
    setSearchValue(value);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setDebouncedSearchValue(value);
    }, 500);
  };

  const dataSource = useMemo(() => {
    return {
      getRows: async params => {
        const { filterModel, sortModel } = params;
        paramsRef.current = params;

        const isOnlyContainsFilter = Object.keys(filterModel).length > 0 && Object.values(filterModel).every(filter => filter.filterType === 'multi');
        let isSearched = false;

        if (previousSearchRef.current !== deboucedSearchValue) {
          previousSearchRef.current = deboucedSearchValue;
          isSearched = true;
        }

        if (isOnlyContainsFilter && !isSearched) {
          return applyFilters(dataToFilter.current?.rows, params);
        }

        let paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
        let url = '';
        url = `${API.product_setting}/translate_value`;

        gridApi.current?.api?.showLoadingOverlay();

        if (sortModel?.length > 0) {
          paginationString = paginationString.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
        }

        if (searchValue) {
          paginationString = paginationString.concat(`&searchValue=${searchValue}`);
        }

        if (Object.keys(filterModel)?.length) {
          paginationString = paginationString.concat(getFilterParamStringForTranslateValue(filterModel));
        }

        await getRows(url, paginationString, enqueueSnackbar, params, setRowCount, gridApi, dataToFilter, isOnlyContainsFilter);
      }
    };
  }, [deboucedSearchValue]);

  const updateStatus = async params => {
    const value = params.getValue();
    let url = '';
    url = `${API.product_setting}/translate_value/${params?.data?.id}`;
    gridApi.current?.api?.showLoadingOverlay();

    const { status } = await dashboardDataApi(PUT, url, '', { ...params.data, is_active: !value });
    gridApi.current?.api?.hideOverlay();

    if (status === 200) {
      params.setValue(!value);
      return;
    }
    return enqueueSnackbar('Failed to update status', { variant: ERROR });
  };

  const updateTranslateValue = async () => {
    const body = getValues();
    let url = '';
    url = `${API.product_setting}/translate_value/${body?.id}`;
    gridApi.current?.api?.showLoadingOverlay();

    const { status } = await dashboardDataApi(PUT, url, '', body);
    gridApi.current?.api?.hideOverlay();
    if (status === 200) {
      enqueueSnackbar('Translate value updated successfully', { variant: SUCCESS });
      setIsDrawerOpen(false);
      setIsEdited(false);
      const rowNode = gridApi.current.api.getRowNode(body.id);
      rowNode?.setData(body);
      return;
    }
    return enqueueSnackbar('Failed to update!', { variant: ERROR });
  };

  const ActionsRenderer = params => {
    return (
      <Switch
        checked={params?.data?.is_active}
        inputProps={{ 'aria-label': 'controlled' }}
        onChange={e => {
          e.stopPropagation();
          updateStatus(params);
        }}
      />
    );
  };

  const translateViewRenderer = params => {
    return (
      <Typography
        className='text-link'
        color='primary'
        onClick={() => {
          setTranslateData(params.data);
        }}
      >
        {params.value}
      </Typography>
    );
  };

  const handleSaveTranslateValues = async () => {
    const body = getValues();

    if (!body?.field_name) {
      return enqueueSnackbar('Field Name is mandatory', { variant: ERROR });
    }
    if (!body?.field_value) {
      return enqueueSnackbar('Field Value is mandatory', { variant: ERROR });
    }
    let url = '';
    url = `${API.product_setting}/translate_value`;
    const { status, data } = await dashboardDataApi(POST, url, '', body);
    if (status === 200) {
      setRowCount(rowCount + 1);
      enqueueSnackbar('Translate value added successfully', { variant: SUCCESS });
      gridApi.current.api.applyTransaction({ add: [{ ...body, id: data?.id }], addIndex: 0 });
      setIsPopupOpen(false);
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        height: '82vh'
      }}
    >
      <Box height='75%' paddingX='20px'>
        <Box className='mb-3 pt-3'>
          <CustomButton
            onClick={() => backMenu()}
            buttonText={t('actions.back')}
            type={'tertiary'}
            size={'small'}
            variant=''
            width={42}
            customWidth={42}
            iconLeft={<KeyboardBackspaceIcon sx={{ width: 20, height: 20 }} />}
          />
        </Box>

        <div className='d-flex justify-content-between mb-3'>
          <div>
            <TextField
              size='small'
              label='Search'
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>

          <Button variant='contained' className='text-capitalize' startIcon={<AddIcon className='icon-size' />} onClick={() => setIsPopupOpen(true)}>
            {t('utils.addTranslateValues')}
          </Button>
        </div>
        <Box className='d-flex justify-content-end mb-3'>
          <TotalCount rowCount={rowCount} />
        </Box>
        <Box className='ag-theme-alpine ag-theme-alpine-1 text-center' height={'100vh'} style={{ width: '100%', height: '100%', overflowX: 'scroll' }}>
          <AgGridWrapper
            ref={gridApi}
            onGridReady={onGridReady}
            autoSizStrategy={{ type: 'fitGridWidth' }}
            enableBrowserTooltips={true}
            defaultColDef={{
              width: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null],
              cellStyle: {
                display: 'flex',
                alignItems: 'center'
              }
            }}
            tooltipShowDelay={0}
            cacheBlockSize={PAGE_LIMIT}
            components={{
              ActionsRenderer: ActionsRenderer,
              translateViewRenderer: translateViewRenderer
            }}
            alwaysShowHorizontalScroll
            suppressHorizontalScroll={false}
            suppressRowClickSelection={true}
            columnDefs={columnDefs()}
            datasource={dataSource}
            rowSelection={'multiple'}
            rowModelType={'infinite'}
            getRowId={data => data?.data.id}
            onCellClicked={params => {
              setIsDrawerOpen(true);
              setTranslateData(params.data);
            }}
            noRowsOverlayComponent={'No records found'}
            paginationPageSize={50}
            rowGroupPanelShow='always'
          />
        </Box>
        <AddTranslateValue
          setIsPopupOpen={setIsPopupOpen}
          isPopupOpen={isPopupOpen}
          saveData={handleSaveTranslateValues}
          setValue={setValue}
          register={register}
          unregister={unregister}
          watch={watch}
        />
        <ViewTranslateValueDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          data={translateData}
          gridApi={gridApi.current}
          setValue={setValue}
          register={register}
          unregister={unregister}
          onSubmit={updateTranslateValue}
          watch={watch}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
        />
      </Box>
    </div>
  );
};

export default TranslateValueSetupList;

async function getRows(url, paginationString, enqueueSnackbar, params, setRowCount, gridApi, dataToFilter, isOnlyContainsFilter) {
  const { status, data } = await dashboardDataApi(GET, url, paginationString);
  if (status === 200 && data?.data) {
    if (data?.data?.count === 0) {
      const message = notFoundMessage('records');
      enqueueSnackbar(message, { variant: WARNING });
    }
    dataToFilter.current = data?.data;

    params.successCallback(data?.data?.rows, data?.data?.count);
    setRowCount(data?.data?.count);
    if (isOnlyContainsFilter) {
      applyFilters(dataToFilter.current?.rows, params);
    }
  }
  gridApi.current?.api?.hideOverlay();
}

function applyFilters(dataToFilter, params) {
  const { sortModel, filterModel, startRow, endRow } = params;

  const filteredRows = dataToFilter?.filter(row =>
    Object.entries(filterModel).every(([field, filter]) => {
      const filterTypeContains = filter?.filterModels?.find(fil => fil?.type === 'contains');
      const filterTypeOr = filter?.filterModels?.find(fil => fil?.operator === 'OR');
      const filterTypeAnd = filter?.filterModels?.find(fil => fil?.operator === 'AND');

      if (filterTypeContains) {
        return row[field]?.toString()?.toLowerCase()?.includes(filterTypeContains?.filter?.toLowerCase());
      }
      if (filterTypeOr) {
        const result = filterTypeOr?.conditions?.some(fil => {
          const fieldValue = row[field]?.toString()?.toLowerCase();
          return fieldValue?.includes(fil?.filter?.toLowerCase());
        });
        return result;
      }
      if (filterTypeAnd) {
        const result = filterTypeAnd?.conditions?.every(fil => {
          const fieldValue = row[field]?.toString()?.toLowerCase();
          return fieldValue?.includes(fil?.filter?.toLowerCase());
        });
        return result;
      }
      return true;
    })
  );

  let sortedRows = [...filteredRows];
  if (sortModel?.length) {
    sortedRows.sort((a, b) => {
      for (let i = 0; i < sortModel.length; i++) {
        const { colId, sort } = sortModel[i];
        const valueA = a[colId]?.toString()?.toLowerCase() ?? '';
        const valueB = b[colId]?.toString()?.toLowerCase() ?? '';
        if (valueA < valueB) return sort === 'asc' ? -1 : 1;
        if (valueA > valueB) return sort === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  // Return filtered rows to ag-Grid
  const rowsForPage = sortedRows?.slice(startRow, endRow);
  params.successCallback(rowsForPage, filteredRows.length);
}
