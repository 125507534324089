import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Checkbox, Divider, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { currencyWithCommas, getCurrencyPrefix, separator } from '../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../../../../services/constantService';
import { getFixedDecimal } from '../../../../../utils/common';
import variables from '../../../../../variables.scss';
import '../../../TeamInfo/index.scss';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TotalRevenue = props => {
  /* getIndirectFees is used when calculate for estimated and actual fee calculation */
  const {
    watch,
    getTotalPercentageOfBase,
    getGrandTotal,
    type = 'estimated',
    getIndirectFees,
    register,
    setValue,
    currencyDetails,
    disabled = false,
    isDisabled = false,
    currentValues,
    currencyIcon = ''
  } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';

  const displayTooltipContent = title => {
    return (
      <Tooltip title={title}>
        <Box component='span' className='pl-2'>
          &nbsp;&nbsp;{currencyIcon}
          {title}
        </Box>
      </Tooltip>
    );
  };
  return (
    <Box id='team-info'>
      <Box className={'d-flex flex-column ign-team-information-table revenue-table mt-2 fs-14'}>
        <Box className={'d-flex table-heading font-weight-bold fs-15 '}>{label} Revenue</Box>
        <Box className=' d-flex flex-column py-1 px-3'>
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0'>Base Fee</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`, true))} </Box>
          </Box>
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0'>Indirect Fee</Box>
            <Box className='col pr-0 text-truncate'>
              {displayTooltipContent(getTotalPercentageOfBase(getIndirectFees ? getIndirectFees(type) : watch('project_indirect_fees'), 'calculation_amt', true))}
            </Box>
          </Box>
          <Divider className='divider-hr' sx={{ height: '2vh', borderWidth: 0, backgroundColor: variables.main }} />
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0 text-wrap'>Revenue</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getGrandTotal() || '--')}</Box>
          </Box>
          {type === 'actual' && ((disabled && watch('is_actual_overridden_revenue')) || !disabled) && (
            <Box className='row align-items-center p-1 mx-0'>
              <Box className='col-4 px-0'>
                <Box sx={{ width: '7vw' }} className='d-flex text-right text-red align-items-center justify-content-end'>
                  {!disabled && (
                    <Checkbox
                      checked={watch('is_actual_overridden_revenue') || false}
                      onChange={e => {
                        if (!e.target.checked) {
                          setValue('actual_overridden_revenue', 0);
                        }
                        setValue('is_actual_overridden_revenue', e.target.checked);
                      }}
                      color='primary'
                      {...register('is_actual_overridden_revenue')}
                      name={'is_actual_overridden_revenue'}
                      className='py-0'
                      disabled={disabled || isDisabled}
                    />
                  )}
                  <Box component='span' className={`${!disabled ? '' : ''}`}>
                    Override
                  </Box>
                </Box>
              </Box>
              <Box className='col-8 pr-0'>
                {!disabled && watch('is_actual_overridden_revenue') ? (
                  <NumericFormat
                    customInput={TextField}
                    label=''
                    id='is_actual_overridden_revenue'
                    variant='outlined'
                    placeholder='Override Revenue'
                    sx={{ width: 'auto', maxWidth: '7vw' }}
                    InputLabelProps={{ focused: true }}
                    className='fs-12'
                    value={watch('actual_overridden_revenue')}
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    disabled={!watch('is_actual_overridden_revenue')}
                    isNumericString={true}
                    displayType={'input'}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={values => {
                      const { value } = values;
                      setValue('old_actual_revenue', currentValues.actual_overridden_revenue);
                      setValue('actual_overridden_revenue', value ? value : '--');
                    }}
                  />
                ) : watch('is_actual_overridden_revenue') ? (
                  displayTooltipContent(`${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(watch('actual_overridden_revenue')).toFixed(2))}`)
                ) : (
                  0
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

TotalRevenue.propTypes = {
  watch: PropTypes.func,
  getTotalPercentageOfBase: PropTypes.func,
  getGrandTotal: PropTypes.func,
  type: PropTypes.string,
  getIndirectFees: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  currencyDetails: PropTypes.object,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  currentValues: PropTypes.object,
  currencyIcon: PropTypes.string
};

export default TotalRevenue;
