import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const ContactMultiStateSelection = ({ name = '', onChange = () => {}, disabled = false, isDrawer = false, className = '', countries = [], label = 'Select States', defaultValues = [], ...rest }) => {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatStateData = state => ({
    label: state.name,
    value: state.name,
    id: state.id || null
  });

  // Fetch states for a single country
  const fetchStatesForCountry = async country => {
    try {
      const { status, data } = await picklistDropDownApis(GET, `countries/states?countryName=${country}`);
      if (status === 200 && Array.isArray(data)) {
        return data?.map(formatStateData);
      }
      return [];
    } catch (error) {
      console.error(`Error fetching states for ${country}:`, error);
      return [];
    }
  };

  // Fetch All Contact States from Contact Address table
  const fetchAllStatesForContact = async () => {
    try {
      const response = await picklistDropDownApis(GET, 'contact/all-states');
      if (response?.data?.rows) {
        setOptions(response.data.rows);
        if (defaultValues?.length > 0) {
          const defaultSelected = options?.filter(option => defaultValues.some(defaultValue => defaultValue.toLowerCase() === option.value.toLowerCase()));
          setSelectedValues(defaultSelected);
        }
      }
    } catch (error) {
      console.error('Failed to fetch job titles:', error);
    }
  };

  useEffect(() => {
    const fetchAllStates = async () => {
      setIsLoading(true);
      try {
        const results = await Promise.all(countries.map(fetchStatesForCountry));
        const allOptions = results.flat();
        setOptions(allOptions);

        if (defaultValues?.length > 0) {
          const defaultSelected = allOptions.filter(option => defaultValues.some(defaultValue => defaultValue.toLowerCase() === option.value.toLowerCase()));
          setSelectedValues(defaultSelected);
        }
      } catch (error) {
        console.error('Error fetching states:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (countries?.length > 0) {
      fetchAllStates();
    } else {
      fetchAllStatesForContact();
    }
  }, [countries?.length]);

  const handleChange = (event, value) => {
    setSelectedValues(value);
    onChange(event, value);
  };

  return (
    <CustomDropdown
      {...rest}
      name={name}
      label={label}
      options={options}
      value={selectedValues}
      onChange={handleChange}
      disabled={disabled || isLoading}
      isDrawer={isDrawer}
      className={className}
      multiple={true}
      isCheckBox={true}
      disableCloseOnSelect={true}
      filterOptions={(options, params) => {
        if (!params.inputValue) return options;

        return options.filter(option => option.label.toLowerCase().includes(params.inputValue.toLowerCase()));
      }}
    />
  );
};

ContactMultiStateSelection.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  defaultValues: PropTypes.arrayOf(PropTypes.string)
};

export default ContactMultiStateSelection;
