import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import '../../../../../src/utils/common.scss';
import CustomPopup from '../../../common/CustomPopup';
import FileDragUpload from '../../../common/FileUpload';
import DocumentTypeSelection from '../../../common/FunctionalComponents/DocumentTypeSelection';
import ShowInSelection from '../../../common/FunctionalComponents/ShowIn';
import Loader from '../../../common/Loader';
import MakeConfidential from '../../../common/MakeConfidential/MakeConfidential';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import './index.scss';
export default function AddDocuments(props) {
  const { t } = useTranslation();
  const { title, setIsPopupOpen, isPopupOpen, isEdit, setIsEdit, unregister = () => {}, handleSave = () => {}, data, className, isForCompany = false } = props;
  const [isCheckApproved, setIsCheckApproved] = useState(false || data?.is_confidential ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm({});
  const [showIn, setShowIn] = useState([]);

  const handleClose = () => {
    setIsPopupOpen(false);
    setIsEdit(false);
    setIsEdit(false);
  };
  useEffect(() => {
    register('is_confidential');
    register('file_type');
    register('file_name');
    register('document_file');
    register('ign_contact_attachment_visibilities');
    return () => {
      unregister('file_type');
      unregister('file_name');
      unregister('document_file');
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue('ign_contact_attachment_visibilities', showIn);
  }, [showIn]);

  const handleShowInChange = value => {
    const result = value?.map(elem => {
      if (elem?.attachm) {
        return {
          name: elem.name ? elem.name : elem.field_value,
          show: elem.show,
          attachment_id: elem.attachm,
          id: elem.id
        };
      } else {
        return {
          name: elem.name ? elem.name : elem.field_value,
          show: true
        };
      }
    });

    setShowIn(result);
  };
  const handleChangeDocument = data => {
    setValue('file', data);
  };

  const saveData = async itemData => {
    setIsLoading(true);
    await handleSave(itemData, data);
    setIsLoading(false);
  };

  const handleCheck = e => {
    setIsCheckApproved(e.target.checked);
    setValue('is_confidential', e.target.checked);
  };

  useEffect(() => {
    setValue('file_name', data?.file_name);
    setValue('file_type', data?.file_type);
    setValue('is_confidential', isCheckApproved);
  }, [data]);

  return (
    <CustomPopup title={title} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit(saveData)}>
      <Box>
        <Box id='add-document'>
          <Box className='upload-file-header d-flex align-center'>
            <>
              <Loader show={isLoading} />
              <Box className='header-title fs-14'>{t('actions.uploadFile')}</Box>
              <Box className='d-flex item-center check-box ml-auto'>
                {!isForCompany && <MakeConfidential name={'Make Confidential'} defaultChecked={isCheckApproved} checked={isCheckApproved} handleChange={handleCheck} />}
              </Box>
            </>
          </Box>
          <Box className='paper-container pr-3 pl-3'>
            <Box className='d-flex pt-2 flex-wrap flex-row'>
              <Box className='custom-personality-input mr-3'>
                <DocumentTypeSelection
                  isForCompany={isForCompany}
                  required={true}
                  onChange={(e, data) => {
                    setValue('file_type', data?.field_value);
                  }}
                  label={t('utils.documentType')}
                  value={isForCompany ? 'Attachment' : data?.ign_translate_value.short_desc || null}
                />
              </Box>
              <Box className='custom-personality-input ml-3'>
                <CustomInputField
                  required={true}
                  className='name-input-box'
                  label={t('utils.addName')}
                  onChange={e => {
                    setValue('file_name', e.target.value);
                  }}
                  defaultValue={data?.file_name}
                  disabled={isEdit}
                />
              </Box>
            </Box>

            <Box className='d-flex custom-personality-popup mt-3 pb-2'>
              <Box className='custom-personality-input mr-3'>
                <Box className='formParent flex justify-center align-center resume'>
                  <ShowInSelection
                    multiple={true}
                    isForCompany={isForCompany}
                    isCheckBox={true}
                    label={t('utils.showIn')}
                    defaultValue={data?.ign_contact_attachment_visibilities?.filter(item => item?.show === true) || data?.attachment_visibilities}
                    onChange={value => {
                      handleShowInChange(value);
                    }}
                  />
                </Box>
              </Box>
              {!isEdit && (
                <Box className='custom-personality-input ml-3'>
                  <Box className='formParent flex justify-center align-center resume'>
                    <FileDragUpload
                      className={className}
                      label={`${t('actions.uploadFile')} (.pdf/.docx)`}
                      handleChangeDocument={handleChangeDocument}
                      width={'18vw'}
                      acceptedFileTypes={['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomPopup>
  );
}

AddDocuments.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  data: PropTypes.object,
  className: PropTypes.string,
  isForCompany: PropTypes.bool
};
