import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { ignWorkbenchQueryApi } from '../../../../../services/ApiService';
import { POST, ROUTES } from '../../../../../services/constantService';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../../../utils/common';
import { CustomLoadingOverlayComponent } from '../../../../Commons/Utils';
import { queryCandidateColumn } from '../Grid/QueryData';
import { CandidateFilterModal } from './QueryFilterAccordion/CandidateFilterAccordion';

const mappedPayload = data => {
  return {
    ...data,
    industry: data?.industry?.map(item => item?.id),
    projectIndustry: data?.projectIndustry?.map(item => item?.id),
    lastActivityBy: data?.lastActivityBy?.map(item => item?.id)
  };
};

const capitalizeProperCase = string => {
  if (!string) return '';
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const mapAllTags = (type, data) => {
  let tags = '--';
  if (type === 'contact') {
    tags = data?.contact_tags?.map(item => capitalizeProperCase(item?.ign_tags?.name))?.join(', ') || '--';
  } else if (type === 'project') {
    tags = data?.project_tags?.map(item => capitalizeProperCase(item?.tag?.name))?.join(', ') || '--';
  }

  return tags;
};

const pastWorkExperience = (workExp = []) => {
  const experiences = Array.isArray(workExp) ? workExp : [];
  return experiences
    .sort((a, b) => {
      const dateA = new Date(a?.updated_at || a?.created_at);
      const dateB = new Date(b?.updated_at || b?.created_at);
      return dateB - dateA;
    })
    .filter(exp => exp?.is_present === false)?.[0];
};

export const QueryCandidateGrid = ({ data, gridType, filterModel, sortModel, setQueryResponse }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    const fetchCandidateQuery = async () => {
      try {
        setGridLoading(true);
        const payload = Object.keys(filterOptions).length > 0 ? filterOptions : mappedPayload(data);
        const response = await ignWorkbenchQueryApi(POST, payload, `type/${data?.query_type?.toLowerCase()}`);
        setFilteredData(response?.data?.rows || []);

        setGridLoading(false);
      } catch (error) {
        console.error('Error fetching contact query:', error);
        setFilteredData([]);

        setGridLoading(false);
      }
    };
    fetchCandidateQuery();
  }, [filterOptions]);

  const columns = useMemo(() => queryCandidateColumn, []);

  const routeCompany = id => {
    const path = ROUTES.viewCompanyWithoutCurrentTab.replace(':id', id);
    window.open(path, '_blank');
  };

  const routeContact = id => {
    const path = ROUTES.allContactGridWithDrawerOpen.replace(':id', id);
    window.open(path, '_blank');
  };

  const routeProject = id => {
    const path = ROUTES.vProject.replace(':id', id);
    window.open(path, '_blank');
  };

  const rowDataArray = useMemo(
    () =>
      filteredData.map(query => ({
        ...query,
        name: query?.contact?.name,
        contactTags: mapAllTags('contact', query?.contact),
        projectTags: mapAllTags('project', query?.project),
        project_name: query?.project?.helia_title,
        project_industry: query?.project?.ign_industries?.name || '--',
        stage: query?.stage || '--',
        current_company_name: query?.contact?.contact_company?.name,
        pastCompanyId: pastWorkExperience(query?.contact?.contact_work_experience || {})?.company?.id,
        previous_company_name: pastWorkExperience(query?.contact?.contact_work_experience || {})?.company?.name,
        current_job_title: query?.contact?.current_job_title || '--',
        previous_job_title: pastWorkExperience(query?.contact?.contact_work_experience || {})?.title || '--',
        industry: (query?.contact?.contact_industries_p1 ?? []).join(',') || '--',
        lastActivity: query?.updated_by_user?.updated_at ? new Date(query?.updated_by_user?.updated_at)?.toDateString() : '--',
        lastActivityBy: query?.updated_by_user?.name || '--'
      })),
    [filteredData]
  );

  return (
    <>
      <CandidateFilterModal data={data} filterOptions={filterOptions} setFilterOptions={setFilterOptions} setQueryResponse={setQueryResponse} />
      <div id='myGrid' className='ag-theme-alpine candidate-query-workbench-grid' style={{ overflow: 'hidden', height: 'calc(70vh - 100px)', marginTop: '10px' }}>
        <AgGridWrapper
          enableBrowserTooltips={true}
          gridType={gridType}
          defaultColumnDefs={queryCandidateColumn}
          rowHeight={50}
          columnDefs={columns}
          loadingOverlayComponent={CustomLoadingOverlayComponent}
          scrollbarWidth={12}
          showHeaderMenu={true}
          rowData={rowDataArray}
          paginationPageSize={1000}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          sortModel={sortModel}
          filterModel={filterModel}
          loading={gridLoading}
          suppressHorizontalScroll={false}
          domLayout='normal'
          disableStaticMarkup
          rowGroupPanelShow='always'
          saveGridSetting={true}
          rowModelType='clientSide'
        />
      </div>
    </>
  );
};

QueryCandidateGrid.propTypes = {
  gridState: PropTypes.array,
  data: PropTypes.object,
  setQueryResponse: PropTypes.func,
  sortModel: PropTypes.array,
  filterModel: PropTypes.array,
  gridType: PropTypes.string
};
