import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Add as AddIcon, ExpandMore as ExpandMoreIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LegendsColorPlateIcon from '../../../../components/Icons/LegendsColorPlateIcon';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import { CompetencyDataApi, echoSenseDataApi, picklistsDataApis, profileLabel } from '../../../../services/ApiService';
import { DELETE, ERROR, GET, PICKLISTS, POST, PUT, ROUTES, SUCCESS } from '../../../../services/constantService';
import { CompetencyGenerationActions } from '../../../../store/CompetencyGenerationSlice';
import useApi from '../../../../utils/Hooks/useApiHook';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import FilterView from './FilterView';
import LabelModalWithHeader from './LabelModalWithHeader';
import LegendPreview from './LegendPreview';
import ExperienceAndCompetencyPreviewMode from './PreviewMode';
import SortableItem from './SortableItem';
import SortableSubCompetency from './SortableSubCompetency';
import './index.scss';

export const textColors = {
  experience: 'rgba(189, 239, 1, 0.3)',
  competency: 'rgba(0, 0, 128, 0.2)',
  uncategorized: 'rgba(189, 246, 254)'
};

function ExperienceAndCompetency({ projectId, positionProfile }) {
  const dispatch = useDispatch();

  const { data: profileCategoryTypes } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.PROFILE_CATEGORY);
    }
  });

  const { data: rubricBinaryRatingsTypes } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.RUBRIC_RATING_BINARY);
    }
  });

  const { data: rubricGradedRatingsData } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.RUBRIC_RATING_GRADE);
    }
  });
  const reduxData = useSelector(state => state?.competencyGenerationSlice);
  const [columns, setColumns] = useState({
    experience: [],
    competency: [],
    uncategorized: []
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isAllCollapsed, setIsAllCollapsed] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filterViewData, setFilterViewData] = useState({});
  const [selectedText, setSelectedText] = useState('');
  const [socketInProgress, setSocketInProgress] = useState(false);
  const [initialFilterState, setInitialFilterState] = useState({});
  const [textToHighlight, setTextToHighlight] = useState([]);
  const [isProjectLabelingExpanded, setIsProjectLabelingExpanded] = useState(true);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [expandedCompetencies, setExpandedCompetencies] = useState({});
  const [labelText, setLabelText] = useState(null);
  const [alertModal, setAlertModal] = useState(null);
  const [legendPreview, setLegendPreview] = useState(false);

  const quillRef = useRef(null); // Reference for ReactQuill
  const [progressBarState, setProgressBarState] = useState({
    active: false,
    progress: 0
  });
  const [expandedSections, setExpandedSections] = useState({
    experience: true,
    competency: true,
    uncategorized: true
  });
  const [profileLabelData, setProfileLabelData] = useState();
  const [refetch, setRefetch] = useState(false);

  const navigate = useNavigate();

  const fetchProfileLabelData = async () => {
    try {
      setLoading(true);
      const res = await profileLabel(GET, `project/${projectId}`, null, null);
      setLoading(false);
      if (res.status >= 300) {
        enqueueSnackbar('Error occurred while fetching profile label data', { variant: ERROR });
        return;
      }
      setProfileLabelData(res);
    } catch (error) {
      enqueueSnackbar('Error occurred while fetching profile label data', { variant: ERROR });
    }
  };

  useEffect(() => {
    fetchProfileLabelData();
  }, [refetch, projectId]);

  useEffect(() => {
    console.log(reduxData, 'reduxData');
    if (reduxData?.data?.projectId === projectId) {
      if (reduxData?.data?.status === 'FAILED') {
        setProgressBarState({
          active: false,
          progress: 0
        });
        enqueueSnackbar(`${t('experienceAndCompetency.failedGeneratingProfileLabels')}`, { variant: ERROR });
        return;
      }
      if (reduxData?.data?.progress && !reduxData?.data?.refresh) {
        setSocketInProgress(true);
        setProgressBarState({
          active: true,
          progress: reduxData?.data?.progress
        });
      }
      if (reduxData?.data?.progress === '0' || reduxData?.data?.progress === 0) {
        setSocketInProgress(true);
        setProgressBarState({
          active: true,
          progress: reduxData?.data?.progress
        });
      }
      if (reduxData?.data?.progress === 33 || reduxData?.data?.progress === '33') {
        setProgressBarState({
          active: true,
          progress: reduxData?.data?.progress
        });
        setTextToHighlight(reduxData?.data?.competencies);
      }
      if (reduxData?.data?.progress === 66 || reduxData?.data?.progress === '66') {
        setRefetch(true);
        setProgressBarState({
          active: true,
          progress: reduxData?.data?.progress
        });
      }
      if (reduxData?.data?.progress === 100 || reduxData?.data?.progress === '100') {
        setProgressBarState({
          active: true,
          progress: reduxData?.data?.progress
        });
        dispatch(CompetencyGenerationActions.updateData({}));
        setProgressBarState({
          active: false,
          progress: 0
        });
        setSocketInProgress(false);
        enqueueSnackbar(`${t('experienceAndCompetency.profileLabelsReadyForView')}`, { variant: SUCCESS });
      }
    }
  }, [reduxData, projectId]);

  useEffect(() => {
    if (profileLabelData?.data?.length) {
      const newColumns = {
        experience: [],
        competency: [],
        uncategorized: []
      };

      profileLabelData.data.forEach(item => {
        const category = item?.label_category?.toLowerCase() || '';
        if (category.includes('experience')) {
          newColumns.experience.push(item);
        } else if (category.includes('competency')) {
          newColumns.competency.push(item);
        } else {
          newColumns.uncategorized.push(item);
        }
      });

      // Sort items by sequence and sub_sequence
      newColumns.experience.sort((a, b) => (a.sequence || 0) - (b.sequence || 0));
      newColumns.competency.sort((a, b) => (a.sequence || 0) - (b.sequence || 0) || (a.sub_sequence || 0) - (b.sub_sequence || 0));
      newColumns.uncategorized.sort((a, b) => (a.sequence || 0) - (b.sequence || 0));

      // Add sequence and sub_sequence if not present
      newColumns.experience.forEach((item, index) => {
        if (!item.sequence) item.sequence = index;
      });
      newColumns.competency.forEach((item, index) => {
        if (!item.sequence) item.sequence = index;
        if (!item.sub_sequence) item.sub_sequence = index;
      });
      newColumns.uncategorized.forEach((item, index) => {
        if (!item.sequence) item.sequence = index;
      });

      setColumns(newColumns);
    }
  }, [profileLabelData]);

  const { data: competencyOptions } = useApi({
    queryFn: () => CompetencyDataApi(POST, '', '', 'all?version=helia')
    // added query helia because for helia and ignyte intelligence we are showing the same competencies
  });

  const isDescriptionEmpty = !positionProfile?.data?.description?.trim();

  const isColumnsEmpty = Object.values(columns).every(array => Array.isArray(array) && array.length === 0);
  const groupedCompetencies = useMemo(() => {
    return columns.competency.reduce((groups, item) => {
      const subcategory = item.label_subtype || 'Uncategorized';
      if (!groups[subcategory]) groups[subcategory] = [];
      groups[subcategory].push(item);
      return groups;
    }, {});
  }, [columns]);

  const previewCompetency = [...new Set((filterViewData?.competency || columns.competency).map(item => item.label_subtype || 'Uncategorized'))];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handlePreview = useCallback(() => {
    setIsPreviewMode(true);
  }, []);

  const handleCollapseAll = () => {
    setIsAllCollapsed(prev => !prev);
  };

  const handleDragEnd = event => {
    const { active, over } = event;
    if (!active || !over) return;

    // Handle subCompetency header dragging
    if (active.id.startsWith('subcomp-') && over.id.startsWith('subcomp-')) {
      const oldSubCompType = active.id.replace('subcomp-', '');
      const newSubCompType = over.id.replace('subcomp-', '');

      if (oldSubCompType === newSubCompType) return;

      setColumns(prev => {
        const newColumns = { ...prev };
        const competencyItems = [...prev.competency];

        // Get all items for both subCompetencies
        const oldSubCompItems = competencyItems.filter(item => item.label_subtype === oldSubCompType);
        const newSubCompItems = competencyItems.filter(item => item.label_subtype === newSubCompType);

        // Find the first index of the target subCompetency
        const targetIndex = competencyItems.findIndex(item => item.label_subtype === newSubCompType);

        if (targetIndex === -1) return prev;

        // Remove old subCompetency items
        const filteredItems = competencyItems.filter(item => item.label_subtype !== oldSubCompType && item.label_subtype !== newSubCompType);

        // Determine the new order based on drag direction
        const draggedItems = oldSubCompItems.map(item => ({ ...item }));
        const targetItems = newSubCompItems.map(item => ({ ...item }));

        // Insert items in the correct order
        let insertIndex = targetIndex;
        if (targetIndex > competencyItems.findIndex(item => item.label_subtype === oldSubCompType)) {
          filteredItems.splice(insertIndex, 0, ...targetItems, ...draggedItems);
        } else {
          filteredItems.splice(insertIndex, 0, ...draggedItems, ...targetItems);
        }

        // Update sequences
        filteredItems.forEach((item, index) => {
          item.sequence = index;
          item.sub_sequence = index;
        });

        newColumns.competency = filteredItems;
        return newColumns;
      });

      return;
    }

    // Handle regular item dragging
    setColumns(prev => {
      const newColumns = JSON.parse(JSON.stringify(prev));
      let sourceColumn, sourceIndex, draggedItem;

      // Find source item and its location
      for (const [columnName, items] of Object.entries(newColumns)) {
        const index = items.findIndex(item => item.id === active.id);
        if (index !== -1) {
          sourceColumn = columnName;
          sourceIndex = index;
          draggedItem = items[index];
          break;
        }
      }

      if (!sourceColumn || !draggedItem) return prev;

      // Find destination location
      let destColumn, destIndex;

      // Check if dropping into drop zone
      if (over.id.toString().includes('-drop-zone')) {
        destColumn = over.id.toString().split('-')[0];
        destIndex = newColumns[destColumn].length;
      } else {
        // Find the target item's location
        for (const [columnName, items] of Object.entries(newColumns)) {
          const index = items.findIndex(item => item.id === over.id);
          if (index !== -1) {
            destColumn = columnName;
            destIndex = index;
            break;
          }
        }
      }

      if (!destColumn) return prev;

      // Remove from source
      newColumns[sourceColumn].splice(sourceIndex, 1);

      // Update item properties
      const updatedItem = {
        ...draggedItem,
        label_category: destColumn?.toUpperCase(),
        sequence: destIndex
      };

      // Handle competency specific updates
      if (destColumn === 'competency') {
        const nearbyItem = newColumns.competency[destIndex] || newColumns.competency[destIndex - 1];
        updatedItem.label_subtype = nearbyItem?.label_subtype || 'Uncategorized';
        updatedItem.sub_sequence = destIndex;
      } else {
        delete updatedItem.label_subtype;
        delete updatedItem.sub_sequence;
      }

      // Insert at destination
      newColumns[destColumn].splice(destIndex, 0, updatedItem);

      // Update sequences
      newColumns[sourceColumn].forEach((item, index) => {
        item.sequence = index;
        if (sourceColumn === 'competency') item.sub_sequence = index;
      });

      if (sourceColumn !== destColumn) {
        newColumns[destColumn].forEach((item, index) => {
          item.sequence = index;
          if (destColumn === 'competency') item.sub_sequence = index;
        });
      }

      return newColumns;
    });
  };

  const handleGeneratePositionProfile = useCallback(() => {
    setIsPreviewMode(false);
    let navigateTo = ROUTES.viewProjectWithDeepLink;
    navigateTo = navigateTo.replace(':id', projectId);
    navigateTo = navigateTo.replace(':parentTab', 'Roadmap');
    navigateTo = navigateTo.replace(':subTab', 'Position%20Profile');
    navigate(navigateTo);
  }, []);

  const handleModify = useCallback(() => {
    setIsPreviewMode(false);
  }, []);

  const handleReGenerate = async () => {
    try {
      setRegenerating(true);
      const response = await echoSenseDataApi(POST, 'generate-profile-label', {
        job_description: positionProfile?.data?.description,
        project_id: projectId
      });
      const deleteResponse = await profileLabel(DELETE, `bulk-delete/${projectId}`);
      if (deleteResponse.status >= 300) {
        enqueueSnackbar('Error occurred regenerating profile labels', { variant: ERROR });
      }
      setRefetch(true);
      setRegenerating(false);
      setProgressBarState({
        active: true,
        progress: 0
      });
      dispatch(
        CompetencyGenerationActions.updateData({
          projectId: projectId,
          progress: '0'
        })
      );
      setAlertModal(false);
      if (response.status >= 300) {
        setProgressBarState({
          active: false,
          progress: 0
        });
        enqueueSnackbar('Error occurred regenerating profile labels', { variant: ERROR });
        return;
      } else {
        enqueueSnackbar('Profile labels triggered to generate successfully', { variant: SUCCESS });
        return;
      }
    } catch (error) {
      enqueueSnackbar('Error occurred regenerating profile labels', { variant: ERROR });
    }
  };

  const onAdd = useCallback(
    (category, subCategory = null) => {
      if (['experience', 'uncategorized'].includes(category)) {
        setSelectedItem({
          label_category: category,
          label_text: selectedText // Pass the selected text
        });
      } else if (['competency'].includes(category) || subCategory) {
        setSelectedItem({
          label_category: 'competency',
          ...(subCategory && { label_subtype: subCategory }),
          label_text: selectedText // Pass the selected text
        });
      } else if (category === 'projectLabelling') {
        const quillText = quillRef.current.getEditor().getSelection(); // Get the selected range from Quill
        const selectedText = quillText ? quillRef.current.getEditor().getText(quillText.index, quillText.length).trim() : ''; // Get the selected text based on the selection range
        quillText
          ? setSelectedItem({
              label_text: selectedText
            })
          : setSelectedItem({});
      } else {
        setSelectedItem({});
      }
      setIsModalOpen(true);
    },
    [selectedText]
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedItem({});
  };

  const highlightMatchingWords = (text, wordList = []) => {
    if (!text) return '';

    const allLabels = wordList.length > 0 ? wordList : [...(columns?.experience || []), ...(columns?.competency || []), ...(columns?.uncategorized || [])];
    const labelTexts = allLabels.map(label => label.label_text || label).filter(Boolean);
    const allLabelTexts = [...new Set([...labelTexts, ...textToHighlight])];
    const escapeRegExp = string => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const highlightedText = allLabelTexts.reduce((acc, label) => {
      const escapedLabel = escapeRegExp(label);
      const regex = new RegExp(`\\b${escapedLabel}\\b`, 'gi');
      const color =
        allLabels.find(item => item.label_text === label)?.label_category?.toLowerCase() === 'experience'
          ? textColors.experience
          : allLabels.find(item => item.label_text === label)?.label_category?.toLowerCase() === 'competency'
            ? textColors.competency
            : textColors.uncategorized;
      return acc.replace(regex, match => `<span style="background-color: ${color};">${match}</span>`);
    }, text);

    return highlightedText;
  };

  const toggleExpand = section => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleBulkSave = async () => {
    try {
      setLoading(true);
      const response = await profileLabel(PUT, 'bulk-update', [...columns.experience, ...columns.competency, ...columns.uncategorized]);
      setLoading(false);
      if (response.status > 300) {
        enqueueSnackbar('Error occurred while bulk updating the labels', { variant: ERROR });
        return;
      } else {
        enqueueSnackbar('Profile Labels updated successfully', { variant: SUCCESS });
      }
    } catch (error) {
      enqueueSnackbar('Error occurred while bulk updating the labels', { variant: ERROR });
    }
  };

  function getCompetencyDescription(subCompetency) {
    if (subCompetency) {
      const subCompetencyData = competencyOptions?.data?.rows?.find(item => item.name === subCompetency);
      return subCompetencyData?.insights;
    }
  }

  const renderSortableItems = (items, columnId, marginLeft = 0, marginTop = 0) =>
    items?.length > 0 ? (
      <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
        {items.map(item => (
          <SortableItem
            key={item.id}
            id={item.id}
            item={item}
            marginLeft={marginLeft}
            marginTop={marginTop}
            competencySubCategory={competencyOptions?.data?.rows ?? []}
            setColumns={setColumns}
            setLabelText={setLabelText}
            profileCategory={profileCategoryTypes?.data}
            rubricBinaryRatings={rubricBinaryRatingsTypes?.data}
            rubricGradedRatings={rubricGradedRatingsData?.data}
          />
        ))}
      </SortableContext>
    ) : (
      <Box
        id={columnId}
        sx={{
          p: 1,
          textAlign: 'center',
          color: 'grey.500',
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed #ccc',
          borderRadius: '4px'
        }}
      >
        <Typography variant='body2'>Drag items here</Typography>
      </Box>
    );

  const renderCompetencySection = () => (
    <div className='column-paper' id='competency-drop-zone'>
      <Box className='column-header' display='flex' justifyContent='space-between' alignItems='center' mb={0.75} sx={{ bgcolor: '#f5f5f5', p: 0.75, borderRadius: 1 }}>
        <Box className='header-left' display='flex' alignItems='center'>
          <TipsAndUpdatesIcon sx={{ fontSize: 'small' }} />
          <Typography variant='body2' sx={{ ml: 0.75 }}>
            {'Competency'}
          </Typography>
        </Box>
        <Box className='header-actions'>
          <IconButton onClick={() => onAdd('competency')} sx={{ mx: 0.25 }}>
            <AddIcon />
          </IconButton>
          <IconButton sx={{ mx: 0.25 }} onClick={() => toggleExpand('competency')}>
            <ExpandMoreIcon
              sx={{
                transform: expandedSections.competency ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s'
              }}
            />
          </IconButton>
        </Box>
      </Box>
      {expandedSections.competency && (
        <Box className='column-content'>
          {Object.entries(groupedCompetencies).length > 0 ? (
            <SortableContext items={Object.keys(groupedCompetencies).map(key => `subcomp-${key}`)} strategy={verticalListSortingStrategy}>
              {Object.entries(groupedCompetencies).map(([competency_subcategory, items]) => (
                <Box key={competency_subcategory} sx={{ mt: 0.75, ml: 0.75 }}>
                  <SortableSubCompetency
                    subCompetency={competency_subcategory}
                    subCompetencyDescription={getCompetencyDescription(competency_subcategory)}
                    onAdd={onAdd}
                    expandedCompetencies={expandedCompetencies}
                    setExpandedCompetencies={setExpandedCompetencies}
                  />
                  {(!Object.prototype.hasOwnProperty.call(expandedCompetencies, competency_subcategory) || expandedCompetencies[competency_subcategory]) && (
                    <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
                      {items.map(item => (
                        <SortableItem
                          key={item.id}
                          id={item.id}
                          item={item}
                          marginLeft={1}
                          marginTop={1}
                          competencySubCategory={competencyOptions?.data?.rows ?? []}
                          setColumns={setColumns}
                          setLabelText={setLabelText}
                          profileCategory={profileCategoryTypes?.data}
                          rubricBinaryRatings={rubricBinaryRatingsTypes?.data}
                          rubricGradedRatings={rubricGradedRatingsData?.data}
                        />
                      ))}
                    </SortableContext>
                  )}
                </Box>
              ))}
            </SortableContext>
          ) : (
            <Box
              sx={{
                p: 1,
                textAlign: 'center',
                color: 'grey.500',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed #ccc',
                borderRadius: '4px'
              }}
            >
              <Typography variant='body2'>Drag items here</Typography>
            </Box>
          )}
        </Box>
      )}
    </div>
  );

  useEffect(() => {
    if (labelText && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const text = editor.getText();
      const index = text.indexOf(labelText);
      if (index !== -1 && text.slice(index, index + labelText.length) === labelText) {
        editor.setSelection(index, labelText.length);
        editor.focus();
        const bounds = editor.getBounds(index, labelText.length);
        if (bounds && editor.root) {
          const scrollTop = bounds.top + editor.root.scrollTop - 10;
          editor.root.scrollTop = scrollTop;
        }
      }
    }
  }, [labelText]);

  return (
    <div className='position-profile'>
      <Loader show={loading} />
      <Stack className='header-buttons' direction='row' spacing={2} justifyContent='space-between'>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <CustomButton type={!isPreviewMode ? 'primary' : 'tertiary'} variant='contained' buttonText={t('actions.modify')} onClick={handleModify} />
            <CustomButton type={isPreviewMode ? 'primary' : 'tertiary'} variant='contained' buttonText={t('actions.preview')} onClick={handlePreview} />
            {isPreviewMode && (
              <div className='preview-controls'>
                <CustomButton type='tertiary' variant='outlined' buttonText={t('actions.collapseAll')} iconLeft={<UnfoldLessIcon />} onClick={handleCollapseAll} />
                <div>
                  <CustomButton type='tertiary' variant='outlined' buttonText={t('actions.filter')} iconLeft={<FilterListIcon />} onClick={() => setIsFilter(prev => !prev)} />
                  {isFilter && (
                    <FilterView
                      setIsFilter={setIsFilter}
                      columns={columns}
                      setFilterViewData={setFilterViewData}
                      initialFilterState={initialFilterState}
                      setInitialFilterState={setInitialFilterState}
                    />
                  )}
                </div>
              </div>
            )}
          </Stack>
        </Stack>
        <Stack direction='row' spacing={2}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <CustomButton type='tertiary' variant='outlined' buttonText={t('actions.legend')} iconLeft={<LegendsColorPlateIcon />} onClick={() => setLegendPreview(!legendPreview)} />
            {legendPreview && <LegendPreview />}
          </div>

          {!isDescriptionEmpty && (
            <>
              {!(isColumnsEmpty && regenerating) && (
                <CustomButton
                  disabled={socketInProgress}
                  type='tertiary'
                  variant='contained'
                  buttonText={isColumnsEmpty ? t('actions.generate') : t('actions.reGenerate')}
                  onClick={() => (isColumnsEmpty ? handleReGenerate() : setAlertModal(true))}
                />
              )}
              {alertModal && !isColumnsEmpty && (
                <CustomModalWithHeader
                  label={'Warning'}
                  closeIcon={true}
                  onClose={() => setAlertModal(false)}
                  isOpen={alertModal}
                  headerClass={'modal-header-background p-2 rounded-top mb-0'}
                  style={{ p: 0 }}
                >
                  <Typography className='text-content p-4' variant='body2' align='center' sx={{ ml: 2, mr: 2 }}>
                    Re-generating will delete any user-created Experience, Competency, and Uncategorized labels for this project.
                  </Typography>
                  <Stack direction='row' spacing={2} justifyContent='center' sx={{ mb: 2 }}>
                    <CustomButton variant='outlined' buttonText={t('actions.cancel')} onClick={() => setAlertModal(false)} />
                    <CustomButton variant='contained' buttonText={t('actions.reGenerate')} onClick={handleReGenerate} isLoading={regenerating} />
                  </Stack>
                </CustomModalWithHeader>
              )}
            </>
          )}
          <CustomButton type='primary' variant='contained' buttonText={t('actions.save')} onClick={handleBulkSave} />
        </Stack>
      </Stack>

      {!isPreviewMode ? (
        <div className='content-container'>
          {progressBarState.active && (
            <div className='progress-bar-container'>
              <Typography variant='body2' className='progress-title'>
                {t('project.analyzeProjectProfile')}
              </Typography>
              <div className='progress-bar-wrapper'>
                <div className='progress-bar'>
                  <LinearProgress variant='determinate' value={progressBarState.progress} className='linear-progress-bar' />
                </div>
                <Typography variant='body2' className='progress-value'>
                  {progressBarState.progress}%
                </Typography>
              </div>
            </div>
          )}
          {isDescriptionEmpty ? (
            <div className='empty-state'>
              <Typography variant='body2' className='text-content'>
                The position profile has not been generated yet, please generate the Position Profile so Ignyte Intelligence can assist with Experience and Competency section
              </Typography>
              <CustomButton variant='contained' buttonText='Generate Position Profile Now' onClick={handleGeneratePositionProfile} />
            </div>
          ) : (
            <>
              <div className='project-labeling-header'>
                <Typography variant='body2'>Project Labeling</Typography>
                <div>
                  <IconButton onClick={() => onAdd('projectLabelling')}>
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={() => setIsProjectLabelingExpanded(!isProjectLabelingExpanded)}>
                    <ExpandMoreIcon className={`expand-icon ${isProjectLabelingExpanded ? 'expanded' : ''}`} />
                  </IconButton>
                </div>
              </div>
              {isProjectLabelingExpanded && (
                <ReactQuill
                  className='project-description w-100'
                  ref={quillRef}
                  readOnly
                  value={highlightMatchingWords(positionProfile?.data?.description || '')}
                  modules={{ toolbar: false }}
                  style={{ border: 'none', padding: '0px' }}
                />
              )}
            </>
          )}

          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <div className='divider horizontal-divider' />

            <div className='columns-container'>
              <div className='column'>
                <div className='column-paper' id='experience-drop-zone'>
                  <div className='column-header'>
                    <div className='header-left'>
                      <SettingsSuggestIcon fontSize='small' />
                      <Typography variant='body2'>Experience</Typography>
                    </div>
                    <div className='header-actions'>
                      <IconButton onClick={() => onAdd('experience')}>
                        <AddIcon />
                      </IconButton>
                      <IconButton onClick={() => toggleExpand('experience')}>
                        <ExpandMoreIcon className={`expand-icon ${expandedSections.experience ? 'expanded' : ''}`} />
                      </IconButton>
                    </div>
                  </div>
                  {expandedSections.experience && <div className='column-content'>{renderSortableItems(columns.experience, 'experience-drop-zone', 1, 1)}</div>}
                </div>
              </div>

              <div className='divider vertical-divider' />

              <div className='column'>{renderCompetencySection()}</div>

              <div className='divider vertical-divider' />

              <div className='column'>
                <div className='column-paper' id='uncategorized-drop-zone'>
                  <div className='column-header'>
                    <div className='header-left'>
                      <AutoAwesomeIcon fontSize='small' />
                      <Typography variant='body2'>Uncategorized</Typography>
                    </div>
                    <div className='header-actions'>
                      <IconButton onClick={() => onAdd('uncategorized')}>
                        <AddIcon />
                      </IconButton>
                      <IconButton onClick={() => toggleExpand('uncategorized')}>
                        <ExpandMoreIcon className={`expand-icon ${expandedSections.uncategorized ? 'expanded' : ''}`} />
                      </IconButton>
                    </div>
                  </div>
                  {expandedSections.uncategorized && <div className='column-content'>{renderSortableItems(columns.uncategorized, 'uncategorized-drop-zone', 1, 1)}</div>}
                </div>
              </div>
            </div>
          </DndContext>
        </div>
      ) : (
        <ExperienceAndCompetencyPreviewMode
          columns={Object.keys(filterViewData).length > 0 ? filterViewData : columns}
          competencySubCategory={previewCompetency ?? []}
          isAllCollapsed={isAllCollapsed}
          rubricBinaryRatings={rubricBinaryRatingsTypes.data}
          rubricGradedRatings={rubricGradedRatingsData.data}
        />
      )}

      <LabelModalWithHeader
        modalTitle='Add Label'
        onClose={handleModalClose}
        isOpen={isModalOpen}
        closeIcon={true}
        maxWidth='sm'
        item={selectedItem}
        competencySubCategory={competencyOptions?.data?.rows || []}
        setColumns={setColumns}
        projectId={projectId}
      />
    </div>
  );
}

ExperienceAndCompetency.propTypes = {
  projectId: PropTypes.string,
  positionProfile: PropTypes.any
};

export default ExperienceAndCompetency;
