import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
function AddContactDrawerHeader(props) {
  const { title, subtitle, onClose, onSave, onSaveAndAddAnother } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddAnother, setIsLoadingAddAnother] = useState(false);
  const handleSave = async () => {
    setIsLoading(true);
    await onSave();
    setIsLoading(false);
    setIsLoadingAddAnother(false);
  };
  const handleSaveAndAddAnother = async () => {
    setIsLoadingAddAnother(true);
    await onSaveAndAddAnother();
    setIsLoadingAddAnother(false);
    setIsLoading(false);
  };
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        bgcolor: 'primary.main',
        width: '100%',
        padding: '8px 16px'
      }}
      disablePadding
    >
      <Stack>
        <Typography variant='body1' fontWeight={'bold'} className='fs-14' color={'text.white'}>
          {title}
        </Typography>
        <Typography variant='p' className='fs-10' color={'text.white7'} fontWeight={'300'}>
          {subtitle}
        </Typography>
      </Stack>
      <Stack direction={'row'} gap={1}>
        <Button
          variant='outlined'
          sx={{
            color: 'text.white',
            borderColor: 'text.white',
            fontWeight: '600',
            '&:hover': {
              borderColor: 'text.white1'
            }
          }}
          className='fs-12'
          onClick={handleSave}
          disabled={isLoading || isLoadingAddAnother}
          endIcon={isLoading && <CircularProgress size={16} color='inherit' />}
        >
          {t('addContactDrawer.save')}
        </Button>
        <Button
          variant='outlined'
          sx={{
            color: 'text.white',
            borderColor: 'text.white',
            fontWeight: '600',
            '&:hover': {
              borderColor: 'text.white1'
            }
          }}
          className='fs-12'
          onClick={handleSaveAndAddAnother}
          disabled={isLoadingAddAnother || isLoading}
          endIcon={isLoadingAddAnother && <CircularProgress size={16} color='inherit' />}
        >
          {t('addContactDrawer.saveAndAddAnother')}
        </Button>
        <Button
          variant='text'
          sx={{
            color: 'text.white',
            fontWeight: '600'
          }}
          size='small'
          className='fs-12'
          onClick={onClose}
          disabled={isLoading || isLoadingAddAnother}
        >
          <CloseIcon />
        </Button>
      </Stack>
    </Stack>
  );
}

export default AddContactDrawerHeader;

AddContactDrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndAddAnother: PropTypes.func.isRequired
};
