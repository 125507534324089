import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import { activityInfoApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import AgGridWrapper from '../../../../utils/AgGridWrapper';
import useApi from '../../../../utils/Hooks/useApiHook';
import AddTypePopup from './AddTypePopup';
import { activityNameRenderer, columnDefs } from './utils';

const ActivityTypes = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isAddOpen, setIsAddOpen] = useState(false);

  const { data, refetch } = useApi({
    queryKey: 'getAllActivityTypes',
    queryFn: async () => {
      return await activityInfoApi(GET, '', 'all-activity-types');
    }
  });

  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 5,
          mb: 2
        }}
      >
        <CustomButton
          buttonText={t('actions.add')}
          iconLeft={
            <Add
              sx={{
                fontSize: '1rem',
                mr: 1
              }}
            />
          }
          onClick={() => setIsAddOpen(true)}
        />
      </Box>
      <div id='myGrid' className='ag-theme-alpine'>
        <AgGridWrapper
          autoSizStrategy={{ type: 'fitGridWidth' }}
          columnDefs={columnDefs}
          rowData={data?.data || []}
          onRowClicked={e => {
            setCurrentRowData(e.data);
            setIsAddOpen(true);
          }}
          components={{
            activityNameRenderer: activityNameRenderer
          }}
          paginationPageSize={50}
          showSidebar={false}
          rowGroupPanelShow='never'
        />
      </div>

      <AddTypePopup currentRowData={currentRowData} setCurrentRowData={setCurrentRowData} isOpen={isAddOpen} setIsOpen={setIsAddOpen} refetch={refetch} />
    </Box>
  );
};

export default ActivityTypes;
