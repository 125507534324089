import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Chip, CircularProgress, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { picklistsDataApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';

// Utility function to safely access nested properties
const getNestedProperty = (obj, path) => {
  return path.split('.').reduce((acc, part) => (acc && acc[part] ? acc[part] : ''), obj);
};

const CustomValueSelection = ({
  maxWidth = '100%',
  minWidth,
  onInputChange = () => {},
  renderOption,
  labelProperty = '',
  onChange = () => {},
  picklistType,
  placeholder,
  textFieldStyle = {},
  options: dropdownOptions = null,
  maxVisibleTags = 2,
  handleOnKeyDown,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch options from the API
  useEffect(() => {
    if (dropdownOptions !== null) {
      setOptions(dropdownOptions);
      return;
    }
    const fetchOptions = async () => {
      try {
        setLoading(true);
        const response = await picklistsDataApis(GET, picklistType);
        if (response.status === 200) {
          setOptions(response.data || []);
          setFilteredOptions(response.data || []);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchOptions();
  }, [picklistType, dropdownOptions]);

  // Filter options based on user input
  useEffect(() => {
    const lowerCasedInput = inputValue.toLowerCase();
    const filtered =
      options?.filter(option => {
        const label = getNestedProperty(option, labelProperty)?.toLowerCase();
        return label?.includes(lowerCasedInput);
      }) || [];
    setFilteredOptions(filtered);
  }, [inputValue, options, labelProperty]);

  return (
    <Autocomplete
      options={filteredOptions}
      size='small'
      value={selectedOptions}
      isOptionEqualToValue={(option, value) => getNestedProperty(option, labelProperty) === getNestedProperty(value, labelProperty)}
      getOptionLabel={option => getNestedProperty(option, labelProperty)}
      onChange={(event, newValue) => {
        setSelectedOptions(newValue);
        onChange(event, newValue);
      }}
      sx={{
        maxWidth,
        minWidth,
        '.MuiAutocomplete-inputRoot': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        },
        '.MuiChip-root': {
          maxWidth: '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }
      }}
      onInputChange={(event, value) => {
        setInputValue(value);
        onInputChange?.(value);
      }}
      renderOption={renderOption}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant='outlined'
          sx={{
            '& .MuiInputBase-input::placeholder': {
              color: '#333333',
              fontSize: '0.8rem',
              opacity: 1
            },
            ...textFieldStyle
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='primary' size={'small'} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      popupIcon={<ExpandMoreIcon />}
      renderTags={(tagValue, getTagProps) => {
        const visibleTags = tagValue.slice(0, maxVisibleTags);
        const hiddenCount = tagValue.length - visibleTags.length;

        return (
          <Box display={'flex'} alignItems={'center'} width={'100%'}>
            <Box overflow={'hidden'} flex={1} textOverflow={'ellipsis'} whiteSpace={'nowrap'} display={'block'}>
              {visibleTags.map((option, index) => (
                <Typography component='span' fontSize={13} key={index}>
                  {getNestedProperty(option, labelProperty)}
                  {visibleTags?.[index + 1] ? ',' : ''}
                  {'\u00A0'}
                </Typography>
              ))}
            </Box>
            {hiddenCount > 0 && (
              <Tooltip
                title={
                  <Box display={'flex'} flexWrap={'wrap'} gap={1} sx={{ backgroundColor: 'white' }}>
                    {tagValue.map((option, index) =>
                      index >= visibleTags.length ? <Chip size='small' variant='outlined' key={index} label={getNestedProperty(option, labelProperty)} {...getTagProps({ index })} /> : null
                    )}
                  </Box>
                }
                interactive
                arrow
                placement='top'
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: '#ffffff',
                      color: '#000000',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    },
                    '& .MuiTooltip-arrow': {
                      color: '#ffffff'
                    }
                  }
                }}
              >
                <Chip variant='outlined' size='small' label={`+${hiddenCount}`} style={{ color: 'white', backgroundColor: 'var(--palette-primary-main)', fontWeight: 'bold', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Box>
        );
      }}
      onKeyDown={e => handleOnKeyDown?.(e, setInputValue)}
      {...rest}
    />
  );
};

export default CustomValueSelection;

CustomValueSelection.propTypes = {
  picklistType: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelProperty: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
  onInputChange: PropTypes.func,
  handleOnKeyDown: PropTypes.func,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  textFieldStyle: PropTypes.object,
  maxVisibleTags: PropTypes.number
};
