import PropTypes from 'prop-types';
import React from 'react';
import CustomPopup from '.';
import ContactMatchView from './ContactMatchView';
import { useTranslation } from 'react-i18next';

const DuplicateContactNotifier = props => {
  const { open = false, data, onConfirmData = () => {}, handleClose = () => {}, label, showAction = true, duplicationField } = props;
  const { t } = useTranslation();
  const handleConfirm = () => {
    onConfirmData();
  };

  return (
    <CustomPopup
      title={t('utils.duplicateRecordDetected')}
      open={open}
      onClose={handleClose}
      showAction={showAction}
      handleSubmit={handleConfirm}
      confirmText={label}
      disabled={data?.data?.exactMatch?.length > 0 ? true : false}
    >
      <ContactMatchView data={data} duplicationField={duplicationField} />
    </CustomPopup>
  );
};

DuplicateContactNotifier.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  final: PropTypes.object,
  onConfirmData: PropTypes.func,
  handleClose: PropTypes.func,
  label: PropTypes.string,
  showAction: PropTypes.bool,
  duplicationField: PropTypes.string
};

export default DuplicateContactNotifier;
