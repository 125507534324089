//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress } from '@mui/material';

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

export const columnDefs = [
  {
    field: 'search',
    colId: 'search',
    headerName: 'Search',
    cellRenderer: 'SearchIdRenderer',
    lockPinned: true,
    pinned: 'left',
    tooltipValueGetter: params => params?.value?.job_title
  },
  {
    field: 'leadership_attribute_1',
    colId: 'leadership_attribute_1',
    headerName: 'Leadership Attribute 1',
    tooltipValueGetter: params => params?.data?.leadership_attribute_definition_1
  },
  {
    field: 'leadership_attribute_2',
    colId: 'leadership_attribute_2',
    headerName: 'Leadership Attribute 2',
    tooltipValueGetter: params => params?.data?.leadership_attribute_definition_2
  },
  {
    field: 'leadership_attribute_3',
    colId: 'leadership_attribute_3',
    headerName: 'Leadership Attribute 3',
    tooltipValueGetter: params => params?.data?.leadership_attribute_definition_3
  },
  {
    field: 'leadership_attribute_4',
    colId: 'leadership_attribute_4',
    headerName: 'Leadership Attribute 4',
    tooltipValueGetter: params => params?.data?.leadership_attribute_definition_4
  },
  {
    field: 'leadership_attribute_5',
    colId: 'leadership_attribute_5',
    headerName: 'Leadership Attribute 5',
    tooltipValueGetter: params => params?.data?.leadership_attribute_definition_5
  },
  {
    field: 'leadership_attribute_6',
    colId: 'leadership_attribute_6',
    headerName: 'Leadership Attribute 6',
    tooltipValueGetter: params => params?.data?.leadership_attribute_definition_6
  },
  {
    field: 'building_successful_teams',
    colId: 'building_successful_teams',
    headerName: 'Building and Developing People And Teams'
  },
  {
    field: 'clarifying_roles_and_responsibilities',
    colId: 'clarifying_roles_and_responsibilities',
    headerName: 'Acting Decisively'
  },
  {
    field: 'connecting_with_people',
    colId: 'connecting_with_people',
    headerName: 'Connecting with People and The Vision of The Enterprise'
  },
  {
    field: 'creating_sense_of_common_purpose',
    colId: 'creating_sense_of_common_purpose',
    headerName: 'Collaborating Across Boundaries'
  },
  {
    field: 'creating_team_synergy',
    colId: 'creating_team_synergy',
    headerName: 'Influencing & Inspiring'
  },
  {
    field: 'decision_making',
    colId: 'decision_making',
    headerName: 'Leading with Authenticity'
  },
  {
    field: 'delegating_responsibility_and_authority',
    colId: 'delegating_responsibility_and_authority',
    headerName: 'Delegating Responsibility & Authority'
  },
  {
    field: 'demonstrating_courage',
    colId: 'demonstrating_courage',
    headerName: 'Demonstrating Courage'
  },
  {
    field: 'focusing_on_customers',
    colId: 'focusing_on_customers',
    headerName: 'Resolving Conflict'
  },
  {
    field: 'fostering_open_communications',
    colId: 'fostering_open_communications',
    headerName: 'Fostering Open Communications'
  },
  {
    field: 'gaining_and_maintaining_trust',
    colId: 'gaining_and_maintaining_trust',
    headerName: 'Knowing Oneself and Fine Tunning Impact on Others'
  },
  {
    field: 'increasing_knowledge_of_the_business',
    colId: 'increasing_knowledge_of_the_business',
    headerName: 'Increasing Knowledge of the Business'
  },
  {
    field: 'linking_organizational_objectives_to_cus',
    colId: 'linking_organizational_objectives_to_cus',
    headerName: 'Linking Objectives To Customer Value Creation'
  },
  {
    field: 'managing_individual_performance',
    colId: 'managing_individual_performance',
    headerName: 'Driving Performance'
  },
  {
    field: 'managing_resources',
    colId: 'managing_resources',
    headerName: 'Managing Resources'
  },
  {
    field: 'organizational_savvy',
    colId: 'organizational_savvy',
    headerName: 'Learning and Adapting'
  },
  {
    field: 'problem_solving',
    colId: 'problem_solving',
    headerName: 'Solving Problems'
  },
  {
    field: 'persuasiveness',
    colId: 'persuasiveness',
    headerName: 'Instilling Confidence'
  },
  {
    field: 'seeing_possibilities',
    colId: 'seeing_possibilities',
    headerName: 'Thinking Strategically'
  },
  {
    field: 'stimulating_people_thinking',
    colId: 'stimulating_people_thinking',
    headerName: "Stimulating & Expanding People's Thinking"
  },
  {
    field: 'created_at',
    colId: 'created_at',
    headerName: 'Created On',
    cellRenderer: 'DateTimeRenderer'
  },
  {
    field: 'updated_at',
    colId: 'updated_at',
    headerName: 'Updated On',
    cellRenderer: 'DateRenderer'
  }
];

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};
