import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import CustomInputField from '../../components/common/StyledComponents/CustomInputField';
import { PortalDataApi } from '../../services/PublicApiService';
import { GET, PUBLIC_BASE_ROUTES } from '../../services/constantService';
import useApi from '../../utils/Hooks/useApiHook';
import HeaderComponent from '../Commons/PublicHeaderComponent';
import JobCard from './Components/JobCard';
import JobViewMore from './Components/JobViewMore';
import './index.scss';
export default function JobBoard() {
  const location = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [consent, setConsent] = useState(false);
  const { data: jobBoardData, success: isJobBoardData } = useApi({
    queryFn: () => {
      return PortalDataApi(GET, 'all', '', '');
    }
  });

  const [jobs, setJobs] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [loader, setLoader] = useState(false);
  const handleJobPostViewMore = data => {
    const id = data?.id;
    navigate({ pathname: `${PUBLIC_BASE_ROUTES.jobBoard}/${id}` });
    setDrawerData(data);
    setDrawerOpen(true);
  };
  const handleJobPostViewClose = data => {
    navigate({ pathname: `${PUBLIC_BASE_ROUTES.jobBoard}` });
    setDrawerData({});
    setDrawerOpen(false);
  };

  const fetchJobById = async id => {
    try {
      const data = await PortalDataApi(GET, id, '', 'get', 'form-data', {
        // 'X-Amz-Content-Sha256': 'UNSIGNED-PAYLOAD'
      });
      if (data) {
        setDrawerData(data);
        setDrawerOpen(true);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const filterJobs = name => {
    const job = jobBoardData?.data?.filter(job => job?.ign_projects?.name.includes(name));
    setJobs(job);
  };

  useEffect(() => {
    if (jobBoardData?.data) {
      setJobs(jobBoardData?.data ?? []);
    }
  }, [jobBoardData]);

  useEffect(() => {
    try {
      if (location?.id) {
        setLoader(true);
        fetchJobById(location?.id);
        setLoader(false);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  }, [location]);
  return (
    <Box>
      <HeaderComponent />
      <Box className='job-container p-3'>
        <Box className='job-title-container p-2 rounded border shadow '>
          <CustomInputField onChange={e => filterJobs(e.target.value)} label={'Search'} className={'m-1 w-25'} endIcon={<SearchIcon />} />
        </Box>
        <Grid container fullWidth spacing={2} rowSpacing={4} className='job-cards mt-1'>
          <Loader show={!isJobBoardData || loader} />

          {jobs &&
            jobs.length > 0 &&
            jobs?.map(job => (
              <Grid key={job?.id} item sx={12} md={4}>
                <JobCard data={job} onClickViewButton={handleJobPostViewMore} />
              </Grid>
            ))}
          {jobs && jobs.length == 0 && <div className='fs-14 d-flex justify-content-center align-items-center w-100'>No Job Posting Present</div>}
        </Grid>
      </Box>
      <JobViewMore isOpen={isDrawerOpen} data={drawerData} handleClose={handleJobPostViewClose} consent={consent} setConsent={setConsent} />
    </Box>
  );
}
