import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
// import { FormLabel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Footer from '../../components/common/Footer';
import ExportData from '../../components/common/FunctionalComponents/ExportDataSelection';
import ExportFilters from '../../components/common/FunctionalComponents/ExportFilterSelection';
import FileTypes from '../../components/common/FunctionalComponents/FileTypeSelection';
export default function ExportList() {
  const { t } = useTranslation();
  return (
    <div id='side-pop-up' className='p-4'>
      <form>
        <Stack spacing={2}>
          <Stack spacing={4}>
            {/* <FormLabel className="fs-14">
              {t(`utils.selectData`)}
            </FormLabel> */}
            <ExportData label={t('utils.selectData')} />
            {/* <FormLabel className="fs-14">
              {t(`utils.selectFilters`)}
            </FormLabel> */}
            <ExportFilters label={t('utils.selectFilters')} />
            {/* <FormLabel className="fs-14">
              {t(`utils.selectFileType`)}
            </FormLabel> */}
            <FileTypes label={t('utils.selectFileType')} />
          </Stack>
          <Stack className='sidePopup-stack' spacing={2} useFlexGap direction={'row'} mx={4}>
            <Footer></Footer>
          </Stack>
        </Stack>
      </form>
    </div>
  );
}

ExportList.propTypes = {
  location: PropTypes.object
};
