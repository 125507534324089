import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScoreCardGauge from '../../../../ScoreCardGauge';

function AiRating(props) {
  const { igNyteRating = 0, recruiterRating = 0, consultantRating = 0, showAiRatingFields, isLogAnActivityOpen } = props;
  const { t } = useTranslation();

  return (
    <div className='d-flex justify-content-space-evenly flex-wrap' style={{ padding: '20px 0' }}>
      {showAiRatingFields.ignyteAIRating.value && (
        <Box
          maxWidth='340px'
          p='12px'
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          className={`contact-details-box ${isLogAnActivityOpen ? 'contact-details-candidate-score-ai-rating' : ''}`}
        >
          <Typography component='p' position='relative' width='fit-content' fontWeight='normal' color='#444444' fontSize='14px' lineHeight='normal'>
            <span className='ai-r-label' style={{ fontWeight: 'bold', fontSize: '13px !important' }}>
              {t('project.candidateScore.aiRatingLabel')}
            </span>
            <span
              style={{
                fontSize: '10px !important',
                marginTop: '4px'
              }}
            >
              {t('project.candidateScore.average')}: <span style={{ fontWeight: 'bold' }}>{igNyteRating}</span>
            </span>
          </Typography>
          <ScoreCardGauge value={igNyteRating} />
        </Box>
      )}

      {showAiRatingFields.recruiterRating.value && (
        <Box
          maxWidth='340px'
          p='12px'
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          className={`contact-details-box ${isLogAnActivityOpen ? 'contact-details-candidate-score-ai-rating' : ''}`}
        >
          <Typography component='p' position='relative' width='fit-content' fontWeight='normal' color='#444444' fontSize='14px' lineHeight='normal'>
            <span className='ai-r-label' style={{ fontWeight: 'bold', fontSize: '13px !important' }}>
              {t('project.candidateScore.recruiterRatingLabel')}
            </span>
            <span
              style={{
                fontSize: '10px !important',
                marginTop: '4px'
              }}
            >
              {t('project.candidateScore.average')}: <span style={{ fontWeight: 'bold' }}>{parseFloat(recruiterRating.toFixed(2))}</span>
            </span>
          </Typography>
          <ScoreCardGauge value={recruiterRating} />
        </Box>
      )}

      {showAiRatingFields.consultantRating.value && (
        <Box
          maxWidth='340px'
          p='12px'
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          className={`contact-details-box ${isLogAnActivityOpen ? 'contact-details-candidate-score-ai-rating' : ''}`}
        >
          <Typography component='p' position='relative' width='fit-content' fontWeight='normal' color='#444444' fontSize='14px' lineHeight='normal'>
            <span className='ai-r-label' style={{ fontWeight: 'bold', fontSize: '13px !important' }}>
              {t('project.candidateScore.consultantRatingLabel')}
            </span>
            <span
              style={{
                fontSize: '10px !important',
                marginTop: '4px'
              }}
            >
              {t('project.candidateScore.average')}: <span style={{ fontWeight: 'bold' }}>{parseFloat(consultantRating.toFixed(2))}</span>
            </span>
          </Typography>
          <ScoreCardGauge value={consultantRating} />
        </Box>
      )}
    </div>
  );
}

AiRating.propTypes = {
  igNyteRating: PropTypes.number,
  recruiterRating: PropTypes.number,
  consultantRating: PropTypes.number,
  showAiRatingFields: PropTypes.object,
  isLogAnActivityOpen: PropTypes.bool
};

export default AiRating;
