import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddCompany from '../../../../../components/AddCompany';
import Loader from '../../../../../components/common/Loader';
import { TargetCompanyToAvoidDataApi, ignCompanyDataApi } from '../../../../../services/ApiService';
import { DELETE, ERROR, POST, SUCCESS } from '../../../../../services/constantService';
import { customFormValidator, splitLastIfMultiple } from '../../../../../utils/common';
import { useCustomMessageHook } from '../../../../../utils/Hooks/useCustomMessageHook';
import { createRequiredField, createSchema } from '../../../../Companies/Utils';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';
function TargetCompaniesToAvoid() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { translateMessage } = useCustomMessageHook();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const { projectData, handleUniversalState, getValueFromUniversalState, setRefresh, refresh } = useContext(ProjectDataContext);
  const [recommendedCompaniesToAvoid, setRecommendedCompaniesToAvoid] = useState([]);
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetCompaniesToAvoid',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const deleteCompany = async option => {
    try {
      setLoader(true);
      if (!option?.id?.includes('new')) {
        await TargetCompanyToAvoidDataApi(DELETE, '', {
          id: option?.id
        });
      }

      const updatedCompanies = recommendedCompaniesToAvoid.filter(curr => curr.id != option.id);
      setRecommendedCompaniesToAvoid(updatedCompanies);
      handleUniversalStateLocally(updatedCompanies);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  const addCompany = companyData => {
    const newCompany = {
      ...companyData,
      id: 'new-' + companyData.id,
      companyData
    };
    const updatedCompanies = [...recommendedCompaniesToAvoid, newCompany];
    setRecommendedCompaniesToAvoid(updatedCompanies);
    handleUniversalStateLocally(updatedCompanies);
  };

  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries
        ? data?.company_industries.map(industry => ({
            industry_id: industry.id
          }))
        : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value
    };
    return addCompanyPayload;
  };

  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const saveData = async () => {
    let formValues = { ...getValues() };
    const requestBody = getPayload(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody) {
      setLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        // setCompanyId(data.id);
        setAddCompanyPopup(false);
        reset();

        setLoader(false);
        addCompany({ ...formValues, ...data });
        // return data.id;
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Company');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!isDataLoaded) {
      setRecommendedCompaniesToAvoid(projectData?.companiesToAvoid || []);
      if (projectData?.targetIndustries) {
        setIsDataLoaded(true);
      }
    }
    if (refresh) {
      setRecommendedCompaniesToAvoid(projectData?.companiesToAvoid || []);
      setRefresh(false);
    }
  }, [projectData, getValueFromUniversalState('targetCompaniesToAvoid')]);

  useEffect(() => {
    if (isDataLoaded) {
      setRecommendedCompaniesToAvoid(getValueFromUniversalState('targetCompaniesToAvoid') || []);
    }
  }, [getValueFromUniversalState('targetCompaniesToAvoid')]);

  useEffect(() => {
    const data = getValueFromUniversalState('newTargetCompaniesToAvoid');
    if (data) {
      setRecommendedCompaniesToAvoid([...recommendedCompaniesToAvoid, data]);
      handleUniversalStateLocally([...recommendedCompaniesToAvoid, data]);
      handleUniversalState({
        target: {
          name: 'newTargetCompaniesToAvoid',
          value: null
        }
      });
    }
  }, [getValueFromUniversalState('newTargetCompaniesToAvoid')]);
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        isCompanySearch
        isNegative
        isGetMore={false}
        searchLabel={t('utils.addCompaniesToAvoid')}
        onSearch={addCompany}
        recommendedOptions={recommendedCompaniesToAvoid}
        onClickRecommendOption={deleteCompany}
      />
      <AddCompany
        isPopupOpen={addCompanyPopup}
        handleClose={() => setAddCompanyPopup(false)}
        handleSubmit={saveData}
        setIsLoading={loader}
        setValue={setValue}
        register={register}
        reset={reset}
        setIsPopupOpen={setAddCompanyPopup}
        unregister={unregister}
        watch={watch}
      ></AddCompany>
    </Box>
  );
}

export default TargetCompaniesToAvoid;
