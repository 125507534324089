import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
function ListItemComponent(props) {
  const { id, title1, value1, title2, value2, title3, value3, icon1, icon2, icon3, onEdit, onDelete, onEditProp, customList, actionBtnXs = 1, addEditBorder = false, sx } = props;
  const list = [
    {
      id: 1,
      icon: icon1 || <BusinessIcon />,
      title: title1,
      value: value1,
      xs: 4
    },
    {
      id: 2,
      icon: icon2 || <EventAvailableIcon />,
      title: title2,
      value: value2,
      xs: 4
    },
    {
      id: 3,
      icon: icon3 || <LocationOnIcon />,
      title: title3,
      value: value3,
      xs: 3
    }
  ];
  return (
    <Grid
      container
      sx={{
        borderRadius: '8px',
        boxShadow: !addEditBorder ? 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' : 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
        transition: 'all 0.5s ease',
        p: 0.5,
        pl: 1.5,
        mb: 2,
        bgcolor: 'background.main',
        ...sx
      }}
    >
      {customList
        ? customList?.map((item, index) => (
            <Grid item xs={item.xs} key={index} width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Stack spacing={1} alignItems='flex-start' justifyContent={'center'} height={'100%'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'4px'}>
                    {item.icon}
                  </Stack>
                  <Stack alignItems={'flex-start'} justifyContent={'center'} gap={'4px'}>
                    <Typography className='fs-14' fontWeight={'500'} onClick={item?.onClick} sx={{ cursor: item?.onClick ? 'pointer' : null, ...item?.sx }}>
                      {item.title}
                    </Typography>
                    {item.value && (
                      <Typography className='fs-12' sx={{ cursor: item?.onPressCompanyName ? 'pointer' : null, ...item?.valueSx }} onClick={item?.onPressCompanyName}>
                        {item.value}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          ))
        : list.map((item, index) => (
            <Grid item xs={item.xs} key={index} width={'100%'} justifyContent={'center'}>
              <Stack spacing={1} alignItems='flex-start' justifyContent={'center'} height={'100%'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'4px'}>
                    {item.icon}
                  </Stack>
                  <Stack alignItems={'flex-start'} justifyContent={'center'} gap={'4px'}>
                    <Typography className='fs-14' fontWeight={'500'}>
                      {item.title}
                    </Typography>
                    {item.value && <Typography className='fs-12'>{item.value}</Typography>}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          ))}
      <Grid
        item
        xs={actionBtnXs}
        width={'100%'}
        sx={{
          mb: 1,
          p: 1
        }}
      >
        <Stack direction={'row'} gap={1} justifyContent={'flex-end'} alignItems={'center'}>
          {onEdit && (
            <Button
              variant='text'
              sx={{
                p: 0,
                borderRadius: '50%',
                minWidth: '30px',
                minHeight: '30px'
              }}
              onClick={() => onEdit(onEditProp)}
            >
              <EditIcon fontSize='small' />
            </Button>
          )}
          {onDelete && (
            <Button
              variant='text'
              sx={{
                borderRadius: '50%',
                minWidth: '30px',
                minHeight: '30px',
                p: 1
              }}
              onClick={() => onDelete(id)}
            >
              <CloseIcon fontSize='small' color='error' />
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
const ensurePrimaryExists = list => {
  if (!list || list.length === 0) {
    return [];
  }
  // Check if any item is already marked as primary
  const hasPrimary = list.some(item => item.is_primary === true);

  // If no primary exists, mark the first item as primary
  if (list.length === 1) {
    return [{ ...list[0], is_primary: true }, ...list.slice(1)];
  }
  if (!hasPrimary) {
    return [{ ...list[0], is_primary: true }, ...list.slice(1)];
  }

  return list;
};
const TableColumn = ({ columnList = [] }) => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent={'center'} alignItems={'center'} height={'100%'}>
      {columnList.map((column, index) => {
        return (
          <Grid
            item
            xs={column.xs}
            key={index}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              width: '100%',
              fontSize: '14px',
              fontWeight: '500',
              color: 'primary.main',
              // textAlign: 'center',
              pl: 1.5
            }}
          >
            {t(column.headerName)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export { ensurePrimaryExists, ListItemComponent, TableColumn };

ListItemComponent.propTypes = {
  title1: PropTypes.string,
  value1: PropTypes.string,
  title2: PropTypes.string,
  value2: PropTypes.string,
  title3: PropTypes.string,
  value3: PropTypes.string,
  icon1: PropTypes.node,
  icon2: PropTypes.node,
  icon3: PropTypes.node,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  id: PropTypes.number,
  onEditProp: PropTypes.object,
  sx: PropTypes.object,
  customList: PropTypes.array,
  actionBtnXs: PropTypes.number,
  addEditBorder: PropTypes.bool
};
TableColumn.propTypes = {
  columnList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
