import { Box, Checkbox, Typography } from '@mui/material';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { fetchGridData, fetchPickLists } from '../../../../actions';
import { globalSearchSelector } from '../../../../selectors';
import { globalSearchApi } from '../../../../services/ApiService';
import { updateGlobalSearchData } from '../../../../store/globalSearchSlice';
import AgGridWrapper from '../../../../utils/AgGridWrapper';
import ViewCompanyDrawer from '../../../ViewCompany/ViewCompanyDrawer';
import ViewContactDrawer from '../../../ViewContactDrawer';
import { columnDefs, SUCCESS_STATUS_CODE } from '../../helper/columnDefs';
import { getFormattedData, getTotalCount } from '../../helper/utils';
import '../../index.scss';

export const TabContent = ({ gridApiRef }) => {
  const { selectedTabId, search } = useParams();

  const searchValue = search;

  const { tabs, limit, selectedContactId, selectedCompanyId, finalFilterModel } = useSelector(globalSearchSelector);
  const companyStatusOption = useSelector(state => state.commonReducer.companyStatus);
  const { pathname } = useLocation();

  const PAGE_LIMIT = limit;
  const selectedTab = tabs?.find(t => t?.model === selectedTabId);

  const [options, setOptions] = useState([]);
  const [gridState, setGridState] = useState([]);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});
  const dataToFilter = useRef({
    rows: [],
    count: 0
  });

  const gridApi = useRef(null);
  const columnApi = useRef(null);
  const gridRef = useRef(null);

  const dispatch = useDispatch();

  const getRows = (selectedTabId, data, sort) => {
    const tabData = data?.find(t => t?.model === selectedTabId);
    const results = (tabData?.data?.hits?.hits || [])?.map(item => getFormattedData(selectedTabId, item, sort));
    return results;
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    gridRef.current = params;
    dispatch(updateGlobalSearchData({ key: 'gridApi', data: gridApi }));
    params.api.sizeColumnsToFit();
    gridApiRef.current = params;
  };

  useEffect(() => {
    gridApi.current?.setGridOption('datasource', dataSource);
  }, [gridApi.current]);

  const defaultColumns = columnDefs(selectedTabId);

  useEffect(() => {
    if (!companyStatusOption) {
      dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
    } else {
      setOptions(companyStatusOption);
    }
  }, [companyStatusOption, dispatch]);

  const getGridSettings = useCallback(() => {
    fetchGridData('', selectedTabId, ({ status, data }) => {
      if (status) {
        const sortOrder = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');
        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (sortOrder.length) {
          const savedConfigMap = sortOrder.reduce((acc, column) => {
            acc[column.colId] = column;
            return acc;
          }, {});
          const updatedColDefs = [...defaultColumns].map(col => {
            const savedConfig = savedConfigMap[col.colId];
            if (savedConfig) {
              const filteredOutConfig = omit(savedConfig, ['sort', 'sortIndex']);
              return {
                ...col,
                ...filteredOutConfig
              };
            }
            return col;
          });
          updatedColDefs.sort((a, b) => {
            const indexA = sortOrder.findIndex(column => column.colId === a.colId);
            const indexB = sortOrder.findIndex(column => column.colId === b.colId);
            return indexA - indexB;
          });

          return setGridState([...updatedColDefs]);
        } else {
          return setGridState([...defaultColumns]);
        }
      } else {
        return setGridState([...defaultColumns]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedTabId) {
      getGridSettings();
    }
  }, []);

  const dataSource = {
    getRows: async params => {
      const { filterModel, sortModel } = params;
      const isLocalFilterApplied = (Object.keys(filterModel).length > 0 && Object.values(filterModel).every(filter => filter.filterType === 'multi')) || sortModel?.length;
      if (!loadingRef.current && isLocalFilterApplied) {
        return applyFilters(dataToFilter.current?.rows, params);
      }
      const subTabfilter = finalFilterModel?.subTabsFilter?.filter ? JSON.parse(finalFilterModel?.subTabsFilter?.filter || '{}') : {};
      const sortFilter = finalFilterModel?.sortFilter?.filter ? JSON.parse(finalFilterModel?.sortFilter?.filter || '{}') : {};
      let formattedFilters = {};

      if (subTabfilter?.filters) {
        Object.keys(subTabfilter?.filters).forEach(key => {
          const values = subTabfilter?.filters?.[key]?.values?.map(item => item?.value);
          formattedFilters[key] = values;
        });
      }

      const models = selectedTabId && selectedTabId !== 'all' ? [selectedTabId] : [];

      const payload = {
        keyword: searchValue,
        size: PAGE_LIMIT,
        from: params.endRow - PAGE_LIMIT,
        models,
        filters: formattedFilters
      };

      if (!searchValue && Object.keys(finalFilterModel).length === 0) {
        params.successCallback([], 0);
        return;
      }

      try {
        dispatch(updateGlobalSearchData({ key: 'loading', data: true }));
        gridApi.current.showLoadingOverlay();
        const { status, data } = await globalSearchApi(payload);
        if (status === SUCCESS_STATUS_CODE) {
          let results = getRows(selectedTabId, data, sortFilter?.sort);
          let count = getTotalCount(selectedTabId, data);
          dataToFilter.current = { rows: results, count };
          loadingRef.current = false;

          if (isLocalFilterApplied) {
            return applyFilters(results, params, count);
          }
          params.successCallback(results, count);

          dispatch(updateGlobalSearchData({ key: 'totalCount', data: count }));
          dispatch(updateGlobalSearchData({ key: 'data', data }));
        } else {
          params.failCallback();
        }
      } catch (error) {
        params.failCallback();
      } finally {
        gridApi.current.hideOverlay();
        dispatch(updateGlobalSearchData({ key: 'loading', data: false }));
      }
    }
  };

  const isMountedRef = useRef(false);
  const loadingRef = useRef(true);

  useEffect(() => {
    if (isMountedRef && Object.keys(finalFilterModel).length) {
      loadingRef.current = true;
      gridApi.current?.setGridOption('datasource', dataSource);
    }
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, [finalFilterModel]);

  const onSelectionChanged = () => {
    // const selectedRows = gridApi.current.getSelectedRows();
    // console.log('Selected Rows:', selectedRows);
  };

  const setSelectAllRows = isAllRowsSelected => {
    gridApi.current.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const headerCheckbox = () => {
    return <Checkbox classes={{ root: 'custom-checkbox' }} size='small' onChange={e => handleChange(e)} />;
  };

  return (
    <Box width='100%'>
      {selectedContactId && (
        <ViewContactDrawer
          navigateToAllContacts={false}
          isDrawerOpen={selectedContactId !== null}
          setIsDrawerOpen={() => dispatch(updateGlobalSearchData({ key: 'selectedContactId', data: null }))}
          id={selectedContactId}
          baseRoute={pathname}
        />
      )}
      {selectedCompanyId && (
        <ViewCompanyDrawer
          baseRoute={pathname}
          setIsEdited={() => {}}
          setIsUpdateCompany={() => {}}
          isUpdateCompany={false}
          options={options}
          isDrawerOpen={selectedCompanyId !== null}
          setIsDrawerOpen={() => dispatch(updateGlobalSearchData({ key: 'selectedCompanyId', data: null }))}
          id={selectedCompanyId}
        />
      )}
      <Box display={'flex'} borderRadius={1} minHeight={'70vh'} flexDirection={'column'} bgcolor={'Background'}>
        {selectedTabId === 'all' && (
          <Typography fontWeight={'bolder'} fontSize={14} color={'black'}>
            {selectedTab?.label}
          </Typography>
        )}
        <Box className='ag-theme-alpine header-column-filter global-search-companies' style={{ width: '100%' }}>
          <AgGridWrapper
            defaultColumnDefs={defaultColumns}
            gridType={selectedTabId}
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              width: 100,
              sortingOrder: ['asc', 'desc', null],
              cellStyle: {
                display: 'flex',
                alignItems: 'center'
              }
            }}
            components={{ headerCheckbox }}
            filterModel={savedFilterModel}
            sortModel={savedSortModel}
            columnDefs={[...gridState]}
            rowModelType='infinite'
            rowSelection='multiple'
            showHeaderMenu={true}
            tooltipShowDelay={0}
            cacheBlockSize={PAGE_LIMIT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressRowClickSelection={true}
            // getRowNodeId={data => data.id}
            onSelectionChanged={onSelectionChanged}
            rowHeight={100}
            disableStaticMarkup
            rowGroupPanelShow='always'
            saveGridSetting={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

TabContent.propTypes = {
  gridApiRef: PropTypes.object
};

const getNestedProperty = (obj, path) => {
  return path.split('.').reduce((acc, part) => (acc && acc[part] ? acc[part] : ''), obj);
};

function applyFilters(dataToFilter, params) {
  const { sortModel, filterModel, startRow, endRow } = params;
  let filteredRows = dataToFilter;
  if (Object.keys(filterModel)?.length) {
    filteredRows = dataToFilter?.filter(row =>
      Object.entries(filterModel).every(([field, filter]) => {
        if (field === 'name') {
          field = 'name.name';
        }
        if (field === 'note_text') {
          field = 'item._source.notes';
        }

        const filterTypeContains = filter?.filterModels?.find(fil => fil?.type === 'contains');
        const filterTypeOr = filter?.filterModels?.find(fil => fil?.operator === 'OR');
        const filterTypeAnd = filter?.filterModels?.find(fil => fil?.operator === 'AND');

        let value = getNestedProperty(row, field);

        if (field === 'tags') {
          value = value
            ?.filter(tag => tag?.name)
            ?.map(tag => tag?.name)
            ?.join(',');
        } else if (Array.isArray(value)) {
          value = value?.join(',');
        }

        if (filterTypeContains) {
          return value?.toString()?.toLowerCase()?.includes(filterTypeContains?.filter?.toLowerCase());
        }
        if (filterTypeOr) {
          const result = filterTypeOr?.conditions?.some(fil => {
            const fieldValue = value?.toString()?.toLowerCase();
            return fieldValue?.includes(fil?.filter?.toLowerCase());
          });
          return result;
        }
        if (filterTypeAnd) {
          const result = filterTypeAnd?.conditions?.every(fil => {
            const fieldValue = value?.toString()?.toLowerCase();
            return fieldValue?.includes(fil?.filter?.toLowerCase());
          });
          return result;
        }
        return true;
      })
    );
  }

  let sortedRows = [...filteredRows];
  if (sortModel?.length) {
    sortedRows.sort((a, b) => {
      for (let i = 0; i < sortModel.length; i++) {
        let { colId, sort } = sortModel[i];
        if (colId === 'name') {
          colId = 'name.name';
        }
        if (colId === 'note_text') {
          colId = 'item._source.notes';
        }

        let valA = getNestedProperty(a, colId);
        let valB = getNestedProperty(b, colId);

        if (colId === 'tags') {
          valA = valA
            ?.filter(tag => tag?.name)
            ?.map(tag => tag?.name)
            ?.join(',');
          valB = valB
            ?.filter(tag => tag?.name)
            ?.map(tag => tag?.name)
            ?.join(',');
        } else if (Array.isArray(valA) || Array.isArray(valB)) {
          valA = valA?.join(',');
          valB = valB?.join(',');
        }

        const valueA = valA?.toString()?.toLowerCase() ?? '';
        const valueB = valB?.toString()?.toLowerCase() ?? '';
        if (valueA < valueB) return sort === 'asc' ? -1 : 1;
        if (valueA > valueB) return sort === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  // Return filtered rows to ag-Grid
  const rowsForPage = sortedRows?.slice(startRow, endRow);
  params.successCallback(rowsForPage, filteredRows.length);
}
