import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Button, Divider, Grid, Stack } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormGrid } from './FormGrid';

function AvatarComponent(props) {
  const { uploadTranslateTitle, imageSrc, setValue } = props;
  const [profileUrl, setProfileUrl] = useState(null);
  const handleUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = e => {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      };
      fr.readAsDataURL(file);
      setValue('avatar', e.target.files);
      input.click();
    };
    input.click();
  };

  const handleRemove = () => {
    setProfileUrl(null);
    setValue('avatar', null);
  };

  return (
    <>
      {profileUrl ? (
        <Stack position={'relative'}>
          <Avatar
            alt='avatar'
            src={profileUrl}
            sx={{
              height: '200px',
              width: '200px',
              borderRadius: '50%',
              border: '1px solid',
              borderColor: 'primary.main'
            }}
          />
          <Stack
            direction={'row'}
            sx={{
              borderRadius: '24px'
            }}
            position={'absolute'}
            bottom={'20px'}
            right={'60px'}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={'white'}
            border={'1px solid'}
            borderColor={'primary.main'}
          >
            <Box sx={{ p: 1 }} onClick={handleUpload}>
              <FileUploadOutlinedIcon color={'primary.main'} fontSize='large' />
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ p: 1 }} onClick={handleRemove}>
              <CloseOutlinedIcon color={'error'} fontSize='large' />
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Box height={'200px'} width={'200px'} borderRadius={'50%'} border={'1px solid'} borderColor={'primary.main'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Stack>
            <PersonIcon sx={{ fontSize: '74px', color: 'background.main' }} />
            <Button variant='text' color='primary' onClick={handleUpload}>
              <Stack alignItems={'center'}>
                <FileUploadOutlinedIcon color={'primary.main'} fontSize='large' />
                {uploadTranslateTitle}
              </Stack>
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}

function AddContactDrawerSectionOne(props) {
  const { setValue, watch, errors, uploadTranslateTitle, recordTypeDropdownOptions, loadingDropdownData, type } = props;

  const columns = [
    {
      id: 'first_name',
      label: 'addContactDrawer.firstName',
      type: 'text',
      required: true,
      error: errors.first_name,
      value: watch('first_name'),
      onChange: e => setValue('first_name', e.target.value),
      style: type === 'edit' ? { display: 'none' } : {},
      xs: 5.5
    },
    {
      id: 'last_name',
      label: 'addContactDrawer.lastName',
      type: 'text',
      required: true,
      error: errors.last_name,
      value: watch('last_name'),
      onChange: e => setValue('last_name', e.target.value),
      xs: 5.5,
      style: type === 'edit' ? { display: 'none' } : {}
    },
    {
      id: 'email',
      label: 'addContactDrawer.email',
      type: 'text',
      required: false,
      error: errors.email,
      value: watch('email'),
      onChange: e => {
        setValue('email', e.target.value);
        debounceAddEmailValue(e.target.value);
      },
      xs: 5.5,
      style: type === 'edit' ? { display: 'none' } : {}
    },
    {
      id: 'phone',
      label: 'addContactDrawer.phone',
      type: 'phone',
      required: false,
      error: errors.phone,
      value: watch('phone'),
      onChange: e => {
        setValue('phone', e);
        debounceAddPhoneValue(e);
      },
      size: 'small',
      xs: 5.5,
      style: type === 'edit' ? { display: 'none' } : {}
    },
    {
      id: 'linkedin_url',
      label: 'addContactDrawer.linkedinUrl',
      type: 'text',
      required: false,
      error: errors.linkedin_url,
      value: watch('linkedin_url'),
      onChange: e => setValue('linkedin_url', e.target.value),
      xs: 5.5
    },
    {
      id: 'record_type',
      label: 'addContactDrawer.recordType',
      type: 'dropdown',
      multiple: true,
      required: false,
      error: errors.record_type,
      options: recordTypeDropdownOptions,
      getOptionLabel: option => option.name,
      loading: loadingDropdownData,
      value: watch('record_type') || [],
      onChange: (e, value) => setValue('record_type', value),
      xs: type === 'edit' ? 6 : 5.5
    },
    {
      id: 'record_source',
      label: 'addContactDrawer.recordSource',
      type: 'text',
      required: false,
      error: errors.record_source,
      value: watch('record_source'),
      onChange: e => setValue('record_source', e.target.value),
      xs: 5.5,
      style: type === 'edit' ? { display: 'none' } : {}
    },
    {
      id: 'is_confidential',
      label: 'addContactDrawer.makeConfidential',
      type: 'checkbox',
      required: false,
      error: errors.is_confidential,
      value: watch('is_confidential'),
      onChange: e => setValue('is_confidential', e.target.checked),
      xs: 5.5,
      sx: type === 'edit' ? { display: 'none' } : {}
    }
  ];

  const updatePhoneValue = value => {
    const alreadyExists = watch('contact_phones')?.find(phone => phone.is_primary === true);
    const newVal = [...(watch('contact_phones') || [])];
    if (!alreadyExists) {
      newVal.push({
        phone_number: value,
        phone_type: { name: 'Work', id: 'work' },
        is_primary: true,
        edit: false,
        id: Math.random() * 1000000000
      });
    }
    newVal.forEach(phone => {
      if (true === phone.is_primary) {
        phone.phone_number = value;
        phone.is_primary = true;
      } else {
        phone.is_primary = false;
      }
    });
    setValue('contact_phones', newVal);
  };

  const debounceAddPhoneValue = useCallback(
    debounce(value => {
      updatePhoneValue(value);
    }, 300),
    [watch, setValue]
  );
  const updateEmailValue = value => {
    const alreadyExists = watch('contact_emails')?.find(email => email.is_primary === true);
    const newVal = [...(watch('contact_emails') || [])];
    if (!alreadyExists) {
      newVal.push({
        email: value,
        is_primary: true,
        email_type: { name: 'Work', id: 'work' },
        edit: false,
        id: Math.random() * 1000000000
      });
    }
    newVal.forEach(email => {
      if (true === email.is_primary) {
        email.email = value;
        email.is_primary = true;
      } else {
        email.is_primary = false;
      }
    });
    setValue('contact_emails', newVal);
  };
  const debounceAddEmailValue = useCallback(
    debounce(value => {
      updateEmailValue(value);
    }, 300),
    [watch, setValue]
  );
  return (
    <Grid container pb={4}>
      <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ display: type === 'edit' ? 'none' : 'flex' }}>
        <AvatarComponent uploadTranslateTitle={uploadTranslateTitle} imageSrc={watch('avatar')} setValue={setValue} />
      </Grid>
      <Grid item xs={type === 'edit' ? 12 : 9}>
        <FormGrid columns={columns} rowGap={type === 'edit' ? 0 : 4} columnGap={2} loading={loadingDropdownData} />
      </Grid>
    </Grid>
  );
}

export default AddContactDrawerSectionOne;

AddContactDrawerSectionOne.propTypes = {
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  uploadTranslateTitle: PropTypes.string.isRequired,
  recordTypeDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  loadingDropdownData: PropTypes.bool,
  type: PropTypes.string
};
AvatarComponent.propTypes = {
  uploadTranslateTitle: PropTypes.string.isRequired,
  imageSrc: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  setValue: PropTypes.func.isRequired
};
