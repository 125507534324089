import { createSlice } from '@reduxjs/toolkit';

/**
 * @module ValidatePermit
 * @description
 * A component used to conditionally show or hide children components based on user permissions.
 * If the user does not have the required permission, the children will be disabled.
 * The type of disabled UI is determined by the `type` parameter.
 *
 * ⚠️ **CAUTION**: Improper configuration of permissions may disable the component.
 *
 * - **IMPORTANT**: Ensure that the permission starts with an action and ends with the type of request.
 * - **IMPORTANT**: Ensure the action is defined in the permissions object and is a boolean value.
 *
 * If a permission is not defined in the initial state, you can add it manually as a `string[]` in the format `['action', 'request-type']`.
 *
 * @example
 * // Example for adding multiple permissions:
 * // Each permission is defined as an array with action and request type.
 * // e.g. [['action1', 'request-type'], ['action2', 'request-type']]
 * // Refer to `quickLinks` in the initial state for more details.
 *
 * @example
 * // Usage example:
 * <ValidatePermit root='home' path='recentActivity' type='btn-disabled'>
 *   {children}
 * </ValidatePermit>
 * // This will show the `children` only if the user has the appropriate permission
 * // for viewing the `recentActivity` component.
 *
 * @param {string} root - The root context for the permission check. It should only contain the root-key.
 * @param {string} path - The specific path to check for permissions.
 * @param {string} type - Defines the type of disabled UI if permission is not granted (e.g., 'btn-disabled').
 */
const initialState = {
  loading: true,
  error: null,
  response: {},
  useSecurity: false
};

const componentPermissionSlice = createSlice({
  name: 'permission/componentPermissionSlice',
  initialState,
  reducers: {
    isLoading: (state, action) => {
      state.loading = action.payload;
    },
    hasError: (state, action) => {
      state.error = action.payload;
    },
    updateResponse: (state, action) => {
      state.response = action.payload;
    },
    updateUseSecurity: (state, action) => {
      state.useSecurity = action.payload;
    }
  }
});

export const { isLoading, hasError, updateResponse, updateUseSecurity } = componentPermissionSlice.actions;

export default componentPermissionSlice.reducer;
