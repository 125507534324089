//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const formatOptions = data => {
  return data.map(item => {
    return {
      id: item?.id,
      label: item?.stage_label,
      name: item?.stage_name
    };
  });
};

const MultiStageSelection = props => {
  const { defaultValues = [], onChange = () => {}, label = '', multiple = false, ...rest } = props;
  const dispatch = useDispatch();
  const projectStage = useSelector(state => state.commonReducer.projectStage);
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (!projectStage) {
      dispatch(fetchPickListData('stages?name=', 'projectStage'));
    } else {
      const formattedOptions = formatOptions(projectStage?.data);
      setOptions(formattedOptions);
    }
  }, [projectStage, dispatch]);

  useEffect(() => {
    if (options?.length > 0 && defaultValues?.length > 0 && selectedValues?.length <= 0) {
      const defaultSelected = options.filter(option => defaultValues.some(defaultValue => defaultValue === option?.name));
      setSelectedValues(defaultSelected);
      onChange(null, defaultSelected);
    }
  }, [options, defaultValues]);

  const handleChange = (event, selected) => {
    setSelectedValues(selected);
    onChange(event, selected);
  };

  return <CustomDropdown {...rest} options={options} value={selectedValues} onChange={handleChange} label={label} multiple={multiple} isCheckBox={multiple} />;
};

MultiStageSelection.propTypes = {
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  multiple: PropTypes.bool
};

export default MultiStageSelection;
