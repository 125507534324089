import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, Box, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RecordAnmation from '../../../../src/assets/Files/askIgnyteAnimation.mp4';
import AskIgnyteIcon from '../../../assets/images/askIgnyte.svg';
import { getUserSelector } from '../../../selectors';
import { getChatHistory, getChatHistoryById, sendMessage } from '../../../services/ApiService';
import ActionCards from './chatCards';
import ChatControls from './ChatControl';

const MessageBubble = styled(Paper)(({ theme, sender }) => ({
  padding: theme.spacing(1.5, 2),
  maxWidth: '70%',
  borderRadius: sender === 'bot' ? '20px 20px 20px 5px' : '20px 20px 5px 20px',
  backgroundColor: sender === 'bot' ? theme.palette.grey[100] : theme.palette.primary.main,
  color: sender === 'bot' ? theme.palette.text.primary : theme.palette.primary.contrastText
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.background.default
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const InputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper
}));

function Message({ message, sender }) {
  const userData = useSelector(getUserSelector);
  const getUserInitials = name => {
    if (!name) return '';
    const initials = name
      .split(' ')
      .map(word => word[0])
      .join('');
    return initials.toUpperCase();
  };
  return (
    <Stack direction='row' spacing={1} alignItems='flex-end' justifyContent={sender === 'user' ? 'flex-end' : 'flex-start'}>
      {sender === 'bot' && (
        <Avatar sx={{ width: 32, height: 32 }}>
          <img src={AskIgnyteIcon} alt='Ask Ignyte' style={{ width: 32, height: 30 }} />
        </Avatar>
      )}
      <MessageBubble sender={sender} elevation={1}>
        <Typography variant='body1'>{message}</Typography>
      </MessageBubble>
      {sender === 'user' && <Avatar sx={{ bgcolor: 'grey.200', width: 32, height: 32, color: 'primary.main' }}>{getUserInitials(userData.name)}</Avatar>}
    </Stack>
  );
}

function Chat({ historicalMessages }) {
  const [isRecording, setIsRecording] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [audioContext, setAudioContext] = useState(null);
  const handleRecordingStateChange = recording => {
    setIsRecording(recording);
  };
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentAudioData, setCurrentAudioData] = useState(null);
  const userData = useSelector(getUserSelector);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(true);
  const [activeInputMethod, setActiveInputMethod] = useState('keyboard');
  const [activeSessionId, setActiveSessionId] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const { t } = useTranslation();

  const handleActionSelect = async (actionId, actionDescription) => {
    setMessages(prev => [...prev, { text: actionDescription, sender: 'user' }]);
    setIsWaitingForResponse(true);
    try {
      const response = await sendMessage({
        message: actionDescription,
        ...(sessionId && { session_id: sessionId }) // Add session_id if it exists
      });

      if (response.data && response.data.data) {
        // Store session ID if it's a new conversation
        if (response.data.data.session?.id) {
          setSessionId(response.data.data.session.id);
        }

        // Handle bot message
        if (response.data.data.messages) {
          const botMessage = response.data.data.messages.find(msg => msg.sender_type === 'bot');
          if (botMessage) {
            setMessages(prev => [...prev, { text: botMessage.message_text, sender: 'bot' }]);
          }
        }
      }
    } catch (error) {
      console.error('Error sending action message:', error);
      setMessages(prev => [...prev, { text: 'Sorry, I encountered an error processing your request.', sender: 'bot' }]);
    } finally {
      setIsWaitingForResponse(false);
    }
  };
  const LoadingBubble = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '4px',
    padding: theme.spacing(1, 2),
    borderRadius: '20px 20px 20px 5px',
    backgroundColor: theme.palette.grey[100],
    width: 'fit-content'
  }));

  const LoadingDot = styled('span')(({ theme }) => ({
    width: '8px',
    height: '8px',
    backgroundColor: theme.palette.grey[400],
    borderRadius: '50%',
    animation: 'bounce 1.4s infinite ease-in-out both',
    '&:nth-of-type(1)': {
      animationDelay: '-0.32s'
    },
    '&:nth-of-type(2)': {
      animationDelay: '-0.16s'
    },
    '@keyframes bounce': {
      '0%, 80%, 100%': {
        transform: 'scale(0)'
      },
      '40%': {
        transform: 'scale(1)'
      }
    }
  }));
  const handleBotResponse = response => {
    setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
  };
  const handleUserTranscription = transcription => {
    // setMessages(prevMessages => [...prevMessages, { text: transcription, sender: 'user' }]);
  };
  const handleMicClick = type => {
    if (type === 'inputMic') {
      if (activeInputMethod !== 'mic') {
        setActiveInputMethod('mic');
      }
      if (!isListening) {
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
          handleSendMessage;
          const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
          const recognition = new SpeechRecognition();

          recognition.lang = 'en-US';
          recognition.interimResults = true;
          recognition.continuous = false;

          recognition.onstart = () => setIsListening(true);
          recognition.onend = () => setIsListening(false);
          recognition.onerror = event => console.error('Speech recognition error:', event.error);

          recognition.onresult = event => {
            const transcript = Array.from(event.results)
              .map(result => result[0].transcript)
              .join('');
            setInputMessage(transcript);
          };

          recognition.start();
        } else {
          alert('Speech Recognition API is not supported in your browser.');
        }
      }
    } else if (type === 'speechMic') {
      if (activeInputMethod !== 'mic') {
        setActiveInputMethod('mic');
      }
      if (!isListening) {
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
          const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
          const recognition = new SpeechRecognition();

          recognition.lang = 'en-US';
          recognition.interimResults = true;
          recognition.continuous = false;

          recognition.onstart = () => setIsListening(true);
          recognition.onend = () => {
            setIsListening(false);
            if (inputMessage.trim()) {
              setTimeout(() => {
                handleSendMessage();
              }, 1000);
            }
          };
          recognition.onerror = event => console.error('Speech recognition error:', event.error);

          recognition.onresult = event => {
            const transcript = Array.from(event.results)
              .map(result => result[0].transcript)
              .join('');
            setInputMessage(transcript);
          };

          recognition.start();
        } else {
          alert('Speech Recognition API is not supported in your browser.');
        }
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleSpeakerToggle = () => {
    setIsSpeakerOn(prevState => !prevState);
  };

  const handleTypeClick = () => {
    // Logic for type button
  };

  const handleTalkClick = () => {
    // Logic for talk button
  };
  const playAudioResponse = async audioData => {
    if (!isSpeakerOn) return; // Don't play if speaker is off

    try {
      // Create AudioContext if it doesn't exist
      const context = audioContext || new (window.AudioContext || window.webkitAudioContext)();
      if (!audioContext) setAudioContext(context);

      // Convert Buffer data to ArrayBuffer
      const arrayBuffer = new Uint8Array(audioData).buffer;

      // Decode the audio data
      const audioBuffer = await context.decodeAudioData(arrayBuffer);

      // Create and play the audio
      const source = context.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(context.destination);
      source.start(0);
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleSendMessage = async (transcribedText, isAudio = false) => {
    const messageToSend = transcribedText && typeof transcribedText === 'string' ? String(transcribedText).trim() : inputMessage ? String(inputMessage).trim() : '';

    if (messageToSend && !isWaitingForResponse) {
      try {
        setIsWaitingForResponse(true);
        setMessages(prev => [...prev, { text: messageToSend, sender: 'user' }]);
        setInputMessage('');
        const payload = {
          message: messageToSend,
          ...(sessionId && { session_id: sessionId }),
          ...(isAudio && { message_type: 'audio' })
        };
        const response = await sendMessage(payload);
        if (response.data && response.data.data) {
          // Store session ID
          if (response.data.data.session?.id) {
            setActiveSessionId(response.data.data.session.id);
          }
          if (response.data.data.session?.id) {
            setSessionId(response.data.data.session.id);
          }

          // Handle bot message
          if (response.data.data.messages) {
            const botMessage = response.data.data.messages.find(msg => msg.sender_type === 'bot');
            if (botMessage) {
              setMessages(prev => [...prev, { text: botMessage.message_text, sender: 'bot' }]);
            }
          }

          // Play audio if available
          if (response.data.data.audio?.data) {
            setCurrentAudioData(response.data.data.audio.data);
          }
        }
        setIsWaitingForResponse(false);
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages(prev => [
          ...prev,
          {
            text: t('askIgnyteChat.sorryCouldNotLoadConversationHistory'),
            sender: 'bot'
          }
        ]);
        setIsWaitingForResponse(false);
      }
      if (transcribedText) {
        setInputMessage('');
      }
    } else {
      console.warn('No message to send');
    }
  };

  const fetchChatHistory = async () => {
    try {
      const response = await getChatHistory();
      const historyData = response?.data?.data || [];
      setChatHistory(historyData);
      // If there's no active session, set the most recent one as active
      if (!activeSessionId && historyData.length > 0) {
        setActiveSessionId(historyData[0].id);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };
  const handleInputMessageChange = useCallback(() => {
    console.log('input message', inputMessage);
    handleSendMessage();
  }, [inputMessage, handleSendMessage]);

  const handleChatHistoryClick = async id => {
    try {
      setActiveSessionId(id);
      const response = await getChatHistoryById(id);
      console.log('responsechatHistory', response.data.data);
      if (response.data.data && response.data.data.length > 0) {
        const historicalMessages = response.data.data.map(msg => ({
          text: msg.message_text,
          sender: msg.sender_type === 'bot' ? 'bot' : 'user'
        }));

        setMessages(historicalMessages);
      }
    } catch (error) {
      console.error('Error fetching chat history by id:', error);
      setMessages(prev => [
        ...prev,
        {
          text: t('askIgnyteChat.sorryCouldNotLoadConversationHistory'),
          sender: 'bot'
        }
      ]);
    }
  };

  useEffect(() => {
    handleInputMessageChange();
    fetchChatHistory();
    // handleChatHistoryById();
  }, []);
  useEffect(() => {
    if (historicalMessages && historicalMessages.length > 0) {
      setMessages(historicalMessages);
      scrollToBottom();
    }
  }, [historicalMessages]);

  return (
    <ChatContainer>
      <MessagesContainer>
        <Stack direction='column' spacing={2} justifyContent='center' alignItems='center' sx={{ mb: 3 }}>
          <Typography
            variant='h4'
            sx={{
              textAlign: 'center'
            }}
          >
            {t('askIgnyteChat.hi')} {userData?.name}
          </Typography>
          <Typography
            variant='h4'
            sx={{
              background: 'linear-gradient(90deg, #2CC191 0%, #000000 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: 'transparent',
              textAlign: 'center',
              marginTop: '-30px'
            }}
          >
            {t('askIgnyteChat.howCanIHelpYou')}
          </Typography>
        </Stack>
        <ActionCards onActionSelect={handleActionSelect} />
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            mt: 3,
            mb: 2,
            textAlign: 'center',
            width: '100%'
          }}
        >
          {t('askIgnyteChat.recentConversations')}
        </Typography>
        {chatHistory.slice(0, 3).map((historyItem, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              px: 2,
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.7
              }
            }}
            onClick={() => handleChatHistoryClick(historyItem.id)}
          >
            <Typography
              variant='body'
              color='text'
              fontSize={18}
              sx={{
                textAlign: 'center',
                backgroundColor: 'background.paper',
                borderRadius: 1,
                p: 1,
                mb: 1,
                boxShadow: 1,
                width: '70%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {historyItem.session_title || t('askIgnyteChat.recentConversations')}
            </Typography>
          </Box>
        ))}

        {messages.map((message, index) => (
          <Message key={index} message={message.text} sender={message.sender} />
        ))}
        {isWaitingForResponse && (
          <Stack direction='row' spacing={1} alignItems='flex-end'>
            <Avatar sx={{ width: 32, height: 32 }}>
              <img src={AskIgnyteIcon} alt='Ask Ignyte' style={{ width: 32, height: 30 }} />
            </Avatar>
            <LoadingBubble>
              <LoadingDot />
              <LoadingDot />
              <LoadingDot />
            </LoadingBubble>
          </Stack>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <Stack direction='row' spacing={1} sx={{ position: 'relative' }}>
          {isListening && (
            <Box sx={{ position: 'absolute', top: -40, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}>
              <video autoPlay loop muted playsInline style={{ width: '150px', height: '60px' }}>
                <source src={RecordAnmation} type='video/mp4' />
              </video>
            </Box>
          )}
          <TextField
            fullWidth
            variant='outlined'
            placeholder={t('askIgnyteChat.message')}
            value={inputMessage}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '25px',
                backgroundColor: '#f5f5f5',
                '& fieldset': {
                  border: 'none'
                }
              }
            }}
            onChange={e => setInputMessage(e.target.value)}
            disabled={isWaitingForResponse}
            onKeyPress={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            InputProps={{
              endAdornment: (
                <Stack direction='row' spacing={1}>
                  <IconButton
                    size='small'
                    onClick={() => handleMicClick('inputMic')}
                    sx={{
                      bgcolor: isListening ? 'primary.main' : 'transparent',
                      '&:hover': {
                        bgcolor: isListening ? 'primary.dark' : 'rgba(0, 0, 0, 0.04)'
                      }
                    }}
                  >
                    <MicIcon
                      sx={{
                        color: isListening ? 'white' : 'inherit'
                      }}
                    />
                  </IconButton>
                </Stack>
              )
            }}
            multiline
            maxRows={4}
            size='small'
          />
          <IconButton
            onClick={handleSendMessage}
            color='primary'
            aria-label='send'
            disabled={!inputMessage.trim() || isWaitingForResponse}
            sx={{
              alignSelf: 'flex-end',
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark'
              },
              '&.Mui-disabled': {
                bgcolor: 'action.disabledBackground',
                color: 'action.disabled'
              }
            }}
          >
            <SendIcon sx={{ transform: 'rotate(-45deg)', color: 'white' }} />
          </IconButton>
        </Stack>
        <ChatControls
          isSpeakerOn={isSpeakerOn}
          onSpeakerToggle={handleSpeakerToggle}
          handleMicClick={handleMicClick}
          onTypeClick={handleTypeClick}
          onTalkClick={handleTalkClick}
          onBotResponse={handleBotResponse}
          onUserTranscription={handleUserTranscription}
          onRecordingStateChange={handleRecordingStateChange}
          onTranscription={handleSendMessage}
          audioData={currentAudioData}
        />
      </InputContainer>
    </ChatContainer>
  );
}

Chat.propTypes = {
  historicalMessages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      sender: PropTypes.oneOf(['bot', 'user']).isRequired
    })
  )
};
Message.propTypes = {
  message: PropTypes.string.isRequired,
  sender: PropTypes.oneOf(['bot', 'user']).isRequired
};

export default Chat;
