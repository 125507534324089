export const translateTabItems = (t, TabData, freezedTabs = []) => {
  return TabData?.map(item => {
    const subItems = item.subTabs.map(subItem => {
      return {
        ...subItem,
        disabled: freezedTabs.includes(subItem.label) ? true : subItem.disabled || false,
        label: t(`${subItem.label}`)
      };
    });
    return {
      ...item,
      subTabs: subItems,
      disabled: freezedTabs.includes(item.label) ? true : item.disabled || false,
      label: t(`${item.label}`)
    };
  });
};
