//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  clientContactsRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  getBdColumnDefs,
  getFilterParamString,
  // IdRenderer,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE
} from './Utils';

import { DateTimeRenderer, loadColumnStateFromLocalStorage, saveColumnStateToLocalStorage } from '../Commons/Utils';

import '../Commons/index.scss';
// import "./index.scss";
import ViewBD from '../../components/ViewSearches/viewBD';
import { searchDataApi, searchDocumentApi } from '../../services/ApiService';
import { API, BLOB, BUSINESS_DEVELOPMENT, ERROR, GET, LOE_GENERATION, POST, SUCCESS, SYNCLINK_CREATED_SUCCESSFULLY, SYNCLINK_FAILED, WARNING } from '../../services/constantService';
import { notFoundMessage, successMessage, unableMessage } from '../../services/MessageService';
import { REFRESH_BDLIST } from '../../types';
import { SCROLL_TIMEOUT } from '../../utils/common';
import { dataSourceUtils } from '../../utils/dataSource';
import ColumnFilter from '../Commons/ColumnFilter';
import CustomFilter from '../Commons/CustomFilter';
import { CompanyNameRenderer } from '../Companies/Utils';

let gridApi, columnApi;
const BDList = props => {
  const { isFromCreateSearch = false } = props;
  const LOE_DOCUMENT_UPLOADED = useSelector(state => state.rootReducer[LOE_GENERATION.LOE_DOCUMENT_UPLOADED]);
  const refreshBD = useSelector(state => state.rootReducer.refreshBD);
  const [viewBD, setViewBD] = useState(false);
  const [filterModel, setFilterModel] = useState({});
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(0);
  const [BDData, setBDData] = useState();
  const location = useLocation();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const saveColumnState = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage('bdListColumns', {
        columnApi,
        gridApi
      });
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage('bdListColumns', { columnApi, gridApi }, true, false);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage('bdListColumns', { columnApi, gridApi }, false, true);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  useEffect(() => {
    if (LOE_DOCUMENT_UPLOADED) {
      const event = LOE_DOCUMENT_UPLOADED;
      console.log('socket event found:: ', LOE_GENERATION.LOE_DOCUMENT_UPLOADED, event.msg, event.obj);
      let bdFound;
      gridApi.forEachNode(node => {
        console.log('node', node.data?.id);
        console.log('socket obj::', event.obj?.bd.id);
        if (node.data?.id === event.obj?.bd?.id) {
          bdFound = node;
        }
      });
      console.log('socket event contact found:: ', bdFound);
      if (bdFound) {
        bdFound.setData(event.obj.bd);
        gridApi.refreshCells({ force: true });
      }
      dispatch({
        type: LOE_GENERATION.LOE_DOCUMENT_UPLOADED,
        payload: undefined
      });
    }
  }, [LOE_DOCUMENT_UPLOADED, dispatch, gridApi]);

  useEffect(() => {
    if (refreshBD) {
      gridApi.onFilterChanged();
      dispatch({ type: REFRESH_BDLIST, payload: false });
    }
  }, [refreshBD, gridApi, dispatch]);

  useEffect(() => {
    return () => {
      saveColumnStateToLocalStorage('bdListColumns', { columnApi });
    };
  }, []);

  const getURLs = {
    listURl: `${API.business_developments}`
  };

  const dataSource = {
    getRows: async params => {
      try {
        params.filterModel && setFilterModel(params.filterModel);
        setShowFilterCount(Object.keys(filterModel)?.length);
        const thisValue = {
          location
          // isClassicQuery,
          // quickQuery,
          // isAdvanceQuery,
          // searchStatus,
          /* queryData, */
          // isSelectedFromList,
          // advanceSelector,
        };
        gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true
        };
        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data.paging) {
            const message = notFoundMessage('records');
            if (data?.paging?.totalCount === 0) {
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            data.data.forEach(element => {
              if (!element.synclink_url) {
                element['synclink_label'] = 'create synclink';
              } else {
                element['synclink_label'] = 'view synclink';
              }
            });
          } else if (Object.keys(data)?.length) {
            params.successCallback([data], 1);
          }
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
    },
    rowCount: null
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    loadColumnStateFromLocalStorage('bdListColumns', { columnApi, gridApi });
    setIsGridReady(true);
  };

  const onViewPopupClose = refreshData => {
    if (refreshData) {
      gridApi.onFilterChanged();
    }
    setViewBD(false);
  };

  const EditRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/searches/${params.data?.id}/active-candidates`,
          state: { isFromBD: true }
        }}
      >
        <Typography style={{ cursor: 'pointer' }} color='primary'>
          <BorderColorIcon />
        </Typography>
      </Link>
    );
  };

  const JobNumberRendererForPipeline = params => {
    return (
      <Link
        to={{
          pathname: `/searches/${params.data?.id}/active-candidates`,
          state: { isFromBD: true }
        }}
      >
        <Typography className='text-link' color='primary'>
          {params.value}
        </Typography>
      </Link>
    );
  };

  const IdRenderer = params => {
    return (
      <Typography
        className='text-link'
        color='primary'
        onClick={() => {
          setViewBD(true);
          setBDData(params.data);
        }}
      >
        {params.value}
        {params?.data?.is_confidential === true ? ' (Confidential)' : ''}
      </Typography>
    );
  };

  const JobNumberRenderer = params => {
    return (
      <Typography
        className='text-link'
        color='primary'
        onClick={() => {
          setViewBD(true);
          setBDData(params.data);
        }}
      >
        {params.value}
      </Typography>
    );
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const createSyncLink = async (data, gridApi) => {
    /* TODO: Here is SyncLink older flow */
    /* const externalLink = `${process.env.REACT_APP_SYNCLINK_URL}/group/sync-link/create-proposal?searchId=${data?.id}`;
    window.open(externalLink, '_blank');
    return; */
    /* TODO: To use new SyncLink flow for create synclink from galaxy api  */
    gridApi.showLoadingOverlay();
    const { status, data: res } = await searchDataApi(POST, data.id, data, 'create-synclink');
    if (status === 200) {
      gridApi.hideOverlay();
      data.synclink_label = 'view synclink';
      enqueueSnackbar(SYNCLINK_CREATED_SUCCESSFULLY, { variant: SUCCESS });
      gridApi.onFilterChanged();
    } else {
      enqueueSnackbar(res.message || SYNCLINK_FAILED, { variant: ERROR });
    }
    gridApi.hideOverlay();
  };

  const viewSyncLink = data => {
    if (data.synclink_url) {
      window.open(data.synclink_url, '_blank');
    }
  };

  const validateBD = bdData => {
    /* const requiredLOEFields = ['job_number', 'job_title', 'company', 'billing_region', 'billing_address', 'city', 'state',
      'zip_code', 'brand', 'projected_fee', 'partners', 'client_team', 'eas', 'currency',
      'term_type', 'intervals', 'fee_percentage', 'estimated_percentage_base', 'estimated_indirect_total_fee'
    ] */
    const requiredLOEFields = ['job_number', 'job_title', 'company', 'billing_region', 'brand', /* 'projected_fee', */ 'partners', 'currency'];
    let isValid = true;
    for (const key of requiredLOEFields) {
      if (key === 'partners') {
        if (!bdData[key]?.length || (bdData[key]?.length && !bdData[key].find(ele => ele?.user))) {
          isValid = false;
          break;
        }
      } else if (!bdData[key]) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      enqueueSnackbar(BUSINESS_DEVELOPMENT.REQUIRED_FIELDS, { variant: ERROR });
      return false;
    }
    return true;
  };

  const generateLOE = async params => {
    const isValid = validateBD(params.data);
    if (!isValid) return;
    try {
      gridApi.showLoadingOverlay();
      const sub_route = `${params.data.id}/${BUSINESS_DEVELOPMENT.GENERATE_LOE}`;
      const { status } = await searchDocumentApi(POST, sub_route);
      if (status === 200) {
        const message = successMessage('LOE Document', 'generated');
        enqueueSnackbar(message, { variant: SUCCESS });
        gridApi.onFilterChanged();
      } else {
        const message = unableMessage('LOE Document', 'generate');
        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (err) {
      console.log('error in generateLOE', err);
    }
    gridApi.hideOverlay();
  };

  const downloadLOE = async params => {
    const loeDocument = params?.data?.search_documents.find(ele => ele.doctype_code === 'LOE');
    if (!loeDocument) {
      const message = notFoundMessage('LOE Document');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    try {
      gridApi.showLoadingOverlay();
      const sub_route = `documents/${loeDocument.id}/download`;
      const { status, data } = await searchDocumentApi(GET, sub_route, {}, {}, BLOB);
      if (status === 200) {
        saveAs(data, loeDocument.file_name);
      } else {
        const message = unableMessage('LOE Document', 'download');
        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (err) {
      console.log('error in generateLOE', err);
    }
    gridApi.hideOverlay();
  };

  const ActionsRenderer = params => {
    const label = params?.data?.synclink_label ? params?.data?.synclink_label : '';
    return (
      <div className='action-label text-capitalize' onClick={() => (params?.data?.synclink_label === 'create synclink' ? createSyncLink(params?.data, gridApi) : viewSyncLink(params?.data))}>
        {label}
      </div>
    );
    /* const label = 'create synclink';
    return (<div
      className="action-label text-capitalize"
      onClick={() => this.createSyncLink(params?.data, this.gridApi)}
    >
      {label}
    </div>); */
  };

  const LOERenderer = params => {
    // const isCreatedLOE = params?.data?.loe_date ? true : false;

    /* check loe document from search documents available */
    const isCreatedLOE = params?.data?.search_documents.find(ele => ele.doctype_code === 'LOE');
    if (params.data?.search_loe_tasks.find(ele => ele.status === LOE_GENERATION.PROCESSING)) {
      return <div className='text-secondary'>Download</div>;
    }
    const label = isCreatedLOE ? 'Download' : 'Generate';
    return (
      <div className='action-label' onClick={() => (isCreatedLOE ? downloadLOE(params) : generateLOE(params))}>
        {label}
      </div>
    );
  };

  return (
    <div className='list-view'>
      <div className='d-flex align-items-center justify-content-end'>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetFilter()}>
          <span className='action-text'>Reset Filter</span>
        </div>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetSort()}>
          <span className='action-text'>Reset Sort</span>
        </div>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterModel} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              JobNumberRenderer,
              EditRenderer,
              DateTimeRenderer,
              IdRenderer,
              JobNumberRendererForPipeline,
              NameRenderer,
              clientContactsRenderer,
              ActionsRenderer,
              CustomFilter,
              LOERenderer
            }}
            suppressMenuHide={true}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={getBdColumnDefs(isFromCreateSearch)}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            onDisplayedColumnsChanged={saveColumnState}
            onDragStopped={saveColumnState}
            onSortChanged={saveColumnStateForSort}
            onFilterChanged={saveColumnStateForFilter}
          ></AgGridReact>
        </div>
        {viewBD && <ViewBD visible={viewBD} onClose={onViewPopupClose} bd={BDData} />}
      </div>
    </div>
  );
};

BDList.propTypes = {
  isFromCreateSearch: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  LOE_DOCUMENT_UPLOADED: PropTypes.object,
  dispatch: PropTypes.func,
  refreshBD: PropTypes.bool
};

export default BDList;
