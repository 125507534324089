import { ROUTES } from '../../services/constantService';

export function createEnglishStatements(apiResponse) {
  return apiResponse.map((item, index) => {
    try {
      let name = item?.created_user?.name || item?.updated_user?.name || item?.created_user?.name || 'User';
      let action, reference, deeplink;
      if (item.activity_type === 'candidate_stage_change') {
        name = item?.ign_contacts?.name || item?.updated_user?.name || item?.ign_contacts?.name || 'User';
      }
      // Label for activity type
      //
      switch (item.activity_type) {
        // case 'note':
        case 'TASK':
          action = 'logged a task for';
          break;
        case 'NOTE':
          action = 'logged a note for';
          break;
        case 'candidate_stage_change':
          action = 'moved ';
          break;
        case 'project_stage_update':
          action = 'moved ';
          break;
        case 'INTERVIEW_INTERNAL':
          action = 'Scheduled Interview Internal';
          break;
        case 'INTERVIEW_EXTERNAL':
          action = 'Scheduled Interview External';
          // action = item.activity_type_data?.labels?.find(l => l.language_code === 'en-US')?.label || item.activity_type;
          break;
        default:
          action = `logged a ${item.activity_type}`;
      }
      if (item.activity_type === 'note') {
        action = action + ` as ${item.notes_type}`;
      }

      if (item.activity_type === 'candidate_stage_change') {
        action = action + ` to ${item.to_stage}`;
      }
      if (item.activity_type === 'project_stage_update') {
        action = action + ' to';
      }
      // Construct reference string

      // If contact_id and project_id are present, use project_id
      // If contact_id is present but project_id is not, use contact_id
      // If company_id is present, use company_id
      // If none of the above are present, return ""

      if (item.project && item.project_id) {
        reference = `${item.project.job_number} - ${item.project.job_title}`;
        deeplink = `${ROUTES.projectDetails}/${item.project_id}`;
      } else if (item.company_id && item.company) {
        reference = `${item.company.name}`;
        deeplink = `${ROUTES.viewCompanyWithoutCurrentTab.split(':id')[0]}${item.company_id}`;
      } else if (item.contact_id && item.ign_contacts) {
        reference = `${item.ign_contacts.name}`;
        deeplink = `${ROUTES.allContactGridWithDrawerOpen.split(':id')[0]}${item.contact_id}`;
      } else if (item.project_id && item.contact_id) {
        reference = `${item.ign_contacts.name}`;
        deeplink = `${ROUTES.projectDetails}/${item.project_id}`;
      } else {
        reference = '';
      }
      return {
        id: index,
        project_id: item?.project_id || '',
        name: ` ${name}` || 'User',
        action: action,
        reference: reference,
        activity_type: item.activity_type,
        contact_id: item?.ign_contacts?.id || '',
        stage_to: item?.to_stage || '',
        deeplink: deeplink
      };
    } catch (err) {
      console.log(err);
    }
  });
}
