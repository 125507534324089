import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
function AddContactDrawerAccordion(props) {
  const { title, children, expand, setExpand, addTitle, onClickAdd, loading, visible, globalLoading, isPersonalityTab = false } = props;
  return (
    <Accordion
      sx={{
        width: '100%',
        borderRadius: isPersonalityTab ? '0px !important' : '12px !important',
        '&:before': {
          display: 'none'
        },
        '&.MuiAccordion-root': {
          overflow: 'hidden'
        },
        boxShadow: isPersonalityTab ? '0px 0px 0px 0px rgba(0, 0, 0, 0)' : '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        display: visible ? 'block' : 'none'
      }}
      disableGutters
      square={false}
      expanded={expand}
      onChange={e => {
        setExpand(e.target.value);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize='large' />}
        sx={{
          bgcolor: 'background.white',
          borderRadius: '12px',
          height: '60px',
          '&.Mui-expanded': {
            borderRadius: '12px 12px 0 0'
          },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            margin: 0
          }
        }}
      >
        <>
          <Typography color={'primary.main'} fontWeight={'bold'} className='fs-14'>
            {title}
          </Typography>

          {addTitle && (
            <Button
              onClick={e => {
                e.stopPropagation();
                onClickAdd();
              }}
              sx={{
                padding: 0,
                // marginLeft: 'auto',
                textDecoration: 'underline',
                fontStyle: 'italic',
                zIndex: 10
              }}
            >
              +{addTitle}
            </Button>
          )}
          {loading && <CircularProgress size={20} sx={{ marginLeft: 'auto', marginRight: '10px' }} />}
        </>
      </AccordionSummary>
      <AccordionDetails sx={{ borderRadius: '0 0 12px 12px' }}>{globalLoading ? <Skeleton variant='rounded' width={'100%'} height={'80px'} /> : children}</AccordionDetails>
    </Accordion>
  );
}

export default AddContactDrawerAccordion;

AddContactDrawerAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expand: PropTypes.bool,
  setExpand: PropTypes.func,
  addTitle: PropTypes.string,
  onClickAdd: PropTypes.func,
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  globalLoading: PropTypes.bool,
  isPersonalityTab: PropTypes.bool
};
