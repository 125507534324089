//----------------------------------------------// In-built Imports // -------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/CustomButton';
import CustomCard from '../../../common/CustomCard';
import CustomCommonPersonality from '../../../common/CustomCommonPersonality';
import '../../index.scss';

const noteType1 = 'strength';
const noteType2 = 'concerns';
const approved1 = `is_${noteType1}_approved_by_partner`;
const approved2 = `is_${noteType2}_approved_by_partner`;

const StrengthAndConcern = props => {
  const {
    strengthCardHeight,
    strengthCardWidth,
    register = () => {},
    setValue = () => {},
    handleSave = () => {},
    currentValues,
    isContactView = false,
    contact_id,
    getContact = () => {},
    getContactDetails = () => {},
    setLoading = () => {},
    popupClose
  } = props;
  const [isCommonPopup, setIsCommonPopup] = useState(false);
  const [item, setItem] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [indexNumbers, setIndexNumbers] = useState();
  const indexValueRef = useRef();
  indexValueRef.current = indexNumbers;
  const itemRef = useRef();
  itemRef.current = item;
  const [isInlineEditing, setIsInLineEditing] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const isInlineEditingRef = useRef();
  isInlineEditingRef.current = isInlineEditing;
  const { t } = useTranslation();
  const notesLabel = {
    label1: t('utils.strength'),
    label2: t('utils.concern')
  };
  const styleData = {
    height: strengthCardHeight || 250,
    width: strengthCardWidth || 400,
    minWidth: strengthCardWidth || 400
  };
  const handleCommonPopup = () => {
    setIsCommonPopup(true);
  };

  useEffect(() => {
    const convertData = () => {
      let newData = [];
      if (currentValues && currentValues.length) {
        currentValues.forEach(itemEntry => {
          if (itemEntry?.notes_type === noteType1 || itemEntry?.notes_type === noteType2) {
            let projectIndex = newData.findIndex(project => project?.projects?.project_id === itemEntry?.project?.id);

            if (projectIndex === -1) {
              newData.push({
                projects: { project_name: itemEntry?.project?.job_title, project_id: itemEntry?.project?.id },
                [noteType1]: [],
                [noteType2]: [],
                [approved1]: false,
                [approved2]: false
              });
              projectIndex = newData.length - 1;
            }

            if (itemEntry.notes_type === noteType1) {
              newData[projectIndex][noteType1].push({ ...itemEntry });
              newData[projectIndex][approved1] = itemEntry.is_partner_approved;
            } else if (itemEntry.notes_type === noteType2) {
              newData[projectIndex][noteType2].push({ ...itemEntry });
              newData[projectIndex][approved2] = itemEntry.is_partner_approved;
            }
          }
        });
        return newData;
      } else {
        return newData;
      }
    };
    setItem(convertData());
    setIsCommonPopup(false);
  }, [currentValues]);
  const handlePopupCommonSubmit = async () => {
    if (isEditing || isInlineEditingRef.current) {
      if (isContactView) {
        const result = await handleSave('contact_notes_all', false);
        if (result) {
          setIsEditing(false);
          setIsInLineEditing(false);
        }
      } else {
        const result = await handleSave();
        if (result) {
          setIsEditing(false);
          setIsInLineEditing(false);
        }
      }
    } else {
      if (isContactView) {
        await handleSave('contact_notes_all', false);
      } else {
        await handleSave();
      }
    }
  };
  const editItems = indexValue => {
    setIndexNumbers(indexValue);
    setIsEditing(true);
  };
  const viewItems = indexValue => {
    setIsMorePopupOpen(true);
    setIndexNumbers(indexValue);
    // setIsEditing(true)
  };
  const inlineEditing = value => {
    setIsInLineEditing(true);
    setSelectedType(value);
  };
  const displayData = data => {
    return (
      data &&
      data.map((elem, parentIndex) => (
        <Box className='d-flex custom-card' key={parentIndex} gap={2}>
          <CustomCard
            data={elem}
            isTypes={true}
            onSubmit={handlePopupCommonSubmit}
            // itemLength={elem[noteType1].length}
            indexValue={parentIndex}
            editItems={editItems}
            isEditing={isEditing}
            noteType1={noteType1}
            noteType2={noteType2}
            // height={strengthCardHeight || 250}
            // width={strengthCardWidth || 400}
            fontSize={12}
            viewItems={viewItems}
            approved1={approved1}
            approved2={approved2}
            styleData={styleData}
            notesLabel={notesLabel}
            contact_id={contact_id}
            getContact={getContact}
            getContactDetails={getContactDetails}
            setLoading={setLoading}
          />
        </Box>
      ))
    );
  };

  return (
    <Box className='personality_sub flex flex-column'>
      <Box className='d-flex align-items-center gap-2'>
        <Typography className='compensation-info' variant='body1'>
          {notesLabel.label1} {t('utils.and')} {notesLabel.label2}
        </Typography>
        {item.length > 0 && (
          <Box mt={0} ml={2}>
            <CustomButton type={'secondary'} variant='outlined' size={'small'} buttonText={t('actions.add')} customWidth={96} onClick={handleCommonPopup} iconLeft={<AddIcon sx={{ width: 14 }} />} />
          </Box>
        )}
      </Box>
      {item.length === 0 ? (
        <Box className='personality-head'>
          <Box className='add-another-buttons'>
            <CustomButton type={'secondary'} variant='outlined' size={'small'} buttonText={t('actions.add')} customWidth={96} onClick={handleCommonPopup} iconLeft={<AddIcon sx={{ width: 14 }} />} />
          </Box>
        </Box>
      ) : (
        <Box className='personality-head d-flex'>
          <Box className='d-flex' gap={2}>
            {itemRef.current.length > 0 && displayData(itemRef.current)}
          </Box>

          {/* <Box>
              {strengthData.length > 0 && <Typography variant='body1'>Strength</Typography>}
              {strengthData.length > 0 && displayData(strengthData, currentPage, "Strength")}
              {totalPages > 1 && (
                <Box className="pagination">
                  <Box className="preButton">
                    <ArrowBackIosNewIcon
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                      fontSize="small"
                    />
                  </Box>
                  <Box className="page-data">
                    {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                      (page) => (
                        <Box
                          key={page}
                          className={currentPage === page ? "active-page" : ""}
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </Box>
                      )
                    )}
                  </Box>
                  <Box className="nextButton" >
                    <ArrowForwardIosIcon
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      fontSize="small"
                    />
                  </Box>
                </Box>
              )}
              {
                strengthData && strengthData.length > 0 &&
                <Box className="add-another-buttons" onClick={handleOpenPopup}>
                  <AddButton
                    title="Add Another"
                  />
                </Box>
              }

              {isPopupOpen && (
                <CustomizedDialogs
                  title="Strength"
                  setIsPopupOpen={setIsPopupOpen}
                  isPopupOpen={isPopupOpen}
                  onSubmit={handlePopupSubmit}
                />
              )}
              {concernData.length > 0 && strengthData.length === 0 && (
                <Box className="sub-personality-label">
                  <Typography variant='body1'>Add Strength or Concerns</Typography>
                  <Box className="add-another-buttons" onClick={handleOpenPopup}>
                    <AddButton
                      title="Add"
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              {concernData.length > 0 && <Typography variant='body1'>Concerns</Typography>}
              {concernData.length > 0 && displayData(concernData, currentConcernPage, "Concerns")}
              {totalConcernPage > 1 && (
                <Box className="pagination">
                  <Box className="preButton">
                    <ArrowBackIosNewIcon
                      onClick={handleConcernPrevPage}
                      disabled={currentConcernPage === 1}
                      fontSize="small"
                    />
                  </Box>
                  <Box className="page-data">
                    {Array.from({ length: totalConcernPage }, (_, index) => index + 1).map(
                      (page) => (
                        <Box
                          key={page}
                          className={currentConcernPage === page ? "active-page" : ""}
                          onClick={() => handleConcernPageChange(page)}
                        >
                          {page}
                        </Box>
                      )
                    )}
                  </Box>
                  <Box className="nextButton" >
                    <ArrowForwardIosIcon
                      onClick={handleNextPageConcern}
                      disabled={currentConcernPage === totalConcernPage}
                      fontSize="small"
                    />
                  </Box>
                </Box>
              )}
              <Box className="add-another-buttons" onClick={handleOpenConcern}>{
                concernData.length > 0 &&
                <AddButton
                  title="Add Another"
                />
              }

              </Box>
              {isConcernPopup && (
                <CustomizedDialogs
                  title="Concerns"
                  setIsPopupOpen={setIsConcernPopup}
                  isPopupOpen={isConcernPopup}
                  onSubmit={handleConcernSubmit}
                />
              )}
              {concernData.length === 0 && strengthData.length > 0 && (
                <>
                  <Typography variant='body1'>Add Strength or Concerns</Typography>
                  <Box className="add-another-buttons" onClick={handleOpenConcern}>
                    <AddButton
                      title="Add"
                    />
                  </Box>
                </>
              )}
            </Box> */}
        </Box>
      )}
      {isCommonPopup && (
        <CustomCommonPersonality
          setIsPopupOpen={setIsCommonPopup}
          isPopupOpen={isCommonPopup}
          onSubmit={handlePopupCommonSubmit}
          noteType1={noteType1}
          noteType2={noteType2}
          approved1={approved1}
          approved2={approved2}
          itemValue={itemRef.current}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
          popupClose={popupClose}
        />
      )}
      {isEditing && (
        <CustomCommonPersonality
          // types={types}
          setIsPopupOpen={setIsEditing}
          isPopupOpen={isEditing}
          onSubmit={handlePopupCommonSubmit}
          isEditing={isEditing}
          itemValue={itemRef.current[indexValueRef.current]}
          noteType1={noteType1}
          noteType2={noteType2}
          // isMorePopupOpen={isMorePopupOpen}
          // setIsMorePopupOpen={setIsMorePopupOpen}
          // editItems={editItems}
          // indexValue={indexValueRef.current}
          approved1={approved1}
          approved2={approved2}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
          popupClose={popupClose}
        />
      )}
      {isMorePopupOpen && (
        <CustomCommonPersonality
          // types={types}
          setIsPopupOpen={setIsMorePopupOpen}
          isPopupOpen={isMorePopupOpen}
          onSubmit={handlePopupCommonSubmit}
          isEditing={isEditing}
          itemValue={itemRef.current[indexValueRef.current]}
          noteType1={noteType1}
          noteType2={noteType2}
          isMorePopupOpen={isMorePopupOpen}
          setIsMorePopupOpen={setIsMorePopupOpen}
          // editItems={editItems}
          // indexValue={indexValueRef.current}
          approved1={approved1}
          approved2={approved2}
          inlineEditing={inlineEditing}
          isInlineEditing={isInlineEditingRef.current}
          setIsInLineEditing={setIsInLineEditing}
          selectedType={selectedType}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
        />
      )}
    </Box>
  );
};

StrengthAndConcern.propTypes = {
  strengthCardHeight: PropTypes.number,
  strengthCardWidth: PropTypes.number,
  register: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  currentValues: PropTypes.object,
  isContactView: PropTypes.bool,
  getContact: PropTypes.func,
  contact_id: PropTypes.string,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func,
  popupClose: PropTypes.bool
};

export default StrengthAndConcern;
