const TabData = [
  {
    label: 'utils.details',
    subTabs: [
      {
        label: 'utils.profile'
      },
      {
        label: 'utils.communication'
      },
      {
        label: 'utils.professionalExperience'
      },
      {
        label: 'utils.educationAndLanguages'
      }
    ]
  },
  {
    label: 'reports.candidate_details_information_compensation',
    subTabs: []
  },
  {
    label: 'utils.personality',
    subTabs: []
  },
  {
    label: 'utils.documentType',
    subTabs: []
  },
  {
    label: 'project.projectsAndActivities',
    subTabs: [
      {
        label: 'navMenu.projects'
      },
      {
        label: 'contacts.recentActivity'
      }
    ]
  }
];

export const contactFreezedTabs = [
  'utils.communication',
  'utils.professionalExperience',
  'utils.educationAndLanguages',
  'reports.candidate_details_information_compensation',
  'utils.personality',
  'utils.documents',
  'project.projectsAndActivities',
  'navMenu.projects',
  'contacts.recentActivity'
];
export default TabData;
