import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import propTypes from 'prop-types';
import * as React from 'react';
import { BDDataApi, ignUsersApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
// Updated component to accept `items` prop
export default function AttendeesDropdown(props) {
  const { t } = useTranslation();
  const { label, projectId, type, onChange, addQuickContact = () => {}, isQuickAddContact = false, personName, setPersonName, items, setItems, required, ...sx } = props;

  const [loading, setLoading] = React.useState(false);
  const handleChange = event => {
    let {
      target: { value }
    } = event;
    value = value.filter(item => item != null);
    setPersonName(typeof value === 'string' && value.length > 0 ? value.split(',') : value);
    onChange(event);
  };

  const getInternalContactDetails = async () => {
    try {
      if (projectId) {
        setLoading(true);
        const { status, data } = await BDDataApi(GET, projectId, {});
        const users = await ignUsersApi(GET, '/getAll');
        if (status === 200 && data) {
          let uniqueContactIds = new Set();
          const partners = data?.partners?.map(item => {
            if (item && item?.user && item?.user?.id) {
              if (!uniqueContactIds.has(item?.user?.id)) {
                uniqueContactIds.add(item?.user?.id);
                return {
                  id: item?.user?.id,
                  title: item?.user?.name,
                  type: 'project-team'
                };
              }
            }
          });

          const researchers = data?.researchers?.map(item => {
            if (item && item?.user && item?.user?.id) {
              if (!uniqueContactIds.has(item?.user?.id)) {
                uniqueContactIds.add(item?.user?.id);
                return {
                  id: item?.user?.id,
                  title: item?.user?.name,
                  type: 'project-team'
                };
              }
            }
          });

          const recruiters = data?.recruiters?.map(item => {
            if (item && item?.user && item?.user?.id) {
              if (!uniqueContactIds.has(item?.user?.id)) {
                uniqueContactIds.add(item?.user?.id);
                return {
                  id: item?.user?.id,
                  title: item?.user?.name,
                  type: 'project-team'
                };
              }
            }
          });

          const project_admin = data?.project_admin?.map(item => {
            if (item && item?.user && item?.user?.id) {
              if (!uniqueContactIds.has(item?.user?.id)) {
                uniqueContactIds.add(item?.user?.id);
                return {
                  id: item?.user?.id,
                  title: item?.user?.name,
                  type: 'project-team'
                };
              }
            }
          });

          const client_contact = data?.project_client_team?.map(item => {
            return {
              id: item?.id,
              title: item?.contact?.name,
              type: 'client-team'
            };
          });

          const allUsers = users?.data?.map(item => {
            return {
              id: item?.id,
              title: item?.name,
              type: 'all-users'
            };
          });
          if (type?.lookup_data?.includes('project-team')) setItems([...partners, ...researchers, ...recruiters, ...project_admin].filter(item => item != null || item != undefined));
          if (type?.lookup_data?.includes('client-contacts')) setItems([...client_contact]);
          if (type?.lookup_data?.includes('all-users')) setItems([...allUsers]);
        }
      }
      setLoading(false);
    } catch (err) {
      console.log('error in getInternalContactDetails::', err);
      setLoading(false);
    }
  };

  // console.log('data in getInternalContactDetails::', type);
  React.useEffect(() => {
    getInternalContactDetails();
  }, [projectId, type]);
  return (
    <div>
      <FormControl fullWidth>
        <Loader show={loading} />
        <InputLabel id='demo-multiple-name-label'>
          {required ? (
            <>
              {label}
              <Box component={'span'} className='text-danger'>
                {' '}
                *{' '}
              </Box>
            </>
          ) : (
            label
          )}
        </InputLabel>
        <Select labelId='demo-multiple-name-label' id='demo-multiple-name' multiple {...sx} value={personName} onChange={handleChange} input={<OutlinedInput label={label} />} MenuProps={MenuProps}>
          {projectId && items ? (
            items?.map(item => (
              <MenuItem key={item.id} value={item}>
                {item?.title}
              </MenuItem>
            ))
          ) : (
            <Typography textAlign={'center'}>{projectId ? 'No attendees found' : 'Please select a project'}</Typography>
          )}
          {projectId && items && isQuickAddContact && (
            <MenuItem className='w-100 text-center'>
              <CustomButton type={'primary'} customWidth={250} variant={'outlined'} onClick={() => addQuickContact()} buttonText={'+ ' + t('actions.addContact')} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

AttendeesDropdown.propTypes = {
  items: propTypes.array,
  props: propTypes.object,
  label: propTypes.string,
  sx: propTypes.object,
  projectId: propTypes.string,
  type: propTypes.string,
  onChange: propTypes.func,
  addQuickContact: propTypes.func,
  isQuickAddContact: propTypes.bool,
  personName: propTypes.array,
  setPersonName: propTypes.func,
  setItems: propTypes.func,
  required: propTypes.bool
};
