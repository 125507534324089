//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CircularProgress, createFilterOptions, Stack } from '@mui/material';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getMethodWithCancelTokenApi } from '../../../../services/ApiService';
import { IGN_API } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const CompanySelectionDebounce = props => {
  const {
    label = '',
    placeholder = '',
    onChange = () => {},
    defaultValue,
    required = false,
    isForAddCompany = false,
    addAnotherButtonOnChange = () => {
      return -1;
    },
    ignoreFiltering = true,
    passValueToParentOnEnter = false,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const filter = createFilterOptions();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(defaultValue);
  const [useInternalState, setUseInternalState] = useState(true);
  const getSearchData = async () => {
    try {
      const url = `${IGN_API.picklists}/companies?name=${useInternalState ? searchValue : defaultValue}`;
      setLoading(true);
      const response = await getMethodWithCancelTokenApi(url, {}, {});
      const { status, data } = response;
      if (status === 200) {
        const records = data?.data?.map(record => ({ ...record }));
        setOptions(records);
        if (data?.data?.length === 0) {
          enqueueSnackbar('No company record found', { variant: 'info' });
          setOptions([]);
        }
      }
      setLoading(false);
    } catch (err) {
      console.error('error in getSearchData, error:: ', err);
      setLoading(false);
      setOptions([]);
    }
  };
  useEffect(() => {
    if (props?.onInputChange) {
      setUseInternalState(false);
    }
  }, []);
  const debounceSearchApiCall = debounce(() => {
    getSearchData();
  }, 400);

  useEffect(() => {
    if ((defaultValue && defaultValue.length >= 3) || (searchValue && searchValue.length >= 3)) {
      debounceSearchApiCall();
    }
    return () => {
      debounceSearchApiCall.cancel();
      setOptions([]);
    };
  }, [defaultValue, searchValue]);

  return (
    <Stack direction={'row'} position={'relative'}>
      <CustomDropdown
        {...rest}
        options={options}
        label={label}
        placeholder={placeholder}
        onInputChange={(event, newValue) => {
          if (useInternalState) {
            setSearchValue(newValue);
          }
          if (props?.onInputChange) {
            props.onInputChange(event, newValue);
          }
        }}
        onChange={(event, newValue) => {
          if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue?.length && newValue[newValue?.length - 1].inputValue))) {
            // window.open(`${window.location.host}/companies/all-companies/add`, '_blank')
          }
          onChange(event, newValue);
          event.preventDefault();
        }}
        onKeyDown={event => {
          if (event.keyCode === 13) {
            event.preventDefault();
            if (passValueToParentOnEnter) {
              onChange(null, event.target.value);
            } else {
              getSearchData();
            }
          }
        }}
        defaultValue={useInternalState ? searchValue : defaultValue}
        required={required}
        addAnotherButton={!isForAddCompany}
        filterOptions={(options, params) => {
          if (ignoreFiltering) {
            return [...options, { inputValue: params.inputValue }];
          }
          const filtered = filter(options, params);
          if (params.inputValue) {
            filtered.push({
              inputValue: params.inputValue
            });
          }
          return filtered;
        }}
        addAnotherButtonText={t('utils.addCompany')}
        isToAddCompany={!isForAddCompany}
        addAnotherButtonOnChange={() => addAnotherButtonOnChange(searchValue)} //! By adding searchValue, we are not requiring parent component to maintain the state
      />
      <Stack position={'absolute'} right={'56px'} top={0} bottom={0} justifyContent={'center'} alignItems={'center'} sx={{ display: 'flex', width: 40 }}>
        {loading && <CircularProgress size={20} />}
      </Stack>
    </Stack>
  );
};

CompanySelectionDebounce.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  isForAddCompany: PropTypes.bool,
  onInputChange: PropTypes.func,
  addAnotherButtonOnChange: PropTypes.func,
  passValueToParentOnEnter: PropTypes.bool,
  ignoreFiltering: PropTypes.bool
};

export default CompanySelectionDebounce;
