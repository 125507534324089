import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AgGridWrapper from '../../../utils/AgGridWrapper';
import { EchoSenseAnalyzeTableColumnDefinition, EchoSenseTranscribeTableColumnDefinition } from '../constants';
import '../index.scss';
import AnalyzeActionCellRender from './escnr/AnalyzeActionCellRender';

const HEADER_HEIGHT = 48;
const CELL_HEIGHT = 42;
export default function EchoSenseTable(props) {
  const { subRoute = 'transcribe', records = [], deleteRecord = () => {}, downloadReport = () => {}, analyzeTranscript = () => {}, deleteAnalyzeRecord = () => {} } = props;
  const [colDefs, setColDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const divHeight = divRef.current.offsetHeight;
      const parentHeight = divHeight - HEADER_HEIGHT;
      const _maxRows = Math.floor(parentHeight / CELL_HEIGHT);
    }
  }, []);

  useEffect(() => {
    if (subRoute == 'transcribe') {
      setColDefs(EchoSenseTranscribeTableColumnDefinition);
      setRowData(records);
    } else {
      // Code for analyze
      setColDefs(EchoSenseAnalyzeTableColumnDefinition);
      setRowData(records);
    }
  }, [subRoute, records]);

  const actionCellRender = useCallback(params => {
    return (
      <Box style={{ margin: '0 12px' }}>
        <Tooltip title='Download' arrow>
          <IconButton
            className={'ES-download-icon'}
            onClick={() => {
              if (params.data.transcript_file_s3_key) downloadReport(params.data.transcript_file_s3_key);
            }}
            disabled={!params.data.transcript_file_s3_key}
          >
            <SaveAltOutlinedIcon sx={{ fill: `${!params.data.transcript_file_s3_key ? 'gray' : '#23cea7'}`, fontSize: 20 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Analyze' arrow>
          <IconButton className='ES-download-icon mx-4' onClick={() => analyzeTranscript(params)} disabled={!params.data.transcript_file_s3_key}>
            <AnalyticsOutlinedIcon sx={{ fill: `${!params.data.transcript_file_s3_key ? 'gray' : 'blue'}`, fontSize: 20 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Remove' arrow>
          <IconButton className='ES-download-icon' onClick={() => deleteRecord(params.data.id)}>
            <RemoveCircleOutlineOutlinedIcon sx={{ fill: 'red', fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }, []);
  const analyzeActionCellRender = useCallback(params => {
    return <AnalyzeActionCellRender params={params} deleteRecord={deleteAnalyzeRecord} />;
  }, []);

  const statusRender = useCallback(params => {
    return <Box style={{ fontWeight: 'bold' }}>{capitalize(params.value)}</Box>;
  }, []);

  return (
    <div ref={divRef} className='ignyte-intelligence-table'>
      <Box sx={{ height: '100%' }}>
        <Box height='100%' position='relative'>
          <div className='ag-theme-alpine' style={{ height: '100%' }}>
            <AgGridWrapper
              gridOptions={{ enableCellTextSelection: true }}
              enableBrowserTooltips
              columnDefs={colDefs}
              rowData={rowData}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              pagination={false}
              disableStaticMarkup
              components={{
                actionCellRender: actionCellRender,
                analyzeActionCellRender: analyzeActionCellRender,
                statusRender: statusRender
              }}
              sideBarColumnToolPanelParams={{
                suppressRowGroups: false,
                suppressValues: true,
                suppressPivotMode: true
              }}
              rowGroupPanelShow='always'
            />
          </div>
        </Box>
      </Box>
    </div>
  );
}

EchoSenseTable.propTypes = {
  subRoute: PropTypes.string,
  records: PropTypes.array,
  deleteRecord: PropTypes.func,
  deleteAnalyzeRecord: PropTypes.func,
  downloadReport: PropTypes.func,
  analyzeTranscript: PropTypes.func
};
