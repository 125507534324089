import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../components/common/CustomButton';
import CustomDropdown from '../../../../../components/common/CustomDropdown';
import { ProjectDataContext } from '../../Context';

export default function EducationAndCertification() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  const [education, setEducation] = useState([]);
  const [certification, setCertification] = useState([]);
  //Translate
  const { t } = useTranslation();
  const handleAddEducation = () => {
    const data = { education: '', mandatory_flag: false, new: true };
    setEducation([...education, data]);
  };

  const editEducation = (index, key, value) => {
    education[index][key] = value;
    const educations = education.slice();
    setEducation(educations);
    handleUniversalStateEducationLocally(educations);
  };

  const handleUniversalStateEducationLocally = updatedVal => {
    const event = {
      //TODO
      target: {
        name: 'education',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const handleUniversalStateCertificationLocally = updatedVal => {
    const event = {
      //TODO
      target: {
        name: 'certification',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };
  const handleAddCertification = () => {
    const data = { certification: '', mandatory_flag: false, new: true };
    setCertification([...certification, data]);
  };
  const editCertification = (index, key, value) => {
    certification[index][key] = value;
    const certifications = certification.slice();
    setCertification(certifications);
    handleUniversalStateCertificationLocally(certifications);
  };

  useEffect(() => {
    //TODO
    if (education.length == 0 && projectData?.projectEducationCertificate) {
      const educations = projectData.projectEducationCertificate?.filter(data => data.type == 'education') ?? [];
      setEducation(educations);
    }
    if (certification.length == 0 && projectData?.projectEducationCertificate) {
      const certifications = projectData.projectEducationCertificate?.filter(data => data.type == 'certificate') ?? [];
      setCertification(certifications);
    }
  }, [projectData]);
  return (
    <Stack direction='row' spacing={1}>
      <Box className='border rounded w-50'>
        <Typography
          sx={{
            backgroundColor: 'secondary.main',
            color: 'titleBarBackground.main'
          }}
          className='p-2'
        >
          {t('utils.education')}
        </Typography>
        <Box>
          <Stack direction={'column'}>
            {education.map((data, index) => (
              <Stack direction='row' className='w-100 p-1' key={data}>
                <CustomDropdown
                  freeSolo={true}
                  className='w-100'
                  label=''
                  options={[]}
                  value={data?.degree ?? ''}
                  onChange={e => {
                    if (e?.target) {
                      editEducation(index, 'education', e.target.value);
                    }
                  }}
                  onInputChange={e => {
                    if (e?.target) {
                      editEducation(index, 'education', e.target.value);
                    }
                  }}
                />
                <Stack direction='row' alignItems={'center'}>
                  <input type='checkbox' checked={data?.mandatory_flag} className='m-1' onChange={() => editEducation(index, 'mandatory_flag', !data.mandatory_flag)} />
                  <Typography variant='caption'>{t('utils.mandatory')}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <CustomButton buttonText={'+' + t('actions.addEducationDetails')} type='btn' onClick={() => handleAddEducation()} variant={'text'} />
        </Box>
      </Box>

      <Box className='border rounded w-50'>
        <Typography
          sx={{
            backgroundColor: 'secondary.main',
            color: 'titleBarBackground.main'
          }}
          className='p-2 rounded'
        >
          {t('utils.certification')}
        </Typography>
        <Box>
          <Stack direction={'column'}>
            {certification.map((data, index) => (
              <Stack direction='row' className='w-100 p-1' key={data}>
                <CustomDropdown
                  className='w-100'
                  label=''
                  options={[]}
                  freeSolo={true}
                  value={data?.certificate ?? ''}
                  onChange={e => {
                    if (e?.target) {
                      editCertification(index, 'certification', e.target.value);
                    }
                  }}
                  onInputChange={e => {
                    if (e?.target) {
                      editCertification(index, 'certification', e.target.value);
                    }
                  }}
                />
                <Stack direction='row' alignItems={'center'}>
                  <input type='checkbox' className='m-1' checked={data?.mandatory_flag} onChange={() => editCertification(index, 'mandatory_flag', !data.mandatory_flag)} />
                  <Typography variant='caption'>{t('utils.mandatory')}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <CustomButton buttonText={'+ ' + t('utils.addCertification')} type='btn' onClick={() => handleAddCertification()} variant={'text'} />
        </Box>
      </Box>
    </Stack>
  );
}
