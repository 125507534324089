import { Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import CustomButton from '../../../components/common/CustomButton';
import Loader from '../../../components/common/Loader';
import {
  PositionProfileDataApi,
  ProjectContactDataApi,
  ProjectDataApi,
  ProjectStagesDataApi,
  SFPADataApi,
  SFPASaveDataApi,
  ignCompanyDataApi,
  picklistDropDownApis,
  picklistsDataApis
} from '../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, ERROR, GET, PICKLISTS, POSITION_PROFILE_ROUTE, POST, PUT, ROUTES } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import { readFile } from '../../../utils/processTranscript';
import { GeneratePositionProfile } from '../Utils';
import BasicInfo from './BasicInfo';
import CustomModal from './CustomModal';
import PositionProfile from './PositionProfile';
import ReviewAndPublishModal from './ReviewAndPublishModal';
import SuccessModal from './SuccessModal';

const AddProject = () => {
  //Translate Value
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const [universalState, setUniversalState] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [isRoadMapBuilderRunning, setRoadMapBuilderRunning] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [isReviewAndPublishModalOpen, setIsReviewAndPublishModalOpen] = useState(false);
  const [isPositionProfileLoading, setIsPositionProfileLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  // const [reviewAndPublishDesc, setReviewAndPublishDesc] = useState("");
  const [payloadCopy, setPayloadCopy] = useState({});
  const [contactByCompany, setContactByCompany] = useState([]);
  const [fileContent, setFileContent] = useState({});
  const [fileUploaded, setFileUploaded] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  useEffect(() => {
    if (query && query.get('companyId') && query.get('companyName')) {
      const company = {
        target: {
          value: { id: query.get('companyId'), name: query.get('companyName') },
          name: 'companyName'
        }
      };
      handleUniversalState(company);
    }
  }, [query]);
  //API Calls
  const { data: positionProfileHideOptionResponse } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'POSITION_PROFILE_HIDE_OPTION');
    }
  });

  const {
    data: industriesResponse
    // loading
    // success: isIndustrySuccess
  } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST);
    }
  });

  // const {
  //   data: contactResponse,
  //   // success: isContactSuccess
  // } = useApi({
  //   queryFn: () => {
  //     return ignContactDataApi(GET);
  //   },
  // });
  const fetchContacts = async () => {
    const contacts = await ignCompanyDataApi(GET, {}, universalState['companyName'] ? universalState['companyName'].id : '', '?tabName=ASSOCIATED_CONTACTS_TAB');

    if (universalState['companyName']) {
      setContactByCompany(contacts?.data ?? []);
    }
  };

  const fetchCompanyData = async () => {
    const company = await ignCompanyDataApi(GET, {}, universalState['companyName'] ? universalState['companyName'].id : '', '');

    if (universalState['companyName'] && company && company?.data) {
      // setContactByCompany(contacts?.data ?? []);
      const companyIndustry = company?.data?.company_industries.length > 0 ? company.data?.company_industries[0]?.industries : {};
      const location = company?.data?.location_description && company?.data?.location_description.length > 0 ? company.data?.location_description : '';
      const placeId = company?.data?.location_id && company?.data?.location_id.length > 0 ? company.data?.location_id : '';

      const localEventIndustry = {
        target: {
          value: companyIndustry,
          name: 'industry'
        }
      };
      handleUniversalState(localEventIndustry);
      const localEventLocation = {
        target: {
          value: location,
          name: 'location'
        }
      };
      handleUniversalState(localEventLocation);
      const localEventPlace = {
        target: {
          value: placeId,
          name: 'location_place_id'
        }
      };
      handleUniversalState(localEventPlace);
    }
  };

  const {
    data: serviceOfferingResponse
    // success: isServiceOfferingSuccess
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.PROJECT_STAGE);
    }
  });

  const {
    data: jobTypeResponse
    // success: isJobTypeResponse
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.JOB_TYPE);
    }
  });
  const {
    data: compensationFrequencyResponse
    // success: isCompensationFrequencyResponse,
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.COMPENSATION_FREQUENCY);
    }
  });
  const {
    data: currencyTypeResponse
    // success: isCurrencyTypeResponse
  } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.CURRENCIES);
    }
  });

  const { data: stageResponse } = useApi({
    queryFn: () => {
      return ProjectStagesDataApi(GET);
    }
  });

  const totalSteps = 2;

  const TabData = [
    {
      label: t('project.addProject'),
      content: '',
      subTabs: []
    }
  ];

  const handleNextStep = () => {
    if (activeStep < totalSteps) {
      setActiveStep(activeStep => activeStep + 1);
    }
  };

  const handleBackStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep => activeStep - 1);
    }
  };

  const handleFormSubmitAndStepper = e => {
    e.preventDefault();
    if (activeStep === 0) {
      handleSaveButton();
    }
    handleNextStep();
  };

  const handleUniversalState = event => {
    const key = event.target.name;
    const value = event.target.value;
    universalState[key] = value;
    setUniversalState(Object.assign({}, universalState));
  };

  const getValueFromUniversalState = key => {
    return universalState[key] ?? '';
  };

  const handleCancelButton = () => {
    navigate(ROUTES.mySearches);
  };

  const handleSaveButton = async () => {
    if (getValueFromUniversalState('projectId')) {
      try {
        const projectID = universalState['projectResponse']?.id;
        const curBody = {
          stage: universalState['stage']?.name,
          job_title: universalState['jobTitle'],
          company_id: universalState['companyName']?.id,
          service_offering: universalState['serviceOffering']?.field_value,
          industries: universalState['industries'],
          job_type: universalState['jobType']?.field_value,
          framework_type: universalState['framework_type']?.field_value,

          //todo
          location: universalState['location'] ?? '',
          min_experience: +universalState['experienceFrom'] ?? '',
          max_experience: +universalState['experienceTo'] ?? '',
          min_compensation: +universalState['minCompensation'] ?? '',
          max_compensation: +universalState['maxCompensation'] ?? '',
          additional_info: universalState['additionalInformation'] ?? '',
          pay_frequency: universalState['compensationFrequency']?.field_value ?? '',
          bd_currency: universalState['currencyType']?.id,
          location_place_id: universalState['location_place_id'] ?? ''
        };
        const previousBody = payloadCopy;
        let dif = {};
        const objKeys = Object.keys(previousBody);
        let changed = false;
        for (let i = 0; i < objKeys.length; i++) {
          const key = objKeys[i];

          if (curBody[key] !== previousBody[key]) {
            dif[key] = curBody[key];
            changed = true;
          }
        }

        const clientContacts = universalState['clientContact'] ?? [];
        const clientContactIDs = clientContacts.map(data => data?.id);

        const alreadyPresentProjectContactData = universalState['clientContactData'] ?? [];
        const alreadyPresentClientIDs = alreadyPresentProjectContactData.map(projectContactData => projectContactData?.contact_id);

        let newClientData = [];

        await clientContacts.forEach(async contactData => {
          if (!alreadyPresentClientIDs.includes(contactData?.id)) {
            const payload = {
              project_id: projectID,
              contact_id: contactData?.id,
              hiring_mgr: false
            };
            const res = await ProjectContactDataApi(POST, '', payload, '', '');
            if (res?.data && res?.status) {
              newClientData.push(res?.data);
            }
          }
        });

        await alreadyPresentProjectContactData.forEach(async projectContactData => {
          if (clientContactIDs.includes(projectContactData?.contact_id)) {
            newClientData.push(projectContactData);
          } else {
            await ProjectContactDataApi(DELETE, '', {
              id: projectContactData?.id
            });
          }
        });

        const newClientDataEvent = {
          target: {
            value: newClientData,
            name: 'clientContactData'
          }
        };

        handleUniversalState(newClientDataEvent);

        const hiringManager = universalState['hiringManager'];
        const hiringManagerData = universalState['hiringManagerData'];

        if (hiringManager?.id != hiringManagerData?.contact_id) {
          const _deleteRes = await ProjectContactDataApi(DELETE, '', {
            id: hiringManagerData?.id
          });

          const hiringManagerPayload = {
            project_id: projectID,
            contact_id: hiringManager?.id,
            hiring_mgr: true
          };
          const hiringManagerCreateResponse = await ProjectContactDataApi(POST, '', hiringManagerPayload, '', '');
          const hiringManagerResponseData = {
            target: {
              value: hiringManagerCreateResponse?.data,
              name: 'hiringManagerData'
            }
          };
          handleUniversalState(hiringManagerResponseData);
        }

        if (changed) {
          setPayloadCopy(dif);
          await ProjectDataApi(PUT, '', { ...dif, id: projectID }, '', '');
        }
        // handleNextStep();
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t('common.somethingWentWrong'), 'error');
      }
    } else {
      try {
        setShowLoader(true);
        //creating project
        const requestBody = {
          stage: universalState['stage']?.name,
          job_title: universalState['jobTitle'],
          company_id: universalState['companyName']?.id,
          service_offering: universalState['serviceOffering']?.field_value,
          industries: universalState['industries'],
          job_type: universalState['jobType']?.field_value ?? '',
          location: universalState['location'],
          min_experience: +universalState['experienceFrom'] ?? '',
          max_experience: +universalState['experienceTo'] ?? '',
          min_compensation: +universalState['minCompensation'] ?? '',
          max_compensation: +universalState['maxCompensation'] ?? '',
          additional_info: universalState['additionalInformation'] ?? '',
          pay_frequency: universalState['compensationFrequency']?.field_value ?? '',
          bd_currency: universalState['currencyType']?.id,
          location_place_id: universalState['location_place_id'],
          bd_project_id: universalState['BDProjectId'],
          framework_type: universalState['framework_type']?.field_value
        };
        setPayloadCopy(requestBody);
        const response = await ProjectDataApi(POST, '', requestBody, '', '');

        setShowLoader(false);
        if (response.data && response.status == '201') {
          const event = {
            target: {
              value: response.data,
              name: 'projectResponse'
            }
          };
          const projectEvent = {
            target: {
              value: response.data.job_number,
              name: 'projectId'
            }
          };

          //creating contact mapping with project
          const projectId = response.data.id;
          handleUniversalState(event);
          handleUniversalState(projectEvent);
          const clientContacts = universalState['clientContact'] ?? [];
          const hiringManager = universalState['hiringManager'];

          if (hiringManager?.id) {
            const hiringManagerPayload = {
              project_id: projectId,
              contact_id: hiringManager?.id,
              hiring_mgr: true
            };
            const hiringManagerResponse = await ProjectContactDataApi(POST, '', hiringManagerPayload, '', '');

            const hiringManagerData = {
              target: {
                value: hiringManagerResponse?.data,
                name: 'hiringManagerData'
              }
            };
            handleUniversalState(hiringManagerData);
          }

          let clientContactResponse = [];
          clientContacts.forEach(async contact => {
            const payload = {
              project_id: projectId,
              contact_id: contact?.id,
              hiring_mgr: false
            };

            const response = await ProjectContactDataApi(POST, '', payload, '', '');
            clientContactResponse.push(response?.data);
          });
          const clientContactData = {
            target: {
              value: clientContactResponse,
              name: 'clientContactData'
            }
          };
          handleUniversalState(clientContactData);
          // handleNextStep();
        } else {
          enqueueSnackbar(t('common.somethingWentWrong'), 'error');
        }
      } catch (err) {
        enqueueSnackbar(t('common.somethingWentWrong'), 'error');
      }
    }
  };

  const handleCreateProject = async () => {
    try {
      const projectId = universalState['projectResponse'].id;

      setSuccessModal(true);
      setRoadMapBuilderRunning(true);

      const isExisted = universalState['position_profile_response']?.id ? true : false;
      const bodyJobDescription = {
        template_name: 'JOB-DESCRIPTION',
        mode: 'initial',
        search_id: projectId
      };
      const sfpaJobDescriptionResponse = await SFPADataApi(POST, projectId, bodyJobDescription);

      let requestBody = {
        project_id: projectId,
        description: universalState['description'],
        hide_options:
          //todo
          universalState['hideOption']?.map(option => option?.short_desc) ?? [],
        job_description: sfpaJobDescriptionResponse?.data?.job_description ?? '',
        ai_response_data: universalState['sfpaPosition']
      };
      if (isExisted) {
        const id = universalState['position_profile_response']?.id;
        requestBody = { ...requestBody, id };
      }

      const fileInfo = fileContent;

      if (fileInfo && fileInfo?.name) {
        let extn = fileInfo?.name ? fileInfo?.name?.split('.')[1] : '';
        if (extn && (extn == 'vtt' || extn == 'json')) {
          setFileUploaded(true);
          const reader = new FileReader();
          reader.onload = async e => {
            const parsedFileContent = await readFile(e.target.result, extn);
            await submitPositionProfileData(
              isExisted,
              {
                ...requestBody,
                transcript: {
                  transcript_filename: fileInfo.name,
                  parsed_data: parsedFileContent
                  // raw_data: e.target.result
                }
              },
              projectId
            );
          };
          reader.onerror = e => {
            console.error(e);
          };
          reader.readAsText(fileInfo);
        } else {
          setFileUploaded(false);
          enqueueSnackbar('Unsupported file type, json or vtt file are supported', { variant: ERROR });
        }
      } else {
        await submitPositionProfileData(isExisted, requestBody, projectId);
      }

      if (getValueFromUniversalState('project-with-stage-draft')) {
        let navigateTo = ROUTES.viewProjectWithDeepLink;
        navigateTo = navigateTo.replace(':id', projectId);
        navigateTo = navigateTo.replace(':parentTab', 'Roadmap');
        navigateTo = navigateTo.replace(':subTab', 'Position Profile');

        navigate(navigateTo);
      }
    } catch (err) {
      enqueueSnackbar(t('common.somethingWentWrong'), 'error');
    }
  };

  const submitPositionProfileData = async (isExisted, requestBody, projectId) => {
    const response = await PositionProfileDataApi(isExisted ? PUT : POST, '', requestBody, '', '');

    if ((response.data && response.status == '201') || response.status == '200') {
      const body = {
        template_name: 'SFPA-Search',
        mode: 'initial',
        search_id: projectId
      };
      const sfpaResponse = await SFPADataApi(POST, projectId, body);

      if (sfpaResponse?.data) {
        //todo project post api
        const saveResponse = await SFPASaveDataApi(POST, '', {
          ...sfpaResponse.data,
          project_id: projectId
        });
        if (saveResponse) {
          setRoadMapBuilderRunning(false);
        }
      }
    } else {
      enqueueSnackbar(t('common.somethingWentWrong'), 'error');
    }
  };

  const onReviewAndPublish = async () => {
    setIsReviewAndPublishModalOpen(true);
  };

  // const generate = async () => {
  //   try {
  //     const projectId = universalState["projectResponse"]?.id;
  //     setShowGenerate(true);
  //     const body = {
  //       template_name: "POSITION-PROFILE",
  //       mode: "initial",
  //       search_id: projectId,
  //     };
  //     const sfpaResponse = await SFPADataApi(POST, projectId, body);
  //     const event = {
  //       target: {
  //         name: "sfpaPosition",
  //         value: sfpaResponse?.data,
  //       },
  //     };
  //     handleUniversalState(event);
  //     setShowGenerate(false);
  //     return;
  //   } catch (err) {
  //     enqueueSnackbar(t('utils.somethingWentWrong'), "error");
  //   }
  // };

  // const handleGenerate = async () => {
  //   try {
  //     await generate();
  //     let hideOptions = [...getValueFromUniversalState("hideOption")].map(
  //       (data) => data?.short_desc
  //     );
  //     const event = {
  //       target: {
  //         name: "description",
  //         value: GeneratePositionProfile({
  //           ...getValueFromUniversalState("sfpaPosition"),
  //           isHiringCompany: !hideOptions.includes("Company Name"),
  //           isCompensationRange: !hideOptions.includes("Compensation"),
  //         }),
  //       },
  //     };
  //     handleUniversalState(event);
  //   } catch (err) {
  //     enqueueSnackbar(t('utils.somethingWentWrong'), "error");
  //   }
  // };

  const onPublish = async newDescription => {
    try {
      setShowLoader(true);
      const event = {
        target: {
          name: 'posted_profile',
          value: newDescription
        }
      };
      handleUniversalState(event);
      const projectId = universalState['projectResponse'].id;
      const requestBody = {
        project_id: projectId,
        description: universalState['description'],
        posted_profile: universalState['posted_profile'],
        hide_options: universalState['hideOption']?.map(option => option?.short_desc) ?? [],
        is_published: true
      };
      const response = await PositionProfileDataApi(POST, '', requestBody, '', '');
      const eventPublished = {
        target: {
          name: 'position_profile_response',
          value: response.data
        }
      };
      handleUniversalState(eventPublished);
      setIsReviewAndPublishModalOpen(false);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setShowLoader(false);
    }
  };

  const handleHideOption = newValue => {
    const localEvent = {
      target: {
        value: newValue,
        name: 'hideOption'
      }
    };
    handleUniversalState(localEvent);
    let hideOptions = [...getValueFromUniversalState('hideOption')].map(data => data?.short_desc);
    const event = {
      target: {
        name: 'description',
        value: GeneratePositionProfile({
          ...getValueFromUniversalState('sfpaPosition'),
          isHiringCompany: !hideOptions.includes('Company Name'),
          isCompensationRange: !hideOptions.includes('Compensation')
        })
      }
    };
    handleUniversalState(event);
    // handleGenerate();
  };

  const generate = async (parsedFileContent = null, previousPositionProfile = null) => {
    try {
      const projectId = universalState['projectResponse'].id;
      const body = {
        template_name: 'POSITION-PROFILE',
        mode: 'initial',
        search_id: projectId,
        transcript: parsedFileContent,
        previousPositionProfile: previousPositionProfile,
        project_id: projectId,
        id: universalState['sfpaPosition']?.id
      };

      const sfpaResponse = await ProjectDataApi(POST, projectId, body, POSITION_PROFILE_ROUTE);
      if (sfpaResponse.status !== 200) {
        enqueueSnackbar(sfpaResponse.data.message, { variant: ERROR });
        setIsPositionProfileLoading(false);
        return;
      }
      const event = {
        target: {
          name: 'sfpaPosition',
          value: sfpaResponse?.data
        }
      };
      handleUniversalState(event);
      return;
    } catch (err) {
      setShowLoader(false);
      enqueueSnackbar(t('common.somethingWentWrong'), 'error');
    }
  };

  const handleRegenerate = async currentPositionProfile => {
    try {
      setIsPositionProfileLoading(true);
      const fileInfo = fileContent;
      let extn = fileInfo?.name ? fileInfo?.name?.split('.')[1] : '';
      if (extn == 'vtt' || extn == 'json') {
        setFileUploaded(true);
        const reader = new FileReader();
        reader.onload = async e => {
          const parsedFileContent = await readFile(e.target.result, extn);
          await generate({ transcript_filename: fileInfo?.name, parsed_data: parsedFileContent }, currentPositionProfile);
          const output = await getValueFromUniversalState('sfpaPosition')?.output;
          const descriptionEvent = {
            target: {
              name: 'description',
              value: output
            }
          };
          handleUniversalState(descriptionEvent);
          setShowLoader(false);
          setIsPositionProfileLoading(false);
        };
        reader.onerror = () => {
          setShowLoader(false);
        };
        reader.readAsText(fileInfo);
      } else if (extn == '') {
        await generate(null, currentPositionProfile);
        const output = await getValueFromUniversalState('sfpaPosition')?.output;
        const descriptionEvent = {
          target: {
            name: 'description',
            value: output
          }
        };
        handleUniversalState(descriptionEvent);
        setShowLoader(false);
        setIsPositionProfileLoading(false);
      } else {
        setFileUploaded(false);
        setIsPositionProfileLoading(false);
        enqueueSnackbar('Unsupported file type, json or vtt file are supported', { variant: ERROR });
      }
    } catch (err) {
      setShowLoader(false);
      setIsPositionProfileLoading(false);
      enqueueSnackbar(t('common.somethingWentWrong'), { variant: ERROR });
    }
  };

  const handleChangeDocument = data => {
    let extn = data?.name ? data?.name?.split('.')[1] : '';
    if (extn == 'vtt' || extn == 'json') {
      setFileContent(data);
      setFileUploaded(true);
    } else {
      setFileUploaded(false);
      enqueueSnackbar('Unsupported file type, json or vtt file are supported', { variant: ERROR });
    }
  };

  useEffect(() => {
    fetchContacts();
    fetchCompanyData();
  }, [universalState['companyName']]);
  return (
    <div className='my-search d-flex flex-column'>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={'/projects/add-project'}
        isDrawer={true}
        isDrawerCss={true}
        style={{ paddingTop: '100px', padding: '30px', minHeight: '90vh' }}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <React.Fragment index={t('project.addProject')}>
          <Loader show={showLoader} />
          <div style={{ backgroundColor: '#fff' }}>
            <form onSubmit={handleFormSubmitAndStepper} className='w-100 p-4'>
              <Stack direction='row' justifyContent={'space-between'} className='p-3'>
                <Stack direction='row' alignItems='center'>
                  {activeStep == 0 && (
                    <TextField
                      size='small'
                      label={`${t('module.project')}#`}
                      value={getValueFromUniversalState('projectId')}
                      variant='outlined'
                      color='secondary'
                      type='text'
                      name='projectId'
                      disabled={true}
                      onChange={handleUniversalState}
                    />
                  )}
                  {activeStep == 1 && <CustomButton variant='outlined' buttonText={t('actions.back')} onClick={handleBackStep} type='btn btn-outline-dark' />}
                </Stack>

                <Stack direction='row'>
                  <CustomButton buttonText={t('actions.cancel')} variant={'text'} type={'m-1 btn btn-outline-secondary'} onClick={handleCancelButton} />
                  {activeStep == 0 && (
                    <CustomButton
                      variant='contained'
                      buttonText={t('actions.saveAndContinue')}
                      // onClick={handleSaveButton}
                      type='primary m-1'
                      buttonType='submit'
                    />
                  )}
                  {activeStep == 1 && (
                    <CustomButton
                      variant='contained'
                      buttonText={getValueFromUniversalState('project-with-stage-draft') ? `${t('actions.update')} ${t('module.project')}` : `${t('actions.create')} ${t('module.project')}`}
                      onClick={handleCreateProject}
                      type='primary m-1'
                    />
                  )}
                </Stack>
              </Stack>

              {activeStep == 0 && (
                <BasicInfo
                  getValueFromUniversalState={getValueFromUniversalState}
                  handleUniversalState={handleUniversalState}
                  serviceOfferingOptions={serviceOfferingResponse?.data ?? []}
                  companyOptions={[]}
                  industryOptions={industriesResponse?.data ?? []}
                  jobTypeOptions={jobTypeResponse?.data ?? []}
                  compensationFrequencyOptions={compensationFrequencyResponse?.data ?? []}
                  currencyTypeOptions={(currencyTypeResponse?.data ?? []).map(val => {
                    return { ...val, name: val.code, code: val.name };
                  })}
                  projectOwnerOptions={contactByCompany || []}
                  stageOptions={stageResponse?.data ?? []}
                  fetchContacts={fetchContacts}
                  setCopyPayload={setPayloadCopy}
                />
              )}

              {activeStep == 1 && (
                <PositionProfile
                  getValueFromUniversalState={getValueFromUniversalState}
                  handleUniversalState={handleUniversalState}
                  hideOptions={positionProfileHideOptionResponse?.data ? positionProfileHideOptionResponse?.data : []}
                  onClickReview={onReviewAndPublish}
                  handleHideOptions={handleHideOption}
                  handleGenerate={handleRegenerate}
                  handleChangeDocument={handleChangeDocument}
                  fileUploaded={fileUploaded}
                />
              )}
            </form>
          </div>

          <SuccessModal open={successModal} handleOpen={() => setSuccessModal(false)} projectId={universalState['projectResponse']?.id || ''} isLoading={isRoadMapBuilderRunning} isMessage={false} />
          <SuccessModal open={showGenerate} handleOpen={() => setShowGenerate(false)} projectId={universalState['projectResponse']?.id || ''} isLoading={true} isMessage={true} isLive={false} />
          <CustomModal
            open={isPositionProfileLoading}
            handleOpen={() => setIsPositionProfileLoading(false)}
            projectId={universalState['projectResponse']?.id || ''}
            isLoading={true}
            isMessage={true}
            isLive={true}
            title={t('project.generatingPositionProfile')}
          />

          <ReviewAndPublishModal
            isOpen={isReviewAndPublishModalOpen}
            onCancel={() => setIsReviewAndPublishModalOpen(!isReviewAndPublishModalOpen)}
            onPublish={onPublish}
            description={getValueFromUniversalState('posted_profile').length > 0 ? getValueFromUniversalState('posted_profile') : (getValueFromUniversalState('description') ?? '')}
            dropDownOptions={positionProfileHideOptionResponse?.data ? positionProfileHideOptionResponse?.data : []}
            handleHideOptions={handleHideOption}
            getUniversalValue={getValueFromUniversalState}
          />
        </React.Fragment>
      </DynamicTabLayout>
    </div>
  );
};

export default AddProject;
