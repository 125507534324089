import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton';
import './index.scss';

const Footer = props => {
  const { t } = useTranslation();
  const { onClose = () => {}, data = {}, closeText = t('actions.cancel'), onSubmit = () => {}, submitText = t('actions.save'), hideSubmit = false, disabled = false, submitLoading } = props;
  return (
    <Box id='common-footer'>
      <Box component='footer' className='container-footer py-2'>
        <CustomButton type={'tertiary-error'} size={'small'} buttonText={closeText} customWidth={90} onClick={onClose} variant={'text'} />
        {!hideSubmit && (
          <CustomButton type={'primary'} size={'small'} buttonText={submitText} customWidth={90} onClick={() => onSubmit(data)} variant={'contained'} disabled={disabled} isLoading={submitLoading} />
        )}
      </Box>
    </Box>
  );
};
Footer.propTypes = {
  onClose: PropTypes.func,
  closeText: PropTypes.string,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  submitLoading: PropTypes.bool,
  hideSubmit: PropTypes.bool
};

export default Footer;
