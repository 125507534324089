import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomPopup from '../../../../components/common/CustomPopup';
import { ProjectDataBulkApi, getMethodWithCancelTokenApi } from '../../../../services/ApiService';
import { ERROR, IGN_API, PUT, SUCCESS } from '../../../../services/constantService';
import { SEARCH_TIMEOUT } from '../../../../utils/common';
import ListItem from './ListItem';

const CancelToken = axios.CancelToken;

const LinkExistingProject = ({ bgId, open, onClose, companyId, ...rest }) => {
  const [isLoading, setLoading] = useState(false);
  const [isAddLoading, setAddLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [value, setValue] = useState('');

  const call = useRef();
  const type = 'quicksearches';

  const handleAddProject = async () => {
    const payload = { updateValues: { bd_project_id: bgId }, ids: selectedProjects?.map(pro => pro?.id) };
    setAddLoading(true);
    const response = await ProjectDataBulkApi(PUT, payload);
    if (response?.status === 200) {
      enqueueSnackbar('The Project has been added to the Business Development', { variant: SUCCESS });
      onClose();
      setSelectedProjects([]);
      setValue('');
    } else {
      enqueueSnackbar('Something went wrong!!', { variant: ERROR });
    }
    setAddLoading(false);
  };

  const handleOnInputChange = (e, value) => {
    setValue(value);
  };

  const handleOnChecked = (checked, option) => {
    if (checked) {
      setSelectedProjects([option, ...selectedProjects]);
    } else {
      const updatedSelectedProjects = selectedProjects.filter(opt => opt?.id !== option?.id);
      setSelectedProjects(updatedSelectedProjects);
    }
  };

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (value?.length < 3) {
      setOptions([]);
      setLoading(false);
      return;
    }

    let url = `${IGN_API.picklists}/${type}`;
    if (rest.projectType) {
      url = `${IGN_API.picklists}/${type}?projectsType=${rest.projectType}`;
    }
    // if (value?.length > 2) { setListShow(true) }
    call.current = CancelToken.source();
    setLoading(true);
    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(url, { name: value, company_id: companyId }, {}, {}, call.current.token).then(response => {
        const { status, data } = response;
        if (status === 200) {
          setOptions(JSON.parse(data)?.data);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <CustomPopup popupHeight={'70vh'} width={'60vw'} title={'Link Existing Project'} open={open} onClose={onClose} showAction={false} loading={isLoading || isAddLoading}>
      <Stack direction={'column'}>
        <Autocomplete
          id='bd-projects-list'
          freeSolo
          loading={isLoading}
          filterOptions={options => options}
          onInputChange={handleOnInputChange}
          inputValue={value}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            } else if (type === 'quicksearches') {
              return option?.ign_companies?.name + '  ' + option.job_title + ' ' + option.job_number;
            }
          }}
          options={options}
          renderOption={(props, option) => {
            return <ListItem option={option} checked={selectedProjects.some(opt => opt?.id === option?.id)} handleOnChecked={handleOnChecked} />;
          }}
          renderInput={params => <TextField {...params} label='Search Projects' variant='outlined' />}
        />

        <Box height={'50vh'} overflow={'scroll'} paddingBottom={2} marginY={2}>
          {selectedProjects.map(option => (
            <ListItem deleteIcon key={option?.id} option={option} checked={selectedProjects.some(opt => opt?.id === option?.id)} handleOnChecked={handleOnChecked} />
          ))}
        </Box>

        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
          <CustomButton buttonText={'Cancel'} variant={'outlined'} onClick={onClose} type={''} />
          <CustomButton buttonText={isAddLoading ? 'Adding...' : 'Add'} disabled={selectedProjects.length === 0} variant={'contained'} onClick={handleAddProject} type={'primary'} />
        </Stack>
      </Stack>
    </CustomPopup>
  );
};

export default LinkExistingProject;
LinkExistingProject.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  bgId: PropTypes.string,
  companyId: PropTypes.string
};
