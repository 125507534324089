//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

//----------------------------------------------// Internal Imports // -------------------------------------------------

// import ActivityDashboard from "./ActivityDashboard";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import LogAnActivity from '../../../Containers/Contacts/LogAnActivity';
import './index.scss';

const ActivityLogs = React.memo(props => {
  const {
    id,
    getHeaderDetails = () => {},
    isHeaderNav,
    ifForCompany = false,
    project_id,
    showPersonalityEdit,
    personalityFieldValue,
    setShowPersonalityEdit,
    updateContactPersonality,
    contactPersonalityForm
  } = props;
  const [logAnActivityPopUp, setLogAnActivityPopUp] = useState(false);
  const location = useLocation();

  return (
    <LogAnActivity
      id={id}
      location={location}
      setIsMenuOpen={setLogAnActivityPopUp}
      isMenuOpen={logAnActivityPopUp}
      isViewCloseIcon={false}
      getHeaderDetails={getHeaderDetails}
      isHeaderNav={isHeaderNav}
      ifForCompany={ifForCompany}
      project_id={project_id}
      showPersonalityEdit={showPersonalityEdit}
      personalityFieldValue={personalityFieldValue}
      setShowPersonalityEdit={setShowPersonalityEdit}
      updateContactPersonality={updateContactPersonality}
      contactPersonalityForm={contactPersonalityForm}
    />
  );
});

ActivityLogs.propTypes = {
  id: PropTypes.string,
  isHeaderNav: PropTypes.string,
  getHeaderDetails: PropTypes.func,
  ifForCompany: PropTypes.bool,
  isCandidate: PropTypes.bool,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string,
  showPersonalityEdit: PropTypes.bool,
  personalityFieldValue: PropTypes.object,
  setShowPersonalityEdit: PropTypes.func,
  updateContactPersonality: PropTypes.func,
  contactPersonalityForm: PropTypes.object
};

export default ActivityLogs;
