import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress, Drawer, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/CustomButton';
import MakeConfidential from '../../../common/MakeConfidential/MakeConfidential';
import RichText from '../../../common/RichText';

const NotesDrawer = props => {
  const {
    defaultValueString = '',
    data,
    title,
    setIsPopupOpen = () => {},
    onSubmit = () => {},
    isPopupOpen,
    isEditing = false,
    notesData,
    isInlineEditing = false,
    inlineEditing = () => {},
    selectedType = '',
    setValue = () => {},
    register = () => {}
  } = props;
  const { t } = useTranslation();
  const defaultValue = {
    [notesData.noteType]: [],
    [notesData.approved]: false
  };

  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState(defaultValue);
  const [isCheckApproved, setIsCheckApproved] = useState(false);

  const handleChange = data => {
    setText(data);
  };

  useEffect(() => {
    register('contact_notes_all');
  }, [register]);

  useEffect(() => {
    if (isEditing && data) {
      setText(data[notesData.noteType]);
      setIsCheckApproved(data[notesData.approved]);
    }
  }, [isEditing, data]);

  useEffect(() => {
    setItem({
      [notesData.noteType]: text,
      [notesData.approved]: isCheckApproved
    });
  }, [isCheckApproved, text]);

  useEffect(() => {
    let result = [];
    if (data?.note_id) {
      result.push({
        notes_type: notesData.noteType,
        notes: item[notesData.noteType] || '',
        is_partner_approved: item[notesData.approved],
        id: data.note_id,
        seq: data?.seq
      });
    } else {
      result.push({
        notes_type: notesData.noteType,
        notes: item[notesData.noteType] || '',
        is_partner_approved: item[notesData.approved]
      });
    }
    setValue('contact_notes_all', result, { shouldDirty: true });
  }, [item, data?.note_id]);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(item);
    setIsLoading(false);
    setIsPopupOpen(false);
  };

  const handleEditClick = type => {
    inlineEditing(type);
  };

  return (
    <Drawer anchor='right' open={isPopupOpen} onClose={handleClose}>
      <Box className='drawer-container' width={460}>
        <Box display='flex' justifyContent='space-between' alignItems='center' className='drawer-container-header'>
          <Typography variant='h6' className='fs-16 fw-600'>
            {title}
          </Typography>
          <CloseIcon cursor='pointer' onClick={handleClose} />
        </Box>
        <Box p={3}>
          <Box mb={2}>
            {isInlineEditing && selectedType === notesData.noteType ? (
              <Box className='d-flex align-items-center col-12 p-0'>
                <Box className='col-11 education-input-base'>
                  <RichText
                    defaultValue={text || ''}
                    onChange={handleChange}
                    style={{
                      height: '70vh'
                    }}
                    customClassName='publish-bio-text'
                  />
                </Box>
                <Box className='d-flex col-1 custom-action-buttons'>
                  <Box component='span' className='action-icon'></Box>
                  <Box component='span' className='action-icon'>
                    <CloseIcon cursor='pointer' className='table-close-icon' onClick={handleClose} />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box onDoubleClick={() => handleEditClick(notesData.noteType)} className='view-custom-data education-input-base mb-5 w-70'>
                <RichText
                  defaultValue={defaultValueString || text}
                  onChange={handleChange}
                  style={{
                    height: '72vh'
                  }}
                  customClassName='publish-bio-text'
                />
              </Box>
            )}
          </Box>

          <Box className='pt-3' display='flex' justifyContent='space-between'>
            <MakeConfidential
              defaultChecked={isCheckApproved}
              handleChange={e => setIsCheckApproved(e.target.checked)}
              name={t('project.candidateScore.approvedToPublish')}
              isHiddenDefaultPadding={true}
            />
            <Box display='flex' justifyContent='flex-end' columnGap={2}>
              <CustomButton type={'secondary'} variant='outlined' size={'small'} buttonText={t('actions.cancel')} customWidth={96} onClick={handleClose} />
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CustomButton
                  type={'primary'}
                  variant='outlined'
                  size={'small'}
                  buttonText={isEditing ? t('actions.update') : t('actions.save')}
                  customWidth={96}
                  onClick={handleSubmit}
                  iconLeft={<SaveIcon sx={{ width: 22 }} />}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

NotesDrawer.propTypes = {
  defaultValueString: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  data: PropTypes.object,
  notesData: PropTypes.object,
  isInlineEditing: PropTypes.bool,
  setIsInlineEditing: PropTypes.func,
  inlineEditing: PropTypes.func,
  selectedType: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func
};

export default NotesDrawer;
