import { Box, Grid, Stack, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGridData } from '../../actions';
import AddContactDrawer from '../../components/AddContactDrawer/AddContactDrawer';
import ViewContactDrawer from '../../components/ViewContactDrawer';
import Loader from '../../components/common/Loader';
import { getUserSelector } from '../../selectors';
import { ProjectCandidateApi, ProjectDataApi, getProjectAndBdCount, getRecentActivities } from '../../services/ApiService';
import { GET, POST, ROUTES } from '../../services/constantService';
import { homeActions } from '../../store/HomeSlice';
import { REFETCH_TIME_DELAY_MIN } from '../../utils/common';
import LeftSection from './Components/LeftSection';
import Popup from './Components/Popup/Popup';
import TableView from './Components/ProjectTable';
import RightSection from './Components/RightSection';
import { generateProjectGridColumns, getAllProjectApiQuery } from './Constants';
import style from './Home.module.scss';

function Home() {
  const { t } = useTranslation();
  const {
    storeProjects = [],
    storeProjectStats = {},
    lastUpdatedAt,
    gridConfig = {
      settings: [],
      sort: [],
      filter: {}
    }
  } = useSelector(state => ({
    storeProjects: state.homeReducer.projects,
    storeProjectStats: state.homeReducer.projectStats,
    lastUpdatedAt: state.homeReducer.lastUpdatedAt,
    gridConfig: state.homeReducer.gridConfig
  }));
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [allProjects, setAllProjects] = React.useState(storeProjects);
  const [contactId, setContactId] = React.useState();
  const [allInterview, setAllInterviews] = React.useState([]);
  const [count, setCount] = React.useState(storeProjectStats);
  const userData = useSelector(getUserSelector);
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(!!contactId);
  const [interviewPopup, setInterviewPopup] = React.useState(false);
  const [interviewCount, setInterviewCount] = React.useState(0);

  const [savedSortModel, setSavedSortModel] = React.useState(gridConfig.sort);
  const [gridState, setGridState] = React.useState(gridConfig.settings);
  const [savedFilterModel, setSavedFilterModel] = React.useState(gridConfig.filter);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const projectColumns = useMemo(() => generateProjectGridColumns(t), [t]);
  const handleCloseAll = () => {
    setInterviewPopup(false);
  };
  const handleInterviewPopup = () => {
    setInterviewPopup(true);
  };

  const fetchAllProjects = async (updatedAt, firstInstance = false) => {
    try {
      if (updatedAt && dayjs().diff(updatedAt, 'minutes') <= REFETCH_TIME_DELAY_MIN) {
        return;
      }
      const projectData = await ProjectDataApi(POST, '', getAllProjectApiQuery, 'all');
      if (projectData?.data) {
        const projectGridData = projectData?.data?.data?.data?.map(item => ({
          ...item,
          _metadata: { ...item }
        }));
        const combinedProjects = firstInstance ? projectGridData : [...allProjects, ...projectGridData];
        setAllProjects(combinedProjects);
        dispatch(homeActions.updateHomeProjects(combinedProjects));
      }
      // TODO: Note from Arun: Logic to fetch the pending records in the background
      // if (firstInstance) {
      //   fetchAllProjects(updatedAt, false, projectData.data.data.paging.totalCount);
      // }
    } catch (err) {
      console.error(err);
    }
  };

  const getInterviewDetails = async () => {
    try {
      const recentActivities = await getRecentActivities({
        all: true,
        userId: userData?.id,
        sortBy: ['activity_added_at', 'DESC'],
        onlyFutureInterviews: true,
        activityAddedAt: true
      });
      if (recentActivities?.data) {
        const data = recentActivities?.data?.filter(item => item?.data?.project !== null);
        setAllInterviews(data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getCount = async updatedAt => {
    try {
      if (updatedAt && dayjs().diff(updatedAt, 'minutes') <= REFETCH_TIME_DELAY_MIN) {
        return;
      }
      const count = await getProjectAndBdCount();
      if (count?.data) {
        const data = count?.data;
        setCount(data);
        dispatch(homeActions.updateProjectStats(data));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getInterviewCount = async () => {
    try {
      const response = await ProjectCandidateApi(GET, '/partner_interview', null, { stage: 'KG Interview' });
      setInterviewCount(response?.data?.count || 0);
    } catch (err) {
      console.error(err);
    }
  };

  const getGridSettings = async () => {
    fetchGridData('', 'homeGrid', ({ status, data }) => {
      if (status) {
        const gridSettings = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');
        let gridSettingToUse = gridSettings.length ? gridSettings : projectColumns;
        setSavedSortModel(sortModel);
        setSavedFilterModel(filterModel);
        if (gridSettings.length) {
          gridSettingToUse = gridSettings.map(col => {
            return omit(col, ['sort', 'sortIndex']);
          });
          setGridState(gridSettingToUse);
        } else {
          setGridState(projectColumns);
        }
        dispatch(
          homeActions.updateGridConfig({
            settings: gridSettingToUse,
            sort: sortModel,
            filter: filterModel
          })
        );
      } else {
        setGridState(projectColumns);
        dispatch(
          homeActions.updateGridConfig({
            settings: projectColumns,
            sort: [],
            filter: {}
          })
        );
      }
    });
  };
  const getAll = async () => {
    try {
      if (!storeProjects.length) {
        setLoading(true);
      }
      getInterviewDetails();
      getCount(lastUpdatedAt);
      getInterviewCount();
      getGridSettings();
      await fetchAllProjects(lastUpdatedAt, true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAll();
  }, []);

  return (
    <Box className={style.home_body} backgroundColor={theme.palette.background.default} handleInterviewPopup={handleCloseAll}>
      <Stack className={style.home_first_section}>
        <Grid container spacing={2} className={style.top_section}>
          <Grid item md={8} sm={8.5} container spacing={2} className={style.left_top_section}>
            <LeftSection handleInterviewPopup={handleInterviewPopup} name={userData?.name || 'User'} count={count} interviewCount={interviewCount} setOpenDrawer={setOpenDrawer} />
          </Grid>
          <Grid item sm={3.5} md={4} className={style.right_top_section}>
            <Box sx={{ marginTop: '20px', paddingRight: '10px' }}>
              <RightSection
                userId={userData?.id}
                setContactId={id => {
                  if (id) {
                    setIsDrawerOpen(true);
                    setContactId(id);
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Stack className={style.home_second_section}>
        <TableView projectGridColumn={projectColumns} projectGridData={allProjects} loading={loading} sortModel={savedSortModel} gridState={gridState} filterModel={savedFilterModel} />
      </Stack>
      <Popup openInterview={interviewPopup} handleClose={handleCloseAll} allInterview={allInterview} />
      {isDrawerOpen && <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={contactId} baseRoute={ROUTES.home} />}
      <AddContactDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Loader show={loading} />
    </Box>
  );
}
export default Home;
