import * as React from 'react';
const MuteSVGComponent = props => (
  <svg width={16} height={20} viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.354 7.99818C11.354 8.56218 11.26 9.10518 11.088 9.61118L10.274 8.79718C10.3274 8.53421 10.3542 8.26652 10.354 7.99818V6.99818C10.354 6.86557 10.4067 6.7384 10.5005 6.64463C10.5942 6.55086 10.7214 6.49818 10.854 6.49818C10.9866 6.49818 11.1138 6.55086 11.2076 6.64463C11.3013 6.7384 11.354 6.86557 11.354 6.99818V7.99818ZM6.354 11.9982C7.172 11.9982 7.932 11.7532 8.566 11.3312L9.284 12.0502C8.56954 12.5667 7.73121 12.8851 6.854 12.9732V14.9982H9.854C9.98661 14.9982 10.1138 15.0509 10.2076 15.1446C10.3013 15.2384 10.354 15.3656 10.354 15.4982C10.354 15.6308 10.3013 15.758 10.2076 15.8517C10.1138 15.9455 9.98661 15.9982 9.854 15.9982H2.854C2.7214 15.9982 2.59422 15.9455 2.50045 15.8517C2.40668 15.758 2.354 15.6308 2.354 15.4982C2.354 15.3656 2.40668 15.2384 2.50045 15.1446C2.59422 15.0509 2.7214 14.9982 2.854 14.9982H5.854V12.9732C4.62069 12.8492 3.47738 12.2716 2.64588 11.3523C1.81439 10.4331 1.35399 9.23771 1.354 7.99818V6.99818C1.354 6.86557 1.40668 6.7384 1.50045 6.64463C1.59422 6.55086 1.7214 6.49818 1.854 6.49818C1.98661 6.49818 2.11379 6.55086 2.20756 6.64463C2.30133 6.7384 2.354 6.86557 2.354 6.99818V7.99818C2.354 9.05905 2.77543 10.0765 3.52558 10.8266C4.27572 11.5768 5.29314 11.9982 6.354 11.9982ZM9.354 2.99818V7.87718L8.354 6.87718V2.99818C8.35631 2.47644 8.15465 1.97445 7.79203 1.59932C7.42942 1.22418 6.93456 1.00559 6.41304 0.990197C5.89152 0.974801 5.38463 1.16381 5.00052 1.5169C4.61641 1.87 4.38548 2.35921 4.357 2.88018L3.512 2.03518C3.74095 1.36242 4.2012 0.792738 4.81084 0.427548C5.42047 0.062357 6.13992 -0.074641 6.84111 0.0409428C7.54229 0.156527 8.17971 0.517191 8.63987 1.05873C9.10004 1.60027 9.3531 2.28753 9.354 2.99818Z'
      fill='#D6D6D6'
    />
    <path
      d='M7.84 10.6055L7.092 9.85753C6.78862 9.97798 6.46037 10.0223 6.1359 9.98674C5.81143 9.95115 5.50061 9.83668 5.23055 9.65332C4.9605 9.46997 4.73943 9.2233 4.58664 8.93485C4.43385 8.64641 4.35398 8.32495 4.354 7.99853V7.12053L3.354 6.12053V7.99853C3.35384 8.52385 3.49163 9.04 3.75358 9.49535C4.01552 9.9507 4.39244 10.3293 4.84663 10.5932C5.30082 10.8572 5.81636 10.9972 6.34167 10.9994C6.86698 11.0016 7.38366 10.8657 7.84 10.6055ZM0 1.35253L12 13.3525L12.708 12.6445L0.708 0.644531L0 1.35253Z'
      fill='#D6D6D6'
    />
  </svg>
);
export default MuteSVGComponent;
