import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { picklistDropDownApis, picklistsDataApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const MultiSelectSearchType = props => {
  const { defaultValues = [], onChange = () => {}, label = '', multiple = false, ...rest } = props;

  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const fetchJobTitles = async () => {
    try {
      const response = await picklistsDataApis(GET, 'SEARCH_TYPE');
      if (response?.data) {
        setOptions(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch search type:', error);
    }
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  useEffect(() => {
    if (options?.length > 0 && defaultValues?.length > 0 && selectedValues?.length === 0) {
      const defaultSelected = options?.filter(option => defaultValues.includes(option?.short_desc));
      setSelectedValues(defaultSelected);
      onChange(null, defaultSelected);
    }
  }, [options, defaultValues, selectedValues, onChange]);

  const handleChange = (event, selected) => {
    setSelectedValues(selected);
    onChange(event, selected);
  };

  return <CustomDropdown {...rest} options={options} value={selectedValues} onChange={handleChange} label={label} multiple={multiple} isCheckBox={multiple} />;
};

MultiSelectSearchType.propTypes = {
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  multiple: PropTypes.bool
};

export default MultiSelectSearchType;
