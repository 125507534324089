import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import SetNote from './SetNote';

function SummarySection(props) {
  const { title, data, themeColor } = props;
  if (data?.length > 0) {
    return (
      <Grid className='d-flex flex-column w-100 pl-3 ml-1 mb-3 border-left-5' style={{ borderLeftColor: themeColor ? themeColor.secondary_color : '' }}>
        <Typography variant='h3' component='h3' className='fs-16 bold text-body'>
          {title}
        </Typography>
        {data.map((item, index) => (
          <SetNote key={index} item={item} theme={themeColor} />
        ))}
      </Grid>
    );
  }
  return null;
}

SummarySection.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  themeColor: PropTypes.object
};
export default SummarySection;
