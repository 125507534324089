import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { ignWorkbenchQueryApi } from '../../../../../services/ApiService';
import { POST, ROUTES } from '../../../../../services/constantService';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../../../utils/common';
import { CustomLoadingOverlayComponent } from '../../../../Commons/Utils';
import { queryProjectColumn } from '../Grid/QueryData';
import { ProjectFilterModal } from './QueryFilterAccordion/ProjectFilterAccordion';

const mappedPayload = data => {
  return {
    ...data,
    engagementType: data?.engagementType?.map(item => item?.field_value),
    geography: data?.geography?.map(item => item?.id),
    industry: data?.industry?.map(item => item?.id),
    stage: data?.stage.map(item => item?.name)
  };
};

export const QueryProjectGrid = ({ data, gridType, filterModel, sortModel, setQueryResponse }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    const fetchContactQuery = async () => {
      try {
        setGridLoading(true);
        const payload = Object.keys(filterOptions).length > 0 ? filterOptions : mappedPayload(data);
        const response = await ignWorkbenchQueryApi(POST, payload, `type/${data?.query_type?.toLowerCase()}`);
        setFilteredData(response?.data?.rows || []);
        setGridLoading(false);
      } catch (error) {
        console.error('Error fetching contact query:', error);
        setFilteredData([]);
        setGridLoading(false);
      }
    };
    fetchContactQuery();
  }, [filterOptions]);

  const columns = useMemo(() => queryProjectColumn, []);

  const capitalizeProperCase = string => {
    if (!string) return string;
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const routeProject = id => {
    const path = ROUTES.vProject.replace(':id', id);
    window.open(path, '_blank');
  };

  const rowDataArray = useMemo(
    () =>
      filteredData.map(query => ({
        ...query,
        project_name: query?.helia_title,
        project_type: query?.record_type || '--',
        tags: query?.project_tags?.map(item => capitalizeProperCase(item?.tag?.name))?.join(', ') || '--',
        engagement_type: query?.service_offering || '--',
        geography: query?.markets?.market || '--',
        industry: query?.ign_industries?.name || '--',
        location: query?.location || '--',
        date_opened: query?.created_at ? new Date(query?.created_at)?.toDateString() : '--',
        stage: query?.stage || '--'
      })),
    [filteredData]
  );

  return (
    <>
      <ProjectFilterModal data={data} filterOptions={filterOptions} setFilterOptions={setFilterOptions} setQueryResponse={setQueryResponse} />
      <div id='myGrid' className='ag-theme-alpine project-query-workbench-grid' style={{ overflow: 'hidden', height: 'calc(70vh - 100px)', marginTop: '10px' }}>
        <AgGridWrapper
          enableBrowserTooltips={true}
          gridType={gridType}
          rowHeight={50}
          columnDefs={columns}
          defaultColumnDefs={queryProjectColumn}
          loadingOverlayComponent={CustomLoadingOverlayComponent}
          scrollbarWidth={12}
          showHeaderMenu={true}
          rowData={rowDataArray}
          paginationPageSize={1000}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          sortModel={sortModel}
          filterModel={filterModel}
          loading={gridLoading}
          suppressHorizontalScroll={false}
          domLayout='normal'
          disableStaticMarkup
          rowGroupPanelShow='always'
          saveGridSetting={true}
          rowModelType='clientSide'
        />
      </div>
    </>
  );
};

QueryProjectGrid.propTypes = {
  gridState: PropTypes.array,
  gridType: PropTypes.string,
  data: PropTypes.object,
  setQueryResponse: PropTypes.func,
  sortModel: PropTypes.array,
  filterModel: PropTypes.array
};
