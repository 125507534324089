//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
// import PropTypes from "prop-types";
import { Box } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import '../../../../../src/index.scss';
import workExperience from '../../../../assets/icons/workExperience.svg';
import { getAccessToken } from '../../../../services/cognitoService';
import { ERROR, IGN_API } from '../../../../services/constantService';
import { customFormValidator, requireValidMessage } from '../../../../utils/common';
import useContactFormHook from '../../../../utils/Hooks/useContactFormHook';
import CardSlider from '../../../common/CardsSlider';
import CustomButton from '../../../common/CustomButton';
import Footer from '../../../common/Footer';
import ExperienceCard from '../../../common/FunctionalComponents/ExperienceCard';
import IndustrySelection from '../../../common/FunctionalComponents/IndustrySelection';
import JobFunctionSelection from '../../../common/FunctionalComponents/JobFunctionSelection';
import Loader from '../../../common/Loader';
import ProfileLabel from '../../../common/ProfileLabel';
import { createRequiredFieldWorkExperience, createSchemaWorkExperience, getProfessionalExperiencePayload, validateDate } from '../../utils';
import AddWorkExperience from './AddWorkExperience.js';
import './index.scss';

const ProfessionalExperience = forwardRef((props, ref) => {
  const { handleSaveTabData = () => {}, id, unregister, onCancel } = props;
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [jobFunction, setJobFunction] = useState([]);
  const [indexValue, setindexValue] = useState();
  const [srcUrl, setSrcUrl] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { path } = useParams();
  const isValidated = async contact => {
    let isValidDate = validateDate(contact?.start_date, contact?.end_date);
    if (isPopupOpen) {
      let isValid = await createSchemaWorkExperience.isValid(contact);
      if (!isValid) {
        let dirtyField = customFormValidator(contact, createRequiredFieldWorkExperience);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      if (contact?.board_is_present === true) {
        let isBoardValidDate = validateDate(contact?.board_start_date, contact?.board_end_date);
        if (!isBoardValidDate.isValid) {
          enqueueSnackbar(isBoardValidDate?.message, { variant: ERROR });
          return false;
        } else {
          return true;
        }
      }
      if (!isValidDate.isValid) {
        enqueueSnackbar(isValidDate?.message, { variant: ERROR });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const {
    getContactInfo,
    handleSave,
    register,
    setValue,
    watch,
    currentValues,
    loading: showLoading,
    isDirty
  } = useContactFormHook({
    id,
    path,
    getPayload: getProfessionalExperiencePayload,
    handleSaveTabData,
    ref,
    isValidated,
    defaultFormValue: { base_salary: '' },
    isEdit,
    indexValue,
    tabName: 'PROFESSIONAL_EXPERIENCE_TAB',
    setIsPopupOpen,
    isPopupOpen
  });

  useEffect(() => {
    register('contact_job_functions');
    register('contact_industries');
  }, [register]);
  useEffect(() => {
    const accessToken = async () => {
      let token = await getAccessToken();
      setSrcUrl(`${IGN_API.contact}/${currentValues?.id}/image?token=${token}&time=${Date.now()}`);
    };
    if (currentValues?.image_id) accessToken();
  }, [currentValues]);

  useEffect(() => {
    setValue('contact_job_functions', jobFunction, { shouldDirty: true });
  }, [jobFunction]);

  useEffect(() => {
    setValue('contact_industries', industries, { shouldDirty: true });
  }, [industries]);
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setIsEdit(false);
  };
  const openPopupEditing = index => {
    setindexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };

  const handleIndustryChange = value => {
    const industry = value?.map(item => {
      return { industry_id: item.id };
    });
    setIndustries(industry);
  };
  const handleJobFunctionChange = value => {
    const jobFunction = value?.map(item => {
      return { job_function_id: item.id };
    });
    setJobFunction(jobFunction);
  };

  const cancelData = () => {
    onCancel(isDirty);
  };

  return (
    <>
      <Loader show={showLoading} />
      {currentValues ? (
        <>
          <Loader show={loading} />
          <Box id='professional_experience'>
            <Box>
              <Footer onSubmit={handleSave} onClose={cancelData}></Footer>
            </Box>
            <Box>
              <Box className='d-flex  justify-content-between'>
                <Box className='text-label header-font fs-16' variant='body1'>
                  {t('contacts.addContact.industryAndJobFunction')}
                </Box>
                <Box className='pt-1'>
                  <ProfileLabel value={currentValues} srcUrl={srcUrl} />
                </Box>
              </Box>
              <Box className='industry-job-layout p-11'>
                <Box className='d-flex flex-row flex-wrap column-space row-space content-gap'>
                  <Box>
                    {/* <Box className="compensation-typo fs-14" gutterBottom={true}>
                  {t('contacts.addContact.industry')}
                </Box> */}
                    <IndustrySelection
                      multiple={true}
                      defaultValue={currentValues?.contact_industries}
                      onChange={value => {
                        handleIndustryChange(value);
                      }}
                      isCheckBox={true}
                      selectedList={industries}
                      disableCloseOnSelect={true}
                      label={t('contacts.addContact.industry')}
                    ></IndustrySelection>
                  </Box>
                  <Box>
                    {/* <Box className="compensation-typo fs-14" gutterBottom={true}>
                  {t('utils.jobFunction')}
                </Box> */}
                    <JobFunctionSelection
                      multiple={true}
                      defaultValue={currentValues?.contact_job_functions}
                      onChange={value => {
                        handleJobFunctionChange(value);
                      }}
                      label={t('utils.jobFunction')}
                      isCheckBox={true}
                      disableCloseOnSelect={true}
                      selectedList={jobFunction}
                    ></JobFunctionSelection>
                  </Box>
                </Box>
              </Box>

              <Box className='work-experience-layout'>
                <Box className='text-label header-font mb-2' variant='body1'>
                  {t('utils.workExperience')}
                </Box>
                <Box className='work_experience_cards p-11'>
                  <CardSlider
                    cardData={currentValues.contact_work_experience}
                    styleData={{
                      titleSize: 13,
                      textSize: 11,
                      height: 110,
                      width: 400,
                      minWidth: 400
                    }}
                    handleOpenPopup={openPopupEditing}
                  >
                    {currentValues.contact_work_experience.map((card, cardIndex) => (
                      <React.Fragment key={cardIndex}>
                        <ExperienceCard
                          data={card}
                          index={cardIndex}
                          getContactDetails={getContactInfo}
                          setLoading={setLoading}
                          addIcon={card?.board_is_present ? workExperience : ''}
                          editItems={openPopupEditing}
                          styleData={{
                            titleSize: 13,
                            textSize: 11,
                            height: 100,
                            width: 400,
                            minWidth: 400
                          }}
                        />
                        {cardIndex < currentValues.contact_work_experience.length - 1 && (
                          <Box className='pr-2 pl-2 arrowIcon'>
                            <ArrowBackIcon />
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                  </CardSlider>
                  <Box className='d-flex justify-content-between'>
                    <CustomButton
                      type={'secondary'}
                      variant='outlined'
                      iconLeft={<AddIcon sx={{ width: 14 }} />}
                      customWidth={120}
                      size={'medium'}
                      onClick={handleOpenPopup}
                      buttonText={currentValues?.contact_work_experience?.length === 0 ? t('actions.add') : t('actions.addAnotherActivity')}
                    ></CustomButton>
                  </Box>
                </Box>
              </Box>

              {isPopupOpen && (
                <AddWorkExperience
                  title={`${isEdit ? t('actions.update') : t('actions.add')} Work Experience`}
                  setIsPopupOpen={setIsPopupOpen}
                  isPopupOpen={isPopupOpen}
                  handleSave={handleSave}
                  register={register}
                  unregister={unregister}
                  setValue={setValue}
                  watch={watch}
                  data={isEdit ? currentValues.contact_work_experience[indexValue] : null}
                  setIsEdit={setIsEdit}
                  isEditing={isEdit}
                  indexValue={indexValue}
                  currentValues={currentValues}
                />
              )}
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
});

ProfessionalExperience.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  cardIndex: PropTypes.number,
  addIcon: PropTypes.string,
  id: PropTypes.string,
  onCancel: PropTypes.func
};

export default ProfessionalExperience;
