import { Box, Snackbar, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import QuickAddContact from '../../../components/common/QuickAddContact/v1/QuickAddContact';
import ViewContactDrawer from '../../../components/ViewContactDrawer';
import {
  BDDataApi,
  CandidateColorsDataApi,
  CandidateStageSetupAPi,
  PositionProfileDataApi,
  ProjectCandidateApi,
  ProjectDataApi,
  ProjectStagesDataApi,
  ignCompanyDataApi,
  picklistDropDownApis,
  picklistsDataApis
} from '../../../services/ApiService';
import { ERROR, FEE_TYPES, GET, POST, PUT, ROUTES, SUCCESS } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import withRouter from '../../../utils/withRouter';
import { ProjectDataContext } from './Context';
import HeaderClosed from './HeaderClosed';
import HeaderOpened from './HeaderOpened';
import ProjectTabLayout from './ProjectTabLayout';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import AddCompany from '../../../components/AddCompany';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import CompanySelectionDebounce from '../../../components/common/FunctionalComponents/CompanySelection/CompanySelectionDebounce';
import ResumeParser from '../../../components/common/ResumeParser';
import { updateViewProjectData } from '../../../store/viewProjectSlice';
import { customFormValidator, splitLastIfMultiple } from '../../../utils/common';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { createRequiredField, createSchema } from '../../Companies/Utils';
import ChooseFromContact from './Comman/ChooseFromContact';

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const NewFields = ({ activeStage, stages, setValue, setAddCompanyPopup, control, addCompanyPopup }) => {
  const [loader, setLoader] = useState(false);
  const [selectedStage, setSelectedStage] = useState();
  const { getValues: companygetValues, reset: companyReset, setValue: setCompanyValue, register: registerCompany, unregister: unregisterCompany, watch: watchCompany } = useForm();
  const changeOptionOnLoad = useRef(null);
  const { translateMessage } = useCustomMessageHook();

  const { t } = useTranslation();

  useEffect(() => {
    if (changeOptionOnLoad.current !== null) {
      setValue('company', changeOptionOnLoad.current);
      changeOptionOnLoad.current = null;
    }
  }, []);

  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries
        ? data?.company_industries.map(industry => ({
            industry_id: industry.id
          }))
        : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value
    };
    return addCompanyPayload;
  };
  const saveCompanyData = async () => {
    let formValues = { ...companygetValues() };
    const requestBody = getPayload(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody) {
      setLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);
      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        setAddCompanyPopup(false);
        companyReset();

        setLoader(false);
        const companyData = await ignCompanyDataApi(GET, {}, data?.id);
        // fetchCompanies();
        changeOptionOnLoad.current = { id: companyData?.data?.id, name: companyData?.data?.name };
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Company');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoader(false);
    }
  };
  const useStyles = makeStyles(() => ({
    inputRoot: {
      height: '56.28px' // Adjust the height as needed
    }
  }));
  const classes = useStyles();

  return (
    <>
      <Controller
        name='company'
        style={{}}
        control={control}
        render={({ field: { onChange, value } }) => (
          <CompanySelectionDebounce
            label={t('contacts.addContact.companyName')}
            classes={{
              inputRoot: classes.inputRoot
            }}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            required
            isForAddCompany
            className={'w-100'}
            addAnotherButtonOnChange={() => setAddCompanyPopup(true)}
            name='company'
            selectedValue={value}
            value={value}
            ignoreFiltering
          />
        )}
      />
      <CustomMuiDropdown
        options={
          stages?.map(stage => {
            if (stage.stage_name !== 'All') return { label: stage.stage_name };
          }) || []
        }
        disabled={activeStage?.stage_name !== 'All'}
        label='Stage'
        value={selectedStage?.stage_name !== 'All' ? { label: selectedStage?.stage_name } : null}
        renderInput={params => <TextField {...params} variant='outlined' />}
        onChange={(event, newVal) => {
          setSelectedStage({ stage_name: newVal.label });
          // { label: 'KG Interview' }
        }}
        getOptionLabel={option => {
          return option?.label || '';
        }}
      />
      <AddCompany
        isPopupOpen={addCompanyPopup}
        handleClose={() => setAddCompanyPopup(false)}
        handleSubmit={saveCompanyData}
        setIsLoading={setLoader}
        loading={loader}
        setValue={setCompanyValue}
        register={registerCompany}
        reset={companyReset}
        setIsPopupOpen={setAddCompanyPopup}
        unregister={unregisterCompany}
        watch={watchCompany}
        noRedirection
      />
    </>
  );
};

NewFields.propTypes = {
  setValue: PropTypes.func,
  setAddCompanyPopup: PropTypes.func,
  control: PropTypes.func,
  addCompanyPopup: PropTypes.bool,
  projectData: PropTypes.object,
  activeStage: PropTypes.string,
  stages: PropTypes.object
};

function ViewProject(props) {
  const [universalState, setUniversalState] = useState({});
  const [headerExpandedView, setExpandedView] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [projectContactData, setProjectContactData] = useState({});
  const [isPublished, setPublished] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [loader, setLoader] = useState(false);
  const [stage, setStage] = useState(projectData?.stage ?? '');
  const [bdStatus, setBdSatus] = useState(projectData?.bd_status ?? '');
  const [tabIndexSet, setTabIndexSet] = useState([]);
  const location = useLocation();

  const navigate = useNavigate();
  const [isComingFromViewProject, setIsComingFromViewProject] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [showDeletedRows, setShowDeletedRows] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isContactDrawerOpen, setIsContactDrawerOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState('');
  const [stages, setStages] = useState([]);
  const [activeStage, setActiveStage] = useState(null);
  const [dummyText, setDummyText] = useState({
    experience: '',
    leadership: ''
  });
  const [openPipeline, setOpenPipeline] = useState(false);

  const handleContactClick = id => {
    setSelectedContactId(id);
    setIsContactDrawerOpen(true);
  };

  let queryParams = useQuery();
  const projectId = props.params.id;

  const { data: DBData } = useApi({
    queryFn: () => {
      return BDDataApi(GET, projectId, null, null, null);
    }
  });
  const { data: PositionProfileData } = useApi({
    queryFn: () => {
      return PositionProfileDataApi(GET, projectId, null, '', null);
    }
  });
  const fetchTranslate = async () => {
    const res = await picklistsDataApis(GET, 'ROADMAP_HEADER');
    const experience = res?.data?.filter(text => text?.field_value === 'EXPRIENCE');
    const leadership = res?.data?.filter(text => text?.field_value === 'COMPETENCY');

    const dummyText = {
      experience: experience?.[0]?.short_desc ?? '',
      leadership: leadership?.[0]?.short_desc ?? ''
    };

    setDummyText({ ...dummyText });
  };
  useEffect(() => {
    fetchTranslate();
  }, []);

  useEffect(() => {
    const bdbyId = async () => {
      let feeObj = {};
      if (!DBData?.data?.fee_type_desc || DBData?.data?.fee_type_desc === FEE_TYPES.ONE_THIRD) {
        feeObj = {
          fee_type_desc: DBData?.data?.fee_type ? DBData?.data?.fee_type : FEE_TYPES.ONE_THIRD,
          fee_percentage: DBData?.data?.fee_percentage ? DBData?.data?.fee_percentage : 33.33
        };
      }
      setCurrentData({
        ...DBData?.data,
        intervals_desc: DBData?.data?.intervals_desc ? DBData?.data?.intervals_desc : DBData?.data?.intervals,
        project_indirect_fees: DBData?.data?.project_indirect_fees?.length ? DBData?.data?.project_indirect_fees : [{}],
        estimated_percentage_bases: DBData?.data?.estimated_percentage_bases?.length ? DBData?.data?.estimated_percentage_bases : [{}],
        recruiters: DBData?.data?.recruiters?.length ? DBData?.data?.recruiters : [{}],
        researchers: DBData?.data?.researchers?.length ? DBData?.data?.researchers : [{}],
        basic_partners: DBData?.data?.partners ? DBData?.data?.partners.map(partner => partner.user)?.filter(user => user) : [],
        client_team: DBData?.data?.client_team ? DBData?.data?.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
        eas: DBData?.data?.eas?.length ? DBData?.data?.eas : [{}],
        partners: DBData?.data?.partners ? DBData?.data?.partners : [],
        billing_desc: DBData?.data?.billing_desc ? DBData?.data?.billing_desc : '',
        bd_status: DBData?.data?.bd_status ? DBData?.data?.bd_status : 'target_identified',
        actual_percentage_bases: DBData?.data?.actual_percentage_bases?.length ? DBData?.data?.actual_percentage_bases : [{}],
        ...feeObj
      });
    };
    bdbyId();
  }, [DBData]);

  useEffect(() => {
    const getCompanyList = async () => {
      const sub_route = 'companies-picklist';
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if (status === 200) {
        setCompanyList(data);
      }
    };
    getCompanyList();
  }, [projectId]);

  const getBD = async id => {
    try {
      if (id) {
        const { status, data } = await BDDataApi(GET, id);
        if (status === 200) {
          let feeObj = {};
          if (!data.fee_type_desc || data.fee_type_desc === FEE_TYPES.ONE_THIRD) {
            feeObj = {
              fee_type_desc: FEE_TYPES.ONE_THIRD,
              fee_percentage: 33.33
            };
          }
          setCurrentData({
            ...data,
            intervals_desc: data.intervals_desc ? data.intervals_desc : data.intervals,
            project_indirect_fees: data.project_indirect_fees?.length ? data.project_indirect_fees : [{}],
            estimated_percentage_bases: data.estimated_percentage_bases?.length ? data.estimated_percentage_bases : [{}],
            recruiters: data.recruiters?.length ? data.recruiters : [{}],
            researchers: data.researchers?.length ? data.researchers : [{}],
            basic_partners: data.partners ? data.partners.map(partner => partner.user)?.filter(user => user) : [],
            client_team: data.client_team ? data.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
            eas: data.eas?.length ? data.eas : [{}],
            partners: data.partners ? data.partners : [],
            billing_desc: data.billing_desc ? data.billing_desc : '',
            bd_status: data.bd_status ? data.bd_status : 'target_identified',
            ...feeObj
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(error, 'error');
    }
  };

  const fetchProjectData = async () => {
    try {
      setLoader(true);
      const res = await ProjectDataApi(GET, projectId, null, null, null);
      if (res?.data) {
        const data = res?.data?.clientContacts || [];
        const hiringManager = data.filter(data => data?.client_contact_types?.findIndex(type => type?.rel_type === 'Hiring Manager') !== -1);
        const clientManager = data.filter(data => data?.client_contact_types?.findIndex(type => type?.rel_type === 'Hiring Manager') === -1);

        setProjectContactData({
          hiring_manager: hiringManager,
          client_contact: clientManager
        });
        setProjectData(res?.data);
      } else {
        throw new Error('Not Found');
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
      setRefresh(true);
    }
  };

  const dispatch = useDispatch();

  const fetchColorLabels = async () => {
    const candidateColorsData = await CandidateColorsDataApi(GET, queryParams.get('id'));
    dispatch(updateViewProjectData({ key: 'selectedColor', data: null }));
    dispatch(updateViewProjectData({ key: 'colors', data: candidateColorsData?.data || [] }));
  };

  useEffect(() => {
    fetchColorLabels();
  }, [projectId]);

  useEffect(() => {
    fetchProjectData();
  }, [tabIndexSet]);

  const handlePublishButton = e => {
    e.preventDefault();
    setPublished(!isPublished);
  };

  const handleUniversalState = event => {
    const key = event.target.name;
    const value = event.target.value;
    universalState[key] = value;
    setUniversalState(Object.assign({}, universalState));
  };

  const getValueFromUniversalState = key => {
    return universalState[key] ?? '';
  };

  const getUniversalState = () => {
    return universalState;
  };

  const handleTabChanges = (tabIndex, childTabIndex) => {
    setTabIndexSet([tabIndex, childTabIndex]);
  };

  const { data: stageResponse } = useApi({
    queryFn: () => {
      return ProjectStagesDataApi(GET);
    }
  });

  const fetchCandidateStages = () => {
    CandidateStageSetupAPi(GET, `/all?projectId=${projectData?.id ? projectData?.id : queryParams.get('id')}&showLogicalStages=true`).then(res => {
      setStages(res.data);
      if (activeStage) {
        const stage = res.data.find(stage => stage.stage_name === activeStage.stage_name);
        setActiveStage(stage);
      } else {
        setActiveStage(res.data?.[0]);
      }
    });
  };

  useEffect(() => {
    fetchCandidateStages();
  }, []);

  useEffect(() => {
    setStage(projectData?.stage ?? DBData?.data?.stage ?? '');
    setBdSatus(projectData?.bd_status ?? DBData?.data?.bd_status ?? '');
  }, [projectData, DBData]);

  const handleStageChange = async value => {
    try {
      setLoader(true);
      const payload = {
        id: projectData?.id,
        stage: value
      };
      await ProjectDataApi(PUT, '', payload, '', '');
      setStage(value);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleBdStatusChange = async value => {
    try {
      setLoader(true);
      const payload = {
        id: projectData?.id,
        bd_status: value
      };
      await ProjectDataApi(PUT, '', payload, '', '');
      setBdSatus(value);
      setCurrentData(prev => ({ ...prev, bd_status: value }));
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (location?.state?.draft || isComingFromViewProject) {
      setIsComingFromViewProject(true);
      if (PositionProfileData && PositionProfileData?.data?.description && PositionProfileData?.data?.description.length > 0) {
        navigate(`${ROUTES.projectDetails}/${projectId}/Overview/Details`);
      }
    }
  }, [PositionProfileData]);

  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const [openCreateFromResume, setOpenCreateFromResume] = useState(false);
  const [openChooseFromContact, setOpenChooseFromContact] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const { register, handleSubmit, setValue, control, getValues } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState({});
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    linkedin: yup.string().url(),
    phonenumber: yup
      .string()
      .transform((value, originalValue) => {
        // Coerce empty string or null to null
        if (originalValue === '' || originalValue === null) return null;
        return value;
      })
      .matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .nullable(),
    jobtitle: yup.string(),
    company: yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required()
    })
  });

  const clearInputs = () => {
    setValue('firstName', null);
    setValue('lastName', null);
    setValue('email', null);
    setValue('linkedin', null);
    setValue('phonenumber', null);
    setValue('jobtitle', null);
    setValue('company', null);
  };

  const onAddSubmit = (data, selectedStage) => {
    setDisabled(true);
    schema
      .validate(data)
      .then(() => {
        ProjectCandidateApi(POST, '/create-from-contact', { contact: data, projectId: projectData?.id, stage: selectedStage?.stage_name })
          .then(res => {
            if (res.status === 500) {
              setAlert({ open: true, message: res.data.message });
            } else {
              setAlert({ open: true, message: 'Candidate added successfully' });
              setOpenQuickAddContact(false);
            }
          })
          .catch(err => {
            setAlert({ open: true, message: err });
          })
          .finally(() => {
            setDisabled(false);
            clearInputs();
          });
      })
      .catch(err => {
        setAlert({ open: true, message: err.errors[0] });
        setDisabled(false);
      });
  };

  const handleAddCandidate = option => {
    if (option === 'Quick add') {
      setOpenQuickAddContact(true);
    } else if (option === 'Add from resume') {
      setOpenCreateFromResume(true);
    } else if (option === 'Select existing contact') {
      setOpenChooseFromContact(true);
    }
  };

  const openCandidateDrawer = id => {
    setIsDrawerOpen(true);
    setSelectedCandidate(id);
  };

  const refreshDataCbRef = useRef(null);

  return (
    <ProjectDataContext.Provider
      value={{
        projectData: {
          ...projectData,
          ...projectContactData
        },
        isBD: projectData?.record_type === 'Business Development',
        bgData: currentData,
        saveInitiate: false,
        universalState: universalState,
        tabIndexSet,
        handleUniversalState,
        getUniversalState,
        getValueFromUniversalState,
        setUniversalState,
        handleTabChanges,
        setProjectData,
        fetchProjectData,
        companyList,
        dummyText,
        showDeletedRows,
        setShowDeletedRows,
        refresh,
        setRefresh
      }}
    >
      <Box>
        <Loader show={loader}></Loader>
        <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={selectedCandidate?.contact_id} />

        <QuickAddContact
          open={openQuickAddContact}
          setOpen={setOpenQuickAddContact}
          label={''}
          projectId={projectData?.id}
          useFormController={{ register, handleSubmit, setValue, control, getValues }}
          newFields={() => (
            <NewFields
              setActiveStage={setActiveStage}
              activeStage={activeStage}
              stages={stages}
              setStages={setStages}
              projectData={projectData}
              setAddCompanyPopup={setAddCompanyPopup}
              setValue={setValue}
              control={control}
              addCompanyPopup={addCompanyPopup}
            />
          )}
          isAddDisabled={disabled}
          onAddSubmit={onAddSubmit}
        />
        <ResumeParser
          isPopupOpen={openCreateFromResume}
          callback={() => {
            refreshDataCbRef.current = true;
          }}
          handleClose={() => setOpenCreateFromResume(false)}
          createProjectCandidate={true}
          stages={stages}
          stage={activeStage?.stage_name}
          projectId={projectData?.id}
        />
        <ChooseFromContact
          callback={() => {
            refreshDataCbRef.current = true;
            setTimeout(() => {
              refreshDataCbRef.current = false;
            }, 300);
          }}
          stages={stages}
          open={openChooseFromContact}
          setOpen={setOpenChooseFromContact}
          openCandidateDrawer={openCandidateDrawer}
          initStage={activeStage?.stage_name}
          projectId={projectData?.id}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert?.open === true}
          onClose={() => setAlert(alertOld => ({ ...alertOld, open: false }))}
          message={alert?.message}
          key={0}
        />
        {headerExpandedView && (
          <div style={{ marginRight: '2rem' }}>
            <HeaderOpened
              handleContactClick={handleContactClick}
              handleExpand={() => setExpandedView(false)}
              isPublished={isPublished}
              stages={stageResponse?.data ?? []}
              handleStageChange={handleStageChange}
              handleBdStatusChange={handleBdStatusChange}
              handlePublish={handlePublishButton}
              stage={stage}
              bdStatus={bdStatus}
              handleAddCandidate={handleAddCandidate}
              fetchProjectData={fetchProjectData}
              switchToPipeline={() => setOpenPipeline(true)}
            />
          </div>
        )}
        {!headerExpandedView && (
          <div style={{ marginRight: '1.5rem' }}>
            <HeaderClosed
              handleContactClick={handleContactClick}
              handleExpand={() => setExpandedView(true)}
              isPublished={isPublished}
              stages={stageResponse?.data ?? []}
              handlePublish={handlePublishButton}
              handleStageChange={handleStageChange}
              handleBdStatusChange={handleBdStatusChange}
              stage={stage}
              bdStatus={bdStatus}
              switchToPipeline={() => setOpenPipeline(false)}
            />
          </div>
        )}
        <>
          <ProjectTabLayout
            data={{ ...projectData, ...projectContactData }}
            getValueFromUniversalState={getValueFromUniversalState}
            handleUniversalState={handleUniversalState}
            DBData={currentData}
            getBD={getBD}
            setCurrentData={setCurrentData}
            PositionProfileData={PositionProfileData}
            refreshDataCbRef={refreshDataCbRef}
            paramsProjectId={projectId}
            openPipelineTab={openPipeline}
          />
          {selectedContactId && isContactDrawerOpen && (
            <ViewContactDrawer
              navigateToAllContacts={false}
              isDrawerOpen={isContactDrawerOpen}
              setIsDrawerOpen={() => {
                setIsContactDrawerOpen(false);
              }}
              id={selectedContactId}
            />
          )}
        </>
      </Box>
    </ProjectDataContext.Provider>
  );
}

ViewProject.propTypes = {
  params: PropTypes.object
};

export default withRouter(ViewProject);
