import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DocumentIcon, PdfIcon } from '../../../../components/Icons';
import { echoSenseApi } from '../../../../services/ApiService';
import { ERROR, GET } from '../../../../services/constantService';
import { formatDownloadFileName } from '../../../../utils/common';
import { showToast } from '../../constants';
import '../../index.scss';

const AnalyzeActionCellRender = ({ params, deleteRecord = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const downloadReport = async (task, type = 'docx') => {
    setLoading(true);
    try {
      const subRoute = `download-analyze-report?taskId=${task.id}&type=${type}`;
      const response = await echoSenseApi(GET, subRoute);
      const { error, msg, data: reportUrl } = response.data;
      if (error) {
        showToast(msg, ERROR);
      } else {
        const fileResponse = await fetch(reportUrl);
        const blob = await fileResponse.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.download = `${formatDownloadFileName(task.analysis_type)}-${formatDownloadFileName(task.friendly_name)}.${type}`;
        anchor.click();

        // Revoke Blob URL after download to release memory
        window.URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      showToast(error.message, ERROR);
    }
    setLoading(false);
  };

  return loading ? (
    <Box textAlign='center'>
      <CircularProgress size='20px' />
    </Box>
  ) : (
    <Box textAlign='center'>
      <IconButton
        className='mx-2 p-0'
        onClick={() => {
          if (params?.data?.decision_summary_completed_at) downloadReport(params.data, 'pdf');
        }}
        disabled={!params?.data?.decision_summary_completed_at}
      >
        <PdfIcon height={20} width={20} fill={`${!params?.data?.decision_summary_completed_at ? 'gray' : '#23cea7'}`} />
      </IconButton>
      <IconButton
        className={'p-0 mx-2'}
        onClick={() => {
          if (params?.data?.decision_summary_completed_at) downloadReport(params?.data, 'docx');
        }}
        disabled={!params?.data?.decision_summary_completed_at}
      >
        <DocumentIcon height={20} width={20} fill={`${!params?.data?.decision_summary_completed_at ? 'gray' : '#23cea7'}`} />
      </IconButton>
      <Tooltip title='Remove' arrow>
        <IconButton className='ES-download-icon p-0 mx-2' onClick={() => deleteRecord(params?.data?.id)}>
          <RemoveCircleOutlineOutlinedIcon sx={{ fill: 'red', fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default AnalyzeActionCellRender;

AnalyzeActionCellRender.propTypes = {
  params: PropTypes.object,
  deleteRecord: PropTypes.func
};
