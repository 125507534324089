import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';

import { DeleteForever } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../../components/common/InputField';
import Loader from '../../../../../components/common/Loader';
import CustomNumbericField from '../../../../../components/common/StyledComponents/CustomNumericField';
import { SFPADataApi, TargetTitleDataApi } from '../../../../../services/ApiService';
import { DELETE, POST } from '../../../../../services/constantService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';

function TargetTitle() {
  const { projectData, handleUniversalState, showDeletedRows, refresh, setRefresh } = useContext(ProjectDataContext);
  const [recommendedTargetTitle, setRecommendedTargetTitle] = useState([]);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const currencyIcon = useMemo(() => {
    return projectData?.bd_currency_values?.currency_icon ?? '';
  }, [projectData]);

  //Translate
  const { t } = useTranslation();

  //Api Integration
  const { data: targetTitleOptionResponse } = useApi({
    queryFn: () => {
      return TargetTitleDataApi(POST, '', '', 'all', '');
    }
  });

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetTitles',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };
  const deleteTargetTitle = async cardData => {
    try {
      setLoader(true);

      if (!cardData.id.includes('new') && !cardData.id.includes('bulk')) {
        const res = await TargetTitleDataApi(DELETE, '', { id: cardData.id }, '', '');
        console.log('target-title-delete', res);
      }

      const updatedTargetTitle = recommendedTargetTitle.filter(curr => curr.id != cardData.id);
      const newValues = [...updatedTargetTitle, { ...cardData, deleted_at: new Date() }];
      setRecommendedTargetTitle(newValues, cardData);
      handleUniversalStateLocally(newValues, cardData);
      setLoader(false);
    } catch (err) {
      enqueueSnackbar(err, 'error');
      setLoader(false);
    }
  };

  const addTargetTitle = (value, index) => {
    let name = '';
    let id = '';
    if (typeof value == 'object') {
      name = value?.name || value?.title_name;
      id = 'new-' + index + recommendedTargetTitle.length + 1;
    } else {
      name = value;
      id = 'new-type' + index + recommendedTargetTitle.length + 1;
    }
    const newTargetTitle = {
      title_name: name,
      id: id,
      revenue_range_from: '',
      revenue_range_to: '',
      employee_range_from: '',
      employee_range_to: '',
      weight: '',
      deleted_at: null
    };
    const updatedTargetTitle = [newTargetTitle, ...recommendedTargetTitle];
    setRecommendedTargetTitle(updatedTargetTitle);
    handleUniversalStateLocally(updatedTargetTitle);
  };

  const handleTargetTitleChange = (key, value, index) => {
    recommendedTargetTitle.filter(data => (showDeletedRows ? data?.deleted_at == null || data?.deleted_at != null : data?.deleted_at == null))[index][key] = value;
    const newTargetTitles = recommendedTargetTitle.slice();
    setRecommendedTargetTitle(newTargetTitles);
    handleUniversalStateLocally(newTargetTitles);
  };

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      console.log({ projectData });
      const companyName = projectData?.ign_companies?.name;
      const jobTitle = projectData?.job_title;
      const ign_industry = {
        name: projectData?.ign_industries?.name
      };
      const fetchedJobTitleList = recommendedTargetTitle?.map(title => {
        return {
          isSelected: true,
          isNew: false,
          name: title?.title_name
        };
      });

      const payload = {
        template_name: 'SFPA-Jobtitle-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: [ign_industry],
          fetchedJobTitleList: fetchedJobTitleList,
          key: 'fetchedJobTitleList',
          searchJobTitle: jobTitle
        }
      };
      console.log('target-title-more-payload', payload);

      const res = await SFPADataApi(POST, projectData?.id, payload);

      console.log('target-title-res', res);
      if (res && res?.status == 200 && res?.data && res?.data?.job_titles) {
        const allData = res?.data?.job_titles ?? [];
        const newData = allData?.filter(data => data?.isNew == true);

        const jobTitles = newData.map((data, index) => {
          return {
            title_name: data?.name,
            id: 'bulk-' + index,
            revenue_range_from: '',
            revenue_range_to: '',
            employee_range_from: '',
            employee_range_to: '',
            weight: '0',
            deleted_at: null
          };
        });
        console.log('target-title', { allData, newData, jobTitles });
        const newJobTitles = [...jobTitles, ...recommendedTargetTitle];
        setRecommendedTargetTitle(newJobTitles);
        handleUniversalStateLocally(newJobTitles);
      } else {
        enqueueSnackbar(res?.data?.message, 'error');
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  const addBackSoftDelete = async cardData => {
    setLoader(true);

    if (!cardData.id.includes('new') && !cardData.id.includes('bulk')) {
      await TargetTitleDataApi(POST, '', { id: cardData.id }, 'restore', '');
    }

    const updatedTargetTitle = recommendedTargetTitle.filter(curr => curr.id != cardData.id);
    const newValues = [...updatedTargetTitle, { ...cardData, deleted_at: null }];
    setRecommendedTargetTitle(newValues, cardData);
    handleUniversalStateLocally(newValues, cardData);
    setLoader(false);
  };

  useEffect(() => {
    if (recommendedTargetTitle.length == 0 && !isDataLoaded) {
      setRecommendedTargetTitle(projectData?.targetTitles || []);
      if (projectData?.targetTitles) {
        setIsDataLoaded(true);
      }
    }
    if (refresh) {
      setRecommendedTargetTitle(projectData?.targetTitles || []);
      setRefresh(false);
    }
  }, [projectData]);
  return (
    <Box>
      <Loader show={loader} />

      <AccordionHeader
        onSearch={addTargetTitle}
        searchOptions={targetTitleOptionResponse?.data?.data || []}
        searchLabel={t('utils.addTargetTitle')}
        isDropDown={false}
        onClickGetMore={() => onClickShowMore()}
      />
      <Stack
        direction='column'
        sx={{
          maxHeight: '40vh',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        {recommendedTargetTitle
          .filter(data => (showDeletedRows ? data?.deleted_at == null || data?.deleted_at != null : data?.deleted_at == null))
          .map((cardData, index) => (
            <Box
              key={cardData?.id}
              sx={{
                width: '93%',
                backgroundColor: 'background.main',
                color: 'titleBarBackground.main',
                position: 'relative'
              }}
              className='pl-2 pt-2 pb-2 pr-2 rounded border m-1'
            >
              {cardData?.deleted_at == null ? (
                <DeleteForever
                  sx={{
                    color: 'error.main',
                    top: '40%',
                    left: '103%',
                    position: 'absolute',
                    cursor: 'pointer'
                  }}
                  fontSize='small'
                  onClick={() => deleteTargetTitle(cardData)}
                />
              ) : (
                <DoneIcon
                  sx={{
                    color: 'primary.main',
                    top: '37%',
                    left: '103%',
                    position: 'absolute',
                    cursor: 'pointer'
                  }}
                  fontSize='small'
                  onClick={() => addBackSoftDelete(cardData)}
                />
              )}

              <Grid container fullWidth spacing={1} alignItems={'flex-end'}>
                <Grid item xs={3}>
                  {cardData?.deleted_at == null ? (
                    <InputField
                      size='small'
                      label={t('utils.targetTitle')}
                      value={cardData?.title_name}
                      fullWidth
                      onChange={e => {
                        e.preventDefault();
                        handleTargetTitleChange('title_name', e.target.value, index);
                      }}
                    />
                  ) : (
                    <div className='fs-14 ml-2 mb-2' style={{ color: 'black', textDecoration: 'line-through' }}>
                      {cardData?.title_name}
                    </div>
                  )}
                </Grid>
                <Grid item xs={1.5}>
                  <CustomNumbericField
                    value={cardData?.weight || ''}
                    label={t('utils.weight')}
                    sx={{
                      width: '100%'
                    }}
                    onChange={value => {
                      handleTargetTitleChange('weight', parseInt(value, 10), index);
                    }}
                    disabled={cardData?.deleted_at != null}
                  />
                </Grid>
                <Grid container item xs={3.75} direction='column' alignItems={'center'}>
                  <Typography variant='caption' color={'initial'}>
                    {t('utils.employeeRange')}
                  </Typography>
                  <Stack direction='row' className='mt-1' alignItems={'center'}>
                    <CustomNumbericField
                      value={cardData.employee_range_from}
                      label={t('utils.minimum')}
                      sx={{
                        width: '100%'
                      }}
                      onChange={value => {
                        handleTargetTitleChange('employee_range_from', value, index);
                      }}
                      disabled={cardData?.deleted_at != null}
                    />
                    -
                    <CustomNumbericField
                      value={cardData.employee_range_to}
                      label={t('utils.maximum')}
                      sx={{
                        width: '100%'
                      }}
                      onChange={value => {
                        handleTargetTitleChange('employee_range_to', value, index);
                      }}
                      disabled={cardData?.deleted_at != null}
                    />
                  </Stack>
                </Grid>
                <Grid container item xs={3.75} direction='column' alignItems={'center'}>
                  <Typography variant='caption' color={'initial'}>
                    {t('utils.revenueRange')}
                  </Typography>
                  <Stack direction='row' className='mt-1' alignItems={'center'}>
                    <CustomNumbericField
                      value={cardData.revenue_range_from}
                      label={t('utils.minimum')}
                      sx={{
                        width: '100%'
                      }}
                      onChange={value => {
                        handleTargetTitleChange('revenue_range_from', value, index);
                      }}
                      disabled={cardData?.deleted_at != null}
                      prefix={currencyIcon + ' '}
                    />
                    -
                    <CustomNumbericField
                      value={cardData.revenue_range_to}
                      label={t('utils.maximum')}
                      sx={{
                        width: '100%'
                      }}
                      onChange={value => {
                        handleTargetTitleChange('revenue_range_to', value, index);
                      }}
                      disabled={cardData?.deleted_at != null}
                      prefix={currencyIcon + ' '}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Stack>
    </Box>
  );
}

export default memo(TargetTitle);
