import { ExpandMore } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../CustomButton';
import CustomSelectionComponent from '../../CustomSelectionComponent';
// import { useDispatch, useSelector } from "react-redux";
// import { fetchPickLists } from "../../../../actions";

const CompanyGridSelection = props => {
  const { data, handleBulkDelete, handleAssignTag, disabled } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const open = Boolean(anchorEl);
  //TO DO : this code will required in future
  // const companyActionOptions = useSelector((state) => state.commonReducer.companyAction);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!companyActionOptions) {
  //     dispatch(fetchPickLists("COMPANY_ACTIONS", "companyAction"));
  //   } else {
  //     setOptions(companyActionOptions);
  //   }
  // }, [companyActionOptions, dispatch]);
  useEffect(() => {
    const action = [{ short_desc: 'Delete' }, { short_desc: 'Assign Tag' }];
    setOptions(action);
  }, []);
  const actionLabel = {
    label1: 'Assign Tag',
    label2: 'Delete',
    label3: 'Change Status'
  };
  const handleMenuItemClick = option => {
    if (option?.short_desc === actionLabel.label2) handleBulkDelete(data);
    // if (option?.short_desc === actionLabel.label3) handleBulkChangeStatus(data,);
    if (option?.short_desc === actionLabel.label1) handleAssignTag(data, 'bulkTag');
    setAnchorEl(null);
    console.log(option);
  };
  const customButtonForCompany = (
    // <CustomButton
    //   type={'primary'}
    //   variant='contained'
    //   size={'medium'}
    //   buttonText={'Action'}
    //   customWidth={96}
    //   onClick={handleClick}
    //   iconRight={<ExpandMore className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} sx={{ width: 20, height: 20 }} />}
    // />
    <CustomButton
      type={'primary'}
      variant='contained'
      size={'medium'}
      buttonText={'Bulk Actions'}
      customWidth={96}
      onClick={handleClick}
      disabled={disabled}
      sx={{
        backgroundColor: disabled ? 'white' : undefined,
        color: disabled ? 'black' : undefined,
        '&:hover': {
          backgroundColor: disabled ? 'white' : undefined
        }
      }}
      iconRight={<ExpandMore className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} sx={{ width: 20, height: 20 }} />}
    />
  );
  // return <CustomSelectionComponent options={options} title='Action' handleMenuItemClick={handleMenuItemClick} component={customButtonForCompany} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />;
  return (
    <CustomSelectionComponent
      options={options}
      title='Action'
      handleMenuItemClick={handleMenuItemClick}
      component={customButtonForCompany}
      setAnchorEl={setAnchorEl}
      anchorEl={disabled ? null : anchorEl} // Add this condition
      disabled={disabled} // Add this prop if CustomSelectionComponent accepts it
    />
  );
};
CompanyGridSelection.propTypes = {
  data: PropTypes.object,
  handleBulkDelete: PropTypes.func,
  handleAssignTag: PropTypes.func,
  disabled: PropTypes.bool
};

export default CompanyGridSelection;
