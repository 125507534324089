export const AWS_DOMAIN_DEV = 'https://galaxy-dev.auth.us-east-2.amazoncognito.com';
export const AWS_DOMAIN_PROD = 'https://auth.galaxy-2020.com';
export const AWS_APP_CLIENT_ID_DEV = '1gaf4glg0gafaf9gojc5738u2m';
export const AWS_APP_CLIENT_ID_PROD = '3n6sd3d4sdupn0ivnvsr138f00';
export const AWS_TOKEN_ENDPOINT = '/oauth2/token';
export const AWS_TOKEN_GRANT_TYPE_AUTH_CODE = 'authorization_code';
export const AWS_TOKEN_GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
export const SOCKET_PORT = `:${process.env.REACT_APP_SOCKET_PORT || 5000}`;
export const TEST_URL = 'https://ignyte-dev.galaxy-2020.com';
export const TEST_SOCKET_URL = TEST_URL + SOCKET_PORT;
export const LAB_URL = 'https://lab.galaxy-2020.com';
export const LAB_SOCKET_URL = LAB_URL + SOCKET_PORT;
export const LOCAL_URL = 'http://localhost:3000';
export const LOCAL_SOCKET_URL = `http://localhost${SOCKET_PORT}`;
export const STAGE_URL = 'https://staging.galaxy-2020.com';
export const STAGE_SOCKET_URL = STAGE_URL + SOCKET_PORT;
export const PROD_URL1 = 'https://www.galaxy-2020.com';
export const PROD_SOCKET_URL1 = PROD_URL1 + SOCKET_PORT;
export const PROD_URL2 = 'https://app.galaxy-2020.com';
export const PROD_SOCKET_URL2 = PROD_URL2 + SOCKET_PORT;
export const PROD_URL3 = 'https://asia.galaxy-2020.com';
export const PROD_SOCKET_URL3 = PROD_URL3 + SOCKET_PORT;

/* /* Use below line when development in local for cognito redirect url */
// export const AWS_APP_REDIRECT_PATH = "/searches/my-searches";

/* Use below line when live application environment for cognito redirect url */
export const AWS_APP_REDIRECT_PATH = '/contacts';

export const SYNCLINK_URL =
  'https://galaxy-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=4udpmvte98tadol3ren9464gp5&response_type=code&scope=email+openid+profile&redirect_uri=https://synclink-test.kingsleygate.com/';
export const GALAXY_HL_SSO_URL_DEV = 'https://galaxy-dev.auth.us-east-2.amazoncognito.com/login?client_id=' + AWS_APP_CLIENT_ID_DEV + '&response_type=code&scope=email+openid+profile&redirect_uri=';
export const GALAXY_HL_SSO_URL_PROD = 'https://auth.galaxy-2020.com/login?client_id=' + AWS_APP_CLIENT_ID_PROD + '&response_type=code&scope=email+openid&redirect_uri=';
export const GALAXY_GUIDE_URL = 'https://galaxy-web-app.s3.us-east-2.amazonaws.com/galaxy-assets/The_Ultimate_Galaxy_Guide_July_2022.pdf';
export const HTML_TAGS_FILTER_REGEX = /(<([^>]+)>)/gi;
export const KGU_DASHBOARD = 'https://kgpuniversity.com';
export const CASH_COLLECTED_DASHBOARD = '/dashboard';
export const DISALLOW_EXTENSION =
  /(\.|\/)(bat|exe|cmd|sh|php([0-9])?|pl|cgi|386|dll|com|torrent|js|app|jar|pif|vb|vbscript|wsf|asp|cer|csr|jsp|drv|sys|ade|adp|bas|chm|cpl|crt|csh|fxp|hlp|hta|inf|ins|isp|jse|htaccess|htpasswd|ksh|lnk|mdb|mde|mdt|mdw|msc|msi|msp|mst|ops|pcd|prg|reg|scr|sct|shb|shs|url|vbe|vbs|wsc|wsf|wsh)$/i;
// export const AR_BY_PARTNER = 'https://prod-useast-a.online.tableau.com/#/site/kingsleygatepartners/views/ARReport08172022/ARByPartner_1?:iid=4';
// export const SALES_BY_COMPENSATION_DASHBOARD = 'https://prod-useast-a.online.tableau.com/#/site/kingsleygatepartners/views/SalesByPartnerReportAugust2022/SalesByPartnerReport?:iid=1';
// export const CASH_COLLECTED_DASHBOARD = 'https://prod-useast-a.online.tableau.com/#/site/kingsleygatepartners/views/CashCollectedReport08172022/CashCollectedByPartner_1?:iid=2';
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const POSITIVE_INTEGER_WITH_ZERO_REGEX = /^[1-9]+[0-9]*$/;
//eslint-disable-next-line
export const LINKEDIN_URL_REGEX =
  //eslint-disable-next-line
  /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
export const WEBSITE_URL_REGEX =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
export const LINKEDIN_URL_REGEX_COMPANY = /^https?:\/\/(?:www\.)?linkedin\.com(?:\/[^\s<>]*)?$/;
// export const WEBSITE_URL_REGEX_COMPANY = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[^\s]*)?$/g;
export const WEBSITE_URL_REGEX_COMPANY = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/[^\s]*)?$/;

//eslint-disable-next-line
// export const LINKEDIN_URL_PROFILE_REGEX =
//   "^(https?://)?(www.)?linkedin.com/in/[a-zA-Z0-9-]+/?$";
export const LINKEDIN_URL_PROFILE_REGEX = '^(https?://)?(www.)?linkedin.com/(in|company)/[a-zA-Z0-9-]+(?:/.*)?$';

// export const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
export const URL_VALIDATION_MESSAGE = 'Please enter a valid URL, e.g., https://linkedin.com';
export const URL_LENGTH_VALIDATION_MESSAGE = 'Please enter less than 254 characters only.';
export const EXPORT_CONTACT_LIMIT = 1500;
export const MAX_URL_LENGTH = 254;
export const SPECIAL_CHARACTERS_REPLACE_REGEX = /[&/\\#,+()$~%.'":*?<>{}]/g;
export const REPLACE_SPACE_REGEX = /\s/g;
export const CONSTANT_MESSAGE = 'Notes Description and Title cannot be empty together';
export const EXPORT_LIMIT = 10000;
export const GET = 'get';
export const CANDIDATE = 'candidate';
export const START_YEAR = '1970';
export const ACTIVITY = 'activity';
export const ACTIVITIES = 'activities';
export const ACTIVITIES_ATTRIBUTE = 'activities-attribute';
export const CONTACT = 'contact';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'patch';
export const DELETE = 'delete';
export const BLOB = 'blob';
export const CLIENTS = 'clients';
export const KGP_TEAM = 'kgp-team';
export const CLIENTSUITE = 'ClientSuite';
export const UPDATED_AT = 'updated_at';
export const ARENA = 'Arena';
export const REPLY = 'reply';
export const ERROR = 'error';
export const SUCCESS = 'success';
export const FROM = 'from';
export const TO = 'to';
export const INVALID_DATE = 'Invalid Date.';
export const INVALID_MESSAGE = 'To date must be after from date.';
export const COMPARE_DATE = 'Compare Date';
export const WARNING = 'warning';
export const THOUSAND_SEPARATOR = 'ThousandSeparator';
export const DECIMAL_SEPARATOR = 'DecimalSeparator';
export const OLD_SEARCH_DATE = '14/03/2022';
export const REDO = `<svg viewbox="0 0 18 18">
                        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
                        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
                    </svg>`;
export const UNDO = `<svg viewbox="0 0 18 18">
                        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                    </svg>`;
export const NOTE = 'note';
export const TASK = 'task';
export const INTERNAL_INTERVIEW = 'interview_internal';
export const CLIENT_INTERVIEW = 'interview_client';
export const CLIENT_FEEDBACK = 'client_feedback';
export const STANDARD_ERROR_MESSAGE = 'Something went wrong. Please try again.';

export const HL_SYNC_CANDIDATES = {
  VALIDATE_EMAIL_MESSAGE: 'Following candidates will not sync because of having invalid/empty personal email',
  VALIDATE_REASON_MESSAGE: "Following candidates couldn't be synced.",
  NO_FOUND_VALID_CANDIDATES: 'No found valid candidates for HL Sync.',
  STOP_SYNCING_CANDIDATES: 'Please wait... we are syncing all candidates with Hirelogic.',
  STOP_SYNCING_MESSAGE_LABLE: 'You can Stop Syncing',
  STOPPING_SYNCING_MESSAGE: 'Stopping process...'
};

export const DASHBOARD = {
  LEGACY_AR_BY_PARTNER: 'ARReport10162022/ARSummary',
  LEGACY_SALES_BY_PARTNER: 'SalesByPartnerReport10162022/SalesByPartner',
  LEGACY_CASH_REPORT: 'CashReport10162022/CollectedYTD',
  LEGACY_REGIONAL_SALES: 'RegionalSalesDashboard10162022/Dashboard',
  AR_BY_PARTNER: 'AR_REPORT',
  CASH_REPORT: 'CASH_REPORT',
  SALES_BY_PARTNER: 'SALES_REPORT',
  BD_REPORT: 'BD_REPORT',
  HOST_URL: 'https://prod-useast-a.online.tableau.com/',
  SCRIPT_URL: 'https://prod-useast-a.online.tableau.com/javascripts/api/viz_v1.js',
  SITE_ROOT: '/t/kingsleygatepartners',
  COOKIE_INFORMATION: 'Please enable 3rd party cookies in your browser to see the tableau views. For more information, ',
  INFORMATION_LINK: 'https://akohubteam.medium.com/how-to-enable-third-party-cookies-on-your-browsers-f9a8143b8cc5',
  INSIGHT_URL: 'insight-url'
};

export const EXTENDED_SEARCH_LOAD_CANDIDATES = 'extended-search/candidates';
export const PRODUCTONE_LOAD_CANDIDATES = 'candidates-galaxy';
export const PRODUCTONE_TERMINATE_EXTENDED = 'terminate-extended-search';
export const ACTIVITIES_TABDATA = 'activity-tabdata';
export const SFPA_BUILDER_VALIDATE = 'There is a problem identifying sfpa builder due to an incomplete SEARCH.';

export const NOTIFICATION = {
  EMPTY_LABEL: 'No notifications',
  READ: 'READ',
  UNREAD: 'UNREAD',
  EXTENDED_SEARCH_COMPLETED: 'extendedSearchCompleted',
  SHAKE_TIME: 1000,
  CONFIRM_REDIRECT: 'Do you want to go to the Product One candidate list?'
};

export const PRODUCT_ONE_MESSAGE = {
  PROCESS_INPROGRESS: 'Process is in-progress',
  STARTED: 'The Extended Search in Galaxy has started and is running in the background.  You will receive an email and a notification in Galaxy when it is complete.',
  VALIDATE_SFPA: 'Cannot process with incomplete SFPA data. Make sure target_industries, contact_targets, target_job_functions, search_industry_rankings are available',
  CONFIRM_TERMINATE: 'Do you want to stop the search?',
  VALIDATE_POPUP_HEADER: 'There is a problem identifying candidates due to an incomplete SFPA',
  UNABLE_TO_FETCH_MESSAGE: "has encountered an error. Please contact <a class='support-mail-link' href='mailto:support@kingsleygate.com'>support@kingsleygate.com</a>",
  QUICK_SEARCH_PROCESS_STARTED: 'search is running...',
  VALIDATE_DISAGREE_SELECTION: 'Reject reason is specified without selecting the disagree checkbox. Please remove the reason or select the checkbox to proceed',
  DISAGREE_TOOLTIP_LABEL: 'Add reason to enable the checkbox'
};

export const DELETE_CONFIRMATION_POPUP_MESSAGE = {
  DELETE_COMPANY: 'Are you sure you want to delete this company?',
  DELETE_CONTACT: 'Are you sure you want to delete this contact?',
  DELETE_FAILED_CONTACT: 'Are you sure you want to delete this record? This will remove all associated records',
  DELETE_CANDIDATE_FROM_SEARCH: 'Are you sure you want to delete this candidate from search?',
  DELETE_RECORD: 'Are you sure you want to delete this record?',
  DELETE_SEARCH: 'Are you sure you want to delete this search?',
  DELETE_BD_SEARCH: 'Are you sure you want to delete this BD?',
  DELETE_TRANSLATE_PRODUCT: 'Are you sure you want to delete this product?',
  DELETE_DOCUMENT: 'Are you sure you want to delete this Document Type?',
  DELETE_AI_TEMPLATE: 'Are you sure you want to delete this AI Template?'
};

export const CONFIRMATION_POPUP_MESSAGE = {
  CANCEL_CONFIRMATION: 'Changes that you made may not be saved. Do you want to continue?',
  DELETE_PRIMARY_EMAIL: 'Are you sure to delete this email because it is primary? If you delete this email, then by default, another email set is primary.'
};

export const PRODUCT_ONE = {
  QUICK: 'quick',
  QUICK_LABEL: 'Quick',
  EXTENDED: 'extended',
  EXTENDED_LABEL: 'Extended',
  ZOOM: 'Zoom',
  TATRAS: 'Galaxy P1',
  EXTENDED_RECORD_LIMIT: 25,
  ZOOM_QUICK_LIMIT: 50,
  TATRAS_QUICK_LIMIT: 80,
  REJECTED_STAGE: 'P1 Rejected'
};
export const ERROR_MESSAGE = {
  GET_SELECTED_COMPANY_ERROR: 'Error found in ignCompanyDataApi::',
  GET_SELECTED_CONTACT_ERROR: 'Error found in ignContactDataApi::'
};

export const LINKEDIN_SCRAPER = {
  GET_LINKEDIN_TASK: 'linkedin-scraper',
  CHECK_DUPLICATE_CONTACTS: 'check-duplicate-contacts',
  LINKEDIN_SCRAPER_COMPLETED: 'linkedinScraperCompleted',
  LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES: 'linkedinScraperCompletedToCandidates',
  LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE: 'linkedinScraperCompletedToProductOne',
  PROCESSING_LABEL: 'PROCESSING...',
  FAILED_LABEL: 'FAILED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  MY_LINKEDIN_CANDIDATES: 'MY_LINKEDIN_CANDIDATES',
  myLinkedinCandidatesRedux: 'myLinkedinCandidates'
};

export const LOE_GENERATION = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  LOE_DOCUMENT_UPLOADED: 'LOE_DOCUMENT_UPLOADED'
};

export const WEBSITE = {
  LABLE: 'Website QR Code',
  TOOLTIP_MESSAGE: 'Click here to get redirect to website'
};
export const LINKEDIN = {
  LABLE: 'LinkedIn QR Code',
  TOOLTIP_MESSAGE: 'Click here to get redirect to linkedin profile'
};
export const COMMENTS_VALIDATION = {
  SINGLE_COMMENT_SAME: 'Selected partner and recruiter comments are identical',
  ALL_COMMENTS_SAME: 'All partners and recruiters comments are identical'
};
export const TARGET_INDUSTRY_RANKING = {
  RANKING_VALIDATION_MESSAGE: 'Weight must between 0 to 100',
  DUPLICATE_TARGET_INDUSTRY: 'Target industry already exists'
};
export const SCORE_RANKING = {
  SCORE_VALIDATION_MESSAGE: 'Score must be between 0 to 100'
};
export const TARGET_COUNTRY_RANKING = {
  RANKING_VALIDATION_MESSAGE: 'Weight must between 0 to 100'
};
export const INLINE_EDIT_RECORD_VALIDATION_MESSAGE = 'Inline changes will be lost. Are you sure you want to continue?';
export const SYNCLINK_CREATED_SUCCESSFULLY = "Synclink record created. Use 'View Synclink' option to access";
export const SYNCLINK_FAILED = 'Synclink creation failed.';
export const UPDATE_EMPLOYMENT_HISTORY_CONFIRMATION_MESSAGE = 'This change will update employmesnt history, do you want to proceed?';
export const IMAGE_UNSUPPORTED_FILE_FORMAT = 'This is an unsupported file format for image';
export const WORKBENCHES_MESSAGE = {
  TOOLTIP_MESSAGE: 'This workbench is locked',
  VALIDATION_MESSAGE: 'Selected Workbench is Locked.'
};

export const ACTIVITY_LOG_MESSAGE = {
  GENERAL_INFO_REQUIRED_FIELDS: 'Please fill all required fields in General Info tab',
  JOB_HISTORY_REQUIRED_FIELDS: 'Company is required for job history',
  BOARD_DETAILS_HISTORY_REQUIRED_FIELDS: 'Company is required for board details'
};

export const AVAILABLE_COUNTRY_WITH_STATES = ['United States of America (USA)', 'India', 'Brazil', 'Mexico'];

export const BILLING_VALIDATIONS = {
  FEE_PERCENTAGE: 'Fee percentage is a required field',
  FEE_CAP_AMOUNT: 'Estimated Revenue exceeds the specified Fee Cap Amount',
  MARKET_VALUE: 'Geography Value is required when Estimated Revenue is greater than 0',
  NOTIFY_BILLING: 'Actual revenue should be greater than 0',
  FEE_TYPE: 'Fee Type required if value is selected',
  INDIRECT_FEE: 'Fee Type is required if calculation type is selected in Indirect Fee',
  LOE_DATE: 'Loe Date is required for acknowledging the estimated revenue',
  FEE_PERCENTAGE_MAX: 'Percentage must be less than or equal to 100',
  BY_PRODUCT_FEE_MAX: 'By product fee must be less than or equal to 100',
  CALCULATION_AMOUNT_MAX: 'Please enter valid calculated amount'
};

export const INVOICE_VALIDATION = {
  COLLECTED_PERCENTAGE: 'Invoice collected percentage must be 100% for closing the opportunity',
  UNSAVED_DATA: 'Unsaved data will be lost. Do you wish to continue?',
  CONFIRM_TO_DELETE: 'Are you sure you want to permanently delete this record?'
};

export const CLIPBOARD_COPY_MESSAGE = {
  SUCCESS: 'Copied to clipboard',
  FAILED: 'Failed to copy'
};

export const MESSATE_TEMPLATE_VALIDATION = {
  SUCCESS: 'Message template saved successfully',
  FAILED: 'Message template failed to save'
};

export const ATTACHMENT_VALIDATION = {
  EMPTY: 'Attachment data is empty',
  NO_FILE: 'Please select a file',
  TYPE_NAME: 'Please select attachment type and name',
  SUCCESS: 'Attachment uploaded successfully',
  FAILED: 'Attachment failed to upload'
};

export const RESOURCE_VALIDATION = {
  SUCCESS: 'Resource saved successfully',
  FAILED: 'Resource failed to save',
  DELETE: 'Resource(s) deleted successfully'
};

export const ROLE_VALIDATION = {
  SUCCESS: 'Role saved successfully',
  FAILED: 'Role failed to save',
  ADD_NAME: 'Please add role name',
  DELETE: 'Role(s) deleted successfully'
};

export const ADD_USER_VALIDATION = {
  REQUIRED: 'Please fill in all the required fields',
  SUCCESS: 'User added successfully',
  FAILED: 'User adding failed',
  DELETE: 'User(s) deleted successfully'
};

export const MESSAGE_STATUS = {
  EMAIL_SUCCESS: 'Email sent successfully',
  EMAIL_FAILED: 'Email failed to send'
};

export const SMS_STATUS = {
  SMS_SUCCESS: 'SMS sent successfully',
  SMS_FAILED: 'SMS failed to send'
};

export const BUSINESS_DEVELOPMENT = {
  REQUIRED_FIELDS: 'Please add all mandatory fields to generate LOE Document.',
  GENERATE_LOE: 'generate-loe'
};

export const API = {
  acquire_lock: '/api/lock/acquire',
  pdl_contact_data_merge: '/api/contacts/pdl-contact-update',
  contact_data_refresh: '/api/v1/contacts/add-contact-to-pdl-staging',
  pdl_contact_comparison: '/api/v1/contacts/pdl-contact-comparison/details',
  pdl_contact_update: '/api/v1/contacts/pdl-contact/update',
  create_contact_from_core_signal: '/api/v1/contacts/coresignal-contact/update',
  release_lock: '/api/lock/release',
  renew_lock: '/api/lock/renew',
  users: '/api/users',
  get_zendesk_token: '/api/users/get-zendesk-token',
  search: '/api/searches',
  candidate: '/api/candidates',
  workbench: '/api/work-benches',
  contact: '/api/contacts',
  companies: '/api/companies',
  user_profile: '/api/users/profile',
  user_export: '/api/users/export-as-excel',
  user_search: '/api/users/search',
  tanslate_search: '/api/tanslate/search',
  picklists: '/api/picklists',
  picklists_companies: '/api/picklists/companies',
  picklists_countries: '/api/picklists/countries',
  picklists_country_states: '/api/picklists/countries/states',
  type_list: '/api/product-settings',
  picklists_timezones: '/api/picklists/timezones',
  picklists_contacts: '/api/picklists/contacts',
  picklists_colors: '/api/picklists/colors',
  picklists_search: '/api/picklists/searches',
  master_query: '/api/master-search/query',
  client_feedback: '/api/client-feedback',
  hl: '/api/hl',
  activities: '/api/v1/activities',
  searches: '/api/search',
  master_search: '/api/master-search',
  global_search: '/api/v1/general/search',
  global_search_filters: '/api/v1/general/filters',
  contact_education: '/api/v1/contact-education',
  business_developments: '/api/business-developments',
  reports: '/api/reports',
  contacts: '/api/contact',
  clientsuite: '/api/clientsuite',
  synclink: '/api/synclink',
  athena: '/api/athena',
  offlimits: '/api/offlimits/contacts',
  user_grid: '/api/user-grid-settings',
  candidatesuite: '/api/candidate-suite',
  dashboard: '/api/dashboard',
  product_one: '/api/product-one',
  product_setting: '/api/product-settings',
  invoice_summary: '/api/invoice-summary/getAllInvoiceSummary',
  invoice_summary_export: '/api/invoice-summary',
  sfpa_setting: '/api/template',
  messageTemplates: '/api/templates',
  emailTemplates: '/api/templates/email',
  tagSetup: '/api/tag-setup/tag_value',
  tagCount: '/api/tag-setup',
  tenant: '/api/users',
  contextFields: '/api/context',
  skillDetails: '/api/v1/skill_details',
  ignUser: '/api/ign-user',
  roleSecurity: '/api/role',
  resources: '/api/resource',
  roleResources: '/api/role-resource',
  userRoles: '/api/user-role',
  userRolesResources: '/api/user-role-resource',
  appSecurity: '/api/ign-app-security',
  sfpa_decision: '/api/v1/sfpa_decision',

  //-------------------ING PickLIst----------------//
  translate_value_picklists: '/api/v1/picklists',
  companies_picklists: '/api/v1/picklists/companies-picklist',
  industry_picklist: '/api/v1/picklists/industries-picklist',
  message_picklist: '/api/v1/massage',
  contact_attachment: '/api/v1/contact/attachments',

  //-------------------CHAT API----------------//
  promt_card: '/api/v1/chatbot/prompts-list',
  chat_history: '/api/v1/chatbot/search-history',
  send_message: '/api/v1/chatbot/send-message',
  transcribe_audio: '/api/v1/chatbot/transcribe',
  chat_history_by_id: '/api/v1/chatbot/chat-history',
  system_params: '/api/v1/chatbot/system-params'
};
export const IGN_API = {
  helia_report: 'api/v1/reports/generate',
  auth: '/api/v1/auth',
  contact: '/api/v1/contacts',
  company: '/api/v1/companies',
  translate_value_picklists: '/api/v1/picklists/translate-values',
  picklist_value: '/api/v1/picklists',
  language_translations: '/api/v1/locales',
  document_download: '/api/v1',
  contact_attachment: '/api/v1/contact/attachments',
  add_document: '/api/v1/contact',
  company_attachment: '/api/company/attachments',
  add_company_documents: '/api/company',
  industry: '/api/v1/industry',
  project: '/api/v1/project',
  project_stage_group_label: '/api/v1/project/get-custom-stage-group-label',
  update_stage_group_label: '/api/v1/project/update-stage-group-label',
  invoice_summary: '/api/v1/invoice-summary',
  position_profile: '/api/v1/position_profile',
  project_client_contact: '/api/v1/project_client_contact',
  picklist_project_client_contact: '/api/v1/picklists/project-client-contact',
  target_title: '/api/v1/target_title',
  sfpa_save: '/api/v1/project/sfpa/save',
  brand_configs: '/api/v1/brand_configs',
  location_setup: '/api/v1/setup/get-location?input=',
  assume_role: '/api/v1/setup/get-assume-role-creds',
  tag_by_module: '/api/tag-setup/tags_by_module',
  target_industry: '/api/v1/project_target_industries/',
  target_company: '/api/v1/project_target_companies/',
  target_job_function: '/api/v1/project_job_function/',
  target_location: '/api/v1/project_target_location',
  target_capital_structure: '/api/v1/project_capital_structure/',
  bulk_save_targets: '/api/v1/project/bulk_save_targets',
  project_education_certification: '/api/v1/project_education_certificate/',
  target_companies_to_avoid: '/api/v1/target_companies_to_avoid/',
  contact_from_resume: '/api/v1/contact/resumes/parse',
  check_contact_duplication: '/api/v1/contact/duplicate',
  contact_create_from_resume: '/api/v1/contact/create-contact',
  candidate_stage_setup: '/api/v1/candidate_stage_setup',
  stage_workflow_setup: '/api/v1/stage-workflow',
  candidate_stage_group: '/api/v1/candidate-stage-group',
  project_skill_details: '/api/v1/project_skill_details',
  project_skill_update_seq_no: '/api/v1/project_skill/update_seq_no',
  project_tags: '/api/v1/project-tag',
  competency: '/api/v1/competencies',
  project_competency: '/api/v1/project_competency',
  decision_graph: '/api/v1/sfpa_decision',
  project_candidate: '/api/v1/project_candidate',
  search_project_candidate: '/api/v1/project_candidate/all-by-project-id',
  custom_report_templates: '/api/v1/custom-report-templates',
  template_groups: '/api/v1/template-groups',
  activities_all: '/api/v1/activities/all',
  create_log_an_activity: '/api/v1/contacts/create-activity',
  update_log_an_activity: '/api/v1/contacts/update-activity',
  gpt: '/api/v1/gpt',
  business_developments: '/api/v1/business-developments',
  business_developments_loe: '/api/v1/business-developments/loe',

  revenue_threshold: '/api/v1/revenue-threshold',
  project_document: '/api/v1/project/documents',
  echoSense: '/api/v1/echo-sense',
  project_stage: '/api/v1/project-stage/all',
  clientSuite: '/api/v1/client-suite/candidate',
  projectBdCount: '/api/v1/project/get-project-stats',
  clientPortalMenuSetup: '/api/v1/client-portal-menu-setup',
  candidateStageGroup: '/api/v1/candidate-stage-group',
  notifications: '/api/v1/notifications',
  activityTypes: '/api/v1/activity-types',
  product_one_extended_search: 'api/v1/product-one/extended-search',
  product_one_quick_search: 'api/v1/product-one/quick-search',
  product_one: 'api/v1/product-one',
  picklists_contacts: '/api/v1/picklists/contacts',
  picklists: '/api/v1/picklists',
  client_portal_menu_setup: '/api/v1/client-portal-menu-setup',
  project_stage_group_color: '/api/v1/project-stage-group-color',
  candidate_colors: '/api/v1/color-setup/candidates-colors',
  workbench_colors: '/api/v1/color-setup/workbench-colors',
  candidate_color_label: '/api/v1/candidate-color-label',
  athena: '/athena/v1',
  users: '/api/v1/users',
  clientDispositionMap: '/api/v1/client-disposition-map',
  getCandidateTags: '/api/v1/project_candidate/tags',
  bulkCandidateCreate: '/api/v1/project_candidate/bulk',
  activities_fit_data: '/api/v1/activities/fit_data',
  workbench: '/api/v1/workbench',
  loe: '/api/v1/loe',
  directOffLimit: '/api/v1/direct-off-limit',
  companyOffLimit: '/api/v1/company-off-limit',
  offLimitSummary: '/api/v1/off-limit-summary/user',
  offLimitConfig: '/api/v1/off-limit-config',
  candidateStage: '/api/v1/candidate_stage_setup/',
  labelConfig: '/api/v1/label-config/get-upload-url',
  query: '/api/v1/query',
  degreeEducation: '/api/v1/picklists/degree-picklist',
  productOneTaskCandidate: 'api/v1/product-one/task-candidates',
  productOneTask: 'api/v1/product-one/tasks',
  companyRelationship: '/api/v1/company-relationship',
  reports: '/api/v1/reports',
  userConfigs: '/api/v1/user-config',
  updateUserConfigs: '/api/v1/user-config/update',
  updateGridSetting: '/api/v1/grid-settings',
  candidateScore: '/api/v1/candidate-score',
  profileLabel: '/api/v1/profile-label',
  labelRubric: '/api/v1/label-rubric',
  loeAll: '/api/v1/loe/all',
  loeTasks: 'api/v1/loe/embed',
  loeTasksList: '/api/v1/loe/tasks',
  loeUpdate: '/api/v1/loe',
  addFromLinkedin: 'api/v1/contacts/coresignal-contact/details',
  createIgnLanguage: '/api/v1/contacts/create-ign-language',
  createIgnCountry: '/api/v1/contacts/create-ign-country',
  createIgnState: '/api/v1/contacts/create-ign-state',
  genericQuickSearch: '/api/v1/general/generic-quick-search'
};
export const LOE_SUB_ROUTES = {
  base: '/',
  all: '/all',
  embed: '/embed',
  tasks: '/tasks',
  download: '/download'
};
export const ROUTES = {
  unauthorized: '/unauthorized',
  login: '/login',
  home: '/',
  homeWithContactId: '/home/:contactId',
  masterSearch: '/master-search',
  globalSearch: '/global-search',
  searches: '/searches',
  createSearch: '/searches/create/:currentTabs',
  createSearchWithoutCurrentTab: '/searches/create',
  pSearches: '/searches/pipeline-searches',
  mySearchesKey: '/searches/my-searches/:type/:filter',
  mySearches: '/searches/my-searches',
  addProject: '/projects/add-project',
  projectDetails: '/projects/view-project',
  vProject: '/projects/view-project/:id',
  viewProjectWithoutChild: '/projects/view-project/:id/:currentTabs',
  viewProject: '/projects/view-project/:id',
  viewProjectWithDeepLink: '/projects/view-project/:id/:parentTab/:subTab',
  cloneProject: '/projects/clone-project',
  validateToken: '/validate-token',
  kgpSearches: '/searches/kgp-searches',
  myPlacements: '/searches/my-placements',
  kgpPlacements: '/searches/kgp-placements',
  billedInvoice: '/invoice-summary/billed-invoice',
  yetToBeBilled: '/invoice-summary/yet-to-be-billed',
  nextTenDays: '/invoice-summary/next-ten-days',
  overdue: '/invoice-summary/overdue',
  addBd: '/searches/business-development/add',
  addBdByCompany: '/companies/:companyName/:id/business-development/add',
  createBd: '/companies/business-development/add',
  addTranslateValue: '/product-setup/translate-value/add',
  addDocumentType: '/product-setup/document-type/add',
  addSfpaBuilder: '/product-setup/sfpa-builder/add',
  readOnlyActivityLog: '/searches/:searchId/:path/:id/log-an-activity/read-only/:currentTabs',
  readOnlyActivityLogWithoutCurrentTab: '/searches/:searchId/:path/:id/log-an-activity/read-only',
  activityLog: '/searches/:searchId/:path/:id/log-an-activity/:currentTabs',
  activityLogWithoutCurrentTab: '/searches/:searchId/:path/:id/log-an-activity',
  viewSearch: '/searches/:id/:currentTabs',
  viewSearchWithActivetab: '/searches/:id/:currentTabs/:currentSfpaTab',

  viewSearchWithoutCurrentTab: '/searches/:id',
  clientSuiteReport: '/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/client-suite-report',
  allCandidates: '/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/all-candidates',
  myLinkedinCandidates: '/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/my-linkedin-candidates',
  companies: '/companies',
  reports: '/reports',
  viewCompany: '/companies/:currentTabs/:id',
  viewCompanyWithoutCurrentTab: '/companies/all-companies/:id',
  addCompany: '/companies/add-company',
  companiesList: '/companies/all-companies',
  addContact: '/contacts/add/:currentTabs/:childTab',
  addContactWithoutChild: '/contacts/add/:currentTabs',
  addContactWithoutCurrentTab: '/contacts/add',
  conClients: '/contacts/my-clients',
  conMyTarget: '/contacts/my-targets',
  // conMergeContacts: "/contacts/my-mergecontacts",
  linkedinContacts: '/contacts/my-linkedin-contacts',
  readOnlyContactActivityLog: '/contacts/:contactId/log-an-activity/read-only/:currentTabs',
  readOnlyContactActivityLogWithoutCurrentTab: '/contacts/:contactId/log-an-activity/read-only',
  contactActivityLog: '/contacts/:contactId/log-an-activity/:currentTabs',
  contactActivityLogWithoutCurrentTab: '/contacts/:contactId/log-an-activity',
  viewContactWithoutCurrentTab: '/contacts/:id',
  viewContact: '/contacts/:id/:currentTab',
  contacts: '/contacts',
  contactListWithID: '/contacts/list/:currentTabs/:id',
  contactList: '/contacts/list/:currentTabs',
  contactListWithoutCurrentTab: '/contacts/list/default',
  contactListWithoutTab: '/contacts*',
  userAdd: '/users/add',
  users: '/users',
  usersWithId: '/users/:id',
  contactView: '/contact/list/all-contacts/:id/details/profile',
  allContactGrid: '/contacts/list/all-contacts',
  allContactGridWithDrawerOpen: '/contacts/list/all-contacts/:id',
  workbenchAdd: '/workbenches/add',
  myWorkbenchesContacts: '/workbenches/my-workbenches/contacts',
  myWorkbenchesSearches: '/workbenches/my-workbenches/searches',
  myWorkbenches: '/workbenches/my-workbenches',
  kgpWorkbenchesContacts: '/workbenches/kgp-workbenches/contacts',
  kgpWorkbenchesSearches: '/workbenches/kgp-workbenches/searches',
  kgpWorkbenches: '/workbenches/kgp-workbenches',
  archivedWorkbenchesContacts: '/workbenches/archived-workbenches/contacts',
  archivedWorkbenchesSearches: '/workbenches/archived-workbenches/searches',
  archivedWorkbenches: '/workbenches/archived-workbenches',
  viewWorkbench: '/workbenches/:id/:currentTab',
  viewWorkbenchWithoutCurrentTab: '/workbenches/:id',
  workbenches: '/workbenches',
  languageExpertise: 'language-expertise',
  certificationType: 'certification-type',
  remaining: '*',
  dashboard: '/dashboard',
  legacyArByPartnerDashboard: '/insights/legacy-ar-by-partner',
  legacySalesByPartnerDashboard: '/insights/legacy-sales-by-partner',
  legacyCashReportDashboard: '/insights/legacy-cash-report',
  legacyRegionalSalesDashboard: '/insights/legacy-regional-sales',
  arByPartnerDashboard: '/insights/ar-by-partner',
  salesByPartnerDashboard: '/insights/sales-by-partner',
  cashReportDashboard: '/insights/cash-report',
  bDReportDashboard: '/insights/bd-report',
  defaultRoute: '/insights/*',
  translateValueProductSetup: '/product-setup/translate-value',
  documentTypeProductSetup: '/product-setup/document-type',
  sfpaBuilder: '/product-setup/sfpa-builder',
  productSetup: '/productSetUp',
  messageTemplates: '/message-templates',
  activityTypes: '/activity-types-setup',
  translateValueSetup: '/translate-value-setup',
  activityTypesWithId: '/activity-types-setup/:id',
  tagSetup: '/tag-setup',
  stageSetup: '/stage-setup',
  stageWorkflow: '/stage-setup/workflow',
  searchApprovalPending: '/search-approval/pending',
  searchApprovalRejected: '/search-approval/rejected',
  invoiceSummary: '/invoice-summary',
  invoiceSummaryBilledInvoice: '/invoice-summary/billed-invoice',
  invoiceSummaryYetToBilled: '/invoice-summary/yet-to-be-billed',
  invoiceSummaryOfNextTenDays: '/invoice-summary/next-ten-days',
  invoiceSummaryOverDue: '/invoice-summary/overdue',
  invoiceSummaryReadyToBill: '/invoice-summary/ready-to-bill',
  clientSuite: '/client-portal',
  security: '/security',
  userSecurity: '/productSetUp/users',
  roleSecurity: '/productSetUp/roles',
  resourceSecurity: '/productSetUp/resources',
  allCompanies: '/companies/all-companies',
  workbench: '/workbench/:id?',
  workbenchQuery: '/query/:queryId?',
  workbenchArchiveQuery: '/archive/:listType/:archiveId?', // list type is query or myList for proper navigation
  candidateProjectSetup: '/candidate-project-status-setup',
  labelConfig: 'i18n-label-config',
  reportTemplateSetup: 'report-template-setup',
  ignyteIntelligence: '/ignyte-Intelligence', //
  profile: '/profile'
};

export const PUBLIC_ROUTES = ['portal', 'unauthorized'];
export const PUBLIC_BASE_ROUTES = {
  jobBoard: '/portal',
  unauthorized: '/unauthorized'
  // athena: "/athena",
};

export const PUBLIC_SUB_ROUTES = {
  jobBoardWithId: '/portal/:id',
  athenaWithAssessmentId: '/athena/:assessment',
  athenaWithName: '/athena/:assessment/:name',
  privacyPolicy: '/privacy-policy'
};

export const IGN_PUBLIC_API = {
  jobBoard: '/api/v1',
  getIp: '/api/get-ip',
  project_candidate: '/api/v1/project_candidate'
};
export const WORKBENCH_TYPES = {
  archived: 'archived',
  archivedContacts: 'archived-contacts',
  archivedSearches: 'archived-searches',
  kgp: 'kgp',
  kgpSearches: 'kgp-searches',
  kgpContacts: 'kgp-contacts',
  user: 'user',
  userContacts: 'user-contacts',
  userSearches: 'user-searches'
};

export const ROUTE_KEY = {
  unauthorized: 'unauthorized',
  login: 'login',
  masterSearch: 'masterSearch',
  globalSearch: 'globalSearch',
  createSearch: 'createSearch',
  pSearches: 'pSearches',
  mySearches: 'mySearches',
  addProject: 'addProject',
  vProject: 'vProject',
  viewProject: 'viewProject',
  viewProjectWithoutChild: 'viewProjectWithoutChild',
  viewProjectWithContactId: 'viewProjectWithContactId',
  viewProjectWithContactIdWithoutChild: 'viewProjectWithContactIdWithoutChild',
  cloneProject: 'cloneProject',
  myPlacements: 'myPlacements',
  kgpPlacements: 'kgpPlacements',
  billedInvoice: 'billedInvoice',
  yetToBeBilled: 'yetToBeBilled',
  nextTenDays: 'nextTenDays',
  overdue: 'overdue',
  addBd: 'addBd',
  createBd: 'createBd',
  addTranslateValue: 'addTranslateValue',
  addDocumentType: 'addDocumentType',
  addSfpaBuilder: 'addSfpaBuilder',
  readOnlyActivityLog: 'readOnlyActivityLog',
  activityLog: 'activityLog',
  viewSearch: 'viewSearch',
  clientSuiteReport: 'clientSuiteReport',
  allCandidates: 'allCandidates',
  myLinkedinCandidates: 'myLinkedinCandidates',
  addCompany: 'addCompany',
  comMyClients: 'comMyClients',
  comMyTargets: 'comMyTargets',
  viewCompany: 'viewCompany',
  companies: 'companies',
  companiesList: 'companiesList',
  addContact: 'addContact',
  addContactWithoutChild: 'addContactWithoutChild',
  conClients: 'conClients',
  conMyTarget: 'conMyTarget',
  // conMergeContacts: "conMergeContacts",
  linkedinContacts: 'linkedinContacts',
  readOnlyContactActivityLog: 'readOnlyContactActivityLog',
  contactActivityLog: 'contactActivityLog',
  viewContact: 'viewContact',
  contacts: 'contacts',
  contactList: 'contactList',
  contactListWithID: 'contactListWithID',
  contactListWithoutCurrentTab: 'contactListWithoutCurrentTab',
  userAdd: 'userAdd',
  users: 'users',
  contactView: 'contactView',
  workbenchAdd: 'workbenchAdd',
  myWorkbenchesContacts: 'myWorkbenchesContacts',
  myWorkbenchesSearches: 'myWorkbenchesSearches',
  myWorkbenches: 'myWorkbenches',
  kgpWorkbenchesContacts: 'kgpWorkbenchesContacts',
  kgpWorkbenchesSearches: 'kgpWorkbenchesSearches',
  kgpWorkbenches: 'kgpWorkbenches',
  archivedWorkbenchesContacts: 'archivedWorkbenchesContacts',
  archivedWorkbenchesSearches: 'archivedWorkbenchesSearches',
  archivedWorkbenches: 'archivedWorkbenches',
  viewWorkbench: 'viewWorkbench',
  languageExpertise: 'languageExpertise',
  certificationType: 'certificationType',
  languages: 'languages',
  dashboard: 'dashboard',
  legacyArByPartnerDashboard: 'legacyArByPartnerDashboard',
  legacySalesByPartnerDashboard: 'legacySalesByPartnerDashboard',
  legacyCashReportDashboard: 'legacyCashReportDashboard',
  legacyRegionalSalesDashboard: 'legacyRegionalSalesDashboard',
  arByPartnerDashboard: 'arByPartnerDashboard',
  salesByPartnerDashboard: 'salesByPartnerDashboard',
  cashReportDashboard: 'cashReportDashboard',
  bdReportDashboard: 'bdReportDashboard',
  defaultRoute: 'defaultRoute',
  translateValueProductSetup: 'translateValueProductSetup',
  documentTypeProductSetup: 'documentTypeProductSetup',
  productSetup: 'productSetup',
  reports: 'reports',
  messageTemplates: 'messageTemplates',
  activityTypes: 'activityTypes',
  activityTypesWithId: 'activityTypesWithId',
  translateValueSetup: 'translateValueSetup',
  tagSetup: 'tagSetup',
  stageSetup: 'stageSetup',
  stageWorkflow: 'stageWorkflow',
  searchApprovalPending: 'searchApprovalPending',
  searchApprovalRejected: 'searchApprovalRejected',
  invoiceSummary: 'invoiceSummary',
  invoiceSummaryBilledInvoice: '/invoice-summary/billed-invoice',
  invoiceSummaryYetToBilled: '/invoice-summary/yet-to-be-billed',
  invoiceSummaryOfNextTenDays: '/invoice-summary/next-ten-days',
  invoiceSummaryOverDue: '/invoice-summary/overdue',
  invoiceSummaryReadyToBill: '/invoice-summary/ready-to-bill',
  SearchSfpaReport: 'SearchSfpaReport',
  clientSuite: 'clientSuite',
  security: 'security',
  userSecurity: 'userSecurity',
  roleSecurity: 'roleSecurity',
  resourceSecurity: 'resourceSecurity',
  labelConfig: 'i18n-label-config',
  reportTemplateSetup: 'reportTemplateSetup',
  ignyteIntelligence: 'ignyte-Intelligence' //
};

export const ROUTE_TYPE = {
  userContacts: 'user-contacts',
  userSearches: 'user-searches',
  user: 'user',
  kgpContacts: 'kgp-contacts',
  kgpSearches: 'kgp-searches',
  kgp: 'kgp',
  archivedContacts: 'archived-contacts',
  archivedSearches: 'archived-searches',
  archived: 'archived'
};

export const LABELS_AND_PLACEHOLDERS = {
  expertiseLevel: 'Level of expertise',
  language: 'Language',
  languages: 'Languages'
};

export const REGIONAL_ROLE_WITH_MARKET = {
  REGHEAD_USA: ['USA'],
  REGHEAD_APAC: ['Singapore', 'India'],
  REGHEAD_EUROPE: ['UK'],
  REGHEAD_LATAM: ['LatAm-Other']
};

export const FINANCE_ROLE_EDIT_FIELDS_RESTRICTIONS = {
  search: [], //['stage', 'type', 'result', 'on_hold_date', 'off_hold_date', 'start_date', 'loe_date', 'is_sfpa_completed', 'contact_name', 'billing_contact', 'mobile_phone', 'work_email', 'billing_address', 'zip_code', 'invoice_delivery_type', 'is_po_direct', 'po', 'billing_notes', 'currency', 'estimated_total_comp', 'fee_type', 'fee', 'fee_percentage', 'fee_amount', 'is_fee_cap', 'admin_fee', 'frequency', 'duration', 'by_product_fee', 'retainer_1', 'retainer_2', 'retainer_3', 'billed_date', 'collected_date', 'amount']
  user: []
};

export const USER_ROLE_EDIT_FIELDS_RESTRICTIONS = {
  search: [
    'stage',
    'type',
    'result',
    'on_hold_date',
    'off_hold_date',
    'start_date',
    'loe_date',
    'is_sfpa_completed',
    'contact_name',
    'billing_contact',
    'mobile_phone',
    'work_email',
    'billing_address',
    'zip_code',
    'invoice_delivery_type',
    'is_po_direct',
    'po',
    'billing_notes',
    'currency',
    'estimated_total_comp',
    'fee_type',
    'fee',
    'fee_percentage',
    'fee_amount',
    'is_fee_cap',
    'admin_fee',
    'frequency',
    'duration',
    'by_product_fee',
    'retainer_1',
    'retainer_2',
    'retainer_3',
    'billed_date',
    'collected_date',
    'amount'
  ],
  user: []
};

export const ADMIN_ROLE_EDIT_FIELDS_RESTRICTIONS = {
  search: [],
  user: []
};

export const HIRELOGIC_STAGES = ['Placement', 'Offer Presented', 'Client Interview', 'KGP Interview', 'Candidate', 'Screened', 'Initial Contact & Scheduled Calls', 'Target'];

export const PENDING_ACKNOWLEDGMENT = 'Pending Acknowledgement';
export const OPEN = 'Open';
export const PENDING_REJECT_BILLING_STATUS = ['PND_RHEAD', 'PND_ACK_EST', 'PND_ACK'];
export const PENDING_REGIONAL_BILLING_STATUS = ['PND_RHEAD'];
export const NOTIFY_BILLING_ACKNOWLEDGEMENT = ['ACK_DONE_EST'];
export const PENDING_BILLING_ACKNOWLEDGEMENT = ['PND_EST', 'PND_ACK'];
export const ALL_BILLING_STATUS = ['PND_RHEAD', 'REJ_RHEAD', 'PND_ACK_EST', 'ACK_DONE_EST', 'PND_ACK', 'ACK_DONE'];
export const SUBMIT_APPROVAL_STATUS = ['APR_RHEAD', 'NONE'];
export const DEFAULT_ALLOWED_SANITIZE_TAGS = ['p', 'h1', 'h2', 'h3', 'em', 'strong', 'u', 'a', 'ol', 'ul', 'li', 'br'];
export const DEFAULT_ALLOWED_SANITIZE_ATTRIBUTES = { a: ['href'] };
export const DISCARD = 'discard';
/* as per requirement change dashboard department 'IT' to 'Office of the Chairman' */
export const VISIBLE_DEPARTMENTS_LIST = ['SLT', 'Finance', 'Executive Team', 'Office of the Chairman'];
export const REGIONAL_ROLE = 'RegionHead';
export const BILLING_DESK_ROLE = 'BILLDESK';
export const BILLING_DESK = 'billing-desk';
export const ADMIN_ROLE = 'admin';
export const AR_BY_PARTNER_DEPARTMENTS = ['Partner', 'Admin'];
export const ALL_REGIONAL_HEAD = ['PND_RHEAD', 'REJ_RHEAD', 'APR_RHEAD', 'ACK_DONE', 'NONE'];
export const ALL_BILLING_DESK = ['PND_ACK_EST', 'ACK_DONE_EST', 'PND_ACK', 'ACK_DONE', 'NONE'];
export const INVOICE_INFO_ROLE = ['PM', 'Partner'];
export const STATUS_UPTO_EST_APR = ['PND_RHEAD', 'REJ_RHEAD', 'APR_RHEAD', 'PND_EST'];
export const SFPA_DATA = {
  sfpa_invalid_data: 'No candidates found for the given SFPA details. Please provide more information in SFPA to find matching candidates'
};
export const STATUS_CHECK = ['REJ_RHEAD', 'PND_ACK_EST'];
export const BD_STAGES = ['On Hold', 'BD'];
export const OPEN_STAGES = ['Open', 'On Hold', 'Closed', 'None'];
export const BD_PENDING_STATUS_MESSAGE = 'Pending Threshold Approval';

export const SCORES_DESCRIPTION = [
  {
    name: 'Title',
    description: 'Title Score indicates the Candidate title similarity with all titles provided in the SFPA'
  },
  {
    name: 'Job Function',
    description: 'Job Function Score indicates the degree of candidate’s job function similarity with respect to SFPA Job functions'
  },
  {
    name: 'Industry',
    description: 'Industry Score indicates the degree of candidate’s industry similarity with respect to SFPA industries'
  },
  {
    name: 'Company',
    description:
      'Company Score indicates the degree of candidate’s company profile match with the target companies provided in SFPA. This takes into account company information like company industry, revenue range, employee range.'
  },
  {
    name: 'Career Experience',
    description: 'Career Experience indicating the nearness of candidate’s years of experience with respect to experience provided in SFPA.'
  },
  {
    name: 'Job Movement',
    description: 'Job Movement indicates the frequency of change at the workplace. Lower change frequency results in higher stint score indicating stability.'
  },
  {
    name: 'Current Title Tenure',
    description: 'Current Title Tenure indicates the recent switch of the candidate weighted by years in the current title.'
  },
  {
    name: 'Relevancy',
    description: 'Relevancy Score indicates the degree of title match with the titles provided in SFPA weighted by years of experience in the recent matching title.'
  },
  {
    name: 'Lifer',
    description: 'Max Candidate title similarity with respect to of all SFPA Titles. max(similarity(candidate_title, list(sfpa_titles)))'
  }
];

export const FEE_TYPES = {
  ONE_THIRD: 'Standard - 1/3',
  OTHER: 'Other Percentage',
  FIXED_FEE: 'Fixed Fee'
};

export const IGNYTE_GLOBALS = {
  BRAND: 'Ignyte'
};

export const IGNYTE_CONSTANTS = {
  Yes: 'Yes',
  No: 'No',
  Cancel: 'Cancel',
  Continue: 'Continue'
};

export const END_POINTS_URL = {
  BRAND_URL: 'image_url',
  PROFILE_URL: 'profileimage',
  CONTACT_URL: '/contacts/list/all-contacts/'
};

export const CONTACTS_LOGS_MESSAGE = {
  ADD_CONTACT_ERROR: 'Error found in addContact::'
};

export const PROJECTS_LOGS_MESSAGE = {
  POSITION_PROFILE_UPDATE_ERROR: 'Error found in update Position Profile::'
};

export const TAG_SETUP_LOGS_MESSAGE = {
  GET_ROWS_FOUND_ERROR: 'Error found in getRows::'
};

export const TRANSLATE_VALUE_SETUP_LOGS_MESSAGE = {
  GET_ROWS_FOUND_ERROR: 'Error found in getRows::'
};

export const DROP_DOWN_PICKLIST = {
  INDUSTRIES_PICKLIST: 'industries-picklist',
  COMPANIES_PICKLIST: 'companies-picklist',
  JOB_FUNCTION: 'job-function-picklist',
  SUB_INDUSTRY: 'sub-industries',
  CAPITAL_STRUCTURE: 'COMPANY_CAPITAL_STRUCTURE',
  CURRENCIES: 'currencies',
  REJECT_REASON: 'CLIENT_REJECT_REASONS',
  DUMMY_TEXT: 'ROADMAP_HEADER'
};

export const PICKLISTS = {
  PROJECT_STAGE: 'PROJECT_STAGE',
  JOB_TYPE: 'JOB_TYPE',
  COMPENSATION_FREQUENCY: 'COMPENSATION_FREQUENCY',
  CURRENCY_TYPE: 'CURRENCY_TYPE',
  LOCATIONS: 'locations',
  CLIENT_CONTACT_TYPE: 'CLIENT_CONTACT_TYPE',
  CLIENT_RELATIONSHIP: 'CLIENT_RELATIONSHIP',
  POSITION_PROFILE_INFO_TEXT: 'POSITION_PROFILE_INFO_TEXT',
  PROJECT_FRAMEWORK_TYPE: 'PROJECT_FRAMEWORK_TYPE',
  PROFILE_CATEGORY: 'PROFILE_CATEGORY',
  RUBRIC_RATING_BINARY: 'RUBRIC_RATING_BINARY',
  RUBRIC_RATING_GRADE: 'RUBRIC_RATING_GRADE'
};

export const SFPA_SUB_ROUTE = 'sfpa-by-ai';
export const POSITION_PROFILE_ROUTE = 'position-profile';

// 'Active' is a short_desc used in translation from database
export const DEFAULT_CONTACT_STATUS = 'Active';
export const CONTACT_STATUS = {
  ACTIVE: 'Active',
  OFF_LIMITS: 'Off-Limits',
  IN_ACTIVE: 'In-active'
};

export const DEFAULT_COMPANY_STATUS = 'Active';
export const COMPANY_STATUS = {
  ACTIVE: 'Active',
  OFF_LIMITS: 'Off-Limits',
  IN_ACTIVE: 'In-active'
};

export const AWS_LANGUAGE_OPTIONS = [
  {
    value: 'en-US',
    label: 'English'
  },
  {
    value: 'es-US',
    label: 'Spanish'
  },
  {
    value: 'pt',
    label: 'Portuguese'
  }
];
export const TAB_QUERY = {
  engagement: '?tabName=ENGAGEMENT_TAB',
  bd: '?tabName=BUSINESS_DEVELOPMENT_TAB'
};
export const SUB_ROUTES = {
  bulkDelete: '/bulk-delete',
  bulkAssignTag: '/assign-tags',
  createAssociatedContact: 'create-associated-contact'
};

export const ADDRESS_TYPE = {
  WORK: {
    field_value: 'work',
    short_desc: 'Work'
  },
  RESIDENTIAL: {
    field_value: 'residential',
    short_desc: 'Residential'
  }
};

export const EMAIL_TYPE = {
  WORK: {
    field_value: 'work',
    short_desc: 'Work'
  },
  PERSONAL: {
    field_value: 'personal',
    short_desc: 'Personal'
  }
};

export const EMAIL_TYPE_ARRAY = [
  {
    field_value: 'work',
    short_desc: 'Work'
  },
  {
    field_value: 'personal',
    short_desc: 'Personal'
  }
];

export const PHONE_TYPE = {
  WORK: {
    field_value: 'work',
    short_desc: 'Work'
  },
  PERSONAL: {
    field_value: 'personal',
    short_desc: 'Personal'
  },
  DIRECT_LINE: {
    field_value: 'direct_line',
    short_desc: 'Direct Line'
  }
};

export const PHONE_TYPE_ARRAY = [
  {
    field_value: 'work',
    short_desc: 'Work'
  },
  {
    field_value: 'personal',
    short_desc: 'Personal'
  }
];

export const COMPANY_DOCUMENT_DOWNLOAD_API = 'api/company/attachments/:attachmentId/download';

export const DUPLICATION_FIELDS = {
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone Number',
  LINKEDIN: 'LinkedIn'
};

export const PROJECT_SCREEN = {
  MY_PROJECT: 'my-projects',
  ALL_PROJECT: 'all-projects',
  PROJECT: 'PROJECT',
  BUSINESS_DEVELOPMENT: 'BUSINESS_DEVELOPMENT',
  MY_BD: 'my-bd',
  ALL_BD: 'all-bd'
};

export const CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS = {
  JOB_INFO: 'jobInformation',
  ADDRESS: 'address'
};
export const DEFAULT_STATUS = 'active';
export const VALIDATION_MESSAGE = 'Invalid LinkedIn URL structure, Valid structure: https://linkedin.com/in/username/';

export const CLIENT_SUITE_FEEDBACL_SUCCESS = 'Candidate Feedback is sent successfully';

export const REVENUE_MAX_LIMIT = 10000000;
export const EMPLOYEE_MAX_LIMIT = 10000000000;

export const FIELDS = {
  revenue_from_massage: 'Revenue range from exceeds the high limits',
  revenue_to_massage: 'Revenue range to exceeds the high limits',
  employee_from_massage: 'Employee range from exceeds the high limits',
  employee_to_massage: 'Employee range to exceeds the high limits'
};
