import { useTranslation } from 'react-i18next';
import { getFilterQuery } from '../../Commons/Utils';

export const PAGE_LIMIT = 50;
export const SUCCESS_STATUS_CODE = 200;

const defaultColDef = {
  lockPinned: true,
  tooltipField: ''
};

export const columnDefs = () => {
  const { t } = useTranslation();

  return [
    {
      ...defaultColDef,
      headerName: t('utils.fieldName'),
      field: 'field_name',
      colId: 'field_name',
      sortable: true,
      width: 250,
      tooltipField: 'field_name',
      cellRenderer: 'translateViewRenderer',
      // filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      filter: 'agMultiColumnFilter',
      enableRowGroup: true
    },
    {
      ...defaultColDef,
      headerName: t('utils.fieldValue'),
      field: 'field_value',
      colId: 'field_value',
      sortable: true,
      width: 250,
      tooltipField: 'field_value',
      cellRenderer: 'translateViewRenderer',
      // filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      filter: 'agMultiColumnFilter',
      enableRowGroup: true
    },
    {
      ...defaultColDef,
      headerName: t('reports.language'),
      sortable: true,
      field: 'lang_cd',
      colId: 'lang_cd',
      width: 250,
      tooltipField: 'lang_cd',
      // filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      filter: 'agMultiColumnFilter',
      enableRowGroup: true
    },
    {
      ...defaultColDef,
      headerName: t('utils.shortDescription'),
      sortable: true,
      field: 'short_desc',
      colId: 'short_desc',
      width: 300,
      tooltipField: 'short_desc',
      // filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      filter: 'agMultiColumnFilter',
      enableRowGroup: true
    },
    {
      ...defaultColDef,
      headerName: t('utils.active'),
      field: 'is_active',
      colId: 'is_active',
      width: 270,
      cellRenderer: 'ActionsRenderer',
      enableRowGroup: true,
      filter: false,
      sortable: false
    }
    // {
    //   ...defaultColDef,
    //   colId: 'searchValueFilter',
    //   hide: true
    // }
  ];
};

export const getFilterParamStringForTranslateValue = data => {
  return getFilterQuery(data, {}, {}, '');
};
