import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import { getUserSelector } from '../../../selectors';
import { fetchLOETasks, loeDataApi, LOEInfoDataApi, updateLOE } from '../../../services/ApiService';
import { FEE_TYPES, POST } from '../../../services/constantService';
import { i18nInterpolator } from '../../../translations';

export default function AddLoePopup({ open, setOpen, refetch, edit = false, editValues, page, setPage, setValues: setEditValues, setEditPopup }) {
  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
    setEditValues({});
    setEditPopup(false);
    setWorkflowId(null);
    unMount();
  };
  const { id: projectId } = useParams();
  const [consultantLoading, setConsultantLoading] = React.useState(false);
  const [consultantDetails, setConsultantDetails] = React.useState([]);
  const [clientLoading, setClientLoading] = React.useState(false);
  const [clientDetails, setClientDetails] = React.useState([]);
  const [BDData, setBDData] = React.useState({});
  const user = useSelector(getUserSelector);

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [loeEmbedUrl, setLoeEmbedUrl] = React.useState('');
  const [workFlowId, setWorkflowId] = React.useState(null);
  const [startCount, setStartCount] = React.useState(false);
  const userData = useSelector(getUserSelector);
  const [infoDetails, setInfoDetails] = React.useState({
    position: '',
    totalCashCompensation: 0,
    estimated: 0,
    feeStructure: '',
    flatFeeAmount: 0,
    progressPaymentIntervals: '',
    includeAdministrativeFee: '',
    administrativeFeeAmount: 0
  });
  const maxPageLimit = 2;
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
    control,
    clearErrors,
    reset: resetField,
    handleSubmit
  } = useForm();
  React.useEffect(() => {
    //------------------------------------------------
    register('id');
    register('status');
    register('consultantName', { required: true });
    register('consultantEmail', { required: true });
    register('consultationTitle', { required: true });
    register('consultationEngagementCountry', { required: true });
    register('clientEmail', { required: true });
    register('clientPrefix');
    register('clientFirstName', { required: true });
    register('clientLastName', { required: true });
    register('clientTitle', { required: true });
    register('clientCompanyName', { required: true });
    // -----------------------------------
    register('initialFeeAmount', { required: true });
    register('progressFeeAmount', { required: true });
    // -----------------------------------

    return () => unMount();
  }, []);

  const unMount = () => {
    resetField();
    // setEditValues({});
    setPage(0);
    setStartCount(false);
  };

  useEffect(() => {
    if (edit) {
      setValues(editValues);
    } else {
      resetField();
      clearErrors();
    }
  }, [edit, editValues]);

  const handleMessage = async message => {
    if (!message.origin.includes('docubee.app')) {
      return;
    }

    const { _, event } = JSON.parse(message.data);

    switch (event) {
      case 'FATAL_ERROR':
        await updateLOE(workFlowId, { status: 'FAILED' });
        refetch();
        break;
      case 'NO_TASKS_AVAILABLE':
        await updateLOE(workFlowId, { status: 'IN_PROGRESS' });
        refetch();
        break;
      case 'WORKFLOW_CANCELED':
        await updateLOE(workFlowId, { status: 'CANCELLED' });
        refetch();
        break;
      case 'WORKFLOW_COMPLETE':
        await updateLOE(workFlowId, { status: 'COMPLETED' });
        refetch();
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [workFlowId]);

  const getPrimaryEmail = contact => {
    try {
      const email = contact?.contact_emails?.filter(item => {
        return item.is_primary === true;
      })[0]?.email;

      if (contact?.contact_emails?.length > 0 && !email) {
        return contact?.contact_emails[0]?.email;
      }
      return email;
    } catch (err) {
      return '';
    }
  };

  const setValues = values => {
    Object.keys(values).forEach(key => {
      setValue(key, values[key]);
    });
  };
  const intervalDuration = 15 * 60 * 1000; // use this to edit refresh time for loe tasks
  const { data: loeData, isSuccess } = useQuery({
    queryKey: ['LOEInfoDataApi', projectId],
    queryFn: async () => {
      return await LOEInfoDataApi(projectId);
    }
  });
  const { data: _ } = useQuery({
    queryKey: ['loeEmbed', 'tasks', watch('instance_id')],
    queryFn: async () => {
      if (!getValues('instance_id') || !userData?.email) return;
      const res = await fetchLOETasks(getValues('instance_id'), userData?.email);
      setLoeEmbedUrl(res?.data?.url);
      return res;
    },
    refetchInterval: intervalDuration
    // enabled: !!watch('instance_id') && !!userData?.email && startCount
  });

  useEffect(() => {
    if (open && edit === false) {
      getInternalContactDetails();
      setPage(0);
    }
    console.log('🚀 ~ AddLoePopup ~ loeData:', loeData);
  }, [loeData, isSuccess, open]);
  const calculateAdministartiveFee = projectIndirectFees => {
    const findAdministrationFee = projectIndirectFees?.filter(item => item?.type === 'administration');
    const calculateFee = findAdministrationFee?.reduce((acc, item) => {
      return acc + +item?.calculation_amt || 0;
    }, 0);

    return calculateFee || 'N/A';
  };
  const list = [
    {
      title: t('loe.position'),
      value: infoDetails?.position || 'N/A',
      prefix: '',
      valid: infoDetails?.position ? true : false,
      navigateToField: 'Overview/Details'
    },
    {
      title: t('loe.totalCashCompensation'),
      value: +infoDetails?.totalCashCompensation || 'N/A',
      prefix: BDData?.bd_currency_values?.currency_icon || '',
      valid: +infoDetails?.totalCashCompensation > 0 ? true : false,
      navigateToField: 'Roadmap/Scope',
      numberFormat: true
    },
    {
      title: t('loe.estimated'),
      value: +infoDetails?.estimated || 'N/A',
      prefix: BDData?.bd_currency_values?.currency_icon || '',
      valid: +infoDetails?.estimated > 0 ? true : false,
      navigateToField: 'Position/Overview',
      numberFormat: true
    },
    {
      title: t('loe.feeStructure'),
      value: infoDetails?.feeStructure || 'N/A',
      prefix: '',
      valid: infoDetails?.feeStructure ? true : false,
      navigateToField: 'Billing/Billing Info'
    },
    {
      title: infoDetails?.feeType === FEE_TYPES.FIXED_FEE ? t('loe.flatFeeAmount') : t('loe.feePercentage'),
      value: infoDetails?.flatFeeAmount || 'N/A',
      prefix: infoDetails?.feeType === FEE_TYPES.FIXED_FEE ? BDData?.bd_currency_values?.currency_icon || '' : '',
      valid: true,
      navigateToField: 'Billing/Billing Info',
      numberFormat: infoDetails?.feeType === FEE_TYPES.FIXED_FEE
    },
    {
      title: t('loe.progressPaymentIntervals'),
      value: infoDetails?.progressPaymentIntervals || 'N/A',
      prefix: '',
      valid: infoDetails?.progressPaymentIntervals ? true : false,
      navigateToField: 'Billing/Billing Info'
    },
    {
      title: t('loe.includeAdministrativeFee'),
      value: infoDetails?.includeAdministrativeFee || 'N/A',
      prefix: '',
      valid: infoDetails?.includeAdministrativeFee !== null ? true : false,
      navigateToField: 'Billing/Billing Info'
    },
    {
      title: t('loe.administrativeFeeAmount'),
      value: +infoDetails?.administrativeFeeAmount || 'N/A',
      prefix: BDData?.bd_currency_values?.currency_icon || '',
      valid: +infoDetails?.administrativeFeeAmount > 0 ? true : false,
      navigateToField: 'Billing/Billing Info',
      numberFormat: true
    }
  ];
  const handleCreateLoe = async data => {
    try {
      const body = {
        project_id: projectId,
        status: 'DRAFT',
        initial_fee: `${BDData?.bd_currency_values?.currency_icon}${data.initialFeeAmount}`,
        progress_fee: `${BDData?.bd_currency_values?.currency_icon}${data.progressFeeAmount}`,
        consultant_name: data.consultantName?.data?.user?.name || data.consultantName?.label,
        consultant_email: data.consultantEmail,
        consultant_title: data.consultationTitle,
        engagement_country: data.consultationEngagementCountry,
        client_email: data?.clientEmail,
        client_prefix: data?.clientPrefix || '',
        client_first_name: data.clientFirstName,
        client_last_name: data.clientLastName,
        created_by: user?.id,
        client_title: data.clientTitle,
        client_company: data.clientCompanyName,
        position: infoDetails?.position,
        total_cash_compensation: `${BDData?.bd_currency_values?.currency_icon}${Number(infoDetails?.totalCashCompensation).toLocaleString()}`,
        fee_structure: infoDetails?.feeStructure,
        progress_payment_intervals: infoDetails?.progressPaymentIntervals,
        fee_percentage: infoDetails?.flatFeeAmount,
        // flat_fee_amount: `${BDData?.bd_currency_values?.currency_icon}${Number(infoDetails?.flatFeeAmount).toLocaleString()}`,
        include_administrative_fees: infoDetails?.includeAdministrativeFee,
        admininistrative_fee_amount: `${BDData?.bd_currency_values?.currency_icon}${Number(infoDetails?.administrativeFeeAmount).toLocaleString()}`,
        initiator_email: user?.email
      };
      const emptyFields = Object.keys(body).filter(key => {
        // Skip client_prefix
        if (key === 'client_prefix') return false;
        return !body[key];
      });

      if (emptyFields.length > 0) {
        console.log('🚀 ~ handleCreateLoe ~ emptyFields:', emptyFields);
        enqueueSnackbar('Please fill all the required fields', { variant: 'error' });
        return;
      }
      const res = await loeDataApi(POST, '/', body);
      if (res?.data?.data?.url) {
        setLoeEmbedUrl(res?.data?.data?.url);
        setWorkflowId(res?.data?.data?.id);
        resetField();
        setPage(page + 1);
        setInterval(() => {
          setStartCount(true);
        }, intervalDuration);
      }
      refetch();
    } catch (err) {
      enqueueSnackbar(t('common.somethingWentWrong'), { variant: 'error' });
    }
  };

  const getInternalContactDetails = async () => {
    try {
      if (projectId) {
        setConsultantLoading(true);
        setClientLoading(true);

        if (isSuccess && loeData) {
          const data = loeData.data;
          setBDData(data?.project || {});
          setInfoDetails({
            position: data?.project?.helia_title,
            totalCashCompensation: data?.project?.estimated_percentage_bases?.reduce((acc, item) => acc + item?.estimated_amt || 0, 0),
            estimated: +data?.project?.estimated_revenue,
            feeType: data?.project?.fee_type,
            feeStructure: data?.project?.fee_type,
            flatFeeAmount:
              data?.project?.fee_type === FEE_TYPES.FIXED_FEE
                ? data?.project?.estimated_percentage_bases?.find(item => item?.type === 'Flat Fee')?.calculated_estimated_amt
                : `${data?.project?.fee_percentage}%`,
            progressPaymentIntervals: data?.project?.intervals,
            includeAdministrativeFee: data?.project?.estimated_indirect_total_fee > 0 ? 'Yes' : 'No',
            administrativeFeeAmount: data?.project?.estimated_indirect_total_fee
          });
          const partners = data?.partner?.map(item => {
            if (item && item?.user && item?.user?.id) {
              return {
                label: item?.user?.name,
                value: item?.user?.id,
                type: t('loe.usersOnThisBD'),
                data: item
              };
            } else {
              return {
                label: '',
                type: t('loe.otherUsers'),
                value: undefined
              };
            }
          });

          const otherConsultants = data?.otherBD?.map(item => {
            return {
              label: item?.name || '',
              value: item?.id || '',
              type: t('loe.otherUsers'),
              data: item
            };
          });

          const clientContacts = data?.clientContacts?.currentContacts?.map(item => {
            return {
              label: item?.ign_contacts?.name,
              value: item?.ign_contacts?.id,
              type: t('loe.companyContacts'),
              data: item
            };
          });

          const associatedContacts = data?.clientContacts?.associatedContacts?.map(item => {
            return {
              label: item?.ign_contacts?.name,
              value: item?.ign_contacts?.id,
              type: t('loe.associatedContacts'),
              data: item
            };
          });

          setConsultantLoading(false);
          setConsultantDetails([...(partners?.filter(Boolean) || []), ...(otherConsultants?.filter(Boolean) || [])]);
          setClientLoading(false);
          setClientDetails([...(clientContacts?.filter(Boolean) || []), ...(associatedContacts?.filter(Boolean) || [])]);
          setValues({
            consultationEngagementCountry: data?.marketDetails?.market || ''
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const consultantList = [
    {
      type: 'dropdown',
      title: `${t('loe.consultantName')} *`,
      value: watch('consultantName'),
      onChange: (e, v) => {
        setValues({ consultantName: v });
        setValues({
          consultantEmail: v?.data?.user?.email || v?.data?.email
        });
        setValues({
          consultationTitle: v?.data?.user?.user_contacts?.length > 0 ? v?.data?.user?.user_contacts[0]?.current_job_title : ''
        });
      },
      multiple: true,
      required: true,
      disabled: false,
      isLoading: consultantLoading,
      options: consultantDetails || [],
      error: errors?.consultantName,
      key: 'consultantName',
      groupBy: item => item?.type
    },
    {
      type: 'text',
      title: i18nInterpolator(t('common.required'), { field: t('loe.consultantEmail') }),
      value: watch('consultantEmail'),
      onChange: e => setValues({ consultantEmail: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [],
      error: errors?.consultantEmail,
      key: 'consultantEmail'
    },
    {
      type: 'text',
      title: i18nInterpolator(t('common.required'), { field: t('loe.consultationTitle') }),
      value: watch('consultationTitle'),
      onChange: e => setValues({ consultationTitle: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [],
      error: errors?.consultationTitle,
      key: 'consultationTitle'
    },
    {
      type: 'text',
      title: `${t('loe.engagementCountry')} *`,
      value: watch('consultationEngagementCountry'),
      onChange: e => setValues({ consultationEngagementCountry: e.target.value }),
      multiple: true,
      required: true,
      disabled: true,
      isLoading: false,
      options: [],
      error: errors?.consultationEngagementCountry,
      key: 'consultationEngagementCountry'
    }
  ];
  const clientList = [
    {
      type: 'dropdown',
      title: `${t('loe.clientName')} *`,
      value: watch('clientId'),
      onChange: (e, v) => {
        setValues({ clientId: v });
        setValues({ clientEmail: getPrimaryEmail(v?.data?.ign_contacts) });
        setValues({ clientFirstName: v?.data?.ign_contacts?.first_name });
        setValues({ clientLastName: v?.data?.ign_contacts?.last_name });
        setValues({ clientTitle: v?.data?.ign_contacts?.current_job_title });
        setValues({ clientCompanyName: v?.data?.ign_contacts?.contact_company?.name });
      },
      multiple: true,
      required: false,
      disabled: false,
      isLoading: clientLoading,
      options: clientDetails || [],
      groupBy: item => item?.type,
      error: errors?.clientEmail,
      key: 'clientId'
    },
    {
      type: 'text',
      title: `${t('loe.clientPrefix')}`,
      value: watch('clientPrefix'),
      onChange: (e, _) => setValues({ clientPrefix: e.target.value }),
      multiple: false,
      required: false,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Mr.',
          label: 'Mr.'
        },
        {
          value: 'Mrs.',
          label: 'Mrs.'
        }
      ],
      error: errors?.clientPrefix,
      key: 'clientPrefix'
    },
    {
      type: 'text',
      title: `${t('loe.clientFirstName')} *`,
      value: watch('clientFirstName'),
      onChange: (e, _) => setValues({ clientFirstName: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'John',
          label: 'John'
        }
      ],
      error: errors?.clientFirstName,
      key: 'clientFirstName'
    },
    {
      type: 'text',
      title: `${t('loe.clientLastName')} *`,
      value: watch('clientLastName'),
      onChange: (e, _) => setValues({ clientLastName: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Doe',
          label: 'Doe'
        }
      ],
      error: errors?.clientLastName,
      key: 'clientLastName'
    },
    {
      type: 'text',
      title: `${t('loe.clientTitle')} *`,
      value: watch('clientTitle'),
      onChange: (e, _) => setValues({ clientTitle: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Software Engineer',
          label: 'Software Engineer'
        }
      ],
      error: errors?.clientTitle,
      key: 'clientTitle'
    },
    {
      type: 'text',
      title: `${t('loe.clientEmail')} *`,
      value: watch('clientEmail'),
      onChange: (e, _) => setValues({ clientEmail: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      error: errors?.clientEmail,
      key: 'clientEmail'
    },
    {
      type: 'text',
      title: `${t('loe.clientCompanyName')} *`,
      value: watch('clientCompanyName'),
      onChange: (e, _) => setValues({ clientCompanyName: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Google',
          label: 'Google'
        }
      ],
      error: errors?.clientCompanyName,
      key: 'clientCompanyName'
    }
  ];
  const onValidationError = () => {
    enqueueSnackbar(t('common.fillAllRequiredFields'), { variant: 'error' });
  };

  const firstPage = (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '90%'
      }}
    >
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Stack>
          <Typography variant='body1' fontWeight={'bold'} className='fs-14'>
            {t('loe.engagementDetails')}
          </Typography>
          <Typography variant='p' className='fs-12' color={'gray'}>
            {t('loe.shareEngagementDetails')}
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        sx={{
          height: '212px',
          padding: '20px'
        }}
      >
        <Grid
          container
          sx={{
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <Grid item xs={8} paddingBottom={'12px'}>
            <Grid container gap={4}>
              {list.map((item, index) => (
                <Grid
                  item
                  xs={3}
                  key={index}
                  color={item.valid ? 'black' : 'red'}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    borderLeft: '2px solid #e0e0e0',
                    paddingLeft: '14px'
                  }}
                >
                  <Typography variant='p' className='fs-10' color={item.valid ? 'gray' : 'red'}>
                    {item.title}
                  </Typography>
                  {!consultantLoading ? (
                    <Stack className='fs-14' direction={'row'}>
                      <Typography variant='p' className='fs-14' style={{ direction: 'ltl' }}>
                        {item.prefix}
                      </Typography>
                      <Typography variant='p' className='fs-14' style={{ direction: 'ltl' }}>
                        {item.numberFormat ? item.value.toLocaleString() : item.value}
                      </Typography>
                    </Stack>
                  ) : (
                    <Skeleton variant='text' width={'100%'} height={'20px'} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              padding: '10px',
              backgroundColor: '#e0e0e01c'
            }}
          >
            <Controller
              name='initialFeeAmount'
              control={control}
              rules={{ required: t('loe.initialFeeAmountHelperText') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={`${t('loe.initialFeeAmount')} *`}
                  size='small'
                  value={watch('initialFeeAmount')}
                  error={!!errors?.initialFeeAmount}
                  helperText={errors?.initialFeeAmount?.message}
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                    startAdornment: BDData?.bd_currency_values?.currency_icon
                  }}
                  onChange={e => setValues({ initialFeeAmount: e.target.value })}
                  sx={{
                    backgroundColor: 'white',
                    width: '80%'
                  }}
                />
              )}
            />
            <Controller
              name='progressFeeAmount'
              control={control}
              rules={{ required: t('loe.progressFeeAmountHelperText') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={`${t('loe.progressFeeAmount')} *`}
                  size='small'
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                    startAdornment: BDData?.bd_currency_values?.currency_icon
                  }}
                  value={watch('progressFeeAmount')}
                  error={!!errors?.progressFeeAmount}
                  helperText={errors?.progressFeeAmount?.message}
                  onChange={e => setValues({ progressFeeAmount: e.target.value })}
                  sx={{
                    backgroundColor: 'white',
                    width: '80%'
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </ListItem>
      {/* <Divider /> */}
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Stack>
          <Typography variant='body1' fontWeight={'bold'} className='fs-14'>
            {t('loe.consultantDetails')}
          </Typography>
          <Typography variant='p' className='fs-12' color={'gray'}>
            {t('loe.consultantSignatureInformation')}
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Grid container justifyContent={'start'} gap={2}>
          {consultantList.map((item, index) => (
            <Grid item xs={2.7} key={index}>
              {item.type === 'text' && (
                <Controller
                  name={item.key}
                  control={control}
                  rules={{ required: `${item.title} is required` }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      name={item.key}
                      label={item.title}
                      size='small'
                      error={!!item.error}
                      value={item.value || ''}
                      onChange={item.onChange}
                      disabled={item.disabled}
                      helperText={item.error?.message}
                      sx={{
                        backgroundColor: 'white',
                        width: '100%'
                      }}
                    />
                  )}
                />
              )}

              {
                // Dropdown
                item.type === 'dropdown' && (
                  <Controller
                    name={item.key}
                    control={control}
                    rules={{ required: t('common.isRequired', { field: item.title }) }}
                    render={({ field }) => (
                      <CustomMuiDropdown
                        {...field}
                        name={item.key}
                        label={item.title}
                        options={item.options}
                        value={item.value || { value: '', label: '' }}
                        onChange={item.onChange}
                        disabled={item.disabled}
                        isLoading={item.isLoading}
                        size='small'
                        getOptionLabel={option => option?.label}
                        autoCompleteProps={{
                          groupBy: item.groupBy
                        }}
                        textFieldProps={{
                          error: !!item.error,
                          helperText: item.error?.message
                        }}
                      />
                    )}
                  />
                )
              }
            </Grid>
          ))}
        </Grid>
      </ListItem>
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Stack>
          <Typography variant='body1' fontWeight={'bold'} className='fs-14'>
            {t('loe.clientDetails')}
          </Typography>
          <Typography variant='p' className='fs-12' color={'gray'}>
            {t('loe.clientSignatureInformation')}
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Grid container gap={2} rowGap={6}>
          {clientList.map((item, index) => (
            <Grid item xs={2.7} key={index}>
              {item.type === 'text' && (
                <>
                  {item.required ? (
                    <Controller
                      name={item.key}
                      control={control}
                      rules={{ required: t('common.isRequired', { field: item.title }) }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={item.title}
                          size='small'
                          error={!!item.error}
                          value={item.value || ''}
                          onChange={item.onChange}
                          disabled={item.disabled}
                          helperText={item.error?.message}
                          sx={{
                            backgroundColor: 'white',
                            width: '100%'
                          }}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      label={item.title}
                      size='small'
                      value={item.value || ''}
                      onChange={item.onChange}
                      disabled={item.disabled}
                      helperText={item.error?.message}
                      sx={{
                        backgroundColor: 'white',
                        width: '100%'
                      }}
                    />
                  )}
                </>
              )}

              {
                // Dropdown
                item.type === 'dropdown' && (
                  <Controller
                    name={item.key}
                    control={control}
                    rules={{ required: t('common.isRequired', { field: item.title }) }}
                    render={({ field }) => (
                      <CustomMuiDropdown
                        {...field}
                        label={item.title}
                        options={item.options}
                        value={item.value}
                        onChange={item.onChange}
                        disabled={item.disabled}
                        isLoading={item.isLoading}
                        size='small'
                        getOptionLabel={option => option?.label}
                        autoCompleteProps={{
                          groupBy: item.groupBy
                        }}
                        textFieldProps={{
                          error: !!item.error,
                          helperText: item.error?.message
                        }}
                      />
                    )}
                  />
                )
              }
            </Grid>
          ))}
        </Grid>
      </ListItem>
    </List>
  );
  const secondPage = (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '90vh'
      }}
    >
      <iframe src={loeEmbedUrl} id='loe-iframe' style={{ height: '90vh', width: '100%' }}></iframe>
    </Stack>
  );
  const DrawerList = (
    <Box sx={{ width: '90vw', position: 'relative', height: '100%', overflowY: 'auto' }} role='presentation'>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          minHeight: '80px'
        }}
      >
        <ListItem disablePadding>
          <Grid container direction='row' width={'100%'} alignItems={'center'} justifyContent={'space-between'} p={'12px'} px={'20px'} bgcolor={'primary.main'}>
            <Grid item xs={4}>
              <Typography variant='body1' fontWeight={'bold'} className='fs-16' color={'#23cea7'}>
                {t('loe.createLoe')}
              </Typography>
              <Typography variant='p' className='fs-12' color={'white'}>
                {t('loe.developClearDetailedActionableProjectScope')}
              </Typography>
            </Grid>
            <Grid item xs={4} display={'flex'} direction='row' alignItems='center' justifyContent='center'>
              <Stack
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: '#23cea7'
                }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                1
              </Stack>
              <Box
                sx={{
                  height: '2px',
                  width: '100px',
                  background: 'white'
                }}
              >
                <Box
                  width={`${(page / maxPageLimit) * 100}%`}
                  sx={{
                    backgroundColor: '#23cea7',
                    height: '100%',
                    transition: 'width 1s'
                  }}
                />
              </Box>
              <Stack
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: '#23cea7'
                }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                2
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={toggleDrawer(false)} variant='text' sx={{ color: 'white' }} startIcon={<CloseOutlinedIcon />}></Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      {page === 0 && firstPage}
      {page === 1 && secondPage}
      {page === 2 && secondPage}
      <Stack
        sx={{
          position: 'sticky',
          bottom: '0',
          width: '100%',
          padding: '8px',
          borderTop: '1px solid #e0e0e0',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          zIndex: 1000,
          display: page !== 0 ? 'none' : 'flex'
        }}
        direction={'row'}
      >
        <Box>
          {page > 0 && (
            <Button variant='outlined' sx={{ width: '100px' }} onClick={() => setPage(page - 1)} disabled={edit && page === 1}>
              {t('common.back')}
            </Button>
          )}
        </Box>
        <Button
          variant='contained'
          sx={{ width: '100px' }}
          onClick={() => {
            if (page === 0) handleSubmit(() => handleCreateLoe(getValues()), onValidationError)();
            if (page === 1) setPage(page + 1);
          }}
          disabled={page === maxPageLimit}
        >
          {t('common.continue')}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open}
        onClose={() => {
          toggleDrawer(false);
        }}
        anchor='right'
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
function NumberFormatCurrency(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values =>
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue
          }
        })
      }
      thousandSeparator=','
      isNumericString
      allowNegative={false}
      format='###,##,##,###'
    />
  );
}
NumberFormatCurrency.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
AddLoePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  edit: PropTypes.bool,
  editValues: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  setValues: PropTypes.func,
  setEditPopup: PropTypes.func
};

//TRASH

// const clients = data?.clientTeam?.map(item => {
//   if (item && item?.contact && item?.contact?.id) {
//     if (!getPrimaryEmail(item?.contact)) return;
//     return {
//       label: getPrimaryEmail(item?.contact) || item?.contact?.first_name + ' ' + item?.contact?.last_name,
//       value: getPrimaryEmail(item?.contact) || item?.contact?.first_name + ' ' + item?.contact?.last_name,
//       type: t('loe.usersOnThisBD'),
//       data: item
//     };
//   } else {
//     return {
//       label: '',
//       type: t('loe.otherUsers'),
//       value: undefined
//     };
//   }
// });
// const otherBD = data?.otherBD?.map(item => {
//   if (item && item?.email) {
//     if (!item?.email) return;
//     return {
//       label: item?.email,
//       value: item?.email,
//       type: t('loe.otherUsers'),
//       data: {
//         contact: {
//           email: item?.email || '',
//           first_name: item?.first_name || '',
//           last_name: item?.last_name || '',
//           current_job_title: item?.current_job_title || '',
//           contact_company: {
//             name: item?.company_name || ''
//           }
//         }
//       }
//     };
//   } else {
//     return {
//       label: '',
//       type: t('loe.otherUsers'),
//       value: undefined
//     };
//   }
// });
