import { Close, DragHandle } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import CustomDropdown from '../../../components/common/CustomDropdown';
import Loader from '../../../components/common/Loader';
import { CompetencyDataApi, ProjectCompetencyDataApi } from '../../../services/ApiService';
import { DELETE, POST, PUT } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import { EditLabelInlineComponent } from './Comman';
import { ProjectDataContext } from './Context';

function Leadership() {
  const [competency, setCompetency] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const { projectData, handleTabChanges, dummyText } = useContext(ProjectDataContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const [isSequence, setSequence] = useState(false);
  const { data: competencyOptions } = useApi({
    queryFn: () => {
      return CompetencyDataApi(POST, '', '', `all?version=${projectData?.framework_type}`);
    }
  });
  const { data: projectCompetencyData, success: isProjectCompetency } = useApi({
    queryFn: () => {
      return ProjectCompetencyDataApi(
        POST,
        '',
        {
          query: {
            project_id: projectData?.id,
            order: ['seq_no']
          }
        },
        'all'
      );
    }
  });

  const { t } = useTranslation();
  const handleDeleteCompetency = async cardData => {
    try {
      setIsLoader(true);
      const recommendationCompetency = competency.filter(data => data.id != cardData.id);

      setCompetency(recommendationCompetency.slice());
      if (!cardData?.id?.includes('new') || !cardData?.id?.includes('update')) {
        await ProjectCompetencyDataApi(DELETE, '', {
          id: cardData?.id
        });
      }
      if (cardData?.id?.includes('update')) {
        await ProjectCompetencyDataApi(DELETE, '', {
          id: cardData?.id?.split('/')[1]
        });
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setIsLoader(false);
    }
  };

  const handleChangeCompetency = (key, index, value) => {
    let comp = competency[index];
    comp[key] = value;
    comp['id'] = comp['id'].includes('update') || comp['id'].includes('new') ? comp['id'] : 'update/' + comp['id'];
    competency[index] = comp;
    setCompetency(competency.slice());
  };

  const handleAddCompetency = () => {
    setCompetency([
      ...competency,
      {
        id: 'new/' + competency.length,
        competency: null,
        question: '',
        insights: '',
        seq_no: competency.length + 1
      }
    ]);
  };
  useEffect(() => {
    if (projectCompetencyData?.data?.data) setCompetency(projectCompetencyData?.data?.data);
  }, [projectCompetencyData]);

  const handleDragDrop = targetItem => {
    if (!draggingItem) return;

    const currentIndex = competency.indexOf(draggingItem);
    const targetIndex = competency.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      competency.splice(currentIndex, 1);
      competency.splice(targetIndex, 0, draggingItem);
      setCompetency(competency.slice());
      setSequence(true);
    }
  };

  const onChangeDropDown = (index, newValue) => {
    const competencyId = competency[index]?.id;
    const id = competencyId?.includes('new') ? competencyId + newValue?.id : competencyId?.includes('update') ? competencyId : 'update/' + competencyId;
    competency[index] = {
      id: id,
      competency: newValue,
      insights: newValue?.insights ?? ' ',
      question: newValue?.question ?? ' '
    };
    setCompetency([...competency]);
  };

  const handleSave = async () => {
    try {
      setIsLoader(true);
      const project_id = projectData?.id;

      await competency.forEach(async (comp, index) => {
        if (comp?.id?.includes('update')) {
          const payload = {
            id: comp?.id?.split('/')[1],
            competency_id: comp?.competency?.id,
            question: comp?.question,
            insights: comp?.insights,
            seq_no: index + 1
          };

          await ProjectCompetencyDataApi(PUT, '', payload);
        }

        if (comp?.id?.includes('new')) {
          delete comp.id;
          const payload = {
            ...comp,
            project_id: project_id,
            competency_id: comp?.competency?.id,
            seq_no: index + 1
          };

          const res = await ProjectCompetencyDataApi(POST, '', payload);
          competency[index] = { ...competency[index], ...res?.data };
          setCompetency(competency.slice());
        }

        if (isSequence && (!comp?.id?.includes('update') || !comp?.id?.includes('new'))) {
          const payload = {
            id: comp?.id,
            seq_no: index + 1
          };

          await ProjectCompetencyDataApi(PUT, '', payload);
        }
      });
      enqueueSnackbar('Competencies Saved Successfully', 'success');
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setIsLoader(false);
    }
  };

  const handleNext = () => {
    handleTabChanges(1, 4);
  };
  return (
    <Box sx={{ backgroundColor: 'titleBarBackground.main' }}>
      <Box className='p-2'>
        <div
          className='fs-12 info-panel'
          dangerouslySetInnerHTML={{
            __html: dummyText?.leadership
          }}
        />
      </Box>
      <Stack direction='row' justifyContent={'flex-end'}>
        <CustomButton type='tertiary m-1' variant='text' buttonText={t('actions.save')} onClick={handleSave} />
        <CustomButton
          buttonText={t('actions.saveAndNext')}
          variant={'contained'}
          type='m-1 primary'
          onClick={async () => {
            await handleSave();
            handleNext();
          }}
        />
      </Stack>
      <Grid container fullWidth spacing={4}>
        <Loader show={!isProjectCompetency || isLoader} />
        <Grid item xs={12}>
          {competency.map((data, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: '96%'
              }}
              className='p-3 m-1 mt-4 ml-5 rounded border'
            >
              <Stack
                key={index}
                direction='row'
                sx={{
                  position: 'absolute',
                  left: '-40px',
                  top: '13px',
                  cursor: 'move !important'
                }}
                draggable='true'
                onDragStart={e => {
                  setDraggingItem(data);
                }}
                onDragEnd={() => {
                  setDraggingItem(null);
                }}
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDrop={() => {
                  handleDragDrop(data);
                }}
              >
                <DragHandle fontSize='small' />

                <Typography variant='caption'>{index + 1}</Typography>
              </Stack>
              <Close
                fontSize='small'
                sx={{
                  border: '1px solid #c3423f',
                  borderRadius: '3px',
                  position: 'absolute',
                  top: '43%',
                  right: '-30px',
                  zIndex: '2',
                  color: 'error.main',
                  cursor: 'pointer'
                }}
                onClick={() => handleDeleteCompetency(data)}
              />
              <CustomDropdown
                options={competencyOptions?.data?.rows ?? []}
                label={t('project.candidateScore.competencyLabel')}
                value={data?.competency?.name}
                onChange={(event, newValue) => {
                  onChangeDropDown(index, newValue);
                }}
                className='w-100'
                key={index}
              />
              <Grid container>
                <Grid item xs={6}>
                  <ul
                    style={{
                      listStyleType: 'square',
                      listStylePosition: 'inside'
                    }}
                    className='m-1'
                  >
                    <div dangerouslySetInnerHTML={{ __html: data?.insights ?? '' }} />
                    {/* {data.insights.map((li) => (
                      <li key={li}>
                        <Typography variant="caption">{li}</Typography>
                      </li>
                    ))} */}
                  </ul>
                </Grid>
                <Grid item xs={6}>
                  <EditLabelInlineComponent index={index} label={t('utils.question')} keyValue='question' description={data.question} onChange={handleChangeCompetency} isSelected={false} />
                </Grid>
              </Grid>
            </Box>
          ))}
          <CustomButton type='primary mt-2 ml-1' variant='contained' buttonText={'+' + t('utils.addCompetency')} onClick={() => handleAddCompetency()} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Leadership;
