import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import Loader from '../../../../components/common/Loader';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { getUserSelector } from '../../../../selectors';
import { ignWorkbenchDataApi, picklistsDataApis } from '../../../../services/ApiService';
import { GET, POST } from '../../../../services/constantService';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { WorkBenchContext } from '../../Context';

function AddWorkBenchModal() {
  const { addWorkBenchModalOpen, setAddWorkBenchModalOpen, refetchFunc } = useContext(WorkBenchContext);
  const [workbenchType, setWorkbenchType] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [isLoading, setLoading] = useState(false);
  const user = useSelector(getUserSelector);

  const { t } = useTranslation();
  const handleFormChange = (key, value) => {
    formValue[key] = value;
    setFormValue(formValue);
  };

  const onClose = () => {
    setAddWorkBenchModalOpen(false);
  };

  const handleCreateList = async () => {
    try {
      setLoading(true);
      const payload = {
        name: formValue.name ?? '',
        type: formValue.type ?? '',
        description: formValue.description ?? '',
        created_by: user.id
      };
      const res = await ignWorkbenchDataApi(POST, '', payload, '');
      if (res && res?.data) {
        enqueueSnackbar('Workbench is created successfully', 'success');
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
      onClose();
      refetchFunc();
    }
  };

  const FetchDropDownValues = async () => {
    try {
      const res = await picklistsDataApis(GET, 'WORKBENCH_TYPE');

      if (res?.data) {
        const filteredData = res.data.filter(item => !['company', 'candidate'].includes(item?.field_value));
        setWorkbenchType(filteredData);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  useEffect(() => {
    FetchDropDownValues();
  }, []);
  return (
    <CustomModalWithHeader label={t('utils.addList')} closeIcon={true} isOpen={addWorkBenchModalOpen} onClose={onClose} maxWidth='md'>
      <Loader show={isLoading} />
      <div style={{ marginTop: '24px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomInputField label={t('utils.listName')} onChange={e => handleFormChange('name', e.target.value)} required />
          </Grid>
          <Grid item xs={12}>
            <CustomDropdown
              label={t('utils.listType')}
              options={workbenchType}
              className={'w-100'}
              onChange={(e, value) => {
                handleFormChange('type', value.field_value);
              }}
              required
              fullWidth
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputField label={t('utils.description')} onChange={e => handleFormChange('description', e.target.value)} required multiline rows={2} />
          </Grid>
        </Grid>
        <div className='w-100 d-flex justify-content-end align-items-center mt-4'>
          <CustomButton type='tertiary-error' variant={'text'} buttonText={t('actions.cancel')} onClick={onClose} />
          <CustomButton buttonText={t('utils.addList')} onClick={handleCreateList} />
        </div>
      </div>
    </CustomModalWithHeader>
  );
}

AddWorkBenchModal.propTypes = { isOpen: PropTypes.bool, onClose: PropTypes.func };

export default AddWorkBenchModal;
