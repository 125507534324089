//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Box, Button, FormControl, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import DatePickerCommon from '../../components/common/DatePicker/DatePicker';
import BasicPartnerSelection from '../../components/common/FunctionalComponents/BasicPartnerSelection';
import MultiSelectCompanySelectionDebounce from '../../components/common/FunctionalComponents/CompanySelection/MultiSelectCompanyDebounce';
import RegionSelection from '../../components/common/FunctionalComponents/RegionSelection';
import TermtypeIntervalSelection from '../../components/common/FunctionalComponents/TermtypeIntervalSelection';
import TermtypeSelection from '../../components/common/FunctionalComponents/TermtypeSelection';
import CustomInputField from '../../components/common/StyledComponents/CustomInputField';
import { formatDate } from '../../utils/date';

const defaultValues = {
  projected_bill_bate: null,
  term_type: null,
  intervals_desc: null,
  company: [],
  region: null,
  partners: [],
  ign_companies: []
};

export default function InvoiceQuery(props) {
  const { option } = props;
  const { handleQuery /* option */ } = props;
  const { handleSubmit, setValue, register, unregister, watch } = useForm({
    defaultValues
  });

  useEffect(() => {
    register('projected_bill_date_to');
    register('projected_bill_date_from');
    register('term_type');
    register('intervals');
    register('intervals_desc');
    register('intervals_other');
    register('ign_companies');
    register('region');
    register('partners');
    register('term_desc');
    return () => {
      unregister('projected_bill_date_to');
      unregister('projected_bill_date_from');
      unregister('term_type');
      unregister('intervals');
      unregister('intervals_desc');
      unregister('intervals_other');
      unregister('ign_companies');
      unregister('region');
      unregister('partners');
      unregister('term_desc');
    };
  }, [register]);

  return (
    <Box className='query p-4'>
      <Box className='d-flex align-items-center'>
        <Typography htmlFor='contact' className='query-label pb-2 fs-12'>
          Projected Bill Date Range
        </Typography>
      </Box>
      <Box className='d-flex align-items-center row-height'>
        <FormControl className='query-input query-input-invoice'>
          <Box className='d-flex align-items-center ' gap={4}>
            <DatePickerCommon
              value={formatDate(watch('projected_bill_date_from'), 'MM-DD-YYYY') || null}
              format='MM/DD/YYYY'
              placeholder='mm/dd/yyyy'
              // onChange={(e) => {
              // const selectedDate = e?.$d;
              // setDateRange({ ...dateRange, start: e })
              // handleChangeExperienceData(index, "start_date", e.selectedDate ? selectedDate : null)
              // }}
              // minDate={watch("projected_bill_date_from")}
              // maxDate={watch("projected_bill_date_to")}
              onChange={e => {
                const selectedDate = e?.$d;
                const date = new Date(selectedDate);
                const formattedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
                setValue('projected_bill_date_from', formattedDate, { shouldDirty: true });
              }}
              label='From Date'
            />
            <DatePickerCommon
              value={formatDate(watch('projected_bill_date_to'), 'MM-DD-YYYY') || null}
              format='MM/DD/YYYY'
              placeholder='mm/dd/yyyy'
              // onChange={(e) => {
              // const selectedDate = e?.$d;
              // setDateRange({ ...dateRange, start: e })
              // handleChangeExperienceData(index, "start_date", e.selectedDate ? selectedDate : null)
              // }}
              // minDate={watch("projected_bill_date_to")}
              onChange={e => {
                const selectedDate = e?.$d;
                const date = new Date(selectedDate);
                const formattedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
                setValue('projected_bill_date_to', formattedDate, { shouldDirty: true });
              }}
              // minDate={watch("projected_bill_date_from") || ""}

              label='End Date'
            />
          </Box>
        </FormControl>
        <FormControl className='query-input query-input-invoice'>
          <TermtypeSelection
            viewAddress={true}
            value={watch('term_type') || null}
            {...register('term_desc')}
            // type='TERM_TYPE'
            label='Term Type'
            onChange={(e, data) => {
              setValue('intervals_desc', null);
              setValue('intervals', null);
              setValue('intervals_other', null);
              setValue('term_type', data ? data.short_desc : null);
              setValue('term_desc', data ? data.short_desc : null);
            }}
          />
        </FormControl>
        <FormControl className='query-input'>
          <TermtypeIntervalSelection
            viewAddress={true}
            disabled={!watch('term_type')}
            value={watch('intervals') || null}
            {...register('intervals_desc')}
            type={watch('term_type') === 'Milestone' ? 'INTERVAL_MILE' : 'INTERVALS'}
            label='Intervals'
            onChange={(e, data) => {
              setValue('intervals', data ? data.short_desc : null, { shouldDirty: true });
              setValue('intervals_desc', data ? data.short_desc : null, { shouldDirty: true });
              if (data !== null && data.field_value !== 'Other Interval') {
                setValue('intervals_other', '');
              }
            }}
          />
        </FormControl>
      </Box>
      <Box className='d-flex align-items-center' style={{ height: 'auto' }}>
        <FormControl className='query-input'>
          <MultiSelectCompanySelectionDebounce
            label={'Company'}
            // value={watch('ign_companies') || []}
            defaultValue={watch('ign_companies')?.map(company => company.name) || []}
            onChange={(e, data) => {
              setValue('ign_companies', Array.isArray(data) ? data : []);
            }}
            viewAddress
            disableCloseOnSelect
            multiple
            isCheckBox
            clearable
            style={{ width: '100%' }}
          />
        </FormControl>
        <FormControl className='query-input'>
          <RegionSelection
            viewAddress={true}
            defaultValue={watch('region') || null}
            onChange={(e, data) => {
              setValue('region', data?.field_value ? data?.field_value : null);
            }}
            label='Region'
          />
        </FormControl>

        <FormControl className='query-input'>
          <BasicPartnerSelection
            viewAddress={true}
            label={'partners'}
            list={watch('partners')}
            defaultValue={option}
            onChange={(e, data) => {
              setValue('partners', data ? data : []);
            }}
            multiple={true}
          />
        </FormControl>
      </Box>
      <Box className='d-flex align-items-center interval-field' style={{ height: 'auto' }}>
        {watch('intervals_desc') === 'Other Interval' ? (
          <CustomInputField
            defaultValue={watch('intervals_other') || ''}
            label={'Interval Other'}
            onChange={e => {
              setValue('intervals_other', e.target.value, { shouldDirty: true });
            }}
          />
        ) : null}
      </Box>
      <Box className='d-flex row-height'>
        <Box style={{ position: 'absolute', right: '80px' }}>
          <Button
            className='query-button mt-0'
            variant='contained'
            color='primary'
            /* style={{ width: 100, marginLeft: 'auto' }} */
            onClick={handleSubmit(handleQuery)}
          >
            Search
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

InvoiceQuery.propTypes = {
  handleQuery: PropTypes.func,
  type: PropTypes.string,
  pullData: PropTypes.func,
  option: PropTypes.array
};
