import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import CustomDropdown from '../../../components/common/CustomDropdown';
import { CandidateStageSetupAPi, ClientDispositionMap, picklistsDataApis } from '../../../services/ApiService';
import { DELETE, GET, POST } from '../../../services/constantService';
import AgGridWrapper from '../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../utils/common';
import { CustomLoadingOverlayComponent, loadColumnStateFromLocalStorage } from '../../Commons/Utils';

function DispositionTable({ projectId }) {
  let columnApi, gridApi;
  const gridApiRef = useRef(null);
  const [, setIsGridReady] = useState(false);

  // const onGridReady = params => {
  //   gridApi = params.api;
  //   columnApi = params.columnApi;
  //   // const columnApi = params.columnApi
  //   params.columnApi.autoSizeAllColumns();
  //   loadColumnStateFromLocalStorage('masterSearchContactColumns', {
  //     columnApi
  //   });
  //   // this.setState({ isGridReady: true });
  //   setIsGridReady(true);
  // };
  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;

    // Use sizeColumnsToFit instead of deprecated autoSizeAllColumns
    params.api.sizeColumnsToFit();

    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi
    });
    setIsGridReady(true);
  };

  const dispositionMenuItemsColumnDefs = [
    {
      field: 'menuItem',
      headerName: 'Disposition Mapping',
      minWidth: 205,
      filter: false,
      suppressHeaderMenuButton: true
    },
    {
      field: 'targetStage',
      headerName: 'Target Stage',
      cellRenderer: ({ value }) => value,
      minWidth: 300,
      filter: false,
      suppressHeaderMenuButton: true
    }
  ];

  const acceptStageChange = dispositionName => async (event, value) => {
    if (!value) {
      await ClientDispositionMap(DELETE, '', {
        project_id: projectId,
        disposition_name: dispositionName
      });
      return;
    }
    await ClientDispositionMap(POST, '/create-if-not-exist', {
      project_id: projectId,
      disposition_name: dispositionName,
      mapped_to_stage: value?.value?.id
    });
    // console.log({ value });
  };

  const rejectStageChange = dispositionName => async (event, value) => {
    if (!value) {
      await ClientDispositionMap(DELETE, '', {
        project_id: projectId,
        disposition_name: dispositionName
      });
      return;
    }
    await ClientDispositionMap(POST, '/create-if-not-exist', {
      project_id: projectId,
      disposition_name: dispositionName,
      mapped_to_stage: value?.value?.id
    });
  };

  const dataSource = {
    getRows: async params => {
      // gridApi.showLoadingOverlay();

      try {
        const res = await CandidateStageSetupAPi(GET, `/all?projectId=${projectId}`);
        const stages = res.data.map(stage => ({ name: stage.stage_name, value: stage }));

        const defaultOptions = await ClientDispositionMap(GET, `?project_id=${projectId}`);
        const { data: dispositionNames } = await picklistsDataApis(GET, 'DISPOSITION_NAME');
        const approvedFieldName = dispositionNames.find(name => name.field_value === 'approved')?.field_value;
        const rejectedFieldName = dispositionNames.find(name => name.field_value === 'rejected')?.field_value;
        const acceptedData = defaultOptions.data.find(data => data.disposition_name === approvedFieldName);
        const rejectedData = defaultOptions.data.find(data => data.disposition_name === rejectedFieldName);

        const labelResponse = await picklistsDataApis(GET, 'DISPOSITION_NAME');

        const dispositionRowData = [
          {
            menuItem: labelResponse.data.find(item => item.field_value === 'approved')?.short_desc || 'Approved',
            targetStage: (
              <div className='flex-center-w-full'>
                <CustomDropdown
                  label='Stages'
                  multiple={false}
                  name='Stages'
                  className={'w-100'}
                  options={stages}
                  defaultValue={{ name: acceptedData?.stage.stage_name, value: acceptedData?.stage }}
                  onChange={acceptStageChange(approvedFieldName)}
                />
              </div>
            )
          },
          {
            menuItem: labelResponse.data.find(item => item.field_value === 'rejected')?.short_desc || 'Rejected',
            targetStage: (
              <div className='flex-center-w-full'>
                <CustomDropdown
                  label='Stages'
                  multiple={false}
                  name='Stages'
                  className={'w-100'}
                  options={stages}
                  defaultValue={{ name: rejectedData?.stage.stage_name, value: rejectedData?.stage }}
                  onChange={rejectStageChange(rejectedFieldName)}
                />
              </div>
            )
          }
        ];

        params.successCallback(dispositionRowData, dispositionRowData.length);
      } catch (err) {
        params.failCallback();
      }

      // gridApi.hideOverlay();
    }
  };

  return (
    <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '340px', width: '522px' }}>
      <AgGridWrapper
        ref={gridApiRef}
        onGridReady={onGridReady}
        enableBrowserTooltips={true}
        datasource={dataSource}
        columnDefs={dispositionMenuItemsColumnDefs}
        getRowNodeId={data => data.id}
        scrollbarWidth={12}
        rowHeight={60}
        rowSelection='multiple'
        defaultColDef={{
          minWidth: 100,
          resizable: true,
          autoSizeStrategy: 'fitGridWidth'
        }}
        components={{
          CustomLoadingOverlayComponent
        }}
        loadingOverlayComponent={'CustomLoadingOverlayComponent'}
        blockLoadDebounceMillis={SCROLL_TIMEOUT}
        suppressHorizontalScroll={false}
        rowModelType='infinite'
        pagination={false}
        // rowGroupPanelShow='always'
        showSidebar={false}
      />
    </div>
  );
}

DispositionTable.propTypes = {
  projectId: PropTypes.string
};

export default DispositionTable;
