import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../../components/common/CustomButton';
import AddedBySelection from '../../../../../../components/common/FunctionalComponents/AddedBy/AddedBy';
import MultiSelectCompanySelectionDebounce from '../../../../../../components/common/FunctionalComponents/CompanySelection/MultiSelectCompanyDebounce';
import MultiSelectJobTitle from '../../../../../../components/common/FunctionalComponents/ContactJobTtitleSelection/MultipleJobTitleSelection';
import CustomDateRangePicker from '../../../../../../components/common/FunctionalComponents/CustomDateRangePicker';
import IndustrySelection from '../../../../../../components/common/FunctionalComponents/IndustrySelection';
import MultiSelectHeliaTitle from '../../../../../../components/common/FunctionalComponents/ProjectHeliaSelection/MultipleHeliaTitleSelection';
import MultiStageSelection from '../../../../../../components/common/FunctionalComponents/StageSelection/MultiSelectStages';
import CustomTagSelection from '../../../../../../components/common/FunctionalComponents/TagSelection/CustomTagSelection';
import CustomInputField from '../../../../../../components/common/StyledComponents/CustomInputField';
import { ignWorkbenchQueryApi } from '../../../../../../services/ApiService';
import { PUT } from '../../../../../../services/constantService';

export function CandidateFilterModal({ data, setFilterOptions, setQueryResponse }) {
  const { t } = useTranslation();

  const [candidateName, setCandidateName] = useState(data?.name || null);
  const [projectName, setProjectName] = useState([]);
  const [projectIndustries, setProjectIndustries] = useState(data?.projectIndustry || []);
  const [candidateStages, setCandidateStages] = useState([]);
  const [currentCompany, setCurrentCompany] = useState([]);
  const [previousCompany, setPreviousCompany] = useState([]);
  const [currentJobTitle, setCurrentJobTitle] = useState([]);
  const [previousJobTitle, setPreviousJobTitle] = useState([]);
  const [industries, setIndustries] = useState(data?.industry || []);
  const [range, setRange] = useState([]);
  const [lastActivityAt, setLastActivityAt] = useState({
    startDate: null,
    endDate: null
  });
  const [lastActivityBy, setLastActivityBy] = useState([]);
  const [projectTags, setProjectTags] = useState([]);
  const [contactTags, setContactTags] = useState([]);
  const handleCompanyChange = (stateFunc, data) => {
    stateFunc(data);
  };

  const handleTagChange = (stateFunc, data) => {
    stateFunc(data);
  };

  const handleIndustryChange = value => {
    setIndustries(value);
  };

  const handleProjectIndustryChange = value => {
    setProjectIndustries(value);
  };

  const handleCandidateStages = value => {
    setCandidateStages(value);
  };

  const handleLastActivityByChange = data => {
    const recordData = data?.map(elem => {
      return elem?.id;
    });
    setLastActivityBy(recordData);
  };

  const handleJobTitleChange = (stateFunc, selected) => {
    stateFunc(selected);
  };

  const handleDateChange = value => {
    setRange(value);
  };

  useEffect(() => {
    setLastActivityAt({
      startDate: range?.[0] ? dayjs.utc(range[0]) : null,
      endDate: range?.[1] ? dayjs.utc(range[1]) : null
    });
  }, [range]);

  const handleSaveAndSearch = async () => {
    const filterOptionPayload = {
      name: candidateName,
      candidateProjectName: projectName?.map(item => item?.name),
      projectIndustry: projectIndustries?.map(item => item.id),
      currentCompanyName: currentCompany?.map(item => item?.name),
      previousCompanyName: previousCompany?.map(item => item?.name),
      industry: industries?.map(item => item.id),
      lastActivity: lastActivityAt,
      lastActivityBy,
      stage: candidateStages?.map(item => item?.name),
      currentJobTitle: currentJobTitle?.map(item => item?.name),
      previousJobTitle: previousJobTitle?.map(item => item?.name),
      tags: contactTags?.map(item => item?.name),
      projectTags: projectTags?.map(item => item?.name)
    };
    setFilterOptions(filterOptionPayload);
    const updateResponse = await ignWorkbenchQueryApi(PUT, filterOptionPayload, `${data.id}`);
    setQueryResponse(updateResponse?.data);
  };

  return (
    <Accordion className='query-accordion-container'>
      <AccordionSummary className='query-accordion-summary' expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body2'>{t('utils.startQuery')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={3}>
            <CustomInputField label={'Name'} defaultValue={data?.name} color={null} onChange={event => setCandidateName(event.target.value)} />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectHeliaTitle
              label={'Project Name'}
              multiple={true}
              isCheckBox={true}
              onChange={selected => setProjectName(selected)}
              defaultValues={data?.candidateProjectName}
              disableCloseOnSelect
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <IndustrySelection
              multiple={true}
              onChange={value => {
                handleProjectIndustryChange(value);
              }}
              defaultValue={projectIndustries}
              selectedList={projectIndustries}
              disableCloseOnSelect
              isCheckBox={true}
              label={'Project Industry'}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <IndustrySelection
              multiple={true}
              onChange={value => {
                handleIndustryChange(value);
              }}
              defaultValue={industries}
              selectedList={industries}
              disableCloseOnSelect
              isCheckBox={true}
              label={'Contact Industry'}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectCompanySelectionDebounce
              label='Current Company Name'
              defaultValues={data?.currentCompanyName}
              onChange={(e, data) => {
                handleCompanyChange(setCurrentCompany, data);
              }}
              className='w-100'
              size='small'
              disableCloseOnSelect={true}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectCompanySelectionDebounce
              label='Previous Company Name'
              defaultValues={data?.previousCompanyName}
              onChange={(e, data) => {
                handleCompanyChange(setPreviousCompany, data);
              }}
              className='w-100'
              size='small'
              disableCloseOnSelect={true}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectJobTitle
              label={'Current Job Title'}
              multiple={true}
              isCheckBox={true}
              onChange={selected => handleJobTitleChange(setCurrentJobTitle, selected)}
              defaultValues={data?.currentJobTitle}
              disableCloseOnSelect
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectJobTitle
              label={'Previous Job Title'}
              multiple={true}
              isCheckBox={true}
              onChange={selected => handleJobTitleChange(setPreviousJobTitle, selected)}
              defaultValues={data?.previousJobTitle}
              disableCloseOnSelect
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTagSelection
              label={'Project Tags'}
              className='w-100'
              size='small'
              optionType={'project'}
              onChange={data => {
                handleTagChange(setProjectTags, data);
              }}
              defaultValues={data?.projectTags}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTagSelection
              label={'Contact Tags'}
              className='w-100'
              size='small'
              optionType={'contact'}
              onChange={data => {
                handleTagChange(setContactTags, data);
              }}
              defaultValues={data?.tags}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiStageSelection
              multiple={true}
              onChange={(e, value) => {
                handleCandidateStages(value);
              }}
              defaultValues={data?.stage}
              disableCloseOnSelect
              isCheckBox={true}
              label={'Candidate Stage'}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <CustomDateRangePicker placeholder={t('Last Activity Date')} onChange={handleDateChange} range={range} setRange={setRange} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <AddedBySelection
              label={'Last Activity By'}
              onChange={data => {
                handleLastActivityByChange(data);
              }}
              defaultValue={data?.lastActivityBy}
              className='w-100'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2' justifyContent='flex-end'>
          <Grid item>
            <CustomButton variant='outlined' buttonText={t('actions.apply')} onClick={handleSaveAndSearch} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

CandidateFilterModal.propTypes = {
  data: PropTypes.object,
  setFilterOptions: PropTypes.func,
  setQueryResponse: PropTypes.func
};
