import EditIcon from '@mui/icons-material/Edit';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toProperCase } from '../../../utils/common';
import { CONSTANTS_SHOW_OFF_LIMIT } from './constant';
import TextBlock from './TextBlog';

export const OffLimitsEntryLogo = ({ severity = '' }) => {
  return (
    <Box
      className={`
      ${severity} ${CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.CONTENT_CONTAINER} ${CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.ICON_CONTAINER} ${severity}`}
    >
      <WarningAmberIcon color='inherit' className={CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.CAUTION_ICON} />
    </Box>
  );
};

OffLimitsEntryLogo.propTypes = {
  severity: PropTypes.string
};

const OffLimitsEntry = ({ companyId, type, severity, endDate, reason, projectName = '', id, showEditIcon, projectId, editIconHandler, companyOnly }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onClickProject = () => {
    projectId && navigate(`/projects/view-project/${projectId}`);
  };

  const isDirect = type === CONSTANTS_SHOW_OFF_LIMIT.DIRECT;

  return (
    <Box p={2} mb={2} className={`${CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.OFF_LIMIT_ENTRY} ${severity}`} id={id} borderRadius={1}>
      <Typography variant='h6' gutterBottom className='d-flex'>
        {isDirect || companyOnly ? t('utils.directOffLimit') : t('utils.inheritedOffLimit')}
        {(showEditIcon || companyOnly) && <EditIcon className={CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.EDIT_OFF_LIMIT_ICON} onClick={editIconHandler} />}
      </Typography>
      <Box className={CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.CONTENT_CONTAINER}>
        <OffLimitsEntryLogo severity={severity} />
        <Box className='flex-1 d-flex flex-column gap-10'>
          <Box className='d-flex'>
            <TextBlock title={t('utils.duration')} value={endDate ? `${new Date(endDate).toLocaleDateString()}` : t('utils.indefinite')} className='flex-1' />
            <TextBlock title={t('utils.severity')} value={toProperCase(severity)} className='flex-1' />
          </Box>
          <TextBlock
            title={t('utils.offLimitsReason')}
            value={(type === CONSTANTS_SHOW_OFF_LIMIT.INHERITED && !projectId) || companyId ? `Current Company marked as off-limits: ${reason}` : reason}
            html
          />
          {type === CONSTANTS_SHOW_OFF_LIMIT.INHERITED && projectId && <TextBlock onClick={onClickProject} title={t('grid.projectName')} value={projectName} underline />}
        </Box>
      </Box>
    </Box>
  );
};

OffLimitsEntry.propTypes = {
  type: PropTypes.string,
  severity: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reason: PropTypes.string,
  projectName: PropTypes.string,
  id: PropTypes.string,
  showEditIcon: PropTypes.bool,
  companyOnly: PropTypes.bool,
  projectId: PropTypes.string,
  companyId: PropTypes.string,
  editIconHandler: PropTypes.func
};

export default OffLimitsEntry;
