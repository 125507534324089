import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createFilterOptions } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import { ignContactDataApi } from '../../../../services/ApiService';
import { ERROR, POST, SUCCESS, VALIDATION_MESSAGE } from '../../../../services/constantService';
import { customFormValidator, requireValidMessage, splitLastIfMultiple } from '../../../../utils/common';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { CreateSchemaQuickAddContact, createRequiredFieldQuickAddContact } from '../../../AddContact/utils';
import { validateEmail } from '../../../MessageTemplatePopup/utils';
import CustomButton from '../../CustomButton';
import CustomDropdown from '../../CustomDropdown';
import QuickAddContact from '../../QuickAddContact/QuickAddContact';

const ContactTypeSelection = props => {
  const {
    value,
    name = '',
    onChange = () => {},
    disabled = false,
    selectedList,
    placeholder,
    label = '',
    contactId = '',
    getContactDetails = () => {},
    companyId = '',
    setIsEditingContact = () => {},
    ...rest
  } = props;

  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const [contactName, setContactName] = useState('');
  const { translateMessage } = useCustomMessageHook();

  const contactData = useSelector(state => state.commonReducer.contact);
  const dispatch = useDispatch();
  const filter = createFilterOptions();

  const createContact = async (allowDuplicate, getValues) => {
    const contact = getValues;
    let isValid = await CreateSchemaQuickAddContact.isValid(contact);

    if (!isValid) {
      let dirtyField = customFormValidator(contact, createRequiredFieldQuickAddContact);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    if (
      (!contact.contact_emails || contact.contact_emails.length === 0) &&
      (!contact.linkedin_url || contact.linkedin_url.trim() === '') &&
      (!contact.contact_phones || contact.contact_phones.length === 0)
    ) {
      const message = translateMessage('AtLeastOneFieldRequired', false, ...splitLastIfMultiple(['Email', 'LinkedIn URL,', 'Phone Number']));
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    let validateEmailSyntax = contact.contact_emails.length > 0 ? validateEmail(contact.contact_emails.map(emailObj => emailObj.email)) : true;
    if (!validateEmailSyntax) {
      const message = translateMessage('ValidFiled', false, 'Email');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (companyId) {
      contact.current_company_id = companyId;
    }

    const updatedEmail = contact.contact_emails.map(emailObj => ({
      ...emailObj,
      email_type: emailObj.email_type?.field_value
    }));
    const updatedPhone = contact.contact_phones.map(phoneObj => ({
      ...phoneObj,
      phone_type: phoneObj.phone_type?.field_value
    }));
    const updatedContact = { ...contact, contact_emails: updatedEmail, contact_phones: updatedPhone };
    const final = updatedContact;
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    const { status, data } = await ignContactDataApi(POST, '', final, sub_route);
    if (status === 201) {
      setOpenQuickAddContact(false);
      if (companyId) {
        setIsEditingContact(true);
        getContactDetails();
      }

      dispatch(fetchPickListData('contact?name=', 'contact'));
      const message = translateMessage('Successfully', false, 'Contact', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else if (status === 500) {
      if (data.reason === VALIDATION_MESSAGE) {
        enqueueSnackbar(`${data.reason}`, { variant: ERROR });
      } else {
        enqueueSnackbar(`${data.message}`, { variant: ERROR });
      }
    } else {
      const message = translateMessage('Successfully', false, 'Contact', 'create');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  useEffect(() => {
    if (!contactData?.data) {
      dispatch(fetchPickListData('contact?name=', 'contact'));
    } else {
      const filteredOptions = contactData?.data.filter(item => item.id !== contactId);
      setOptions(filteredOptions);
    }
  }, [contactData?.data, dispatch]);

  useEffect(() => {
    const contactItem = async () => {
      if (contactData?.data) {
        if (selectedList?.length > 0) {
          const filterData = contactData?.data?.filter(item => selectedList?.every(listItem => listItem?.relationType?.name !== item?.name));
          setOptions(filterData);
        } else {
          setOptions(contactData?.data);
        }
      }
    };
    contactItem();
  }, [selectedList]);
  const handleClose = () => {
    setOpenQuickAddContact(false);
  };
  return (
    <>
      <QuickAddContact openQuickAddContact={openQuickAddContact} handleClose={handleClose} first_name={contactName} createContact={createContact} setContactName={setContactName} />
      <CustomDropdown
        {...rest}
        value={value}
        options={options}
        name={name}
        onInputChange={(e, val, reason) => {
          if (reason === 'input') {
            setContactName(val || '');
          }
        }}
        onChange={(event, newValue) => {
          if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue?.length && newValue[newValue?.length - 1].inputValue))) {
            setOpenQuickAddContact(true);
            setContactName(newValue.inputValue || newValue[newValue?.length - 1].inputValue);
            return;
          }
          onChange(event, newValue);
        }}
        disabled={disabled}
        placeholder={placeholder}
        label={label}
        addAnotherButton={true}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue) {
            filtered.push({
              inputValue: params.inputValue
            });
          }
          return filtered;
        }}
        noOptionsText={
          <CustomButton
            variant='text'
            onClick={() => {
              setOpenQuickAddContact(true);
            }}
            type={'tertiary'}
            size={'small'}
            buttonText={t('contacts.addContact.addNewContact')}
            iconLeft={
              <AddCircleIcon
                sx={{
                  width: '2vw',
                  height: '2vh',
                  paddingRight: 1
                }}
              />
            }
          />
        }
      />
    </>
  );
};
ContactTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  contactId: PropTypes.string,
  companyId: PropTypes.string,
  getContactDetails: PropTypes.func,
  setIsEditingContact: PropTypes.func
};
export default ContactTypeSelection;
