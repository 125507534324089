const checkPermissionType = (arr = [], permissionType = '') => {
  const permissionArr = permissionType.split(' ');
  if (!arr.length) return false;
  if (!permissionArr.length) return true;
  return permissionArr.every(item => arr.includes(item));
};

const validateComponentPermission = (initialState = {}, parent = '', path = '', permissionType = '', root) => {
  try {
    if (root) {
      return initialState?.menuItems?.find(item => item === parent);
    }
    if (!parent) return false;
    const parentItem = initialState?.items[parent];
    if (!parentItem) return false;
    if (!path) {
      return checkPermissionType(parentItem.permissions, permissionType);
    }

    const childItem = parentItem.childPages.find(item => item.resource_name === path);
    if (!childItem) return false;
    return checkPermissionType(childItem.permissions, permissionType);
  } catch (e) {
    console.log(e);
    return false;
  }
};
module.exports = {
  validateComponentPermission
};
