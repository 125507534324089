//----------------------------------------------// In-built Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { globalSearchSelector } from '../../selectors';
import { updateGlobalSearchData } from '../../store/globalSearchSlice';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    width: '100%'
  },
  inputRoot: {
    color: 'gray',
    fontSize: 14
  },
  clearIndicator: {
    color: 'white'
  },
  popper: {
    width: 'auto !important',
    maxWidth: '500px'
  }
}));

export default function MasterSearchSelection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { label = '', placeholder = 'Search', ...rest } = props;
  const { loading: isLoading, search, refetch } = useSelector(globalSearchSelector);

  const navigate = useNavigate();

  const { selectedTabId, search: searchValue } = useParams();

  const onKeyDown = event => {
    if (event?.key === 'Enter') {
      handleGlobalSearch();
    }
  };

  const onInputChange = event => {
    dispatch(updateGlobalSearchData({ key: 'search', data: event?.target.value }));
  };

  const handleGlobalSearch = () => {
    const tab = selectedTabId ? selectedTabId : 'all';

    if (!search) {
      navigate(`/global-search/${tab}`);
      return;
    }

    if (selectedTabId === 'all') {
      dispatch(updateGlobalSearchData({ key: 'data', data: [] }));
      dispatch(updateGlobalSearchData({ key: 'refetch', data: refetch + 1 }));
    }
    navigate(`/global-search/${tab}/${search}`);
  };

  useEffect(() => {
    dispatch(updateGlobalSearchData({ key: 'search', data: searchValue }));
  }, []);

  return (
    <div className='position-relative header-search'>
      <TextField
        inputProps={{
          maxLength: 31
        }}
        {...rest}
        onKeyDown={onKeyDown}
        onChange={onInputChange}
        value={search}
        placeholder={placeholder}
        InputProps={{
          ...rest.InputProps,
          disableUnderline: true,
          endAdornment: (
            <React.Fragment>{isLoading ? <CircularProgress color='inherit' size={20} /> : <SearchIcon sx={{ cursor: 'pointer' }} fontSize={'large'} onClick={handleGlobalSearch} />}</React.Fragment>
          ),
          classes: {
            root: classes.inputRoot
          }
        }}
        sx={{ paddingX: '0.5rem' }}
        variant='standard'
        label={label}
        fullWidth
      />
    </div>
  );
}

MasterSearchSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool
};
