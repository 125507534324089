import { addDays, endOfDay, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import * as locales from 'rsuite/locales';
import './index.scss';

const data = Object.keys(locales).map(key => ({
  key: key.replace(/([a-z]{2})([A-Z]{2})/, '$1-$2'),
  value: locales[key]
}));

const CustomDateRangePicker = ({
  placeholder,
  onChange,
  range,
  setRange,
  style = {
    width: '255px'
  }
}) => {
  const locale = data.find(item => item.key === (navigator?.language || 'en-US'));

  const handleSelectRange = value => {
    setRange(value);
    onChange?.(value);
  };

  const { t } = useTranslation();

  const Ranges = [
    {
      label: t('common.today'),
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: t('common.yesterday'),
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
    },
    {
      label: t('common.this_week'),
      value: [startOfWeek(new Date(), { weekStartsOn: 0 }), endOfDay(new Date())] // Start of the week (Sunday)
    },
    {
      label: t('common.last_week'),
      value: [startOfWeek(subDays(new Date(), 7), { weekStartsOn: 0 }), endOfWeek(subDays(new Date(), 7), { weekStartsOn: 0 })]
    },
    {
      label: t('common.this_month'),
      value: [startOfMonth(new Date()), endOfDay(new Date())]
    },
    {
      label: t('common.this_year'),
      value: [startOfYear(new Date()), endOfDay(new Date())]
    },
    {
      label: t('common.last_year'),
      value: [startOfYear(subDays(new Date(), 365)), endOfYear(subDays(new Date(), 365))]
    }
  ];

  return (
    <CustomProvider locale={locale?.value}>
      <DateRangePicker style={style} placeholder={placeholder} character=' - ' format='dd/MM/yyyy' ranges={Ranges} value={range} onChange={handleSelectRange} showHeader={false} />
    </CustomProvider>
  );
};

CustomDateRangePicker.propTypes = {
  onChange: PropTypes.func,
  range: PropTypes.array,
  setRange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object
};

export default CustomDateRangePicker;
