//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { SUCCESS_STATUS_CODE } from '../../../../../Containers/Commons/Utils';
import { useTranslation } from 'react-i18next';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
//----------------------------------------------// Internal Imports // -------------------------------------------------
const ActiveProjects = ({ rowData = [] }) => {
  const { t } = useTranslation();
  const [rowCount, setRowCount] = useState(0);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setRows(rowData);
    setRowCount(rowData?.length || 0);
  }, [rowData]);

  // !! Note from Arun: This API is failing since we don't have project_id in the payload *Optimization_Pointer*
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // setLoading(true);

  //       const { status, data } = await ProjectCandidateApi(
  //         GET,
  //         '/all',
  //         {},
  //         {
  //           contactId: contactId || id,
  //           page: 1,
  //           limit: 50
  //         }
  //       );
  //       if (status === SUCCESS_STATUS_CODE) {
  //         setRows(data?.rows || []);
  //         setRowCount(data?.rows?.length || 0);
  //         // setLoading(false);
  //         if (data?.length === 0) {
  //           setRowCount(0);
  //         }
  //       } else {
  //         // setLoading(false);
  //         throw new Error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR);
  //       }
  //     } catch (error) {
  //       console.error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, error);
  //       // Handle error
  //     }
  //   };

  //   fetchData();
  // }, [id]);

  const getProjects = params => {
    return (
      <Box>
        {
          <Link to={`/projects/view-project/${params?.data?.project?.id}`} target='_blank' className='header-content'>
            <Box className='fs-14 details-width cursor-pointer'>{params?.data?.project?.job_number}</Box>
          </Link>
        }
      </Box>
    );
  };
  const columnDefs = useMemo(
    () => [
      {
        field: 'job_number',
        headerName: t('grid.projectId'),
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        filter: false,
        floatingFilter: false
      },
      {
        field: 'helia_title',
        headerName: t('grid.projectTitle'),
        // cellRenderer: "getProjects",
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        // cellClass: "text-style"
        filter: false,
        floatingFilter: false
      },
      {
        field: 'ign_companies.name',
        headerName: 'Company Name',
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        // cellClass: "text-style"
        filter: false,
        floatingFilter: false
      },
      {
        field: 'stage',
        headerName: 'Stage',
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        filter: false,
        floatingFilter: false
      }
    ],
    []
  );

  return (
    <Stack
      className={'custom-scrollbar'}
      sx={{
        height: '100%'
      }}
    >
      <Box className='justify-content-between py-3'>
        <Box className='mb-1 mr-3 float-right'>{rowCount}</Box>
      </Box>
      <Box className='ag-theme-alpine text-center ml-3 mr-3 mb-3'>
        <AgGridWrapper
          rowData={rows}
          columnDefs={columnDefs}
          components={{
            getProjects: getProjects
          }}
          saveGridSetting={false}
          rowGroupPanelShow='always'
          onCellClicked={params => navigate(`/projects/view-project/${params.data.id}`)}
          showSidebar={false}
          pagination={false}
        />
      </Box>
    </Stack>
  );
};

ActiveProjects.propTypes = {
  rowData: PropTypes.array
};
export default ActiveProjects;
