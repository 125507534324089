//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import { saveAs } from "file-saver";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { ExpandableSection } from '../../components/ActivityLog/Components/ExpandableSection';
import Loader from '../../components/common/Loader';
import { BDDataApi, invoiceSummaryApiIGN } from '../../services/ApiService';
import { ERROR, GET, PATCH, SUCCESS, WARNING } from '../../services/constantService';
import {
  // VALIDATION_MESSAGE,
  notFoundMessage
} from '../../services/MessageService';
import AgGridWrapper from '../../utils/AgGridWrapper';
import ColumnFilter from '../Commons/ColumnFilter';
import CustomFilter from '../Commons/CustomFilter';
import { DateRenderer, SUCCESS_STATUS_CODE } from '../Commons/Utils';
import { PAGE_LIMIT } from '../Contacts/utils';
import ConfirmProjectedDatePopup from '../Searches/ViewProject/InvoiceInfo/Component/ConfirmProjectedDatePopup';
import './index.scss';
import InvoiceQuery from './invoiceQuery';
import { columnDefs, getQueryParamsStringForInvoice } from './utils';

let gridApi, columnApi;
export default function InvoiceSummary(props) {
  const { type, title } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showQuery, setShowQuery] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [active, setActive] = useState(false);
  const [uiSelector, setUiSelector] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [filterModel, setFilterModel] = useState({});
  const [isQuerySelector, setIsQuerySelector] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [queryStatus, setQueryStatus] = useState(1);
  const [gridLoading, setGridLoading] = useState(false);
  const [isSectionOpen, setIsSectionOpen] = useState(true); // New state for managing open/close
  const [projectedData, setProjectedData] = useState(null);
  const [isProjectedDateSelected, setIsProjectedDateSelected] = useState(false);
  const { watch, register, setValue, getValues } = useForm();
  let queryData = useRef();
  const gridRef = useRef();
  let isClassicQuery = useRef();

  useEffect(() => {
    register('project_invoice_info');
    register('collected_percentage');
    register('total_invoice_amt');
    register('total_collected_amt');
    register('total_invoice_expense');
    setValue('project_invoice_info', gridData);
    // setValue('bd_currency_values', currentValues['bd_currency_values']);
    register('bd_currency_values');
  }, [register, gridData, setValue]);
  // useEffect(() => {
  //   return () => {
  //     const columnApis = columnApi;
  //     saveColumnStateToLocalStorage(
  //       type ? `${type}invoiceProject` : "invoiceProject",
  //       { columnApis }
  //     );
  //   };
  // }, []);

  // eslint-disable-next-line no-unused-vars
  let isSelectedFromList = false;

  const defaultColumns = ['name', 'freeze_dropdown'];

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    gridRef.current = params.api;
    // loadColumnStateFromLocalStorage(
    //   type ? `${type}invoiceProject` : "invoiceProject",
    //   { columnApi, gridApi }
    // );
  };

  const resetFilter = async () => {
    gridRef.current?.setFilterModel(null);
    // const customFilterField = columnApi.columnController.columnDefs;
    // customFilterField.map(item => {
    //   if (item?.filter === 'CustomFilter') {
    //     gridApi.destroyFilter(item.field);
    //   }
    // });
  };

  const resetSort = async () => {
    console.log('gridApi=>>', gridApi);

    // gridApi.setSortModel(null);
    gridRef.current?.applyColumnState({
      state: gridRef.current?.getColumnState().map(col => ({ ...col, sort: null }))
    });
  };

  const prepareInvoiceInfoPayload = (formData, index, obj) => {
    let invoiceObj = null;
    if (formData && formData.project_invoice_info && formData.project_invoice_info.length > index) {
      invoiceObj = formData.project_invoice_info[index];
    }

    if (!obj && invoiceObj) {
      invoiceObj.billed_date = invoiceObj.billed_date || null;
      invoiceObj.due_by_date = invoiceObj.due_by_date || null;
      invoiceObj.collected_date = invoiceObj.collected_date || null;
    }
    return {
      id: projectedData?.data?.project_id,
      total_invoice_amt: formData?.total_invoice_amt || 0,
      total_collected_amt: formData?.total_collected_amt || 0,
      collected_percentage: formData?.collected_percentage || 0,
      invoice_info_obj: obj ? obj : invoiceObj,
      total_invoice_expense: formData?.total_invoice_expense || 0,
      updated_at: projectedData?.data?.updated_at
    };
  };
  const updateField = async (index, message, updateType, deletedInvoice) => {
    let formData = getValues();
    formData = prepareInvoiceInfoPayload(formData, index, deletedInvoice);
    formData.updateType = updateType;
    setIsLoading(true);
    const { status, data } = await BDDataApi(PATCH, projectedData?.data?.project_id, formData, 'update-invoice-info');
    if (status === 200) {
      setIsLoading(false);
      enqueueSnackbar(`Invoice Info ${updateType} Successfully`, { variant: SUCCESS });
      if (['update', 'create'].includes(updateType)) {
        setIsLoading(true);
        const { data: res } = await BDDataApi(GET, projectedData?.data?.project_id, null, null, null);
        setIsLoading(false);
        // setCurrentData(prevState => {
        //   const projectInvoiceInfo = res?.project_invoice_info || [];
        //   const updatedInvoiceInfo = projectInvoiceInfo.map(inv => (inv?.invoice_id === data?.id?.invoice_id ? { ...inv, ...data } : inv));

        //   return {
        //     ...prevState,
        //     project_invoice_info: updatedInvoiceInfo
        //   };
        // });
      }
      return true;
    }
    if (status === 500) {
      //setRecordData(data)
      //setIsConfirmationPopup(true)
      setIsLoading(false);
      enqueueSnackbar(`${data.message}:${data.reason}`, { variant: ERROR });
    }
  };

  // const saveColumnsState = async () => {
  //   try {
  //     gridApi && gridApi.showLoadingOverlay();
  //     const columnApis = columnApi;
  //     const gridApis = gridApi;
  //     await saveColumnStateToLocalStorage(
  //       type ? `${type}invoiceProject` : "invoiceProject",
  //       { columnApi: columnApis, gridApi: gridApis }
  //     );
  //     gridApi && gridApi.hideOverlay();
  //   } catch (e) {
  //     console.log("Error found in saveColumnsState::", e);
  //   }
  // };

  // const saveColumnsStateForFilter = async () => {
  //   try {
  //     gridApi && gridApi.showLoadingOverlay();
  //     const columnApis = columnApi;
  //     const gridApis = gridApi;
  //     await saveColumnStateToLocalStorage(
  //       type ? `${type}invoiceProject` : "invoiceProject",
  //       { columnApi: columnApis, gridApi: gridApis },
  //       true,
  //       false
  //     );
  //     gridApi && gridApi.hideOverlay();
  //   } catch (e) {
  //     console.log("Error found in saveColumnsStateForFilter::", e);
  //   }
  // };

  // const saveColumnsStateForSort = async () => {
  //   try {
  //     gridApi && gridApi.showLoadingOverlay();
  //     const columnApis = columnApi;
  //     const gridApis = gridApi;
  //     await saveColumnStateToLocalStorage(
  //       type ? `${type}invoiceProject` : "invoiceProject",
  //       { columnApi: columnApis, gridApi: gridApis },
  //       false,
  //       true
  //     );
  //     gridApi && gridApi.hideOverlay();
  //   } catch (e) {
  //     console.log("Error found in saveColumnsState::", e);
  //   }
  // };

  // const dataSource = {
  //   getRows: async params => {
  //     if (params.filterModel) {
  //       setFilterModel(params.filterModel);
  //     }
  //     if (params.filterModel && Object.keys(params.filterModel)?.length > 0) {
  //       gridApi.deselectAll();
  //     }
  //     const { filterModel, sortModel } = params;
  //     setShowFilterCount(Object.keys(filterModel)?.length);
  //     let paginationString;
  //     paginationString = `getAllInvoiceSummary?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
  //     gridApi.showLoadingOverlay();
  //     if (sortModel?.length > 0) {
  //       paginationString = paginationString.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
  //     }
  //     if (isClassicQuery.current) {
  //       const paramsStringData = getQueryParamsStringForInvoice(queryData.current);
  //       paginationString = paginationString.concat(paramsStringData);
  //     }

  //     if (Object.keys(filterModel)?.length) {
  //       paginationString = paginationString.concat(getFilterParamStringForInvoiceSummery(filterModel));
  //     }
  //     if (type) paginationString = paginationString.concat(`&&invoiceStatus=${type}`);
  //     const { status, data } = await invoiceSummaryApiIGN(GET, null, null, paginationString);
  //     if (status === SUCCESS_STATUS_CODE) {
  //       if (data?.paging) {
  //         if (data?.paging?.totalCount === 0) {
  //           const message = notFoundMessage('records');
  //           enqueueSnackbar(message, { variant: WARNING });
  //         }
  //         params.successCallback(data?.data, data?.paging?.totalCount);
  //         setRowCount(data?.paging?.totalCount);
  //       } else if (Object.keys(data)?.length) {
  //         params.successCallback([data], 1);
  //       }
  //       isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
  //     } else {
  //       params.failCallback();
  //     }

  //     gridApi.hideOverlay();
  //     setIsLoading(false);
  //   }
  // };

  const getGridData = async params => {
    let paginationString;
    paginationString = `getAllInvoiceSummary?limit=${PAGE_LIMIT}&page=${1}`;

    if (isClassicQuery.current) {
      const paramsStringData = getQueryParamsStringForInvoice(queryData.current);
      console.log('paramsStringData', paramsStringData);
      paginationString = paginationString.concat(paramsStringData);
    }
    // paginationString = paginationString.concat(getFilterParamStringForInvoiceSummery(filterModel));

    if (type) paginationString = paginationString.concat(`&&invoiceStatus=${type}`);
    setIsLoading(true);
    setGridLoading(true);
    const { status, data } = await invoiceSummaryApiIGN(GET, null, null, paginationString);
    if (status === SUCCESS_STATUS_CODE) {
      if (data?.paging) {
        if (data?.paging?.totalCount === 0) {
          const message = notFoundMessage('records');
          enqueueSnackbar(message, { variant: WARNING });
        }
        setGridData(data?.data || []);
        // params.successCallback(data?.data, data?.paging?.totalCount);
        setRowCount(data?.paging?.totalCount);
      } else if (Object.keys(data)?.length) {
        setGridData(data?.data || []);
        // params.successCallback([data], 1);
      }
      isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
    } else {
      // params.failCallback();
    }

    // gridApi.hideOverlay();
    setGridLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    console.log('gridApi', gridApi);

    getGridData(gridApi);
  }, [gridApi, queryStatus]);

  const CustomLoadingOverlayComponent = () => {
    return <CircularProgress />;
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };

  const setSelectAllRows = isRowsSelected => {
    setIsAllRowsSelected(isRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  // const exportCallback = async () => {
  //   const selectedRows = gridApi.getSelectedRows();
  //   if (selectedRows?.length > 0) {
  //     let sub_route = `export-as-excel?`;
  //     if (type && type !== "")
  //       sub_route = `${sub_route}&invoiceStatus=${type ? type : ""}`;
  //     if (isClassicQuery.current) {
  //       const paramsString = getQueryParamsStringForInvoice(queryData.current);
  //       sub_route = `${sub_route}${paramsString}&isClassicQuery=${isClassicQuery}`;
  //     }
  //     if (filterModel && Object.keys(filterModel)?.length) {
  //       sub_route = sub_route.concat(
  //         getFilterParamStringForInvoiceSummery(filterModel, showQuery)
  //       );
  //       sub_route = `${sub_route}&filter=true`;
  //     }
  //     let id = [];
  //     if (!isAllRowsSelected) {
  //       selectedRows.map((data) => id.push(data.id));
  //     }
  //     const columnHeaders = columnApi
  //       .getAllDisplayedColumns()
  //       .map((column) => column.getColDef().headerName);
  //     gridApi.showLoadingOverlay();
  //     const payload = {
  //       headers: {
  //         columnHeaders: columnHeaders,
  //         selectedRows: { id: id },
  //         type: type ? type : "Invoice Summary",
  //         allRowSelected: isAllRowsSelected,
  //         limit: 1000,
  //       },
  //     };
  //     let { status, data, headers } = await invoiceSummaryApi(
  //       POST,
  //       "",
  //       payload,
  //       sub_route,
  //       BLOB
  //     );
  //     if (status === 200) {
  //       let fileName = "InvoiceSummary.xlsx";
  //       let fileNameHeader = headers["content-disposition"].split('"');
  //       if (fileNameHeader && fileNameHeader?.length > 2) {
  //         fileName = fileNameHeader[1];
  //       }
  //       saveAs(new File([data], fileName));
  //     } else {
  //       enqueueSnackbar(VALIDATION_MESSAGE.export_fail, { variant: ERROR });
  //     }
  //     gridApi.hideOverlay();
  //   } else {
  //     enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
  //       variant: ERROR,
  //     });
  //   }
  // };

  const IdRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.ProjectInvoiceInfo?.id}/Billing/Invoice%20Info`
        }}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Typography style={{ padding: '12px' }} className='text-link' color='primary'>
          {params?.data?.ProjectInvoiceInfo?.job_number}
        </Typography>
      </Link>
    );
  };

  const TitleRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.project_id}/Billing/Invoice%20Info`
        }}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Typography style={{ padding: '12px' }} className='text-link' color='primary'>
          {params?.data?.ProjectInvoiceInfo?.job_title}
        </Typography>
      </Link>
    );
  };

  const ProjectedDateRenderer = params => {
    return (
      // <Link
      //   to={{
      //     pathname: `/projects/${params?.data?.project_id}/invoice-info`
      //   }}
      //   target='_blank'
      //   rel='noopener noreferrer'
      // >
      <Typography
        className='text-link'
        color='primary'
        style={{ padding: '12px' }}
        onClick={() => {
          const projectData = {
            ...params,
            rowIndex: params?.node?.rowIndex
          };
          setIsProjectedDateSelected(true);
          setProjectedData(projectData);
        }}
      >
        {DateRenderer(params)}
      </Typography>
      // </Link>
    );
  };

  const showQueryState = async value => {
    await resetSort();
    await resetFilter();
    gridApi.onFilterChanged();
    isSelectedFromList = false;
    setShowQuery(value);
    setMinimize(!value);
    localStorage.setItem('invoiceProject', value);
  };

  const handleQuery = data => {
    setIsSectionOpen(false);
    if (isAllRowsSelected === true) {
      setSelectAllRows(false);
      setIsAllRowsSelected(true);
    } else {
      gridApi.forEachNode(node => {
        node.setSelected(false);
      });
    }
    localStorage.removeItem('invoiceSummary');
    setQueryStatus(prev => prev + 1);
    queryData.current = data;
    gridApi.onFilterChanged();
  };

  const classicQuery = queryData => {
    console.log('called=>>', queryData);

    isClassicQuery.current = true;
    handleQuery(queryData);
  };

  const pullData = () => {
    if (uiSelector === false && isQuerySelector === false) {
      setUiSelector(true);
      setIsQuerySelector(true);
    } else if (uiSelector === true && isQuerySelector === true) {
      setUiSelector(false);
      setIsQuerySelector(false);
    }
  };

  const handleQueryClose = async () => {
    await resetSort();
    await resetFilter();
    isClassicQuery.current = false;
    setShowQuery(false);
    queryData.current = {};
    gridApi.onFilterChanged();
    if (uiSelector === true) {
      setIsQuerySelector(true);
    } else {
      setIsQuerySelector(false);
    }
    setQueryStatus(0);
    setIsSectionOpen(true);
    localStorage.removeItem(type ? `${type}invoiceProject` : 'invoiceProject');
  };

  useEffect(() => {
    if (type === 'billedInvoice' || type === 'nextTenDays' || type === 'yetToBeBilled' || type === 'overdue' || type === 'readyToBilled') setActive(false);
    else setActive(true);
  }, [type]);

  const handleProjectedDateClose = () => {
    setIsProjectedDateSelected(false);
  };

  return (
    <div className='d-flex flex-column w-100 h-100'>
      {isProjectedDateSelected && (
        <ConfirmProjectedDatePopup
          projectedData={projectedData}
          watch={watch}
          invoiceList={gridData}
          setValue={setValue}
          onClose={handleProjectedDateClose}
          setInvoiceList={setGridData}
          updateField={updateField}
        />
      )}
      <Loader show={isLoading} />
      <Helmet>
        <title>{title ? title : 'Invoice Summary'}</title>
      </Helmet>
      <div className='d-flex justify-content-between align-items-end position-sticky search-header p-3 pl-4 border-bottom-0'>
        <Typography className='job-title header-style'>Invoice Summary</Typography>
      </div>

      <div className='d-flex pl-4 pb-3 align-items-center'>
        <Link to='/invoice-summary' className='action-button pr-2'>
          <Button className={active ? 'active-button' : ''} variant='outlined' color='primary'>
            All Invoices
          </Button>
        </Link>
        <Link to='/invoice-summary/billed-invoice' className='action-button pr-2'>
          <Button variant='outlined' className={type === 'billedInvoice' ? 'active-button' : ''} color='primary'>
            Billed Invoices
          </Button>
        </Link>
        <Link to='/invoice-summary/yet-to-be-billed' className='action-button pr-2'>
          <Button className={type === 'yetToBeBilled' ? 'active-button' : ''} variant='outlined' color='primary'>
            Yet to be Billed
          </Button>
        </Link>
        <Link to='/invoice-summary/next-ten-days' className='action-button pr-2'>
          <Button className={type === 'nextTenDays' ? 'active-button' : ''} variant='outlined' color='primary'>
            Approaching in 10 days
          </Button>
        </Link>

        <Link to='/invoice-summary/overdue' className='action-button pr-2'>
          <Button className={type === 'overdue' ? 'active-button' : ''} variant='outlined' color='primary'>
            OverDue
          </Button>
        </Link>
        <Link to='/invoice-summary/ready-to-bill' className='action-button'>
          <Button className={type === 'readyToBilled' ? 'active-button' : ''} variant='outlined' color='primary'>
            Ready to Bill
          </Button>
        </Link>
      </div>

      {showQuery ? (
        <div className='d-flex query-toolbar d-flex p-0 m-4'>
          <ExpandableSection title='Filter By' defaultOpen={isSectionOpen} isTeamInfo={true} accordionClassName='accordion' titleClassName='title-text'>
            <InvoiceQuery type={type} handleQuery={classicQuery} pullData={pullData} />
          </ExpandableSection>
          <div className=''>
            <span
              style={{
                display: minimize ? 'inline' : 'none',
                padding: 12
              }}
              className='cursor-pointer d-flex'
              onClick={handleQueryClose}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
      ) : (
        <div className={'toolbar p-4 mt-2'}>
          <Button className={'start-query ml-0'} variant={'outlined'} color={'primary'} onClick={() => showQueryState(true)}>
            Start Query
          </Button>
        </div>
      )}

      <div className='d-flex align-items-center justify-content-end'>
        {/* <div className='action-container' style={{ minWidth: '0px' }} onClick={() => resetFilter()}>
          <span className='action-text'>Reset Filter</span>
        </div>

        <div className='action-container' style={{ minWidth: '0px' }} onClick={() => resetSort()}>
          <span className='action-text'>Reset Sort</span>
        </div> */}
        {/* <Button
          disabled={!selectedRowsCount}
          className='mr-3'
          variant='outlined'
          color='primary'
          // onClick={exportCallback}
        >
          Export List
        </Button> */}
        {/* <Typography className='mr-3'>Total count: {rowCount}</Typography> */}
      </div>
      <div className='list-view flex-grow-1'>
        {columnApi && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} filterModel={filterModel} showFilterCount={showFilterCount} />}
        <div id='myGrid' className='ag-theme-alpine pl-4'>
          <AgGridWrapper
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            suppressHorizontalScroll={false}
            rowData={gridData}
            components={{
              HeaderCheckbox: HeaderCheckbox,
              IdRenderer: IdRenderer,
              TitleRenderer: TitleRenderer,
              ProjectedDateRenderer: ProjectedDateRenderer,
              CustomLoadingOverlayComponent,
              DateRenderer,
              CustomFilter
            }}
            // datasource={dataSource}
            rowModelType={'clientSide'}
            rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            scrollbarWidth={12}
            getRowNodeId={data => data.id}
            columnDefs={columnDefs(enqueueSnackbar, setIsLoading)}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            // onDisplayedColumnsChanged={saveColumnsState}
            // onDragStopped={saveColumnsState}
            // onSortChanged={saveColumnsStateForSort}
            // onFilterChanged={saveColumnsStateForFilter}
            paginationPageSize={PAGE_LIMIT}
            cacheBlockSize={PAGE_LIMIT}
            onRowSelected={params => {
              const rowCount = params?.api?.getSelectedRows()?.length;
              console.log('params.getSelectedRows', params.api.getSelectedRows());
              setSelectedRowsCount(rowCount);
            }}
            loading={gridLoading}
            rowGroupPanelShow='always'
          />
        </div>
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

InvoiceSummary.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string
};
