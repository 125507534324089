import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../store/commonSlice';
import { clarityTracker, shouldTrackOnClarity } from '../clarityJs';

const UseCurrentUserHook = () => {
  const dispatch = useDispatch();

  const updateUserState = useCallback(
    data => {
      const isAdmin = data?.roles?.includes('admin') || data?.roles?.includes('finance');

      localStorage.setItem('isAdmin', isAdmin);

      if (shouldTrackOnClarity()) {
        clarityTracker.identify(data.email);
        clarityTracker.setTag('email', data.email);
        clarityTracker.setTag('firstName', data.first_name);
        clarityTracker.setTag('lastName', data.last_name);
      }

      dispatch(
        commonActions.updateUserData({
          ...data,
          isAdmin
        })
      );
    },
    [dispatch]
  );

  return { updateUserState };
};

export default UseCurrentUserHook;
