import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGrid } from './FormGrid';
import CountryTypeSelection from '../CountryTypeSelection';
import { useTranslation } from 'react-i18next';
function AddContactDrawerSectionTwelve(props) {
  const { setValue, watch, nationalityList, loadingDropdownData, getDropdownData } = props;
  const { t } = useTranslation();
  const columns = [
    {
      id: 'birth_date',
      label: 'addContactDrawer.dateOfBirth',
      type: 'date',
      size: 'small',
      required: false,
      error: false,
      value: watch('birth_date'),
      onChange: e => {
        setValue('birth_date', e);
      },
      xs: 3.8
    },
    {
      id: 'gender',
      label: 'addContactDrawer.gender',
      type: 'text',
      size: 'small',
      required: false,
      error: false,
      value: watch('gender'),
      onChange: e => setValue('gender', e.target.value),
      xs: 3.8
    },
    {
      id: 'nationality',
      label: 'addContactDrawer.nationality',
      type: 'custom',
      size: 'small',
      required: false,
      error: false,
      value: watch('nationality'),
      onChange: (e, v) => setValue('nationality', v),
      xs: 3.8,
      tooltipTitle: t('addContactDrawer.pleaseTypeToSearch'),
      customComponent: () => (
        <CountryTypeSelection
          onChange={(e, option) => {
            setValue('nationality', option);
          }}
          label={t('addContactDrawer.nationality')}
          value={watch('nationality') || null}
          required={false}
          size={'small'}
          fullWidth={true}
        />
      )
    },
    {
      id: 'additional_last_name',
      label: 'addContactDrawer.additionalLastName',
      type: 'text',
      size: 'small',
      required: false,
      error: false,
      value: watch('additional_last_name'),
      onChange: e => setValue('additional_last_name', e.target.value),
      xs: 3
    },
    {
      id: 'pronouns',
      label: 'addContactDrawer.pronouns',
      type: 'text',
      size: 'small',
      required: false,
      error: false,
      value: watch('pronouns'),
      onChange: e => setValue('pronouns', e.target.value),
      xs: 8.8
    }
  ];
  return (
    <Grid container px={1} pb={0}>
      <Grid item xs={12}>
        <FormGrid columns={columns} gap={1} rowGap={3} />
      </Grid>
    </Grid>
  );
}

export default AddContactDrawerSectionTwelve;

AddContactDrawerSectionTwelve.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  nationalityList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  getDropdownData: PropTypes.func.isRequired
};
