import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const MultiSelectHeliaTitle = props => {
  const { defaultValues = [], onChange = () => {}, label = '', multiple = false, ...rest } = props;

  const isInitialLoad = useRef(true);
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const fetchJobTitles = async () => {
    try {
      const response = await picklistDropDownApis(GET, 'project/helia_title');
      if (response?.data?.rows) {
        setOptions(response.data.rows);
      }
    } catch (error) {
      console.error('Failed to fetch job titles:', error);
    }
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  useEffect(() => {
    if (isInitialLoad.current && options?.length > 0 && defaultValues?.length > 0) {
      const defaultSelected = options.filter(option => defaultValues.includes(option?.name));
      setSelectedValues(defaultSelected);
      onChange(null, defaultSelected);
      isInitialLoad.current = false;
    }
  }, [options, defaultValues, onChange]);

  const handleChange = (event, selected) => {
    setSelectedValues(selected);
    onChange(selected);
  };

  return <CustomDropdown {...rest} options={options} value={selectedValues} onChange={handleChange} label={label} multiple={multiple} isCheckBox={multiple} />;
};

MultiSelectHeliaTitle.propTypes = {
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  multiple: PropTypes.bool
};

export default MultiSelectHeliaTitle;
