//-----------------------------------------------------------// In-built Imports // ------------------------------

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';

//-----------------------------------------------------------// External Imports // ------------------------------

import { getServerSocketConfig } from '../../Containers/Commons/urlUtil';
import { getUserSelector } from '../../selectors';
import { LINKEDIN_SCRAPER, LOE_GENERATION } from '../../services/constantService';
import { CompetencyGenerationActions } from '../../store/CompetencyGenerationSlice';
import { UPDATE_SOCKET } from '../../types';

export const useSocketHook = () => {
  const [user, setUser] = useState('');
  const dispatch = useDispatch();
  const userData = useSelector(getUserSelector);

  // Setup socket event handlers
  const setupSocketListeners = (socket, user) => {
    const eventHandlers = {
      connected: msg => {
        dispatch({ type: UPDATE_SOCKET, payload: socket });
      },
      [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: handleSocketEvent(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED),
      [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]: handleSocketEvent(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES),
      [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE]: handleSocketEvent(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE),
      [LOE_GENERATION.LOE_DOCUMENT_UPLOADED]: handleSocketEvent(LOE_GENERATION.LOE_DOCUMENT_UPLOADED),
      ECHOSENSE_RUBRIC_FUNCTION: msg => {
        console.log('COMPETENCY_GENERATION_COMPLETED', msg);
        dispatch(CompetencyGenerationActions.updateData(msg));
      }
    };

    // Register all event handlers
    Object.entries(eventHandlers).forEach(([event, handler]) => {
      socket.on(event, handler);
    });

    // Return cleanup function
    return () => {
      Object.keys(eventHandlers).forEach(event => {
        socket.off(event);
      });
      socket.disconnect();
    };
  };

  // Generic event handler factory
  const handleSocketEvent = type => (msg, obj) => {
    dispatch({
      type,
      payload: { msg, obj }
    });
  };

  useEffect(() => {
    if (user) {
      const { url, options } = getServerSocketConfig();
      const socket = io(url, { ...options, withCredentials: true });

      const cleanup = setupSocketListeners(socket, user);
      return cleanup;
    }
  }, [user]); // Add user as dependency

  useEffect(() => {
    setUser(userData?.id);
  }, [userData]);

  return {};
};
