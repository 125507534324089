// import { createStore, applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducer from '../reducers';
import commonSlice from './commonSlice';
import companySlice from './companySlice';
import contactSlice from './contactSlice';
import HomeSlice from './HomeSlice';
import searchSlice from './searchSlice';
// import workbenchSlice from "./workbenchSlice";
import CompetencyGenerationSlice from './CompetencyGenerationSlice';
import componentPermissionSlice from './componentPermissionSlice';
import globalSearchSlice from './globalSearchSlice';
import logAnActivitySlice from './logAnActivitySlice';
import pickListSlice from './pickListSlice';
import profileFormatSlice from './profileFormatSlice';
import viewProjectSlice from './viewProjectSlice';
// export default function configureStore(initialState) {
//   return createStore(
//     reducer,
//     initialState,
//     composeWithDevTools(applyMiddleware(thunk))
//   );
// }
// const initialState = {};

const store = configureStore({
  // initialState,
  reducer: {
    rootReducer: reducer,
    pickListReducer: pickListSlice,
    companyReducer: companySlice,
    contactReducer: contactSlice,
    commonReducer: commonSlice,
    searchReducer: searchSlice,
    viewProjectReducer: viewProjectSlice,
    logAnActivityReducer: logAnActivitySlice,
    globalSearchReducer: globalSearchSlice,
    componentPermissionSlice: componentPermissionSlice,
    profileFormatSlice: profileFormatSlice,
    competencyGenerationSlice: CompetencyGenerationSlice,
    homeReducer: HomeSlice
    /* workbenchReducer: workbenchSlice */
  },
  middleware: [thunk]
});

export default store;
