import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useTagColumnDef = () => {
  const { t } = useTranslation();

  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Tag Name',
        cellRenderer: 'nameRenderer',
        minWidth: 400,
        width: 400,
        maxWidth: 400,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        filter: 'agMultiColumnFilter'
      },
      {
        field: 'color',
        headerName: 'Color Label',
        minWidth: 200,
        width: 250,
        maxWidth: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        cellRenderer: 'colorRenderer',
        filter: 'agMultiColumnFilter'
      },
      {
        field: 'module',
        headerName: 'Module',
        minWidth: 400,
        width: 400,
        maxWidth: 400,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        cellRenderer: 'getModules',
        filter: 'agMultiColumnFilter',
        valueGetter: params => {
          return params?.data?.tag_module?.map(item => item?.module_name);
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 400,
        width: 400,
        maxWidth: 400,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        cellRenderer: 'statusRender',
        filter: 'agMultiColumnFilter',
        // valueGetter: params => {
        //   return params?.data?.status ? 'Active' : 'Inactive';
        // }
        valueGetter: params => {
          // Return null when status is false/Inactive to prevent grouping
          return params?.data?.status ? 'Active' : null;
        }
      },
      {
        headerName: 'Actions',
        field: 'action',
        colId: 'action',
        sortable: false,
        maxWidth: 350,
        minWidth: 350,
        cellRenderer: 'GetActionRenderer',
        // pinned: 'right',
        // lockPinned: true,
        // suppressColumnsToolPanel: true,
        filter: false,
        suppressHeaderMenuButton: true
      }
    ],
    [t]
  );

  return columnDefs;
};

export const createSchema = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
  tag_module: yup
    .array()
    .of(
      yup.object().shape({
        module_name: yup.string().required()
      })
    )
    .min(1)
});
export const createRequiredField = t => {
  return [
    { fieldName: 'name', label: t('utils.tagName'), type: String },
    { fieldName: 'color', label: t('utils.colourLabel'), type: String },
    {
      fieldName: 'tag_module',
      label: t('utils.module'),
      type: [],
      obj: {
        fieldName: 'module_name',
        label: t('utils.module'),
        type: String
      }
    }
  ];
};

export const getUpdatedPayload = (obj, userData) => {
  if (typeof obj.status === 'string') {
    obj.status = obj.status === 'Active' ? true : false;
  }

  if (obj.tag_module) {
    const tagModuleArray = obj?.tag_module.map(item => {
      if (item?.field_value) {
        return {
          module_name: item?.field_value,
          created_by: userData?.id,
          updated_by: userData?.id
        };
      } else {
        return {
          module_name: item?.module_name,
          created_by: userData?.id,
          updated_by: userData?.id
        };
      }
    });
    obj['tag_module'] = tagModuleArray;
  }

  return obj;
};
export const updateObject = (obj1, obj2) => {
  for (const key in obj2) {
    if (obj2[key] !== undefined) {
      obj1[key] = obj2[key];
    }
  }
  return obj1;
};
export const getPayload = (formValues, userData) => {
  let tagModuleArray = [];
  if (formValues?.tag_module) {
    tagModuleArray = formValues?.tag_module.map(item => ({
      module_name: item?.field_value,
      created_by: userData?.id,
      updated_by: userData?.id
    }));
  }
  const result = {
    name: formValues?.name,
    color: formValues?.color,
    status: formValues?.status === 'Active' || formValues?.status === undefined,
    tag_module: tagModuleArray
  };
  return result;
};
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
