import React, { useEffect, useState } from 'react';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
// import GroupsIcon from '@mui/icons-material/Groups';
// import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import { getAccessToken } from '../../../../services/cognitoService';
import { API } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';
export default function AddedBySelection(props) {
  const { placeholder, onChange = () => {}, label, defaultValue, ...rest } = props;
  const [options, setOptions] = useState([]);
  console.log(
    'state',
    useSelector(state => state.rootReducer)
  );
  const users = useSelector(state => state.commonReducer.ign_user);
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const setUserData = async () => {
      const token = await getAccessToken();
      setOptions(
        users.data.map(ele => ({
          id: ele.id,
          label: ele.name,
          logo: `${API.users}/${ele.id}/profileimage?token=${token}`
        }))
      );
    };
    if (!users) {
      dispatch(fetchPickListData('user?name=', 'ign_user'));
    } else {
      setUserData();
    }
  }, [users, dispatch]);
  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      console.log(x, 'defaultX');
      const index = options?.findIndex(obj => [x?.id, x?.added_user?.name, x?.name].includes(obj?.label)) ?? -1;
      console.log(x, 'defaultX', index);
      array.push(index);
    }

    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };
  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };
  return (
    <CustomDropdown
      options={options}
      label={label}
      multiple={true}
      isCheckBox={true}
      disableCloseOnSelect={true}
      placeholder={placeholder}
      onChange={handleChange}
      value={option ? values : defaultValue ? getValueOfSelected() : []}
      selectedValue={defaultValue}
      {...rest}
    />
  );
}
AddedBySelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string
};
