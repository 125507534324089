import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ignFetchRevenueMarket } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const MultipleRevenueMarketSelection = props => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const {
    className = 'SingleSelectStyle',
    onChange = () => {},
    isDrawer = false,
    label = '',
    defaultValue = [],
    required = false,
    disabled = false,
    value = [],
    isMultiSelect = false,
    size = 'small',
    InputLabelProps = {},
    ...rest
  } = props;

  // Redux selector for fetching markets
  const market = useSelector(state => state.commonReducer.market);

  // Fetch markets on mount if not already available
  useEffect(() => {
    const fetchMarkets = async () => {
      if (!market || market.length === 0) {
        await dispatch(ignFetchRevenueMarket('REVENUE_MARKET', 'market'));
      }
    };
    fetchMarkets();
  }, [dispatch, market]);

  // Update dropdown options when market changes
  useEffect(() => {
    if (Array.isArray(market) && market.length > 0) {
      const dropdownOptions = market.map(item => ({
        label: item.market,
        name: item.market,
        id: item.id
      }));
      setOptions(dropdownOptions);
    } else {
      setOptions([]);
    }
  }, [market]);

  // Ensure value passed is compatible with dropdown options
  const formattedValue = value.map(val => {
    return options.find(option => option.id === val.id || option.id === val) || val;
  });

  return (
    <CustomDropdown
      {...rest}
      value={formattedValue} // Fixed value format
      label={label}
      options={options}
      multiple={isMultiSelect}
      isCheckBox={isMultiSelect}
      className={className}
      onChange={onChange}
      isDrawer={isDrawer}
      required={required}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
      InputLabelProps={InputLabelProps}
    />
  );
};

// PropTypes validation
MultipleRevenueMarketSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.array, // Fixed type
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.array,
  size: PropTypes.string,
  style: PropTypes.object,
  isMultiSelect: PropTypes.bool,
  InputLabelProps: PropTypes.object // Added validation
};

export default MultipleRevenueMarketSelection;
