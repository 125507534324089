import { CloseOutlined, Mic, MicOff } from '@mui/icons-material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Divider, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import Popup from 'reactjs-popup';
import { gptDataApi } from '../../../services/ApiService';
import { AWS_LANGUAGE_OPTIONS, ERROR, POST } from '../../../services/constantService';
import CustomButton from '../CustomButton';
import * as TranscribeClient from './awsTranscribe';
import './index.scss';

const BUTTON_THRESHOLD_WIDTH = 600;

const startRecording = async (language, isRecording, onTranscriptionDataReceived, isLoading) => {
  if (!language) {
    alert('Please select a language');
    return;
  }

  try {
    isRecording(true);
    await TranscribeClient.startRecording(language, onTranscriptionDataReceived, isRecording, isLoading);
  } catch (error) {
    isRecording(false);
    alert('An error occurred while recording: ' + error.message);
    stopRecording();
  }
};

const stopRecording = function () {
  TranscribeClient.stopRecording();
};

/**
 * Custom hook for handling transcription and recording.
 *
 * This hook provides functionality to start and stop audio recording, transcribe the recorded audio,
 * and manage the transcription state. It also provides components for controlling the transcription
 * process and displaying the transcribed text.
 *
 * @param {{
 *  defaultTranscribedText: string
 * }} props The default transcribed text to display.
 *
 * @returns {{
 *   stopRecording: Function,
 *   TranscribeButton: React.FunctionComponent,
 *   SummariseButton: React.FunctionComponent,
 *   ViewTranscribedText: React.FunctionComponent,
 *   TranscribePopup: React.FunctionComponent,
 *   transcribedText: string
 * }} An object containing properties for managing transcription and recording.
 */
const useTranscribe = props => {
  const { defaultTranscribedText } = props;

  const [language] = React.useState(AWS_LANGUAGE_OPTIONS[0]);
  const [loading, setLoading] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [isTranscribePopupOpen, setIsTranscribePopupOpen] = React.useState(false);
  const [prevTranscribedText, setPrevTranscribedText] = React.useState(defaultTranscribedText);

  const { t } = useTranslation();

  const { setValue, getValues, watch } = useForm({
    defaultValues: {
      body: ''
    }
  });
  const updatePrevTranscribedText = text => {
    setPrevTranscribedText(text);
  };

  const onTranscriptionDataReceived = data => {
    const currentBody = getValues('body') ? getValues('body') : '<p></p>';
    const updatedBody = currentBody.replace(/<\/p>$/, `${data}</p>`);
    setValue('body', updatedBody);
  };

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  const getButtonText = (isRecording, isLoading) => {
    if (isRecording && isLoading) {
      return t('utils.pleaseWait');
    }
    if (isRecording) {
      return t('utils.stopDictating');
    }
    if (isLoading) {
      return t('utils.pleaseWait');
    }
    return t('utils.startDictation');
  };

  const TranscribeButton = width => {
    const isSmallWidth = width?.width > BUTTON_THRESHOLD_WIDTH;
    return (
      <CustomButton
        type={isRecording ? 'tertiary-error' : 'outline-primary'}
        variant='text'
        buttonText={isSmallWidth ? getButtonText(isRecording, loading) : null}
        customWidth={'auto'}
        boxClassName={`outline-primary ${isSmallWidth ? '' : 'p-1'}`}
        onClick={e => {
          e.preventDefault();
          if (isRecording) {
            stopRecording();
            setIsRecording(false);
            return;
          }
          setLoading(true);
          startRecording(language, setIsRecording, onTranscriptionDataReceived, setLoading);
        }}
        iconLeft={
          <Tooltip title='Dictation'>
            {isRecording ? (
              <MicOff
                sx={{
                  width: 18,
                  height: 18,
                  marginRight: isSmallWidth ? 1 : 0
                }}
              />
            ) : (
              <Mic
                sx={{
                  width: 18,
                  height: 18,
                  marginRight: isSmallWidth ? 1 : 0
                }}
              />
            )}
          </Tooltip>
        }
        iconRight={
          <Spinner
            size='sm'
            className='ml-2'
            style={{
              display: loading ? 'inline-block' : 'none'
            }}
          />
        }
        disabled={loading}
        bgColor={'none'}
      />
    );
  };

  const SummariseButton = (quill, width) => {
    const [isLoading, setIsLoading] = useState(false);
    const isSmallWidth = width?.width > BUTTON_THRESHOLD_WIDTH;
    const [previousValue, setPreviousValue] = useState('');
    return (
      <CustomButton
        isLoading={isLoading}
        buttonText={isSmallWidth ? t('actions.summarize') : null}
        variant={'text'}
        bgColor={'none'}
        iconLeft={
          <Tooltip title='Summarize'>
            <TextSnippetIcon
              sx={{
                width: 18,
                height: 18,
                marginRight: isSmallWidth ? 1 : 0
              }}
            />
          </Tooltip>
        }
        customWidth={'auto'}
        boxClassName={`outline-primary ${isSmallWidth ? '' : 'p-1'}`}
        onClick={async () => {
          setIsLoading(true);
          const editor = quill.current.getEditor();
          const selection = editor.getSelection(); // Get the selection range
          let selectedText = editor.getText();
          let selectedIndex = 0;
          let selectedLength = selectedText.length;
          updatePrevTranscribedText(editor.root.innerHTML);
          if (selection && selection.length > 0) {
            selectedIndex = selection.index;
            selectedLength = selection.length;
            selectedText = editor.getText(selectedIndex, selectedLength);
            // setPreviousValue(editor.root.innerHTML);
          } else if (defaultTranscribedText && defaultTranscribedText.length > 0) {
            enqueueSnackbar('Select the text to summarize', { variant: ERROR });
            setIsLoading(false);
            return;
          }
          const gptSummarizeRes = await gptDataApi(POST, 'summarize-text', { text: selectedText });
          if (gptSummarizeRes.status === 200 && gptSummarizeRes.data?.summarize_data) {
            editor.deleteText(selectedIndex, selectedLength);
            editor.insertText(selectedIndex, gptSummarizeRes?.data?.summarize_data);
          }
          setIsLoading(false);
        }}
      />
    );
  };
  const ViewTranscribedText = width => {
    const isSmallWidth = width?.width > BUTTON_THRESHOLD_WIDTH;
    return (
      <CustomButton
        variant='text'
        buttonText={isSmallWidth ? t('utils.viewTranscribedText') : null}
        bgColor='none'
        iconLeft={
          <Tooltip title='View Transcribed Text'>
            <WysiwygIcon
              sx={{
                width: 18,
                height: 18,
                marginRight: isSmallWidth ? 1 : 0
              }}
            />
          </Tooltip>
        }
        customWidth='auto'
        boxClassName={`outline-primary ${isSmallWidth ? '' : 'p-1'}`}
        onClick={() => {
          setIsTranscribePopupOpen(true);
        }}
      />
    );
  };

  const TranscribePopup = () => {
    return (
      <Popup
        open={isTranscribePopupOpen}
        modal={true}
        onClose={() => {
          setIsTranscribePopupOpen(false);
        }}
        className='transcribe-popup'
        position='center center'
        overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
        contentStyle={{
          width: '80%',
          maxWidth: '800px',
          padding: '20px',
          borderRadius: '8px'
        }}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <h6 className='header-font font-weight-bold'>Transcribed Text</h6>
          <div
            onClick={() => {
              setIsTranscribePopupOpen(false);
            }}
            className='cursor-pointer'
          >
            <CloseOutlined />
          </div>
        </div>
        <Divider className='my-2' />
        <ReactQuill readOnly={true} value={prevTranscribedText} modules={{ toolbar: false }} className='w-100 flex' theme='bubble' id='task-notes' />
      </Popup>
    );
  };

  const transcribedText = watch('body');

  return {
    stopRecording,
    TranscribeButton,
    SummariseButton,
    ViewTranscribedText,
    TranscribePopup,
    setTranscribedText: setValue,
    transcribedText,
    updatePrevTranscribedText
  };
};

export default useTranscribe;

PropTypes.useTranscribe = {
  defaultTranscribedText: PropTypes.string
};
