import propTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import AgGridWrapper from '../../../utils/AgGridWrapper';

let columnApi;
const PAGE_LIMIT = 50;
const TableView = ({ projectGridColumn, projectGridData, sx, loading, sortModel, gridState, filterModel }) => {
  const [isGridReady, setIsGridReady] = useState(false);
  const navigate = useNavigate();
  const homeGridRef = useRef();
  const csvExportRef = useRef();
  const onClickGridProject = project => {
    const { id, candidate_count } = project;

    let pathWithTab = ROUTES.viewProjectWithDeepLink;
    pathWithTab = pathWithTab.replace(':id', id);
    if (candidate_count > 0) {
      pathWithTab = pathWithTab.replace(':parentTab', 'Candidate Pipeline');
      pathWithTab = pathWithTab.replace(':subTab', 'null');
    } else {
      pathWithTab = pathWithTab.replace(':parentTab', 'Overview');
      pathWithTab = pathWithTab.replace(':subTab', 'Details');
    }
    navigate(pathWithTab);
  };
  // const columns = useMemo(() => {
  //   let gridDefColumns = gridState; //for dynamic columns
  //   // let gridDefColumns = Array.isArray(gridState) && gridState.length ? projectGridColumn : projectGridColumn; //for constant column
  //   return gridDefColumns.map((col, index) => {
  //     if (col.field === 'job_number') {
  //       return {
  //         ...col,
  //         cellRenderer: params => <JobNumberCellRenderer value={params.value} data={params.data} onClick={onClickGridProject} />
  //       };
  //     } else if (col.field === 'created_at') {
  //       // Updated condition for created_at
  //       return {
  //         ...col,
  //         valueGetter: params => calculateNumberOfDays(params?.data?.created_at),
  //         cellRenderer: params => <EllipsisCellRenderer value={calculateNumberOfDays(params?.data?.created_at)} />,
  //         filter: true, // Ensure filtering is enabled
  //         filterParams: {
  //           // Add this to sort the filter list numerically
  //           comparator: (a, b) => Number(a) - Number(b) // Sorts numbers
  //         }
  //       };
  //     } else {
  //       return {
  //         ...col,
  //         cellRenderer: params => <EllipsisCellRenderer value={params?.value} />
  //       };
  //     }
  //   });
  // }, [projectGridColumn, gridState, navigate]);

  const columns = useMemo(() => {
    let gridDefColumns = gridState; //projectGridColumn;

    if (Array.isArray(gridState) && gridState.length) {
      const savedConfigMap = gridState.reduce((acc, column) => {
        acc[column.field] = column;
        return acc;
      }, {});

      const updatedColDefs = [...projectGridColumn].map(col => {
        const savedConfig = savedConfigMap[col.field];
        if (savedConfig) {
          return {
            ...col,
            ...savedConfig
          };
        }
        return {
          ...col
        };
      });

      updatedColDefs.sort((a, b) => {
        const indexA = gridState.findIndex(column => column.field === a.field);
        const indexB = gridState.findIndex(column => column.field === b.field);
        return indexA - indexB;
      });
      gridDefColumns = updatedColDefs;
    }

    return gridDefColumns.map((col, index) => {
      if (col.field === 'job_number') {
        return {
          ...col,
          cellRenderer: params => <JobNumberCellRenderer value={params.value} data={params.data} onClick={onClickGridProject} />
        };
      }
      // else if (col.field === 'created_at') {
      //   return {
      //     ...col,
      //     valueGetter: params => {
      //       // Return empty string if it's a group row
      //       if (params.node.group) {
      //         return '';
      //       }
      //       return calculateNumberOfDays(params?.data?.created_at);
      //     },
      //     cellRenderer: params => {
      //       // Return empty string if it's a group row
      //       if (params.node.group) {
      //         return '';
      //       }
      //       return <EllipsisCellRenderer value={calculateNumberOfDays(params?.data?.created_at)} />;
      //     },
      //     filter: true,
      //     filterParams: {
      //       comparator: (a, b) => Number(a) - Number(b)
      //     }
      //   };
      // }
      // else if (col.field === 'min_compensation' || col.field === 'max_compensation') {
      //   return {
      //     ...col,
      //     cellRenderer: params => (params?.value ? `${params?.data?.bd_currency_values?.currency_icon || ''} ${params?.value?.toLocaleString()}` : '')
      //   };
      else if (col.field === 'min_compensation' || col.field === 'max_compensation') {
        return {
          ...col,
          cellRenderer: params => {
            if (params.node.group) {
              return params.value ? `${params?.data?.bd_currency_values?.currency_icon || ''} ${params.value.toLocaleString()}` : '';
            }
            return params?.value ? `${params?.data?.bd_currency_values?.currency_icon || ''} ${params?.value?.toLocaleString()}` : '';
          },
          aggFunc: 'sum',
          valueGetter: params => Number(params?.data?.[col.field]) || 0
        };
      } else if (col.field === 'candidate_count') {
        return {
          ...col,
          aggFunc: 'sum',
          valueGetter: params => Number(params?.data?.candidate_count) || 0
        };
      } else {
        return {
          ...col,
          cellRenderer: params => <EllipsisCellRenderer value={params?.value} />
        };
      }
    });
  }, [projectGridColumn, gridState, navigate]);
  const data = useMemo(() => projectGridData, [projectGridData, loading]);
  const isRowSelectable = useMemo(() => {
    return params => (params.data ? params.data.stage === 'Open' : false);
  }, []);

  const onGridReady = params => {
    columnApi = params.columnApi;
    // params.columnApi.autoSizeColumns();
    homeGridRef.current = params;
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    // params.columnApi?.getAllColumns().map(col => {
    //   params.columnApi?.setColumnVisible(col.colId, col?.colDef?.visible);
    // });
    setIsGridReady(true);
  };

  const exportCSVColumns = [
    { key: 'job_number', label: 'Project ID' },
    { key: 'job_title', label: 'Job Title' },
    { key: 'ign_companies.name', label: 'Company' },
    { key: 'creator.name', label: 'Added By' },
    { key: 'stage', label: 'Stage' },
    { key: 'location', label: 'Location' },
    { key: 'ign_industries.name', label: 'Industry' },
    { key: 'service_offering', label: 'Service Offering' },
    { key: 'candidate_count', label: 'Candidates' },
    { key: 'probability', label: 'Probability' },
    { key: 'min_experience', label: 'Minimum Experience' },
    { key: 'max_experience', label: 'Maximum Experience' },
    { key: 'min_compensation', label: 'Minimum Compensation' },
    { key: 'max_compensation', label: 'Maximum Compensation' },
    { key: 'fee_type', label: 'Fee Type' },
    { key: 'fee_percentage', label: 'Fee Percentage' },
    { key: 'term_type', label: 'Term Type' }
  ];
  return (
    <>
      <div id='myGrid' className='ag-theme-alpine header-column-filter'>
        <AgGridWrapper
          defaultColumnDefs={projectGridColumn}
          gridType={'homeGrid'}
          columnDefs={columns}
          showHeaderMenu={true}
          rowData={data}
          suppressRowClickSelection={true}
          paginationPageSize={PAGE_LIMIT}
          isRowSelectable={isRowSelectable}
          rowSelection='multiple'
          sortModel={sortModel}
          filterModel={filterModel}
          enableRangeSelection={true}
          defaultColDef={{
            resizable: true,
            filter: true,
            sortable: true,
            maxWidth: 450,
            enableValue: true,
            enableRowGroup: true
          }}
          columnMenu={true}
          onGridReady={onGridReady}
          rowGroupPanelShow='always'
          saveGridSetting={true}
        />
      </div>
    </>
  );
};

const JobNumberCellRenderer = props => {
  const { value, data, onClick } = props;
  return (
    <a
      href='#'
      style={{ textDecoration: 'underline' }}
      onClick={e => {
        e.preventDefault();
        onClick(data);
      }}
    >
      {value}
    </a>
  );
};

const EllipsisCellRenderer = props => {
  const { value } = props;
  if (!value) {
    return null;
  }
  let valueToUse = value;
  if (typeof value === 'object') {
    valueToUse = value.value;
  }
  return (
    <span
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
        //display: 'inline-block'
      }}
      title={valueToUse} // Tooltip with full content
    >
      {valueToUse}
    </span>
  );
};

TableView.propTypes = {
  projectGridColumn: propTypes.array,
  projectGridData: propTypes.array,
  sx: propTypes.object,
  loading: propTypes.bool,
  sortModel: propTypes.array,
  gridState: propTypes.array,
  filterModel: propTypes.object
};

JobNumberCellRenderer.propTypes = {
  value: propTypes.string.isRequired,
  data: propTypes.object.isRequired,
  onClick: propTypes.func.isRequired
};

EllipsisCellRenderer.propTypes = {
  value: propTypes.oneOfType([propTypes.string, propTypes.number])
};

export default TableView;
