const columnDefs = [
  {
    field: 'activity_label',
    headerName: 'Activity Name',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    cellRenderer: 'activityNameRenderer',
    filter: 'agMultiColumnFilter',
    cellStyle: { cursor: 'pointer' },
    valueGetter: params => {
      if (params.node.group) {
        return;
      }
      return params?.data?.labels.map(item => item?.label);
    }
  },
  {
    field: 'show_in',
    headerName: 'Show In',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    filter: 'agMultiColumnFilter',
    enableRowGroup: true,
    valueGetter: params => {
      return params.data?.show_in || '';
    },
    cellStyle: { cursor: 'pointer' }
  }
];

const activityNameRenderer = params => {
  if (params.node.group) {
    return params.value;
  }

  const browserLocale = navigator.language;
  return params?.data?.labels?.find(item => item?.language_code === browserLocale)?.label || params?.data?.labels?.find(item => item?.language_code === 'en-US')?.label || 'No Label';
};

module.exports = { columnDefs, activityNameRenderer };
