import * as React from 'react';

function LegendsColorPlateIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10 0C4.47778 0 0 4.47778 0 10C0 15.5222 4.47778 20 10 20C10.9222 20 11.6667 19.2556 11.6667 18.3333C11.6667 17.9 11.5056 17.5111 11.2333 17.2167C10.9722 16.9222 10.8167 16.5389 10.8167 16.1111C10.8167 15.1889 11.5611 14.4444 12.4833 14.4444H14.4444C17.5111 14.4444 20 11.9556 20 8.88889C20 3.97778 15.5222 0 10 0ZM3.88889 10C2.96667 10 2.22222 9.25556 2.22222 8.33333C2.22222 7.41111 2.96667 6.66667 3.88889 6.66667C4.81111 6.66667 5.55556 7.41111 5.55556 8.33333C5.55556 9.25556 4.81111 10 3.88889 10ZM7.22222 5.55556C6.3 5.55556 5.55556 4.81111 5.55556 3.88889C5.55556 2.96667 6.3 2.22222 7.22222 2.22222C8.14444 2.22222 8.88889 2.96667 8.88889 3.88889C8.88889 4.81111 8.14444 5.55556 7.22222 5.55556ZM12.7778 5.55556C11.8556 5.55556 11.1111 4.81111 11.1111 3.88889C11.1111 2.96667 11.8556 2.22222 12.7778 2.22222C13.7 2.22222 14.4444 2.96667 14.4444 3.88889C14.4444 4.81111 13.7 5.55556 12.7778 5.55556ZM16.1111 10C15.1889 10 14.4444 9.25556 14.4444 8.33333C14.4444 7.41111 15.1889 6.66667 16.1111 6.66667C17.0333 6.66667 17.7778 7.41111 17.7778 8.33333C17.7778 9.25556 17.0333 10 16.1111 10Z'
        fill='#003C5B'
      />
    </svg>
  );
}

export default LegendsColorPlateIcon;
