import * as React from 'react';

function DevelopingBadgeIcon(props) {
  return (
    <svg width={30} height={37} viewBox='0 0 30 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M16.649 23.612L8.955 36.184c-.51.833-1.742.793-2.196-.07l-1.615-3.072-3.492-.008c-.982-.002-1.585-1.067-1.075-1.9l7.69-12.566 8.382 5.044z' fill='#003956' />
      <path d='M12.276 23.612l7.694 12.572c.51.833 1.742.793 2.196-.07l1.615-3.072 3.492-.008c.982-.002 1.585-1.067 1.076-1.9l-7.69-12.566-8.383 5.044z' fill='#003956' />
      <path
        d='M16.686.71l1.9 1.368c.447.322.984.495 1.536.495h2.349c1.132 0 2.136.723 2.485 1.791l.726 2.215c.17.52.503.974.95 1.296l1.9 1.369c.916.66 1.299 1.83.949 2.898l-.726 2.215a2.57 2.57 0 000 1.601l.726 2.215a2.58 2.58 0 01-.95 2.898l-1.9 1.369a2.593 2.593 0 00-.949 1.296l-.726 2.215a2.612 2.612 0 01-2.485 1.79h-2.349c-.552 0-1.09.174-1.536.496l-1.9 1.369c-.916.66-2.156.66-3.072 0l-1.9-1.37a2.628 2.628 0 00-1.536-.494H7.829a2.611 2.611 0 01-2.485-1.791l-.726-2.215a2.594 2.594 0 00-.95-1.296l-1.9-1.369a2.58 2.58 0 01-.949-2.898l.726-2.215c.17-.52.17-1.08 0-1.601l-.726-2.215a2.58 2.58 0 01.95-2.898l1.9-1.369c.446-.322.778-.775.949-1.296l.726-2.215a2.612 2.612 0 012.485-1.79h2.349c.551 0 1.09-.174 1.536-.496l1.9-1.369a2.631 2.631 0 013.072 0z'
        fill='#23CEA7'
      />
      <path d='M15.15 26.425c6.274 0 11.36-5.044 11.36-11.266S21.425 3.893 15.15 3.893c-6.274 0-11.36 5.044-11.36 11.266s5.086 11.266 11.36 11.266z' fill='#F0F0FC' />
      <path
        opacity={0.1}
        d='M29.475 18.18a2.55 2.55 0 01-.947 2.889l-1.893 1.377a2.529 2.529 0 00-.959 1.292l-.725 2.219a2.615 2.615 0 01-2.483 1.792H20.12c-.553 0-1.082.17-1.537.488l-1.892 1.365a2.6 2.6 0 01-3.074 0l-1.905-1.366a2.645 2.645 0 00-1.537-.487H7.827a2.615 2.615 0 01-2.483-1.792l-.725-2.219c6.454 2.621 13.842.963 18.156-4.023 2.877-3.34 4.094-7.801 3.455-12.19.122.134.258.244.405.354l1.893 1.365a2.562 2.562 0 01.947 2.901l-.726 2.219a2.72 2.72 0 000 1.597l.726 2.219z'
        fill='#000'
      />
      <path
        d='M17.47 12.12l.07.143.16.022 3.224.438c.834.113 1.16 1.125.559 1.697l-2.35 2.234-.117.112.029.16.576 3.177c.147.81-.717 1.443-1.46 1.048l-2.87-1.525-.14-.075-.142.075-2.869 1.525c-.743.395-1.607-.238-1.46-1.048l.576-3.178.029-.159-.118-.111-2.349-2.235c-.601-.572-.275-1.584.558-1.697l3.225-.438.16-.022.07-.144 1.417-2.906-.27-.132.27.132c.364-.748 1.44-.748 1.805 0l1.417 2.906z'
        fill='#87EBD4'
        stroke='#23CEA7'
        strokeWidth={0.6}
      />
    </svg>
  );
}

export default DevelopingBadgeIcon;
