import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { ProjectDataApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import AgGridWrapper from '../../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../../utils/common';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { CustomLoadingOverlayComponent } from '../../../Commons/Utils';
import { WorkBenchContext } from '../../Context';

export default function AddProjectFromExisting() {
  const { setAddProjectFromExistingModal, addProjectFromExistingModal, addRecord, refetchFunc } = useContext(WorkBenchContext);
  const [rowData, setRowData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = React.useRef(null);
  const { t } = useTranslation();

  const onSelectionChanged = rows => {
    const selectedData = rows.map(node => {
      return {
        project_id: node.id,
        id: undefined,
        ...node
      };
    });
    setSelectedIds(selectedData);
  };

  const fetchProjects = async () => {
    setLoading(true);
    if (inputValue) {
      const res = await ProjectDataApi(GET, '', {}, `query?searchValue=${inputValue}`);
      setRowData(res?.data?.data ?? []);
    }
    setLoading(false);
  };

  const addToProjectWorkbench = async () => {
    try {
      setLoading(true);
      for (let data of selectedIds) {
        await addRecord(null, data?.project_id);
      }
      enqueueSnackbar('Added to Workbench', 'success');
      setAddProjectFromExistingModal(false);
      setLoading(false);
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const columnDefs = [
    {
      headerName: 'Project Name',
      field: 'job_title',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Job Number',
      field: 'job_number',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Company',
      field: 'company_name',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Project Industries',
      field: 'project_industries',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Location',
      field: 'location',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Record Type',
      field: 'record_type',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Stage',
      field: 'stage',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Horizontal Practice',
      field: 'horizontal_practice',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Vertical Practice',
      field: 'vertical_practice',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      cellRendererFramework: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
      sortable: true,
      filter: true
    },
    {
      headerName: 'Owner',
      field: 'created_by',
      sortable: true,
      filter: true
    }
  ];

  const formattedRowData = useMemo(() => {
    return rowData.map(elem => {
      return {
        ...elem,
        project_industries: elem?.ign_industries?.name,
        created_by: elem?.creator?.name,
        company_name: elem?.ign_companies?.name
      };
    });
  }, [rowData]);
  return (
    <>
      <CustomModalWithHeader
        isOpen={addProjectFromExistingModal}
        style={{ width: '80vw !important', minHeight: '70vh' }}
        onClose={() => setAddProjectFromExistingModal(!addProjectFromExistingModal)}
        label={t('utils.addExistingProject')}
        closeIcon={true}
      >
        <div className='min-vw-50 p-4'>
          <Loader show={isLoading} />
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={10}>
              <CustomInputField className='w-100' label={t('grid.projectName')} onChange={e => setInputValue(e.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <CustomButton buttonText={t('common.search')} type='primary w-100' onClick={fetchProjects} />
            </Grid>
          </Grid>
          <div id='myGrid' className='mt-2 w-100 ag-theme-alpine ag-grid-container' style={{ height: '60vh' }}>
            <AgGridWrapper
              enableBrowserTooltips={true}
              rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
              onRowSelected={params => {
                const rowCount = params?.api?.getSelectedRows();
                onSelectionChanged(rowCount);
              }}
              rowHeight={50}
              columnDefs={columnDefs}
              loadingOverlayComponent={CustomLoadingOverlayComponent}
              scrollbarWidth={12}
              showHeaderMenu={true}
              rowData={formattedRowData}
              paginationPageSize={1000}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: true
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              suppressHorizontalScroll={false}
              domLayout='normal'
              disableStaticMarkup
              rowGroupPanelShow='always'
              saveGridSetting={false} // Setting to false as there is no grid key and logic to fetch the grid settings was not implemented
              rowModelType='clientSide'
            />
            {/* <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={formattedRowData}
              suppressRowClickSelection={true}
              paginationPageSize={10}
              rowSelection='multiple'
              enableRangeSelection={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: true
              }}
              columnMenu={true}
              suppressChangeDetection={true}
            /> */}
          </div>

          <div className='mt-2 d-flex justify-content-end'>
            <CustomButton buttonText={t('utils.addToWorkbench')} disabled={selectedIds.length == 0} onClick={addToProjectWorkbench} />
          </div>
        </div>
      </CustomModalWithHeader>
    </>
  );
}
