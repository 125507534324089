import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Switch, Tooltip, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateCandidateScoreApi } from '../../../../../services/ApiService';
import { STANDARD_ERROR_MESSAGE } from '../../../../../services/constantService';
import { showToast, stripHTMLTags } from '../../../../../utils/common';
import FabLoader from '../../../../common/FabLoader';
import { CompetentBadgeIcon, DevelopingBadgeIcon, ExpertBadgeIcon, NeedsImprovementBadgeIcon, ProficientBadgeIcon, ThumbsDownIcon, ThumbsUpIcon, UnQualifiedBadgeIcon } from '../../../../Icons';

const RatingLevelType = {
  0: 'UNQUALIFIED',
  1: 'NEEDS IMPROVEMENT',
  2: 'DEVELOPING',
  3: 'COMPETENT',
  4: 'PROFICIENT',
  5: 'EXPERT'
};

const RatingSlider = styled(Slider)({
  color: '#838383',
  height: 8,
  marginTop: 10,
  '& .MuiSlider-track': {
    border: 'none',
    visibility: 'hidden'
  },
  '& .MuiSlider-thumb': {
    width: '0px',
    height: '0px',
    backgroundColor: '#838383',
    border: '2px #838383',
    boxShadow: 'inherit'
  },
  '& .MuiSlider-thumb:hover': {
    boxShadow: 'none'
  },
  '& .MuiSlider-thumb:focusVisible': {
    boxShadow: 'none'
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 26,
    height: 26,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1B365D',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  },
  '& .MuiSlider-valueLabelCircle': {
    backgroundColor: 'white',
    color: '#1B365D',
    borderRadius: '55px',
    width: '17px',
    textAlign: 'center',
    height: '17px',
    fontSize: '13px',
    fontWeight: 500
  },
  '& .MuiSlider-mark ': {
    width: '6px',
    height: '6px',
    borderRadius: '6px',
    backgroundColor: '#838383'
  },
  '& .MuiSlider-rail': {
    height: '4px',
    top: '-10px',
    backgroundColor: '#C2DEEC'
  }
});

export const RatingWrapper = props => {
  const { value, type, handleModal, category, updateMasterData, isIgnyteAiRating = false, comments = null, data, fieldKey } = props;
  const [sliderValue, setSliderValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const updateRatingDb = useCallback(
    async (fieldKey, rating, oldRating) => {
      const sub_route = `/score/${data.id}`;
      try {
        setIsLoading(true);
        const response = await UpdateCandidateScoreApi(sub_route, {
          [`${fieldKey}_rating`]: rating
        });
        if (get(response, 'data.error', false)) {
          showToast(response.data.msg, 'error');
          setSliderValue(oldRating);
        } else {
          updateMasterData(response.data.data, category);
        }
      } catch (error) {
        showToast(STANDARD_ERROR_MESSAGE, 'error');
        setSliderValue(oldRating);
      } finally {
        setIsLoading(false);
      }
    },
    [data.id, updateMasterData]
  );

  const debounceLoadData = useCallback(debounce(updateRatingDb, 500), [data.id, updateMasterData]);

  const handleSliderChange = (_, newValue) => {
    setSliderValue(newValue);
    debounceLoadData(fieldKey, newValue, sliderValue);
  };

  const handleSwitchChange = useCallback(
    e => {
      const value = e.target.checked ? 1 : 0;
      setSliderValue(value);
      debounceLoadData(fieldKey, value, value ? 0 : 1);
    },
    [debounceLoadData]
  );

  const handleCopyRating = useCallback(comment => {
    navigator.clipboard.writeText(comment);
    showToast('Comments copied to clipboard');
  }, []);

  const stripedComments = useMemo(() => stripHTMLTags(comments), [comments]);
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '30px 0px' }}>
        <Box className='d-flex justify-content-center'>
          {type.toUpperCase() === 'GRADED' && sliderValue === 0 && <UnQualifiedBadgeIcon width={38} height={48} />}
          {type.toUpperCase() === 'GRADED' && sliderValue === 1 && <NeedsImprovementBadgeIcon width={38} height={48} />}
          {type.toUpperCase() === 'GRADED' && sliderValue === 2 && <DevelopingBadgeIcon width={38} height={48} />}
          {type.toUpperCase() === 'GRADED' && sliderValue === 3 && <CompetentBadgeIcon width={38} height={48} />}
          {type.toUpperCase() === 'GRADED' && sliderValue === 4 && <ProficientBadgeIcon width={38} height={48} />}
          {type.toUpperCase() === 'GRADED' && sliderValue === 5 && <ExpertBadgeIcon width={38} height={48} />}
          {type.toUpperCase() === 'BINARY' && sliderValue === 0 && <ThumbsDownIcon width={38} height={48} />}
          {type.toUpperCase() === 'BINARY' && sliderValue === 1 && <ThumbsUpIcon width={38} height={48} />}
        </Box>
        <Box className='d-flex justify-content-center'>
          <Typography
            sx={{
              color: '#666666',
              textAlign: 'center',
              fontSize: '10px !important',
              marginTop: '1rem !important'
            }}
          >
            {type.toUpperCase() === 'GRADED' ? RatingLevelType[sliderValue] : t('project.candidateScore.meetsText')}
          </Typography>
        </Box>
        <Box
          className='mt-4'
          style={{
            cursor: isIgnyteAiRating ? 'not-allowed' : 'inherit'
          }}
        >
          {type.toUpperCase() === 'GRADED' && (
            <RatingSlider
              className='mark-my-styles'
              onChange={handleSliderChange}
              valueLabelDisplay='on'
              aria-label='pretto slider'
              marks={true}
              min={0}
              max={5}
              disabled={isIgnyteAiRating}
              value={sliderValue}
            />
          )}
          {type.toUpperCase() === 'BINARY' && (
            <Box
              className='d-flex align-items-center justify-content-center'
              style={{
                cursor: isIgnyteAiRating ? 'not-allowed' : 'pointer'
              }}
            >
              <Switch className='SwitchStyle candidate-score-switch' checked={sliderValue ? true : false} disabled={isIgnyteAiRating} onChange={handleSwitchChange} />
              <Typography style={{ fontSize: 10, color: '#666666' }}>{t('project.candidateScore.doesNotMeetText')}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      {isIgnyteAiRating ? (
        <Box
          className='d-flex align-items-center'
          style={{ cursor: data.evidence_text ? 'pointer' : 'not-allowed' }}
          onClick={() => {
            if (data.evidence_text) {
              handleModal(data);
            }
          }}
        >
          <CommentOutlinedIcon
            sx={{
              color: data.evidence_text ? '#1FA890' : '#959fb7',
              fontSize: '1rem'
            }}
          />
          <Box className='fs-12 ml-1' color={data.evidence_text ? '#1FA890' : '#959fb7'}>
            {t('project.candidateScore.evidenceText')}
          </Box>
        </Box>
      ) : stripedComments ? (
        <Box className='bottom-action cursor-pointer d-flex align-items-start justify-content-between gap-2'>
          <Box className='d-flex align-items-center cursor-pointer' onClick={() => handleModal(data, fieldKey, category)}>
            <CommentOutlinedIcon
              sx={{
                color: '#AEAEAE',
                fontSize: '1rem'
              }}
            />
            <Tooltip title={stripedComments} placement='bottom' arrow>
              <Box
                className='fs-12 ml-1 d-flex'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '150px'
                }}
              >
                {stripedComments || '-'}
              </Box>
            </Tooltip>
          </Box>
          <ContentCopyIcon
            sx={{
              color: '#1FA890',
              fontSize: '1rem',
              cursor: 'pointer'
            }}
            onClick={() => handleCopyRating(stripedComments)}
          />
        </Box>
      ) : (
        <Box className='bottom-action cursor-pointer d-flex align-items-start gap-2' onClick={() => handleModal(data, fieldKey, category)}>
          <CommentOutlinedIcon
            sx={{
              color: '#1FA890',
              fontSize: '1rem'
            }}
          />
          <Box className='fs-12 activity-bar-title ml-1'>{t('project.candidateScore.commentsText')}</Box>
        </Box>
      )}
      <FabLoader isLoading={isLoading} size={20} />
    </>
  );
};

RatingWrapper.propTypes = {
  value: PropTypes.number,
  type: PropTypes.string,
  handleModal: PropTypes.func,
  isIgnyteAiRating: PropTypes.bool,
  comments: PropTypes.string,
  category: PropTypes.string,
  data: PropTypes.object,
  fieldKey: PropTypes.string,
  updateMasterData: PropTypes.func
};

export default RatingWrapper;
