/* eslint-disable no-unused-vars */
//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from 'prop-types';
// import { useDispatch, useSelector } from "react-redux";

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchLogAnActivityTypes } from '../../../../actions';
import CustomMuiDropdown from '../../CustomMuiDropdown';

const getLocation = str => {
  if (str.includes('contacts')) {
    return 'Contacts';
  }
  if (str.includes('project')) {
    return 'Candidates';
  }
  if (str.includes('company')) {
    return 'Companies';
  }
  return null;
};

const ActivityTypeSelection = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const activityType = useSelector(state => state.commonReducer.activityType);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!activityType) {
      dispatch(fetchLogAnActivityTypes('ACTIVITY_TYPE', 'activityType'));
    } else {
      const url = getLocation(location.pathname);
      if (url) {
        const filteredActivityType = activityType.filter(item => item?.show_in?.includes(url));
        setOptions(filteredActivityType);
      } else {
        setOptions(activityType);
      }
    }
  }, [activityType, dispatch]);
  const { label, onChange = () => {}, defaultValue, editActivity, disabled = false } = props;

  useEffect(() => {
    if (editActivity) {
      const selectedActivity = activityType.find(item => item.activity_type_code === editActivity.activity_type);
      if (selectedActivity) {
        onChange(null, selectedActivity);
      }
    }
  }, [editActivity, activityType]);

  return (
    <div>
      <CustomMuiDropdown
        label={label}
        options={options}
        value={defaultValue}
        // Custom logic can be handled flexibly based on the requirement
        getOptionLabel={option => {
          if (option.labels?.length === 0) {
            return 'No Label';
          }
          return option?.labels?.find(item => item?.language_code === navigator.language)?.label ?? option?.labels?.find(item => item?.language_code === 'en-US')?.label;
        }}
        onChange={onChange}
        autoCompleteProps={{
          size: 'small',
          // For Objects comparison it is required to use this function as the default comparison is done by reference
          isOptionEqualToValue: (option, value) => {
            return option?.labels?.find(item => item?.activity_type_code === value?.activity_type_code) !== undefined;
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};

ActivityTypeSelection.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  editActivity: PropTypes.any,
  disabled: PropTypes.bool
};

export default ActivityTypeSelection;
