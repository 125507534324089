import * as React from 'react';
const SpeakerSVGComponent = props => (
  <svg width={15} height={15} viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.92843 10C7.81282 9.99939 7.6998 9.96666 7.60273 9.90567L3.92851 7.46432H1.07142C0.919868 7.46432 0.774526 7.40586 0.667365 7.30181C0.560203 7.19775 0.5 7.05662 0.5 6.90947V3.10318C0.5 2.95602 0.560203 2.81489 0.667365 2.71083C0.774526 2.60678 0.919868 2.54832 1.07142 2.54832H3.92851L7.5913 0.106968C7.67738 0.0458496 7.77935 0.00933883 7.88569 0.00156582C7.99202 -0.0062072 8.09848 0.0150677 8.19301 0.0629842C8.28754 0.110901 8.36638 0.183547 8.42061 0.272705C8.47483 0.361864 8.50228 0.463978 8.49985 0.567497V9.44515C8.49985 9.5923 8.43965 9.73343 8.33249 9.83749C8.22533 9.94154 8.07998 10 7.92843 10Z'
      fill='#666666'
    />
  </svg>
);
export default SpeakerSVGComponent;
