import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  projects: [],
  recentActivities: [],
  projectStats: {},
  lastUpdatedAt: null,
  gridConfig: {
    settings: [],
    sort: [],
    filter: {}
  }
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateHomeProjects(state, action) {
      state.projects = action.payload;
      state.lastUpdatedAt = dayjs();
    },
    updateHomeRecentActivities(state, action) {
      state.recentActivities = action.payload;
      // state.lastUpdatedAt = dayjs();
    },
    updateProjectStats(state, action) {
      state.projectStats = action.payload;
      state.lastUpdatedAt = dayjs();
    },
    updateGridConfig(state, action) {
      state.gridConfig = action.payload;
    },
    resetHomeState: () => initialState
  }
});

export const homeActions = homeSlice.actions;

export default homeSlice.reducer;
