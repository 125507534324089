import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import { Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomConfirmationPopup from '../../../../components/common/CustomConfirmationPopup';
import { ignWorkbenchDataApi, ignWorkbenchQueryApi } from '../../../../services/ApiService';
import { DELETE, ERROR, PUT, SUCCESS } from '../../../../services/constantService';
import SpeakerSVGComponent from '../../../../components/Icons/SearchIcon';

const DropDownListComponent = ({ data, onClick, types, selectedId, refetch, onLoadMore, isLoading, hasMore, showDeleteBtn = false, showRestoreIcon = false }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [actionLoadingId, setActionLoadingId] = useState(null);

  const { t } = useTranslation();

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let timeoutId;
    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

        if (scrollPercentage > 0.8 && !isLoading && hasMore && onLoadMore) {
          onLoadMore();
        }
      }, 150);
    };

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading, hasMore, onLoadMore]);

  const handleDeleteConfirmation = (id, event) => {
    event.stopPropagation();
    setSelectedDeleteId(id);
    setShowConfirmation(true);
  };

  const handleDeleteWorkbenchQuery = async () => {
    try {
      setActionLoadingId(selectedDeleteId);
      await ignWorkbenchQueryApi(DELETE, null, `/${selectedDeleteId}`);
      enqueueSnackbar('Query is archived successfully', { variant: SUCCESS });
      refetch && refetch();
      setActionLoadingId(null);
    } catch (err) {
      enqueueSnackbar('Error archiving query', { variant: ERROR });
    } finally {
      setShowConfirmation(false);
      setActionLoadingId(null);
    }
  };

  const handleDeleteWorkbenchList = async () => {
    try {
      setActionLoadingId(selectedDeleteId);
      const payload = { id: selectedDeleteId };
      const res = await ignWorkbenchDataApi(DELETE, '', payload, 'delete-workbench');
      if (res?.data) {
        enqueueSnackbar('Workbench List is archived successfully', { variant: SUCCESS });
        refetch && refetch();
        setActionLoadingId(null);
      }
    } catch (err) {
      enqueueSnackbar('Error archiving list', { variant: ERROR });
    } finally {
      setShowConfirmation(false);
      setActionLoadingId(null);
    }
  };

  const handleRestoreWorkbenchList = async id => {
    try {
      setActionLoadingId(id);
      const res = await ignWorkbenchDataApi(PUT, `restore/${id}`);
      if (res?.data) {
        enqueueSnackbar('Workbench List restored successfully', { variant: SUCCESS });
        refetch && refetch();
        setActionLoadingId(null);
      }
    } catch (err) {
      enqueueSnackbar('Error archiving list', { variant: ERROR });
    } finally {
      setShowConfirmation(false);
      setActionLoadingId(null);
    }
  };

  const handleRestoreQueryList = async id => {
    try {
      setActionLoadingId(id);
      await ignWorkbenchQueryApi(PUT, null, `restore/${id}`);
      enqueueSnackbar('Workbench List restored successfully', { variant: SUCCESS });
      refetch && refetch();
      setActionLoadingId(null);
    } catch (err) {
      enqueueSnackbar('Error archiving list', { variant: ERROR });
    } finally {
      setShowConfirmation(false);
      setActionLoadingId(null);
    }
  };

  const renderIcon = type => {
    switch (type?.toLowerCase()) {
      case types?.PROJECT?.toLowerCase():
        return <SpeakerSVGComponent className='fs-16' />;
      case types?.CONTACT?.toLowerCase():
        return <AssignmentIndOutlinedIcon className='fs-16' />;
      case types?.COMPANY?.toLowerCase():
        return <ApartmentOutlinedIcon className='fs-16' />;
      case types?.CANDIDATE?.toLowerCase():
        return <PersonAddAltOutlinedIcon className='fs-16' />;
      default:
        return null;
    }
  };

  return (
    <div className='mt-2'>
      {(!data || data.length === 0) && !showRestoreIcon && !isLoading && <div className='fs-12 ml-4'>No data</div>}
      {showRestoreIcon && (!data || data.length === 0) && !isLoading && <div className='fs-12 ml-4'>Items you archive will appear here</div>}
      <div
        ref={containerRef}
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollBehavior: 'smooth'
        }}
      >
        {data?.map((elem, index) => (
          <Grid
            key={elem.id || index}
            container
            onClick={() => onClick(elem)}
            component='div'
            className={`cursor-pointer rounded d-flex align-items-center p-2 mt-1 ${elem?.id === selectedId ? 'background-color-selected' : 'hover:bg-gray-100'}`}
          >
            <Grid item xs={2}>
              {renderIcon(elem?.query_type || elem?.type)}
            </Grid>
            <Grid item xs={9}>
              <div className='fs-14'>{elem?.query_name || elem?.name}</div>
            </Grid>
            {actionLoadingId === elem?.id && (
              <div className='text-center py-2'>
                <CircularProgress size={20} />
              </div>
            )}
            {!actionLoadingId && showDeleteBtn && !showRestoreIcon && (
              <Grid item xs={1}>
                <Box display='flex' justifyContent='flex-end'>
                  <Tooltip title={t('actions.archive')} arrow PopperProps={{ disableInteractive: true }}>
                    <DeleteOutlinedIcon className='fs-16 hover:text-red-500' onClick={event => handleDeleteConfirmation(elem?.id, event)} />
                  </Tooltip>
                </Box>
              </Grid>
            )}
            {!actionLoadingId && showRestoreIcon && !showDeleteBtn && (
              <Grid item xs={1}>
                <Box display='flex' justifyContent='flex-end'>
                  <Tooltip title={t('actions.restore')} arrow>
                    <RestoreOutlinedIcon className='fs-16 hover:text-red-500' onClick={event => (elem?.query_name ? handleRestoreQueryList(elem?.id) : handleRestoreWorkbenchList(elem?.id))} />
                  </Tooltip>
                </Box>
              </Grid>
            )}
          </Grid>
        ))}
        {isLoading && (
          <div className='text-center py-2'>
            <CircularProgress size={20} />
          </div>
        )}
      </div>
      <CustomConfirmationPopup
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        type={`${t('actions.archiving')} ${data?.find(item => item.id === selectedDeleteId)?.query_type || data?.find(item => item.id === selectedDeleteId)?.type}`}
        cancelText={`${t('actions.cancel')}`}
        confirmText={`${t('actions.ok')}`}
        onConfirm={data?.find(item => item.id === selectedDeleteId)?.query_type ? handleDeleteWorkbenchQuery : handleDeleteWorkbenchList}
        customMessage={
          <span>
            {t('utils.sureToArchiveMessage')} {data?.find(item => item.id === selectedDeleteId)?.query_type ? 'query' : 'list'}{' '}
            <b>{data?.find(item => item.id === selectedDeleteId)?.query_name || data?.find(item => item.id === selectedDeleteId)?.name}</b>?
          </span>
        }
        setOpen={setShowConfirmation}
      />
    </div>
  );
};

DropDownListComponent.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  types: PropTypes.object,
  selectedId: PropTypes.string,
  refetch: PropTypes.func,
  onLoadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  showDeleteBtn: PropTypes.bool,
  showRestoreIcon: PropTypes.bool
};

export default DropDownListComponent;
