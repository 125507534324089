import DownloadIcon from '@mui/icons-material/Download';
import { Grid, Slider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ignAthenaDataApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import CustomButton from '../../common/CustomButton';
import CustomDropdown from '../../common/CustomDropdown';
import Loader from '../../common/Loader';
import { DownloadAthenaReport } from './../../../components/common/AthenaReport';

function AthenaScore(props) {
  const { contactId } = props;
  const [loader, setLoader] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [assessmentsIds, setAssessmentIds] = useState([]);
  const [assessmentScores, setAssessmentScore] = useState([]);
  const [dropdownValue, setDropdownValue] = useState({});
  const [selectedAssessment, setSelectedAssessment] = useState();

  const marks = [
    {
      value: 0,
      label: '50'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 50,
      label: '0'
    },
    {
      value: 75,
      label: '25'
    },
    {
      value: 100,
      label: '50'
    }
  ];

  const fetchAssessmentsByContactId = async id => {
    try {
      setLoader(true);
      const contactAssessments = await ignAthenaDataApi(GET, '', '', `get-assessments/${id}`);
      const assessments = contactAssessments?.data;
      const assessmentOptions = assessments.map((assessment, index) => {
        return { name: `Assessment ${index + 1}`, value: assessment?.id, ...assessment };
      });
      setAssessmentIds(assessmentOptions);
      if (assessmentOptions.length > 0) {
        handleAssessmentDropdownChange('', assessmentOptions[0]);
      }
    } catch (err) {
      enqueueSnackbar(err);
    } finally {
      setLoader(false);
    }
  };

  const handleAssessmentDropdownChange = async (e, value) => {
    try {
      if (value) {
        setLoader(true);
        const assessmentId = value?.value;
        setSelectedAssessment(value);
        const assessmentScore = await ignAthenaDataApi(GET, '', '', `assessment-score-by-assessment-id/${assessmentId}`);
        setAssessmentScore(assessmentScore?.data);
        setDropdownValue(value);
      }
    } catch (err) {
      enqueueSnackbar(err);
    } finally {
      setLoader(false);
    }
  };

  const handleAthenaReportDownload = async () => {
    try {
      setIsDownloading(true);
      if (selectedAssessment) {
        const { contact_id, project_id, language_used = 'ENG' } = selectedAssessment;
        await DownloadAthenaReport(contact_id, project_id, language_used);
      } else {
        enqueueSnackbar('Please select an assessment', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(err);
    } finally {
      setIsDownloading(false);
    }
  };
  useEffect(() => {
    fetchAssessmentsByContactId(contactId);
  }, [contactId]);
  return (
    <div className='p-3'>
      <Loader show={loader} />
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex justify-content-between align-items-center'>
          <CustomDropdown options={assessmentsIds} value={dropdownValue} onChange={handleAssessmentDropdownChange} />
          <CustomButton isLoading={isDownloading} type={'tertiary ml-1'} buttonText={<DownloadIcon></DownloadIcon>} onClick={handleAthenaReportDownload} />
        </div>
        <div className='d-flex'>
          <div className='fs-16 fw-bold'>Invited on :</div>
          <span className='fs-16 ml-1'>{assessmentsIds.length > 0 ? new Date(assessmentsIds[0]?.created_at).toLocaleDateString() : ''}</span>
        </div>
        <div className='d-flex'>
          <div className='fs-16 fw-bold'>Completed on :</div>
          <span className='fs-16 ml-1'>{assessmentsIds.length > 0 ? new Date(assessmentsIds[0]?.completed_at).toLocaleDateString() : ''}</span>
        </div>
      </div>

      <div className='athena-score-component mt-2'>
        {assessmentScores.map(score => (
          <>
            <Grid container className='p-1'>
              <Grid item xs={4} className='border p-3 w-100 question rounded-left d-flex align-items-center'>
                <div
                  className='question-font'
                  dangerouslySetInnerHTML={{
                    __html: score?.ign_assessment_questions?.left_question || '-'
                  }}
                ></div>
              </Grid>
              <Grid item xs={4} className='d-flex flex-column border p-3 w-100 align-items-center justify-content-around athena-question-box'>
                <Slider
                  // aria-label="Custom marks"
                  // getAriaValueText={valuetext}
                  step={1}
                  value={score?.score}
                  marks={marks}
                  track={false}
                  className='question-slider'
                  // valueLabelDisplay="on"
                />
              </Grid>
              <Grid item xs={4} className='border p-3 question rounded-right d-flex align-items-center'>
                <div
                  className='question-font'
                  dangerouslySetInnerHTML={{
                    __html: score?.ign_assessment_questions?.right_question || '-'
                  }}
                ></div>
              </Grid>
            </Grid>
          </>
        ))}
      </div>
    </div>
  );
}

AthenaScore.propTypes = {
  contactId: PropTypes.string,
  data: PropTypes.any
};

export default AthenaScore;
