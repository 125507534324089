//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import variables from '../../../variables.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DateTimeTimezonePickers(props) {
  const { onChange, defaultValue, label, timezone, timezoneOptions, handleTimezoneChange, value, required, timezoneRequired } = props;
  return (
    <>
      <TextField
        select
        label={
          timezoneRequired ? (
            <Box component='span' className='required'>
              Timezone
            </Box>
          ) : (
            'Timezone'
          )
        }
        value={timezone}
        onChange={handleTimezoneChange}
        SelectProps={{
          native: true
        }}
        size='small'
        required={timezoneRequired} // This makes the field required
        error={timezoneRequired && !timezone} // Display an error if no timezone is selected
        helperText={timezoneRequired && !timezone ? 'Please select a timezone' : ''}
      >
        {timezoneOptions?.map(tz => (
          <option key={tz} value={tz}>
            {tz}
          </option>
        ))}
      </TextField>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'MobileDateTimePicker', 'DesktopDateTimePicker', 'StaticDateTimePicker']}>
          <DateTimePicker
            timezone={timezone}
            onAccept={onChange}
            defaultValue={defaultValue ? dayjs(defaultValue).tz(timezone) : null}
            label={
              required ? (
                <Box component='span' className='required'>
                  {' '}
                  {label}{' '}
                  <Box component={'span'} className='text-danger'>
                    {' '}
                    *{' '}
                  </Box>
                </Box>
              ) : (
                label
              )
            }
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: variables.heading }
            }}
            slotProps={{ textField: { size: 'small' } }}
            value={value ? dayjs(value).tz(timezone) : null}
            className='date-time-picker'
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
}

DateTimeTimezonePickers.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  timezone: PropTypes.string,
  timezoneOptions: PropTypes.array,
  handleTimezoneChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  timezoneRequired: PropTypes.bool
};
