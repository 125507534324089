// import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import propTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AttendeesComponent, DateComponent, NameComponent, ProjectName, TimeComponent } from './components';

// let columnApi;
function InterviewTable(props) {
  const { data } = props;
  // const [isGridReady, setIsGridReady] = useState(false);
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      filter: true,
      minHeight: 300,
      resizable: true,
      sortable: true
    };
  }, []);

  // const onGridReady = (params) => {
  //   columnApi = params.columnApi;
  //   params.api.sizeColumnsToFit();
  //   params.columnApi.autoSizeColumns();

  //   setIsGridReady(true);
  //   // refresh the grid for row data
  //   params.api.refreshCells();
  // };

  const agCol = () => {
    return [
      {
        minWidth: 100,
        field: 'project',
        headerName: 'Project ID',
        editable: false,
        cellRenderer: 'projectRenderer'
      },
      {
        minWidth: 200,
        field: 'ign_contacts.name',
        headerName: 'Candidate Name',
        cellRenderer: 'nameRenderer',
        editable: false
      },
      {
        minWidth: 250,
        field: 'project.job_title',
        headerName: 'Job Title',
        editable: false
      },
      {
        minWidth: 200,
        field: 'project.name',
        headerName: 'Project Name',
        editable: false
      },
      {
        minWidth: 250,
        field: 'attendees',
        headerName: 'Attendees',
        cellRenderer: 'attendeesRenderer',
        editable: false
      },
      {
        minWidth: 100,
        field: 'interview_date',
        headerName: 'Time scheduled',
        cellRenderer: 'timeRenderer',
        editable: false,
        sort: 'desc'
      },
      {
        minWidth: 100,
        field: 'interview_date',
        headerName: 'Date scheduled',
        cellRenderer: 'dateRenderer',
        editable: false,
        sort: 'desc'
      }
    ];
  };

  return (
    <div
      className={'px-4 ag-theme-alpine'}
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <AgGridReact
        rowStyle={{ justifyContent: 'start' }}
        columnDefs={agCol()}
        rowData={data}
        rowSelection='multiple'
        defaultColDef={defaultColDef}
        components={{
          attendeesRenderer: AttendeesComponent,
          timeRenderer: TimeComponent,
          dateRenderer: DateComponent,
          projectRenderer: ProjectName,
          nameRenderer: NameComponent
        }}
        rowHeight={70}
      />
    </div>
  );
}
//proptypes
InterviewTable.propTypes = {
  data: propTypes.array
};

export default InterviewTable;
