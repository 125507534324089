//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import AddIcon from '@mui/icons-material/Add';
import FlagTwoToneIcon from '@mui/icons-material/FlagTwoTone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import { Button, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';

import CompensationPopover from '../../src/Containers/Searches/AllCandidates/CompensationPopover';
import getCurrencyDetailsFromCode from '../Containers/Commons/GetCurrencyDetailsFromCode';
import { currencyValueSetter, getCurrencyPrefix } from '../Containers/Commons/Utils';
import { checkContactFetchingStatus } from '../Containers/Contacts/utils';
import RichTextPopup from '../components/RichTextPopup/index';
import RichTextBoxPopover from '../components/common/RichTextBoxPopover';
import { IgnContactFromResumeApi } from '../services/ApiService';
import { requireMessage, requireTemplateMessage, validateMessage } from '../services/MessageService';
import { DISALLOW_EXTENSION, EMAIL_REGEX, ERROR, HTML_TAGS_FILTER_REGEX, IGN_API, LINKEDIN_URL_PROFILE_REGEX, LOCAL_SOCKET_URL, LOCAL_URL, POST } from '../services/constantService';
import { addHttps } from './string';

import ISO6391 from 'iso-639-1';

export const SEARCH_TIMEOUT = 500; // Timeout in milliseconds

export const IGNYTE_INTELLIGENCE = 'ignyte_intelligence_assisted';

export const INSIDE_TRACK = 'Inside Track';
export const REFETCH_TIME_DELAY_MIN = 10;
export const SCROLL_TIMEOUT = 500; // Time to wait before fetching the data while scrolling in grid
export const getContactField = (data, field) => {
  return (data && data.contact && data.contact[field]) || '';
};

export const getCurrencyDetails = currencyCode => {
  if (currencyCode) {
    const details = getCurrencyDetailsFromCode(currencyCode);
    return details;
  }
  return '';
};

export const tooltipValueGrid = params => {
  let url = params?.value?.replace(/[&\\#,+()$~^%!&@_'"*?<>{}]/g, '');
  if (url && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  return url;
};

export const tooltipValue = value => {
  let url = value?.replace(/[&\\#,+()$~^%!&@_'"*?<>{}]/g, '');
  if (url && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  return url;
};

// export const tooltipValueField = (value) => {
//   return value = value?.replace(/[&\\#,+()$~^%!&@_'"*?<>{}]/g, '');
// }

export const RichTextPopupRendered = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  const key = params.column && params.column.colId;
  const sourceField = params.sourceField;
  const getValue = () => {
    return sourceField ? params?.data[sourceField] && params?.data[sourceField][0][key] : params?.data[key];
  };
  return (
    <div>
      {params?.data && (
        <RichTextBoxPopover placement={params?.placement} value={getValue()}>
          <div>
            <RichTextPopup renderGrid={true} value={getValue()} />
          </div>
        </RichTextBoxPopover>
      )}
    </div>
  );
};

export function highlightText(word, wordToHighlight) {
  if (!wordToHighlight) return word;

  // Escape special characters in the wordToHighlight
  const escapedWord = wordToHighlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create the regex with the escaped word
  const regex = new RegExp(`(${escapedWord})`, 'gi');
  return word?.replace(regex, '<span class="highlight">$1</span>');
}

export const ReadMoreHTML = ({ htmlContent, allText, showMoreText, onDoubleClick, maxWidth, textStyles, readMoreStyles }) => {
  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 700,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      boxShadow: 'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;'
    }
  }))(Tooltip);

  return (
    <Typography
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: maxWidth || '500px',
        width: '100%',
        ...textStyles
      }}
      onDoubleClick={onDoubleClick}
    >
      {htmlContent}
      <HtmlTooltip title={allText} placement='top'>
        <Typography sx={{ fontSize: '12px', fontStyle: 'italic', ...readMoreStyles }} className={'fs-12 text-blue'}>
          {showMoreText}
        </Typography>
      </HtmlTooltip>
    </Typography>
  );
};

ReadMoreHTML.propTypes = {
  htmlContent: PropTypes.string,
  showMoreText: PropTypes.node,
  allText: PropTypes.string,
  onDoubleClick: PropTypes.func,
  maxWidth: PropTypes.string,
  textStyles: PropTypes.object,
  readMoreStyles: PropTypes.object
};

export function renderRichText(text, { maxWidth = null, maxCount = 50, textStyles = {}, readMoreStyles = {}, showReadMore = true, readMoreText = 'Read More' } = {}) {
  return (
    <ReadMoreHTML
      maxWidth={maxWidth}
      textStyles={textStyles}
      readMoreStyles={readMoreStyles}
      htmlContent={
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          style={{
            display: '-webkit-box',
            maxWidth: maxWidth || '500px',
            width: '100%',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            lineClamp: 2,
            fontSize: '12px',
            textOverflow: 'ellipsis',
            ...textStyles
          }}
        />
      }
      allText={<div dangerouslySetInnerHTML={{ __html: text }} />}
      showMoreText={
        text?.length > maxCount && showReadMore ? (
          <div
            style={{
              textDecoration: 'underline',
              fontSize: '12px'
            }}
          >
            {readMoreText}
          </div>
        ) : (
          ''
        )
      }
    />
  );
}

export const handelScroll = item => {
  let scrollItem;
  if (item) {
    scrollItem = document.getElementById(item);
    window.history.pushState(item, item, '#' + item);
  } else {
    let url = window.location.href.split('#');
    let target = url[url?.length - 1];
    scrollItem = document.getElementById(target);
  }

  scrollItem && scrollItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const customFormValidator = (data, field) => {
  let missingFieldsArr = [];
  field.forEach(ele => {
    if (Array.isArray(ele.type)) {
      if (data[ele.fieldName]?.length == 0 || !data[ele.fieldName]) {
        missingFieldsArr.push(ele.label);
      } else if (ele?.obj?.type === String) {
        if (Array.isArray(data[ele.fieldName])) {
          data[ele?.fieldName]?.forEach(item => {
            if (item[ele?.obj?.fieldName] === null || item[ele?.obj?.fieldName] === undefined || item[ele?.obj?.fieldName] === '') {
              missingFieldsArr.push(ele.obj.label);
            }
          });
        }
      }
    } else if (ele.type === Object) {
      if (!data[ele.fieldName] || data[ele.fieldName][ele.obj.fieldName] === null || data[ele.fieldName][ele.obj.fieldName] === undefined || data[ele.fieldName][ele.obj.fieldName] === '') {
        missingFieldsArr.push(ele.obj.label);
      }
    } else {
      if (ele.type === Number) {
        if (data[ele.fieldName] === null || data[ele.fieldName] === undefined) {
          missingFieldsArr.push(ele.label);
        }
      } else if (!data[ele.fieldName] && !data[ele.fieldName] != '' && !data[ele.fieldName] != null) {
        missingFieldsArr.push(ele.label);
      }
    }
  });
  if (missingFieldsArr?.length > 0) {
    return missingFieldsArr;
  }
  return false;
};

export const customTemplateFormValidator = (data, fields) => {
  const missingFieldsArr = [];
  fields.forEach(field => {
    const { fieldName, label, type, min, max } = field;

    if (Array.isArray(type)) {
      if (!data[fieldName] || data[fieldName]?.length === 0) {
        missingFieldsArr.push({ label, min, max });
      }
    } else if (type === String) {
      const fieldValue = data[fieldName];
      if (fieldValue === null || fieldValue === undefined || fieldValue === '') {
        missingFieldsArr.push({ label, min, max });
      }
    } else if (type === Number) {
      const fieldValue = data[fieldName];
      if (fieldValue === null || fieldValue === undefined || fieldValue < min || fieldValue > max) {
        missingFieldsArr.push({ label, min, max });
      }
    } else {
      if (!data[fieldName] && data[fieldName] !== '' && data[fieldName] !== null) {
        missingFieldsArr.push({ label, min, max });
      }
    }
  });

  if (missingFieldsArr?.length > 0) {
    return missingFieldsArr;
  }
  return false;
};

export const requireValidTemplateMessage = dirtyFields => {
  const errorMessages = dirtyFields.map(field => {
    const { label, min, max } = field;
    return requireTemplateMessage(label, 'is', min, max);
  });

  if (errorMessages?.length >= 1) {
    return errorMessages.join(', ');
  } else {
    return errorMessages[0];
  }
};

export const getColorDot = value => {
  let color = '';
  if (value > 85) {
    color = '#26C137';
  } else if (value <= 85 && value >= 65) {
    color = '#FFA500';
  } else if ((value === 0 || value > 0) && value < 85) {
    color = '#808080';
  } else {
    color = '';
  }

  return (
    <div className='product-one-score-content'>
      <div className='product-one-color' style={{ backgroundColor: color }}></div>
      <span>{value}</span>
    </div>
  );
};

export const getFlagForLifer = value => {
  if (value === 1.0) {
    return (
      <div>
        <FlagTwoToneIcon style={{ color: '#FFA500' }}></FlagTwoToneIcon>
      </div>
    );
  }
  return <div></div>;
};

export const customFormValidatorInline = (field, requiredField) => {
  let missingFieldsArr = [];
  requiredField.forEach(element => {
    if (element.fieldName === field) {
      missingFieldsArr.push(element.label);
    }
  });
  if (missingFieldsArr?.length > 0) {
    return missingFieldsArr;
  }
  return false;
};
export const requireValidMessage = dirtyField => {
  return dirtyField?.length > 1 ? requireMessage(dirtyField.join(', '), 'are') : requireMessage(dirtyField, 'is');
};

export const splitLastIfMultiple = dirtyField => {
  if (dirtyField.length > 1) {
    let lastElement = dirtyField.splice(dirtyField.length - 1, 1);
    let initialElements = dirtyField.join(', ');
    return [initialElements, ...lastElement];
  } else {
    return [false, dirtyField[0]];
  }
};

export const validateSfpaMessage = inValidFields => {
  let less2message = '';
  let maxtokenMsg = '';
  inValidFields.map((msg, index) => {
    msg.includes('MaxTokens') || msg.includes('Temperature') ? (maxtokenMsg = maxtokenMsg + msg) : (less2message = less2message + msg + (index !== inValidFields?.length - 1 ? ', ' : ' '));
  });
  let finalMsg =
    (less2message?.length > 0 ? less2message : '') + (less2message?.length > 0 ? 'must be in between 0 to 2 ' : '') + (maxtokenMsg?.length > 0 && less2message?.length > 0 ? 'and ' : '') + maxtokenMsg;
  return finalMsg;
};

export const CompensationRenderer = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params && params.data && !params.data.contact) {
    const currencyCode = params.data.currency_iso_code ? params.data.currency_iso_code : '';
    const baseSalary = params.data.base_salary ? params.data.base_salary : '';
    const targetBonus = params.data.target_bonus_value ? params.data.target_bonus_value : '';
    const equity = params.data.equity ? params.data.equity : '';

    return (
      <CompensationPopover targetBonus={targetBonus} equity={equity} currencyCode={currencyCode} baseSalary={baseSalary} contactDetails={params.data}>
        <div>
          <NumericFormat
            style={{ border: 'none', fontSize: '15px' }}
            color='primary'
            value={params.colDef.field === 'base_salary' ? baseSalary : targetBonus}
            thousandSeparator={true}
            decimalSeparator='.'
            displayType='text'
            prefix={getCurrencyPrefix(currencyCode !== 'RUR' ? currencyCode : '')}
            suffix={getCurrencyPrefix(currencyCode === 'RUR' ? 'RUR' : '')}
            renderText={data => {
              const value = currencyValueSetter(data, currencyCode);
              return value ? value : '--';
            }}
          />
        </div>
      </CompensationPopover>
    );
  }
  if (params && params.data && params.data.contact) {
    const currencyCode = params.data.contact.currency_iso_code ? params.data.contact.currency_iso_code : '';
    const baseSalary = params.data.contact.base_salary ? params.data.contact.base_salary : '';
    const targetBonus = params.data.contact.target_bonus_value ? params.data.contact.target_bonus_value : '';
    const equity = params.data.contact.equity ? params.data.contact.equity : '';

    return (
      <CompensationPopover targetBonus={targetBonus} equity={equity} currencyCode={currencyCode} baseSalary={baseSalary} contactDetails={params.data.contact}>
        <div>
          <NumericFormat
            style={{ border: 'none', fontSize: '15px' }}
            color='primary'
            value={baseSalary}
            thousandSeparator={true}
            decimalSeparator='.'
            displayType='text'
            prefix={getCurrencyPrefix(currencyCode !== 'RUR' ? currencyCode : '')}
            suffix={getCurrencyPrefix(currencyCode === 'RUR' ? 'RUR' : '')}
            renderText={data => {
              const value = currencyValueSetter(data, currencyCode);
              return value ? value : '--';
            }}
          />
        </div>
      </CompensationPopover>
    );
  }
  return '';
};

export const compareByPosition = (firstElement, secondElement) => firstElement.position - secondElement.position;

export const compareByStartYear = (firstElement, secondElement) => {
  if ((firstElement?.start_year || null) === (secondElement?.start_year || null)) {
    return compareByEndYear(firstElement, secondElement);
  } else {
    return (firstElement?.start_year || null) - (secondElement?.start_year || null);
  }
};
export const compareByEndYear = (firstElement, secondElement) => {
  return (firstElement?.end_year || null) - (secondElement?.end_year || null);
};

export const getPresentYearValue = year => {
  if (year === 'Present') {
    const currentDate = new Date();
    const newYear = currentDate.getFullYear();
    return newYear.toString();
  }
  return year;
};

export const sortByChronologicalOrder = details => {
  // display records first having end_year Present
  const presentYearDetails = details
    ?.filter(ele => ele.end_year === 'Present')
    .sort(compareByStartYear)
    .reverse();
  const withoutPresentYear = details
    ?.filter(ele => ele.end_year !== 'Present')
    .sort(compareByStartYear)
    .reverse();
  const joinArray = [...presentYearDetails, ...withoutPresentYear];
  return joinArray;
};
export function isEmpty(value) {
  return value === undefined || value === null || (typeof value === 'object' && Object.keys(value)?.length === 0) || (typeof value === 'string' && value.trim()?.length === 0) || !value;
}

export const LinkedInRenderer = (url, fontSize = 'inherit') => {
  return url ? (
    <a className='contact-profile-link' target='_blank' rel='noopener noreferrer' href={`${addHttps(url)}`}>
      <LinkedInIcon fontSize={fontSize} style={{ color: 'rgb(40, 103, 178)' }} />
    </a>
  ) : (
    <span className='contact-profile-link'>
      <LinkedInIcon fontSize={fontSize} color='disabled' />
    </span>
  );
};

export const cleanupFormValues = data => {
  const values = { ...data };
  const keys = ['screening_notes', 'career_snapshot_profile', 'recommendations_profile', 'personal_profile', 'published_bio'];
  keys.forEach(key => delete values[key]);
  return values;
};

export const RedirectToCompanies = ({ name, id, showInLink }) => {
  return (
    <React.Fragment>
      <Link to={`/companies/${id}/details`} target='_blank' rel='noopener noreferrer'>
        <span className={!showInLink === true ? 'action-text company-redirect-link' : ''}>{name}</span>
      </Link>
    </React.Fragment>
  );
};

export const filterTags = string => {
  const filterString = string ? string.replace(HTML_TAGS_FILTER_REGEX, '') : '';
  if (filterString) return string;
  else return '--';
};

export const checkFilterAndSort = (filter, sort) => {
  if (filter && sort && Object.keys(filter)?.length === 0 && Object.keys(sort)?.length === 0) return true;
  return false;
};

const getExtension = filename => {
  var parts = filename.split('.');
  return parts[parts?.length - 1];
};

export const checkIsImage = filename => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
    case 'tif':
    case 'pjp':
    case 'xbm':
    case 'jxl':
    case 'svgz':
    case 'jpeg':
    case 'ico':
    case 'tiff':
    case 'svg':
    case 'jfif':
    case 'webp':
      return true;
    default:
      return false;
  }
};

export const linkedinChecker = str => {
  const linkedinUrl = str.split('/');
  if (linkedinUrl && linkedinUrl[0].includes('http')) {
    const url = linkedinUrl[4];
    return `/${url}/`;
  } else {
    const url = linkedinUrl[2];
    return `/${url}/`;
  }
};

RedirectToCompanies.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  showInLink: PropTypes.bool
};

export const AddRecordButton = props => {
  const { iconColor = 'red', children, color = '#000' } = props;
  return (
    <Button
      {...props}
      variant={'text'}
      startIcon={<AddIcon style={{ color: iconColor }} />}
      className='button-text-capitalized'
      style={{
        position: 'relative',
        bottom: '-6px'
      }}
    >
      <span
        style={{
          fontWeight: '600',
          fontSize: '13px',
          fontStyle: 'italic',
          color
        }}
      >
        {children}
      </span>
    </Button>
  );
};

AddRecordButton.propTypes = {
  iconColor: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string
};

export const getFixedDecimal = (number, decimal = 2) => {
  if (!number) return 0;
  number = number.toString().split('.');
  if (number[1] && number[1]?.length > 2) number[1] = number[1].slice(0, decimal);
  return Number(number.join('.'));
};

export const getValueWithoutHtml = value => {
  if (!value) return false;
  let text = value.replace(/(<([^>]+)>)/gi, '');
  if (text.trim() === '') {
    return false;
  } else {
    return true;
  }
};

export const getInvoiceEmail = (formData, enqueueSnackbar) => {
  if (formData?.email_invoice_to) {
    let separatedArray = formData.email_invoice_to.split(',');
    for (let x of separatedArray) {
      let email = [];
      email = validateInvoiceEmail(x, enqueueSnackbar);
      if (!email) return false;
    }
    return true;
  } else {
    return true;
  }
};

const validateInvoiceEmail = (email, enqueueSnackbar) => {
  if (email && !email.match(EMAIL_REGEX)) {
    const message = validateMessage('valid invoice email', 'enter');
    enqueueSnackbar(message, { variant: ERROR });
    return false;
  }
  return true;
};

export const isValidURL = string => {
  //eslint-disable-next-line
  const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return res !== null;
};
// function used for restrict some file extension

export const disallowFiles = (fileName, enqueueSnackbar, list, index) => {
  let extension = fileName && fileName.split('.').pop();
  let file = list[index];
  if (file.allowed_extension && file.doctype_code && fileName) {
    if (file.doctype_code !== 'Other') {
      let array = file.allowed_extension && file.allowed_extension.split(',');

      const trimArray =
        array &&
        array.map(element => {
          return element.trim();
        });

      let isValid = trimArray && trimArray.includes(extension);

      if (!isValid) {
        enqueueSnackbar(`${file.doc_desc} does not support  ${extension} type`, { variant: ERROR });
        return false;
      } else {
        return true;
      }
    } else {
      if (DISALLOW_EXTENSION.exec(fileName)) {
        enqueueSnackbar(`${file.doc_desc} does not support  ${extension} type`, { variant: ERROR });
        return false;
      }
      return true;
    }
  } else {
    enqueueSnackbar('Please select document type', { variant: ERROR });
  }
};

export const validateLinkedinProfileURL = url => {
  if (url) {
    return url.match(LINKEDIN_URL_PROFILE_REGEX);
  }
};

export const getDynamicUrl = (url, restOfDomain) => {
  const host = url.includes('localhost') ? `${LOCAL_URL}/api/v1/auth` : `${url}.app.${restOfDomain}/api/v1/auth`;
  localStorage.setItem('host', url.includes('localhost') ? LOCAL_SOCKET_URL : `${url}.app.${restOfDomain}`);
  return host;
};

export const getDynamicUrlForUser = (url, restOfDomain) => {
  const host = url.includes('localhost') ? `${LOCAL_URL}/api/users/profile` : `${url}.app.${restOfDomain}/api/users/profile`;
  localStorage.setItem('host', url.includes('localhost') ? LOCAL_SOCKET_URL : `${url}.app.${restOfDomain}`);
  return host;
};

export const checkDuplicateOnBlur = async (dataToBeCheck, setLoading) => {
  setLoading(true);
  const duplicateRecord = await IgnContactFromResumeApi(POST, `${IGN_API.check_contact_duplication}`, dataToBeCheck);
  setLoading(false);
  if (duplicateRecord?.data?.exactMatch?.length > 0) {
    return {
      isDuplicate: true,
      duplicateRecords: duplicateRecord
    };
  } else {
    return {
      isDuplicate: false
    };
  }
};

export const convertArrayBufferToBlobUrl = data => {
  const uint8ArrayData = new Uint8Array(data);
  const blob = new Blob([uint8ArrayData]);
  return URL.createObjectURL(blob);
};

export const roundOffToDecimalFirst = val => {
  return Math.round(val * 10) / 10;
};

export function getLangCodes() {
  const languages = ISO6391.getAllNames();

  return languages.map(lang => lang?.slice(0, 3)?.toUpperCase());
}

export function getTranslateValue(t, constant) {
  return t(`${constant}:key`);
}

export function getInputLabelPropsBasicDetails() {
  // Dynamically adjust label position based on whether there's a value or not
  return {
    top: '-3px', // Align label based on input's focus
    lineHeight: 'normal',
    fontSize: '0.875rem'
  };
}

export function openInNewTab(url) {
  window.open(url, '_blank').focus();
}

export function toProperCase(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function sleepAsync(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export const COMMON_TOAST_CONFIG = {
  preventDuplicate: true,
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

export const showToast = (msg, variant = 'info') => {
  enqueueSnackbar(msg, { ...COMMON_TOAST_CONFIG, variant });
};

export const stripHTMLTags = input => {
  if (!input) return '';
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = input;
  return tempDiv.textContent || tempDiv.innerText || '';
};

export function formatDownloadFileName(fileName) {
  return fileName.replace(/ /g, '_');
}
