import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const MultipleCompanyStatusSelection = props => {
  const { value = [], name = '', label = '', onChange = () => {}, disabled = false, isDrawer = false, required = false, multiple = true, defaultValues = [], ...rest } = props;

  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState(value);
  const companyStatusOption = useSelector(state => state.commonReducer.companyStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyStatusOption) {
      dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
    } else {
      setOptions(companyStatusOption);
    }
  }, [companyStatusOption, dispatch]);

  useEffect(() => {
    if (defaultValues?.length > 0 && selectedValues.length <= 0) {
      const filteredValues = options.filter(item => defaultValues.includes(item?.field_value));
      setSelectedValues(filteredValues);
    }
  }, [defaultValues, options]);

  const handleDropdownChange = (e, selectedOptions) => {
    setSelectedValues(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <CustomDropdown
      {...rest}
      value={selectedValues}
      defaultValues={defaultValues}
      options={options}
      name={name}
      onChange={handleDropdownChange}
      isDrawer={isDrawer}
      disabled={disabled}
      label={label}
      required={required}
      multiple={multiple}
      isCheckBox={multiple}
    />
  );
};

MultipleCompanyStatusSelection.propTypes = {
  value: PropTypes.array,
  defaultValues: PropTypes.array,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool
};

export default MultipleCompanyStatusSelection;
