import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Chip, CircularProgress, TextField, Tooltip } from '@mui/material';

import PropTypes from 'prop-types';

/**
 * DropdownWithCustomOptions component
 * @param {{
 * isLoading?: boolean,
 * disabled?: boolean,
 * label?: string,
 * options?: object[],
 * value?: any,
 * getOptionLabel?: GetOptionLabel,
 * renderOption?: (props: object, option: object) => JSX.Element,
 * className?: string,
 * onChange?: (event: object, value: object) => void,
 * textFieldProps?: TextFieldProps,
 * autoCompleteProps?: AutocompleteProps,
 * size?: string,
 * name?: string
 * }} props
 */
const DropdownWithCustomOptions = ({
  isLoading = false,
  disabled = false,
  label = '',
  size = 'small',
  options = [],
  value = [],
  getOptionLabel = option => option?.short_desc || option,
  renderOption = (props, option) => <li {...props}>{getOptionLabel(option)}</li>,
  className,
  onChange,
  textFieldProps = {},
  autoCompleteProps = {},
  name,
  InputLabelProps = {},
  multiple = false,
  inputValue,
  onInputChange,
  required = false,
  noOptionComponent = null,
  isOptionEqualToValue = (option, value) => option === value || option?.short_desc === value?.short_desc,
  useLocalInputState = false,
  handleKeyDown,
  maxVisibleTags = 3
}) => {
  const [search, setSearch] = React.useState('');

  const handleOnKeyDown = event => {
    if (handleKeyDown) return handleKeyDown(event.key, search, multiple);
    if (event.key === 'Enter' && search && multiple) {
      event?.preventDefault();
      const newValue = [...value, search];
      onChange(null, newValue);
      setSearch('');
    }
  };

  const handleInputChange = (event, newInputValue) => {
    if (onInputChange) {
      onInputChange(event, newInputValue);
    }
    if (useLocalInputState) {
      setSearch(newInputValue);
    }
  };

  return (
    <Autocomplete
      size={size}
      options={options}
      value={value}
      multiple={multiple}
      inputValue={inputValue || search}
      onInputChange={(event, newInputValue) => {
        handleInputChange(event, newInputValue);
      }}
      getOptionLabel={getOptionLabel}
      popupIcon={<ExpandMoreIcon />}
      className={`animate-icon ${className}`}
      renderOption={renderOption}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={handleOnKeyDown}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          required={required}
          label={label}
          variant='outlined'
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          {...textFieldProps}
        />
      )}
      {...autoCompleteProps}
      noOptionsText={noOptionComponent}
      renderTags={(tagValue, getTagProps) => {
        const visibleTags = tagValue.slice(0, maxVisibleTags);
        const hiddenCount = tagValue.length - visibleTags.length;
        return (
          <>
            {visibleTags.map((option, index) => (
              <Chip size='small' variant='outlined' key={index} label={getOptionLabel(option)} {...getTagProps({ index })} />
            ))}
            {hiddenCount > 0 && (
              <Tooltip
                title={
                  <Box sx={{ backgroundColor: 'white' }}>
                    {tagValue.map((option, index) =>
                      index >= visibleTags.length ? <Chip size='small' variant='outlined' key={index} label={getOptionLabel(option)} {...getTagProps({ index })} /> : null
                    )}
                  </Box>
                }
                interactive
                arrow
                placement='top'
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: '#ffffff',
                      color: '#000000',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    },
                    '& .MuiTooltip-arrow': {
                      color: '#ffffff'
                    }
                  }
                }}
              >
                <Chip variant='outlined' size='small' label={`+${hiddenCount}`} style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </>
        );
      }}
      // renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip variant='outlined' key={index} label={getOptionLabel(option)} {...getTagProps({ index })} />)}
    />
  );
};

DropdownWithCustomOptions.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  className: PropTypes.string,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
  autoCompleteProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  onInputChange: PropTypes.func,
  size: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  multiple: PropTypes.bool,
  inputValue: PropTypes.string,
  required: PropTypes.bool,
  noOptionComponent: PropTypes.element,
  isOptionEqualToValue: PropTypes.func,
  useLocalInputState: PropTypes.bool,
  handleKeyDown: PropTypes.func,
  maxVisibleTags: PropTypes.number
};

export default DropdownWithCustomOptions;

/**
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 */

/**
 * @typedef {import('@mui/material/Autocomplete').AutocompleteProps} AutocompleteProps
 */

/**
 * @typedef {import('@mui/material/Autocomplete').GetOptionLabel} GetOptionLabel
 */
