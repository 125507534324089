import { Box, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoldMedalIcon, RubricIcon, StarEmptyIcon, StarFilledIcon, ThumbsUpIcon } from '../../../../Icons';

function CriteriaComponent({ data, handleModal }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5
      }}
    >
      <div className='d-flex justify-content-between font-bold box-criteria-tab'>
        <Box>
          <Box sx={{ fontSize: '12px', color: '#3D84A8' }}>
            {capitalize(data.profile_label.label_category || 'Unclassified')}
            {data.profile_label.label_subtype && ` / ${capitalize(data.profile_label.label_subtype)}`}
          </Box>
        </Box>

        {data.profile_label.label_requirement.toUpperCase() === 'IDEAL' ? (
          <Box className='d-flex gap-2 items-end'>
            <StarFilledIcon />
            <Box className='fs-12 activity-bar-title'>{capitalize(data.profile_label.label_requirement)}</Box>
          </Box>
        ) : (
          <Box className='d-flex gap-2 items-end'>
            <StarEmptyIcon />
            <Box className='fs-12 activity-bar-title'>{capitalize(data.profile_label.label_requirement)}</Box>
          </Box>
        )}
      </div>

      <Box className='d-flex gap-2'>
        <Box className='d-flex'>
          {data.profile_label.label_type.toUpperCase() === 'GRADED' ? <GoldMedalIcon className='mr-1' width={18} height={18} /> : <ThumbsUpIcon className='mr-1' width={18} height={18} />}
        </Box>
        <Typography variant='subtitle1' color='primary' sx={{ fontWeight: '600 !important', fontSize: '12px !important', alignItems: 'center' }}>
          {capitalize(data.competency)}
        </Typography>
      </Box>

      <Typography variant='description' style={{ fontSize: 10, maxHeight: 120, overflow: 'auto' }}>
        {data.profile_label.description}
      </Typography>

      <Box className='bottom-action cursor-pointer d-flex align-items-start gap-2 pb-2 ps-5' onClick={() => handleModal(data)}>
        <Box
          className='fs-10 activity-bar-title ml-1 py-1 px-2 d-flex align-items-center'
          sx={{
            backgroundColor: '#F5F5F5',
            borderRadius: '4px'
          }}
        >
          <RubricIcon className='mr-1' sx={{ fontSize: '1rem', color: '#959fb7' }} />
          <Box className='fs-12 ml-1 activity-bar-title'>{t('project.candidateScore.rubric')}</Box>
        </Box>
      </Box>
    </Box>
  );
}

CriteriaComponent.propTypes = {
  data: PropTypes.object,
  handleModal: PropTypes.func
};

export default CriteriaComponent;
