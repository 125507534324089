import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../components/common/CustomButton';
import { WorkBenchContext } from '../../../Context';

export default function NoMyListComponent() {
  const { setAddWorkBenchModalOpen } = useContext(WorkBenchContext);
  const { t } = useTranslation();
  return (
    <div className='vw-50 p-4'>
      <b className='fs-16'>{t('utils.welcomeToWorkbench')}</b>
      <p className='fs-14 mt-2'>{t('utils.noListCreatedYet')}</p>
      <CustomButton buttonText={'+ ' + t('actions.add')} type='primary mt-4' onClick={() => setAddWorkBenchModalOpen(true)} />
    </div>
  );
}
