//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useTranslation } from 'react-i18next';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import '../../../../Containers/Commons/CheckboxStyle/index.scss';
import AddressTypeSelection from '../../../common/FunctionalComponents/AddressTypeSelection';
import CountrySelection from '../../../common/FunctionalComponents/CountrySelection';
import StateSelection from '../../../common/FunctionalComponents/StateSelection';
import MakeConfidential from '../../../common/MakeConfidential/MakeConfidential';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';

const Address = props => {
  const { index, addressDetails, selectedOption, removeAddress, handleChange = () => {}, element, isAddContact = true } = props;
  const { t } = useTranslation();

  const title = t('utils.makePrimary');
  const [country, setCountry] = useState('');
  useEffect(() => {
    if (addressDetails?.length) {
      const contactCountry = addressDetails[index]?.contact_country ? addressDetails[index]?.contact_country : addressDetails[index]?.country;
      if (contactCountry) {
        setCountry(contactCountry?.name);
      }
    }
  }, [addressDetails]);

  return (
    <>
      {index == 2 && <Box className='address-divider mt-4 mb-4'></Box>}

      {isAddContact ? (
        <Box className='form-section d-flex flex-column' sx={{ width: '100%' }}>
          <Box className={`${addressDetails.length === 1 ? 'pb-2' : ''}`}>
            <Box className='d-flex flex-row justify-content-between flex-wrap'>
              <Box className='fs-14'>
                {t('contacts.addContact.address')} {index + 1}
              </Box>
              {/* <Box className={`${addressDetails.length === 1 ? "pb-2" : ""}`}> */}
              {addressDetails.length == 2 && (
                <Box className='d-flex justify-content-end flex-2 pr-4 '>
                  <CloseIcon className='close-icon mt-1' cursor='pointer' onClick={() => removeAddress(index)} />
                  <Box className='set-position'>
                    <MakeConfidential
                      name={title}
                      defaultChecked={element.checked}
                      handleChange={e => {
                        handleChange(index, 'is_primary', e.target.checked);
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box className='content-gap address-section-content-data d-flex flex-row flex-wrap pt-1'>
            {/* <Box className='d-flex flex-column'> */}
            {/*  <Box className="label-text fs-14">
              {t('contacts.addContact.country')}
              <span className="MuiInputLabel-asterisk"> *</span>
            </Box> */}
            <Box className='input-field-data d-flex flex-column justify-content-center'>
              <CountrySelection
                label={t('contacts.addContact.country')}
                value={element.contact_country || ''}
                onChange={(e, data) => {
                  handleChange(index, 'contact_country', data, { shouldDirty: true });
                }}
              />
              <Typography className='invalid-message'>{element?.errors?.contact_country ? 'Please fill this field' : ''}</Typography>
            </Box>
            {/*  <Box className="label-text fs-14">
              {t('contacts.addContact.city')}
            </Box> */}
            <Box className='input-field-data d-flex justify-content-center align-items-center'>
              <CustomInputField
                label={t('contacts.addContact.city')}
                value={element?.city || ''}
                onChange={e => {
                  handleChange(index, 'city', e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box className='d-flex flex-column row-gap content-gap '>
            {/* <Box className="label-text fs-14">
              {t('contacts.addContact.state')}
            </Box> */}
            <Box className='input-field-data d-flex justify-content-left align-items-center'>
              <StateSelection
                label={t('contacts.addContact.state')}
                value={element?.contact_state}
                onChange={(e, data) => {
                  handleChange(index, 'contact_state', data);
                }}
                country={country}
              />
            </Box>
            {/* <Box className="label-text fs-14">
              {t('utils.metropolitanArea')}
            </Box> */}
            <Box className='input-field-data d-flex justify-content-center align-items-center'>
              <CustomInputField
                label={t('utils.metropolitanArea')}
                defaultValue={element?.metropolitan_area || null}
                onChange={e => {
                  handleChange(index, 'metropolitan_area', e.target.value);
                }}
              />
            </Box>
            {/* </Box> */}
            {/* <Box className='d-flex flex-column row-gap content-gap '> */}
            {/* <Box className="label-text fs-14">
              {t('utils.zip')}
            </Box> */}
            <Box className='input-field-data d-flex justify-content-center align-items-center'>
              <CustomInputField
                label={t('utils.zip')}
                value={element?.zip_code || ''}
                onChange={e => {
                  handleChange(index, 'zip_code', e.target.value);
                }}
              />
            </Box>
            {/* <Box className="label-text fs-14">
              {t('contacts.addContact.addressType')}
            </Box> */}
            <Box className='input-field-data d-flex flex-column justify-content-center'>
              <AddressTypeSelection
                label={t('contacts.addContact.addressType')}
                value={element?.ign_translate_value?.short_desc ? element?.ign_translate_value?.short_desc : element?.address_type}
                selectedList={addressDetails}
                onChange={(e, data) => {
                  handleChange(index, 'address_type', data);
                }}
              />
              <Typography className='invalid-message'>{element?.errors?.address_type ? 'Please fill this field' : ''}</Typography>
            </Box>
          </Box>
          {/* </Box> */}
          <Box className='d-flex flex-row flex-wrap'>
            {/* <Box className="label-text fs-14 pb-2">
            {t('contacts.addContact.address')}
          </Box> */}
            <Box className='input-field-data-add flex pt-3'>
              <Box className='input-field-data-large'>
                <CustomInputField
                  label={t('contacts.addContact.address')}
                  value={element?.address_lines ?? ''}
                  onChange={e => {
                    handleChange(index, 'address_lines', e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className='form-section d-flex flex-column'>
          <Box className={`${addressDetails.length === 1 ? 'pb-2' : ''}`}>
            <Box className='d-flex flex-row justify-content-between flex-wrap'>
              <Box className='text-label header-font fs-14'>
                {t('contacts.addContact.address')} {index + 1}
              </Box>
              {addressDetails.length == 2 && (
                <Box className='d-flex justify-content-end flex-2 pr-4 '>
                  <CloseIcon className='close-icon mt-1' cursor='pointer' onClick={() => removeAddress(index)} />
                  <Box className='set-position'>
                    <MakeConfidential
                      name={title}
                      defaultChecked={element?.is_primary}
                      handleChange={e => {
                        handleChange(index, 'is_primary', e.target.checked);
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Grid container className='w-100' spacing={2} rowSpacing={2}>
            <Grid item xs={6}>
              <CountrySelection
                className='w-100'
                label={t('contacts.addContact.country')}
                value={element.country && element.country.name ? element.country.name : ''}
                onChange={(e, data) => {
                  handleChange(index, 'country', data, { shouldDirty: true });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                className='w-100'
                label={t('contacts.addContact.city')}
                value={element?.city || ''}
                onChange={e => {
                  handleChange(index, 'city', e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                className='w-100'
                label={t('utils.zip')}
                value={element?.zip_code || ''}
                onChange={e => {
                  handleChange(index, 'zip_code', e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <StateSelection
                className='w-100'
                label={t('contacts.addContact.state')}
                value={element?.state || ''}
                onChange={(e, data) => {
                  handleChange(index, 'state', data);
                }}
                country={country}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                className='w-100'
                label={t('utils.metropolitanArea')}
                defaultValue={element?.metropolitan_area || null}
                onChange={e => {
                  handleChange(index, 'metropolitan_area', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AddressTypeSelection
                className='w-100'
                label={t('contacts.addContact.addressType')}
                value={element?.ign_translate_value?.short_desc ? element?.ign_translate_value?.short_desc : element?.address_type}
                selectedList={addressDetails}
                onChange={(e, data) => {
                  handleChange(index, 'address_type', data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                className='w-100'
                label={t('contacts.addContact.address')}
                value={element?.address_lines || ''}
                onChange={e => {
                  handleChange(index, 'address_lines', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

Address.propTypes = {
  index: PropTypes.number,
  selectedOption: PropTypes.number,
  removeAddress: PropTypes.func,
  handleChange: PropTypes.func,
  element: PropTypes.object,
  addressDetails: PropTypes.array,
  isAddContact: PropTypes.bool
};

export default Address;
