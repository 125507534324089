import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import { debounce, uniqueId } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPickListData } from '../../actions';
import CustomDateRangePicker from '../../components/common/FunctionalComponents/CustomDateRangePicker';
import CustomValueSelection from '../../components/common/FunctionalComponents/CustomValueSelection';
import { LocationSetupApi, picklistDropDownApis } from '../../services/ApiService';
import { ERROR, GET, PROJECT_SCREEN } from '../../services/constantService';

const ResetFilterIcon = ({ color = '#003C5B' }) => {
  return (
    <svg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.7601 17.83L15.6001 15L12.7601 12.17L14.1701 10.76L17.0001 13.57L19.8301 10.76L21.2401 12.17L18.4301 15L21.2401 17.83L19.8301 19.24L17.0001 16.4L14.1701 19.24L12.7601 17.83ZM10.0001 9V16.88C10.0401 17.18 9.94013 17.5 9.71013 17.71C9.61762 17.8027 9.50773 17.8762 9.38676 17.9264C9.26578 17.9766 9.1361 18.0024 9.00513 18.0024C8.87416 18.0024 8.74448 17.9766 8.62351 17.9264C8.50253 17.8762 8.39265 17.8027 8.30013 17.71L6.29013 15.7C6.18113 15.5933 6.09824 15.4629 6.04794 15.319C5.99763 15.175 5.98127 15.0213 6.00013 14.87V9H5.97013L0.210131 1.62C0.0477395 1.41153 -0.0255352 1.14726 0.00631897 0.88493C0.0381731 0.622602 0.172566 0.383546 0.380131 0.22C0.570131 0.08 0.780131 0 1.00013 0H15.0001C15.2201 0 15.4301 0.08 15.6201 0.22C15.8277 0.383546 15.9621 0.622602 15.9939 0.88493C16.0258 1.14726 15.9525 1.41153 15.7901 1.62L10.0301 9H10.0001Z'
        fill={color}
      />
    </svg>
  );
};

ResetFilterIcon.propTypes = {
  color: PropTypes.string
};

const SearchFilters = ({ initialFetch, projectStages, handleQuery, currentQuery, projectsLoader }) => {
  const [selectedUserList, setSelectedUsers] = useState([]);
  const [selectedProjectStages, setSelectedProjectStages] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const isFilterApplied = selectedUserList?.length > 0 || selectedProjectStages?.length > 0 || selectedIndustries?.length > 0 || selectedLocations?.length > 0 || dateRange?.length > 0;

  const { t } = useTranslation();
  const { type } = useParams();
  const [allUsers, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  const industries = useSelector(state => state.commonReducer.industries);
  const dispatch = useDispatch();

  const userSelectOptions = allUsers.map(user => ({ id: user?.id, label: user?.name }));
  const industryOptions = industries?.filter(ind => ind?.name?.length > 1) || [];
  const isProjectSelected = type === PROJECT_SCREEN.PROJECT;

  const fetchUsers = async () => {
    try {
      try {
        const sub_route = 'user?name=';
        const { status, data } = await picklistDropDownApis(GET, sub_route);
        if (status === 200) {
          setUsers(data.data);
        }
      } catch (error) {
        enqueueSnackbar(t('common.somethingWentWrong'));
      }
    } catch (err) {
      enqueueSnackbar(t('common.somethingWentWrong'));
    }
  };

  const fetchLocations = async input => {
    try {
      const res = await LocationSetupApi(GET, input);
      if (res?.data?.predictions) setLocations(res?.data?.predictions);
    } catch (err) {
      enqueueSnackbar({ message: err?.message, variant: ERROR });
    }
  };

  const deboucedFetchLocations = debounce(input => {
    if (!input) return;
    fetchLocations(input);
  }, 500);

  useEffect(() => {
    if (!industries) {
      dispatch(fetchPickListData('industries-picklist', 'industries'));
    }
  }, [industries, dispatch]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (type) {
      if (type === PROJECT_SCREEN.PROJECT) {
        setSelectedProjectStages([
          {
            stage: 'Open'
          }
        ]);
      }
      if (type === PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
        setSelectedProjectStages([
          {
            bd_status: {
              field_value: 'target_identified',
              short_desc: 'Target Identified'
            }
          }
        ]);
      }
    } else {
      setSelectedProjectStages([]);
    }
  }, [type]);

  useEffect(() => {
    if (projectsLoader === false && !initialFetch) {
      const updatedSelectedProjectStages = selectedProjectStages?.map(stg => {
        if (type === PROJECT_SCREEN.PROJECT) {
          return projectStages?.find(i => i?.stage === stg?.stage);
        }
        if (type === PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
          return projectStages?.find(i => i?.bd_status?.field_value === stg?.bd_status?.field_value);
        }
        return stg;
      });

      setSelectedProjectStages(updatedSelectedProjectStages);
    }
  }, [projectsLoader, initialFetch]);

  const handleStageSelect = (e, items) => {
    setSelectedProjectStages([...items]);
    if (!items?.length) {
      let query = { ...currentQuery };
      delete query.query.stage;
      delete query.query.bd_status;
      debouncedQuery.cancel();
      return handleQuery({ ...query });
    }
    const stages = items.map(stage => (isProjectSelected ? stage.stage : stage.bd_status?.field_value));
    const query = { ...currentQuery.query };
    if (isProjectSelected) {
      query.stage = stages;
      delete query.bd_status;
    } else {
      query.bd_status = stages;
      delete query.stage;
    }
    debouncedQuery({
      ...currentQuery,
      query: {
        ...query
      }
    });
  };

  const handleUserSelect = (e, items) => {
    setSelectedUsers([...items]);
    if (!items?.length) {
      let query = { ...currentQuery };
      delete query.query.user_conditions;
      debouncedQuery.cancel();
      return handleQuery({ ...query });
    }
    const users = items.map(user => user.id);
    debouncedQuery({
      ...currentQuery,
      query: {
        ...currentQuery.query,
        user_conditions: users
      }
    });
  };

  const handleLocationsSelect = (e, items) => {
    setSelectedLocations([...items]);
    if (!items?.length) {
      let query = { ...currentQuery };
      delete query.query.location_place_id;
      debouncedQuery.cancel();
      return handleQuery({ ...query });
    }
    const location_place_id = items.map(i => i.place_id);
    debouncedQuery({
      ...currentQuery,
      query: {
        ...currentQuery.query,
        location_place_id
      }
    });
  };

  const handleIndustrySelect = (_e, items) => {
    setSelectedIndustries([...items]);
    if (!items?.length) {
      let query = { ...currentQuery };
      delete query.query.industries;
      debouncedQuery.cancel();
      return handleQuery({ ...query });
    }
    const industries = items.map(i => i?.name);
    debouncedQuery({
      ...currentQuery,
      query: {
        ...(currentQuery?.query || {}),
        industries
      }
    });
  };

  const handleDateChange = value => {
    setDateRange(value);
    if (value === null) {
      let query = { ...currentQuery };
      delete query.query.date_range;
      debouncedQuery.cancel();
      return handleQuery({ ...query });
    }
    debouncedQuery({
      ...currentQuery,
      query: {
        ...currentQuery.query,
        date_range: value
      }
    });
  };

  const debouncedQuery = useMemo(
    () =>
      debounce(query => {
        handleQuery(query);
      }, 1000),
    [handleQuery]
  );

  useEffect(() => {
    return () => {
      debouncedQuery.cancel();
    };
  }, [debouncedQuery]);

  const resetFilter = () => {
    if (!isFilterApplied) return;
    setSelectedUsers([]);
    setDateRange([]);
    setSelectedLocations([]);
    setSelectedIndustries([]);
    setSelectedProjectStages([]);

    let query = { ...currentQuery };
    delete query.query.stage;
    delete query.query.bd_status;
    delete query.query.user_conditions;
    delete query.query.location_place_id;
    delete query.query.date_range;
    delete query.query.industries;
    handleQuery({ ...query });
  };

  const handleIndustriesOnKeyDown = (event, setSearch) => {
    if (event.key === 'Enter' && event.target.value) {
      event?.preventDefault();
      const newValue = [...selectedIndustries, { id: uniqueId(), name: event.target.value }];
      handleIndustrySelect(null, newValue);
      setSearch('');
    }
  };

  return (
    <Box bgcolor={'#CED8DE'} p={1} display={'flex'} alignItems={'center'} flexWrap={'wrap'} gap={1} width={'100%'}>
      <CustomValueSelection
        options={userSelectOptions || []}
        labelProperty={'label'}
        placeholder={selectedUserList?.length ? '' : t('project.filters.team_members')}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        multiple
        value={selectedUserList}
        disableCloseOnSelect
        minWidth={'220px'}
        maxWidth={'220px'}
        textFieldStyle={{
          background: 'white',
          borderRadius: '5px'
        }}
        onChange={handleUserSelect}
        renderOption={(props, option, { selected }) => {
          return (
            <Box {...props} display={'flex'} alignItems={'center'}>
              <Checkbox size='small' checked={selected} />
              <Tooltip title={option?.label}>
                <Typography overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} color={'primary'} fontSize={14} fontWeight={500}>
                  {option?.label}
                </Typography>
              </Tooltip>
            </Box>
          );
        }}
      ></CustomValueSelection>

      <CustomDateRangePicker placeholder={t('project.filters.date_started')} onChange={handleDateChange} range={dateRange} setRange={setDateRange} />

      <CustomValueSelection
        options={locations || []}
        labelProperty={'description'}
        placeholder={selectedLocations?.length ? '' : t('project.filters.location')}
        isOptionEqualToValue={(option, value) => option?.place_id === value?.place_id}
        multiple
        minWidth={'250px'}
        maxWidth={'250px'}
        textFieldStyle={{
          background: 'white',
          borderRadius: '5px'
        }}
        maxVisibleTags={1}
        value={selectedLocations}
        disableCloseOnSelect
        onChange={handleLocationsSelect}
        onInputChange={deboucedFetchLocations}
        renderOption={(props, option, { selected }) => {
          return (
            <Box {...props} display={'flex'} alignItems={'center'}>
              <Checkbox size='small' checked={selected} style={{ marginRight: 6 }} />
              <Tooltip title={option?.description}>
                <Typography overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} fontSize={14} fontWeight={500}>
                  {option?.description}
                </Typography>
              </Tooltip>
            </Box>
          );
        }}
      ></CustomValueSelection>

      <CustomValueSelection
        textFieldStyle={{
          background: 'white',
          borderRadius: '5px'
        }}
        handleOnKeyDown={handleIndustriesOnKeyDown}
        options={industryOptions || []}
        labelProperty={'name'}
        placeholder={selectedIndustries?.length ? '' : t('project.filters.industry')}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        multiple
        maxVisibleTags={2}
        minWidth={'250px'}
        maxWidth={'250px'}
        value={selectedIndustries}
        disableCloseOnSelect
        onChange={handleIndustrySelect}
        renderOption={(props, option, { selected }) => {
          return (
            <Box {...props} display={'flex'} alignItems={'center'}>
              <Checkbox size='small' checked={selected} style={{ marginRight: 6 }} />
              <Tooltip title={option?.name}>
                <Typography overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} fontSize={14} fontWeight={500}>
                  {option?.name}
                </Typography>
              </Tooltip>
            </Box>
          );
        }}
      ></CustomValueSelection>

      <CustomValueSelection
        textFieldStyle={{
          background: 'white',
          borderRadius: '5px'
        }}
        options={projectStages}
        labelProperty={isProjectSelected ? 'stage' : 'bd_status.short_desc'}
        placeholder={selectedProjectStages?.length ? '' : t('project.filters.stage')}
        multiple
        maxVisibleTags={2}
        minWidth={'180px'}
        maxWidth={'180px'}
        value={selectedProjectStages}
        disableCloseOnSelect
        onChange={handleStageSelect}
        renderOption={(props, option, { selected }) => {
          const optionLabel = isProjectSelected ? option?.stage : option?.bd_status?.short_desc;
          return (
            <Box {...props} display={'flex'} alignItems={'center'}>
              <Checkbox size='small' checked={selected} style={{ marginRight: 6 }} />
              <Box display={'grid'} gridTemplateColumns={'auto 1fr'} gap={0.5} alignItems={'center'}>
                <Tooltip title={optionLabel}>
                  <Typography fontSize={14} fontWeight={500} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                    {optionLabel}
                  </Typography>
                </Tooltip>
                <Typography fontSize={13}>({option?.count})</Typography>
              </Box>
            </Box>
          );
        }}
      ></CustomValueSelection>

      <Box px={1} py={0.6} border={'1px solid #003C5B'} borderRadius={'3px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'white'}>
        <Box onClick={resetFilter} sx={{ cursor: 'pointer', opacity: isFilterApplied ? 1 : 0.5 }}>
          <ResetFilterIcon />
        </Box>
      </Box>
    </Box>
  );
};

SearchFilters.propTypes = {
  projectStages: PropTypes.array,
  handleQuery: PropTypes.func,
  currentQuery: PropTypes.object,
  projectsLoader: PropTypes.bool,
  initialFetch: PropTypes.bool
};

export default SearchFilters;
