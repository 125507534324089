export const columnDefs = [
  {
    colId: 'actions',
    field: 'actions',
    headerName: '',
    cellRenderer: 'ActionsRenderer',
    sortable: false,
    maxWidth: 80,
    minWidth: 80,
    rowDrag: true
  },
  {
    colId: 'name',
    headerName: 'Name',
    field: 'name',
    cellRenderer: 'TextRenderer'
  },
  {
    colId: 'company',
    headerName: 'Company',
    field: 'company',
    cellRenderer: 'TextRenderer'
  },
  {
    colId: 'title',
    headerName: 'Title',
    field: 'title',
    cellRenderer: 'TextRenderer'
  },
  {
    colId: 'stage',
    headerName: 'Stage',
    field: 'stage',
    cellRenderer: 'TextRenderer'
  },
  {
    colId: 'fq',
    headerName: 'FQ',
    field: 'fq',
    cellRenderer: 'TextRenderer'
  }
];

export const templateOptions = {
  report_segments: {
    // report_segments_cover_page: {},
    report_segments_live_search_metrics: {},
    report_segments_candidates_interviewed_or_scheduled_to_inteview_with_kg: {},
    report_segments_fq_summary: {}
  },
  candidate_details_information: {
    candidate_details_information_compensation: {
      candidate_details_information_compensation_compensation_actual: {},
      candidate_details_information_compensation_compensation_expectations: {},
      candidate_details_information_compensation_equity: {},
      candidate_details_information_compensation_compensation_notes: {}
    },
    candidate_details_information_next_steps: {},
    candidate_details_information_status_bar: {},
    candidate_details_information_fq_details: {},
    candidate_details_information_board_history: {},
    candidate_details_information_strengths: {},
    candidate_details_information_potential_issues: {},
    candidate_details_information_show_linkedin: {},
    candidate_details_information_show_comments: {}
  }
};

export function deepEqual(obj1, obj2) {
  // Check if both are strictly equal
  if (obj1 === obj2) return true;

  // Check if either is null or not an object
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the objects have the same number of keys
  if (keys1.length !== keys2.length) return false;

  // Recursively check each property
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
