import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { LocationSetupApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import CustomDropdown from '../CustomDropdown';

function MultipleGeoLocationInput(props) {
  const { label = 'Enter Location', onChange = () => {}, val = [], isMultiSelect = true, defaultValues, ...rest } = props;

  const [input, setInput] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState(val);

  // Fetch data based on user input
  const fetchData = async () => {
    try {
      const res = await LocationSetupApi(GET, input);
      const modifiedVals = res?.data?.predictions?.map(item => {
        return { ...item, id: item?.place_id, name: item?.description, label: item?.description };
      });
      if (res?.data?.predictions) setOptions(modifiedVals);
    } catch (err) {
      console.error('Error fetching location data:', err);
    }
  };

  useEffect(() => {
    if (input?.length > 0) {
      fetchData();
    }
  }, [input]);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationInput = await Promise.all(
          defaultValues.map(async dVal => {
            const { data: locationResponse } = await LocationSetupApi(GET, dVal);

            // Map and filter to handle undefined results
            return locationResponse?.predictions?.map(item => (dVal === item?.description ? { ...item, id: item?.place_id, name: item?.description, label: item?.description } : null)).filter(Boolean); // Remove null values
          })
        );

        // Flatten the nested array
        const flattenedLocationInput = locationInput.flat();

        console.log(flattenedLocationInput, 'SelectedValForLocation');
        setSelectedValues(flattenedLocationInput);
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchLocationData();
  }, [defaultValues]);

  // Handle changes when the user selects values
  const onChangeValue = (event, newValue) => {
    console.log({ location: 'onChange', event, newValue });
    setSelectedValues(newValue);
    onChange(event, newValue);
  };

  return (
    <CustomDropdown
      {...rest}
      defaultValues={defaultValues}
      options={options}
      multiple={isMultiSelect}
      isCheckBox={isMultiSelect}
      value={selectedValues}
      onInputChange={(event, newValue) => {
        setInput(newValue);
      }}
      onChange={onChangeValue}
      label={label}
    />
  );
}

MultipleGeoLocationInput.propTypes = {
  label: PropTypes.string,
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  defaultValues: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  isMultiSelect: PropTypes.bool
};

export default MultipleGeoLocationInput;
