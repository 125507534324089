import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomSelectionComponent from '../../../../components/common/CustomSelectionComponent';

const StageActionRenderer = ({ params, handleEditClick, toggleStage }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const handleClick = event => setAnchorEl(event.currentTarget);

  return (
    <Box className='d-flex align-items-center fullHeight justify-content-center'>
      <CustomSelectionComponent
        options={[
          {
            short_desc: t('actions.edit'),
            onClick: () => handleEditClick(params.data)
          },
          {
            short_desc: params?.data?.deleted_at ? t('actions.activate') : t('actions.disable'),
            onClick: () => toggleStage(params.data)
          }
        ]}
        handleMenuItemClick={() => null}
        component={<MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </Box>
  );
};

StageActionRenderer.propTypes = {
  params: PropTypes.object,
  handleEditClick: PropTypes.func,
  toggleStage: PropTypes.func
};

export default StageActionRenderer;
