import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomMuiDropdown from '../../CustomMuiDropdown';

const MarkNoteAsSelection = props => {
  const { onChange = () => {}, selectedOption, size = 'medium', disabled = false } = props;
  const [options, setOptions] = useState([]);
  const markNote = useSelector(state => state.commonReducer.markNote);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!markNote) {
      dispatch(fetchPickLists('CONTACTS_MARK_NOTE_AS', 'markNote'));
    } else {
      setOptions(markNote);
    }
  }, [markNote, dispatch]);

  return (
    <CustomMuiDropdown
      options={options}
      onChange={(e, value) => {
        onChange(value?.field_value);
      }}
      autoCompleteProps={{
        isOptionEqualToValue: (option, value) => option?.field_value === value,
        fullWidth: true
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return options?.find(item => item?.field_value === option)?.short_desc;
        }
        return option?.short_desc;
      }}
      label={t('utils.markNoteAs')}
      value={selectedOption}
      size={size}
      disabled={disabled}
    />
  );
};

MarkNoteAsSelection.propTypes = {
  onChange: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func,
  size: PropTypes.string,
  editActivityNote: PropTypes.string,
  disabled: PropTypes.bool
};

export default MarkNoteAsSelection;
