import CloseIcon from '@mui/icons-material/Close';
import PaletteIcon from '@mui/icons-material/Palette';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ignWorkbenchDataApi } from '../../../services/ApiService';
import { POST } from '../../../services/constantService';

const ColorLegends = ({ colors = [], labelMatch = '', workbenchId, setRefetchColors }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorLabels, setColorLabels] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    const initialLabels = {};
    colors.forEach(color => {
      initialLabels[color.id] = color?.[labelMatch]?.[0]?.label || '';
    });
    setColorLabels(initialLabels);
  }, [colors, labelMatch]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Memoize the debounced API call
  const debouncedApiCall = useCallback(
    debounce(async (label, colorId) => {
      try {
        const payload = {
          workbenchId,
          label,
          colorId
        };

        await ignWorkbenchDataApi(POST, '', payload, 'add-color-label');
        setRefetchColors(true);
      } catch (error) {
        console.error('Error updating color label:', error);
      }
    }, 500),
    [workbenchId]
  );

  const handleInputChange = (e, colorId) => {
    const label = e.target.value;
    // Update UI immediately
    setColorLabels(prev => ({
      ...prev,
      [colorId]: label
    }));
    // Debounce API call
    debouncedApiCall(label, colorId);
  };

  return (
    <Box position='relative'>
      <Button color='primary' variant='outlined' onClick={handleClick} size='small' startIcon={<PaletteIcon />} sx={{ textTransform: 'none' }}>
        Color Legend
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box position='relative' minWidth={200} p={2}>
          <Button
            size='small'
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              minWidth: 'auto'
            }}
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </Button>

          <Box mt={3}>
            {colors?.map(color => (
              <MenuItem key={color.id} sx={{ py: 1 }}>
                <Box display='flex' alignItems='center' gap={2} width='100%'>
                  <Box border='1px solid rgba(0, 0, 0, 0.12)' bgcolor={color?.color_code || 'transparent'} width={12} height={12} />
                  <TextField variant='standard' size='small' fullWidth placeholder='Enter Text' value={colorLabels[color.id] || ''} onChange={e => handleInputChange(e, color.id)} />
                </Box>
              </MenuItem>
            ))}
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

ColorLegends.propTypes = {
  colors: PropTypes.array,
  labelMatch: PropTypes.string,
  workbenchId: PropTypes.string,
  setRefetchColors: PropTypes.bool
};

export default ColorLegends;
