import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ignWorkbenchDataApi } from '../../../services/ApiService.js';
import { ERROR, GET, POST, SUCCESS } from '../../../services/constantService.js';

import CustomButton from '../CustomButton/index.js';
import CustomPopup from '../CustomPopup/index.js';

import { debounce } from 'lodash';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../../utils/common.scss';
import CustomDropdown from '../CustomDropdown/index.js';
import './index.scss';

const AddToWorkbench = props => {
  const { moduleIds, isPopupOpen, handleClose = () => {}, isProject = false } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [listData, setListData] = useState([]);
  const [selectedType, setSelectedType] = useState('my-list');
  const [selectedWorkbenches, setSelectedWorkbenches] = useState(null);
  const [searchText, setSearchText] = useState('');

  const { t } = useTranslation();

  const fetchWorkbenches = async (search = '') => {
    try {
      setIsLoading(true);
      const condition = {
        type: isProject ? 'project' : 'contact'
      };
      const requestPath = selectedType === 'my-list' ? 'myList' : 'allList';
      const res = await ignWorkbenchDataApi(GET, '', '', `${requestPath}?condition=${JSON.stringify(condition)}&search=${search}`);
      if (res && res.data && res.data.data && res.data.data.rows) {
        const { data } = res;
        setListData(data.data.rows);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to fetch workbenches: ${error?.message}`, { variant: ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkbenches(searchText);
  }, [selectedType, searchText]);

  const handleAddToWorkBench = async () => {
    try {
      setIsLoading(true);
      const payload = moduleIds?.map(id => ({
        contact_id: isProject ? null : id,
        project_id: isProject ? id : null,
        workbench_id: selectedWorkbenches?.id
      }));

      await ignWorkbenchDataApi(POST, '', payload, 'bulk_add_record');
      enqueueSnackbar(`${isProject ? 'Project' : 'Contacts'} added to workbench ${selectedWorkbenches?.name}`, { variant: SUCCESS });
      setSelectedWorkbenches(null);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTypeChange = (_e, val) => {
    setSelectedType(val);
    setSelectedWorkbenches(null);
  };

  const debounceSearchApiCall = debounce(() => {
    fetchWorkbenches();
  }, 1000);

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      debounceSearchApiCall();
    }
    return () => {
      debounceSearchApiCall.cancel();
      setListData([]);
    };
  }, [searchText]);

  return (
    <CustomPopup
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={isPopupOpen}
      title={t(`${isProject ? 'actions.addProjectsToWorkbenchList' : 'actions.addContactsToWorkbenchList'}`)}
    >
      {/* <Loader show={isLoading} /> */}
      <Box width={'50vw'} className='d-flex flex flex-column' gap={2}>
        <Box className='flex flex-column'>
          <RadioGroup aria-label='list' name='list-selection' value={selectedType} onChange={handleTypeChange} row>
            <FormControlLabel
              value='my-list'
              control={<Radio size='small' />}
              label={
                <Typography fontWeight={600} fontSize={14}>
                  My Lists
                </Typography>
              }
            />
            <FormControlLabel
              value='all-list'
              control={<Radio size='small' />}
              label={
                <Typography fontWeight={600} fontSize={14}>
                  All Lists
                </Typography>
              }
            />
          </RadioGroup>

          <CustomDropdown
            label={t('navMenu.workbench')}
            className={'w-100'}
            options={listData}
            onChange={(event, value) => {
              setSelectedWorkbenches(value);
              value = { selectedWorkbenches };
            }}
            onInputChange={(event, value) => {
              setSearchText(value);
            }}
          >
            <Stack position={'absolute'} right={'56px'} top={0} bottom={0} justifyContent={'center'} alignItems={'center'} sx={{ display: 'flex', width: 40 }}>
              {isLoading && <CircularProgress size={20} />}
            </Stack>
          </CustomDropdown>
        </Box>

        <Box className='d-flex justify-content-end'>
          <CustomButton type={'primary'} size={'small'} disabled={selectedWorkbenches === null} buttonText={t('actions.add')} customWidth={96} variant='contained' onClick={handleAddToWorkBench} />
        </Box>
      </Box>
    </CustomPopup>
  );
};

AddToWorkbench.propTypes = {
  isPopupOpen: PropTypes.bool,
  isProject: PropTypes.bool,
  handleClose: PropTypes.func,
  moduleIds: PropTypes.array
};

export default AddToWorkbench;
