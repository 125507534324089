//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
// import PropTypes from "prop-types";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Box from '@mui/material/Box';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { Flag } from '@mui/icons-material';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EchoSenseCreateNewRequest from '../../../../Containers/IgnyteIntelligence/components/EchoSenseCreateNewRequest';
import { echoSenseApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import Notes from '../../../IgnNotes';
import '../index.scss';
const addContactDataToState = (contact, state, setState) => {
  const firstName = contact?.data?.first_name;
  const lastName = contact?.data?.last_name;
  const email = contact?.data?.contact_emails?.find(email => email.is_primary)?.email;
  const fullName = `${firstName} ${lastName}`;

  // Remove existing fields if they exist
  const filteredState = state.filter(item => item.field_name !== 'first_name' && item.field_name !== 'last_name' && item.field_name !== 'primary_email' && item.field_name !== 'full_name');

  // Add new fields
  const newFields = [
    { field_name: 'first_name', field_value: firstName },
    { field_name: 'last_name', field_value: lastName },
    { field_name: 'primary_email', field_value: email },
    { field_name: 'full_name', field_value: fullName }
  ].filter(field => field.field_value); // Only add fields that have values

  setState([...filteredState, ...newFields]);
};

const ActivityBars = React.memo(props => {
  const { activityState, setActivityComponent, moduleId, ifForCompany = false, contact = null } = props;
  // const url = window.location.href;

  // const [isEmailPopupOpen, setEmailIsPopupOpen] = useState(false);
  // const [isSmsPopupOpen, setSmsIsPopupOpen] = useState(false);
  const [isNotesPopupOpen, setIsNotesPopupOpen] = useState(false);
  const [IsEchoSenseOpen, setIsEchoSenseOpen] = useState(false);
  const [messageTemplateData, setMessageTemplateData] = useState([]);
  const [currentUpdates, setCurrentUpdates] = useState({
    pendingTranscribes: [],
    completedTranscribes: [],
    pendingAnalysis: [],
    completedAnalysis: []
  });

  const { t } = useTranslation();

  const fetchEchoSenseUpdates = async () => {
    try {
      const records = await echoSenseApi(GET, 'updates');
      if (records.status != 200) {
        return;
      }
      if (records.data.error) {
        return;
      }
      setCurrentUpdates(records.data.data);
    } catch (error) {
      return;
    }
  };
  useEffect(() => {
    if (!ifForCompany) {
      fetchEchoSenseUpdates();
    }
  }, []);

  useEffect(() => {
    if (contact) {
      addContactDataToState(contact, messageTemplateData, setMessageTemplateData);
    }
  }, [contact]);

  const buttonComponentsList = [
    {
      key: 'recent_activity',
      visible: true,
      onClick: () => setActivityComponent('recent_activity'),
      icon: activityState === 'recent_activity' ? <WatchLaterIcon className='ml-auto mr-auto pb-1' /> : <ScheduleOutlinedIcon className='ml-auto mr-auto pb-1' />,
      title: t('contacts.recentActivity'),
      hoverColor: 'primary.light1'
    },
    {
      key: 'milestones',
      visible: true,
      onClick: () => setActivityComponent('milestones'),
      icon:
        activityState === 'milestones' ? (
          <Flag className='ml-auto mr-auto pb-1' sx={{ color: 'secondary.main' }} />
        ) : (
          <FlagOutlinedIcon className='ml-auto mr-auto pb-1' sx={{ color: 'secondary.main' }} />
        ),
      title: t('utils.milestones'),
      hoverColor: 'secondary.light1'
    },
    {
      key: 'ignyte_intelligence',
      visible: !ifForCompany,
      onClick: () => setIsEchoSenseOpen(true),
      icon: <PsychologyOutlinedIcon width='20px' sx={{ color: 'primary.main' }} />,
      title: t('navMenu.ignyteIntelligence'),
      hoverColor: 'primary.light1'
    }
  ];

  return (
    <Box id='ActivityBars_container'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          mt: 1,
          gap: 1
        }}
      >
        {buttonComponentsList
          .filter(button => button.visible)
          .map((button, index) => (
            <Box
              key={index}
              onClick={button.onClick}
              sx={{
                py: 2,
                border: '1px solid #E0E0E0',
                borderRadius: '8px',
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: activityState === button.key ? button.hoverColor : 'transparent',
                '&:hover': {
                  backgroundColor: button.hoverColor || 'primary.light1'
                }
              }}
            >
              {button.icon}
              <Typography className='fs-10'>{button.title}</Typography>
            </Box>
          ))}
      </Box>

      {/* <MessageTemplatePopup
        isOpen={isEmailPopupOpen}
        setIsPopupOpen={setEmailIsPopupOpen}
        data={messageTemplateData}
        contact={contact}
        templateState={messageTemplateData}
        setTemplateState={setMessageTemplateData}
      />
      <SMSTemplatePopup isOpen={isSmsPopupOpen} setIsPopupOpen={setSmsIsPopupOpen} data={messageTemplateData} /> */}
      <Notes isOpen={isNotesPopupOpen} setIsPopupOpen={setIsNotesPopupOpen} data={messageTemplateData} contact_id={moduleId} />
      {/* <EchoSense isOpen={IsEchoSenseOpen} setIsPopupOpen={setIsEchoSenseOpen} data={data} userId={userId} /> */}
      {!ifForCompany && (
        <EchoSenseCreateNewRequest
          subRoute={'transcribe'}
          open={IsEchoSenseOpen}
          setOpen={setIsEchoSenseOpen}
          isFromLogAnActivity
          defaultCandidate={{ contact_id: moduleId, full_name: contact?.data?.name }}
          processingList={[...currentUpdates.pendingTranscribes, ...currentUpdates.pendingAnalysis]}
          completedList={[...currentUpdates.completedTranscribes, ...currentUpdates.completedAnalysis]}
        />
      )}
    </Box>
  );
});

export default ActivityBars;

ActivityBars.propTypes = {
  activityState: PropTypes.string,
  setActivityComponent: PropTypes.func,
  moduleId: PropTypes.string,
  ifForCompany: PropTypes.bool,
  contact: PropTypes.object
};
