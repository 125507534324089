import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import { picklistsDataApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

export default function MultipleBrandSelection(props) {
  const { placeholder, disabled = false, label = '', isMultiSelect = true, onChange = () => {}, size = 'small', value, ...rest } = props;

  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(isMultiSelect ? [] : '');

  useEffect(() => {
    const fetchBrands = async () => {
      const { status, data } = await picklistsDataApis(GET, 'SERVICE_OFFERING');
      if (status === 200 && data?.length > 0) {
        const array = data.map(item => ({
          label: item.long_desc,
          field_value: item.field_value,
          name: item.short_desc,
          id: item?.id || null
        }));
        setOptions(array);
      } else {
        setOptions([]);
      }
    };

    if (value) {
      // Check if there are already selected values and set them
      setSelectedValue(value);
    }
    fetchBrands();
  }, [value, isMultiSelect]);

  const handleChange = (e, val) => {
    if (isMultiSelect) {
      setSelectedValue(prevValue => {
        const selectedNames = prevValue.map(item => item.name); // Extract names of already selected items
        const newSelection = val.filter(item => !selectedNames.includes(item.name)); // Add new selections
        const deselected = prevValue.filter(item => val.some(v => v.name === item.name)); // Keep only matching values

        return [...deselected, ...newSelection]; // Merge updated selections
      });
    } else {
      setSelectedValue(val);
    }
    onChange(e, val);
  };

  return (
    <CustomDropdown
      {...rest}
      multiple={isMultiSelect}
      isCheckBox={isMultiSelect}
      options={options}
      filterOptions={options => options}
      placeholder={placeholder}
      onChange={handleChange}
      onInputChange={(e, val, reason) => {
        if (!isMultiSelect && reason === 'input') {
          setSelectedValue(val);
        }
      }}
      disabled={disabled}
      label={label}
      size={size}
      value={selectedValue}
    />
  );
}

MultipleBrandSelection.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  value: PropTypes.array
};
