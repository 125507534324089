import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IgnContactFromResumeApi, LogCandidateActivityAPI, ProjectCandidateApi } from '../../../services/ApiService';
import { EMAIL_TYPE, END_POINTS_URL, ERROR, IGN_API, PHONE_TYPE, POST, SUCCESS } from '../../../services/constantService';
import '../../../utils/common.scss';
import CustomPopup from '../CustomPopup';
import CreateContactFromResume from './CreateContactFromResume';
import './index.scss';
import ResumeUpload from './ResumeUpload';
//import Loader from "../Loader";
import { enqueueSnackbar } from 'notistack';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { createRequiredFieldQuickAddContact, CreateSchemaQuickAddContact } from '../../AddContact/utils';
// import { validateEmail } from '../../MessageTemplatePopup/utils';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import CustomModalWithHeader from '../../../Containers/Commons/CustomModalWithHeader';
import { useOfflimit } from '../../../utils/Hooks/useOfflimit';
import CustomButton from '../CustomButton';
import OffLimitWarning from '../OffLimitWarning';

const ResumeParser = props => {
  const { isPopupOpen, handleClose = () => {}, createProjectCandidate = false, stage = null, projectId = null, callback = async () => {}, handleSave = null, resumeUploadLimit = null } = props;
  const [bulkCreateLoading, setBulkCreateLoading] = useState(false);
  const [bulkCreatedSuccess, setBulkCreatedSuccess] = useState([]);
  const [bulkCreatedError, setBulkCreatedError] = useState([]);
  const [bulkCreatedUpdate, setBulkCreatedUpdate] = useState([]);
  const [isViewSummary, setIsViewSummary] = useState(false);
  const [files, setFile] = useState([]);
  const [openCreateContactFromResume, setOpenCreateContactFromResume] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataObjects, setDataObjects] = useState([]);
  const [allContactsOffLimit, setAllContactsOffLimit] = useState(false);
  const [offLimitWarningPopupForUser, setOffLimitWarningPopupForUser] = useState(false);
  const [offLimitWarningPopupForBulk, setOffLimitWarningPopupForBulk] = useState(false);
  const { getOffLimitFromSummaryForCompanies } = useOfflimit();

  const { translateMessage } = useCustomMessageHook();
  const { t } = useTranslation();

  const createContactFromResume = async () => {
    setLoading(true);
    let resumeArray = [];
    for (let i = 0; i < files.length; i++) {
      let bodyFormData = new FormData();
      bodyFormData.append('resume', files[i]);
      const { status, data } = await IgnContactFromResumeApi(POST, `${IGN_API.contact_from_resume}`, bodyFormData);
      if (status === 200) {
        const templatedResume = await getResumeJson(data);
        const duplicateRecord = await IgnContactFromResumeApi(POST, `${IGN_API.check_contact_duplication}`, data);

        const obj = {
          checked: true,
          file: files[i],
          resumeData: data,
          templatedData: templatedResume,
          duplicateData: duplicateRecord,
          loading: true
        };

        resumeArray.push(obj);
        setDataObjects([...resumeArray]);
      }
      setOpenCreateContactFromResume(true);
      setLoading(false);
    }
  };

  const createProjectCandidateFn = async (contactId, projectId, stage) => {
    try {
      let candidate = {
        contact_id: contactId,
        project_id: projectId,
        off_limits: false,
        lifer: 0.0,
        title: 0.0,
        job_function: 0.0,
        industry: 0.0,
        company: 0.0,
        career_experience: 0.0,
        job_movement: 0.0,
        current_tenure: 0.0,
        relevancy: 0.0,
        company_employee_range: 0.0,
        company_revenue_range: 0.0,
        company_industry: 0.0,
        ex_employee: false,
        exact_company_match: false,
        proximity_location: 0.0,
        source: 'resume',
        stage: stage,
        reason: 'None',
        reason_codes: [],
        applied_at: new Date(),
        category: 'sourced'
      };

      await ProjectCandidateApi(POST, '/', candidate);
      enqueueSnackbar('Created Candidate from Contact', { variant: SUCCESS });
    } catch (err) {
      enqueueSnackbar('Error in creating Candidate from Contact', { variant: ERROR });
    }
  };

  const createContact = async (allowDuplicate, getValues, selectedResumeIndex) => {
    const contact = getValues;
    let isValid = await CreateSchemaQuickAddContact.isValid(contact);

    if (!isValid) {
      let dirtyField = customFormValidator(contact, createRequiredFieldQuickAddContact);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    // let validateEmailSyntax = validateEmail(contact.contact_emails.map(emailObj => emailObj.email));
    // if (!validateEmailSyntax) {
    //   const message = translateMessage('ValidFiled', false, 'Email');
    //   enqueueSnackbar(message, { variant: ERROR });
    //   return;
    // }
    const final = contact;
    let bodyFormData = new FormData();
    bodyFormData.append('file_type', 'resume');
    bodyFormData.append('file', dataObjects[selectedResumeIndex].file);
    bodyFormData.append('contact', JSON.stringify(final));
    bodyFormData.append('parsed_resume_data', JSON.stringify(dataObjects[selectedResumeIndex]?.resumeData));

    const { status, data } = await IgnContactFromResumeApi(POST, `${IGN_API.contact_create_from_resume}`, bodyFormData);
    if (status === 201) {
      if (handleSave) {
        handleSave();
      }
      dataObjects[selectedResumeIndex].templatedData = { ...dataObjects[selectedResumeIndex].templatedData, alreadyCreated: true };
      setDataObjects([...dataObjects]);
      const message = translateMessage('Successfully', false, 'Contact', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
      if (createProjectCandidate) {
        const currentCompanyId = data?.current_company_id;
        const res = await getOffLimitFromSummaryForCompanies([currentCompanyId]);
        if (res?.length) {
          const offLimitdata = [
            {
              offLimits: res.map(off => ({ ...off, type: 'Inherited' })),
              contactDetails: data
            }
          ];
          setAllContactsOffLimit(offLimitdata);
          setOffLimitWarningPopupForUser(true);
          return;
        }
        await createProjectCandidateFn(data.id, projectId, stage);
        await callback();
        return;
      }
      // navigate(`${ROUTES.allContactGrid}/${data.id}`);
    } else if (status === 500) {
      enqueueSnackbar(`${data.message}`, { variant: ERROR });
    } else {
      const message = translateMessage('Successfully', false, 'Contact', 'create');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const handleWarningOfflimitConfirmForUser = async () => {
    const contactDetails = allContactsOffLimit[0]?.contactDetails;
    await createProjectCandidateFn(contactDetails?.id, projectId, stage);
    await LogCandidateActivityAPI(POST, '', {
      notes_type: 'offlimits_override',
      notes: 'Off-limit(s) overwritten',
      activity_type: 'offlimits_override',
      project_id: projectId,
      contact_id: contactDetails?.id
    });
    handleCloseWarningPopupForUsers();
  };
  const handleWarningOfflimitConfirmForBulk = async () => {
    allContactsOffLimit.map(async obj => {
      await createProjectCandidateFn(obj?.contactDetails?.id, projectId, stage);
      await LogCandidateActivityAPI(POST, '', {
        notes_type: 'offlimits_override',
        notes: 'Off-limit(s) overwritten',
        activity_type: 'offlimits_override',
        project_id: projectId,
        contact_id: obj?.contactDetails?.id
      });
      handleCloseWarningPopupForUsers();
    });
  };

  const handlePopupClose = () => {
    handleClose();
    setFile([]);
    setDataObjects([]);
    setOpenCreateContactFromResume(false);
    setBulkCreatedError([]);
    setBulkCreatedSuccess([]);
    setBulkCreatedUpdate([]);
  };

  const getResumeJson = async data => {
    let resume = {};
    resume['first_name'] = data?.first_name;
    resume['last_name'] = data?.last_name;
    let experience = [];
    const workExperience = data?.contact_work_experience ?? [];
    for (let i = 0; i < workExperience.length; i++) {
      const ele = workExperience[i];
      // const companyId = await ignCompanyDataApi(
      //   POST,
      //   {
      //     name: ele?.company?.name,
      //     company_tags: [],
      //     company_industries: [],
      //     company_status: 'active'
      //   },
      //   '',
      //   ''
      // );
      if (i == 0) {
        resume['current_company_id'] = ele?.company_id ? ele?.company : null;
        resume['contact_company'] = ele?.company?.name;
        resume['current_job_title'] = ele?.title;
      }
      await experience.push({
        id: i,
        city: ele?.city || null,
        company_id: ele?.company_id,
        // || companyId?.data?.id
        company: ele?.company?.name || null,
        country_id: ele?.country_id || null,
        country: ele?.country?.name || null,
        state_id: ele?.state_id || null,
        state: ele?.state?.name || null,
        end_date: ele?.um_end_date && moment(ele?.um_end_date).utc().local().isValid() ? moment(ele?.um_end_date).utc().local().format() : null,
        start_date: ele?.un_start_date && moment(ele?.un_start_date).utc().local().isValid() ? moment(ele?.un_start_date).utc().local().format() : null,
        title: ele?.title || null,
        is_present: ele?.is_present || false,
        board_committee: ele?.board_committee || null,
        board_end_date: ele?.board_end_date && moment(ele?.board_end_date).utc().local().isValid() ? moment(ele?.board_end_date).utc().local().format() : null,
        board_start_date: ele?.board_start_date && moment(ele?.board_start_date).utc().local().isValid() ? moment(ele?.board_start_date).utc().local().format() : null,
        board_is_present: ele?.isBoardMember,
        description: ele?.description || null,
        hide: ele?.hide || false
      });
    }
    resume['contact_work_experience'] = experience;
    const email =
      data?.contact_emails?.length > 0
        ? data?.contact_emails
            .filter(elem => elem?.email?.length > 0)
            .map((elem, index) => {
              if (index === 0) {
                return { ...elem, primary: true, id: index, email_type: EMAIL_TYPE.PERSONAL.field_value };
              } else {
                return { ...elem, primary: false, id: index, email_type: EMAIL_TYPE.PERSONAL.field_value };
              }
            })
        : [];
    resume['contact_emails'] = email;
    const phone_number =
      data?.contact_phones?.length > 0
        ? data?.contact_phones
            .filter(elem => elem?.phone_number?.length > 1)
            .map((elem, index) => {
              if (elem?.phone_number) {
                if (index === 0) {
                  return { ...elem, primary: true, id: index, phone_type: PHONE_TYPE.PERSONAL.field_value };
                } else {
                  return { ...elem, primary: false, id: index, phone_type: PHONE_TYPE.PERSONAL.field_value };
                }
              }
              return;
            })
        : [];
    resume['contact_phones'] = phone_number;
    const address = data?.contact_address
      ? data?.contact_address?.map((elem, index) => {
          if (index === 0) {
            return { ...elem, is_primary: true, id: index, address_type: '', metropolitan_area: '', zip_code: '' };
          } else {
            return { ...elem, is_primary: false, id: index, address_type: '', metropolitan_area: '', zip_code: '' };
          }
        })
      : [];
    resume['contact_address'] = address;
    const educationDetails = data.contact_education_details
      ? data?.contact_education_details?.map((ele, index) => {
          let education = {
            id: index,
            degree_name: ele?.degree_name || '',
            school_name: ele?.school_name || '',
            start_date: ele?.start_date || null,
            end_date: ele?.end_date || null,
            country_id: ele?.country_id,
            country: ele?.country?.name || ele?.country,
            major: ele?.major,
            is_present: ele?.is_present || false
          };
          return education;
        })
      : [
          {
            id: 0,
            degree_name: '',
            school_name: '',
            start_date: '',
            end_date: '',
            major: '',
            country_id: '',
            is_present: false
          }
        ];
    resume['contact_education_details'] = educationDetails;

    const certificate = data.contact_certificate
      ? data?.contact_certificate?.map((elem, index) => {
          let certificateObj = {
            id: index,
            certification_type: elem?.certification_type?.field_value ? elem?.certification_type?.field_value : elem?.certification_type,
            certifications: elem?.certifications ? elem?.certifications : '',
            expire_on: elem?.expire_on ? elem.expire_on : ''
          };
          return certificateObj;
        })
      : [{ certification_type: '', certifications: '', expire_on: '' }];

    resume['contact_certificate'] = certificate;
    const language = data?.contact_languages
      ? data?.contact_languages?.map((elem, index) => {
          let languageObj = {
            id: index,
            ...elem,
            expertise: elem?.expertise?.field_value ? elem?.expertise?.field_value : elem?.expertise
          };
          return languageObj;
        })
      : [];

    resume['contact_languages'] = language;
    resume['linkedin_url'] = data?.linkedin_url || '';
    resume['current_company_id'] = experience && experience.length > 0 && experience[0]?.company?.id ? experience[0]?.company?.id : null;
    resume['contact_company'] = experience && experience.length > 0 && experience[0]?.company ? experience[0]?.company : {};
    resume['current_job_title'] = experience && experience.length > 0 && experience[0]?.title ? experience[0]?.title : '';
    resume['expire_on'] = data?.expire_on || '';
    resume['contact_record_types'] = data?.contact_record_types || [];
    resume['source'] = data?.source ? data?.source : 'Ignyte';
    resume['contact_industries'] = data?.contact_industries || [];
    resume['contact_job_functions'] = data?.contact_job_functions || [];
    // setTabIndex(0);
    // setActiveTab(0);
    // setIsLoading(false);
    return resume;
  };

  function transformContactData(inputData, contactName = false) {
    let errorFlag = false;
    let errorString = 'Please Select Company for Experiences ';

    const result = {
      ...inputData,
      current_company_id: inputData.contact_work_experience[0]?.company_id,
      first_name: inputData.first_name,
      last_name: inputData.last_name,
      contact_emails: inputData.contact_emails.map(email => ({
        email: email.email,
        email_type: email.email_type,
        is_primary: email.is_primary
      })),
      contact_phones: inputData.contact_phones.map(phone => ({
        phone_number: phone.phone_number,
        phone_type: phone.phone_type,
        is_primary: phone.is_primary
      })),
      contact_address: inputData.contact_address.map(address => ({
        country: { name: address.country?.name },
        city: address.city,
        state: { name: address.state?.name },
        zip_code: address.zip_code,
        address_type: address.address_type?.field_value,
        country_id: address.country_id || null,
        state_id: address.state_id || null,
        address_lines: address?.address_lines
      })),
      contact_education_details: inputData.contact_education_details.map(education => ({
        school_name: education.school_name,
        degree_name: education.degree_name,
        start_date: education.start_date,
        end_date: education.end_date
      })),
      source: inputData?.source ? inputData?.source : 'Ignyte',
      linkedin_url: inputData.linkedin_url || null,
      contact_company: {
        name: inputData.contact_work_experience[0]?.company?.name,
        id: inputData.contact_work_experience[0]?.company_id
      },
      contact_certificate: inputData?.contact_certificate?.map(certificate => {
        delete certificate.id;
        if (certificate.certifications.length > 0) {
          return {
            ...certificate
          };
        }
      }),

      contact_languages: inputData?.contact_languages?.map(language => {
        delete language.id;
        return {
          ...language
        };
      }),

      contact_work_experience: inputData.contact_work_experience
        .filter(experience => !experience.hide)
        .map((experience, index) => {
          const company = experience?.company?.name ? experience?.company?.name : experience.company;
          if (experience?.company != null && company && company?.length > 0) {
            return {
              city: experience.city,
              company_id: experience?.company_id ?? null,
              company_name: experience?.company ?? '',
              country_id: experience.country_id,
              country: experience.country?.name || null,
              state_id: experience.state_id,
              state: experience.state?.name || null,
              end_date: experience.end_date && moment(experience.end_date).utc().local().isValid() ? moment(experience.end_date).utc().local().format() : null,
              start_date: experience.start_date && moment(experience.start_date).utc().local().isValid() ? moment(experience.start_date).utc().local().format() : null,
              title: experience.title,
              is_present: experience.is_present,
              board_committee: experience.board_committee,
              board_end_date: experience.board_end_date && moment(experience.board_end_date).utc().local().isValid() ? moment(experience.board_end_date).utc().local().format() : null,
              board_start_date: experience.board_start_date && moment(experience.board_start_date).utc().local().isValid() ? moment(experience.board_start_date).utc().local().format() : null,
              board_is_present: experience.board_is_present,
              description: experience.description
            };
          } else {
            let errorMessage = `Please Select Company for Experience ${index + 1}`;
            errorString = errorString + `${index + 1}, `;
            if (contactName) {
              errorMessage = `Please Select Company for ${inputData.first_name} in Experience ${index + 1}`;
            }
            enqueueSnackbar(errorMessage, 'error');
            errorFlag = true;
          }
        })
    };
    if (errorFlag) {
      return { error: errorString };
    } else {
      return { data: result };
    }
  }
  const bulkAdd = async parsedResume => {
    if (parsedResume.length > 0) {
      setBulkCreateLoading(true);
      let contacts = [];
      for (let i = 0; i < parsedResume.length; i++) {
        const isExactMatch = parsedResume[i].duplicateData?.data?.exactMatch ? true : false;
        if (parsedResume[i].checked && parsedResume[i].alreadyCreated != true && !isExactMatch) {
          const { file, resumeData, templatedData } = parsedResume[i];
          let bodyFormData = new FormData();
          const transformedData = transformContactData(templatedData, true);
          if (handleSave) {
            // handleSave(transformedData);
          } else {
            if (transformedData?.data) {
              bodyFormData.append('file_type', 'resume');
              bodyFormData.append('file', file);
              bodyFormData.append('contact', JSON.stringify(transformedData.data));
              bodyFormData.append('parsed_resume_data', JSON.stringify(resumeData));

              const { status, data } = await IgnContactFromResumeApi(POST, `${IGN_API.contact_create_from_resume}`, bodyFormData);
              if (status === 201) {
                // handlePopupClose();
                dataObjects[i].templatedData = { ...dataObjects[i].templatedData };
                dataObjects[i].loading = false;
                dataObjects[i].response = data;
                dataObjects[i].alreadyCreated = true;
                setDataObjects([...dataObjects]);
                contacts.push(data);
              } else {
                dataObjects[i].templatedData = { ...dataObjects[i].templatedData, error: true };
                dataObjects[i].error = data?.message;
                dataObjects[i].loading = false;
                const bulkError = bulkCreatedError;
                bulkError.push(dataObjects[i]);
                setBulkCreatedError(bulkError);
                setDataObjects([...dataObjects]);
              }
            } else {
              dataObjects[i].templatedData = { ...dataObjects[i].templatedData, error: true };
              dataObjects[i].error = transformedData.error;
              dataObjects[i].loading = false;
              setDataObjects([...dataObjects]);
              const bulkError = bulkCreatedError;
              bulkError.push(dataObjects[i]);
              setBulkCreatedError(bulkError);
            }
          }
        }
      }
      setBulkCreatedSuccess([...contacts]);
      const companyIds = contacts.map(contact => contact.current_company_id);
      const res = await getOffLimitFromSummaryForCompanies(companyIds);
      if (!res?.length) {
        contacts.map(async data => {
          if (createProjectCandidate) {
            await createProjectCandidateFn(data.id, projectId, stage);
          }
        });
      } else {
        const companyIds = res.map(offlimit => offlimit.company_id);
        const filteredContacts = contacts.filter(contact => companyIds.includes(contact.current_company_id));
        const offLimitData = filteredContacts.map(contact => {
          const companyOffLimitRelatedToThisContact = res.find(off => off.company_id === contact?.current_company_id);
          return {
            offLimits: [{ ...companyOffLimitRelatedToThisContact, type: 'Inherted' }],
            contactDetails: contact
          };
        });
        setAllContactsOffLimit(offLimitData);
        setOffLimitWarningPopupForBulk(true);
      }

      setBulkCreateLoading(false);
    }
  };

  const handleBulkCreateUpdate = updatedContact => {
    setBulkCreatedUpdate([...bulkCreatedUpdate, updatedContact]);
  };

  const handleCloseViewSummary = () => {
    setIsViewSummary(false);
    handlePopupClose();
  };

  const handleContactClick = id => {
    window.open(`${END_POINTS_URL.CONTACT_URL}${id}`);
  };
  // We are fetching Companies dynamically
  // useEffect(() => {
  //   const getCompanyList = async () => {
  //     const sub_route = 'companies-picklist';
  //     const { status, data } = await picklistDropDownApis(GET, sub_route);
  //     if (status === 200) {
  //       setCompanyList(data);
  //     }
  //   };
  //   getCompanyList();
  // }, []);

  const handleCloseWarningPopupForUsers = () => {
    setOffLimitWarningPopupForUser(false);
    setAllContactsOffLimit(null);
    setOffLimitWarningPopupForBulk(false);
  };

  return (
    <>
      <CustomPopup onClose={handleClose} aria-labelledby='customized-dialog-title' open={isPopupOpen} title={t('contacts.addContact.createFromResume')} loading={loading}>
        <Box className='d-flex flex flex-column' gap={4} id='create-contact-from-resume'>
          <Box className='head-content flex flex-column py-4'>
            <ResumeUpload label='Select File' width={400} setFile={setFile} file={files} onClickUpload={createContactFromResume} resumeUploadLimit={resumeUploadLimit || null} />

            {/* {file && file.length > 0 && (
              <Box className='d-flex justify-content-start'>
                <CustomButton type={'primary'} size={'small'} onClick={createContactFromResume} buttonText={t('actions.uploadFile')} customWidth={96} variant='contained' />
              </Box>
            )} */}

            <CreateContactFromResume
              openCreateContactFromResume={openCreateContactFromResume}
              handleClose={handlePopupClose}
              resumeData={dataObjects}
              createContact={createContact}
              files={files}
              bulkAdd={bulkAdd}
              bulkLoader={bulkCreateLoading}
              setBulkCreatedUpdate={handleBulkCreateUpdate}
              handleViewSummary={() => setIsViewSummary(!isViewSummary)}
              projectId={projectId}
              formatterFunction={transformContactData}
              handleBulkCreateUpdate={handleBulkCreateUpdate}
            />

            <CustomModalWithHeader isOpen={isViewSummary} closeIcon={true} onClose={() => setIsViewSummary(!isViewSummary)} label={'Summary'}>
              <div className='w-100 p-3'>
                <Grid container className='w-100' spacing={3} rowSpacing={3}>
                  <Grid item xs={6} className='font-weight-bold fs-14'>
                    Contact Created Successfully :
                  </Grid>
                  <Grid item xs={6}>
                    <div>{bulkCreatedSuccess.length}</div>
                    <div className='w-100'>
                      {bulkCreatedSuccess.map(data => (
                        <u className='w-100 cursor-pointer ml-1' onClick={() => handleContactClick(data?.id)} key={data?.id}>
                          {data?.name + ','}
                        </u>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs={6} className='font-weight-bold fs-14'>
                    Contact Creation Error :
                  </Grid>
                  <Grid item xs={6}>
                    <div>{bulkCreatedError.length}</div>
                  </Grid>
                  <Grid item xs={6} className='font-weight-bold fs-14'>
                    Contact Updated Success:
                  </Grid>
                  <Grid item xs={6}>
                    <div>{bulkCreatedUpdate.length}</div>
                  </Grid>
                  <Grid xs={12} container justifyContent={'flex-end'}>
                    <CustomButton buttonText={'Close Bulk Parser'} onClick={handleCloseViewSummary} />
                  </Grid>
                </Grid>
              </div>
            </CustomModalWithHeader>
          </Box>
        </Box>
      </CustomPopup>
      {offLimitWarningPopupForUser && (
        <OffLimitWarning
          open={offLimitWarningPopupForUser}
          onClose={handleCloseWarningPopupForUsers}
          onConfirm={handleWarningOfflimitConfirmForUser}
          offlimits={allContactsOffLimit}
          buttonText='Continue'
          companyOffLimit
          contactDetails={allContactsOffLimit?.[0]?.contactDetails}
        />
      )}
      {offLimitWarningPopupForBulk && (
        <OffLimitWarning
          open={offLimitWarningPopupForBulk}
          onClose={handleCloseWarningPopupForUsers}
          onConfirm={handleWarningOfflimitConfirmForBulk}
          offlimits={allContactsOffLimit}
          buttonText='Continue'
        />
      )}
    </>
  );
};

ResumeParser.propTypes = {
  isPopupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  createProjectCandidate: PropTypes.bool,
  stage: PropTypes.string,
  projectId: PropTypes.string,
  callback: PropTypes.func,
  handleSave: PropTypes.func,
  resumeUploadLimit: PropTypes.number
};
export default ResumeParser;
