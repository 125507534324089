import { capitalize, isEmpty } from 'lodash';

export const generateWorkbenchProjectColumn = t => {
  return [
    {
      field: 'color_code.color_name',
      colId: 'color',
      headerName: 'Color',
      flex: 1,
      suppressSizeToFit: true,
      cellRenderer: 'colorRenderer',
      valueGetter: ({ data }) => {
        if (!data) {
          return null;
        }
        try {
          if (data.color_code) {
            if (!isEmpty(data.color_code.workbench_labels)) {
              const selected = data.color_code.workbench_labels.find(color => color.workbench_id === data.workbench_id);
              return selected.label;
            }
            return data.color_code.color_name ? capitalize(data.color_code.color_name) : data.color_code.color_code;
          }
          return null;
        } catch {
          return null;
        }
      },
      minWidth: 100,
      maxWidth: 150,
      visible: true,
      filter: false,
      sortable: true
    },
    {
      field: 'project.job_number',
      headerName: t('grid.projectId'),
      minWidth: 150,
      flex: 1,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      cellRenderer: 'projectNumberRenderer',
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'job_title',
      headerName: 'Title',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'stage',
      headerName: 'Status',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'created_by',
      headerName: 'Owner',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'days_open',
      headerName: 'Days Open',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      valueGetter: ({ data }) => data?.days_open
    },
    {
      field: 'project.ign_companies.name',
      headerName: 'Company',
      minWidth: 200,
      flex: 1,
      filter: 'agMultiColumnFilter',
      sortable: true,
      cellRenderer: 'companyRenderer',
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'industries',
      headerName: 'Industries',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'job_function',
      headerName: 'Job Function',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'consultants',
      headerName: 'Consultants',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'capital_structure',
      headerName: 'Capital Structure',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'vertical_practice',
      headerName: 'Vertical Practice',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'horizontal_practice',
      headerName: 'Horizontal Practice',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'service_offering',
      headerName: 'Service Offering',
      minWidth: 200,
      flex: 2,
      filter: 'agMultiColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      headerName: 'Actions',
      width: 120,
      pinned: 'right',
      field: 'actions',
      cellRenderer: 'actionRenderer',
      suppressFilter: true,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      filter: false,
      sortable: false
    }
  ];
};

export const workbenchContactColumn = [
  {
    field: 'color_code.color_name',
    colId: 'color',
    headerName: 'Color',
    flex: 1,
    suppressSizeToFit: true,
    cellRenderer: 'colorRenderer',
    minWidth: 100,
    maxWidth: 150,
    visible: true,
    filter: false,
    sortable: true,
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }
      try {
        if (data.color_code) {
          if (!isEmpty(data.color_code.workbench_labels)) {
            const selected = data.color_code.workbench_labels.find(color => color.workbench_id === data.workbench_id);
            return selected.label;
          }
          return data.color_code.color_name ? capitalize(data.color_code.color_name) : data.color_code.color_code;
        }
        return null;
      } catch {
        return null;
      }
    }
  },
  {
    field: 'contact.name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: 'nameRenderer'
  },
  {
    field: 'company_name',
    headerName: 'Company',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: 'companyRenderer',
    // cellRenderer: params => (params?.data?.company_name ? <RouteOpener type='company' id={params.data?.contact?.contact_company?.id} label={params.data?.company_name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.company_name || null;
    }
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'job_function',
    headerName: 'Job Function',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'contact_country_p1',
    headerName: 'Country',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'owner',
    headerName: 'Owner',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'last_activity_type',
    headerName: 'Last Activity Type',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'last_activity_date',
    headerName: 'Last Activity Date',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'updated_on',
    headerName: 'Updated On',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'contact_industry_p1',
    headerName: 'Industries',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'source',
    headerName: 'Source',
    minWidth: 200,
    flex: 2,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    headerName: 'Actions',
    width: 120,
    pinned: 'right',
    field: 'actions',
    cellRenderer: 'actionRenderer',
    suppressFilter: true,
    filter: false,
    sortable: false,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true
  }
];
