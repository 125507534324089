import { Grid, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPromtCard } from '../../../services/ApiService';
function ActionCard({ title, onClick }) {
  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        cursor: 'pointer',
        height: '100%',
        alignItems: 'center',
        '&:hover': {
          bgcolor: 'grey.100'
        },
        borderRadius: 2
      }}
      onClick={onClick}
    >
      <Typography variant='body1'>{title}</Typography>
    </Paper>
  );
}

function ActionCards({ onActionSelect }) {
  const { t } = useTranslation();
  const [actions, setActions] = useState([]);
  const fetchPromtCard = async () => {
    try {
      const response = await getPromtCard();
      const promptData = response?.data?.data || [];
      const formattedActions = promptData.map(item => ({
        title: item.description,
        id: item.id
      }));
      setActions(formattedActions);
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  };

  useEffect(() => {
    fetchPromtCard();
  }, []);
  return (
    <Grid container spacing={2}>
      {actions.map(action => (
        <Grid item xs={12} sm={4} key={action.id}>
          <ActionCard title={action.title ? action.title : t('askIgnyteChat.helloHowMayIHelpYou')} onClick={() => onActionSelect(action.id, action.title)} />
        </Grid>
      ))}
    </Grid>
  );
}

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

ActionCards.propTypes = {
  onActionSelect: PropTypes.func.isRequired
};

export default ActionCards;
