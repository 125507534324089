import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES, ROUTE_KEY } from '../../services/constantService';
// import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

export const useNavItems = () => {
  const DashboardWithPlusIcon = React.forwardRef(function DashboardWithPlusIcon(props, ref) {
    return (
      <SvgIcon width='27' height='27' viewBox='0 0 479 494' ref={ref} {...props}>
        <path d='M270.016 0.104948C283.568 -0.246257 296.558 -0.0401726 308.596 5.99383C332.946 18.1995 345.803 37.9754 346.849 65.1801C347.244 75.4503 346.913 85.7484 346.913 96.4726C349.137 96.4726 350.924 96.4726 352.712 96.4726C379.374 96.4729 406.037 96.4519 432.699 96.4792C460.033 96.5071 478.002 114.538 478.012 141.912C478.024 173.241 478.021 204.569 478.024 235.897C478.025 237.855 478.024 239.813 478.024 241.771C477.652 242.056 477.28 242.341 476.908 242.626C476.094 241.488 475.419 240.213 474.448 239.231C433.018 197.336 383.217 182.633 326.863 199.021C270.517 215.407 236.26 254.51 223.949 312.103C218.869 335.869 220.689 359.477 228.019 382.608C229.03 385.798 228.399 387.649 226.097 389.89C216.308 399.422 206.815 409.258 197.03 418.794C188.887 426.73 182.54 435.788 179.292 446.706C178.279 450.111 176.173 449.52 173.96 449.521C142.965 449.539 111.97 449.542 80.9745 449.533C67.8104 449.529 54.6388 449.765 41.4838 449.408C17.7999 448.766 0.0738908 430.32 0.0448918 406.356C-0.0333472 341.699 0.014657 277.043 0.013741 212.386C0.013405 188.557 -0.0103029 164.727 0.0250211 140.898C0.0634921 114.945 18.3484 96.6231 44.4085 96.548C73.0662 96.4653 101.724 96.5291 131.117 96.5291C131.117 93.3201 131.003 90.2279 131.137 87.1465C131.656 75.1952 130.808 62.9611 133.104 51.3539C139.091 21.0995 165.597 0.274734 196.529 0.122015C220.858 0.00189744 245.187 0.102921 270.016 0.104948ZM215.516 55.3148C209.355 55.3236 203.192 55.2336 197.034 55.3699C191.232 55.4983 187.016 58.7111 186.804 64.1993C186.395 74.7991 186.685 85.4259 186.685 96.245C221.663 96.245 256.385 96.245 291.589 96.245C291.589 86.563 291.659 77.1054 291.568 67.6495C291.484 58.9457 287.749 55.3386 278.96 55.329C258.145 55.3061 237.331 55.3215 215.516 55.3148Z' />
        <path d='M222.607 443.951C239.135 427.458 255.411 411.215 271.916 394.745C255.521 365.179 252.097 334.294 263.187 302.332C271.922 277.158 288.271 257.862 311.018 244.105C355.616 217.136 414.031 225.857 449.191 264.582C484.715 303.708 487.813 361.988 456.728 404.486C425.883 446.654 365.425 464.428 312.096 434.863C310.891 436.015 309.568 437.232 308.297 438.5C292.612 454.163 277.058 469.958 261.221 485.465C245.187 501.165 219.144 494.218 213.61 472.761C210.749 461.668 213.971 452.048 222.607 443.951ZM342.497 280.255C341.144 280.892 339.776 281.498 338.441 282.172C309.261 296.914 296.019 331.504 307.84 362.089C319.736 392.872 353.134 409.594 384.692 400.57C420.145 390.431 439.579 353.571 427.954 318.516C416.441 283.8 378.207 266.408 342.497 280.255Z' />
      </SvgIcon>
    );
  });
  const { t } = useTranslation();
  const navItems = useMemo(
    () => [
      {
        label: t('navMenu.home'),
        route: '/',
        key: 'home',
        permissionKey: '',
        logo: HomeIcon
      },
      {
        label: t('navMenu.projects'),
        route: '/searches',
        permissionKey: '',
        key: 'searches',
        logo: DashboardWithPlusIcon
      },
      {
        label: t('navMenu.contacts'),
        route: '/contacts',
        key: 'contact',
        permissionKey: 'contact',
        logo: AssignmentIndOutlinedIcon
      },
      {
        label: t('navMenu.companies'),
        route: '/companies',
        key: 'company',
        permissionKey: 'company',
        logo: ApartmentIcon
      },
      // {
      //   label: t('navMenu.reports'),
      //   route: '/reports',
      //   key: 'reports',
      //   logo: DescriptionOutlinedIcon
      // },
      {
        label: t('navMenu.setup'),
        route: '/productSetUp',
        key: 'setup',
        logo: SettingsOutlinedIcon,
        permissionKey: 'setup'
      },
      // {
      //   label: t('navMenu.security'),
      //   route: '/security',
      //   key: 'security',
      //   logo: LockIcon
      // },
      /*  { label: "Users", route: "/users", key: "users", logo: GroupOutlinedIcon }, */
      {
        label: t('navMenu.clientPortal'),
        route: '/client-portal',
        key: 'clientPortal',
        logo: ImportantDevicesOutlinedIcon,
        permissionKey: '',
        clientSuite: true,
        target: 'new'
      },
      {
        label: t('navMenu.jobPortal'),
        route: '/portal',
        key: 'jobPortal',
        logo: TravelExploreOutlinedIcon,
        target: 'new',
        permissionKey: 'job_portal'
      },
      // {
      //   label: 'Athena',
      //   route: '/athena/54411541-4a97-4204-914b-e7cf9f6bbc85',
      //   key: 'athena',
      //   logo: TravelExploreOutlinedIcon
      //   // target: "new",
      // },
      {
        label: t('navMenu.workbench'),
        route: '/workbench',
        key: 'workbench',
        logo: DashboardOutlinedIcon,
        permissionKey: ''
      },
      {
        label: t('navMenu.ignyteIntelligence'),
        route: ROUTES.ignyteIntelligence,
        key: ROUTE_KEY.ignyteIntelligence,
        logo: PsychologyOutlinedIcon,
        permissionKey: 'ignyte_intelligence'
      }
    ],
    [t]
  );

  return navItems;
};
