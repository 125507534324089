import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../components/common/CustomButton';
import CustomDropdown from '../components/common/CustomDropdown';
import Loader from '../components/common/Loader';
import CustomModalWithHeader from '../Containers/Commons/CustomModalWithHeader';
import { ignAthenaDataApi, picklistsDataApis } from '../services/ApiService';
import { ERROR, GET, POST, SUCCESS } from '../services/constantService';
import useApi from './Hooks/useApiHook';

export const AthenaEmailInvite = async (email, contact_id, project_id = null, language_used = 'ENG') => {
  try {
    const res = await ignAthenaDataApi(POST, '', { email, contact_id, project_id, language_used }, 'invite');
    enqueueSnackbar(res?.data || 'Athena Invite Sent', { variant: SUCCESS });
  } catch (error) {
    enqueueSnackbar('Failed to send invite', { variant: ERROR });
  }
};

export const AthenaEmailInviteModal = ({ isOpen, onClose, contact, projectId = null }) => {
  /**
   * contact : {
   *  id, contact_emails, name
   * }
   */

  const [isLoading, setLoading] = useState(false);
  const [language, setLanguage] = useState('');

  const { data: languageData } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'LANGUAGE_CODE');
    }
  });

  const [noPrimaryEmailModalContactEmail, setNoPrimaryEmailModalContactEmail] = useState('');
  const { t } = useTranslation();

  const navigate = useNavigate();

  const openDrawer = params => {
    navigate(`/contacts/list/all-contacts/${params.data.id}`);
  };

  const handleAthenaSaveAndInvite = async () => {
    setLoading(true);
    const email = noPrimaryEmailModalContactEmail;
    const lang = language.length > 0 ? language : 'ENG';
    await AthenaEmailInvite(email, contact.id, projectId, lang);
    setLoading(false);
  };
  const handleUpdateContact = () => {
    const params = {
      data: contact
    };
    openDrawer(params);
  };

  const setDefaultEmail = () => {
    if (contact?.contact_emails && contact?.contact_emails.length > 0) {
      setNoPrimaryEmailModalContactEmail(contact?.contact_emails[0]?.email);
    }
  };
  useEffect(() => {
    setDefaultEmail();
  }, [contact?.contact_emails]);
  return (
    <CustomModalWithHeader isOpen={isOpen} closeIcon={true} onClose={() => onClose()} label={t('utils.inviteToAthenaEmailRequired')}>
      <div className='fs-14 p-2'>
        {t('utils.name')} : {contact?.name}
      </div>

      {contact?.contact_emails && contact?.contact_emails?.length > 0 ? (
        <div className='fs-14 p-2'>
          <CustomDropdown
            label={t('contacts.addContact.email')}
            options={contact?.contact_emails}
            defaultValue={contact?.contact_emails[0]}
            value={noPrimaryEmailModalContactEmail}
            onChange={(e, v) => {
              setNoPrimaryEmailModalContactEmail(v?.email ? v.email : '');
            }}
            required
            className={'w-100'}
          />
          <CustomDropdown
            label={t('reports.language')}
            options={languageData.data ? languageData.data : []}
            // value={language}
            onChange={(e, v) => setLanguage(v?.field_value ? v.field_value : '')}
            required
            className={'w-100 mt-2'}
          />
        </div>
      ) : (
        <div className='fs-12 p-2'>{t('utils.contactDoesNotHaveEmail')}</div>
      )}

      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <CustomButton buttonText={t('actions.cancel')} variant={'text'} type='tertiary m-1' onClick={() => onClose()} />

        {contact?.contact_emails && contact?.contact_emails?.length > 0 ? (
          <CustomButton
            disabled={noPrimaryEmailModalContactEmail?.length == 0 || language?.length == ''}
            buttonText={t('utils.invite')}
            type='primary m-1'
            onClick={() => handleAthenaSaveAndInvite()}
          />
        ) : (
          <CustomButton buttonText={t('actions.updateContact')} type='primary m-1' onClick={() => handleUpdateContact()} />
        )}
      </div>
    </CustomModalWithHeader>
  );
};

AthenaEmailInviteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contact: PropTypes.object,
  projectId: PropTypes.string
};
