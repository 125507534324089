import { Grid } from '@mui/material';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { getMethodWithCancelTokenApi } from '../../../services/ApiService';
import { IGN_API } from '../../../services/constantService';
import { FormGrid } from './FormGrid';
function AddContactDrawerSectionTwo(props) {
  const { setValue, watch, projectList, projectStageList, loadingDropdownData } = props;
  const [disableStage, setDisableStage] = useState(false);

  const [projects, setProjects] = useState(projectList);

  const fetchProjectsByName = async name => {
    const url = `${IGN_API.picklists}/project?includeBd=true&name=${name}&limit=20`;
    const response = await getMethodWithCancelTokenApi(url);
    setProjects(get(response, 'data.data', []));
  };
  const debounceLoadData = useCallback(debounce(fetchProjectsByName, 300), []);

  const lockProjectStage = () => {
    const projectName = watch('project_name');
    if (!projectName) return;
    if (projectName?.record_type !== 'Project') {
      setValue('project_stage', {
        id: 'Target',
        name: 'Target'
      });
      setDisableStage(true);
    } else {
      setDisableStage(false);
    }
  };
  useEffect(() => {
    lockProjectStage();
  }, [watch('project_name')]);
  const columns = [
    {
      id: 'project_name',
      label: 'addContactDrawer.projectName',
      type: 'dropdown',
      required: false,
      error: false,
      value: watch('project_name'),
      getOptionLabel: option => option.name,
      options: projects,
      useLocalInputState: true,
      onInputChange: (_, text) => debounceLoadData(text),
      onChange: (e, v) => setValue('project_name', v),
      disabled: watch('project_name')?.disabled || false,
      loading: loadingDropdownData,
      xs: 6
    },
    {
      id: 'project_stage',
      label: 'addContactDrawer.projectStage',
      type: 'dropdown',
      required: false,
      error: false,
      value: watch('project_stage'),
      getOptionLabel: option => option.name,
      options: projectStageList,
      onChange: (e, v) => setValue('project_stage', v),
      xs: 5.75,
      loading: loadingDropdownData,
      disabled: watch('project_stage')?.disabled || disableStage || false
    }
  ];
  return (
    <Grid container px={0} pb={0} width={'100%'} height={'100%'}>
      <Grid item xs={12} sx={{ width: '100%', height: '100%' }} minHeight={'24px'}>
        <FormGrid columns={columns} gap={1} />
      </Grid>
    </Grid>
  );
}

export default AddContactDrawerSectionTwo;

AddContactDrawerSectionTwo.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  projectList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  projectStageList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired
};
