import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------

import { getDateFieldValue, invoiceColumnDefs } from '../../../../../components/ViewSearch/utils';
//import { PAGE_LIMIT } from '../../../../ViewContact/utils';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import GenericCellEditor from '../../../../../Containers/Commons/CellEditors/GenericCellEditor';
import NumberEditor from '../../../../../Containers/Commons/CellEditors/NumberEditor';
import CustomFilter from '../../../../../Containers/Commons/CustomFilter';
import { CustomLoadingOverlayComponent, DateRenderer, getCurrencyPrefix } from '../../../../../Containers/Commons/Utils';
import CheckboxSelection from '../../../../../components/CheckboxSelection';
import CustomConfirmationPopup from '../../../../../components/common/CustomConfirmationPopup';
import Loader from '../../../../../components/common/Loader';
import { getUserSelector } from '../../../../../selectors';
import { BDDataApi } from '../../../../../services/ApiService';
import { ERROR, GET, INVOICE_INFO_ROLE, INVOICE_VALIDATION } from '../../../../../services/constantService';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { AddRecordButton, getFixedDecimal } from '../../../../../utils/common';
import { validateDate } from '../../../../../utils/date';
import ConfirmProjectedDatePopup from './ConfirmProjectedDatePopup';
// import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService'

let editIndex = -1;
let forceEditField = false;
let forceEditValue = false;
const InvoiceInfoTable = props => {
  const gridRef = useRef();
  const { register, setValue, currentValues, watch, getValueWithCurrency, updateField, loading } = props;
  const [invoiceList, setInvoiceList] = useState([{}]);
  const [previousList, setPreviousList] = useState([{}]);
  const [isEditing, setEditing] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const invoiceListRef = useRef();
  invoiceListRef.current = invoiceList;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [projectedData, setProjectedData] = useState(null);
  const [isProjectedDateSelected, setIsProjectedDateSelected] = useState(false);
  const userData = useSelector(getUserSelector);
  const [isInvoiceInfoRole, setInvoiceInfoRole] = useState(false);
  useEffect(() => {
    register('project_invoice_info');
    register('collected_percentage');
    register('total_invoice_amt');
    register('total_collected_amt');
    register('total_invoice_expense');
    setValue('project_invoice_info', invoiceList);
    setValue('bd_currency_values', currentValues['bd_currency_values']);
    register('bd_currency_values');
  }, [register, invoiceList, setValue]);
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setIsLoader(true);
        const res = await BDDataApi(GET, currentValues.id, null, null, null);
        setInvoiceList(res?.data?.project_invoice_info || []);
        setPreviousList(res?.data?.project_invoice_info || []);
      } catch (error) {
        enqueueSnackbar('Failed to fetch project data', 'error');
      } finally {
        setIsLoader(false);
      }
    };

    fetchProjectData();
  }, [currentValues]);

  const checkArrayContains = () => {
    if (userData?.all_roles) {
      const containsValue = INVOICE_INFO_ROLE.some(value => userData.all_roles.includes(value));
      return containsValue;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (checkArrayContains()) {
      setInvoiceInfoRole(true);
    } else {
      setInvoiceInfoRole(false);
    }
  }, []);

  const handleChangeInvoiceList = (data, field, index, type, fromExpenses) => {
    if (field === 'expenses' && data === null) return;
    /* here use invoiceListRef because multiple time state update for all column */
    let existingInvoiceList = [...invoiceListRef.current];
    existingInvoiceList[index] = { ...existingInvoiceList[index], [field]: type === 'Number' ? Number(data) : data };
    if (fromExpenses) {
      // if (field === 'expense') {
      // }
      if (field === 'billed_date') {
        existingInvoiceList[index].due_by_date = getDateFieldValue({ data: existingInvoiceList[index] }, 'due_by_date', watch('invoice_terms'));
      } else {
        existingInvoiceList[index] = {
          ...existingInvoiceList[index],
          retainer: 0,
          indirect_fee: 0,
          invoice_amt: 0
        };
      }
    } else {
      if (!existingInvoiceList[index].expenses) {
        existingInvoiceList[index].invoice_amt = Number(existingInvoiceList[index]?.retainer || 0) + Number(existingInvoiceList[index]?.indirect_fee || 0);
      }
    }
    existingInvoiceList[index].outstanding_amt = existingInvoiceList[index].invoice_amt - Number(existingInvoiceList[index].collected_amt || 0);
    setInvoiceList(existingInvoiceList);
    invoiceListRef.current = existingInvoiceList;
  };

  const validateInvoiceInfo = item => {
    let error;
    let inValidDate = [];
    if (!validateDate(item.billed_date)) {
      inValidDate.push('Date Billed');
    }
    if (!validateDate(item.due_by_date)) {
      inValidDate.push('Due By Date');
    }
    if (!validateDate(item.collected_date)) {
      inValidDate.push('Date Collected');
    }
    if (!validateDate(item?.projected_bill_date)) {
      inValidDate.push('Projected Bill Date');
    }
    let keyList = [];

    if (!item?.projected_bill_date && !item?.expenses) keyList.push('Projected Bill Date');
    const message = keyList.join(', ');
    if (inValidDate?.length) {
      error = `Please enter valid date for ${inValidDate?.length > 1 ? 'fields' : 'field'} ${inValidDate.join(', ')}`;
    }
    if (keyList?.length) {
      error = keyList?.length > 1 ? `${message} are required` : `${message} is required`;
    }
    // if (!item.expenses > 0) {
    // }
    /* if invalid for then re-editing ag-grid row */
    if (error) {
      enqueueSnackbar(error, { variant: ERROR });
      return false;
    }
    return true;
  };

  const updateRowData = async (params, checkEmpty) => {
    try {
      if (forceEditField) {
        handleChangeInvoiceList(forceEditValue, forceEditField, editIndex, null, true);
        params.api.refreshCells({ force: true });
        startRowEditing(params, editIndex, forceEditField);
        forceEditField = false;
        forceEditValue = false;
        return;
      }
      if (editIndex === -1) return;
      let index = editIndex;
      stopRowEditing(params, false, true);
      /* used setTimeOut because update state and form Values and then validate form data and update search */
      setTimeout(async () => {
        const item = watch('project_invoice_info')[index];
        if (checkEmpty && item && !Object.keys(item)?.length) {
          stopRowEditing(params, true, true);
          return;
        }
        if (!validateInvoiceInfo(item)) {
          startRowEditing(null, index);
          return;
        } else {
          await updateField(index, `${item.id ? 'Updating' : 'Adding new'} record`, item.id ? 'update' : 'create');
        }
      }, 0);
    } catch (err) {
      console.log('error in updateRowData::', err);
    }
  };

  const existingStartEditRow = () => {
    enqueueSnackbar('Please complete editing or cancel', { variant: ERROR });
    startRowEditing(null, editIndex);
  };

  const handleRemoveInvoiceList = async (index, confirm = false) => {
    if (editIndex !== -1 && editIndex !== invoiceList?.length) return existingStartEditRow();
    if (!confirm) {
      setDeleteIndex(index);
      return setDeletePopup(true);
    }
    let existingInvoiceList = [...invoiceList];
    const deletedInvoice = existingInvoiceList.splice(index, 1);
    setInvoiceList(existingInvoiceList);
    setTimeout(async () => await updateField(index, 'Deleting record', 'delete', deletedInvoice[0]), 0);
  };

  const handleAddInvoiceList = () => {
    let newInvoiceList = [...invoiceList, {}];
    setInvoiceList(newInvoiceList);
    editIndex = invoiceList?.length;
    setTimeout(() => startRowEditing(gridRef.current, invoiceList?.length), 800); //delay added to make row editable by default while changes being reflected in the grid
  };

  const getTotalFieldValue = (key, field) => {
    const calculateData = invoiceListRef.current;
    const totalValue =
      calculateData.reduce((prev, curr) => {
        if (!curr.expenses) {
          prev = prev + (curr[key] || 0);
        }
        return prev;
      }, 0) || 0;
    if (field) {
      setValue(field, getFixedDecimal(totalValue));
    }
    return totalValue;
  };

  const getPercentageCollected = () => {
    const actualRevenue = currentValues.actual_revenue || 0;
    let percentage = parseFloat(((getTotalFieldValue('collected_amt') || 0) / (currentValues.actual_revenue || 0) || 0) * 100);
    if (actualRevenue === 0) {
      percentage = 0;
    }
    percentage = getFixedDecimal(percentage);
    setValue('collected_percentage', percentage);
    return percentage;
  };

  const getTotalExpenses = () => {
    const totalExpense =
      invoiceList.reduce((prev, curr) => {
        if (curr.expenses) {
          prev = prev + curr.invoice_amt;
        }
        return prev;
      }, 0) || 0;
    setValue('total_invoice_expense', getFixedDecimal(totalExpense));
    return totalExpense;
  };

  const onGridReady = params => {
    gridRef.current = params;
    //params.api.sizeColumnsToFit();
    // window.onresize = () => {
    //   params.api.sizeColumnsToFit();
    // }
    // params.api.currency = getCurrencyPrefix(watch('currency'))
    console.log(params);
  };

  const startRowEditing = (params, index, field) => {
    let editKeys = ['invoice_label', 'invoice_id', 'expenses', 'retainer', 'indirect_fee', 'invoice_amt', 'projected_bill_date', 'due_by_date', 'billed_date', 'collected_date', 'collected_amt'];
    if (field) editKeys = [field, ...editKeys];

    // If we're already editing a different row, stop editing it first
    if (editIndex !== -1 && editIndex !== index) {
      gridRef.current.api.stopEditing();
    }

    // Start editing the cells
    editKeys.forEach(ele => {
      gridRef.current.api.startEditingCell({
        rowIndex: index,
        colKey: ele
      });
    });

    // Update the editIndex to the new row
    editIndex = index;
    setEditing(true);

    // Refresh all cells to update the actions renderer
    gridRef.current.api.refreshCells({ force: true });
  };
  const stopRowEditing = (params, reset = false, skipUpdate = false) => {
    params.api.stopEditing();
    editIndex = -1;
    if (reset) {
      setInvoiceList([...previousList]);
    }
    setEditing(false);
    params.api.refreshCells({ force: true });
    !skipUpdate && updateRowData(params, true);
  };

  const onCellDoubleClicked = params => {
    console.log('Double clicked cell:', params);
    // Don't trigger for the actions column
    if (params.column.colId !== 'actions') {
      startRowEditing(params, params.rowIndex);
    }
  };

  const ActionsRenderer = params => {
    const isCurrentRow = editIndex === params?.node?.rowIndex;
    const isAnyRowEditing = editIndex !== -1;

    const handleEditClick = e => {
      e.stopPropagation();
      startRowEditing(params, params?.node?.rowIndex);
    };

    const handleDeleteClick = e => {
      e.stopPropagation();
      handleRemoveInvoiceList(params?.node?.rowIndex);
    };

    // If this is the row being edited, show save/cancel icons
    if (isCurrentRow) {
      return (
        <div className='d-flex justify-content-center' style={{ gap: '8px' }}>
          <button
            style={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              padding: '4px'
            }}
            onClick={() => updateRowData(params, true)}
          >
            <DoneIcon className='done-icon' fontSize='small' />
          </button>
          <button
            style={{
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              padding: '4px'
            }}
            onClick={() => setPopupOpen(true)}
          >
            <CloseIcon className='close-icon' fontSize='small' />
          </button>
        </div>
      );
    }

    // If no row is being edited, show edit/delete icons for all rows
    if (!isAnyRowEditing) {
      return (
        <div className='d-flex justify-content-center' style={{ gap: '8px' }}>
          <button
            style={{
              border: 'none',
              background: 'none',
              cursor: isInvoiceInfoRole ? 'default' : 'pointer',
              padding: '4px',
              opacity: isInvoiceInfoRole ? 0.5 : 1,
              pointerEvents: isInvoiceInfoRole ? 'none' : 'auto'
            }}
            onClick={handleEditClick}
          >
            <EditIcon className='edit-icon' fontSize='small' />
          </button>
          <button
            style={{
              border: 'none',
              background: 'none',
              cursor: isInvoiceInfoRole ? 'default' : 'pointer',
              padding: '4px',
              opacity: isInvoiceInfoRole ? 0.5 : 1,
              pointerEvents: isInvoiceInfoRole ? 'none' : 'auto'
            }}
            onClick={handleDeleteClick}
          >
            <CloseIcon className='edit-icon' fontSize='small' />
          </button>
        </div>
      );
    }

    // If another row is being edited, show nothing
    return null;
  };
  const ProjectedDateRenderer = params => {
    const formattedDate = DateRenderer(params);
    const projectData = {
      ...params,
      rowIndex: params?.node?.rowIndex
    };

    return (
      <>
        {params && params.data && !params.data.expenses ? (
          <Typography
            className='text-link'
            color='primary'
            style={{ padding: '12px' }}
            onClick={() => {
              setIsProjectedDateSelected(true);
              setProjectedData(projectData);
            }}
          >
            {formattedDate}
          </Typography>
        ) : (
          <span>{formattedDate}</span>
        )}
      </>
    );
  };

  const InvoiceDateRenderer = params => {
    const formattedDate = DateRenderer(params);
    return (
      <>
        <span>{formattedDate}</span>
      </>
    );
  };

  const handleConfirmPopup = () => {
    setPopupOpen(false);
    stopRowEditing(gridRef.current, true);
  };

  const handleProjectedDateClose = () => {
    setIsProjectedDateSelected(false);
  };

  const handleCancelPopup = () => {
    setPopupOpen(false);
    return;
  };

  const handleConfirmDeletePopup = () => {
    setDeletePopup(false);
    setDeleteIndex(-1);
    handleRemoveInvoiceList(deleteIndex, true);
  };

  const handleCancelDeletePopup = () => {
    setDeletePopup(false);
    setDeleteIndex(-1);
    return;
  };

  const updateInfoState = (value, field) => {
    forceEditField = field;
    forceEditValue = value;
  };

  return (
    <Box className='d-flex flex-column mt-5' sx={{ width: '100%' }}>
      {/* Conditional Popups */}
      {isPopupOpen && <CustomConfirmationPopup open={isPopupOpen} onClose={handleCancelPopup} type='delete' cancelText='No' confirmText='Yes' onConfirm={handleConfirmPopup} setOpen={setPopupOpen} />}
      {isDeletePopup && (
        <CustomConfirmationPopup
          open={isDeletePopup}
          setOpen={setDeletePopup}
          type='Delete'
          customMessage={INVOICE_VALIDATION.CONFIRM_TO_DELETE}
          onConfirm={handleConfirmDeletePopup}
          onCancel={handleCancelDeletePopup}
          onClose={handleCancelDeletePopup}
          cancelText='No'
          confirmText='Yes'
        />
      )}
      {isProjectedDateSelected && (
        <ConfirmProjectedDatePopup
          onClose={handleProjectedDateClose}
          projectedData={projectedData}
          invoiceList={invoiceList}
          updateField={updateField}
          setInvoiceList={setInvoiceList}
          setValue={setValue}
          register={register}
          watch={watch}
          currentValues={currentValues}
          enqueueSnackbar={enqueueSnackbar}
          loading={loading}
        />
      )}

      {/* Main Content */}
      <Box className='mr-3' sx={{ width: '100%' }}>
        <Box className='d-flex flex-column team-information-table revenue-table p-2' sx={{ width: '100%' }}>
          <Box className='d-flex table-heading font-weight-bold fs-15 mb-2'>Invoice Info</Box>

          <Box className='d-flex align-items-center justify-content-between py-2'>
            <Box className='d-flex flex-grow-1'>
              <Box className='d-flex col text-start'>
                <span className='invoice-heading-label'>Total Invoice Amt</span>
                <span className='invoice-heading-value'>{getValueWithCurrency(getTotalFieldValue('invoice_amt', 'total_invoice_amt'))}</span>
              </Box>
              <Box className='d-flex col text-start'>
                <span className='invoice-heading-label'>% Collected</span>
                <span className='invoice-heading-value'>{getPercentageCollected()}</span>
              </Box>
            </Box>
          </Box>

          <Box className='d-flex align-items-center justify-content-between py-2'>
            <Box className='d-flex flex-grow-1'>
              <Box className='d-flex col text-start'>
                <span className='invoice-heading-label'>Total Collected Amt</span>
                <span className='invoice-heading-value'>{getValueWithCurrency(getTotalFieldValue('collected_amt', 'total_collected_amt'))}</span>
              </Box>
              <Box className='d-flex col text-start'>
                <span className='invoice-heading-label'>Total Expenses</span>
                <span className='invoice-heading-value'>{getValueWithCurrency(getTotalExpenses())}</span>
              </Box>
            </Box>
          </Box>

          <Box className='text-start'>
            <AddRecordButton iconColor={isEditing || isInvoiceInfoRole ? 'inherit' : 'red'} onClick={handleAddInvoiceList} disabled={isEditing || isInvoiceInfoRole}>
              Add Invoice
            </AddRecordButton>
          </Box>
          <Loader show={isLoader} />
          <Box className='list-view pt-2'>
            <Box id='myGrid' className='ag-theme-alpine invoice-grid p-0'>
              <AgGridWrapper
                onGridReady={onGridReady}
                enableBrowserTooltips={true}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  minWidth: 50,
                  maxWidth: 200,
                  width: 120,
                  sortingOrder: ['asc', 'desc', null],
                  suppressMovable: true
                }}
                loadingOverlayComponent='CustomLoadingOverlayComponent'
                components={{
                  CustomLoadingOverlayComponent,
                  GenericCellEditor,
                  ActionsRenderer,
                  ProjectedDateRenderer,
                  DateRenderer,
                  NumberEditor,
                  CheckboxSelection,
                  CustomFilter,
                  invoiceDateRenderer: InvoiceDateRenderer
                }}
                suppressMenuHide={true}
                getRowNodeId={data => data.id}
                scrollbarWidth={12}
                rowData={invoiceList}
                columnDefs={invoiceColumnDefs(handleChangeInvoiceList, updateInfoState, getCurrencyPrefix(currentValues?.bd_currency_values?.code || ''))}
                suppressMovableColumns={false}
                key={invoiceList}
                editType='fullRow'
                rowModelType={'clientSide'}
                ref={gridRef}
                suppressColumnVirtualisation={true}
                // onRowValueChanged={params => updateRowData(params, true)} // updated current row data
                onCellDoubleClicked={onCellDoubleClicked}
                sideBarColumnToolPanelParams={{
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

InvoiceInfoTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  getValueWithCurrency: PropTypes.func,
  updateField: PropTypes.func,
  loading: PropTypes.bool
};

export default InvoiceInfoTable;
