//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InvoiceSummary from './List';

export default function ReadyToBilled() {
  return <InvoiceSummary type='readyToBilled' storageKey='invoiceSummery' title='Ready to be Billed' />;
}
