import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import RouteOpener from '../../../../../components/common/RouteToNewPage';
import { ignWorkbenchQueryApi } from '../../../../../services/ApiService';
import { POST, ROUTES } from '../../../../../services/constantService';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../../../utils/common';
import { CustomLoadingOverlayComponent } from '../../../../Commons/Utils';
import { queryCompanyColumn } from '../Grid/QueryData';
import { CompanyFilterModal } from './QueryFilterAccordion/CompanyFilterAccordion';

const mappedPayload = data => {
  return {
    ...data,
    industry: data?.industry?.map(item => item?.id)
  };
};

export const QueryCompanyGrid = ({ data, gridType, filterModel, sortModel, setQueryResponse }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    const fetchContactQuery = async () => {
      try {
        setGridLoading(true);
        const payload = Object.keys(filterOptions).length > 0 ? filterOptions : mappedPayload(data);
        const response = await ignWorkbenchQueryApi(POST, payload, `type/${data?.query_type?.toLowerCase()}`);
        setFilteredData(response?.data?.rows || []);
        setGridLoading(false);
      } catch (error) {
        setFilteredData([]);
        setGridLoading(false);
      }
    };
    fetchContactQuery();
  }, [filterOptions]);

  const columns = useMemo(() => queryCompanyColumn, []);

  const capitalizeProperCase = string => {
    if (!string) return string;
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const routeCompany = id => {
    const path = ROUTES.viewCompanyWithoutCurrentTab.replace(':id', id);
    window.open(path, '_blank');
  };

  const rowDataArray = useMemo(
    () =>
      filteredData.map(query => ({
        ...query,
        name: query?.name,
        location: query?.location_description || '--',
        tags: query?.company_tags?.map(item => capitalizeProperCase(item?.ign_tag?.name))?.join(', ') || '--',
        industry: query?.company_industries?.map(item => item?.industries?.name)?.join(', ') || '--',
        employee_range: `${query?.employee_range_from || '--'} - ${query?.employee_range_to || '--'}` || '--',
        revenue_range: `${query?.revenue_range_from || '--'} - ${query?.revenue_range_to || '--'}` || '--',
        company_status: capitalizeProperCase(query?.company_status) || '--'
      })),
    [filteredData]
  );

  return (
    <>
      <CompanyFilterModal data={data} filterOptions={filterOptions} setFilterOptions={setFilterOptions} setQueryResponse={setQueryResponse} />
      <div id='myGrid' className='ag-theme-alpine company-query-workbench-grid' style={{ overflow: 'hidden', height: 'calc(70vh - 100px)', marginTop: '10px' }}>
        <AgGridWrapper
          frameworkComponents={{ RouteOpener }}
          enableBrowserTooltips={true}
          gridType={gridType}
          defaultColumnDefs={queryCompanyColumn}
          rowHeight={50}
          columnDefs={columns}
          loadingOverlayComponent={CustomLoadingOverlayComponent}
          scrollbarWidth={12}
          showHeaderMenu={true}
          rowData={rowDataArray}
          paginationPageSize={1000}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          sortModel={sortModel}
          filterModel={filterModel}
          loading={gridLoading}
          suppressHorizontalScroll={false}
          domLayout='normal'
          disableStaticMarkup
          rowGroupPanelShow='always'
          saveGridSetting={true}
          rowModelType='clientSide'
        />
      </div>
      {/* <Stack spacing={2} style={{ height: '550px', width: '100%', marginTop: '10px' }}>
        <div className='ag-theme-alpine'>
          <AgGridReact
            rowData={rowDataArray}
            columnDefs={gridState.length ? gridState : columns}
            paginationPageSize={pageSize}
            rowSelection='multiple'
            defaultColDef={{
              resizable: true,
              filter: true,
              sortable: true
            }}
            onGridReady={onGridReady}
          />
        </div>
      </Stack> */}
      {/* <RenderPagination pageNumber={pageNumber} totalPages={totalPages} handlePageChange={handlePageChange} /> */}
    </>
  );
};

QueryCompanyGrid.propTypes = {
  gridState: PropTypes.array,
  gridType: PropTypes.string,
  data: PropTypes.object,
  setQueryResponse: PropTypes.func,
  sortModel: PropTypes.array,
  filterModel: PropTypes.array
};
