import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

const FabLoader = ({ isLoading = false, size = 40, style = {} }) => {
  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: theme => theme.zIndex.tooltip + 1, // Ensures it stays on top
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      style={style}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

FabLoader.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.object
};

export default FabLoader;
