//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { formatDate, formatDateUtcLocal } from '../../../utils/date';
import { ExpandableSection } from '../../ActivityLog/Components/ExpandableSection';
import DatePickerCommon from '../DatePicker/DatePicker';
import CountrySelection from '../FunctionalComponents/CountrySelection';
import IndustrySelection from '../FunctionalComponents/IndustrySelection';
import JobFunctionSelection from '../FunctionalComponents/JobFunctionSelection';
import StateSelection from '../FunctionalComponents/StateSelection';
import CustomInputField from '../StyledComponents/CustomInputField';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Checkbox, Grid, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import ResumeCompanySelection from './ResumeCompanySelection';

const ProfessionalExperience = props => {
  const { setValue = () => {}, data, setSpecificValue, companyList } = props;
  const [loader, setLoader] = useState(false);
  const [country, setCountry] = useState({});
  const [dateRange, setDateRange] = useState({ start: '', end: '', memberStart: '', memberEnd: '' });
  // const [workingEndDate, setWorkingEndDate] = useState(null);
  const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [jobFunction, setJobFunction] = useState([]);
  const snackbar = useSnackbar();
  const experienceDetails = () => {
    return data['contact_work_experience'];
  };
  const setExperienceDetails = value => {
    setValue('contact_work_experience', value);
  };
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState('panel1');
  const handleExpand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleExpandAll = () => {
    // setExpanded(allPanels)
    setExpanded(true);
  };

  const handleCollapseAll = () => {
    setExpanded(false);
  };
  const handleExperienceVisibility = index => {
    snackbar.enqueueSnackbar('Hidden experiences will not be included when saving the contact', { variant: 'error', preventDuplicate: true });
    setExperienceDetails(experienceDetails()?.map((item, i) => (i === index ? { ...item, hide: !item.hide } : item)));
  };

  const handleChangeExperienceData = (index, name, data, recordData) => {
    let newList = [...experienceDetails()];
    newList[index] = { ...newList[index], [name]: data };
    if (name === 'country_id') {
      newList[index] = { ...newList[index], ['country']: { name: recordData?.name } };
    }
    if (name === 'state_id') {
      newList[index] = { ...newList[index], ['state']: { name: recordData?.value } };
    }
    if (name === 'company_id') {
      newList[index] = { ...newList[index], ['company']: { name: recordData?.name } };
    }
    const updatedList = [...newList];
    setExperienceDetails(updatedList);
  };

  const handleWorkingCheckChange = index => {
    setLoader(true);
    if (selectedCheckboxIndex == index) {
      setSelectedCheckboxIndex(null);
      setExperienceDetails(experienceDetails().map(item => ({ ...item, is_present: false })));
    } else {
      setSelectedCheckboxIndex(index);
      setExperienceDetails(experienceDetails().map((item, i) => ({ ...item, is_present: i == index })));
    }

    setLoader(false);
  };

  const handleCheckboxChange = (event, element) => {
    setSpecificValue('contact_work_experience', element.id, { ...element, board_is_present: event.target.checked });
  };

  const handleAddAnotherExperience = () => {
    setExperienceDetails([...experienceDetails(), { title: ' ', company: ' ' }]);
  };

  const handleIndustryChange = value => {
    const industry = value?.map(item => {
      const obj = { ...item, industry_id: item?.id };
      delete obj.id;
      return obj;
    });
    setValue('contact_industries', industry || null, { shouldDirty: true });
    setIndustries(industry);
  };

  const handleJobFunctionChange = value => {
    const jobFunction = value?.map(item => {
      const obj = { job_functions: item, job_function_id: item.id };
      delete obj.id;
      return obj;
    });
    setValue('contact_job_functions', jobFunction || null, { shouldDirty: true });
    setJobFunction(jobFunction);
  };

  return (
    <>
      <Loader show={loader} />
      <Box id='education-experiences' className='w-100'>
        <Box className='my-cards flex flex-wrap w-100'>
          <Box className='form-section flex flex-column w-100'>
            <Box className='section-content w-100'>
              <Box className='text-label fs-16 pl-1 pb-1 header-font my-4'>{t('contacts.addContact.industryAndJobFunction')}</Box>
              <Grid container className='w-100' spacing={2}>
                <Grid item xs={6}>
                  <IndustrySelection
                    multiple={true}
                    defaultValue={data['contact_industries'] || []}
                    onChange={value => {
                      handleIndustryChange(value);
                    }}
                    className={'w-100'}
                    isCheckBox={true}
                    selectedList={industries}
                    disableCloseOnSelect={true}
                    label={t('contacts.addContact.industry')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <JobFunctionSelection
                    multiple={true}
                    className={'w-100'}
                    defaultValue={data['contact_job_functions'] || []}
                    onChange={value => {
                      handleJobFunctionChange(value);
                    }}
                    label={t('utils.jobFunction')}
                    isCheckBox={true}
                    disableCloseOnSelect={true}
                    selectedList={jobFunction}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className='my-cards flex flex-wrap w-100'>
          <Box className='form-section flex flex-column w-100'>
            <Box className='section-content w-100'>
              <Box className='d-flex justify-content-between align-items-center mt-4 mb-2 w-100'>
                <Box className='text-label fs-16 pl-1 header-font'>{t('utils.workExperience')}</Box>
                <Box className='d-flex'>
                  <Link
                    component='button'
                    className='expand-collapse-button mr-2'
                    onClick={() => {
                      handleExpandAll();
                    }}
                  >
                    {t('actions.expandAll')}
                  </Link>
                  <Link
                    component='button'
                    className='expand-collapse-button'
                    onClick={() => {
                      handleCollapseAll();
                    }}
                  >
                    {t('actions.collapseAll')}
                  </Link>
                </Box>
              </Box>

              {experienceDetails()?.map((element, index) => {
                return (
                  <ExpandableSection
                    key={index}
                    title={`${t('project.candidateScore.experienceLabel')} ${index + 1}`}
                    showCheckbox={false}
                    defaultOpen={expanded === true ? expanded : expanded === `panel${index + 1}`}
                    panelName={`panel${index + 1}`}
                    ariaControlls={`panel${index + 1}d-content`}
                    accID={`panel${index + 1}d-header`}
                    handleExpanded={handleExpand(`panel${index + 1}`)}
                    handleExperienceVisibility={() => handleExperienceVisibility(index)}
                    isVisible={!element.hide}
                    className='mb-3 max-w-100'
                    titleClassName='text-label fs-16 header-font'
                    showHideIcon={true}
                  >
                    <Box className='my-cards flex flex-wrap w-100'>
                      <Box className='form-section flex flex-column w-100'>
                        <Box className='text-label fs-16 pb-1 header-font'>{t('contacts.addContact.jobTitleAndCompany')}</Box>
                        <Grid container className='w-100' spacing={2} rowSpacing={2}>
                          <Grid item xs={6}>
                            <CustomInputField
                              className='w-100'
                              required={true}
                              value={element?.title || ''}
                              onChange={e => {
                                handleChangeExperienceData(index, 'title', e.target.value);
                              }}
                              label={t('contacts.addContact.jobTitle')}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <ResumeCompanySelection
                              viewAddress={true}
                              label={t('contacts.addContact.companyName')}
                              defaultValue={element.company || ''}
                              onChange={(e, data) => {
                                handleChangeExperienceData(index, 'company_id', data?.id, data);
                              }}
                              companyList={companyList}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    <Box className='text-label fs-16 pb-1 header-font mt-2'>{t('contacts.addContact.basicInfo')}</Box>
                    <Grid container className='w-100' spacing={2} rowSpacing={2}>
                      <Grid item xs={6}>
                        <CountrySelection
                          className='w-100'
                          value={element?.country && element?.country.name ? element?.country.name : ''}
                          onChange={(e, data) => {
                            setCountry(data?.name);
                            handleChangeExperienceData(index, 'country_id', data?.id, data);
                          }}
                          label={t('contacts.addContact.country')}
                        ></CountrySelection>
                      </Grid>

                      <Grid item xs={6}>
                        <StateSelection
                          className='w-100'
                          value={element?.state && element?.state.name ? element?.state.name : ''}
                          onChange={(e, data) => {
                            handleChangeExperienceData(index, 'state_id', data?.id, data);
                          }}
                          label={t('contacts.addContact.state')}
                          country={country}
                        ></StateSelection>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInputField
                          className='w-100'
                          value={element?.city || ''}
                          onChange={e => {
                            handleChangeExperienceData(index, 'city', e.target.value);
                          }}
                          label={t('contacts.addContact.city')}
                        />
                      </Grid>
                      <Grid item xs={6}></Grid>

                      <Grid item xs={6}>
                        <DatePickerCommon
                          width='100%'
                          className={'w-100'}
                          value={formatDate(element?.start_date, 'MM-DD-YYYY')}
                          format='MM/DD/YYYY'
                          placeholder='mm/dd/yyyy'
                          onChange={e => {
                            const selectedDate = e?.$d;
                            setDateRange({ ...dateRange, start: e });
                            handleChangeExperienceData(index, 'start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'MM-DD-YYYY') : null);
                          }}
                          isDefaultDatePickerStyle={false}
                          // maxDate={dateRange.end}
                          label={t('utils.from')}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <DatePickerCommon
                          width='100%'
                          className={'w-100'}
                          value={formatDate(element?.end_date, 'MM-DD-YYYY') || null}
                          format='MM/DD/YYYY'
                          placeholder='mm/dd/yyyy'
                          onChange={e => {
                            const selectedDate = e?.$d;
                            // setWorkingEndDate(selectedDate);
                            setDateRange({ ...dateRange, end: e });
                            handleChangeExperienceData(index, 'end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                          }}
                          isDefaultDatePickerStyle={false}
                          disabled={element?.is_present}
                          label={t('utils.to')}
                          // minDate={dateRange.start}
                        />
                      </Grid>
                    </Grid>

                    <Box className='flex flex-row flex-wrap d-flex pt-2 pb-2'>
                      <Box className=' row d-flex mt-3'>
                        <Box className='col-md-6'>
                          <Box sx={{ width: '18vw' }}>
                            <Box className='text-label fs-16 pl-1 pb-1 header-font' sx={{ width: '18vw' }}>
                              {t('utils.timePeriod')}
                            </Box>
                            <Box className='input-field d-flex justify-content-start align-items-center mt-2' sx={{ width: '18vw' }}>
                              <Checkbox onChange={() => handleWorkingCheckChange(index)} checked={element?.is_present} className='checkboxStyle'></Checkbox>
                              <Box className='ml-2 mt-1 text-color fs-12'>{t('utils.theyCurrentlyWorkHere')}</Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className='col-md-6'>
                          <Box sx={{ width: '18vw' }}>
                            <Box className='text-label fs-16 pl-1 pb-1 header-font' sx={{ width: '18vw' }}>
                              {t('utils.boardMember')}
                            </Box>
                            <Box className='input-field d-flex justify-content-start align-items-center mt-2' sx={{ width: '18vw' }}>
                              <Box>
                                <Checkbox className='checkboxStyle' checked={element?.board_is_present} onChange={e => handleCheckboxChange(e, element)} />
                              </Box>

                              <Box className='ml-2 mt-1 text-color fs-12'>{t('utils.theyAreABoardMember')}</Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    {element?.board_is_present && (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <DatePickerCommon
                            value={formatDateUtcLocal(element?.board_start_date, 'MM-DD-YYYY') || null}
                            format='MM/DD/YYYY'
                            placeholder='mm/dd/yyyy'
                            onChange={e => {
                              const selectedDate = e?.$d;
                              setDateRange({ ...dateRange, memberStart: e });
                              handleChangeExperienceData(index, 'board_start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                            }}
                            label={t('utils.fromYear')}
                            maxDate={dateRange.memberEnd}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DatePickerCommon
                            value={formatDateUtcLocal(element?.board_end_date, 'MM-DD-YYYY') || null}
                            format='MM/DD/YYYY'
                            placeholder='mm/dd/yyyy'
                            onChange={e => {
                              const selectedDate = e?.$d;
                              setDateRange({ ...dateRange, memberEnd: e });
                              handleChangeExperienceData(index, 'board_end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                            }}
                            label={t('utils.toYear')}
                            minDate={dateRange.memberStart}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomInputField
                            value={element?.board_committee}
                            onChange={e => {
                              handleChangeExperienceData(index, 'board_committee', e.target.value);
                            }}
                            label={t('utils.committeeDetails')}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </ExpandableSection>
                );
              })}

              <Box className='d-flex'>
                <Link
                  component='button'
                  onClick={() => {
                    handleAddAnotherExperience();
                  }}
                >
                  {`+ ${t('actions.addAnotherActivity')}`}
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ProfessionalExperience.propTypes = {
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  data: PropTypes.object,
  setSpecificValue: PropTypes.func,
  companyList: PropTypes.array
};

export default ProfessionalExperience;
