import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR } from '../../../services/constantService';
import { useOfflimit } from '../../../utils/Hooks/useOfflimit';
import CustomPopup from '../CustomPopup';
import OffLimitsEntry from './OffLimitsEntry';
import { CONSTANTS_SHOW_OFF_LIMIT } from './constant';
import './index.scss';

const ShowOffLimits = ({ companyId, contactId = null, data = [], isPopupOpen, handleClose = () => {}, showDirect = false, showInherited = false, onEdit = () => {}, companyOnly = false }) => {
  const { contactOffLimit, getOffLimitFromSummary } = useOfflimit(data || []);

  const [loading, setLoading] = useState(false);

  const directEntry = contactOffLimit.find(entry => entry.type === CONSTANTS_SHOW_OFF_LIMIT.DIRECT);
  const inheritedEntries = contactOffLimit.filter(entry => entry.type === CONSTANTS_SHOW_OFF_LIMIT.INHERITED);
  const showEditIcon = showDirect && showInherited;

  const { t } = useTranslation();

  const fetchInheritedOffLimits = async () => {
    try {
      setLoading(true);
      await getOffLimitFromSummary(contactId);
    } catch (error) {
      enqueueSnackbar('Error fetching inherited off limits', { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactId) {
      fetchInheritedOffLimits();
    }
  }, [contactId]);

  return (
    <CustomPopup
      onClose={handleClose}
      open={isPopupOpen}
      title={t('utils.offLimitsInfo')}
      paperStyles='paper-custom-info'
      titleClassName='show-off-limits-title'
      overFlowClass='custom-padding'
      headerClassName='show-off-limits-header'
      hideDivider
      popupClassName='show-off-limits'
      loading={loading}
    >
      <Box>
        {showDirect && directEntry && (
          <OffLimitsEntry
            type={directEntry?.type}
            severity={directEntry?.severity}
            startDate={directEntry?.start_date}
            endDate={directEntry?.end_date}
            reason={directEntry?.reason}
            id='direct-offlimits'
            showEditIcon={showEditIcon}
            editIconHandler={onEdit}
            companyId={companyId}
          />
        )}
        {
          //TODO: ui is pending on trevor
          showDirect && !directEntry && (
            <Box p={2} mb={2} className={CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.OFF_LIMIT_ENTRY} onClick={onEdit} id='direct-offlimits' borderRadius={1}>
              <Typography variant='h6' gutterBottom className='d-flex'>
                {t('utils.directOffLimit')}
              </Typography>
              <Box className={CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.CONTENT_CONTAINER}>
                <Box className='flex-1 d-flex flex-column gap-10 '>
                  <Box className='d-flex not-found'>
                    <Typography variant='p' gutterBottom className='d-flex underline'>
                      Click here to create a Direct Off-limit
                    </Typography>
                    <AddCircleOutlineIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }
        {showInherited &&
          inheritedEntries.map((entry, index) => (
            <OffLimitsEntry
              key={index}
              type={entry.type}
              severity={entry.severity}
              startDate={entry.start_date}
              endDate={entry.end_date}
              projectId={entry.project_id}
              reason={entry.reason}
              projectName={entry.project_name}
              id='inherited-offlimits'
              showEditIcon={index === 0 && !showEditIcon}
              editIconHandler={onEdit}
              companyOnly={companyOnly}
            />
          ))}
      </Box>
    </CustomPopup>
  );
};

ShowOffLimits.propTypes = {
  isPopupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  onEdit: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      contact_id: PropTypes.string,
      type: PropTypes.string,
      severity: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      project_id: PropTypes.string
    })
  ),
  showDirect: PropTypes.bool,
  showInherited: PropTypes.bool,
  companyOnly: PropTypes.bool,
  contactId: PropTypes.string,
  companyId: PropTypes.string
};

export default ShowOffLimits;
