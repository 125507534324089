import PropTypes from 'prop-types';
import React from 'react';

const MenuIcon = props => (
  <svg width={25} height={32} viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M17 0V1.83333H0V0H17ZM0 11H8.5V9.16667H0V11ZM0 6.41667H17V4.58333H0V6.41667Z' fill='#1B4965' />
  </svg>
);

MenuIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default MenuIcon;
