import CloseIcon from '@mui/icons-material/Close';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Chip, Divider, IconButton, Menu, MenuItem, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ViewContactDrawer from '../../../../components/ViewContactDrawer';
import ResumeParser from '../../../../components/common/ResumeParser';
import {
  CandidateColorLabelDataApi,
  CandidateStageSetupAPi,
  LogCandidateActivityAPI,
  ProjectCandidateApi,
  ProjectCandidateDataApi,
  candidateStageGroupApi,
  ignAthenaDataApi,
  ignCompanyDataApi,
  searchProjectCandidateAPI
} from '../../../../services/ApiService';
import { DELETE, ERROR, GET, PATCH, POST, SUCCESS } from '../../../../services/constantService';
import { SCROLL_TIMEOUT, customFormValidator, renderRichText, splitLastIfMultiple } from '../../../../utils/common';
import { CustomLoadingOverlayComponent } from '../../../Commons/Utils';
import ChooseFromContact from '../Comman/ChooseFromContact';
import ConfirmModal from '../Comman/ConfirmModal';
// import QuickAdd from '../Comman/QuickAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AddCompany from '../../../../components/AddCompany';
import { ButtonDropDown } from '../../../../components/common/ButtonDropdown';
import CustomButton from '../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import Loader from '../../../../components/common/Loader';
import QuickAddContact from '../../../../components/common/QuickAddContact/v1/QuickAddContact';
import { QUICK_ADD_CONTACT_COMMON_FIELD } from '../../../../components/common/QuickAddContact/v1/utils/Constants';
import { AthenaEmailInviteModal } from '../../../../utils/AthenaInvite';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { createRequiredField, createSchema } from '../../../Companies/Utils';
import { ProjectDataContext } from '../Context';
import { ColorLegend } from './ColorLegend';
import CustomDropdownFilter, { ColorDropdown } from './CustomDropdownFilter';
import ViewReportDrawer from './ViewReportDrawer';

import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchGridData } from '../../../../actions';
import AddContactDrawer from '../../../../components/AddContactDrawer/AddContactDrawer';
import CustomPopup from '../../../../components/common/CustomPopup';
import CompanySelectionDebounce from '../../../../components/common/FunctionalComponents/CompanySelection/CompanySelectionDebounce';
import OffLimitWarning from '../../../../components/common/OffLimitWarning';
import RichText from '../../../../components/common/RichText';
import AgGridWrapper from '../../../../utils/AgGridWrapper';
import ExportCSV from '../../../../utils/ExportCSV';
import { useOfflimit } from '../../../../utils/Hooks/useOfflimit';
import { formatDate } from '../../../../utils/date';
import RejectReasonModal from '../Comman/RejectReasonModal';
import '../index.scss';
import { OfflimitRenderer } from './OfflimitRenderer';

const PAGE_LIMIT = 20;
const REJECTED_STAGE_TYPE = 'Rejected';

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '180px',
    display: 'block'
  }
});

const TextRender = props => {
  const classes = useStyles();

  return (
    <Tooltip title={props.text} arrow>
      <p className={classes.text}>{props.text}</p>
    </Tooltip>
  );
};

const NameRender = props => {
  const classes = useStyles();
  const handleNameClick = e => {
    e.stopPropagation();
    props.setSelectedCandidate(props.candidate);
    props.setIsDrawerOpen(true);
  };

  let newEntry = props?.candidate?.is_new;

  const linkedin_url = get(props, 'candidate.contact.linkedin_url', null);
  return (
    <Tooltip title={props.name} arrow>
      <Stack direction='row' justifyContent={'flex-start'} alignItems={'center'}>
        <Stack mr={1}>
          {linkedin_url ? (
            <Link className='flex mr-1' to={linkedin_url.includes('http') ? linkedin_url : `https://${linkedin_url}`} target='_blank'>
              <LinkedInIcon fontSize='inherit' className={`${linkedin_url} ? 'enabled-linkedin-link': 'disabled-linkedin-link'} fs-16`} />
            </Link>
          ) : (
            <LinkedInIcon fontSize='inherit' className={`${linkedin_url} ? 'enabled-linkedin-link': 'disabled-linkedin-link'} fs-16`} />
          )}
        </Stack>
        <div className={`${classes.text} name-text`} onClick={handleNameClick}>
          <span style={{ textDecoration: 'underline' }}>{props.name}</span> {newEntry && <Chip label='New' color='primary' size='small' style={{ marginLeft: '.5rem' }} />}
        </div>
      </Stack>
    </Tooltip>
  );
};

const RenderRejectReasons = props => {
  return (
    <Stack className='w-100 p-2' direction='column'>
      {props?.value?.length > 0 ? <Typography className='fs-10'>{props?.value?.join(', ')}</Typography> : <div>-</div>}
    </Stack>
  );
};

const ActionRenderer = ({ projectData, item, stages = stagesWithoutLogical, handleStageChange, handleDeleteCandidate, handleViewAthena, handleInviteToAthena }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStageAnchorEl, setOpenStageAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openStage, setOpenStage] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState({});

  const [selectedReasons, setSelectedReasons] = useState({
    reasons: [],
    comments: ''
  });

  const { t } = useTranslation();

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCandidateShowClientPortalChange = id => value => {
    ProjectCandidateApi(PATCH, '/', { id, show_in_client_portal: value }).catch(() => {
      enqueueSnackbar('Error updating show in client portal', { variant: ERROR });
    });
  };

  const handleStageMenuOpen = event => {
    event.stopPropagation();
    setOpenStageAnchorEl(event.currentTarget);
    setOpenStage(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleStageClose = event => {
    event.stopPropagation();
    setOpenStageAnchorEl(null);
    setOpenStage(false);
  };

  const changeStage = handleStageChange(item, item?.contact_id, selectedReasons);

  const handleCloseOfAllModal = event => {
    handleStageClose(event);
    handleClose(event);
    setOpenReasonModal(false);
  };
  const subMenuStageActionsHandler = (event, stage) => {
    setSelectedStage(stage);
    if (stage?.stage_type === REJECTED_STAGE_TYPE) {
      setOpenReasonModal(true);
    } else {
      setSelectedReasons(prev => ({ ...prev, reasons: item?.reject_reasons, comments: item?.reject_comments }));
      onSelectStageHandler(stage, event);
    }
  };

  const onSelectStageHandler = (stage = {}, event) => {
    event.stopPropagation();
    const currentStage = stage?.stage_name ? stage?.stage_name : selectedStage?.stage_name;
    changeStage(currentStage);
    handleCloseOfAllModal(event);
  };

  const onRejectReasonModalClose = event => {
    event.stopPropagation();
    setSelectedReasons({
      reasons: [],
      comments: ''
    });
    [];
    handleCloseOfAllModal(event);
  };

  return (
    <div className='flex-align-center'>
      <CheckboxRenderer
        defaultChecked={item?.show_in_client_portal || false}
        hover={'Show in client portal'}
        onChange={event => {
          event?.stopPropagation();
          const checked = event.target.checked;
          handleCandidateShowClientPortalChange(item?.id)(checked);
        }}
      />
      <ViewReportDrawer
        candidateName={(item?.contact?.first_name || '') + ' ' + (item?.contact?.last_name || '')}
        projectName={projectData?.job_title}
        isDrawerOpen={viewReport}
        setIsDrawerOpen={setViewReport}
        loading={loading}
        setLoading={setLoading}
        contactId={item?.contact_id}
        candidateId={item?.id}
      />
      <RejectReasonModal open={openReasonModal} onSubmit={onSelectStageHandler} reasons={selectedReasons} setReasons={setSelectedReasons} onClose={onRejectReasonModalClose} />
      <IconButton
        onClick={e => {
          e.stopPropagation();
          handleDeleteCandidate(item?.id);
        }}
      >
        <CloseIcon className='red' />
      </IconButton>

      <Box position={'relative'}>
        <IconButton style={{ cursor: 'pointer' }} onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          id='lock-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox'
          }}
        >
          <MenuItem className='fs-14' onClick={handleStageMenuOpen}>
            <Menu
              id='lock-menu'
              anchorEl={openStageAnchorEl}
              open={openStage}
              onClose={handleStageClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox'
              }}
            >
              {stages &&
                stages?.map(stage => {
                  return (
                    <MenuItem
                      onClick={event => {
                        subMenuStageActionsHandler(event, stage);
                      }}
                      className='fs-14'
                      key={stage.stage_name}
                    >
                      {stage.stage_label}
                    </MenuItem>
                  );
                })}
            </Menu>
            Change Stage
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleViewAthena(item);
              handleClose();
            }}
            className='fs-14'
          >
            View Athena
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleInviteToAthena(item);
              handleClose();
            }}
            className='fs-14'
          >
            Invite To Athena
          </MenuItem>
          <MenuItem
            onClick={() => {
              setViewReport(true);
              handleClose();
            }}
            className='fs-14'
          >
            {t('reports.viewReports')}
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

const CheckboxRenderer = ({ defaultChecked, onChange, hover }) => {
  return (
    <Tooltip title={hover} placement='top'>
      <Box display='flex' justifyContent='center' width='100%'>
        <input
          style={{
            width: '18px',
            height: '18px',
            cursor: 'pointer'
          }}
          type='checkbox'
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
      </Box>
    </Tooltip>
  );
};
let stagesWithoutLogical = [];
let gridApi, columnApi;
const CandidatePipeline = props => {
  const { refreshDataCbRef } = props;
  const [activeStage, setActiveStage] = useState(null);
  const [stages, setStages] = useState([]);
  stagesWithoutLogical = stages?.filter(stage => !stage?.isLogicalStage) || [];
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  const gridApiRef = useRef(null);
  const [alert, setAlert] = useState({});
  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const [openCreateFromResume, setOpenCreateFromResume] = useState(false);
  const [openChooseFromContact, setOpenChooseFromContact] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const activeStageRef = useRef(activeStage);
  const searchValueRef = useRef('');
  const { selectedColor } = useSelector(state => state.viewProjectReducer);
  const [openAddContactDrawer, setOpenAddContactDrawer] = useState(false);
  const selectedColorIdRef = useRef(selectedColor);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [filterStages, setFilterStages] = useState([]);
  const deleteId = useRef(null);
  let query = useQuery();
  const navigate = useNavigate();
  const [selectedStage, setSelectedStage] = useState(null);
  const [quickAddContactProcessing, setQuickAddContactProcessing] = useState(false);
  const [offLimitWarningPopup, setOffLimitWarningPopup] = useState(false);
  const [offLimitWarningPopupForUsers, setOffLimitWarningPopupForUsers] = useState(false);
  const [offlimitForNewContact, setOfflimitForNewContact] = useState(false);
  const [candidateStageChangeState, setCandidateStageChangeState] = useState(null);
  const [candidateStageChangeStateForUsers, setCandidateStageChangeStateForUsers] = useState(null);
  const { contactOffLimit, getOffLimitFromSummary, getOffLimitFromSummaryForUsers } = useOfflimit();
  const [allContactsOffLimit, setAllContactsOffLimit] = useState(false);
  const { projectData } = useContext(ProjectDataContext);
  const [showTickWarning, setShowTickWarning] = useState(false);
  const [newContactOffLimitData, setNewContactOffLimitData] = useState(null);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState({
    reasons: [],
    comments: ''
  });
  const pipelineGridRef = useRef(null);
  const csvExportRef = useRef(null);
  const [activeType, setActiveType] = useState(null);
  const [gridLoading, setGridLoading] = useState(true);

  const [gridState, setGridState] = useState([]);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});

  const [currentGridData, setCurrentGridData] = useState([]);

  const [filteredGridData, setFilteredGridData] = useState([]);

  const handleCloseWarningPopup = () => {
    setOffLimitWarningPopup(false);
    setShowTickWarning(false);
    setCandidateStageChangeState(null);
  };
  const handleCloseWarningPopupForUsers = () => {
    setOffLimitWarningPopupForUsers(false);
    setShowTickWarning(false);
    setCandidateStageChangeStateForUsers(null);
  };
  const handleCloseWarningPopupForNewContact = () => {
    setOfflimitForNewContact(false);
    setShowTickWarning(false);
    setNewContactOffLimitData(null);
    setOpenQuickAddContact(false);
  };

  const handleWarningOfflimitConfirm = async () => {
    handleStageChange?.(candidateStageChangeState?.id)?.(candidateStageChangeState?.stage)?.(candidateStageChangeState?.rejectReasons);
    await LogCandidateActivityAPI(POST, '', {
      notes_type: 'offlimits_override',
      notes: 'Off-limit(s) overwritten',
      activity_type: 'offlimits_override',
      project_id: projectData?.id,
      contact_id: candidateStageChangeState?.contactId
    });
    handleCloseWarningPopup();
  };
  const handleWarningOfflimitConfirmForUsers = async () => {
    setAlert({ open: true, message: 'Updating candidate stages' });
    const { ids, stage, rejectReasons } = candidateStageChangeStateForUsers;
    ProjectCandidateApi(PATCH, '/bulk', { ids, stage, reject_reasons: rejectReasons?.reasons, reject_comments: rejectReasons?.comments })
      .then(() => {
        setAlert({ open: true, message: 'Candidate stages updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating candidate stages' });
      });
    candidateStageChangeStateForUsers?.contacts?.map(async contact => {
      await LogCandidateActivityAPI(POST, '', {
        notes_type: 'offlimits_override',
        notes: 'Off-limit(s) overwritten',
        activity_type: 'offlimits_override',
        project_id: projectData?.id,
        contact_id: contact?.contactId
      });
    });

    handleCloseWarningPopupForUsers();
  };
  const handleWarningOfflimitConfirmForNewContact = async () => {
    setAlert({ open: true, message: 'Updating candidate stages' });
    const contact_id = newContactOffLimitData?.contactDetails?.id;
    const payload = {
      contact_id,
      project: projectData,
      project_id: projectData?.id,
      stage: newContactOffLimitData?.stage
    };
    ProjectCandidateDataApi(POST, null, payload)
      .then(() => {
        setAlert({ open: true, message: 'Candidate stages updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating candidate stages' });
      });
    await LogCandidateActivityAPI(POST, '', {
      notes_type: 'offlimits_override',
      notes: 'Off-limit(s) overwritten',
      activity_type: 'offlimits_override',
      project_id: projectData?.id,
      contact_id
    });
    handleCloseWarningPopupForNewContact();
  };

  const fetchOffLimits = async (contactId, setAlert) => {
    setAlert({ open: true, message: 'Fetching Off-Limits details...' });
    const res = await getOffLimitFromSummary(contactId, projectData.id);
    return res;
  };

  const checkIfClientPortalStage = async (stage, stageGroups) => {
    const stage_id = stages.find(stg => stg.stage_name === stage)?.id;
    const isClientPortal = !!stageGroups?.find(obj => obj?.stage_id === stage_id);
    return isClientPortal;
  };

  const handleStageChangeWithOffLimits = useCallback(
    (item, contactId, selectedReasons) => async stage => {
      const id = item?.id;
      const reasons = selectedReasons?.reasons?.length > 0 ? selectedReasons?.reasons?.map(item => item.field_value) : selectedReasons?.reasons;
      const rejectReasons = {
        reject_reasons: reasons?.length ? reasons : item?.reject_reasons,
        reject_comments: selectedReasons?.comments
      };
      const offLimits = await fetchOffLimits(contactId, setAlert);
      const groupData = await candidateStageGroupApi(GET, `all?project_id=${projectData.id}`);
      setAlert({ open: false, message: '' });
      if (offLimits?.length) {
        setCandidateStageChangeState({
          id,
          stage,
          contactId,
          rejectReasons
        });
        const stageGroups = groupData?.data?.rows ?? [];
        const isClientPortal = await checkIfClientPortalStage(stage, stageGroups);
        setShowTickWarning(isClientPortal);
        setOffLimitWarningPopup(true);
      } else {
        handleStageChange?.(id)?.(stage)?.(rejectReasons);
      }
    },
    [stages, projectData]
  );

  const [colDefs, setColDefs] = useState([
    {
      field: 'color_code.color_name',
      colId: 'color',
      headerName: 'Color',
      flex: 1,
      suppressSizeToFit: true,
      pinned: 'left',
      cellRenderer: ({ data }) => {
        return <ColorDropdown onChange={colorId => handleCandidateColorChange(data?.id)(colorId)} selectedColor={data?.color_id} fromCandidate />;
      },
      filterFramework: props => CustomDropdownFilter({ ...props, pipelineGridRef }),
      valueGetter: ({ data }) => {
        if (!data) {
          return null;
        }
        if (data.color_code) {
          if (!isEmpty(data.color_code.candidate_labels)) {
            return data.color_code.candidate_labels[0].label;
          }
          return data.color_code.color_name ? capitalize(data.color_code.color_name) : data.color_code.color_code;
        }
        return null;
      },
      minWidth: 100,
      maxWidth: 100,
      visible: true,
      filter: false,
      sortable: false
    },
    {
      field: 'contact.name',
      colId: 'name',
      headerName: 'Name',
      flex: 1,
      suppressSizeToFit: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <NameRender name={data?.contact?.name} candidate={data} setSelectedCandidate={setSelectedCandidate} setIsDrawerOpen={setIsDrawerOpen} />,
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      visible: true,
      pinned: 'left',
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      valueGetter: ({ data }) => data?.contact?.name
    },
    {
      field: 'contact.current_job_title',
      colId: 'job_title',
      headerName: 'Job Title',
      flex: 1,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={data?.contact?.current_job_title} name={'Job Title'} />,
      minWidth: 200,
      maxWidth: 300,
      visible: true,
      filter: 'agMultiColumnFilter',
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      valueGetter: ({ data }) => data?.contact?.current_job_title
    },
    {
      field: 'contact.contact_company?.name',
      flex: 1,
      colId: 'current_company',
      headerName: 'Current Company',
      visible: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={data?.contact?.contact_company?.name} name={'Current Company'} />,
      minWidth: 200,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      maxWidth: 200,
      sortable: true,
      valueGetter: ({ data }) => data?.contact?.contact_company?.name
    },
    {
      headerName: 'Off-limit(s)',
      field: 'offlimits',
      colId: 'offlimits',
      minWidth: 200,
      maxWidth: 200,
      visible: true,
      filter: false,
      sortable: false,
      cellRenderer: ({ value }) => (
        <OfflimitRenderer
          value={{
            contactId: value?.contact_id,
            offlimits: value?.contact?.offlimits_summaries
          }}
        />
      )
    },
    {
      field: 'stage',
      headerName: 'Stage',
      colId: 'stage',
      flex: 1,
      sortable: true,
      suppressSizeToFit: true,
      visible: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={data?.stage} name='Stage' />,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      minWidth: 200,
      maxWidth: 200,
      valueGetter: ({ data }) => data?.stage
    },
    {
      field: 'stage_setup.report_stage_group.report_group_name',
      headerName: 'Stage Group',
      colId: 'report_group_name',
      flex: 1,
      sortable: true,
      visible: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => {
        const reportGroupName = data?.stage_setup?.report_stage_group?.report_group_name;
        let modifiedText = reportGroupName;
        if (reportGroupName === 'Pipeline') {
          modifiedText = '01-Pipeline';
        } else if (reportGroupName === 'Active') {
          modifiedText = '02-Active';
        } else if (reportGroupName === 'Eliminated') {
          modifiedText = '03-Eliminated';
        }
        return <TextRender text={modifiedText} name='Stage Group' />;
      },
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      minWidth: 200,
      maxWidth: 200,
      valueGetter: ({ data }) => {
        const reportGroupName = data?.stage_setup?.report_stage_group?.report_group_name;
        let modifiedText = reportGroupName;
        if (reportGroupName === 'Pipeline') {
          modifiedText = '01-Pipeline';
        } else if (reportGroupName === 'Active') {
          modifiedText = '02-Active';
        } else if (reportGroupName === 'Eliminated') {
          modifiedText = '03-Eliminated';
        }
        return modifiedText;
      }
    },
    {
      field: 'contact.contact_address.city',
      headerName: 'Location',
      colId: 'location',
      flex: 1,
      sortable: true,
      visible: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={data?.contact?.contact_address?.[0]?.city} name='Location' />,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      minWidth: 200,
      maxWidth: 200,
      valueGetter: ({ data }) => data?.contact?.contact_address?.[0]?.city
    },
    {
      field: 'Industry',
      headerName: 'Industry',
      colId: 'industry',
      flex: 1,
      visible: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={data?.contact?.contact_industries?.[0]?.industries?.name} name={'Industry'} />,
      minWidth: 200,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      maxWidth: 300,
      sortable: true,
      valueGetter: ({ data }) => data?.contact?.contact_industries?.[0]?.industries?.name
    },
    {
      minWidth: 300,
      field: 'summary_text',
      colId: 'summary_text',
      headerName: 'Rationale',
      filter: 'agMultiColumnFilter',
      cellRenderer: ({ data }) => {
        return (
          <Box
            onDoubleClick={() => {
              setRationaleModalData({
                open: true,
                candidateRationale: data,
                loading: false,
                initialRationaleText: data?.summary_text,
                value: data?.summary_text
              });
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(data?.summary_text || '--')}
          </Box>
        );
      },
      editable: false,
      sortable: true,
      visible: true
    },
    {
      field: 'Comments',
      headerName: 'Comments',
      colId: 'comments',
      flex: 1,
      visible: true,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => {
        const commentsText = data?.contact?.contact_notes_all?.find(note => note?.notes_type === 'comments')?.notes;

        return (
          <Box
            onDoubleClick={() => {
              setCommentsModalData({
                open: true,
                candidate: data,
                loading: false,
                initialCommentText: commentsText,
                value: commentsText
              });
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(commentsText || '--')}
          </Box>
        );
      },
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      valueGetter: params => {
        const commentsText = params.data?.contact?.contact_notes_all?.find(note => note?.notes_type === 'comments')?.notes;
        if (!commentsText) return '';
        const temp = document.createElement('div');
        temp.innerHTML = commentsText;
        return temp.textContent || temp.innerText || '';
      }
    },
    {
      field: 'next_steps',
      headerName: 'Next Steps',
      colId: 'next_steps',
      flex: 1,
      visible: true,
      cellRenderer: ({ data }) => {
        const nextStepsText = data?.contact?.contact_notes_all?.find(note => note?.notes_type === 'next_step')?.notes;
        return (
          <Box
            onDoubleClick={() => {
              setNextStepsModalData({
                open: true,
                candidateNextSteps: data,
                loading: false,
                initialNextSteps: nextStepsText,
                value: nextStepsText
              });
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(nextStepsText || '--')}
          </Box>
        );
      },
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      valueGetter: ({ data }) => data?.contact?.contact_notes_all?.find(note => note?.notes_type === 'next_step')?.notes
    },
    {
      field: 'reject_reasons',
      colId: 'reject_reasons',
      headerName: 'Reject Reasons',
      minWidth: 200,
      maxWidth: 200,
      visible: true,
      filter: 'agMultiColumnFilter',
      flex: 1,
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <RenderRejectReasons value={data?.reject_reasons} name='Reject Reasons' />,
      editable: false,
      sortable: true,
      valueGetter: ({ data }) => data?.reject_reasons
    },
    {
      minWidth: 140,
      maxWidth: 300,
      flex: 1,
      colId: 'created_at',
      field: 'created_at',
      visible: true,
      headerName: 'Date Added',
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={formatDate(data?.created_at)} name='Created At' />,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      editable: false,
      sortable: true,
      valueGetter: ({ data }) => formatDate(data?.created_at)
    },
    {
      minWidth: 140,
      flex: 1,
      field: 'created_by',
      colId: 'created_by',
      visible: true,
      headerName: 'Added By',
      // cellRendererFramework: ({ value }) => value,
      cellRenderer: ({ data }) => <TextRender text={`${data?.created_by_user?.first_name || ''} ${data?.created_by_user?.last_name || ''}`.trim()} name='Created By' />,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      editable: false,
      sortable: true,
      valueGetter: ({ data }) => data?.created_by_user?.first_name || ''
    },
    {
      minWidth: 180,
      maxWidth: 180,
      flex: 1,
      visible: true,
      field: 'updated_at',
      colId: 'updated_at',
      headerName: 'Last Activity On',
      cellRenderer: ({ data }) => <TextRender text={formatDate(data?.updated_at)} name='Updated At' />,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      editable: false,
      sortable: true,
      valueGetter: ({ data }) => formatDate(data?.updated_at)
    },
    {
      minWidth: 180,
      maxWidth: 180,
      flex: 1,
      field: 'updated_by',
      colId: 'updated_by',
      visible: true,
      headerName: 'Last Activity By',
      cellRenderer: ({ data }) => <TextRender text={`${data?.updated_by_user?.first_name || ''} ${data?.updated_by_user?.last_name || ''}`.trim()} name='Updated By' />,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      editable: false,
      sortable: true,
      valueGetter: ({ data }) => data?.updated_by_user?.first_name || ''
    },
    {
      headerName: 'Action',
      flex: 1,
      field: 'Action',
      colId: 'actions',
      visible: true,
      cellRenderer: ({ data }) => (
        <ActionRenderer
          projectData={projectData}
          item={data}
          stages={stagesWithoutLogical}
          handleStageChange={handleStageChangeWithOffLimits}
          handleDeleteCandidate={handleDeleteCandidate}
          handleViewAthena={handleViewAthena}
          handleInviteToAthena={handleInviteAthena}
        />
      ),
      minWidth: 200,
      maxWidth: 200,
      pinned: 'right',
      filter: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      cellStyle: params => {
        return params.node.group ? { display: 'none' } : {};
      }
    }
  ]);

  const clearQuickAddContactData = () => {
    const fieldsToReset = [
      QUICK_ADD_CONTACT_COMMON_FIELD.stage,
      QUICK_ADD_CONTACT_COMMON_FIELD.company,
      QUICK_ADD_CONTACT_COMMON_FIELD.first_name,
      QUICK_ADD_CONTACT_COMMON_FIELD.last_name,
      QUICK_ADD_CONTACT_COMMON_FIELD.email,
      QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber,
      QUICK_ADD_CONTACT_COMMON_FIELD.linkedin,
      QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title
    ];

    fieldsToReset.forEach(field => {
      if (field && typeof field === 'string') {
        setValue(field, '');
      }
    });

    setSelectedStage(null);
  };

  const getGridSettings = useCallback(() => {
    fetchGridData('', 'candidatePipelineGrid', ({ status, data }) => {
      if (status) {
        const sortOrder = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');

        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (sortOrder.length) {
          const savedConfigMap = sortOrder.reduce((acc, column) => {
            acc[column.colId] = column;
            return acc;
          }, {});

          const updatedColDefs = colDefs.map(col => {
            const savedConfig = savedConfigMap[col.colId];
            if (savedConfig) {
              const filteredOutConfig = omit(savedConfig, ['sort', 'sortIndex']);
              return {
                ...col,
                ...filteredOutConfig
              };
            }
            return col;
          });

          updatedColDefs.sort((a, b) => {
            const indexA = sortOrder.findIndex(column => column.colId === a.colId);
            const indexB = sortOrder.findIndex(column => column.colId === b.colId);
            return indexA - indexB;
          });

          setGridState([...updatedColDefs]); //server  columns
          // setGridState([...colDefs]); //client columns for testing
        } else {
          return setGridState([...colDefs]);
        }
      } else {
        return setGridState([...colDefs]);
      }
    });
  }, [isGridReady]);

  useEffect(() => {
    if (isGridReady) {
      getGridSettings();
    }
  }, [isGridReady]);

  useEffect(() => {
    if (isDrawerOpen) {
      setColDefs(colDefs.slice(1));
    } else {
      // setColDefs(colDefs => [
      //   {
      //     width: 40,
      //     checkboxSelection: true,
      //     headerCheckboxSelection: true,
      //     suppressColumnsToolPanel: true,
      //     suppressHeaderMenuButton: true,
      //     filter: false,
      //     sortable: false,
      //     pinned: 'left',
      //     colId: 'checkboxes'
      //   },
      //   ...colDefs
      // ]);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    activeStageRef.current = activeStage;
  }, [activeStage]);

  useEffect(() => {
    selectedColorIdRef.current = selectedColor;
  }, [selectedColor]);

  const refreshData = async () => {
    await fetchCandidateData();
    await fetchCandidateStages();
  };

  useEffect(() => {
    if (refreshDataCbRef.current !== null) {
      refreshData();
    }
  }, [refreshDataCbRef.current]);

  const fetchCandidateStages = () => {
    CandidateStageSetupAPi(GET, `/all?projectId=${projectData?.id ? projectData?.id : query.get('id')}&showLogicalStages=true`).then(res => {
      if (res.status === 200) {
        setStages(res.data);
        if (activeStage) {
          const stage = res.data.find(stage => stage.stage_name === activeStage.stage_name);
          setActiveStage(stage);
          setSelectedStage(stage);
        } else {
          setActiveStage(res.data?.[0]);
          setSelectedStage(res.data?.[0]);
        }
      }
    });
  };

  const bulkUpdateCandidateStage = (stage, rejectReasons) => {
    let selectedNodes = pipelineGridRef.current.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    const reasons = rejectReasons?.reasons?.length > 0 ? rejectReasons?.reasons?.map(item => item.field_value) : rejectReasons?.reasons;
    const rejectedReasons = {
      reasons,
      comments: rejectReasons?.comments
    };
    if (typeof stage === 'string' && selectedData?.length > 0) {
      bulkUpdateStage(selectedData, stage, rejectedReasons);
    }
  };

  const bulkUpdateStage = async (selectedData, stage, rejectReasons) => {
    try {
      setAlert({ open: true, message: 'Fetching Off-Limits details...' });
      const ids = selectedData.map(data => data?.id);
      const contacts = selectedData.map(data => data.contact);
      const contactIds = contacts?.map(contact => contact?.id);

      const res = await getOffLimitFromSummaryForUsers(contactIds, projectData.id);
      const hasOffLimits = res?.filter(contact => contact.offLimits.length > 0);
      if (!hasOffLimits) {
        setAlert({ open: true, message: 'Updating candidate stages' });
        ProjectCandidateApi(PATCH, '/bulk', { ids, stage, reject_reasons: rejectReasons?.reasons, reject_comments: rejectReasons?.comments })
          .then(() => {
            setAlert({ open: true, message: 'Candidate stages updated successfully' });
            setSelectedReasons({
              reasons: [],
              comments: ''
            });
            refreshData();
          })
          .catch(() => {
            setAlert({ open: true, message: 'Error updating candidate stages' });
          });
      } else {
        const groupData = await candidateStageGroupApi(GET, `all?project_id=${projectData.id}`);
        const stageGroups = groupData?.data?.rows ?? [];
        const isClientPortal = await checkIfClientPortalStage(stage, stageGroups);
        setShowTickWarning(isClientPortal);
        setAlert({ open: false, message: '' });
        setCandidateStageChangeStateForUsers({
          ids,
          stage,
          contacts: hasOffLimits,
          rejectReasons
        });
        const data = hasOffLimits?.map(offLimitResponse => {
          const contactId = offLimitResponse?.contactId;
          const offLimits = offLimitResponse?.offLimits;
          const contactDetails = contacts.find(contact => contact.id === contactId);
          return {
            offLimits,
            contactDetails
          };
        });
        setAllContactsOffLimit(data);
        setOffLimitWarningPopupForUsers(true);
      }
    } catch (error) {
      setAlert({ open: true, message: 'Something went wrong' });
    }
  };

  useEffect(() => {
    fetchCandidateStages();
  }, []);

  const handleStageChange = id => stage => rejectPayload => {
    setAlert({ open: true, message: 'updating stage' });
    const { reject_reasons, reject_comments } = rejectPayload;
    ProjectCandidateApi(PATCH, '/', { id, stage, reject_reasons, reject_comments })
      .then(() => {
        setAlert({ open: true, message: 'Stage updated successfully' });
        refreshData();
        if (selectedCandidate) {
          setSelectedCandidate(prev => ({ ...prev, stage }));
          setSelectedReasons({
            reasons: [],
            comments: ''
          });
        }
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating stage' });
      });
  };

  const handleCandidateColorChange = id => colorId => {
    setAlert({ open: true, message: 'Updating color' });
    if (id === selectedCandidate?.id) {
      setSelectedCandidate({ ...selectedCandidate, color_id: colorId });
    }
    ProjectCandidateApi(PATCH, '/', { id, color_id: colorId })
      .then(() => {
        setAlert({ open: true, message: 'Color assignment updated successfully' });
        refreshData();
      })
      .catch(() => {
        if (id === selectedCandidate?.id) {
          setSelectedCandidate(selectedCandidate);
        }
        setAlert({ open: true, message: 'Error updating color' });
      });
  };

  const handleCandidateColorLabelChange = (label, colorId) => {
    setAlert({ open: true, message: 'updating label' });
    CandidateColorLabelDataApi(POST, { projectId: query.get('id'), label, colorId })
      .then(() => {
        setAlert({ open: true, message: 'Label updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating Label' });
      });
  };

  const handleViewAthena = async item => {
    try {
      const payload = {
        project_id: item.project_id,
        contact_id: item.contact_id,
        language_used: '',
        email_used: ''
      };
      const res = await ignAthenaDataApi(POST, '', payload, 'create-assessment');

      const assessmentId = res?.data ?? '';
      if (assessmentId.length > 0) {
        navigate(`/athena/${assessmentId}/${item?.contact?.first_name + ' ' + item?.contact?.last_name}`);
      }
    } catch (err) {
      enqueueSnackbar(err);
    }
  };

  const [isInviteAthenaModalOpen, setInviteAthenaModalOpen] = useState(false);
  const [inviteAthenaContact, setInviteAthenaContact] = useState({});
  const handleInviteAthena = item => {
    setInviteAthenaContact({ ...item?.contact, id: item?.contact_id, name: item?.contact?.first_name + ' ' + item?.contact?.last_name });
    setInviteAthenaModalOpen(true);
  };

  const [{ open: isCommentsModalOpen, candidate, loading: updateCommentsLoading, initialCommentText, value: commentText }, setCommentsModalData] = useState({
    open: false,
    candidate: null,
    loading: false,
    initialCommentText: '',
    value: ''
  });

  const [{ open: isNextStepsModalOpen, candidateNextSteps, loading: updateNextStepsLoading, initialNextSteps, value: nextSteps }, setNextStepsModalData] = useState({
    open: false,
    candidateNextSteps: null,
    loading: false,
    initialNextSteps: '',
    value: ''
  });

  const [{ open: isRationaleModalOpen, candidateRationale, loading: updateRationaleLoading, initialRationaleText, value: rationaleText }, setRationaleModalData] = useState({
    open: false,
    candidateRationale: null,
    loading: false,
    initialRationaleText: '',
    value: ''
  });

  const formatData = data => {
    return data.map(item => {
      const nextStepsText = item?.contact?.contact_notes_all?.find(note => note?.notes_type === 'next_step')?.notes;
      const commentsText = item?.contact?.contact_notes_all?.find(note => note?.notes_type === 'comments')?.notes;
      return {
        id: item.id,
        Color: item,
        name: <NameRender name={item?.contact?.name} candidate={item} setSelectedCandidate={setSelectedCandidate} setIsDrawerOpen={setIsDrawerOpen} />,
        'Job Title': <TextRender text={item?.contact?.current_job_title} name={'Job Title'} />,
        'Current Company': <TextRender text={item?.contact?.contact_company?.name} name={'Current Company'} />,
        Industry: <TextRender text={item?.contact?.contact_industries?.[0]?.industries?.name} name={'Industry'} />,
        location: <TextRender text={item?.contact?.contact_address?.[0]?.city} name='Location' />,
        stage: <TextRender text={item?.stage} name='Stage' />,
        Comments: (
          <Box
            onDoubleClick={() => {
              setCommentsModalData({
                open: true,
                candidate: item,
                loading: false,
                initialCommentText: commentsText,
                value: commentsText
              });
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(commentsText || '--')}
          </Box>
        ),
        next_steps: (
          <Box
            onDoubleClick={() => {
              setNextStepsModalData({
                open: true,
                candidateNextSteps: item,
                loading: false,
                initialNextSteps: nextStepsText,
                value: nextStepsText
              });
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(nextStepsText || '--')}
          </Box>
        ),
        Rationale: (
          <Box
            onDoubleClick={() => {
              setRationaleModalData({
                open: true,
                candidateRationale: item,
                loading: false,
                initialRationaleText: rationaleText,
                value: rationaleText
              });
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(rationaleText || '--')}
          </Box>
        ),
        reject_reasons: <RenderRejectReasons value={item?.reject_reasons} name='Reject Reasons' />,
        created_at: <TextRender text={formatDate(item?.created_at)} name='Created At' />,
        created_by: <TextRender text={`${item?.created_by_user?.first_name || ''} ${item?.created_by_user?.last_name || ''}`.trim()} name='Created By' />,
        updated_at: <TextRender text={formatDate(item?.updated_at)} name='Updated At' />,
        updated_by: <TextRender text={`${item?.updated_by_user?.first_name || ''} ${item?.updated_by_user?.last_name || ''}`.trim()} name='Updated By' />,
        offlimits: item,
        Action: (
          <ActionRenderer
            projectData={projectData}
            item={item}
            stages={stagesWithoutLogical}
            handleStageChange={handleStageChangeWithOffLimits}
            handleDeleteCandidate={handleDeleteCandidate}
            handleViewAthena={handleViewAthena}
            handleInviteToAthena={handleInviteAthena}
          />
        )
      };
    });
  };

  const changeStage = handleStageChangeWithOffLimits(selectedCandidate, selectedCandidate?.contact_id, selectedReasons);

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    pipelineGridRef.current = params;
    // const columnApi = params.columnApi
    // params.columnApi.autoSizeAllColumns();
    // this.setState({ isGridReady: true });

    // gridApi?.setGridOption('datasource', dataSource);
    setIsGridReady(true);
  };

  const handleAddCandidate = option => {
    if (option === 'Quick add') {
      setOpenQuickAddContact(true);
    } else if (option === 'Add from resume') {
      setOpenCreateFromResume(true);
    } else if (option === 'Select existing contact') {
      setOpenChooseFromContact(true);
    } else if (option === 'Add new contact') {
      setOpenAddContactDrawer(true);
    }
  };

  // const dataSource = {
  //   getRows: async params => {
  //     const { sortModel, filterModel } = params;
  //     let page = Math.floor(params.endRow / 100);
  //     let sub_route = `/all?limit=${PAGE_LIMIT}&page=${page}&projectId=${projectData.id}&stage=${encodeURIComponent(activeStageRef.current?.stage_name)}`;

  //     if (activeStageRef.current?.isLogicalStage) {
  //       sub_route = `/all?limit=${PAGE_LIMIT}&page=${page}&projectId=${projectData.id}`;
  //       activeStageRef.current.stageFilters.forEach(filter => {
  //         sub_route += `&stage=${encodeURIComponent(filter)}`;
  //       });
  //     }

  //     if (selectedColorIdRef.current) {
  //       sub_route += `&color_id=${selectedColorIdRef.current}`;
  //     }

  //     if (sortModel?.length > 0) {
  //       sub_route = sub_route.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
  //     }

  //     const contactName = searchValueRef.current?.contact;

  //     if (contactName) {
  //       sub_route += `&contact_name=${contactName?.first_name || ''} ${contactName?.last_name || ''}`;
  //     }

  //     // gridApiRef.current.api.showLoadingOverlay();
  //     setGridLoading(true);

  //     const res = await ProjectCandidateApi(GET, sub_route);
  //     if (res.status === 200) {
  //       setCurrentGridData(res.data.rows);
  //       let formattedData = formatData(res.data.rows);
  //       formattedData = formattedData.filter(candidate => {
  //         return Object.entries(filterModel).every(([key, value]) => {
  //           if (!value || !value.filter) return true;
  //           const searchValue = value.filter.toLowerCase();
  //           switch (key) {
  //             case 'name':
  //               return candidate.name?.props?.name?.toLowerCase().includes(searchValue);
  //             case 'Job Title':
  //               return candidate['Job Title']?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'Current Company':
  //               return candidate['Current Company']?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'Industry':
  //               return candidate['Industry']?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'location':
  //               return candidate['location']?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'stage':
  //               return candidate['stage']?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'Comments':
  //               return candidate.Comments?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'next_steps':
  //               return candidate.next_steps?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'created_at':
  //               return candidate.created_at?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'created_by':
  //               return candidate.created_by?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'updated_at':
  //               return candidate.updated_at?.props?.text?.toLowerCase().includes(searchValue);
  //             case 'updated_by':
  //               return candidate.updated_by?.props?.text?.toLowerCase().includes(searchValue);
  //             default:
  //               return true;
  //           }
  //         });
  //       });

  //       // gridApiRef.current.api.hideOverlay();
  //       setGridLoading(false);

  //       const totalCount = formattedData.length;
  //       params.successCallback(formattedData, totalCount);
  //     } else {
  //       params.failCallback();
  //     }
  //   }
  // };

  const getRowsData = async (bgFetch = false, limit = PAGE_LIMIT, offset = 0) => {
    let page = 1;
    let sub_route = `/all?limit=${limit}&page=${page}&offset=${offset}&projectId=${projectData.id}&stage=${encodeURIComponent(activeStageRef.current?.stage_name)}`;

    if (activeStageRef.current?.isLogicalStage) {
      sub_route = `/all?limit=${limit}&page=${page}&offset=${offset}&projectId=${projectData.id}`;
      activeStageRef.current.stageFilters.forEach(filter => {
        sub_route += `&stage=${encodeURIComponent(filter)}`;
      });
    }

    if (selectedColorIdRef.current) {
      sub_route += `&color_id=${selectedColorIdRef.current}`;
    }

    //sorting will be handled by ag grid
    // if (sortModel?.length > 0) {
    //   sub_route = sub_route.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
    // }

    const contactName = searchValueRef.current?.contact;

    if (contactName) {
      sub_route += `&contact_name=${contactName?.first_name || ''} ${contactName?.last_name || ''}`;
    }

    if (!bgFetch) {
      pipelineGridRef.current?.api.showLoadingOverlay();
      setGridLoading(true);
    }
    const res = await ProjectCandidateApi(GET, sub_route);
    if (res.status === 200) {
      const dataLength = res.data.rows.length;
      if (!bgFetch) {
        setCurrentGridData(res.data.rows);
      } else {
        setCurrentGridData(prevData => {
          return [...prevData, ...res.data.rows];
        });
      }
      const totalCount = res.data.count || 100; // Handle edge case
      if (dataLength < limit) {
        // * NOTE: DO not remove this if block. // No extra fetch required
      } else if (!bgFetch && totalCount > limit) {
        getRowsData(true, totalCount, limit);
      }
    }
    if (!bgFetch) {
      pipelineGridRef.current?.api.hideOverlay();
      setGridLoading(false);
    }
  };

  const fetchCandidateData = () => {
    if (!gridApiRef.current || !gridApiRef.current.api) {
      console.error('Grid API is not available. Please ensure the grid is initialized.');
      return;
    }

    // Reset the data source to trigger a refetch
    // gridApiRef.current.api.setDatasource(dataSource);
  };

  useEffect(() => {
    if (activeStage) {
      getRowsData(false, PAGE_LIMIT, 0);
    }
  }, [activeStage]);

  const handleDeleteCandidate = id => {
    deleteId.current = id;
    setShowConfirmDialog(true);
  };

  const handleConfirmDeleteCandidate = async () => {
    setAlert({ open: true, message: 'Deleting candidate' });
    ProjectCandidateApi(DELETE, `/?id=${deleteId.current}`)
      .then(res => {
        if (res.status === 500) {
          throw new Error(res.data.message);
        }
        setAlert({ open: true, message: 'Candidate deleted successfully' });
        refreshData();
      })
      .catch(err => {
        setAlert({ open: true, message: err?.message });
      })
      .finally(() => {
        setShowConfirmDialog(false);
      });
  };

  // useEffect(() => {
  //   if (activeStage?.stage_name && projectData?.id) {
  //     fetchCandidateData();
  //   }
  // }, [activeStage, projectData]);

  const openCandidateDrawer = id => {
    setIsDrawerOpen(true);
    setSelectedCandidate(id);
  };

  const useStyles = makeStyles(() => ({
    inputRoot: {
      height: '56.28px' // Adjust the height as needed
    }
  }));

  const { register, handleSubmit, setValue, control, getValues, watch } = useForm();
  const classes = useStyles();
  // const { data: companiesResponse, refetch: fetchCompanies } = useApi({
  //   queryFn: () => {
  //     return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.COMPANIES_PICKLIST);
  //   }
  // });
  const { t } = useTranslation();
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const { translateMessage } = useCustomMessageHook();

  const newFields = () => {
    const [loader, setLoader] = useState(false);
    const { getValues: companygetValues, reset: companyReset, setValue: setCompanyValue, register: registerCompany, unregister: unregisterCompany, watch: watchCompany } = useForm();
    const changeOptionOnLoad = useRef(null);

    useEffect(() => {
      if (changeOptionOnLoad.current !== null) {
        setValue('company', changeOptionOnLoad.current);
        changeOptionOnLoad.current = null;
      }
    }, []);

    const isValidated = async requestBody => {
      let isValid = await createSchema.isValid(requestBody);
      if (!isValid) {
        let dirtyField = customFormValidator(requestBody, createRequiredField);
        if (dirtyField) {
          const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      } else {
        return true;
      }
    };

    const getPayload = data => {
      const addCompanyPayload = {
        ...data,
        company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
        company_industries: data?.company_industries
          ? data?.company_industries.map(industry => ({
              industry_id: industry.id
            }))
          : [],
        capital_structure: data?.capital_structure?.field_value,
        currency_unit: data?.currency_unit?.field_value
      };
      return addCompanyPayload;
    };

    const saveCompanyData = async () => {
      let formValues = { ...companygetValues() };
      const requestBody = getPayload(formValues);
      const IsValidRequestBody = await isValidated(requestBody);
      if (IsValidRequestBody) {
        setLoader(true);
        const { status, data } = await ignCompanyDataApi(POST, requestBody);
        if (status === 201) {
          const message = translateMessage('Successfully', false, 'Company', 'created');
          enqueueSnackbar(message, { variant: SUCCESS });
          setAddCompanyPopup(false);
          companyReset();

          setLoader(false);
          const companyData = await ignCompanyDataApi(GET, {}, data?.id);
          changeOptionOnLoad.current = { id: companyData?.data?.id, name: companyData?.data?.name };
        } else if (status === 409) {
          const message = translateMessage('AlreadyExist', false, 'Company');
          enqueueSnackbar(message, {
            variant: ERROR
          });
        } else {
          const message = translateMessage('UnableMessage', false, 'Create', 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        }
        setLoader(false);
      }
    };

    return (
      <>
        <CompanySelectionDebounce
          classes={{
            inputRoot: classes.inputRoot
          }}
          onChange={(event, newValue) => {
            setValue('company', newValue);
          }}
          label={t('contacts.addContact.companyName')}
          name='company'
          className={'w-100'}
          value={watch('company')}
          ignoreFiltering
          addAnotherButton
          addAnotherButtonOnChange={() => setAddCompanyPopup(true)}
        />
        <CustomMuiDropdown
          options={
            stagesWithoutLogical?.map(stage => {
              return { label: stage.stage_name };
            }) || []
          }
          disabled={activeStage?.stage_name !== 'All'}
          label='Stage'
          value={selectedStage?.stage_name !== 'All' ? { label: selectedStage?.stage_name } : null}
          renderInput={params => <TextField {...params} variant='outlined' />}
          onChange={(event, newVal) => {
            setSelectedStage({ stage_name: newVal?.label ?? '' });
          }}
          getOptionLabel={option => {
            return option?.label || '';
          }}
        />
        <AddCompany
          isPopupOpen={addCompanyPopup}
          handleClose={() => setAddCompanyPopup(false)}
          handleSubmit={saveCompanyData}
          setIsLoading={setLoader}
          loading={loader}
          setValue={setCompanyValue}
          register={registerCompany}
          reset={companyReset}
          setIsPopupOpen={setAddCompanyPopup}
          unregister={unregisterCompany}
          watch={watchCompany}
          noRedirection
        ></AddCompany>
      </>
    );
  };

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().nullable(),
    linkedin: yup.string().url().nullable(),
    phonenumber: yup
      .string()
      .transform((value, originalValue) => {
        // Coerce empty string or null to null
        if (originalValue === '' || originalValue === null) return null;
        return value;
      })
      .matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .nullable(),
    jobtitle: yup.string().nullable()
  });

  const [disabled, setDisabled] = useState(false);

  const clearInputs = () => {
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.first_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.lastName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.last_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.email, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_emails, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.jobtitle, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title, null);
    setValue('company', null);
    setSelectedStage(null);
  };

  const onAddSubmit = data => {
    schema
      .validate(data)
      .then(async () => {
        if (!data?.email && !data?.linkedin && !data?.phonenumber) {
          setAlert({ open: true, message: 'Email/LinkedIn/Phone number is required.', error: true });
        } else if (!data?.company || !data?.company?.id || !data?.company?.name) {
          setAlert({ open: true, message: 'Company is required', error: true });
        } else if (!selectedStage || !selectedStage?.stage_name) {
          setAlert({ open: true, message: 'Stage is required', error: true });
        } else {
          setDisabled(true);
          setQuickAddContactProcessing(true);
          const payload = {
            contact: data,
            projectId: projectData?.id,
            stage: selectedStage?.stage_name,
            checkOffLimit: true
          };
          if (!(data?.email && data?.email?.length > 0)) {
            delete payload['contact']['email'];
          }
          await ProjectCandidateApi(POST, '/create-from-contact', payload)
            .then(async res => {
              if (res.status === 500) {
                setAlert({ open: true, message: res.data.message });
                setDisabled(false);
                setQuickAddContactProcessing(false);
              } else {
                const isOfflimitError = !!res?.data?.offlimits;
                if (isOfflimitError) {
                  const error = res?.data;
                  setAlert({ open: true, message: error.message });
                  setNewContactOffLimitData({
                    offLimits: error?.offlimits,
                    contactDetails: error?.contact,
                    stage: error?.stage
                  });
                  const groupData = await candidateStageGroupApi(GET, `all?project_id=${projectData.id}`);
                  setAlert({ open: false, message: '' });
                  const stageGroups = groupData?.data?.rows ?? [];
                  const isClientPortal = await checkIfClientPortalStage(error?.stage, stageGroups);
                  setShowTickWarning(isClientPortal);
                  setTimeout(() => {
                    setOfflimitForNewContact(true);
                  }, 100);
                  return;
                }
                setAlert({ open: true, message: 'Candidate added successfully' });
                refreshData().finally(() => {
                  setDisabled(false);
                  setOpenQuickAddContact(false);
                });
              }
            })
            .catch(err => {
              setAlert({ open: true, message: err?.message });
              setDisabled(false);
              setOpenQuickAddContact(false);
            })
            .finally(() => {
              setDisabled(false);
              clearInputs();
              setQuickAddContactProcessing(false);
            });
        }
      })
      .catch(err => {
        setAlert({ open: true, message: err.errors[0] });
        setDisabled(false);
        setQuickAddContactProcessing(false);
      });
  };

  const handleUpdateCandidateComments = async candidate => {
    if (updateCommentsLoading || !candidate) return;

    setCommentsModalData(prev => ({ ...prev, loading: true }));

    const activityId = candidate?.contact?.contact_notes_all?.find(note => note?.notes_type === 'comments')?.id;
    const type = activityId ? PATCH : POST;

    try {
      await LogCandidateActivityAPI(type, activityId, {
        notes: commentText,
        activity_type: 'NOTE',
        notes_type: 'comments',
        project_id: candidate?.project_id,
        contact_id: candidate?.contact_id
      });
      setCommentsModalData({ loading: false, open: false, initialCommentText: '', candidate: null, value: '' });
      fetchCandidateData();
    } catch (error) {
      setCommentsModalData(prev => ({ ...prev, loading: false }));
      enqueueSnackbar(`Error updating comments: ${error?.message}`, { variant: ERROR });
    }
  };

  const handleUpdateCandidateNextSteps = async candidate => {
    if (updateCommentsLoading || !candidate) return;

    setNextStepsModalData(prev => ({ ...prev, loading: true }));
    const activityId = candidateNextSteps?.contact?.contact_notes_all?.find(note => note?.notes_type === 'next_step')?.id;
    const type = activityId ? PATCH : POST;

    try {
      await LogCandidateActivityAPI(type, activityId, {
        notes: nextSteps,
        activity_type: 'NOTE',
        notes_type: 'next_step',
        project_id: candidate?.project_id,
        contact_id: candidate?.contact_id
      });
      setNextStepsModalData({ loading: false, open: false, initialNextSteps: '', candidateNextSteps: null, value: '' });
      fetchCandidateData();
    } catch (error) {
      setNextStepsModalData(prev => ({ ...prev, loading: false }));
      enqueueSnackbar(`Error updating comments: ${error?.message}`, { variant: ERROR });
    }
  };

  const handleUpdateCandidateRationale = async candidate => {
    if (updateRationaleLoading || !candidate) return;
    setRationaleModalData(prev => ({ ...prev, loading: true }));

    const activityId = candidateRationale?.contact?.contact_notes_all?.find(note => note?.notes_type === 'rationale')?.id;
    const type = activityId ? PATCH : POST;

    try {
      await LogCandidateActivityAPI(type, activityId, {
        notes: rationaleText,
        activity_type: 'NOTE',
        notes_type: 'rationale',
        project_id: candidate?.project_id,
        contact_id: candidate?.contact_id
      });
      setRationaleModalData({ loading: false, open: false, initialRationaleText: '', candidateRationale: null, value: '' });
    } catch (error) {
      setRationaleModalData(prev => ({ ...prev, loading: false }));
      enqueueSnackbar(`Error updating rationale: ${error?.message}`, { variant: ERROR });
    } finally {
      getRowsData();
    }
  };

  function handleCommentsClose() {
    setCommentsModalData({ loading: false, open: false, candidate: null, initialCommentText: '', value: '' });
  }

  function handleNextStepsClose() {
    setNextStepsModalData({ loading: false, open: false, candidateNextSteps: null, initialNextSteps: '', value: '' });
  }

  function handleRationaleClose() {
    setRationaleModalData({ loading: false, open: false, candidateRationale: null, initialRationaleText: '', value: '' });
  }

  const onRejectReasonModalClose = event => {
    event.stopPropagation();
    setSelectedReasons({
      reasons: [],
      comments: ''
    });
    setOpenReasonModal(false);
  };

  const subMenuStageActionsHandler = (stage, type) => {
    const findSelectedStage = stagesWithoutLogical.find(item => item.stage_label === stage);
    setSelectedStage(findSelectedStage);
    setActiveType(type);

    if (findSelectedStage?.stage_type === REJECTED_STAGE_TYPE) {
      setOpenReasonModal(true);
    } else {
      setSelectedReasons({
        reasons: selectedCandidate?.reject_reasons,
        comments: selectedCandidate?.reject_comments
      });

      if (type === 'pipeline') {
        bulkUpdateCandidateStage(stage, {
          reasons: selectedCandidate?.reject_reasons,
          comments: selectedCandidate?.reject_comments
        });
      } else {
        changeStage(stage, {
          reasons: selectedCandidate?.reject_reasons,
          comments: selectedCandidate?.reject_comments
        });
      }
      setOpenReasonModal(false);
    }
  };

  const onSelectStageHandler = (stage = {}, event) => {
    event.stopPropagation();
    const currentStage = stage?.stage_label ? stage?.stage_label : selectedStage?.stage_label;
    if (activeType === 'pipeline') {
      bulkUpdateCandidateStage(currentStage, selectedReasons);
    } else {
      changeStage(currentStage, selectedReasons);
    }
    setOpenReasonModal(false);
    // setSelectedReasons({
    //   reasons: [],
    //   comments: ''
    // });
  };

  const candidatePipelineColumnsExports = [
    { key: 'contact.name', label: 'Name' },
    { key: 'contact.current_job_title', label: 'Job Title' },
    { key: 'stage', label: 'Stage' },
    { key: 'created_by_user.first_name', label: 'Created By' },
    // { key: 'company_industries', label: 'Company Industries' },
    { key: 'reason', label: 'Reason' },
    { key: 'source', label: 'Source' },
    { key: 'project.framework_type', label: 'Project Framework Type' },
    { key: 'project.helia_title', label: `Project ${t('project.internal_title')}` },
    { key: 'color_code.color_code', label: 'Color Code' },
    { key: 'created_at', label: 'Date Added' },
    { key: 'updated_at', label: 'Last Activity' }
  ];

  const [searchValue, setSearchValue] = useState('');
  const [candidateOptions, setCandidateOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);

  const searchProjectCandidates = async () => {
    try {
      setLoading(true);
      const response = await searchProjectCandidateAPI(GET, {
        project_id: projectData?.id,
        search: searchValue
      });

      if (response.status === 200) {
        setCandidateOptions(response?.data?.candidates || []);
      } else {
        setCandidateOptions([]);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to search: ${error?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue?.length > 1) {
      timerRef.current = setTimeout(() => {
        searchProjectCandidates();
      }, 500);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchValue]);

  return (
    <div className='ms-1'>
      <CustomPopup width={'60vw'} title={'Comments'} open={isCommentsModalOpen} onClose={handleCommentsClose} disabled={false} handleSubmit={() => {}}>
        <Box className='pipeline-comment-richtext'>
          <RichText defaultValue={initialCommentText || ''} onChange={content => setCommentsModalData(prev => ({ ...prev, value: content }))} />
        </Box>
        <Box className='d-flex w-100 justify-content-end mt-2'>
          <CustomButton
            disabled={commentText === initialCommentText || updateCommentsLoading}
            buttonText={updateCommentsLoading ? 'Saving' : 'Save'}
            boxClassName={'flex justify-content-end'}
            onClick={() => handleUpdateCandidateComments(candidate)}
          />
        </Box>
      </CustomPopup>

      <CustomPopup width={'60vw'} title={'Next Steps'} open={isNextStepsModalOpen} onClose={handleNextStepsClose} disabled={false} handleSubmit={() => {}}>
        <RichText defaultValue={initialNextSteps || ''} onChange={content => setNextStepsModalData(prev => ({ ...prev, value: content }))} />
        <Box className='d-flex w-100 justify-content-end mt-2'>
          <CustomButton
            disabled={nextSteps === initialNextSteps || updateNextStepsLoading}
            buttonText={updateNextStepsLoading ? 'Saving' : 'Save'}
            boxClassName={'flex justify-content-end mt-2'}
            onClick={() => handleUpdateCandidateNextSteps(candidateNextSteps)}
          />
        </Box>
      </CustomPopup>
      <CustomPopup width={'60vw'} title={t('project.rationale')} open={isRationaleModalOpen} onClose={handleRationaleClose} disabled={false} handleSubmit={() => {}}>
        <RichText defaultValue={initialRationaleText || ''} onChange={content => setRationaleModalData(prev => ({ ...prev, value: content }))} />
        <Box className='d-flex w-100 justify-content-end mt-2'>
          <CustomButton
            disabled={rationaleText === initialRationaleText || updateRationaleLoading}
            buttonText={updateRationaleLoading ? t('actions.saving') : t('actions.save')}
            boxClassName={'flex justify-content-end mt-2'}
            onClick={() => handleUpdateCandidateRationale(candidateRationale)}
          />
        </Box>
      </CustomPopup>

      <Loader show={quickAddContactProcessing} />
      <div className='main-container'>
        <Stack direction={'row'} gap={4} alignItems={'center'}>
          {/** FOR FUTURE REFERRENCE */}
          {/* <Box style={{ width: '70%' }}>
            <Autocomplete
              size='small'
              onInputChange={(_, newInputValue) => {
                setSearchValue(newInputValue);
                searchValueRef.current = newInputValue;
              }}
              onChange={(_e, val) => {
                searchValueRef.current = val;
                fetchCandidateData();
              }}
              options={candidateOptions}
              popupIcon={<ExpandMoreIcon />}
              getOptionLabel={option => `${option?.contact?.first_name || ''} ${option?.contact?.last_name || ''}`}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('project.search_value')}
                  variant='outlined'
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {!loading && params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  {option?.contact?.first_name || ''} {option?.contact?.last_name || ''}
                </li>
              )}
            />
          </Box> */}
        </Stack>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <ColorLegend handleCandidateColorLabelChange={handleCandidateColorLabelChange} />
          <ButtonDropDown
            disabled={selectedRowsCount === 0}
            options={stagesWithoutLogical ? stagesWithoutLogical.map(stage => stage.stage_label) : []}
            buttonText={'Change Stage'}
            onClick={data => {
              setActiveType('pipeline');
              subMenuStageActionsHandler(data, 'pipeline');
            }}
          />
          <ButtonDropDown options={['Quick add', 'Add from resume', 'Select existing contact', 'Add new contact']} buttonText={'Add Candidate'} onClick={handleAddCandidate} />
        </Stack>
      </div>

      <div className='contact-pipeline ml-0'>
        <div style={{ display: 'grid', gridTemplateColumns: !isDrawerOpen ? '140px 1fr' : '1fr' }}>
          {!isDrawerOpen && (
            <div className='mt-3'>
              {(stages?.length ? (filterStages?.length > 0 ? filterStages : stages) : []).map((stage, index) => (
                <Tooltip key={index} placement='right' arrow title={stage.description}>
                  <div
                    key={index}
                    className='stage-container'
                    style={{
                      backgroundColor: stage.color || '#1e79ab',
                      boxShadow:
                        activeStage && stage.stage_name === activeStage.stage_name
                          ? 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'
                          : null
                    }}
                    onClick={() => {
                      setActiveStage(stage);
                      setSelectedStage(stage);
                    }}
                  >
                    <Typography className='stage-text' variant='body1' textTransform='capitalize'>
                      {stage.stage_label}
                    </Typography>
                    <div className='flex-align-center'>
                      <Divider orientation='vertical' flexItem className='white-divider' />
                      <Typography className='stage-text' variant='body1'>
                        {stage.project_candidate_count || 0}
                      </Typography>
                    </div>
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
          <div className='mx-2 mt-3' style={{ paddingTop: '0.2rem' }}>
            {activeStage !== null && (
              <div
                className='stage-header'
                style={{
                  backgroundColor: activeStage.color || '#1e79ab'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='subtitle1' fontWeight='bold' style={{ marginRight: '.7rem' }}>
                    {activeStage.stage_name}
                  </Typography>
                  {activeStage.recent_candidate_count && (activeStage.largest_created_at || activeStage.largest_updated_at) ? (
                    <Typography variant='body2'>
                      {activeStage.recent_candidate_count} New added{' '}
                      {moment(activeStage?.largest_created_at || activeStage?.largest_updated_at)
                        .startOf('minute')
                        .fromNow()}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </div>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {activeStage.project_candidate_count} Candidates
                </Typography>
              </div>
            )}
            <ExportCSV ref={csvExportRef} iconHeight={26} iconWidth={50} columns={candidatePipelineColumnsExports} data={currentGridData} arrayKeyMapping={{}} fileName='Candidate pipeline.csv' />
            {/* <Stack direction={'row'} style={{ justifyContent: 'flex-end' }}>
              <GridHeaderMenu onExport={() => csvExportRef.current.exportCSV([])} gridRef={pipelineGridRef.current} />
            </Stack> */}
            <div id='myGrid' className='ag-theme-alpine candidate-pipeline-grid' style={{ overflow: 'hidden', height: 'calc(70vh - 100px)' }}>
              {/* {isGridReady && <ColumnFilter classNameValue={'columns-icon m-2 mt-2 ml-8'} columnApi={columnApi} defaultColumns={defaultColumns} customNameMappings={{ checkboxes: 'Checkboxes' }} />} */}
              <AgGridWrapper
                defaultColumnDefs={colDefs}
                enableBrowserTooltips={true}
                gridType={'candidatePipelineGrid'}
                // ref={gridApiRef}
                rowHeight={50}
                columnDefs={gridState}
                loadingOverlayComponent={CustomLoadingOverlayComponent}
                scrollbarWidth={12}
                rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
                showHeaderMenu={true}
                rowData={currentGridData}
                onGridReady={onGridReady}
                sortModel={savedSortModel}
                filterModel={savedFilterModel}
                paginationPageSize={PAGE_LIMIT}
                loading={gridLoading}
                defaultColDef={{
                  minWidth: 100,
                  resizable: true,
                  autoSizeStrategy: 'fitGridWidth'
                }}
                //  frameworkComponents={{
                //    CustomLoadingOverlayComponent
                //  }}
                getRowNodeId={data => data.id}
                blockLoadDebounceMillis={SCROLL_TIMEOUT}
                suppressHorizontalScroll={false}
                onSelectionChanged={event => {
                  setSelectedRowsCount(event.api.getSelectedNodes().length);
                }}
                domLayout='normal'
                disableStaticMarkup
                rowGroupPanelShow='always'
                saveGridSetting={true}
                rowModelType='clientSide'
                setFilteredGridData={setFilteredGridData}
              />
            </div>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={alert?.open === true}
              onClose={() => setAlert(alertOld => ({ ...alertOld, open: false, error: false }))}
              message={alert?.message}
              key={0}
              autoHideDuration={5000}
              ContentProps={{
                sx: {
                  bgcolor: alert.error ? 'red' : 'black',
                  color: 'white'
                }
              }}
            />
          </div>
        </div>
      </div>
      <QuickAddContact
        open={openQuickAddContact}
        setOpen={setOpenQuickAddContact}
        label={activeStage?.stage_name}
        projectId={projectData?.id}
        callback={async () => {
          refreshData();
        }}
        useFormController={{ register, handleSubmit, setValue, control, getValues }}
        newFields={newFields} // flush fuction to reset all fields
        clearQuickAddContactData={clearQuickAddContactData}
        isAddDisabled={disabled && selectedStage && selectedStage?.stage_name && selectedStage.stage_name != ''}
        onAddSubmit={onAddSubmit}
      />
      <AddContactDrawer
        openDrawer={openAddContactDrawer}
        setOpenDrawer={setOpenAddContactDrawer}
        defaultValues={{
          project_name: {
            id: projectData?.id || '',
            name: ` ${projectData?.job_number} - ${projectData?.job_title} (${projectData?.ign_companies?.name})` || '',
            record_type: projectData?.record_type || '',
            disabled: true
          },
          project_stage:
            activeStage?.stage_name !== 'All'
              ? {
                  id: 'Target',
                  name: 'Target',
                  disabled: projectData?.record_type === 'Project' ? false : true
                }
              : null
        }}
      />
      {offLimitWarningPopup && (
        <OffLimitWarning
          open={offLimitWarningPopup}
          onClose={handleCloseWarningPopup}
          onConfirm={handleWarningOfflimitConfirm}
          offlimits={contactOffLimit}
          buttonText='Continue'
          avoidHardLimit
          showTickWarning={showTickWarning}
        />
      )}

      {offLimitWarningPopupForUsers && (
        <OffLimitWarning
          open={offLimitWarningPopupForUsers}
          onClose={handleCloseWarningPopupForUsers}
          onConfirm={handleWarningOfflimitConfirmForUsers}
          offlimits={allContactsOffLimit}
          buttonText='Continue'
          avoidHardLimit
          showTickWarning={showTickWarning}
        />
      )}

      {offlimitForNewContact && (
        <OffLimitWarning
          open={offlimitForNewContact}
          onClose={handleCloseWarningPopupForNewContact}
          onConfirm={handleWarningOfflimitConfirmForNewContact}
          offlimits={newContactOffLimitData?.offLimits}
          buttonText='Continue'
          showTickWarning={showTickWarning}
          companyOffLimit
          contactDetails={newContactOffLimitData?.contactDetails}
        />
      )}

      <ChooseFromContact
        open={openChooseFromContact}
        setOpen={setOpenChooseFromContact}
        callback={refreshData}
        stages={stages}
        openCandidateDrawer={openCandidateDrawer}
        initStage={activeStage?.stage_name}
        projectId={projectData?.id}
      />
      <ResumeParser
        isPopupOpen={openCreateFromResume}
        handleClose={() => setOpenCreateFromResume(false)}
        createProjectCandidate={true}
        stage={activeStage?.stage_name}
        stages={stages}
        projectId={projectData?.id}
        callback={async () => {
          refreshData();
        }}
      />
      <RejectReasonModal open={openReasonModal} onSubmit={onSelectStageHandler} reasons={selectedReasons} setReasons={setSelectedReasons} onClose={onRejectReasonModalClose} />
      <ConfirmModal message={'Are you sure want to delete candidate?'} open={showConfirmDialog} setOpen={setShowConfirmDialog} onConfirm={handleConfirmDeleteCandidate} />
      <Box id='view-contact-drawer' zIndex={100000000}>
        <>
          {isDrawerOpen && (
            <ViewContactDrawer
              isCandidate={true}
              project_id={projectData.id}
              candidate_id={selectedCandidate?.id}
              navigateToAllContacts={false}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              id={selectedCandidate?.contact_id}
              selectedCandidate={selectedCandidate}
              setSelectedCandidate={setSelectedCandidate}
              changeStage={data => {
                setActiveType('contactDrawer');
                subMenuStageActionsHandler(data, 'contactDrawer');
              }}
              handleCandidateColorChange={handleCandidateColorChange}
              isNavigationButtons={true}
              currentGridData={filteredGridData.length > 0 ? filteredGridData : currentGridData}
              setFunction={setSelectedCandidate}
              fromCandidatePipeline
            />
          )}
        </>
      </Box>
      <AthenaEmailInviteModal contact={inviteAthenaContact} isOpen={isInviteAthenaModalOpen} onClose={() => setInviteAthenaModalOpen(false)} projectId={projectData?.id} />
    </div>
  );
};

TextRender.propTypes = {
  text: PropTypes.string
};

CheckboxRenderer.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  hover: PropTypes.string
};

NameRender.propTypes = {
  name: PropTypes.string.isRequired,
  setSelectedCandidate: PropTypes.func.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
  candidate: PropTypes.object.isRequired
};

ActionRenderer.propTypes = {
  item: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  handleStageChange: PropTypes.func.isRequired,
  handleDeleteCandidate: PropTypes.func.isRequired,
  handleViewAthena: PropTypes.func.isRequired,
  projectData: PropTypes.object,
  handleInviteToAthena: PropTypes.func
};

CandidatePipeline.propTypes = {
  refreshDataCbRef: PropTypes.object
};

RenderRejectReasons.propTypes = {
  value: PropTypes.array
};

export default CandidatePipeline;
