import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomPopup from '../../../components/common/CustomPopup';
import '../../ProductSetup/index.scss';
import TranslateForm from './TranslateForm';

const AddTranslateValue = props => {
  const { setIsPopupOpen, isPopupOpen, saveData, setValue, register, unregister, watch, isEdit = false } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const [loading, setLoading] = useState(false);

  return (
    <CustomPopup
      dropdown={true}
      title={t(`${isEdit ? 'utils.editTranslateValue' : 'utils.addTranslateValue'}`)}
      open={isPopupOpen}
      onClose={handleClose}
      showAction={true}
      disabled={loading}
      handleSubmit={async () => {
        setLoading(true);
        await saveData();
        setLoading(false);
      }}
      confirmText={t('utils.addTranslateValues')}
    >
      <TranslateForm watch={watch} unregister={unregister} register={register} setValue={setValue}></TranslateForm>
    </CustomPopup>
  );
};
AddTranslateValue.propTypes = {
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  saveData: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  isEdit: PropTypes.bool
};

export default AddTranslateValue;
