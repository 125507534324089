import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ignWorkbenchDataApi, ignWorkbenchQueryApi } from '../../../../services/ApiService';
import { ERROR, GET, ROUTES } from '../../../../services/constantService';
import { WorkBenchContext } from '../../Context';
import DropDownListComponent from './DropdownListComponent';
import ListHeaderComponent from './ListHeaderComponent';

function ListComponent() {
  const { setAddWorkBenchModalOpen, setNoMyList, refetch, types, workbenchId, setAddQueryModalOpen, queryId, archiveId } = useContext(WorkBenchContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isInitialMount = useRef(true);
  const previousRefetch = useRef(refetch);

  const [searchText, setSearchText] = useState('');
  const [showMyList, setShowMyList] = useState(true);
  const [showQueryList, setShowQueryList] = useState(true);
  const [showAllList, setShowAllList] = useState(true);
  const [showArchiveList, setShowArchiveList] = useState(true);

  const [myListData, setMyListData] = useState([]);
  const [allListData, setAllListData] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);

  const [myListPage, setMyListPage] = useState(1);
  const [allListPage, setAllListPage] = useState(1);
  const [queryListPage, setQueryListPage] = useState(1);
  const [archiveListPage, setArchiveListPage] = useState(1);
  const [hasMoreMyList, setHasMoreMyList] = useState(true);
  const [hasMoreAllList, setHasMoreAllList] = useState(true);
  const [hasMoreQueryList, setHasMoreQueryList] = useState(true);
  const [hasMoreArchiveList, setHasMoreArchiveList] = useState(true);

  const [isLoadingMyList, setIsLoadingMyList] = useState(false);
  const [isLoadingAllList, setIsLoadingAllList] = useState(false);
  const [isLoadingQueryList, setIsLoadingQueryList] = useState(false);
  const [isLoadingArchiveList, setIsLoadingArchiveList] = useState(false);

  const PAGE_SIZE = 10;

  const fetchMyListData = useCallback(
    async (page, isLoadMore = false, searchValue = '') => {
      if (!hasMoreMyList && isLoadMore) return;

      try {
        setIsLoadingMyList(true);
        const { data: res } = await ignWorkbenchDataApi(GET, '', '', `myList?page=${page}&limit=${PAGE_SIZE}&search=${encodeURIComponent(searchValue)}`);

        if (res?.data?.rows) {
          const newData = res.data.rows;
          setMyListData(prev => (isLoadMore ? [...prev, ...newData] : newData));

          const totalPages = res.data.pagination?.totalPages || 0;
          const currentPage = res.data.pagination?.currentPage || 0;
          setHasMoreMyList(currentPage < totalPages);

          if (page === 1 && newData.length === 0) {
            setNoMyList(true);
          }
        }
      } catch (err) {
        enqueueSnackbar(err.message || 'Error fetching my list', { variant: ERROR });
      } finally {
        setIsLoadingMyList(false);
      }
    },
    [hasMoreMyList, setNoMyList]
  );

  const fetchAllListData = useCallback(
    async (page, isLoadMore = false, searchValue = '') => {
      if (!hasMoreAllList && isLoadMore) return;

      try {
        setIsLoadingAllList(true);
        const { data: res } = await ignWorkbenchDataApi(GET, '', '', `allList?page=${page}&limit=${PAGE_SIZE}&search=${encodeURIComponent(searchValue)}`);

        if (res?.data?.rows) {
          const newData = res.data.rows;
          setAllListData(prev => (isLoadMore ? [...prev, ...newData] : newData));

          const totalPages = res.data.pagination?.totalPages || 0;
          const currentPage = res.data.pagination?.currentPage || 0;
          setHasMoreAllList(currentPage < totalPages);
        }
      } catch (err) {
        enqueueSnackbar(err.message || 'Error fetching all list', { variant: ERROR });
      } finally {
        setIsLoadingAllList(false);
      }
    },
    [hasMoreAllList]
  );

  const fetchQueryData = useCallback(
    async (page, isLoadMore = false, searchValue = '') => {
      if (!hasMoreQueryList && isLoadMore) return;

      try {
        setIsLoadingQueryList(true);
        const res = await ignWorkbenchQueryApi(GET, null, `?page=${page}&limit=${PAGE_SIZE}&search=${encodeURIComponent(searchValue)}`);

        if (res.success && res.data?.rows) {
          const newData = res.data.rows;
          setQueryData(prev => (isLoadMore ? [...prev, ...newData] : newData));

          const totalPages = res.data.pagination?.totalPages || 0;
          const currentPage = res.data.pagination?.currentPage || 0;
          setHasMoreQueryList(currentPage < totalPages);
        }
      } catch (err) {
        enqueueSnackbar(err.message || 'Error fetching queries', { variant: ERROR });
      } finally {
        setIsLoadingQueryList(false);
      }
    },
    [hasMoreQueryList]
  );

  const fetchArchiveData = useCallback(
    async (page, isLoadMore = false, searchValue = '') => {
      if (!hasMoreArchiveList && isLoadMore) return;
      try {
        setIsLoadingArchiveList(true);

        const [queryArchives, listArchives, allArchives] = await Promise.all([
          ignWorkbenchQueryApi(GET, null, `/archives?page=${page}&limit=${PAGE_SIZE}&search=${encodeURIComponent(searchValue)}`),
          ignWorkbenchDataApi(GET, '', '', `archived/myList?page=${page}&limit=${PAGE_SIZE}&search=${encodeURIComponent(searchValue)}`),
          ignWorkbenchDataApi(GET, '', '', `archived/allList?page=${page}&limit=${PAGE_SIZE}&search=${encodeURIComponent(searchValue)}`)
        ]);
        // Combine and process the data
        const queryRows = queryArchives.success && queryArchives.data?.rows ? queryArchives.data.rows : [];
        const listRows = listArchives?.data?.data?.rows || [];
        const allListRows = allArchives?.data?.data?.rows || [];

        // Combine all types of archives, marking their type for proper handling
        const combinedData = [
          ...queryRows.map(item => ({ ...item, archiveType: 'query' })),
          ...listRows.map(item => ({ ...item, archiveType: 'list' })),
          ...allListRows.map(item => ({ ...item, archiveType: 'list' }))
        ];

        // Sort combined data by some criteria (e.g., date if available)
        combinedData.sort((a, b) => new Date(b.created_at || b.createdAt) - new Date(a.created_at || a.createdAt));

        if (isLoadMore) {
          setArchiveData(prev => [...prev, ...combinedData]);
        } else {
          setArchiveData(combinedData);
        }

        // Calculate pagination based on combined results
        const totalQueryPages = queryArchives.data?.pagination?.totalPages || 0;
        const totalListPages = listArchives?.data?.data?.pagination?.totalPages || 0;
        const totalMyListPages = allArchives?.data?.data?.pagination?.totalPages || 0;
        const maxTotalPages = Math.max(totalQueryPages, totalListPages, totalMyListPages);
        const currentPage = page;

        setHasMoreArchiveList(currentPage < maxTotalPages);
      } catch (err) {
        enqueueSnackbar(err.message || 'Error fetching archived lists', { variant: ERROR });
      } finally {
        setIsLoadingArchiveList(false);
      }
    },
    [hasMoreArchiveList]
  );

  const performSearch = useCallback(
    async searchValue => {
      setMyListData([]);
      setAllListData([]);
      setQueryData([]);
      setArchiveData([]);
      await Promise.all([fetchMyListData(1, false, searchValue), fetchAllListData(1, false, searchValue), fetchQueryData(1, false, searchValue), await fetchArchiveData(1, false, searchValue)]);
    },
    [fetchMyListData, fetchAllListData, fetchQueryData, fetchArchiveData]
  );

  const debouncedSearch = useMemo(() => debounce(value => performSearch(value), 500), [performSearch]);

  const handleSearchChange = useCallback(
    event => {
      const value = event.target.value;
      setSearchText(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const onClickList = useCallback(
    data => {
      navigate(ROUTES.workbench.replace(':id', data.id));
    },
    [navigate]
  );

  const onClickQuery = useCallback(
    data => {
      navigate(ROUTES.workbenchQuery.replace(':queryId', `${data.id}`));
    },
    [navigate]
  );

  const onClickArchive = useCallback(
    data => {
      const listType = data?.query_type ? 'query' : 'myList';
      navigate(ROUTES.workbenchArchiveQuery.replace(':listType', listType).replace(':archiveId', `${data.id}`));
    },
    [navigate]
  );

  const handleLoadMoreMyList = useCallback(() => {
    if (hasMoreMyList && !isLoadingMyList) {
      const nextPage = myListPage + 1;
      setMyListPage(nextPage);
      fetchMyListData(nextPage, true, searchText);
    }
  }, [hasMoreMyList, isLoadingMyList, myListPage, fetchMyListData, searchText]);

  const handleLoadMoreAllList = useCallback(() => {
    if (hasMoreAllList && !isLoadingAllList) {
      const nextPage = allListPage + 1;
      setAllListPage(nextPage);
      fetchAllListData(nextPage, true, searchText);
    }
  }, [hasMoreAllList, isLoadingAllList, allListPage, fetchAllListData, searchText]);

  const handleLoadMoreQueryList = useCallback(() => {
    if (hasMoreQueryList && !isLoadingQueryList) {
      const nextPage = queryListPage + 1;
      setQueryListPage(nextPage);
      fetchQueryData(nextPage, true, searchText);
    }
  }, [hasMoreQueryList, isLoadingQueryList, queryListPage, fetchQueryData, searchText]);

  const handleLoadMoreArchiveList = useCallback(() => {
    if (hasMoreArchiveList && !isLoadingArchiveList) {
      const nextPage = archiveListPage + 1;
      setArchiveListPage(nextPage);
      fetchArchiveData(nextPage, true, searchText);
    }
  }, [hasMoreArchiveList, isLoadingArchiveList, archiveListPage, fetchArchiveData, searchText]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      performSearch(searchText);
    } else if (refetch !== previousRefetch.current) {
      performSearch(searchText);
      previousRefetch.current = refetch;
    }
  }, [refetch, performSearch, searchText]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className='w-100 border background-white custom-vh-80 rounded'>
      <Box display='flex' alignItems='center' width='100%' className='workbench-search'>
        <TextField
          sx={{ paddingX: '0.5rem' }}
          variant='standard'
          size='small'
          placeholder={t('common.search')}
          fullWidth
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true
          }}
        />
      </Box>

      <ListHeaderComponent
        key='my-favorite-list'
        name={t('utils.myList')}
        onClick={() => setShowMyList(!showMyList)}
        isShowing={showMyList}
        showAddList={true}
        onClickAddList={() => setAddWorkBenchModalOpen(true)}
        buttonName={`+ ${t('actions.add')}`}
      />
      {showMyList && (
        <DropDownListComponent
          data={myListData}
          onClick={onClickList}
          types={types}
          selectedId={workbenchId}
          onLoadMore={handleLoadMoreMyList}
          isLoading={isLoadingMyList}
          hasMore={hasMoreMyList}
          showDeleteBtn={true}
          refetch={() => {
            setMyListPage(1);
            fetchMyListData(1, false, searchText);
            setArchiveListPage(1);
            fetchArchiveData(1, false, searchText);
          }}
        />
      )}

      <ListHeaderComponent key='all-favorite-list' name={t('utils.allList')} onClick={() => setShowAllList(!showAllList)} isShowing={showAllList} showAddList={false} />
      {showAllList && (
        <DropDownListComponent
          data={allListData}
          onClick={onClickList}
          types={types}
          selectedId={workbenchId}
          onLoadMore={handleLoadMoreAllList}
          isLoading={isLoadingAllList}
          hasMore={hasMoreAllList}
          showDeleteBtn={true}
          refetch={() => {
            setAllListPage(1);
            fetchAllListData(1, false, searchText);
            setArchiveListPage(1);
            fetchArchiveData(1, false, searchText);
          }}
        />
      )}

      <ListHeaderComponent
        key='my-query-list'
        name={t('utils.queryList')}
        onClick={() => setShowQueryList(!showQueryList)}
        isShowing={showQueryList}
        showAddList={true}
        buttonName={`+ ${t('actions.add')}`}
        onClickAddList={() => setAddQueryModalOpen(true)}
      />
      {showQueryList && (
        <DropDownListComponent
          data={queryData}
          onClick={onClickQuery}
          types={types}
          selectedId={queryId}
          onLoadMore={handleLoadMoreQueryList}
          isLoading={isLoadingQueryList}
          hasMore={hasMoreQueryList}
          refetch={() => {
            setQueryListPage(1);
            fetchQueryData(1, false, searchText);
            setArchiveListPage(1);
            fetchArchiveData(1, false, searchText);
          }}
          showDeleteBtn={true}
        />
      )}

      <ListHeaderComponent key='archived-list' name={t('utils.archiveList')} onClick={() => setShowArchiveList(!showArchiveList)} isShowing={showArchiveList} showAddList={false} />
      {showArchiveList && (
        <DropDownListComponent
          data={archiveData}
          onClick={onClickArchive}
          types={types}
          selectedId={archiveId}
          onLoadMore={handleLoadMoreArchiveList}
          isLoading={isLoadingArchiveList}
          hasMore={hasMoreArchiveList}
          showDeleteBtn={false}
          showRestoreIcon={true}
          refetch={() => {
            setMyListPage(1);
            fetchMyListData(1, false, searchText);
            setAllListPage(1);
            fetchAllListData(1, false, searchText);
            setQueryListPage(1);
            fetchQueryData(1, false, searchText);
            setArchiveListPage(1);
            fetchArchiveData(1, false, searchText);
          }}
        />
      )}
    </div>
  );
}

export default ListComponent;
