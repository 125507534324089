//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/CustomButton';
import CustomCard from '../../../common/CustomCard';
import CustomCommonPersonality from '../../../common/CustomCommonPersonality';
import '../../index.scss';

const noteType1 = 'screening_notes';
const noteType2 = 'recommendations';
const approved1 = `is_${noteType1}_approved_by_partner`;
const approved2 = `is_${noteType2}_approved_by_partner`;

const ScreeningNotes = props => {
  const {
    screeningCardHeight,
    screeningCardWidth,
    register = () => {},
    setValue = () => {},
    handleSave = () => {},
    currentValues,
    isContactView = false,
    contact_id,
    getContact = () => {},
    getContactDetails = () => {},
    setLoading = () => {},
    popupClose
  } = props;
  const [isCommonPopup, setIsCommonPopup] = useState(false);
  // const [isAnotherPopupOpen, setIsAnotherPopupOpen] = useState(false);
  const [item, setItem] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  // const [addAnotherData, setAddAnotherData] = useState()
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [indexNumbers, setIndexNumbers] = useState();
  const indexValueRef = useRef();
  indexValueRef.current = indexNumbers;
  const itemRef = useRef();
  itemRef.current = item;
  const [isInlineEditing, setIsInLineEditing] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const isInlineEditingRef = useRef();
  isInlineEditingRef.current = isInlineEditing;
  const { t } = useTranslation();

  const notesLabel = {
    label1: t('actions.add'),
    label2: t('utils.recommendation')
  };
  const styleData = {
    height: screeningCardHeight || 250,
    width: screeningCardWidth || 400,
    minWidth: screeningCardWidth || 400
  };
  // const setIndexValueNumber = (indexNumber) => {
  //   setIndexValue(indexNumber)
  // }
  const handleCommonPopup = () => {
    setIsCommonPopup(true);
  };
  useEffect(() => {
    const convertData = () => {
      let newData = [];
      if (currentValues && currentValues.length) {
        currentValues.forEach(itemEntry => {
          if (itemEntry?.notes_type === noteType1 || itemEntry?.notes_type === noteType2) {
            let projectIndex = newData.findIndex(project => project?.projects?.project_id === itemEntry?.project?.id);

            if (projectIndex === -1) {
              newData.push({
                projects: { project_name: itemEntry?.project?.job_title, project_id: itemEntry?.project?.id },
                [noteType1]: [],
                [noteType2]: [],
                [approved1]: false,
                [approved2]: false
              });
              projectIndex = newData.length - 1;
            }

            if (itemEntry.notes_type === noteType1) {
              newData[projectIndex][noteType1].push({ ...itemEntry });
              newData[projectIndex][approved1] = itemEntry.is_partner_approved;
            } else if (itemEntry.notes_type === noteType2) {
              newData[projectIndex][noteType2].push({ ...itemEntry });
              newData[projectIndex][approved2] = itemEntry.is_partner_approved;
            }
          }
        });
        return newData;
      } else {
        return newData;
      }
    };
    setItem(convertData());
    setIsCommonPopup(false);
  }, [currentValues]);
  const handlePopupCommonSubmit = async () => {
    if (isEditing || isInlineEditingRef.current) {
      if (isContactView) {
        const result = await handleSave('contact_notes_all', false);
        if (result) {
          setIsEditing(false);
          setIsInLineEditing(false);
        }
      } else {
        const result = await handleSave();
        if (result) {
          setIsEditing(false);
          setIsInLineEditing(false);
        }
      }
    } else {
      if (isContactView) {
        await handleSave('contact_notes_all', false);
      } else {
        await handleSave();
      }
    }
    // const indexValues = indexNumbers !== null && indexNumbers !== undefined ? indexNumbers : indexValueRef.current
    // console.log(indexValues)
    // if (itemRef.current.length > 0) {
    //   const updatedItem = isEditing ? itemRef.current : [...itemRef.current];
    //   if (isEditing) {
    //     data.forEach(({ project, type, text }) => {
    //       const projectObj = item.find(itemProject => itemProject.projects.name === project);

    //       if (projectObj) {
    //         if (type === "Screening") {
    //           projectObj.projects.screening = [text];
    //         } else if (type === "Recommendation") {
    //           const existingIndex = projectObj.projects.recommendation.indexOf(text);

    //           if (existingIndex !== -1) {
    //             projectObj.projects.recommendation[existingIndex] = text;
    //           } else {
    //             projectObj.projects.recommendation = [text];
    //           }
    //         }
    //       }
    //     });
    //   } else {
    //     data.forEach((d) => {
    //       const { project, type, text } = d;
    //       const projectIndex = updatedItem.findIndex(
    //         (obj) => obj.projects.name === project
    //       );

    //       if (projectIndex !== -1) {
    //         if (type === "Screening") {
    //           updatedItem[projectIndex].projects.screening.push(text);
    //         } else if (type === "Recommendation") {
    //           updatedItem[projectIndex].projects.recommendation.push(text);
    //         }
    //       } else {
    //         const newProjectObj = {
    //           projects: {
    //             name: project,
    //             screening: type === "Screening" ? [text] : [],
    //             recommendation: type === "Recommendation" ? [text] : [],
    //           },
    //         };
    //         updatedItem.push(newProjectObj);
    //       }
    //     });
    //   }
    //   setItem(updatedItem);
    // } else {
    //   const transformedData = data.reduce((result, { project, type, text }) => {
    //     result['projects'] = result["projects"] || {
    //       name: project,
    //       screening: [],
    //       recommendation: [],
    //     };

    //     if (type === "Screening") {
    //       result["projects"].screening.push(text);
    //     } else if (type === "Recommendation") {
    //       result["projects"].recommendation.push(text);
    //     }

    //     return result;
    //   }, {});
    //   const finalData = transformedData;
    //   setItem((prevTexts) => [...prevTexts, finalData]);
    // }
  };
  // const handleAddAnother = (index, type, project_id) => {
  //   // setIndexNumbers(index)
  //   const capitalLetter = capitalizeFirstLetter(type)
  //   setAddAnotherData({ type: capitalLetter, project: project_id })
  //   setIsAnotherPopupOpen(true)
  // }
  const editItems = indexValue => {
    setIndexNumbers(indexValue);
    setIsEditing(true);
  };
  const viewItems = indexValue => {
    setIsMorePopupOpen(true);
    setIndexNumbers(indexValue);
    // setIsEditing(true)
  };
  const inlineEditing = value => {
    setIsInLineEditing(true);
    setSelectedType(value);
  };
  const displayData = data => {
    return (
      data &&
      data.map((elem, parentIndex) => (
        <Box className='d-flex custom-card' key={parentIndex} gap={2}>
          <CustomCard
            data={elem}
            isTypes={true}
            // addAnother={elem.projects.screening.length > 0 && elem.projects.recommendation.length > 0 ? null : (elem.projects.screening.length > 0 ? "recommendation" : "screening")}
            // unSelectedTypeFunction={handleAddAnother}
            onSubmit={handlePopupCommonSubmit}
            // itemLength={elem[noteType1].length}
            indexValue={parentIndex}
            editItems={editItems}
            isEditing={isEditing}
            noteType1={noteType1}
            noteType2={noteType2}
            // height={screeningCardHeight || 250}
            // width={screeningCardWidth || 400}
            fontSize={12}
            viewItems={viewItems}
            approved1={approved1}
            approved2={approved2}
            styleData={styleData}
            notesLabel={notesLabel}
            contact_id={contact_id}
            getContact={getContact}
            getContactDetails={getContactDetails}
            setLoading={setLoading}
          />
        </Box>
      ))
    );
  };
  return (
    <Box className='personality_sub flex flex-column'>
      <Box className='d-flex align-items-center gap-2'>
        <Typography className='compensation-info' variant='body1'>
          {notesLabel.label1} {t('utils.and')} {notesLabel.label2}
        </Typography>
        {item.length > 0 && (
          <Box mt={0} ml={2}>
            <CustomButton type={'secondary'} variant='outlined' size={'small'} buttonText={t('actions.add')} customWidth={96} onClick={handleCommonPopup} iconLeft={<AddIcon sx={{ width: 14 }} />} />
          </Box>
        )}
      </Box>
      {item.length === 0 ? (
        <Box className='personality-head'>
          <Box className='add-another-buttons'>
            <CustomButton type={'secondary'} variant='outlined' size={'small'} buttonText={t('actions.add')} customWidth={96} onClick={handleCommonPopup} iconLeft={<AddIcon sx={{ width: 14 }} />} />
          </Box>
        </Box>
      ) : (
        <Box className='personality-head d-flex'>
          <Box className='d-flex' gap={2}>
            {itemRef.current.length > 0 && displayData(itemRef.current)}

            {/* {isAnotherPopupOpen && (
                <CustomCommonPersonality
                  setIsPopupOpen={setIsAnotherPopupOpen}
                  isPopupOpen={isAnotherPopupOpen}
                  onSubmit={handlePopupCommonSubmit}
                  types="ScreeningAndRecommendation"
                  addAnotherData={addAnotherData}
                  // isAddAnother={true}
                />
              )} */}
          </Box>
        </Box>
      )}

      {isCommonPopup && (
        <CustomCommonPersonality
          setIsPopupOpen={setIsCommonPopup}
          isPopupOpen={isCommonPopup}
          onSubmit={handlePopupCommonSubmit}
          // types="ScreeningAndRecommendation"
          itemValue={itemRef.current}
          noteType1={noteType1}
          noteType2={noteType2}
          approved1={approved1}
          approved2={approved2}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
          popupClose={popupClose}
        />
      )}
      {isEditing && (
        <CustomCommonPersonality
          // types={types}
          setIsPopupOpen={setIsEditing}
          isPopupOpen={isEditing}
          onSubmit={handlePopupCommonSubmit}
          isEditing={isEditing}
          itemValue={itemRef.current[indexValueRef.current]}
          noteType1={noteType1}
          noteType2={noteType2}
          // isMorePopupOpen={isMorePopupOpen}
          // setIsMorePopupOpen={setIsMorePopupOpen}
          // editItems={editItems}
          // indexValue={indexValueRef.current}
          approved1={approved1}
          approved2={approved2}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
          popupClose={popupClose}
        />
      )}
      {isMorePopupOpen && (
        <CustomCommonPersonality
          // types={types}
          setIsPopupOpen={setIsMorePopupOpen}
          isPopupOpen={isMorePopupOpen}
          onSubmit={handlePopupCommonSubmit}
          isEditing={isEditing}
          itemValue={itemRef.current[indexValueRef.current]}
          noteType1={noteType1}
          noteType2={noteType2}
          isMorePopupOpen={isMorePopupOpen}
          setIsMorePopupOpen={setIsMorePopupOpen}
          // editItems={editItems}
          // indexValue={indexValueRef.current}
          approved1={approved1}
          approved2={approved2}
          inlineEditing={inlineEditing}
          isInlineEditing={isInlineEditingRef.current}
          setIsInLineEditing={setIsInLineEditing}
          selectedType={selectedType}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
        />
      )}
    </Box>
  );
};

ScreeningNotes.propTypes = {
  screeningCardHeight: PropTypes.number,
  screeningCardWidth: PropTypes.number,
  register: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  currentValues: PropTypes.object,
  isContactView: PropTypes.bool,
  getContact: PropTypes.func,
  contact_id: PropTypes.string,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func,
  popupClose: PropTypes.bool
};

export default ScreeningNotes;
