import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../../components/common/CustomButton';
import MultipleCompanyStatusSelection from '../../../../../../components/common/FunctionalComponents/CompanyStatusSelection/MultipleCompanyStatusSelection';
import IndustrySelection from '../../../../../../components/common/FunctionalComponents/IndustrySelection';
import CustomTagSelection from '../../../../../../components/common/FunctionalComponents/TagSelection/CustomTagSelection';
import MultipleGeoLocationInput from '../../../../../../components/common/GeoLocation/MultipleGeoLocation';
import CustomInputField from '../../../../../../components/common/StyledComponents/CustomInputField';
import { ignWorkbenchQueryApi } from '../../../../../../services/ApiService';
import { PUT } from '../../../../../../services/constantService';

export function CompanyFilterModal({ data, setFilterOptions, setQueryResponse }) {
  const { t } = useTranslation();

  const [projectName, setProjectName] = useState(data?.name || null);
  const [companyStatus, setCompanyStatus] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState(data?.industry || []);
  const [employeeRange, setEmployeeRange] = useState({ start: data?.employeeRangeStart || null, end: data?.employeeRangeEnd || null });
  const [revenueRange, setRevenueRange] = useState({ start: data?.revenueRangeStart || null, end: data?.revenueRangeEnd || null });
  const [tags, setTags] = useState([]);

  const handleIndustryChange = value => {
    setIndustries(value);
  };

  const handleLocationChange = (e, val) => {
    setLocations(val);
  };

  const handleTagChange = val => {
    setTags(val);
  };

  const handleSaveAndSearch = async () => {
    const filterOptionPayload = {
      name: projectName,
      industry: industries?.map(item => item?.id),
      location: locations?.map(item => item?.description),
      stage: companyStatus?.map(item => item?.field_value),
      employeeRange,
      revenueRange,
      tags: tags?.map(item => item?.name)
    };
    setFilterOptions(filterOptionPayload);
    const updateResponse = await ignWorkbenchQueryApi(PUT, filterOptionPayload, `${data.id}`);
    setQueryResponse(updateResponse?.data);
  };

  return (
    <Accordion className='query-accordion-container'>
      <AccordionSummary className='query-accordion-summary' expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body2'>{t('utils.startQuery')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={3}>
            <CustomInputField label={'Name'} color={null} defaultValue={data?.name} onChange={event => setProjectName(event.target.value)} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <IndustrySelection
              multiple={true}
              onChange={value => {
                handleIndustryChange(value);
              }}
              defaultValue={industries}
              disableCloseOnSelect
              isCheckBox={true}
              label={'Industry Selection'}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleGeoLocationInput val={locations} defaultValues={data?.location} onChange={handleLocationChange} label={'Location'} className='w-100' size='small' />
          </Grid>
          <Grid item xs={3}>
            <MultipleCompanyStatusSelection
              className='w-100'
              label={'Company Stage'}
              multiple={true}
              value={companyStatus}
              defaultValues={data?.stage}
              onChange={data => {
                setCompanyStatus(data);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInputField
              type='number'
              label={'Employee Range From'}
              defaultValue={employeeRange.start}
              onChange={e => {
                setEmployeeRange(prev => ({
                  ...prev,
                  start: parseInt(e.target.value) || null
                }));
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInputField
              type='number'
              label={'Employee Range To'}
              defaultValue={employeeRange.end}
              onChange={e => {
                setEmployeeRange(prev => ({
                  ...prev,
                  end: parseInt(e.target.value) || null
                }));
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInputField
              type='number'
              label={'Revenue Range From'}
              defaultValue={revenueRange.start}
              onChange={e => {
                setRevenueRange(prev => ({
                  ...prev,
                  start: parseInt(e.target.value) || null
                }));
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInputField
              type='number'
              label={'Revenue Range To'}
              defaultValue={revenueRange.end}
              onChange={e => {
                setRevenueRange(prev => ({
                  ...prev,
                  end: parseInt(e.target.value) || null
                }));
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTagSelection label={'Tags'} className='w-100' size='small' optionType={'company'} onChange={handleTagChange} defaultValues={data?.tags} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2' justifyContent='flex-end'>
          <Grid item>
            <CustomButton variant='outlined' buttonText={t('actions.apply')} onClick={handleSaveAndSearch} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

CompanyFilterModal.propTypes = {
  data: PropTypes.object,
  setFilterOptions: PropTypes.func,
  setQueryResponse: PropTypes.func
};
