import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './index.scss';

import { CheckBoxRenderer, ResourcesRenderer, RoleNameRenderer, columnDefs } from './utils';
// import Loader from "../../../components/common/Loader";
import { ArrowBack } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import Loader from '../../../components/common/Loader';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import { rolesApi } from '../../../services/ApiService';
import { DELETE, GET, ROLE_VALIDATION } from '../../../services/constantService';
import AgGridWrapper from '../../../utils/AgGridWrapper';
import useApi from '../../../utils/Hooks/useApiHook';
import useDebounce from '../../../utils/Hooks/useDebounce';
import ManageRole from './ManageRole';
import AddRolePopup from './components/AddRolePopup';

const RolesSecurity = () => {
  const [rowData, setRowData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [toDelete, setToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { t } = useTranslation();

  const { data: rolesData, refetch } = useApi({
    queryFn: () => rolesApi(GET, `getAll?search=${debouncedSearchValue}`)
  });

  const deleteRoles = async () => {
    setLoading(true);
    for (const toDel of toDelete) {
      await rolesApi(DELETE, `deleteById/${toDel}`);
    }
    setToDelete([]);
    await refetch();
    enqueueSnackbar(ROLE_VALIDATION.DELETE, {
      variant: 'success'
    });
    setLoading(false);
  };

  useEffect(() => {
    if (rolesData && rolesData?.data) {
      setRowData(rolesData?.data);
    }
  }, [rolesData]);

  useEffect(() => {
    refetch();
  }, [debouncedSearchValue]);

  return (
    <React.Fragment>
      <div
        className='position-relative'
        style={{
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      >
        <Loader show={loading} />
        <div
          style={{
            height: '90vh',
            backgroundColor: 'white',
            marginRight: '20px',
            borderRadius: '10px'
          }}
        >
          {/* <Loader show={loading || isLoading || isRefetching} /> */}

          <Box height='85%' paddingX='20px'>
            <div className='py-2 text-right d-flex justify-content-between'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <div
                  className='roles-back-button'
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <ArrowBack className='icon-size' /> Back
                </div>
                <CustomInputField placeholder={t('common.search')} size='small' value={searchValue} onChange={e => setSearchValue(e.target.value)} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <CustomButton type={'tertiary-error'} disabled={toDelete.length === 0} onClick={() => deleteRoles()} buttonText={'Delete Roles'} />
                <CustomButton startIcon={<AddIcon className='icon-size' />} onClick={() => setIsPopupOpen(!isPopupOpen)} buttonText={'Add Role'} />
              </Box>
            </div>
            <div className='ag-theme-alpine text-left' id='myGrid'>
              <AgGridWrapper
                disableStaticMarkup
                rowData={rowData}
                columnDefs={columnDefs}
                components={{
                  roleNameRenderer: props => <RoleNameRenderer {...props} setIsDrawerOpen={setIsDrawerOpen} setCurrentRole={setCurrentRole} />,
                  checkboxRenderer: props => <CheckBoxRenderer {...props} setToDelete={setToDelete} />,
                  resourcesRenderer: props => <ResourcesRenderer {...props} setIsDrawerOpen={setIsDrawerOpen} setCurrentRole={setCurrentRole} />
                }}
                rowGroupPanelShow='always'
                rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
                paginationPageSize={50}
                onSelectionChanged={event => {
                  setToDelete([...event.api.getSelectedRows().map(ele => ele?.id)]);
                }}
              />
            </div>
            <AddRolePopup isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} refetch={refetch} />
          </Box>
        </div>
      </div>
      <ManageRole isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setCurrentRole={setCurrentRole} currentRole={currentRole} />
    </React.Fragment>
  );
};

export default RolesSecurity;
