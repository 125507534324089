//-----------------------------------------------------------// External Imports // ------------------------------

import httpService from './httpService';
const { AwsClient } = require('aws4fetch');
const url = window.location.href;
//'https://sgcorp.sandbox.ignyte.app/portal/api/v1/all';
const requestType = url.split('//')[0];
const requestUrl = url.split('//')[1];
//'sgcorp.sandbox.ignyte.app/portal/api/v1/all'

const baseUrl = requestUrl.split('/')[0];
//'sgcorp.sandbox.ignyte.app'

const podName = baseUrl.split('.')[0];
//'sgcorp'

const restBaseUrl = baseUrl.substring(baseUrl.indexOf('.') + 1);
//'sandbox.ignyte.app'

const apiEndpoint = requestUrl.substring(requestUrl.indexOf('/') + 1);
// 'portal/api/v1/all'

const baseApiEndpoint = apiEndpoint.split('/')[0];
//'portal'

const restApiEndpoint = apiEndpoint.substring(baseApiEndpoint.length + 1);
//'api/v1/all

const newUrl = `${requestType}//api.${restBaseUrl}/${baseApiEndpoint}/${podName}${restApiEndpoint}`;
//'api.sandbox.ignyte.app/portal/sgcorp/api/v1/all'

export const dynamicUrl = baseUrl.includes('localhost') && process.env.REACT_APP_IS_LOCAL ? 'http://localhost:3000/portal' : newUrl;

// const urlForEnv = 'http://localhost:3000/portal/api/v1/get-config';

// const urlForEnv = `https://${baseUrl}/tenants/${podName}/env.json`;
const urlForEnv = `https://${baseUrl}/tenants/env.json`;

const fetchEnv = async () => {
  let payload = {};
  let envObj = {};

  if (process.env.REACT_APP_IS_LOCAL == 'true') {
    payload = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      service: 'execute-api',
      region: 'us-east-2'
    };

    envObj = {
      gridKey: process.env.REACT_APP_AG_GRID_KEY
    };
  } else {
    const { data: res } = await httpService.get(urlForEnv);
    payload = {
      accessKeyId: res?.accessKey,
      secretAccessKey: res?.secretKey,
      service: 'execute-api',
      region: 'us-east-2'
    };
    envObj = {
      gridKey: res?.gridKey
    };
  }

  localStorage.setItem('env-aws', JSON.stringify(payload));
  localStorage.setItem('envObj', JSON.stringify(envObj));
  return payload;
};

fetchEnv();
const fetchConfig = () => {
  return localStorage.getItem('env-aws') ? JSON.parse(localStorage.getItem('env-aws')) : fetchEnv();
};

// const client = new AwsClient({ ...fetchConfig() });

let client;

try {
  client = new AwsClient({ ...fetchConfig(), retries: 0 });
} catch (error) {
  console.error('Error in creating client', error);
}

export default client;
