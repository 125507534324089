import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Popover, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/CustomButton';
import './../../index.scss';

export default function PersonalityFilter({ open, onClose, anchorEl, filterOptions, onFilterUpdate, setIsPublished, isPublished, setIsFilterApplied }) {
  const [localFilterOptions, setLocalFilterOptions] = useState(filterOptions);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPublishedFilter, setIsPublishedFilter] = useState(isPublished);

  const { t } = useTranslation();

  const handleSearchChange = event => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const applyFilterHandler = () => {
    // Check if any filters are still active
    const hasActiveFilters = Object.values(localFilterOptions).some(option => option.value);
    // If no active filters and not published, reset the filter state
    if (!hasActiveFilters && !isPublishedFilter) {
      setIsFilterApplied(false);
      onClose();
      return;
    }
    setIsPublished(isPublishedFilter);
    onFilterUpdate(localFilterOptions);
    setIsFilterApplied(true);
    onClose();
  };

  const handleCheckboxChange = key => {
    const updatedOptions = {
      ...localFilterOptions,
      [key]: {
        ...localFilterOptions[key],
        value: !localFilterOptions[key].value
      }
    };
    setLocalFilterOptions(updatedOptions);
  };

  useEffect(() => {
    setLocalFilterOptions(filterOptions);
  }, [filterOptions]);

  const filteredOptions = Object.entries(localFilterOptions).filter(([_key, option]) => option.label.toLowerCase().includes(searchQuery));

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        borderRadius: '12px'
      }}
    >
      <Box className='personality-filter-popover-box' sx={{ maxWidth: '310px', maxHeight: '600px' }}>
        <TextField
          fullWidth
          placeholder={t('common.search')}
          value={searchQuery}
          onChange={handleSearchChange}
          rows={1}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, fontSize: '18px' }} />,
            endAdornment: searchQuery && (
              <IconButton onClick={() => setSearchQuery('')} size='small'>
                <CloseIcon sx={{ fontSize: '18px' }} />
              </IconButton>
            )
          }}
          inputProps={{
            className: 'personality-filter-text-field-input'
          }}
          className='personality-filter-text-field'
        />
        <Box className='personality-filter-filter-section mb-2' sx={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '10px' }}>
          <Box>
            {filteredOptions.length > 0 && <Box className='personality-filter-section-title fs-12'>{t('utils.notesType')}</Box>}
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {filteredOptions.map(([key, option]) => (
                <label key={key} className='personality-filter-checkbox-label fs-14'>
                  <input type='checkbox' checked={option.value} onChange={() => handleCheckboxChange(key)} />
                  {option.label}
                </label>
              ))}
            </Box>
          </Box>

          <Box>
            <Box className='personality-filter-section-title fs-12'>{t('utils.status')}</Box>
            <label className='personality-filter-checkbox-label fs-14'>
              <input type='checkbox' checked={isPublishedFilter} onChange={() => setIsPublishedFilter(!isPublishedFilter)} /> {t('project.published')}
            </label>
          </Box>
        </Box>
        <Box className='personality-filter-action-buttons'>
          <CustomButton type={'secondary'} variant='outlined' size={'small'} buttonText={t('actions.cancel')} customWidth={96} onClick={onClose} />
          <CustomButton type={'primary'} variant='outlined' size={'small'} buttonText={t('actions.apply')} customWidth={96} onClick={applyFilterHandler} />
        </Box>
      </Box>
    </Popover>
  );
}

PersonalityFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  filterOptions: PropTypes.object,
  onFilterUpdate: PropTypes.func,
  setIsPublished: PropTypes.func,
  isPublished: PropTypes.bool,
  setIsFilterApplied: PropTypes.func
};
