import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
function SetContent(props) {
  const note = props.note;
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const [wordLimitExceed, setWordLimitExceed] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    const noteLength = note?.length;
    if (noteLength > 60) {
      setWordLimitExceed(true);
    } else {
      setWordLimitExceed(false);
    }
  }, [note]);

  return (
    <Box sx={{ mb: 2, mt: 1 }}>
      {active ? (
        <>
          <Typography variant='body2' className='fs-13' sx={{ mb: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: note }}></div>
          </Typography>
          {wordLimitExceed && (
            <button onClick={() => setActive(false)} className='border-0 mb-1 mt-1 p-0 fs-12' style={{ background: 'transparent', color: themeColor ? themeColor.secondary_color : '' }}>
              View less
            </button>
          )}
        </>
      ) : (
        <>
          <Typography variant='body2' className='fs-13' sx={{ mb: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: note }} className='multiline-elipsis'></div>
          </Typography>
          {wordLimitExceed && (
            <button onClick={() => setActive(true)} className='border-0 mb-1 mt-1 p-0 fs-12' style={{ background: 'transparent', color: themeColor ? themeColor.secondary_color : '' }}>
              View more
            </button>
          )}
        </>
      )}
    </Box>
  );
}

SetContent.propTypes = {
  note: PropTypes.string
};

export default SetContent;
