import HomeIcon from '@mui/icons-material/Home';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';
import DynamicTabLayout from '../../components/DynamicTabs/TabLayout';
import List from './List';

const breadcrumbsLinks = [
  {
    label: (
      <>
        <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
      </>
    ),
    link: '/contacts'
  }
];

const Container = props => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <React.Fragment>
      <Helmet>
        <title>Add Contact - KGP Galaxy</title>
      </Helmet>
      <div className='pt-8'>
        <DynamicTabLayout
          navItems={[
            {
              label: t('navMenu.contacts'),
              content: '',
              subTabs: []
            }
          ]}
          baseRoute='/contacts/list'
          navLinks={breadcrumbsLinks}
          showBreadcrumbs={false}
          activeTab={activeTab}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          setActiveTab={setActiveTab}
          isDrawer={true}
          isDrawerCss={true}
          className='p-8 pt-28'
        >
          <List index={t('navMenu.contacts')} {...props} />
        </DynamicTabLayout>
      </div>
    </React.Fragment>
  );
};

Container.propTypes = {};

export default Container;
