import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const CheckboxSelection = forwardRef((props, ref) => {
  const [checked, setChecked] = useState(props.value || false);

  useImperativeHandle(ref, () => ({
    getValue: () => checked // ✅ Ensure AG Grid gets the correct updated value
  }));

  // ✅ Handle checkbox change
  const checkedHandler = event => {
    event.stopPropagation(); // Prevent AG Grid from stopping editing
    const isChecked = event.target.checked;
    const colId = props?.column?.colId;

    // Update grid data
    props?.node?.setDataValue(colId, isChecked);

    setTimeout(() => {
      props.api.startEditingCell({
        rowIndex: props.node.rowIndex,
        colKey: colId
      });
    }, 200);
  };

  return (
    <input
      type='checkbox'
      checked={checked}
      onChange={checkedHandler} // ✅ Handle checkbox selection
      style={{ cursor: 'pointer' }}
    />
  );
});

CheckboxSelection.propTypes = {
  value: PropTypes.bool,
  data: PropTypes.object,
  api: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  stopEditing: PropTypes.func,
  node: PropTypes.object,
  column: PropTypes.object,
  field: PropTypes.string
};

export default CheckboxSelection;
