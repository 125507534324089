import { DeleteForever } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../../components/common/InputField';
import Loader from '../../../../../components/common/Loader';
import { SFPADataApi, TargetIndustryDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, GET, POST, TARGET_INDUSTRY_RANKING } from '../../../../../services/constantService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';

const TargetIndustry = () => {
  const { projectData, handleUniversalState, showDeletedRows, refresh, setRefresh } = useContext(ProjectDataContext);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  //API Integration
  const { data: targetIndustryOptionsResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST);
    }
  });

  const { t } = useTranslation();

  const [recommendedIndustries, setRecommendedIndustries] = useState(projectData?.targetIndustries || []);

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetIndustries',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const handleTargetIndustryChange = (key, value, index) => {
    recommendedIndustries.filter(data => (showDeletedRows ? data?.deleted_at == null || data?.deleted_at != null : data?.deleted_at == null))[index][key] = value;
    const newTargetIndustries = recommendedIndustries.slice();
    setRecommendedIndustries(newTargetIndustries);
    handleUniversalStateLocally(newTargetIndustries);
  };

  const deleteTargetIndustry = async cardData => {
    try {
      setLoader(true);
      if (cardData?.industry_id == null || cardData?.industry_id == undefined || (!cardData?.id.includes('new') && !cardData?.id.includes('bulk'))) {
        await TargetIndustryDataApi(
          DELETE,
          '',
          {
            id: cardData?.id
          },
          ''
        );
      }

      const updatedVal = recommendedIndustries.filter(selectedTargetIndustry => selectedTargetIndustry.id != cardData?.id);
      const newValues = [...updatedVal.slice(), { ...cardData, deleted_at: new Date() }];
      setRecommendedIndustries(newValues);
      handleUniversalStateLocally(newValues);
      setLoader(false);
    } catch (err) {
      enqueueSnackbar(err, 'Target industry is not deleted');
    }
  };

  const checkIfAlreadyExisting = name => {
    const res = recommendedIndustries.some(industry => industry.industry?.name.toLowerCase() === name.toLowerCase());
    console.log({ res });
    return res;
  };

  const addTargetIndustry = event => {
    let newIndustry = {};

    if (typeof event == 'object') {
      newIndustry = {
        industry: event,
        industry_id: 'new-' + event.id,
        weight: '0',
        id: 'new' + event.id
      };
    } else {
      newIndustry = {
        industry: {
          name: event,
          id: 'new-type-' + recommendedIndustries.length + 1
        },
        id: 'new-type-' + recommendedIndustries.length + 1 + event,
        weight: '0',
        industry_id: 'new-type-' + recommendedIndustries.length + 1
      };
    }

    if (!checkIfAlreadyExisting(newIndustry?.industry?.name)) {
      const updatedIndustries = [newIndustry, ...recommendedIndustries];
      setRecommendedIndustries(updatedIndustries);
      handleUniversalStateLocally(updatedIndustries);
    } else {
      enqueueSnackbar(TARGET_INDUSTRY_RANKING.DUPLICATE_TARGET_INDUSTRY, 'warning');
    }
  };

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      const companyName = projectData?.ign_companies?.name;
      const ign_industry = {
        name: projectData?.ign_industries?.name
      };
      const fetchedIndustry = recommendedIndustries?.map(industry => {
        return {
          isSelected: true,
          isNew: false,
          name: industry?.industry?.name ?? industry.industry_name
        };
      });
      const payload = {
        template_name: 'SFPA-Industry-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: [ign_industry],
          fetchedIndustryList: fetchedIndustry,
          key: 'fetchedIndustryList'
        }
      };

      const res = await SFPADataApi(POST, projectData?.id, payload);
      if (res && res?.data && res?.data?.target_industries) {
        const allData = res?.data?.target_industries ?? [];
        const newData = allData?.filter(data => data?.isNew == true);

        const targetIndustries = newData.map((data, index) => {
          return {
            industry: {
              name: data?.name,
              industry_id: 'bulk-' + index
            },
            weight: '0',
            industry_id: 'bulk-' + index,
            id: 'bulk-' + index + data?.name,
            deleted_at: null
          };
        });
        const newTargetIndustries = [...targetIndustries, ...recommendedIndustries];
        setRecommendedIndustries(newTargetIndustries);
        handleUniversalStateLocally(newTargetIndustries);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  const addBackSoftDelete = async cardData => {
    setLoader(true);
    const payload = {
      id: cardData?.id
    };

    if (!cardData?.id.includes('new') && !cardData?.id.includes('bulk')) {
      await TargetIndustryDataApi(POST, '', payload, 'restore');
    }
    const updatedVal = recommendedIndustries.filter(selectedTargetIndustry => selectedTargetIndustry.id != cardData?.id);
    const newValues = [...updatedVal.slice(), { ...cardData, deleted_at: null }];
    setRecommendedIndustries(newValues);
    handleUniversalStateLocally(newValues);
    setLoader(false);
  };
  useEffect(() => {
    if (!isDataLoaded) {
      setRecommendedIndustries(projectData?.targetIndustries ?? []);
      if (projectData?.targetIndustries) {
        setIsDataLoaded(true);
      }
    }
    if (refresh) {
      setRecommendedIndustries(projectData?.targetIndustries ?? []);
      setRefresh(false);
    }
  }, [projectData]);
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addTargetIndustry}
        searchOptions={targetIndustryOptionsResponse?.data ?? []}
        searchLabel={t('utils.addIndustry')}
        isDropDown={false}
        onClickGetMore={() => onClickShowMore()}
      />
      <Stack
        direction='column'
        sx={{
          maxHeight: '40vh',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        {recommendedIndustries
          .filter(data => (showDeletedRows ? data?.deleted_at == null || data?.deleted_at != null : data?.deleted_at == null))
          .map((cardData, index) => (
            <Box
              key={index}
              sx={{
                width: '93%',
                backgroundColor: 'background.main',
                color: 'titleBarBackground.main',
                position: 'relative'
              }}
              className='pl-2 pt-3 pb-3 pr-2 rounded border m-1'
            >
              {cardData?.deleted_at == null ? (
                <DeleteForever
                  sx={{
                    color: 'error.main',
                    top: '37%',
                    left: '103%',
                    position: 'absolute',
                    cursor: 'pointer'
                  }}
                  fontSize='small'
                  onClick={() => deleteTargetIndustry(cardData)}
                />
              ) : (
                <DoneIcon
                  sx={{
                    color: 'primary.main',
                    top: '37%',
                    left: '103%',
                    position: 'absolute',
                    cursor: 'pointer'
                  }}
                  fontSize='small'
                  onClick={() => addBackSoftDelete(cardData)}
                />
              )}
              <Grid container fullWidth spacing={3} alignItems={'center'}>
                <Grid item xs={9}>
                  {cardData?.deleted_at == null ? (
                    <p className='fs-14' style={{ color: 'black' }}>
                      {cardData?.industry?.name ?? cardData?.industry_name}
                    </p>
                  ) : (
                    <p className='fs-14' style={{ color: 'black', textDecoration: 'line-through' }}>
                      {cardData?.industry?.name ?? cardData?.industry_name}
                    </p>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    label={t('utils.weight')}
                    fullWidth
                    value={cardData?.weight}
                    onChange={event => {
                      handleTargetIndustryChange('weight', event.target.value, index);
                    }}
                    size='small'
                    disabled={cardData?.deleted_at != null}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
      </Stack>
    </Box>
  );
};

export default TargetIndustry;
