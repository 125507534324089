//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { formatDate } from '../../../utils/date';
import { getFilterQuery } from '../../Commons/Utils';

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

const getRequestedBy = params => {
  let name = params?.data?.approve_request_by?.first_name ? `${params?.data?.approve_request_by?.first_name} ${params?.data?.approve_request_by?.last_name}` : '';
  return name;
};
export const columnDefs = () => {
  const columns = [
    {
      resizable: true,
      sortable: true,
      field: 'job_number',
      colId: 'job_number',
      headerName: 'Project#',
      minWidth: 250,
      maxWidth: 250,
      suppressSizeToFit: true,
      cellRenderer: 'IdRenderer',
      tooltipField: 'job_number',
      filter: 'agMultiColumnFilter'
    },
    {
      field: 'job_title',
      colId: 'job_title',
      headerName: 'Project Title',
      minWidth: 250,
      maxWidth: 200,
      tooltipField: 'job_title',
      cellRenderer: 'TitleRenderer',
      filter: 'agMultiColumnFilter'
    },
    {
      field: 'approval_requested_date',
      colId: 'approval_requested_date',
      headerName: 'Request Date',
      tooltipValueGetter: params => {
        const date = formatDate(params?.data?.approval_requested_date);
        return params?.data?.approval_requested_date ? `${date}` : '';
      },
      minWidth: 200,
      maxWidth: 200,
      filter: 'agMultiColumnFilter',
      filterValueGetter: params => {
        const date = formatDate(params?.data?.approval_requested_date);
        return params?.data?.approval_requested_date ? `${date}` : '';
      },
      valueGetter: params => {
        const date = formatDate(params?.data?.approval_requested_date);
        return params?.data?.approval_requested_date ? `${date}` : '';
      }
    },
    {
      field: 'approve_request_by',
      colId: 'approve_request_by',
      headerName: 'Requested By',
      tooltipValueGetter: params => getRequestedBy(params),
      minWidth: 250,
      maxWidth: 250,
      filter: 'agMultiColumnFilter',
      valueGetter: params => getRequestedBy(params)
    },
    {
      colId: 'billing_notes',
      field: 'billing_notes',
      headerName: 'Billing Notes',
      minWidth: 250,
      maxWidth: 550,
      width: 550,
      cellRenderer: 'BillingRender',
      cellRendererParams: {
        placement: 'left'
      },
      filter: 'agMultiColumnFilter',
      filterValueGetter: params => {
        const isHtml = /<\/?[a-z][\s\S]*>/i.test(params?.data?.billing_notes);
        return isHtml ? params?.data?.billing_notes.replace(/<\/?[^>]+(>|$)/g, '') : params?.data?.billing_notes;
      }
    }
  ];
  return columns;
};

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};
export const getFilterParamStringForApprovalSearch = data => {
  return getFilterQuery(data, {}, {}, '');
};
