import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import { PROJECT_SCREEN } from '../../../services/constantService';
import Search from '../../Searches/Search';
import './index.scss';

const HeaderWithSearch = props => {
  const { t } = useTranslation();
  const { filter } = useParams();

  const { handleSearchChange, searchValue, viewButton, setViewButton = () => {}, activeButton, setActiveButton } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const onSelect = buttonName => {
    setActiveButton(buttonName);
    setAnchorEl(null);
  };

  return (
    <div className='p-3 d-flex w-100 flex-wrap justify-content-between'>
      <div className='d-flex'>
        <div className='mr-2'>
          <CustomButton
            buttonText={t('module.project')}
            type={`${viewButton === PROJECT_SCREEN.PROJECT ? 'primary' : 'border'}`}
            sx={{ backgroundColor: 'transparent' }}
            variant={viewButton === PROJECT_SCREEN.BUSINESS_DEVELOPMENT ? 'outlined' : 'contained'}
            onClick={() => {
              if (viewButton != PROJECT_SCREEN.PROJECT) {
                setViewButton(PROJECT_SCREEN.PROJECT);
              }
            }}
          />
        </div>

        <CustomButton
          buttonText={'Business Development'}
          type={`${viewButton === PROJECT_SCREEN.BUSINESS_DEVELOPMENT ? 'primary' : 'border'}`}
          variant={viewButton === PROJECT_SCREEN.BUSINESS_DEVELOPMENT ? 'contained' : 'outlined'}
          sx={{ backgroundColor: 'transparent' }}
          onClick={() => {
            if (viewButton != PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
              setViewButton(PROJECT_SCREEN.BUSINESS_DEVELOPMENT);
            }
          }}
        />
        {viewButton == PROJECT_SCREEN.PROJECT && (
          <div style={{ width: '2s50px' }} className='ml-2'>
            <CustomButton
              variant='tertiary'
              type={`btn btn-sm ${activeButton === PROJECT_SCREEN.MY_PROJECT ? 'bg-white border' : 'bg-light'}`}
              id='basic-button'
              onClick={handleClick}
              buttonText={activeButton == PROJECT_SCREEN.MY_PROJECT ? 'My Projects' : 'All Projects'}
              iconRight={<ArrowDropDownIcon />}
            ></CustomButton>
            <Menu
              id='basic-menu'
              sx={{ width: '100%' }}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              anchorEl={anchorEl}
            >
              {activeButton == PROJECT_SCREEN.MY_PROJECT && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.ALL_PROJECT)}>All Projects</MenuItem>}
              {activeButton == PROJECT_SCREEN.ALL_PROJECT && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.MY_PROJECT)}>My Projects</MenuItem>}
            </Menu>
          </div>
        )}
        {viewButton == PROJECT_SCREEN.BUSINESS_DEVELOPMENT && (
          <div style={{ width: '250px' }} className='ml-2'>
            <CustomButton
              type={`btn btn-sm ${activeButton === PROJECT_SCREEN.MY_BD ? 'bg-white border' : 'bg-light'}`}
              variant='contained'
              id='basic-button'
              onClick={handleClick}
              buttonText={activeButton == PROJECT_SCREEN.MY_BD ? 'My BD' : 'All BD'}
              iconRight={<ArrowDropDownIcon />}
            ></CustomButton>
            <Menu
              id='basic-menu'
              sx={{ width: '100%' }}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              size='small'
              anchorEl={anchorEl}
            >
              {activeButton == PROJECT_SCREEN.MY_BD && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.ALL_BD)}>All BD</MenuItem>}
              {activeButton == PROJECT_SCREEN.ALL_BD && <MenuItem onClick={() => onSelect(PROJECT_SCREEN.MY_BD)}>My BD</MenuItem>}
            </Menu>
          </div>
        )}
      </div>
      {/* <div className='d-flex gap-2 flex-grow-1 justify-content-end'>
        <div className='multicheck w-25'>
          <CustomDropdown
            options={userSelectOptions ?? []}
            multiple={true}
            isCheckBox={true}
            disableCloseOnSelect={true}
            onChange={(event, newValue) => handleUserSelect(event, newValue)}
            label={'Select Users'}
            name='users'
            className={'w-100'}
            // noOptionsText={<CustomButton type={'w-100 primary'} variant={'outlined'} onClick={() => setAddCompanyPopup(true)} buttonText={'+ ' + t('utils.addCompany')} />}
            disabled={false}
            required={false}
            value={selectedUserList}
            // FOR FUTURE REFERRENCE
            // sx={{
            //   '& .MuiInputBase-root': {
            //     height: 32,
            //     fontSize: '0.75rem',
            //     paddingTop: 0, // Ensure no extra padding
            //     paddingBottom: 0,
            //     alignItems: 'center' // Vertically align content
            //   },
            //   '& .MuiSvgIcon-root': {
            //     fontSize: '0.75rem'
            //   },
            //   '& .MuiInputLabel-root': {
            //     top: '-5px',
            //     fontSize: '0.75rem'
            //   },
            //   '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            //     fontSize: '0.75rem', // Shrink label size
            //     top: '0' // Align label when focused
            //   },
            //   '& .MuiAutocomplete-tag': {
            //     fontSize: '0.75rem' // Match font size
            //   }
            // }}
          />
        </div>
        <div className='d-flex flex-wrap'>{statData.map(data => StatComponent(data))}</div>
      </div> */}

      <Search projectType={filter} value={searchValue} onChange={handleSearchChange} type='quicksearches' className='search-bar' sx={{ minWidth: '33%' }} />
    </div>
  );
};
HeaderWithSearch.propTypes = {
  stats: PropTypes.array,
  viewButton: PropTypes.string,
  setViewButton: PropTypes.func,
  handleQuery: PropTypes.func,
  currentQuery: PropTypes.object,
  activeButton: PropTypes.string,
  setActiveButton: PropTypes.func,
  allUsers: PropTypes.array,
  handleSearchChange: PropTypes.func,
  searchValue: PropTypes.string
};
export default HeaderWithSearch;
