//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { Auth } from 'aws-amplify';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { MoreHoriz } from '@mui/icons-material';
import { Avatar, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getUserSelector } from '../../../selectors';
import { userDataApi } from '../../../services/ApiService';
import { END_POINTS_URL, GET } from '../../../services/constantService';
import Loader from '../../common/Loader';
import UserMenu from '../UserMenu';
import './index.scss';

const HeaderProfile = props => {
  const { className, isMoreOption = true, showMyProfile = true } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(getUserSelector);

  const getProfileUrl = async () => {
    const srcUrl = await userDataApi(GET, user.id, {}, END_POINTS_URL.PROFILE_URL);
    return srcUrl?.data?.data;
  };

  const { data: profileUrl } = useQuery({
    queryKey: ['profileUrl', user?.profile_image_id],
    queryFn: () => getProfileUrl(user?.profile_image_id),
    enabled: () => {
      return !!user;
    }
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return (
      <div>
        <Loader show={true} />
      </div>
    );
  }

  return (
    <>
      <Box
        className={`d-flex align-items-center ${className}`}
        sx={{
          gap: 1
        }}
        onClick={handleClick}
      >
        {profileUrl ? (
          <span className='profile-icon d-flex align-items-center'>
            <Avatar src={profileUrl} />
          </span>
        ) : (
          <span className='profile-icon profile-default-icon d-flex align-items-center'>
            <AccountCircleIcon fontSize='inherit' />
          </span>
        )}
        {/* <span className="profile-name">{user?.name || ""}</span> */}
        {isMoreOption && (
          <span className='profile-dropdown'>
            <MoreHoriz fontSize='inherit' />
          </span>
        )}
      </Box>
      <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleClose={handleClose} open={Boolean(anchorEl)} showMyProfile={showMyProfile} />
      {/* <Menu
        className="header-menu-logout"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu> */}
    </>
  );
};

HeaderProfile.propTypes = {
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  isMoreOption: PropTypes.bool,
  showMyProfile: PropTypes.bool
};

export default HeaderProfile;
