import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';
import InfoDialog from './InfoPopup';

const SortableSubCompetency = ({ subCompetency, onAdd, expandedCompetencies, setExpandedCompetencies, subCompetencyDescription = '' }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: `subcomp-${subCompetency}`,
    data: {
      type: 'subcompetency',
      value: subCompetency
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleToggleExpand = e => {
    e.stopPropagation();
    setExpandedCompetencies(prev => ({
      ...prev,
      [subCompetency]: !prev[subCompetency]
    }));
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners} className={`sortable-subcompetency__container ${isDragging ? 'sortable-subcompetency__container--dragging' : ''}`}>
      <Box className='sortable-subcompetency__content'>
        <IconButton size='small' className='sortable-subcompetency__drag-icon' aria-label='Drag to reorder'>
          <DragIndicatorIcon fontSize='small' />
        </IconButton>
        <Typography variant='body2' className='sortable-subcompetency__title' title={subCompetency}>
          {subCompetency}
        </Typography>
        <InfoDialog labelText={subCompetency} description={subCompetencyDescription}></InfoDialog>
      </Box>
      <Box className='sortable-subcompetency__actions'>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            onAdd('competency', subCompetency);
          }}
          size='small'
          className='sortable-subcompetency__add-button'
          aria-label='Add Sub Competency'
        >
          <AddIcon fontSize='small' />
        </IconButton>
        <IconButton
          onClick={handleToggleExpand}
          size='small'
          className={`sortable-subcompetency__expand-button ${
            expandedCompetencies[subCompetency] ? 'sortable-subcompetency__expand-button--expanded' : 'sortable-subcompetency__expand-button--collapsed'
          }`}
          aria-label='Expand or collapse'
        >
          <ExpandMoreIcon fontSize='small' />
        </IconButton>
      </Box>
    </Box>
  );
};

SortableSubCompetency.propTypes = {
  subCompetency: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  expandedCompetencies: PropTypes.object.isRequired,
  setExpandedCompetencies: PropTypes.func.isRequired,
  subCompetencyDescription: PropTypes.string
};

export default SortableSubCompetency;
