import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import askIgnyteIcon from '../../assets/images/askIgnyteHeaderLogo.png';
import MenuIcon from '../Icons/AskIgnyteMenu.jsx';
import ChatHistoryMenu from './IgnyteChat/chatHistoryMenu.jsx';
import Chat from './IgnyteChat/index.jsx';
import WindowControls from './IgnyteChat/windowControl.jsx';

function ChatPopup({ open, onClose }) {
  const [isResizing, setIsResizing] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [historicalMessages, setHistoricalMessages] = useState([]);
  const [previousDimensions, setPreviousDimensions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [isMinimized, setIsMinimized] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [dimensions, setDimensions] = useState(() => {
    const initialWidth = Math.round(window.innerWidth * 0.3);
    return {
      width: Math.min(Math.max(initialWidth, 600), window.innerWidth - 64),
      height: window.innerHeight
    };
  });
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const resizingRef = useRef(false);
  const startPosRef = useRef({ x: 0, y: 0 });

  const handleMouseDown = e => {
    e.preventDefault();
    resizingRef.current = true;
    setIsResizing(true);
    startPosRef.current = { x: e.clientX, y: e.clientY };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  const handleMaximize = () => {
    if (isMinimized) {
      setIsMinimized(false);
      const initialWidth = Math.round(window.innerWidth * 0.3);
      setDimensions({
        width: Math.min(Math.max(initialWidth, 600), window.innerWidth - 64),
        height: window.innerHeight
      });
      return;
    }
    if (isMaximized) {
      const initialWidth = Math.round(window.innerWidth * 0.3);
      setDimensions({
        width: Math.min(Math.max(initialWidth, 400), window.innerWidth - 64),
        height: window.innerHeight
      });
      setIsMaximized(false);
    } else {
      setPreviousDimensions(dimensions);
      const initialWidth = Math.round(window.innerWidth * 0.3);
      setDimensions({
        width: Math.min(Math.max(initialWidth, 400), window.innerWidth - 64),
        height: window.innerHeight
      });
      setIsMaximized(true);
    }
  };
  const handleHistoricalMessages = messages => {
    setHistoricalMessages(messages);
  };

  const handleMouseMove = e => {
    if (!resizingRef.current) return;

    const deltaX = e.clientX - startPosRef.current.x;
    const deltaY = e.clientY - startPosRef.current.y;

    setDimensions(prev => ({
      width: Math.max(Math.min(prev.width - deltaX, window.innerWidth * 0.99), 300),
      height: Math.max(Math.min(prev.height - deltaY, window.innerHeight), 64)
    }));

    startPosRef.current = { x: e.clientX, y: e.clientY };
  };

  const handleMouseUp = () => {
    resizingRef.current = false;
    setIsResizing(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose(event, reason);
  };

  const handleBoxClose = (event, reason) => {
    setIsBoxVisible(true);
    setIsMinimized(false);
    onClose(event, reason);
  };
  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const triggerHistoryRefresh = () => {
    setShouldRefresh(prev => !prev);
  };

  useEffect(() => {
    if (!isMinimized) {
      const inputElement = document.querySelector('input');
      inputElement?.focus();
    }
  }, [isMinimized]);

  return isMinimized ? (
    isBoxVisible ? (
      <Box
        sx={{
          position: 'fixed',
          bottom: 5,
          right: 5,
          width: '300px',
          height: '64px',
          backgroundColor: 'white',
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10px',
          zIndex: 2500
        }}
      >
        <img
          src={askIgnyteIcon}
          alt='Ask Ignyte'
          style={{
            height: '24px',
            width: 'auto'
          }}
        />
        <WindowControls isMinimized={isMinimized} isMaximized={isMaximized} onMinimize={handleMinimize} onMaximize={handleMaximize} onClose={handleBoxClose} />
      </Box>
    ) : null
  ) : (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth={false}
      hideBackdrop={true}
      PaperProps={{
        sx: {
          position: 'fixed',
          right: 5,
          bottom: 5,
          m: 0,
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
          zIndex: 2500,
          width: `${dimensions.width}px`,
          height: isMinimized ? '64px' : `${dimensions.height}px`,
          overflow: 'hidden',
          transition: 'height 0.2s ease-in-out',
          borderRadius: '8px',
          maxHeight: 'none'
        }
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
          background: 'none'
        }
      }}
    >
      <DialogTitle
        sx={{
          padding: '0',
          borderBottom: isMinimized ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginRight: '20px' }}>
          {!isMinimized && (
            <IconButton
              aria-label='menu'
              onClick={handleMenuClick}
              aria-controls={menuOpen ? 'chat-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={menuOpen ? 'true' : undefined}
              sx={{
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <ChatHistoryMenu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            dimensions={dimensions}
            isMinimized={isMinimized}
            onHistoricalMessages={handleHistoricalMessages}
            shouldRefresh={shouldRefresh}
          />
          <div style={{ flex: 1, display: 'flex', justifyContent: isMinimized ? 'flex-start' : 'center', marginLeft: isMinimized ? '18px' : '0', marginTop: isMinimized ? '20px' : '0' }}>
            <img
              src={askIgnyteIcon}
              alt='Ask Ignyte'
              style={{
                height: '24px',
                width: 'auto'
              }}
            />
          </div>
        </div>
        <WindowControls isMinimized={isMinimized} isMaximized={isMaximized || isResizing} onMinimize={handleMinimize} onMaximize={handleMaximize} onClose={onClose} />
      </DialogTitle>
      {!isMinimized && (
        <>
          <DialogContent
            sx={{
              padding: '0',
              height: isMaximized ? '100%' : `${dimensions.height}px`,
              overflow: 'auto'
            }}
          >
            <Chat historicalMessages={historicalMessages} />
          </DialogContent>
          <div
            onMouseDown={handleMouseDown}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '20px',
              height: '20px',
              cursor: 'nw-resize',
              zIndex: 1400
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '6px',
              left: '6px',
              width: '10px',
              height: '10px',
              borderLeft: '2px solid rgba(0, 0, 0, 0)',
              borderTop: '2px solid rgba(0, 0, 0, 0)',
              pointerEvents: 'none'
            }}
          />
        </>
      )}
    </Dialog>
  );
}

ChatPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChatPopup;
