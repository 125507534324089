import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col } from 'react-grid-system';
import security_icon from '../../../../assets/icons/security_icon.svg';
import ValidatePermit from '../../../../security/ValidatePermit';
import { AllDocumentsApi } from '../../../../services/ApiService';
import * as AttachmentsService from '../../../../services/AttachmentsService';
import { ERROR, IGN_API, PATCH, POST, SUCCESS } from '../../../../services/constantService';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import AddDocuments from '../../../AddContact/Components/Documents/AddDocuments';
import CustomButton from '../../../common/CustomButton';
import FluidLayoutComponent from '../../../common/FluidLayoutComponent';
import CompanyDocumentCard from '../../../common/FunctionalComponents/CompanyDocumentCard';

const ViewCompanyDocuments = props => {
  const { register, data, unregister, setValue = () => {}, getContactDetails = () => {}, isHeaderNav, companyData, setLoading } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState(false);
  const { translateMessage } = useCustomMessageHook();
  const editData = () => {};
  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };
  const download = async index => {
    let attachmentId = data?.company_attachments[index]?.id;
    try {
      const url = await AttachmentsService.downloadCompanyDocuments(attachmentId);
      // const url = await ignContactDataApi(GET, null, null, sub_route)
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);
        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        const message = translateMessage('UnableMessage', false, 'Document', 'download');

        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
    }
  };
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
  const sub_route = `${IGN_API.company}/${companyData?.id}`;
  const sub_route1 = `${IGN_API.add_company_documents}/${companyData?.id}/attachments`;
  const uploadDocuments = async (document, item) => {
    if (!document) {
      enqueueSnackbar('Attachment data not fill', { variant: ERROR });
      return;
    }
    if (!document.file_name) {
      const message = translateMessage('Required', false, 'Name');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!isEdit) {
      if (!document.file) {
        const message = translateMessage('SelectFile', false, '');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    let payload = {};
    if (isEdit) {
      payload = {
        attachment: {
          id: item.id,
          file_type: 'attachment',
          file_name: document.file_name,
          is_confidential: document.is_confidential,
          attachment_visibilities: document.ign_contact_attachment_visibilities
        }
      };
      const { status, data } = await AllDocumentsApi(PATCH, sub_route, payload);
      console.log(status, data);
      if (status === 200) {
        // getHeaderDetails()
        getContactDetails();
        const message = translateMessage('Successfully', false, 'Attachment', 'uploaded');
        enqueueSnackbar(message, { variant: SUCCESS });

        setIsPopupOpen(false);
      } else {
        enqueueSnackbar(data?.message || 'Attachment', { variant: ERROR });
      }
    } else {
      let fileNameSplit = document.file?.name?.split('.');
      let bodyFormData = new FormData();
      bodyFormData.append('is_confidential', document.is_confidential);
      bodyFormData.append('file_name', `${document.file_name}.${fileNameSplit[fileNameSplit.length - 1]}`);
      bodyFormData.append('file_type', 'attachment');
      bodyFormData.append('file', document.file);
      bodyFormData.append('attachment_visibilities', JSON.stringify(document.ign_contact_attachment_visibilities));
      const { status, data } = await AllDocumentsApi(POST, sub_route1, bodyFormData);
      processUpload(status, data, document.file.name);
    }
  };

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      getContactDetails();
      //getHeaderDetails()
      const message = translateMessage('Successfully', false, `${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsPopupOpen(false);
    } else {
      const message = translateMessage('UnableMessage', false, `${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };
  return (
    <ValidatePermit path='company_documents' type='message-only' parent={'company'} permissionType={'read'}>
      <div id='view-company' className={'custom-scrollbar '}>
        <Box className={isHeaderNav === 'closed' ? 'details-container-close drawer-tab-layout-close' : 'details-container'}>
          <Box className={`scroll-content ${isHeaderNav === 'closed' ? 'details-container-subtab-close' : 'details-container-subtab'}`}>
            {/* <Box className={`${isHeaderNav === 'closed' ? 'details-container-close' : 'details-container'} scroll-content`}> */}
            <Box className='section-details m-2'>
              <Box className='d-flex flex-column w-100 row-gap'>
                <Box className='d-flex flex-row w-100 column-gap viewcontact-icon-style'>
                  <DescriptionIcon color='disabled'></DescriptionIcon>
                  <Box className='d-flex flex-column w-100 '>
                    <Box className='title-color fs-12'>{'Documents'}</Box>
                  </Box>
                </Box>
                <Box id='bio_card'>
                  <Box className='custom-card'>
                    <Box className='d-flex'>
                      <FluidLayoutComponent>
                        {data &&
                          data?.company_attachments?.map((card, cardIndex) => (
                            <Col key={cardIndex} sm={12} md={12} lg={6} className='p-3'>
                              <CompanyDocumentCard
                                data={card}
                                index={cardIndex}
                                editData={editData}
                                editItems={openPopupEditing}
                                download={download}
                                getContact={getContactDetails}
                                isForCompany={true}
                                setLoading={setLoading}
                                downloadIcon={<CloudDownloadIcon />}
                                addIcon={card.is_primary ? security_icon : ''}
                                styleData={{
                                  titleSize: 13,
                                  textSize: 10,
                                  height: 130,
                                  width: 'auto'
                                }}
                              ></CompanyDocumentCard>
                            </Col>
                          ))}
                      </FluidLayoutComponent>
                    </Box>
                    <Box className='add-document padding-left-20 pt-2'>
                      <Box onClick={handleOpenPopup}>
                        <CustomButton
                          type={'secondary'}
                          variant='outlined'
                          size={'medium'}
                          buttonText={data?.company_attachments?.length === 0 ? 'Add' : 'Add Another'}
                          customWidth={120}
                          iconLeft={<AddIcon sx={{ width: 14 }} />}
                        />
                      </Box>
                      {isPopupOpen && (
                        <AddDocuments
                          title={`${isEdit ? 'Update' : 'Add'} Document`}
                          setIsPopupOpen={setIsPopupOpen}
                          isPopupOpen={isPopupOpen}
                          isEdit={isEdit}
                          setIsEdit={setIsEdit}
                          data={isEdit ? data?.company_attachments[indexValue] : null}
                          register={register}
                          unregister={unregister}
                          handleSave={uploadDocuments}
                          setValue={setValue}
                          isForCompany={true}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </ValidatePermit>
  );
};
ViewCompanyDocuments.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  data: PropTypes.object,
  isHeaderNav: PropTypes.string,
  getContactDetails: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  companyData: PropTypes.object,
  setLoading: PropTypes.func
};

export default ViewCompanyDocuments;
