import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const InfoDialog = ({ labelText, description = '' }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const infoIconRef = useRef(null);
  const popoverRef = useRef(null);

  const calculatePosition = useCallback(() => {
    if (infoIconRef.current) {
      const iconRect = infoIconRef.current.getBoundingClientRect();
      return {
        top: iconRect.bottom + 14,
        right: window.innerWidth - iconRect.right - 7,
        iconLeft: iconRect.left + 10
      };
    }
    return { top: 0, right: 0, iconLeft: 0 };
  }, []);

  const [position, setPosition] = useState(calculatePosition);

  const handleToggle = () => {
    if (!popoverOpen) {
      const newPosition = calculatePosition();
      setPosition(newPosition);
      setPopoverOpen(true);
    } else {
      setPopoverOpen(false);
    }
  };

  const handleClose = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    if (popoverOpen) {
      const handleScroll = () => {
        setPopoverOpen(false);
      };
      const handleClickOutside = event => {
        if (infoIconRef.current && !infoIconRef.current.contains(event.target) && popoverRef.current && !popoverRef.current.contains(event.target)) {
          setPopoverOpen(false);
        }
      };
      window.addEventListener('scroll', handleScroll, true);
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        window.removeEventListener('scroll', handleScroll, true);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [popoverOpen]);

  return (
    <div className='relative inline-block' style={{ marginRight: '50px' }}>
      <InfoOutlinedIcon ref={infoIconRef} onClick={handleToggle} className='cursor-pointer fs-24' />
      {popoverOpen && (
        <div
          ref={popoverRef}
          className='popover-container'
          style={{
            top: `${position.top}px`,
            right: `${position.right}px`
          }}
        >
          <div className='popover-arrow'></div>
          <div className='popover-content'>
            <div className='popover-header'>
              <Typography variant='body2' className='bold'>
                {labelText}
              </Typography>
              <CloseIcon onClick={handleClose} className='cursor-pointer' />
            </div>
            <div className='popover-description'>
              <Typography variant='body2' sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {description}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

InfoDialog.propTypes = {
  labelText: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default InfoDialog;
