import { Box } from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModalWithHeader from '../../../../../Containers/Commons/CustomModalWithHeader';
import { UpdateCandidateScoreApi } from '../../../../../services/ApiService';
import { STANDARD_ERROR_MESSAGE } from '../../../../../services/constantService';
import { showToast } from '../../../../../utils/common';
import CustomButton from '../../../../common/CustomButton';
import RichText from '../../../../common/RichText';

function CommentModal({ open, setOpen, fieldKey, updateMasterData, payloadValue }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [richText, setRichText] = useState('');

  useEffect(() => {
    if (!isEmpty(payloadValue)) {
      setRichText(payloadValue[`${fieldKey}_comments`] || '');
    }
  }, [payloadValue, fieldKey]);

  const handleClose = useCallback(() => {
    if (isLoading) return;
    setOpen(false);
  }, [isLoading, setOpen]);

  const updateRatingDb = useCallback(
    async comments => {
      const sub_route = `/score/${payloadValue.id}`;
      try {
        setIsLoading(true);
        const response = await UpdateCandidateScoreApi(sub_route, {
          [`${fieldKey}_comments`]: comments
        });
        if (get(response, 'data.error', false)) {
          showToast(response.data.msg, 'error');
        } else {
          updateMasterData(response.data.data, payloadValue.category);
          handleClose();
        }
      } catch (error) {
        showToast(STANDARD_ERROR_MESSAGE, 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [payloadValue, fieldKey, updateMasterData, handleClose]
  );

  const handlePost = () => updateRatingDb(richText);

  return (
    <CustomModalWithHeader
      closeIcon={true}
      onClose={handleClose}
      isOpen={open}
      style={{ width: 700, padding: 0 }}
      subBoxStyle={{
        background: '#23CEA7',
        padding: '6px 10px',
        borderRadius: '4px 4px 0 0'
      }}
      labelStyle={{ color: '#fff', fontSize: '16px' }}
      label={t('project.candidateScore.postComment')}
      buttonStyle={{ color: '#fff', cursor: 'pointer' }}
      showDivider={false}
    >
      <Box px={2} py={2} display='flex' flexDirection='column'>
        <RichText skipFirstRender onChange={setRichText} defaultValue={richText} />
        <CustomButton
          disabled={isLoading}
          isLoading={isLoading}
          style={{ marginTop: 10 }}
          type='primary'
          onClick={handlePost}
          buttonText={t('project.candidateScore.post')}
          size='medium'
          boxClassName='w-full'
          fullWidth={true}
        />
      </Box>
    </CustomModalWithHeader>
  );
}

CommentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fieldKey: PropTypes.string,
  payloadValue: PropTypes.object,
  updateMasterData: PropTypes.func
};

export default CommentModal;
