//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { orderBy } from 'lodash';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const TermtypeIntervalSelection = props => {
  const { label = 'Fee Type', type, required = false, selectedType, sort = false, viewAddress, isDisabled = false, ...rest } = props;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const feeTypes = useSelector(state => state.commonReducer[type]);
  const dispatch = useDispatch();

  const filterDataOptions = data => {
    return data?.filter(item => {
      return selectedType.every(listItem => listItem?.type !== item?.field_value);
    });
  };
  const filterOptions = data => {
    return data?.filter(item => item.short_desc.match(new RegExp(value, 'gi')));
  };

  useEffect(() => {
    if (!feeTypes) {
      dispatch(fetchPickLists(type));
    } else {
      if (selectedType?.length) {
        if (selectedType[0] !== undefined) {
          const filterData = filterDataOptions(feeTypes);
          const filterValue = filterOptions(filterData);
          setOptions(filterValue);
        }
      } else {
        const filterValue = filterOptions(feeTypes);
        setOptions(filterValue);
      }
    }
  }, [feeTypes, selectedType, value, dispatch]);

  return (
    <CustomDropdown
      {...rest}
      disabled={isDisabled}
      options={sort ? orderBy(options, 'short_desc', 'asc') : options}
      filterOptions={options => options}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      required={required}
      label={label}
      viewAddress={viewAddress}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return options?.find(item => item?.field_value === option || item?.short_desc === option)?.short_desc;
        }
        return option.short_desc;
      }}
      //   getOptionSelected={(option, value) => {
      //     if (typeof value === 'string') {
      //       return option?.short_desc === value;
      //     }
      //     return option?.short_desc === value?.short_desc
      //   }}
    />
  );
};

TermtypeIntervalSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  variant: PropTypes.string,
  sort: PropTypes.bool,
  selectedType: PropTypes.array,
  viewAddress: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object
};

export default TermtypeIntervalSelection;
