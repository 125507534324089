import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Menu, MenuItem } from '@mui/material';
import { differenceInDays, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getChatHistory, getChatHistoryById } from '../../../services/ApiService';

function ChatHistoryMenu({ anchorEl, open, onClose, dimensions, isMinimized, handleMenuClose, onHistoricalMessages, shouldRefresh }) {
  const [chatHistory, setChatHistory] = useState([]);
  const [categorizedHistory, setCategorizedHistory] = useState({
    today: [],
    yesterday: [],
    twoDaysAgo: [],
    lastWeek: []
  });

  const fetchChatHistory = async () => {
    try {
      const response = await getChatHistory();
      const historyData = response?.data?.data || [];
      setChatHistory(historyData);
      categorizeChatHistory(historyData);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const categorizeChatHistory = history => {
    const now = new Date();
    const categorized = {
      today: [],
      yesterday: [],
      twoDaysAgo: [],
      lastWeek: []
    };

    history.forEach(item => {
      const itemDate = parseISO(item.created_at);
      const daysDifference = differenceInDays(now, itemDate);

      if (daysDifference === 0) {
        categorized.today.push(item);
      } else if (daysDifference === 1) {
        categorized.yesterday.push(item);
      } else if (daysDifference === 2) {
        categorized.twoDaysAgo.push(item);
      } else if (daysDifference <= 7) {
        categorized.lastWeek.push(item);
      }
    });

    setCategorizedHistory(categorized);
  };
  const handleChatHistoryClick = async id => {
    try {
      const response = await getChatHistoryById(id);
      console.log('responsechatHistory', response.data);
      if (response.data.data && response.data.data.length > 0) {
        const historicalMessages = response.data.data.map(msg => ({
          text: msg.message_text,
          sender: msg.sender_type === 'bot' ? 'bot' : 'user',
          session_id: msg.session_id
        }));
        onHistoricalMessages(historicalMessages);
        onClose();
      }
    } catch (error) {
      console.error('Error fetching chat history by id:', error);
    }
  };

  useEffect(() => {
    if (open && shouldRefresh) {
      fetchChatHistory();
    }
  }, [open, shouldRefresh]);

  // Original effect for initial load
  useEffect(() => {
    if (open) {
      fetchChatHistory();
    }
  }, [open]);

  const renderCategorySection = (title, items) => {
    if (items.length === 0) return null;

    return (
      <>
        <MenuItem
          sx={{
            color: '#666',
            fontSize: '0.875rem',
            mt: 2,
            '&:hover': {
              background: 'transparent',
              cursor: 'default'
            }
          }}
          disableRipple
        >
          {title}
        </MenuItem>
        {items.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => handleChatHistoryClick(item.id)}
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word'
            }}
          >
            {item.session_title}
          </MenuItem>
        ))}
      </>
    );
  };

  return (
    <Menu
      id='chat-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'menu-button'
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        '& .MuiMenuItem-root': {
          whiteSpace: 'normal',
          minHeight: 'auto'
        },
        '& .header-item': {
          '&:hover': {
            background: 'transparent',
            cursor: 'default'
          }
        },
        '& .MuiPaper-root': {
          width: `${dimensions.width < 800 ? dimensions.width * 0.8 : dimensions.width * 0.5}px`,
          maxWidth: '80vw',
          marginRight: '8px',
          marginBottom: '8px',
          borderRadius: '8px',
          position: 'absolute',
          opacity: 1,
          transform: 'none',
          transition: 'opacity 403ms cubic-bezier(0.4, 0, 0.2, 1), transform 268ms cubic-bezier(0.4, 0, 0.2, 1)',
          top: '5px',
          left: '1133px',
          height: isMinimized ? '64px' : `${dimensions.height - 0}px`,
          transformOrigin: '0px 0px',
          '& .MuiList-root': {
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&': {
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none'
            }
          }
        }
      }}
    >
      <MenuItem
        sx={{
          color: '#9F0064',
          fontSize: '1rem',
          fontWeight: 'bold',
          '&:hover': {
            background: 'transparent',
            cursor: 'default'
          },
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}
        disableRipple
      >
        <ArrowBackIcon
          onClick={onClose}
          sx={{
            color: '#666',
            cursor: 'pointer'
          }}
        />
        Search History
      </MenuItem>

      {renderCategorySection('Today', categorizedHistory.today)}
      {renderCategorySection('Yesterday', categorizedHistory.yesterday)}
      {renderCategorySection('2 days ago', categorizedHistory.twoDaysAgo)}
      {renderCategorySection('Last week', categorizedHistory.lastWeek)}

      {chatHistory.length === 0 && (
        <MenuItem
          sx={{
            color: '#666',
            fontStyle: 'italic',
            textAlign: 'center',
            width: '100%'
          }}
          disableRipple
        >
          No search history found
        </MenuItem>
      )}
    </Menu>
  );
}

ChatHistoryMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  isMinimized: PropTypes.bool.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  onHistoricalMessages: PropTypes.func.isRequired,
  shouldRefresh: PropTypes.bool.isRequired
};

export default ChatHistoryMenu;
