import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { ignWorkbenchQueryApi } from '../../../../../services/ApiService';
import { POST, ROUTES } from '../../../../../services/constantService';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../../../utils/common';
import { CustomLoadingOverlayComponent } from '../../../../Commons/Utils';
import { queryContactColumn } from '../Grid/QueryData';
import { ContactFilterModal } from './QueryFilterAccordion/ContactFilterAccordion';

const mappedPayload = data => {
  return {
    ...data,
    industry: data?.industry?.map(item => item?.id),
    jobFunction: data?.jobFunction?.map(item => item?.id),
    contactCreatedBy: data?.contactCreatedBy?.map(item => item?.id)
  };
};

export const QueryContactGrid = ({ data, gridType, filterModel, sortModel, setQueryResponse }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    const fetchContactQuery = async () => {
      try {
        setGridLoading(true);
        const payload = Object.keys(filterOptions).length > 0 ? filterOptions : mappedPayload(data);
        const response = await ignWorkbenchQueryApi(POST, payload, `type/${data?.query_type?.toLowerCase()}`);
        setFilteredData(response?.data?.rows || []);
        setGridLoading(false);
      } catch (error) {
        setFilteredData([]);
        setGridLoading(false);
      }
    };
    fetchContactQuery();
  }, [filterOptions]);

  const columns = useMemo(() => queryContactColumn, []);

  const capitalizeProperCase = string => {
    if (!string) return '';
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const routeCompany = id => {
    const path = ROUTES.viewCompanyWithoutCurrentTab.replace(':id', id);
    window.open(path, '_blank');
  };

  const routeContact = id => {
    const path = ROUTES.allContactGridWithDrawerOpen.replace(':id', id);
    window.open(path, '_blank');
  };

  const rowDataArray = useMemo(
    () =>
      filteredData.map(query => ({
        ...query,
        contact_name: query?.name,
        company_id: query?.contact_company?.id || query?.contact_company?.[0]?.id,
        company_name: query?.contact_company?.name || query?.contact_company?.[0]?.name,
        tags: query?.contact_tags?.map(item => capitalizeProperCase(item?.ign_tags?.name))?.join(', ') || '--',
        country: query?.contact_country_p1 || '--',
        state: query?.contact_state_p1 || '--',
        city: query?.contact_address?.[0]?.city || '--',
        job_title: query?.current_job_title || '--',
        job_function: query?.contact_job_functions_p1 || '--',
        industry: query?.contact_industries_p1?.join(', ') || '--',
        status: query?.contact_status || '--',
        type: query?.contact_record_types?.[0]?.type || '--',
        lastActivity: query?.updated_at ? new Date(query?.updated_at)?.toDateString() : '--'
      })),
    [filteredData]
  );

  return (
    <>
      <ContactFilterModal data={data} filterOptions={filterOptions} setFilterOptions={setFilterOptions} setQueryResponse={setQueryResponse} />
      <div id='myGrid' className='ag-theme-alpine contact-query-workbench-grid' style={{ overflow: 'hidden', height: 'calc(70vh - 100px)', marginTop: '10px' }}>
        <AgGridWrapper
          enableBrowserTooltips={true}
          gridType={gridType}
          rowHeight={50}
          columnDefs={columns}
          defaultColumnDefs={queryContactColumn}
          loadingOverlayComponent={CustomLoadingOverlayComponent}
          scrollbarWidth={12}
          showHeaderMenu={true}
          rowData={rowDataArray}
          paginationPageSize={1000}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          sortModel={sortModel}
          filterModel={filterModel}
          loading={gridLoading}
          suppressHorizontalScroll={false}
          domLayout='normal'
          disableStaticMarkup
          rowGroupPanelShow='always'
          saveGridSetting={true}
          rowModelType='clientSide'
        />
      </div>
    </>
  );
};

QueryContactGrid.propTypes = {
  gridState: PropTypes.array,
  gridType: PropTypes.string,
  data: PropTypes.object,
  setQueryResponse: PropTypes.func,
  sortModel: PropTypes.array,
  filterModel: PropTypes.array
};
