import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ignAthenaDataApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import CustomDropdown from '../../common/CustomDropdown';
import Loader from '../../common/Loader';
import DecisionMatrixChart from '../../DecisionMatrixChart';

function AthenaDecision(props) {
  const { contactId } = props;
  const [loader, setLoader] = useState(false);
  const [assessmentsIds, setAssessmentIds] = useState([]);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState([]);
  const [decisionGraphItem, setDecisionGraphItem] = useState({});

  const fetchAssessmentsByContactId = async id => {
    try {
      setLoader(true);
      const contactAssessments = await ignAthenaDataApi(GET, '', '', `get-assessments/${id}`);
      const assessments = contactAssessments?.data;
      let assessmentOptions = [{ name: 'Select All', value: true }];
      assessments.map((assessment, index) => {
        assessmentOptions.push({ name: `Assessment ${index + 1}`, value: assessment?.id, ...assessment });
      });

      setAssessmentIds(assessmentOptions);
      if (assessmentOptions.length > 0) {
        handleAssessmentDropdownChange('', [assessmentOptions[0]]);
      }
    } catch (err) {
      enqueueSnackbar(err);
    } finally {
      setLoader(false);
    }
  };
  const handleAssessmentDropdownChange = async (e, value) => {
    try {
      //select All
      const selectAll = value.filter(val => val.value == true).length > 0 ? true : false;
      setLoader(true);
      setSelectedAssessmentId(selectAll ? assessmentsIds : value);
    } catch (err) {
      enqueueSnackbar(err);
    } finally {
      setLoader(false);
    }
  };

  const mapCandidateCoordinates = data => {
    const coordinates = data.map(assessment => {
      return {
        x: assessment.x_coordinate,
        y: assessment.y_coordinate,
        label: assessment.name
      };
    });
    return coordinates;
  };
  useEffect(() => {
    fetchAssessmentsByContactId(contactId);
  }, [contactId]);

  useEffect(() => {
    const item = {
      scatter_data: mapCandidateCoordinates(selectedAssessmentId),
      sfpa: true
    };

    setDecisionGraphItem(item);
  }, [selectedAssessmentId]);
  return (
    <Box className='p-3' sx={{ height: '100%' }}>
      <Loader show={loader} />
      <div className='d-flex justify-content-between align-items-center'>
        <CustomDropdown multiple={true} isCheckBox={true} options={assessmentsIds} value={selectedAssessmentId} onChange={handleAssessmentDropdownChange} />
      </div>
      <Box className='athena-score-component'>
        <DecisionMatrixChart
          item={decisionGraphItem}
          dataPoints={{
            item_1: 0,
            item_2: 0,
            item_3: 0,
            item_4: 0,
            item_5: 0,
            item_6: 0,
            item_7: 0
          }}
          isAuraVisible={false}
          //   projectId={'41612bed-67e2-4572-8de2-4fb79b5aff4d'}
        />
      </Box>
    </Box>
  );
}

AthenaDecision.propTypes = {
  contactId: PropTypes.string
};

export default AthenaDecision;
