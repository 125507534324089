import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';

function ResonTags(props) {
  const dataArray = JSON.parse(props.data);

  return (
    <Typography variant='body2' sx={{ mb: 2, mt: 1 }}>
      {dataArray.map((item, index) => (
        <Chip key={index} label={item} size='small' sx={{ marginRight: 1, marginBottom: 0.5 }} />
      ))}
    </Typography>
  );
}

ResonTags.propTypes = {
  data: PropTypes.object
};

export default ResonTags;
