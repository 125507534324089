import { Edit } from '@mui/icons-material';
import { Box, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerComponent from '../../../components/common/Drawer';
import Footer from '../../../components/common/Footer';
import TranslateForm from './TranslateForm';

const ViewTranslateValueDrawer = props => {
  const { isDrawerOpen, setIsDrawerOpen, onSubmit, setValue, register, unregister, watch, data, isEdited, setIsEdited } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  return (
    <DrawerComponent
      id={'view-tag-drawer'}
      anchor={'right'}
      open={isDrawerOpen}
      onClose={() => {
        setIsEdited(false);
        setIsDrawerOpen(false);
      }}
      width={isEdited ? '52vw' : '42vw'}
      drawerCloseIconTop='0px'
      top={52}
    >
      <Box className='d-flex flex-column'>
        <Box className='header-section d-flex p-2 align-items-center'>
          <Box className='d-flex p-2 text-bold align-items-center' fontSize={'16px'} flex={1}>
            <Box>{t('utils.translateValuesSetup')}</Box>

            <Box style={{ cursor: 'pointer', backgroundColor: 'black', borderRadius: '6px' }} className='ml-10 text-white px-1' onClick={() => setIsEdited(true)}>
              <Edit style={{ width: '12px', height: '12px' }} />
            </Box>
          </Box>
        </Box>
        {isEdited === false ? (
          <Box className='body-section px-8 pb-8'>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
              <Box className='pb-12'>
                <Box style={{ fontSize: '14px' }} className='text-bold pb-2'>
                  {t('utils.fieldName')}
                </Box>
                <Box>{data?.field_name || '---'}</Box>
              </Box>

              <Box className='pb-12'>
                <Box style={{ fontSize: '14px' }} className='text-bold pb-2'>
                  {t('utils.fieldValue')}
                </Box>
                <Box>{data?.field_value || '---'}</Box>
              </Box>
              <Box className='pb-12'>
                <Box style={{ fontSize: '14px' }} className='text-bold pb-2'>
                  {t('utils.languageCode')}
                </Box>
                <Box>{data?.lang_cd || '---'}</Box>
              </Box>

              <Box className='pb-12'>
                <Box style={{ fontSize: '14px' }} className='text-bold pb-2'>
                  {t('utils.shortDescription')}
                </Box>
                <Box>{data?.short_desc || '---'}</Box>
              </Box>

              <Box className='pb-12'>
                <Box style={{ fontSize: '14px' }} className='text-bold pb-2'>
                  {t('utils.active')}
                </Box>
                <Switch checked={data?.is_active} disabled size='small' />
              </Box>

              <Box></Box>

              <Box className='pb-12'>
                <Box style={{ fontSize: '14px' }} className='text-bold pb-2'>
                  {t('utils.longDescription')}
                </Box>
                <Box>{data?.long_desc || '---'}</Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className='d-flex flex-column'>
            <TranslateForm watch={watch} unregister={unregister} register={register} setValue={setValue} data={data} isEdit></TranslateForm>
            <Box className='mr-4'>
              <Footer
                onSubmit={async () => {
                  setLoading(true);
                  await onSubmit();
                  setLoading(false);
                }}
                data={data}
                onClose={() => {
                  setIsEdited(false);
                }}
                disabled={loading}
                submitText={t('actions.saveChanges')}
              ></Footer>
            </Box>
          </Box>
        )}
      </Box>
    </DrawerComponent>
  );
};
ViewTranslateValueDrawer.propTypes = {
  data: PropTypes.object,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  saveData: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  onSubmit: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  isEdited: PropTypes.bool,
  setIsEdited: PropTypes.func,
  gridApi: PropTypes.object
};

export default ViewTranslateValueDrawer;
