import { CheckBox } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Stack } from '@mui/material';
import moment from 'moment';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomDropdown from '../../../components/common/CustomDropdown';
import { PicklistProjectContactDataApi, picklistsDataApis, ProjectContactDataApi } from '../../../services/ApiService';
import { GET, POST } from '../../../services/constantService';
import AgGridWrapper from '../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../utils/common';
import useApi from '../../../utils/Hooks/useApiHook';
import { CustomLoadingOverlayComponent, loadColumnStateFromLocalStorage } from '../../Commons/Utils';
import ClientContact from './ClientContact';
import { ProjectDataContext } from './Context';

const colDefs = [
  {
    field: 'clientContact',
    headerName: 'Client Contact',
    suppressSizeToFit: true,
    cellRenderer: ({ value }) => value,
    minWidth: 300,
    cellStyle: { textAlign: 'left' },
    // maxWidth: 300,
    sortable: false,
    filter: false,
    floatingFilter: false
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    suppressSizeToFit: true,
    minWidth: 350,
    cellStyle: { textAlign: 'left' },
    // maxWidth: 350,
    sortable: false,
    filter: false,
    floatingFilter: false
  },
  {
    field: 'hiringManager',
    headerName: 'Hiring Manager',
    suppressSizeToFit: true,
    cellRenderer: ({ value }) => value,
    minWidth: 200,
    maxWidth: 200,
    cellStyle: { textAlign: 'left' },
    sortable: false,
    filter: false,
    floatingFilter: false
  },
  {
    field: 'inviteToClientPortal',
    headerName: 'Invite to Client Portal',
    suppressSizeToFit: true,
    cellRenderer: ({ value }) => value,
    minWidth: 200,
    maxWidth: 200,
    cellStyle: { textAlign: 'left' },
    sortable: false,
    filter: false,
    floatingFilter: false,
    suppressHeaderMenuButton: true
  },
  {
    field: 'inviteDetails',
    headerName: 'Invite Details',
    suppressSizeToFit: true,
    cellRenderer: ({ value }) => value,
    minWidth: 350,
    cellStyle: { textAlign: 'left' },
    // maxWidth: 400,
    sortable: false,
    filter: false,
    floatingFilter: false
  }
];

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

let gridApi;
let columnApi;
function InviteToClientPortal() {
  const gridApiRef = useRef(null);
  const { projectData } = useContext(ProjectDataContext);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [clientEmails, setClientEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [language, setLanguage] = useState('');
  const { data: languageData } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'LANGUAGE_CODE');
    }
  });
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [sendInviteButton, setSendInviteButton] = useState({
    text: 'Send Invite',
    disabled: false
  });
  const [selectedClientContact, setSelectedClientContact] = useState({});

  const [alert, setAlert] = useState({
    open: false,
    message: ''
  });

  let query = useQuery();

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    // params.columnApi.autoSizeAllColumns();
    gridApi?.setGridOption('datasource', dataSource);
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true });
  };

  const showEmailsPopup = data => {
    const emails = data.map(item => ({ label: item.email, id: item.email }));
    console.log({ emails });
    setClientEmails(emails);
    setShowInviteDialog(true);
  };

  const handleSendInvite = async () => {
    setSendInviteButton({ text: 'Sending...', disabled: true });
    try {
      const res = await ProjectContactDataApi(POST, 'invite', {
        email: selectedEmail,
        firstName: selectedClientContact?.contact?.first_name,
        lastName: selectedClientContact?.contact?.last_name,
        id: selectedClientContact?.id,
        lang: language,
        project_id: projectData?.id,
        project_name: projectData?.job_title,
        contact_id: selectedClientContact?.contact?.id
      });

      if (res.status >= 400) {
        throw new Error(res?.data?.message || 'Error sending invite');
      }

      if (gridApiRef.current && gridApiRef.current.api) gridApiRef.current.api.refreshInfiniteCache();

      setAlert({ open: true, message: 'Invite Sent Successfully' });
    } catch (err) {
      console.error(err);
      setAlert({ open: true, message: err?.message || 'Error sending invite' });
    } finally {
      setSendInviteButton({ text: 'Send Invite', disabled: false });
      setShowInviteDialog(false);
    }
  };

  const onSendInviteClick = clientContact => {
    showEmailsPopup(clientContact?.contact?.contact_emails);
    setSelectedClientContact(clientContact);
  };

  const formatData = data => {
    return data.map(item => {
      return {
        id: item.id,
        clientContact: <ClientContact name={item?.contact?.name} customClassName={'pipelineContact'} />,
        inviteDetails: item.invited_by_user && item.clientsuite_invitation_sent_on ? `${moment(item.clientsuite_invitation_sent_on).format('LT, DD MMM YYYY')} by ${item.invited_by_user.name}` : '',
        jobTitle: item?.contact?.current_job_title,
        inviteToClientPortal: (
          <Button variant='outlined' onClick={() => onSendInviteClick(item)}>
            {item?.clientsuite_invitation_sent_on ? 'Resend Invite' : 'Send Invite'}
          </Button>
        ),
        hiringManager: item.hiring_mgr ? <CheckBox checked disabled /> : ''
      };
    });
  };

  const dataSource = {
    getRows: async params => {
      // gridApi.showLoadingOverlay();
      setLoading(true);
      const res = await PicklistProjectContactDataApi(
        POST,
        '',
        {
          query: {
            limit: 50,
            page: params.endRow / 50,
            project_id: projectData?.id ? projectData?.id : query.get('id')
          }
        },
        ''
      );

      if (res.status === 200) {
        console.log({ resultData: res.data });
        params.successCallback(formatData(res.data.data), res.data.paging.totalCount);
      } else {
        params.failCallback();
      }

      // gridApi.hideOverlay();
      setLoading(false);
    }
  };

  return (
    <div id='myGrid' className='ag-theme-alpine invite-to-client-portal'>
      <Stack direction='column'>
        <div className='fs-12 my-2 info-panel' dangerouslySetInnerHTML={{ __html: t('project.invite_to_client_portal_suggestion') }} />
      </Stack>
      <AgGridWrapper
        enableBrowserTooltips={true}
        ref={gridApiRef}
        rowHeight={50}
        paginationPageSize={50}
        // datasource={dataSource}
        columnDefs={colDefs}
        scrollbarWidth={12}
        rowSelection='multiple'
        onGridReady={onGridReady}
        defaultColDef={{
          minWidth: 100,
          resizable: true,
          autoSizeStrategy: 'fitGridWidth',
          suppressMenu: true
        }}
        components={{
          CustomLoadingOverlayComponent
        }}
        loadingOverlayComponent={'CustomLoadingOverlayComponent'}
        getRowNodeId={data => data.id}
        blockLoadDebounceMillis={SCROLL_TIMEOUT}
        suppressHorizontalScroll={false}
        rowModelType='infinite'
        rowGroupPanelShow='never'
        showFilters={false}
        showSidebar={false}
      />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert.open} onClose={() => setAlert({ ...alert, open: false })} message={alert.message} key={'top-center'} />
      <Dialog
        open={showInviteDialog}
        onClose={() => {
          setShowInviteDialog(false);
          setSelectedEmail(null);
        }}
      >
        <DialogTitle>Select Email</DialogTitle>
        <DialogContent dividers>
          <CustomDropdown label='Email' required options={clientEmails} onInputChange={(e, v) => setSelectedEmail(v)} style={{ width: '350px' }} />
          <CustomDropdown
            label={t('reports.language')}
            options={languageData?.data ? languageData?.data : []}
            // value={language}
            onChange={(e, v) => setLanguage(v?.field_value ? v.field_value : '')}
            required
            className={'w-100 mt-2'}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowInviteDialog(false);
            }}
            variant='text'
            color='error'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSendInvite();
            }}
            disabled={!selectedEmail || sendInviteButton.disabled || language.length == 0}
            variant='contained'
            color='primary'
          >
            {sendInviteButton.text}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InviteToClientPortal;
