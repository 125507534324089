import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { picklistsDataApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const MultiSelectContactStatus = ({ name = '', onChange = () => {}, disabled = false, label = 'Select Status', defaultValues = [], ...rest }) => {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        const { data: response } = await picklistsDataApis(GET, 'CONTACT_STATUS');

        setOptions(response);
        if (defaultValues.length > 0 && response?.length > 0) {
          const defaultSelected = response.filter(option => defaultValues.includes(option.field_value));
          setSelectedValues(defaultSelected);
        }
      } catch (error) {
        console.error('Error fetching status options:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (event, value) => {
    setSelectedValues(value);
    onChange(event, value);
  };

  return (
    <CustomDropdown
      {...rest}
      name={name}
      label={label}
      options={options}
      value={selectedValues}
      onChange={handleChange}
      disabled={disabled || isLoading}
      multiple={true}
      isCheckBox={true}
      disableCloseOnSelect={true}
    />
  );
};

MultiSelectContactStatus.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  defaultValues: PropTypes.arrayOf(PropTypes.string)
};

export default MultiSelectContactStatus;
