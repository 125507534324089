//----------------------------------------------// In-built Imports // -------------------------------------------------
import * as React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

/**
 * *Note
 * options props can be [{ short_desc: '' }, { short_desc: 'Delete' }]
 * or
 * [{ short_desc: '', onClick: ()=>{} }, { short_desc: 'Delete', onClick: ()=>{}]
 * If the option has onClick function, it will be executed or it will fallback to handleMenuItemClick function
 */

export default function CustomSelectionComponent(props) {
  const { options, handleMenuItemClick, component, anchorEl, setAnchorEl } = props;
  const open = Boolean(anchorEl);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = option => {
    if (option.onClick) {
      option.onClick();
      setAnchorEl(null);
      return;
    }
    handleMenuItemClick(option);
  };

  return (
    <div id='pop-up-on-click' className='contact-action-cell'>
      <List component='nav' aria-label='Device settings' className='p-0'>
        <ListItem id='lock-button' aria-haspopup='listbox' aria-controls='lock-menu' aria-expanded={open ? 'true' : undefined} onClick={handleClickListItem} className='p-0'>
          {component}
        </ListItem>
      </List>
      {
        <Menu
          id='lock-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox'
          }}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem
                key={index}
                //selected={index === selectedIndex}
                onClick={() => handleMenuClick(option)}
                className='fs-14'
              >
                {option?.short_desc}
              </MenuItem>
            ))}
        </Menu>
      }
    </div>
  );
}

CustomSelectionComponent.propTypes = {
  options: PropTypes.array,
  handleMenuItemClick: PropTypes.func,
  component: PropTypes.func,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func
};
