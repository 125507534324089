import { Button, Checkbox, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ValidatePermit from '../../security/ValidatePermit';
import { getUserSelector } from '../../selectors';
import { CandidateStageSetupAPi, contactDocumentApi, getMethodWithCancelTokenApi, ignCompanyDataApi, ignContactDataApi } from '../../services/ApiService';
import { DELETE, GET, IGN_API, PATCH, POST } from '../../services/constantService';
import { ensurePrimaryExists } from './component';
import ContactDrawerUI from './ContactDrawerUI';
function EditContactDrawer(props) {
  const { contactId, contactDetails, callback } = props;
  // -----------------------------------------------------------------------------------------------------------------------------
  const [jobFunctionList, setJobFunctionList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectStageList, setProjectStageList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companyNameList, setCompanyNameList] = useState([]);
  const [levelOfEducationList, setLevelOfEducationList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [loadingDropdownData, setLoadingDropdownData] = useState(false);
  const userData = useSelector(getUserSelector);
  const [contactData, setContactData] = useState({});
  const [recordTypeDropdownOptions, setRecordTypeDropdownOptions] = useState([]);

  const [phoneTypeList, setPhoneTypeList] = useState([]);
  const [emailTypeList, setEmailTypeList] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [RelationTypeList, setRelationTypeList] = useState([]);
  const [levelOfProficiencyList, setLevelOfProficiencyList] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [certificationTypeList, setCertificationTypeList] = useState([]);
  const getDropdownData = async type => {
    const url = `${IGN_API.picklists}/${type}`;
    //   if (!openDrawer) return [];
    const res = await getMethodWithCancelTokenApi(
      url,
      {
        limit: 10000
      },
      {}
    ).then(response => {
      const { status, data } = response;
      if (status === 200) {
        if (type === 'project?includeBd=true') {
          const list = data?.data;
          setProjectList(list);
        } else if (type === 'industries-picklist') {
          const list = data;
          setIndustryList(list);
        } else if (type === 'job-function-picklist') {
          const list = data;
          setJobFunctionList(list);
        } else if (type === 'countries') {
          const list = data?.data;
          setCountryList(list);
          setNationalityList(list);
        } else if (type === 'companies-picklist') {
          const list = data?.data;
          setCompanyNameList(list);
        } else if (type === 'countries/states') {
          return data;
        } else if (type === 'degree-picklist?name=') {
          const list = data?.data;
          setLevelOfEducationList(list);
        } else if (type === 'languages-picklist?name=') {
          const list = data?.data;
          setLanguageList(list);
        } else if (type === 'translate-values/RECORD_TYPE') {
          const list = data;
          setRecordTypeDropdownOptions(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/PHONE_TYPE') {
          const list = data;
          setPhoneTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/EMAIL_TYPE') {
          const list = data;
          setEmailTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/ADDRESS_TYPE') {
          const list = data;
          setAddressTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/RELATION_TYPE') {
          const list = data;
          setRelationTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/LEVEL_OF_EXPERTISE') {
          const list = data;
          setLevelOfProficiencyList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/CERTIFICATION_TYPE') {
          const list = data;
          setCertificationTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else {
          return { status, data };
        }
      }
    });
    return res?.data;
  };
  const fetchCandidateStages = async () => {
    const res = await CandidateStageSetupAPi(GET, '/all?showLogicalStages=true');
    if (res.status === 200) {
      const list = res.data?.map(item => ({ name: item.stage_label, id: item.stage_name }));
      setProjectStageList(list);
    }
  };
  const getAllDropdownData = async () => {
    setLoadingDropdownData(true);

    const dropdownPromises = [];
    dropdownPromises.push(getDropdownData('translate-values/PHONE_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/EMAIL_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/ADDRESS_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/RELATION_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/CERTIFICATION_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/LEVEL_OF_EXPERTISE'));
    dropdownPromises.push(getDropdownData('translate-values/RECORD_TYPE'));
    dropdownPromises.push(getDropdownData('industries-picklist'));
    dropdownPromises.push(getDropdownData('job-function-picklist'));
    dropdownPromises.push(getDropdownData('languages-picklist?name='));
    dropdownPromises.push(fetchCandidateStages());
    // * Note From Arun: Not being used while displaying contact from Contact Page
    // dropdownPromises.push(getDropdownData('companies-picklist'));
    // dropdownPromises.push(getDropdownData('project?includeBd=true&limit=20'));

    await Promise.allSettled(dropdownPromises);
    setLoadingDropdownData(false);
  };

  const handleContactLoad = async existingData => {
    setGlobalLoading(true);
    await getAllDropdownData();
    await getContactDetails(existingData);
    setGlobalLoading(false);
  };
  useEffect(() => {
    handleContactLoad(contactDetails);
  }, [contactId]);
  const prepareDate = date => {
    return date ? dayjs(date).startOf('day').utc(true).format() : null;
  };
  const setContactDataForAPI = async values => {
    // Transform work experience entries
    const createdCompanies = [];

    const workExperienceResults = values?.contact_work_experience
      ? await Promise.allSettled(
          values?.contact_work_experience?.map(async item => {
            const company = item.company;
            delete item.company;
            if (!item?.exists) {
              delete item.id;
            }
            try {
              if (company.exists || company.id) {
                return {
                  ...item,
                  start_date: prepareDate(item?.start_date),
                  end_date: prepareDate(item?.end_date),
                  board_start_date: prepareDate(item?.board_start_date),
                  board_end_date: prepareDate(item?.board_end_date),
                  company_id: company.id,
                  country_id: item?.country?.id || null,
                  state_id: item?.state?.id || null,
                  contact_id: contactId,
                  is_board_member: item?.is_board_member || false,
                  board_is_present: dayjs(item?.board_end_date).isAfter(dayjs()) || (!item?.board_is_present && item?.is_board_member) ? true : false
                };
              } else {
                const res = await ignCompanyDataApi(POST, {
                  name: company.name,
                  comments: '',
                  company_tags: [],
                  company_industries: [],
                  company_status: 'active'
                });
                createdCompanies.push({
                  id: res.data.id,
                  name: company.name
                });

                return {
                  ...item,
                  company_id: res.data.id || createdCompanies.find(item => item.name === company.name).id,
                  start_date: prepareDate(item?.start_date),
                  end_date: prepareDate(item?.end_date),
                  board_start_date: prepareDate(item?.board_start_date),
                  board_end_date: prepareDate(item?.board_end_date),
                  country_id: item?.country?.id || null,
                  state_id: item?.state?.id || null,
                  contact_id: contactId,
                  is_board_member: item?.is_board_member || false,
                  board_is_present: dayjs(item?.board_end_date).isAfter(dayjs()) || (!item?.board_is_present && item?.is_board_member) ? true : false
                };
              }
            } catch (error) {
              console.error('Error processing work experience:', error);
              throw error;
            }
          })
        )
      : [];

    const transformedWorkExperience = workExperienceResults?.filter(result => result.status === 'fulfilled').map(result => result.value);
    const transformedEducation = values?.education?.map(item => {
      if (!item?.exists) {
        delete item.id;
      }
      return {
        ...item,

        country_id: item?.country?.id || item?.country?.name,
        school_name: item.school_name,
        degree_name: item.degree_name,
        start_date: prepareDate(item.start_date),
        end_date: prepareDate(item.end_date),
        is_present: item.is_present,
        contact_id: contactId
      };
    });
    const transformedCertificationsAndLicenses = values?.certifications_and_licenses?.map(item => {
      if (!item?.exists) {
        delete item.id;
      }
      return {
        ...item,
        certification_type: item?.type?.id,
        certifications: item.title,
        expire_on: prepareDate(item.expires),
        contact_id: contactId
      };
    });
    const transformedLanguages = values?.contact_languages
      ? await Promise.allSettled(
          values?.contact_languages?.map(async item => {
            if (!item?.exists) {
              delete item.id;
            }
            if (!item.contact_languages.id) {
              const findLanguage = languageList.find(language => language.name === item.contact_languages.name);
              if (findLanguage) {
                item.contact_languages.id = findLanguage.id;
              }
            }
            return {
              ...item,
              language_id: item.contact_languages.id || '',
              expertise: item.level_of_expertise.id || '',
              contact_id: contactId
            };
          })
        )
      : [];
    const transformedLanguagesRes = transformedLanguages?.filter(result => result.status === 'fulfilled').map(result => result.value);
    const contactPhone = values?.contact_phones?.map(item => {
      if (item.edit) return;
      if (!item?.exists) {
        delete item.id;
      }
      delete item.edit;
      return {
        ...item,
        phone_type: item?.phone_type?.id || '',
        phone_number: item.phone_number,
        is_primary: item?.is_primary || false,
        contact_id: contactId
      };
    });
    const contactEmail = values?.contact_emails?.map(item => {
      if (item.edit) return;
      if (!item?.exists) {
        delete item.id;
      }
      delete item.edit;
      return {
        ...item,
        email_type: item?.email_type?.id || '',
        email: item?.email,
        is_primary: item?.is_primary || false,
        contact_id: contactId
      };
    });
    const transformedContactAddress = values?.contact_address?.map(item => {
      if (item.edit) return;
      delete item.edit;
      if (!item?.exists) {
        delete item.id;
      }
      return {
        ...item,
        address_type: item?.address_type?.id || '',
        address: item?.address_lines || '',
        metropolitan_area: item?.metropolitan_area?.description || '',
        address_lines: item?.address_lines || '',
        city: item?.city || '',
        state_id: item?.state?.id || null,
        country_id: item?.country?.id || null,
        zip_code: item?.zip_code || '',
        is_primary: item?.is_primary || false,
        contact_id: contactId
      };
    });
    const transformJobFunction = values?.contact_job_functions?.map(item => {
      delete item.edit;
      return {
        ...item,
        job_function_id: item?.id,
        contact_id: contactId
      };
    });
    const transformIndustry = values?.contact_industries?.map(item => {
      return {
        ...item,
        industry_id: item?.id,
        contact_id: contactId
      };
    });
    //-------------------------------------------------------------------------------------------------
    const transformRelationShip = values?.contact_relationship_type?.map(item => {
      if (!item?.exists) {
        delete item.id;
      }
      return {
        ...item,
        type: item?.relation_type?.id || '',
        ref_contact_id: item?.contact?.id || '',
        contact_ref_id: item?.contact?.id || '',
        contact_id: contactId
      };
    });
    //-------------------------------------------------------------------------------------------------

    const transformRecordType = values?.record_type?.map(item => {
      return {
        // ...item,
        type: item.name
        // contact_id: contactId
      };
    });
    // Process the results and handle any failures

    // Return the complete transformed data
    delete values.contact_languages;
    delete values.certifications_and_licenses;
    delete values.education;
    delete values.contact_work_experience;
    delete values.contact_emails;
    delete values.contact_phones;
    delete values.contact_job_functions;
    delete values.contact_industries;
    delete values.contact_relationship_type;

    return {
      ...values,
      linkedin_url: values?.linkedin_url || null,
      birth_date: prepareDate(values?.birth_date),
      contact_work_experience: transformedWorkExperience || [],
      contact_education_details: transformedEducation || [],
      contact_certificate: transformedCertificationsAndLicenses || [],
      contact_languages: transformedLanguagesRes || [],
      contact_phones: ensurePrimaryExists(contactPhone || []),
      contact_emails: ensurePrimaryExists(contactEmail || []),
      contact_address: ensurePrimaryExists(transformedContactAddress || []),
      contact_job_functions: transformJobFunction || [],
      contact_industries: transformIndustry || [],
      contact_relationship: transformRelationShip || [],
      contact_relationship_type: transformRelationShip || [],
      contact_record_types: transformRecordType || [],
      nationality: values?.nationality?.id || null,
      updated_by: userData?.id || null
    };
  };
  const removeEmptyFields = values => {
    Object.keys(values).forEach(key => {
      if (values[key] === null || values[key] === undefined || values[key] === '') {
        if (key !== 'linkedin_url') {
          // delete values[key];
        }
      }
    });
    if (values?.contact_address && values?.contact_address?.length === 0) {
      delete values.contact_address;
    }
    return values;
  };
  const getToDeleteFields = (obj, initialObj) => {
    let newObj = {};
    const updatedObj = {
      record_type: obj.record_type || [],
      contact_relationship: obj.contact_relationship || [],
      contact_relationship_type: obj.contact_relationship_type || [],
      contact_job_functions: obj.contact_job_functions || [],
      contact_industries: obj.contact_industries || [],
      contact_languages: obj.contact_languages || [],
      contact_phones: obj.contact_phones || [],
      contact_emails: obj.contact_emails || [],
      contact_address: obj.contact_address || []
    };
    const toValidateStringFields = ['highlights', 'birth_date', 'gender', 'additional_last_name', 'pronouns', 'is_confidential'];
    const toCheck = [
      {
        initialObj: 'contact_work_experience',
        obj: 'contact_work_experience',
        idKey: 'id'
      },
      {
        initialObj: 'education',
        obj: 'contact_education_details',
        idKey: 'id'
      }
    ];
    console.log('initialObj', initialObj, obj);
    //------------------------------------String Fields-------------------------------------------------------------

    for (const key of toValidateStringFields) {
      if (obj[key] && initialObj[key]) {
        if (obj[key] !== initialObj[key]) {
          updatedObj[key] = obj[key];
        } else {
          delete obj[key];
        }
      }
    }
    //------------------------------------Object Fields-------------------------------------------------------------
    for (const i of toCheck) {
      if (initialObj[i.initialObj] && obj[i.obj]) {
        // find which key is not present in the initialObj

        const deleteItems = [];
        for (const item of initialObj[i.initialObj]) {
          // if item is not present in the initialObj then add it to the newObj
          if (!obj[i.obj].some(item2 => item2[i.idKey] === item[i.idKey])) {
            deleteItems.push(item);
          }
        }
        newObj[i.obj] = deleteItems;
      }
    }
    if (initialObj['record_type'] && obj['contact_record_types']) {
      // find which key is not present in the initialObj

      const deleteItems = [];
      for (const item of initialObj['record_type']) {
        // if item is not present in the initialObj then add it to the newObj
        if (!obj['contact_record_types'].some(item2 => item2['type'] === item['name'])) {
          deleteItems.push(item);
        }
      }
    }
    //------------------------------------Object Fields-------------------------------------------------------------
    return {
      toDelete: newObj,
      toAdd: {
        ...obj,
        linkedin_url: obj?.linkedin_url || null,
        force: true,
        ...updatedObj
      }
    };
  };
  const deleteContactDetails = async (id, type) => {
    const { status } = await contactDocumentApi(DELETE, id, null, null, null, type);
    if (status === 200) {
      return true;
    }
    return false;
  };
  const updateContact = async values => {
    try {
      // Transform the data
      setGlobalLoading(true);
      const transformedData = await setContactDataForAPI(values);
      const getDeleteFields = getToDeleteFields(transformedData, contactData, 'id');

      const res = await ignContactDataApi(PATCH, contactId, removeEmptyFields(getDeleteFields.toAdd), '');

      if (getDeleteFields?.toDelete?.contact_work_experience && getDeleteFields?.toDelete?.contact_work_experience?.length > 0) {
        for (const item of getDeleteFields.toDelete.contact_work_experience) {
          await deleteContactDetails(item?.id, 'work-experience');
        }
      }
      if (getDeleteFields?.toDelete?.contact_education_details && getDeleteFields?.toDelete?.contact_education_details?.length > 0) {
        for (const item of getDeleteFields.toDelete.contact_education_details) {
          await deleteContactDetails(item?.id, 'education-details');
        }
      }
      // // -------------------------------------------
      await getContactDetails();
      enqueueSnackbar(res?.data?.message, { variant: res?.status === 200 ? 'success' : 'error' });
      // await callback?.();
      setGlobalLoading(false);
      return res;
    } catch (error) {
      console.error('Error saving contact:', error);
      throw error;
    }
  };
  const toggleDrawer = event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };
  const getContactDetails = async existingData => {
    let res;
    if (existingData) {
      res = existingData;
    } else {
      const response = await ignContactDataApi(GET, contactId, '', '');
      res = response?.data;
    }

    const data = {
      linkedin_url: res?.linkedin_url,
      // linkedin_username: res?.['linkedin_url']?.split('/')[2] || '',
      first_name: res?.first_name || '',
      last_name: res?.last_name || '',
      highlights: res?.highlights || '',
      record_source: res?.source || '',
      education: res?.contact_education_details?.map((education, index) => ({
        id: education?.id || index + 1,
        degree_name: education?.degree_name,
        school_name: education?.school_name,
        country: education?.country,
        start_date: education?.start_date ? dayjs(education?.start_date) : null,
        end_date: education?.end_date ? dayjs(education?.end_date) : null,
        is_present: education?.end_date ? dayjs(education?.end_date).isAfter(dayjs()) : false,
        education_level: education,
        exists: true,
        edit: false
      })),
      certifications_and_licenses: res?.contact_certificate?.map((certification, index) => ({
        id: certification?.id || index + 1,
        type: {
          name: certification?.certification_type,
          id: certification?.certification_type
        },
        title: certification?.certifications,
        expires: dayjs(certification?.expire_on),
        edit: false,
        exists: true
      })),
      contact_languages: res?.contact_languages?.map(item => ({
        id: item?.id,
        contact_languages: item?.language,
        level_of_expertise: {
          name: item?.expertise,
          id: item?.expertise
        },
        edit: false,
        exists: true
      })),
      contact_phones: res?.contact_phones?.map(item => ({
        id: item?.id,
        phone_type: phoneTypeList.find(i => i.id === item?.phone_type) || {
          id: item?.phone_type,
          name: item?.phone_type
        },
        phone_number: item?.phone_number,
        is_primary: item?.is_primary || false,
        edit: false,
        exists: true
      })),
      contact_emails: res?.contact_emails?.map(item => ({
        id: item?.id,
        email_type: emailTypeList.find(i => i.id === item?.email_type) || {
          id: item?.email_type,
          name: item?.email_type
        },
        email: item?.email,
        is_primary: item?.is_primary || false,
        edit: false,
        exists: true
      })),
      contact_address: res?.contact_address?.map(i => ({
        id: i?.id,
        address_type: addressTypeList.find(item => item.id === i?.address_type) || {
          id: i?.address_type,
          name: i?.address_type
        },
        metropolitan_area: {
          description: i?.metropolitan_area
        },
        address_lines: i?.address_lines,
        city: i?.city,
        state: i?.contact_state,
        country: i?.contact_country,
        zip_code: i?.zip_code,
        is_primary: i?.is_primary || false,
        edit: false,
        exists: true
      })),
      contact_work_experience: res?.contact_work_experience?.map((item, index) => ({
        id: item?.id || index + 1,
        title: item?.title,
        company: item?.company,
        country: item?.country,
        state: item?.state,
        city: item?.city,
        start_date: dayjs(item?.start_date).isValid() ? dayjs(item?.start_date) : null,
        end_date: dayjs(item?.end_date).isValid() ? dayjs(item?.end_date) : null,
        is_present: item?.is_present,
        board_start_date: dayjs(item?.board_start_date).isValid() ? dayjs(item?.board_start_date) : null,
        board_end_date: dayjs(item?.board_end_date).isValid() ? dayjs(item?.board_end_date) : null,
        board_is_present: item?.board_is_present,
        is_board_member: item?.is_board_member,
        board_committee: item?.board_committee,
        edit: false,
        exists: true,
        description: item?.description
      })),
      record_type: res?.contact_record_types?.map(item => ({
        id: item?.id,
        name: item?.type,
        edit: false,
        exists: true
      })),
      contact_job_functions: res?.contact_job_functions?.map(item => ({
        id: item?.job_functions?.id,
        name: item?.job_functions?.name,
        edit: false,
        exists: true
      })),
      contact_industries: res?.contact_industries?.map(item => ({
        id: item?.industries?.id,
        name: item?.industries?.name,
        edit: false,
        exists: true
      })),
      birth_date: dayjs(res?.birth_date).isValid() ? dayjs(res?.birth_date) : undefined, // idk how it works but it works so don't touch it please
      gender: res?.gender,
      nationality: res?.country || null,
      additional_last_name: res?.additional_last_name,
      pronouns: res?.pronouns,
      is_confidential: res?.is_confidential,
      contact_relationship_type: res?.contact_relationship_type?.map(item => ({
        id: item?.id,
        relation_type: RelationTypeList.find(i => i.id === item.type) || {
          id: item?.type || '',
          name: item?.type || ''
        },
        contact: item?.contact_data,
        edit: false,
        exists: true
      }))
    };
    setContactData(data);
  };

  const contactDrawerProps = {
    includeHeader: false,
    includeSubHeader: false,
    CustomHeader,
    recordTypeDropdownOptions,
    projectList,
    projectStageList,
    countryList,
    companyNameList,
    levelOfEducationList,
    certificationTypeList,
    phoneTypeList,
    emailTypeList,
    addressTypeList,
    languageList,
    levelOfProficiencyList,
    jobFunctionList,
    industryList,
    nationalityList,
    getDropdownData,
    RelationTypeList,
    loadingDropdownData,
    saveContact: updateContact,
    toggleDrawer,
    customHeaderHeight: '56px',
    sectionVisibility: {
      sectionOne: true,
      sectionTwo: false,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true,
      sectionNine: true,
      sectionTen: true,
      sectionEleven: true,
      sectionTwelve: true
    },
    type: 'edit',
    contactData,
    globalLoading,
    callback
  };

  return (
    <Stack
      sx={{
        width: '100%',
        position: 'relative',
        height: '100%',
        // overflowY: 'scroll',
        bgcolor: 'background.main',
        // smooth scroll
        scrollBehavior: 'smooth'
      }}
    >
      <ValidatePermit parent={'contact'} type='btn-disabled' permissionType={'update'} sx={{ height: 'calc(100vh - 300px)' }} showReadOnlyInfo={true}>
        <ContactDrawerUI {...contactDrawerProps} />
      </ValidatePermit>
    </Stack>
  );
}
function CustomHeader(props) {
  const { expandAll, collapseAll, onExpandAll, onCollapseAll, allExpanded, allCollapsed, onSave, globalLoading } = props;
  const { t } = useTranslation();
  return (
    <Stack
      direction={'row'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      width={'100%'}
      sx={{
        paddingX: '16px',
        paddingY: '8px',
        bgcolor: 'background.white',
        borderBottom: '1px solid',
        borderColor: 'background.main'
      }}
      gap={'20px'}
    >
      <Stack direction={'row'} alignItems={'center'} onClick={() => onExpandAll()}>
        <Checkbox checked={allExpanded} onClick={() => onExpandAll()} />
        <Typography className='fs-14'>{expandAll}</Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} onClick={() => onCollapseAll()}>
        <Checkbox checked={allCollapsed} onClick={() => onCollapseAll()} />
        <Typography className='fs-14'>{collapseAll}</Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'center'}>
        <Button variant='contained' onClick={onSave} disabled={globalLoading}>
          {t('Save')}
        </Button>
      </Stack>
    </Stack>
  );
}
EditContactDrawer.propTypes = {
  contactId: PropTypes.string.isRequired,
  callback: PropTypes.func,
  contactDetails: PropTypes.object
};
CustomHeader.propTypes = {
  uploadResumeTitle: PropTypes.string,
  linkedinURLTitle: PropTypes.string,
  getProfileData: PropTypes.string,
  expandAll: PropTypes.string,
  collapseAll: PropTypes.string,
  onExpandAll: PropTypes.func,
  onCollapseAll: PropTypes.func,
  onGetProfileData: PropTypes.func,
  allExpanded: PropTypes.bool,
  allCollapsed: PropTypes.bool,
  onOpenCreateContactFromResume: PropTypes.func,
  onAddFromLinkedin: PropTypes.func,
  onSave: PropTypes.func,
  globalLoading: PropTypes.bool,
  callback: PropTypes.func
};
export default EditContactDrawer;
