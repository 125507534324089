import { CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import CustomConfirmationPopup from '../../../../components/common/CustomConfirmationPopup';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import { picklistsDataApis, profileLabel } from '../../../../services/ApiService';
import { ERROR, GET, PICKLISTS, POST, PUT } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import './index.scss';

function LabelModalWithHeader({ modalTitle = 'Add label', closeIcon = true, isOpen, onClose, maxWidth = 'md', item, competencySubCategory, setColumns, projectId, profileCategory }) {
  const { t } = useTranslation();

  let profileCategoryTypes = profileCategory || [];
  if (profileCategoryTypes.length === 0) {
    const { data } = useApi({
      queryFn: () => {
        return picklistsDataApis(GET, PICKLISTS.PROFILE_CATEGORY);
      }
    });
    profileCategoryTypes = data?.data;
  }
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    label_text: item?.label_text || '',
    label_category: item?.label_category_value?.field_value || '',
    label_subtype: item?.label_subtype || '',
    description: item?.description || '',
    interview_question: item?.interview_question || '',
    status: item?.status === 'YES' ? 'YES' : 'NO',
    label_type: item?.label_type?.toLowerCase() || 'binary',
    label_requirement: item?.label_requirement?.toLowerCase() || 'minimal'
  });
  const [showTypeConfirmation, setShowTypeConfirmation] = useState(false);
  const [pendingTypeChange, setPendingTypeChange] = useState(null);

  // Update formData when item changes
  useEffect(() => {
    if (item) {
      setFormData({
        label_text: item.label_text || '',
        label_category: item.label_category_value?.field_value || item.label_category || '',
        label_subtype: item.label_subtype || '',
        description: item.description || '',
        interview_question: item.interview_question || '',
        status: item.status === 'YES' ? 'YES' : 'NO',
        label_type: item?.label_type?.toLowerCase() || 'binary',
        label_requirement: item?.label_requirement?.toLowerCase() || 'minimal'
      });
    }
  }, [item]);

  const [errors, setErrors] = useState({
    label_text: '',
    label_category: '',
    label_subtype: '',
    interview_question: ''
  });

  const handleChange = useCallback(
    field => event => {
      if (field === 'label_type' && item?.id) {
        setPendingTypeChange(event.target.value);
        setShowTypeConfirmation(true);
        return;
      }
      setFormData(prevData => ({
        ...prevData,
        [field]: field === 'status' ? (event.target.checked ? 'YES' : 'NO') : event.target.value
      }));
      // Clear error when user starts typing
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    },
    []
  );

  const handleTypeChangeConfirm = () => {
    setFormData(prevData => ({
      ...prevData,
      label_type: pendingTypeChange
    }));
    setShowTypeConfirmation(false);
    setPendingTypeChange(null);
  };

  const handleTypeChangeCancel = () => {
    setShowTypeConfirmation(false);
    setPendingTypeChange(null);
  };

  const handleSubcategoryChange = useCallback((event, newValue) => {
    setFormData(prevData => ({
      ...prevData,
      label_subtype: newValue?.name || ''
    }));
    setErrors(prev => ({
      ...prev,
      label_subtype: ''
    }));
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.label_text.trim()) {
      newErrors.label_text = 'Selected Text is required';
    }

    if (!formData.label_category) {
      newErrors.label_category = 'Type is required';
    }

    if (formData.label_category === 'competency' && !formData.label_subtype) {
      newErrors.label_subtype = 'Parent Label is required for Competency type';
    }

    if (!formData.interview_question.trim()) {
      newErrors.interview_question = 'Interview Questions is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async data => {
    if (!validateForm()) {
      enqueueSnackbar('Please fill in all required fields marked with *', { variant: ERROR });
      return;
    }

    setLoading(true);
    try {
      if (item?.id) {
        await profileLabel(PUT, `update/${item.id}`, {
          label_text: data.label_text,
          label_category: data.label_category,
          label_subtype: data.label_subtype,
          description: data.description,
          interview_question: data.interview_question,
          status: data.status,
          label_type: data.label_type,
          label_requirement: data.label_requirement
        });
        setColumns(prevColumns => {
          const updatedColumns = { ...prevColumns };
          const category = data.label_category?.toLowerCase() || '';

          // Remove item from all columns first
          Object.keys(updatedColumns).forEach(columnId => {
            updatedColumns[columnId] = updatedColumns[columnId].filter(label => label.id !== item.id);
          });

          // Add updated item to appropriate column
          if (category.includes('experience')) {
            updatedColumns.experience.push({ ...item, ...data });
          } else if (category.includes('competency')) {
            updatedColumns.competency.push({ ...item, ...data });
          } else {
            updatedColumns.uncategorized.push({ ...item, ...data });
          }

          return updatedColumns;
        });
      } else {
        // api call to add new label
        const { data: response } = await profileLabel(POST, null, {
          project_id: projectId,
          label_text: data.label_text,
          label_category: data.label_category,
          label_subtype: data.label_subtype,
          description: data.description,
          interview_question: data.interview_question,
          status: data.status,
          label_type: data.label_type,
          label_requirement: data.label_requirement
        });
        setColumns(prevColumns => {
          const updatedColumns = { ...prevColumns };
          const category = data.label_category?.toLowerCase() || '';
          const newItem = {
            id: response.id,
            label_text: data.label_text,
            label_category: data.label_category,
            label_subtype: data.label_subtype,
            description: data.description,
            interview_question: data.interview_question,
            status: data.status,
            label_type: data.label_type,
            label_requirement: data.label_requirement
          };

          if (category.includes('experience')) {
            updatedColumns.experience.push(newItem);
          } else if (category.includes('competency')) {
            updatedColumns.competency.push(newItem);
          } else {
            updatedColumns.uncategorized.push(newItem);
          }

          return updatedColumns;
        });
      }
      setFormData({
        label_text: '',
        label_category: '',
        label_subtype: '',
        description: '',
        interview_question: '',
        status: 'NO',
        label_type: 'binary',
        label_requirement: 'minimal'
      });
      onClose();
    } catch (error) {
      console.error('Error adding label:', error);
      enqueueSnackbar('Error adding label', { variant: ERROR });
      return;
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <CustomModalWithHeader
        label={modalTitle}
        closeIcon={closeIcon}
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={maxWidth}
        className='label-modal'
        headerClass={'modal-header-background p-2 rounded-top mb-0'}
        style={{ p: 0 }}
      >
        {loading ? (
          <div className='label-form__loading'>
            <Typography variant='body2' className='label-form__loading-text'>
              {item?.id ? 'Updating Label' : 'Adding Label'}
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <div className='label-form'>
            <TextField
              required
              fullWidth
              size='small'
              label='Selected Text'
              value={formData.label_text}
              onChange={handleChange('label_text')}
              className='label-form__field'
              error={!!errors.label_text}
              helperText={errors.label_text}
            />

            <CustomDropdown
              options={profileCategoryTypes}
              label='Type'
              value={profileCategoryTypes?.find(option => option.field_value === formData.label_category) || null}
              onChange={(e, newValue) => {
                setFormData(prev => ({
                  ...prev,
                  label_category: newValue?.field_value || '',
                  label_subtype: !newValue?.field_value?.toLowerCase().includes('competency') ? '' : prev.label_subtype
                }));
                setErrors(prev => ({
                  ...prev,
                  label_category: '',
                  label_subtype: ''
                }));
              }}
              className='label-form__field'
              required
              error={!!errors.label_category}
              helperText={errors.label_category}
            />

            <CustomDropdown
              options={competencySubCategory}
              label='Parent Label'
              value={formData.label_subtype}
              onChange={handleSubcategoryChange}
              className='label-form__field'
              disabled={formData?.label_category?.toLowerCase() !== 'competency'}
              required={formData?.label_category === 'competency'}
              error={!!errors.label_subtype}
              helperText={errors.label_subtype}
            />

            <TextField
              label='Description'
              placeholder='Description'
              fullWidth
              size='small'
              multiline
              value={formData.description}
              onChange={handleChange('description')}
              className='label-form__field'
            />

            <TextField
              label='Interview Questions'
              placeholder='Interview Questions'
              required
              fullWidth
              size='small'
              multiline
              value={formData.interview_question}
              onChange={handleChange('interview_question')}
              className='label-form__field'
              error={!!errors.interview_question}
              helperText={errors.interview_question}
            />

            <div className='form-group'>
              <div className='form-group__label-container'>
                <FormLabel component='legend' className='form-group__label'>
                  Status:
                </FormLabel>
              </div>
              <div className='form-group__controls-container'>
                <FormControlLabel
                  control={<Switch checked={formData.status === 'YES'} onChange={handleChange('status')} color='primary' size='small' />}
                  label={formData.status ? 'Active' : 'Inactive'}
                  classes={{ label: 'form-control__label' }}
                />
              </div>
            </div>

            <div className='form-group'>
              <div className='form-group__label-container'>
                <FormLabel component='legend' className='form-group__label'>
                  Type:
                </FormLabel>
              </div>
              <div className='form-group__controls-container'>
                <RadioGroup row value={formData.label_type} onChange={handleChange('label_type')}>
                  <FormControlLabel value='graded' control={<Radio size='small' />} label='Graded' classes={{ label: 'form-control__label' }} />
                  <FormControlLabel value='binary' control={<Radio size='small' />} label='Binary' classes={{ label: 'form-control__label' }} />
                </RadioGroup>
              </div>
            </div>

            <div className='form-group'>
              <div className='form-group__label-container'>
                <FormLabel component='legend' className='form-group__label'>
                  Requirement:
                </FormLabel>
              </div>
              <div className='form-group__controls-container'>
                <RadioGroup row value={formData.label_requirement} onChange={handleChange('label_requirement')}>
                  <FormControlLabel value='ideal' control={<Radio size='small' />} label='Ideal' classes={{ label: 'form-control__label' }} />
                  <FormControlLabel value='minimal' control={<Radio size='small' />} label='Minimum' classes={{ label: 'form-control__label' }} />
                </RadioGroup>
              </div>
            </div>

            <CustomButton fullWidth buttonText={modalTitle || 'Add label'} size='small' onClick={() => handleSubmit(formData)} />
          </div>
        )}
      </CustomModalWithHeader>
      <CustomConfirmationPopup
        open={showTypeConfirmation}
        onClose={handleTypeChangeCancel}
        type={`${t('utils.changingType')}`}
        cancelText={`${t('actions.cancel')}`}
        confirmText={`${t('actions.ok')}`}
        onConfirm={handleTypeChangeConfirm}
        customMessage={`${t('utils.sureToChangeType')}`}
        setOpen={setShowTypeConfirmation}
      />
    </>
  );
}

LabelModalWithHeader.propTypes = {
  modalTitle: PropTypes.string,
  closeIcon: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  item: PropTypes.object,
  competencySubCategory: PropTypes.array,
  setColumns: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  profileCategory: PropTypes.array
};

export default LabelModalWithHeader;
