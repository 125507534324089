import { Box, Button, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { validateComponentPermission } from './utilsPermission';
import { userSecuritySelector } from '../selectors';

/**
 * ValidatePermit component renders its children based on the provided permissions.
 *
 * @param {Object} props The component properties
 * @param {React.ReactNode} props.children The content to render if permission is granted
 * @param {('home'|'project'|'contact'|'company'|'job_portal'|'setup'|'ignyte_intelligence')} [props.parent=''] The parent path to validate
 * @param {string} [props.path=''] The permission path to validate (skip this if you want to validate the parent only)
 * @param {string} [props.permissionType='read'] The permission type to validate. Can include multiple types (e.g. 'read update delete')
 * @param {('hide'|'btn-disabled'|'overlay'|'custom'|'message-only')} [props.type='hide'] The type of permission validation:
 * - hide: Hides the component
 * - btn-disabled: Disables the button
 * - overlay: Shows an overlay with a message
 * - custom: Renders a custom component
 * - message-only: Shows only the error message
 * @param {string} [props.errorMessage] The message to display when permission is denied
 * @param {React.ReactNode} [props.custom] Custom component to render if permission is denied and type is 'custom'
 * @param {Object} [props.props] Additional props to pass to the Box component
 * @param {Object} [props.sx] Styles to apply to the Box component
 * @param {number} [props.opacity] Opacity to apply to the overlay when type is 'overlay'
 * @param {boolean} [props.redirectToInvalid=false] Whether to redirect to the invalid page (use only for root)
 * @param {boolean} [props.showPermissionInfo=true] Whether to show permission info (shows a small message following cursor)
 * @param {boolean} [props.showReadOnlyInfo=false] Whether to show read-only info (shows a small message following cursor)
 * @param {string} [props.readOnlyMessage='Read Only'] The message to display for read-only content
 * @returns {React.ReactNode} The rendered component
 */
function ValidatePermit({
  children,
  root = false,
  parent = '',
  path = '',
  type = 'hide',
  errorMessage = 'You do not have permission to view/edit this content. Please contact your administrator.',
  redirectToInvalid = false,
  custom,
  opacity,
  permissionType,
  showPermissionInfo = true,
  showReadOnlyInfo = false,
  readOnlyMessage = 'Read Only',
  ...props
}) {
  const permission = useSelector(state => state.componentPermissionSlice); // Redux state for permissions
  const appSecureList = useSelector(userSecuritySelector); // Redux state for appSecureList
  if (redirectToInvalid && !validate()) {
    return <></>;
  }
  const validate = () => {
    if (permission.useSecurity && parent !== '') {
      return validateComponentPermission(appSecureList || {}, parent, path, permissionType, root);
    }
    return true;
  };
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });

  // Add mouse move handler
  React.useEffect(() => {
    const handleMouseMove = e => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    if (showReadOnlyInfo) {
      window.addEventListener('mousemove', handleMouseMove);
      return () => window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [showReadOnlyInfo]);
  return validate() ? (
    <>{children}</>
  ) : (
    <>
      {type === 'message-only' ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            textAlign: 'center',
            fontSize: '14px',
            minHeight: '24px',
            ...(props && props.sx)
          }}
          backgroundColor='titleBarBackground.main'
          {...props}
        >
          <Typography variant='p' className='fs-12'>
            {errorMessage}
          </Typography>
          {showPermissionInfo && (
            <Typography variant='p' className='fs-12'>
              Required Permission(s): {parent} {path} &gt; {permissionType}
            </Typography>
          )}
        </Box>
      ) : type === 'btn-disabled' ? (
        <>
          <Button variant={'text'} disabled={true} {...props} sx={{ opacity: 0.9 }}>
            {children}
            {showReadOnlyInfo && (
              <Box
                sx={{
                  position: 'fixed',
                  left: mousePosition.x + 20,
                  top: mousePosition.y + 20,
                  backgroundColor: 'error.main',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  pointerEvents: 'none',
                  zIndex: 1000000000
                }}
              >
                {readOnlyMessage}
              </Box>
            )}
          </Button>
        </>
      ) : type === 'hide' ? null : type === 'overlay' ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',

            ...(props && props?.sx)
          }}
          {...props}
        >
          {children}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: `rgba(255, 255, 255, ${opacity || 0.5})`,
              zIndex: 1000000000,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '14px',
              height: '100%',
              width: '100%'
            }}
            {...props}
          >
            <Typography variant='p' className='fs-12'>
              {errorMessage}
            </Typography>
            {showPermissionInfo && (
              <Typography variant='p' className='fs-12'>
                Required Permission(s): {parent} {path} &gt; {permissionType}
              </Typography>
            )}
          </Box>
          {showReadOnlyInfo && (
            <Box
              sx={{
                position: 'fixed',
                left: mousePosition.x + 10,
                top: mousePosition.y + 10,
                backgroundColor: 'rgba(255, 0, 0, 0.8)',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
                pointerEvents: 'none',
                zIndex: 1000000000
              }}
            >
              This is read only content.
            </Box>
          )}
        </Box>
      ) : (
        <Box {...props}>{custom}</Box>
      )}
    </>
  );
}
ValidatePermit.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  props: PropTypes.object,
  type: PropTypes.string, // [btn-disabled, hide, overlay, custom, message-only]
  sx: PropTypes.object,
  errorMessage: PropTypes.string,
  custom: PropTypes.node,
  opacity: PropTypes.number,
  redirectToInvalid: PropTypes.bool,
  parent: PropTypes.string,
  permissionType: PropTypes.string,
  root: PropTypes.bool,
  showPermissionInfo: PropTypes.bool,
  showReadOnlyInfo: PropTypes.bool,
  readOnlyMessage: PropTypes.string
};
export default ValidatePermit;
