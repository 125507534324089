import BookIcon from '@mui/icons-material/Book';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Button, List, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ListItemComponent } from '.';
import LevelOfEducationTypeSelection from '../../common/FunctionalComponents/LevelOfEducationTypeSelection';
import CountryTypeSelection from '../CountryTypeSelection';
import { FormGrid } from './FormGrid';
function EducationListItem(props) {
  const { list = [], onEdit, onDelete } = props;
  const { t } = useTranslation();
  const newList = list.sort((a, b) => dayjs(b?.start_date).diff(dayjs(a?.start_date)));
  return (
    <>
      {newList.map((item, index) => (
        <ListItemComponent
          id={item.id}
          key={index}
          onEditProp={item}
          title1={item?.degree_name || '-'}
          icon1={<BookIcon />}
          value1={item?.school_name || '-'}
          title2={
            `${item?.start_date && dayjs(item?.start_date).isValid() ? dayjs(item?.start_date).format('MMM YYYY') : '  '} - ${
              item?.is_present ? t('addContactDrawer.present') : item?.end_date && dayjs(item?.end_date).isValid() ? dayjs(item?.end_date).format('MMM YYYY') : ''
            }` || '-'
          }
          title3={item?.country?.name || '-'}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </>
  );
}
function AddContactDrawerSectionFour(props) {
  const { setValue, watch, setNewEducationOpen, newEducationOpen, levelOfEducationList, countryList, loadingDropdownData, getDropdownData } = props;
  const { register, unregister, watch: watchForm, setValue: setValueForm, resetField } = useForm();
  const { t } = useTranslation();
  useEffect(() => {
    register('id', { required: false });
    register('degree_name', { required: true });
    register('school_name', { required: true });
    register('country', { required: false });
    register('state', { required: false });
    register('country', { required: false });
    register('end_date', { required: false });
    register('start_date', { required: false });
    register('is_present', { required: false });
    register('new', { required: false });
    register('edit', { required: false });
    return () => {
      unregister('id');
      unregister('degree_name');
      unregister('school_name');
      unregister('country');
      unregister('state');
      unregister('country');
      unregister('end_date');
      unregister('start_date');
      unregister('is_present');
      unregister('new');
      unregister('edit');
    };
  }, [register]);
  useEffect(() => {
    if (newEducationOpen) {
      const list = watch('education') || [];
      const newList = [
        ...list,
        {
          id: list?.length + 1 || 100,
          degree_name: '',
          school_name: '',
          country: null,
          state: null,
          end_date: null,
          start_date: null,
          is_present: false,
          new: true,
          edit: true
        }
      ];
      setValue('education', newList);
      setNewEducationOpen(false);
    }
  }, [newEducationOpen]);
  const onSave = e => {
    const list = watch('education') || [];

    if (e?.id) {
      const newList = list.map(item =>
        item.id === e?.id
          ? {
              ...e,
              new: false,
              edit: false
            }
          : item
      );
      setValue('education', newList);
    } else {
      setValue('education', [
        ...list,
        {
          ...e,
          id: list?.length + 1 || 0,
          new: false,
          edit: false
        }
      ]);
    }
    onCancel();
  };
  const onCancel = e => {
    const list = watch('education') || [];
    if (!e?.new) {
      const newList = list.map(item => (item.id === e?.id ? { ...item, edit: false } : item));
      setValue('education', newList);
    } else {
      const newList = list.filter(item => item.id !== e?.id);
      setValue('education', newList);
    }
    resetField();
  };
  const onEdit = e => {
    const list = watch('education') || [];
    const newList = list.map(item => (item.id === e?.id ? { ...item, edit: true } : item));
    setValue('education', newList);
  };
  const onDelete = e => {
    const list = watch('education');
    const newList = list.length > 1 ? list.filter(item => item.id !== e) : [];
    setValue('education', newList);
  };
  return (
    <Stack justifyItems={'flex-start'} alignItems={'flex-start'} width={'100%'}>
      <List disablePadding sx={{ width: '100%' }} mb={1}>
        {watch('education')
          ?.sort((a, b) => dayjs(b?.start_date).diff(dayjs(a?.start_date)))
          ?.map((item, index) => {
            return item?.edit ? (
              <CreateNewEducation
                onSave={onSave}
                onCancel={onCancel}
                register={register}
                unregister={unregister}
                setValue={setValueForm}
                watch={watchForm}
                levelOfEducationList={levelOfEducationList}
                countryList={countryList}
                loadingDropdownData={loadingDropdownData}
                getDropdownData={getDropdownData}
                preData={item}
              />
            ) : (
              <ListItemComponent
                id={item.id}
                key={index}
                onEditProp={item}
                title1={item?.degree_name || '-'}
                icon1={<BookIcon />}
                value1={item?.school_name || '-'}
                title2={
                  `${item?.start_date && dayjs(item?.start_date).isValid() ? dayjs(item?.start_date).format('MMM YYYY') : '  '} - ${
                    item?.is_present ? t('addContactDrawer.present') : item?.end_date && dayjs(item?.end_date).isValid() ? dayjs(item?.end_date).format('MMM YYYY') : ''
                  }` || '-'
                }
                title3={item?.country?.name || '-'}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
          })}
      </List>
    </Stack>
  );
}
const CreateNewEducation = props => {
  const { onSave, onCancel, watch, setValue, levelOfEducationList, countryList, loadingDropdownData, getDropdownData, preData } = props;
  const onClickSave = () => {
    onSave(watch());

    setValue('id', null);
    // onCancel();
  };
  const onClickCancel = () => {
    onCancel(watch());
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (preData) {
      for (const key in preData) {
        setValue(key, preData[key]);
      }
    }
  }, [preData]);
  const sectionOneColumns = [
    {
      id: 'degree_name',
      label: 'addContactDrawer.levelOfEducation',
      type: 'custom',
      xs: 3.9,
      value: watch('degree_name'),
      loading: loadingDropdownData,
      fullWidth: true,
      customComponent: () => (
        <LevelOfEducationTypeSelection
          onChange={(e, data) => {
            setValue('degree_name', data?.value, { shouldDirty: true });
          }}
          label={t('addContactDrawer.levelOfEducation')}
          defaultValue={watch('degree_name')}
          required={true}
          size={'small'}
          fullWidth={true}
        />
      ),
      onChange: (e, v) => setValue('degree_name', v),
      required: true,
      options: levelOfEducationList,
      getOptionLabel: option => option.name
    },
    { id: 'school_name', label: 'addContactDrawer.schoolName', type: 'text', xs: 3.9, value: watch('school_name'), onChange: e => setValue('school_name', e.target.value), required: true },
    {
      id: 'country',
      label: 'addContactDrawer.country',
      type: 'custom',
      xs: 3.9,
      value: watch('country'),
      onChange: (e, v) => setValue('country', v),
      tooltipTitle: t('addContactDrawer.pleaseTypeToSearch'),
      customComponent: () => (
        <CountryTypeSelection
          onChange={(e, option) => {
            setValue('country', option);
          }}
          label={t('addContactDrawer.country')}
          value={watch('country') || null}
          required={false}
          size={'small'}
          fullWidth={true}
        />
      )
    },
    { id: 'start_date', label: 'addContactDrawer.from', type: 'date', xs: 3.9, value: watch('start_date'), onChange: e => setValue('start_date', e) },
    { id: 'end_date', label: 'addContactDrawer.to', type: 'date', xs: 3.9, value: watch('end_date'), onChange: e => setValue('end_date', e) },
    {
      id: 'is_present',
      label: 'addContactDrawer.educationCurrentlyEnrolled',
      fontSize: '12px',
      type: 'checkbox',
      xs: 3.9,
      checked: watch('is_present') ? true : false,
      onChange: e => setValue('is_present', e.target.checked)
    }
  ];

  return (
    <Stack
      justifyItems={'flex-start'}
      alignItems={'flex-start'}
      width={'100%'}
      border={'1px solid'}
      mt={1}
      borderColor={'background.main'}
      sx={{
        borderRadius: '4px'
      }}
      gap={2}
      p={1}
    >
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} gap={1}>
        <Typography fontWeight={'600'} className='fs-14'>
          {watch('new') ? t('addContactDrawer.add') : t('addContactDrawer.edit')} {t('addContactDrawer.education')}
        </Typography>
        <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={1} pr={2} width={'50%'} height={'100%'}>
          <Button
            sx={{
              borderRadius: '50%',
              minHeight: '20px',
              minWidth: '20px',
              p: 0.5,
              bgcolor: 'primary.light1'
            }}
            variant='text'
            onClick={onClickSave}
          >
            <SaveIcon fontSize='small' />
          </Button>
          <Button
            sx={{
              borderRadius: '50%',
              minHeight: '20px',
              minWidth: '20px',
              p: 0.5,
              bgcolor: 'primary.light1'
            }}
            variant='text'
            onClick={onClickCancel}
          >
            <CloseIcon color='error' fontSize='small' />
          </Button>
        </Stack>
      </Stack>
      <FormGrid columns={sectionOneColumns} rowGap={4} columnGap={1} />
    </Stack>
  );
};
export default AddContactDrawerSectionFour;

AddContactDrawerSectionFour.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setNewEducationOpen: PropTypes.func.isRequired,
  newEducationOpen: PropTypes.bool.isRequired,
  levelOfEducationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  getDropdownData: PropTypes.func.isRequired,
  preData: PropTypes.object.isRequired
};

CreateNewEducation.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  levelOfEducationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  getDropdownData: PropTypes.func.isRequired,
  preData: PropTypes.object.isRequired
};
EducationListItem.propTypes = {
  list: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  levelOfEducationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  getDropdownData: PropTypes.func.isRequired,
  preData: PropTypes.object.isRequired
};
