import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import RestoreIcon from '@mui/icons-material/Restore';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const GridActions = ({
  onExport,
  resetFilters,
  resetSorting,
  resetPersonalization,
  gridRef,
  hideExport = false,
  showColumnChooser,
  onExcelExport,
  enableAdvancedFilter,
  toggleAdvancedFilter,
  advancedFilters,
  api
}) => {
  // console.log('gridRef==', gridRef);
  const [themeColor, setThemeColor] = useState({});

  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);

  const handleClose = () => {
    api.closeToolPanel();
  };

  const handleExport = () => {
    onExport();
    handleClose();
  };

  const handleResetSort = () => {
    // if (gridRef) {
    //   gridRef?.api?.setSortModel(null);
    // }
    resetSorting();
    handleClose();
  };

  const handleExcelExport = () => {
    onExcelExport();
    // if (gridRef) gridRef?.api?.setFilterModel(null);
    handleClose();
  };

  const handleResetFilter = () => {
    resetFilters();
    // if (gridRef) gridRef?.api?.setFilterModel(null);
    handleClose();
  };

  const handleShowScores = () => {
    console.log('show scores');
    handleClose();
  };
  const showColumnSelector = () => {
    showColumnChooser();
    handleClose();
  };

  const handleResetGridPersonalization = () => {
    resetPersonalization();
    handleClose();
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);
  const bgColor = themeColor.primary_color ? themeColor.primary_color : '#ccc';
  const textColor = themeColor.text_color ? themeColor.primary_color : '#333';

  return (
    <>
      <List className='action-button-list'>
        {!hideExport && (
          <ListItem disablePadding>
            <ListItemButton onClick={handleExport} className='button-action-wrapper' sx={{ backgroundColor: alpha(bgColor, 0.6), color: textColor }}>
              <DownloadIcon /> <ListItemText primary='Export CSV' className='button-action' />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton onClick={onExcelExport} className='button-action-wrapper' sx={{ backgroundColor: alpha(bgColor, 0.6), color: textColor }}>
            <DownloadIcon />
            <ListItemText primary='Export Excel' className='button-action' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleResetSort} className='button-action-wrapper' sx={{ backgroundColor: alpha(bgColor, 0.6), color: textColor }}>
            <SwapVertIcon /> <ListItemText primary='Reset Sort' className='button-action' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleResetFilter} className='button-action-wrapper' sx={{ backgroundColor: alpha(bgColor, 0.6), color: textColor }}>
            <FilterListIcon /> <ListItemText primary='Reset Filter' className='button-action' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleResetGridPersonalization} className='button-action-wrapper' sx={{ backgroundColor: alpha(bgColor, 0.6), color: textColor }}>
            <RestoreIcon /> <ListItemText primary='Reset Personalization' className='button-action' />
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
          <ListItemButton onClick={showColumnChooser}>
            <ListItemText primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: 600 } }} primary='Column Selector' />
          </ListItemButton>
        </ListItem> */}
        {enableAdvancedFilter && (
          <ListItem disablePadding>
            <ListItemButton onClick={toggleAdvancedFilter} className='button-action-wrapper' sx={{ backgroundColor: alpha(bgColor, 0.6), color: textColor }}>
              <ListItemText primary={advancedFilters ? 'Hide Advanced Filters' : 'Advanced Filters'} className='button-action' />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </>
  );
};

GridActions.propTypes = {
  onExport: PropTypes.func,
  gridRef: PropTypes.object,
  hideExport: PropTypes.bool,
  resetFilters: PropTypes.func,
  resetSorting: PropTypes.func,
  showColumnChooser: PropTypes.func,
  onExcelExport: PropTypes.func,
  resetPersonalization: PropTypes.func,
  toggleAdvancedFilter: PropTypes.func,
  enableAdvancedFilter: PropTypes.bool,
  advancedFilters: PropTypes.bool,
  api: PropTypes.object
};

export default GridActions;
