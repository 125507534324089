import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CustomPopover = props => {
  const { children, id, open, anchorEl, anchorOrigin, transformOrigin, onClose, pointerEvents = '' } = props;

  return (
    <Popover
      id={id}
      sx={{ pointerEvents: pointerEvents, fontSize: '13px' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disableRestoreFocus
      onClose={onClose}
    >
      {children}
    </Popover>
  );
};

CustomPopover.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  open: PropTypes.func,
  anchorEl: PropTypes.func,
  anchorOrigin: PropTypes.string,
  transformOrigin: PropTypes.string,
  onClose: PropTypes.func,
  pointerEvents: PropTypes.string
};

export default CustomPopover;
