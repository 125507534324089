import { Avatar, Box, Divider, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomModalWithHeader from '../../../../../Containers/Commons/CustomModalWithHeader';
import Loader from '../../../../common/Loader';

function EvidenceModal({ open, setOpen, data, evidenceLoading }) {
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <CustomModalWithHeader
      closeIcon
      label={t('project.candidateScore.evidenceOfIgnyteIntelligenceRating')}
      onClose={handleClose}
      isOpen={open}
      style={{ height: '80vh', width: '45vw', padding: 0 }}
      subBoxStyle={{
        background: '#23CEA7',
        padding: '6px 10px',
        borderRadius: '4px 4px 0 0'
      }}
      labelStyle={{ color: '#fff', fontSize: '16px' }}
      buttonStyle={{ color: '#fff', cursor: 'pointer' }}
      showDivider={false}
    >
      <Box sx={{ height: '70vh', overflowY: 'auto', padding: '20px' }} display='flex' flexDirection='column'>
        {open && data?.heading && <Typography sx={{ fontSize: '12px !important', marginBottom: '20px !important' }}>{data.heading}</Typography>}

        {evidenceLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' flex={1}>
            <Loader loadingMessage={t('project.candidateScore.fetchingEvidenceHistory')} show className='loader-override' />
          </Box>
        ) : (
          <>
            <Typography
              variant='h6'
              sx={{
                marginBottom: '4px !important',
                fontSize: '14px !important',
                fontWeight: '700 !important'
              }}
            >
              {t('project.candidateScore.evidenceHistoryText')}
            </Typography>

            <Box sx={{ overflow: 'hidden', maxHeight: '80%', overflowY: 'auto', marginTop: '20px' }}>
              {!isEmpty(data?.evidence) ? (
                data.evidence.map((item, index) => (
                  <React.Fragment key={index}>
                    {Object.entries(item).map(([key, value], idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          gap: 2,
                          marginBottom: '6px',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 28,
                            height: 28,
                            background: '#EAEAEA',
                            fontSize: 13,
                            color: 'black'
                          }}
                        >
                          {key === 'interviewer_text' ? 'I' : 'C'}
                        </Avatar>
                        <Typography sx={{ fontSize: '12px !important', textAlign: 'left' }}>{trim(value)}</Typography>
                      </Box>
                    ))}
                    {index < data.evidence.length - 1 && <Divider sx={{ my: 2 }} />}
                  </React.Fragment>
                ))
              ) : (
                <Typography sx={{ fontSize: '12px !important', textAlign: 'center', marginTop: '20px' }}>{t('project.candidateScore.noEvidenceText')}</Typography>
              )}
            </Box>
          </>
        )}
      </Box>
    </CustomModalWithHeader>
  );
}

EvidenceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
  evidenceLoading: PropTypes.bool
};

export default EvidenceModal;
