import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import GoldMedalIcon from '../../../../components/Icons/GoldMedalIcon';
import StarEmptyIcon from '../../../../components/Icons/StarEmptyIcon';
import StarFilledIcon from '../../../../components/Icons/StarFilledIcon';
import ThumbsUpIcon from '../../../../components/Icons/ThumbsUpIcon';
import { textColors } from './ExperienceAndCompetency';
import './index.scss';

const LegendPreview = () => {
  const legends = [
    {
      title: 'Graded',
      icon: <GoldMedalIcon />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      title: 'Binary',
      icon: <ThumbsUpIcon width={18} height={18} />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      title: 'Ideal',
      icon: <StarFilledIcon />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      title: 'Minimum',
      icon: <StarEmptyIcon />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    }
  ];

  const colors = [
    {
      title: 'Experience',
      icon: <Box className='color-box-icon' style={{ backgroundColor: textColors.experience }} />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      title: 'Competency',
      icon: <Box className='color-box-icon' style={{ backgroundColor: textColors.competency }} />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      title: 'Uncategorized',
      icon: <Box className='color-box-icon' style={{ backgroundColor: textColors.uncategorized }} />,
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    }
  ];

  return (
    <Box className='legend-modal-preview'>
      {/* Legends Section */}
      <Box className='legend-box'>
        <Typography variant='body2' className='section-title'>
          Legends
        </Typography>
        {legends.map((legend, index) => (
          <Box key={index} className='legend-item'>
            <Box className='legend-text'>
              <span className='legend-icon'>{legend.icon}</span>
              <Typography variant='body2' className='legend-title'>
                {legend.title}
              </Typography>
            </Box>
            <Typography variant='body2' className='legend-description'>
              {legend.description}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Colors Section */}
      <Box className='legend-box'>
        <Typography variant='body2' className='section-title'>
          Colors
        </Typography>
        {colors.map((color, index) => (
          <Box key={index} className='legend-item'>
            <Box className='legend-text'>
              <span className='legend-icon'>{color.icon}</span>
              <Typography variant='body2' className='legend-title'>
                {color.title}
              </Typography>
            </Box>
            <Typography variant='body2' className='legend-description'>
              {color.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

LegendPreview.propTypes = {
  textColors: PropTypes.shape({
    experience: PropTypes.string,
    competency: PropTypes.string,
    uncategorized: PropTypes.string
  })
};

export default LegendPreview;
