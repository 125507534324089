//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import RectangleIcon from '@mui/icons-material/Rectangle';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import profileUrl from '../../../assets/images/profile-image.png';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useSnackbar } from 'notistack';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ActivityBars from '../../../components/ViewContactDrawer/ActivityLogsIgnyte/ActivityBar';
import ActivityHistory from '../../../components/ViewContactDrawer/ActivityLogsIgnyte/ActivityHistorys';
import ActivityFilter from '../../../components/ViewContactDrawer/ActivityLogsIgnyte/ActivityHistorys/ActivityFilter';
import '../../../components/ViewContactDrawer/ActivityLogsIgnyte/index.scss';
import CustomButton from '../../../components/common/CustomButton';
import { logAnActivitySelector } from '../../../selectors';
import { AllDocumentsApi, ProjectDataApi, ignContactDataApi, logAnActivityApi } from '../../../services/ApiService';
import { successMessage } from '../../../services/MessageService';
import { ERROR, GET, IGN_API, POST, SUCCESS } from '../../../services/constantService';
import { logAnActivityActions } from '../../../store/logAnActivitySlice';
import LogAnActivityTab from './LogAnActivityTab';
import styles from './index.module.scss';
import './index.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string
};

export default function LogAnActivity(props) {
  const {
    id,
    location,
    setIsMenuOpen = () => {},
    isMenuOpen,
    getHeaderDetails = () => {},
    isViewCloseIcon,
    getContact = () => {},
    isHeaderNav,
    ifForCompany = false,
    project_id,
    showPersonalityEdit = false,
    setShowPersonalityEdit,
    personalityFieldValue,
    updateContactPersonality
  } = props;
  const { register, unregister, setValue, watch, getValues, handleSubmit, reset, control } = useForm({});
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [value1 /* setValue1 */] = useState(0);
  const [contact, setContact] = useState(null);
  const [projectFilter, setProjectFilter] = useState(null);
  const [activityComponent, setActivityComponent] = useState('recent_activity');
  // const [activityDetail, setActivityDetail] = useState(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const isActiveTabValues = useRef(false);
  // let id = params;
  const { enqueueSnackbar } = useSnackbar();

  const logData = useSelector(logAnActivitySelector);

  const getJobInfo = jobInfoArray => {
    const jobInfoArr = jobInfoArray && jobInfoArray.filter(item => item !== null && item !== undefined);
    const commaSeparatedJobInfo = jobInfoArr.join(',');
    return commaSeparatedJobInfo || '-';
  };

  const getLocation = address => {
    const newAddress = address.filter(item => item?.address_type === 'work');
    if (newAddress.length === 1) {
      const addressArray = [newAddress[0]?.metropolitan_area ? newAddress[0]?.metropolitan_area : newAddress[0]?.city, newAddress[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(',') : '-';
    } else {
      const addressDetails = address.filter(item => item?.address_type === 'residential');
      const addressArray = [addressDetails[0]?.address_lines, addressDetails[0]?.city, addressDetails[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(',') : '-';
    }
  };

  useEffect(() => {
    if (isMenuOpen) setState({ ...state, ['right']: open });
  }, [isMenuOpen]);

  const { t } = useTranslation();

  const cleanupData = data => {
    let contactObject = {};
    contactObject = {
      ...data,
      avatar: profileUrl,
      screened: true,
      linkedinIcon: <LinkedInIcon className='linkedin-icon-header' />,
      screen: {
        icon: <RectangleIcon className='screen-icon fs-14' />,
        text: t('utils.screened'),
        className: 'btn-Style'
      },
      additionalContent: {
        content1: {
          icon: <WorkIcon className='content-icon fs-14 mr-2' />,
          text: getJobInfo([data?.current_job_title, data?.contact_company?.name])
        },
        content2: {
          icon: <LocationOnIcon className='content-icon fs-14 mr-2' />,
          text: getLocation(data?.contact_address)
        }
      },
      additionalContentClass: { className: 'first-grid' },
      mainContent: {},
      actionItems: ['Project', 'List'],
      disableEditing: true
    };
    return contactObject;
  };

  const getContactDetails = async () => {
    try {
      const sub_route = '?tabName=CONTACT_HEADER_TAB';
      const { status, data } = await ignContactDataApi(GET, id, {}, sub_route);
      if (status === 200 && data) {
        const result = cleanupData(data);
        setContact({
          data: result,
          avatarSizeXs: 1.8,
          avatarSizeMd: 1.8,
          avatarSizeSm: 1.8,
          mainContentWidthXs: 8.2,
          mainContentWidthMd: 8.2,
          mainContentWidthSm: 8.2,
          buttonSizeXs: 1.8,
          buttonSizeMd: 1.8,
          buttonSizeSm: 1.8,
          height: '19vh'
        });
      }
    } catch (err) {
      enqueueSnackbar('Could not fetch contact details', { variant: ERROR });
    }
  };

  const {
    data: activityDetail,
    isLoading,
    isRefetching,
    refetch: refetchLogAnActivity
  } = useQuery({
    queryKey: ['activityDetail', { id, project_id, milestone: activityComponent === 'milestones' }],
    queryFn: () =>
      logAnActivityApi(
        GET,
        `${id}`,
        {
          milestone: activityComponent === 'milestones',
          project_id: project_id
        },
        ifForCompany ? 'company-activity' : 'activity'
      )
        .then(res => {
          return res?.data || [];
        })
        .catch(error => {
          enqueueSnackbar('Could not fetch activity details', { variant: error });
        })
  });

  const {
    data: projectDetails,
    isFetching,
    error
  } = useQuery({
    queryKey: ['projectDetails', project_id],
    queryFn: () => ProjectDataApi(GET, project_id),
    enabled: Boolean(project_id), // Ensure contactId is available and searchTerm is valid
    keepPreviousData: true // Keep existing data while fetching new
  });

  const onFilterByProjectId = project => {
    setProjectFilter(project);
    //setProjectId(project?.project_id);
    refetchLogAnActivity();
  };

  useEffect(() => {
    if (!isFetching) {
      setProjectFilter(projectDetails?.data);
    }
  }, [isFetching]);

  useEffect(() => {
    if (id && !ifForCompany) {
      getContactDetails();
    }
  }, [id]);

  const onHandleSave = async operation => {
    setLoading(true);
    const logAnActivityData = { ...getValues() };

    const finalPayload = {
      id: logData || null,
      project_id: logAnActivityData.project_id,
      notes_type: logAnActivityData.notes_type,
      notes: logAnActivityData.notes,
      activity_type: logAnActivityData.activity_type?.activity_type_code,
      activity_added_at: logAnActivityData.activity_added_at,
      due_date: logAnActivityData.due_date,
      original_text: logAnActivityData.original_text,
      created_by: logAnActivityData.created_by,
      updated_by: logAnActivityData.updated_by,
      internal_attendees: logAnActivityData.internal_attendees && logAnActivityData.internal_attendees.length > 0 ? logAnActivityData.internal_attendees : null,
      external_attendees: logAnActivityData.client_attendees && logAnActivityData.client_attendees.length > 0 ? logAnActivityData.client_attendees : null,
      interview_date: logAnActivityData.interview_date,
      interview_date_timezone: logAnActivityData.interview_date_timezone,
      user_attendees: logAnActivityData.user_attendees || null,
      contact_attendees: logAnActivityData.contact_attendees,
      attachment_id: null,
      send_ical: logAnActivityData?.send_ical,
      milestone: logAnActivityData?.milestone
    };
    if (logAnActivityData.attachment && logAnActivityData?.attachment?.entries?.length > 0) {
      const res = await AllDocumentsApi(POST, `${IGN_API.add_document}/${id}/attachments`, logAnActivityData.attachment);
      if (res.status === 200) {
        finalPayload.attachment_id = res?.data?.id || null;
      } else {
        enqueueSnackbar('Attachment not uploaded', { variant: ERROR });
      }
    }

    if (ifForCompany) {
      finalPayload.company_id = id;
    } else {
      finalPayload.contact_id = id && id;
    }
    if (!finalPayload.activity_type) {
      setLoading(false);
      enqueueSnackbar('activity type is required', { variant: ERROR });
      return false;
    }
    const sub_route = ifForCompany ? 'company-create-activity' : 'create-activity';

    const { status, data } = await logAnActivityApi(POST, null, finalPayload, sub_route);
    if (operation === 'save') {
      setIsMenuOpen(false);
      setState({ ...state, ['right']: false });
    }

    if (status === 200) {
      setLoading(false);
      refetchLogAnActivity();
      setActivityComponent('recent_activity');
      getHeaderDetails();
      updateContactPersonality();
      const message = successMessage('', 'Activity Created');
      enqueueSnackbar(message, { variant: SUCCESS });
      dispatch(logAnActivityActions.resetEditId());
      return true;
    }
    if (status === 500) {
      setLoading(false);
      enqueueSnackbar(`${data.message}`, { variant: ERROR });
      return false;
    }
  };

  useEffect(() => {
    if (showPersonalityEdit) {
      if (personalityFieldValue?.activity_type_code === 'NOTE') {
        const { notes_type, isEditable } = personalityFieldValue || {};
        if (!isEditable) {
          setValue('notes_type', notes_type);
          setValue('activity_type', personalityFieldValue);
        }
        setActivityComponent('add_activity');
      }
    }
    return () => {
      setValue('activity_type', null);
      setValue('notes_type', null);
      setActivityComponent('recent_activity');
    };
  }, [personalityFieldValue]);

  return (
    <Box className={`${styles.log_an_activity} ${styles.details_container}`}>
      <Box className={`${styles.log_an_activity_inside} mt-1 px-3`} id='ActivityLogs_container'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
          <Box className='side-nav-header font-weight-bold header-font text-capitalize fs-16'>
            {activityComponent === 'add_activity' && showPersonalityEdit ? `${t('actions.edit')} ${t('contacts.activity')}` : `${t('actions.activityLog')}`}
          </Box>
          {activityComponent !== 'add_activity' ? (
            <CustomButton
              variant='outlined'
              type={'secondary'}
              size={'small'}
              onClick={() => {
                setActivityComponent('add_activity');
              }}
              iconLeft={<MonitorHeartOutlinedIcon />}
              buttonText={t('actions.logActivity')}
              disabled={activityComponent === 'add_activity' && showPersonalityEdit}
            />
          ) : (
            <IconButton aria-label='delete' className='p-0 mb-1'>
              <CloseIcon
                onClick={() => {
                  setActivityComponent('recent_activity');
                }}
              />
            </IconButton>
          )}
        </Box>
        <Divider sx={{ mt: 1 }} />
        <Box className='mb-3'>
          {activityComponent === 'add_activity' || showPersonalityEdit || activityComponent === 'edit_activity' ? null : (
            <ActivityBars activityState={activityComponent} setActivityComponent={setActivityComponent} moduleId={id} ifForCompany={ifForCompany} contact={contact} />
          )}
        </Box>
        {/* Filter by Project */}
        {['recent_activity', 'milestones'].includes(activityComponent) && <ActivityFilter onSelectProjectFilter={onFilterByProjectId} selectedProject={projectFilter} contact={contact} />}

        {(loading || isRefetching || isLoading) && (
          <Box className='d-flex justify-content-center align-items-center mt-5'>
            <Spinner animation='border' variant='success' />
          </Box>
        )}
        {!(loading || isLoading || isRefetching) && (
          <CustomTabPanel value={value1} index={0}>
            {activityComponent === 'recent_activity' && (
              <ActivityHistory
                activityState={activityComponent}
                activityDetails={activityDetail}
                isHeaderNav={isHeaderNav}
                setActivityComponent={setActivityComponent}
                getActivityDetails={refetchLogAnActivity}
              />
            )}
            {activityComponent === 'milestones' && (
              <ActivityHistory
                activityState={activityComponent}
                activityDetails={activityDetail}
                isHeaderNav={isHeaderNav}
                setActivityComponent={setActivityComponent}
                getActivityDetails={refetchLogAnActivity}
              />
            )}
            {activityComponent === 'add_activity' && (
              <LogAnActivityTab
                location={location}
                register={register}
                unregister={unregister}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                handleSubmit={handleSubmit}
                onHandleSave={onHandleSave}
                watch={watch}
                enqueueSnackbar={enqueueSnackbar}
                isViewCloseIcon={isViewCloseIcon}
                getContact={getContact}
                ifForCompany={ifForCompany}
                project_id={project_id}
                gotoRecentActivity={() => setActivityComponent('recent_activity')}
                control={control}
                showApprovedToPublish={activityComponent === 'add_activity' && showPersonalityEdit}
                onClose={() => {
                  setShowPersonalityEdit(false);
                  reset();
                  setActivityComponent('recent_activity');
                }}
              />
            )}
          </CustomTabPanel>
        )}
      </Box>
    </Box>
  );
}

LogAnActivity.propTypes = {
  id: PropTypes.string,
  location: PropTypes.string,
  setIsMenuOpen: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  isViewCloseIcon: PropTypes.bool,
  getHeaderDetails: PropTypes.func,
  isHeaderNav: PropTypes.string,
  ifForCompany: PropTypes.bool,
  project_id: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  getContact: PropTypes.func,
  showPersonalityEdit: PropTypes.bool,
  personalityFieldValue: PropTypes.object,
  setShowPersonalityEdit: PropTypes.func,
  updateContactPersonality: PropTypes.func
};
