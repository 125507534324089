import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ColorDropdowns from '../../../../../components/ColorLegends/Workbenches/ColorDropdownSelection';
import RouteOpener from '../../../../../components/common/RouteToNewPage';
import { ignWorkbenchDataApi } from '../../../../../services/ApiService';
import { PUT } from '../../../../../services/constantService';
import { CustomActionRenderer } from '../../../../../utils/ActionRenderer';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { calculateNumberOfDays } from '../../../../../utils/date';
import { generateWorkbenchProjectColumn } from './GridData';

function ProjectGrid({ colors, data, onSelect, handleDeleteContact, gridType, gridState, gridApiRef, savedSortModel, savedFilterModel }) {
  const { t } = useTranslation();
  const sx = {};
  const defaultColumnDefs = useMemo(() => generateWorkbenchProjectColumn(t), []);

  const handleProjectColorChange = id => async colorId => {
    const payload = {
      id,
      color_id: colorId
    };

    await ignWorkbenchDataApi(PUT, '', payload, 'update-color-label');
  };

  const columns = useMemo(() => {
    if (gridState?.length) {
      const savedConfigMap = gridState.reduce((acc, column) => {
        acc[column.field] = column;
        return acc;
      }, {});

      const updatedColDefs = defaultColumnDefs.map(col => {
        const savedConfig = savedConfigMap[col.field];
        if (savedConfig) {
          return {
            ...col,
            ...savedConfig
          };
        }
        return col;
      });

      updatedColDefs.sort((a, b) => {
        const indexA = gridState.findIndex(column => column.field === a.field);
        const indexB = gridState.findIndex(column => column.field === b.field);
        return indexA - indexB;
      });

      return [...updatedColDefs];
    } else {
      return defaultColumnDefs;
    }
  }, [t, gridState]);

  const onGridReady = params => {
    gridApiRef(params);
  };
  const handleDelete = async elem => {
    handleDeleteContact([elem]);
  };

  const otherActionOption = [
    {
      component: Delete,
      onClick: handleDelete
    }
  ];

  const CompanyRenderer = useCallback(params => {
    return params.value ? <RouteOpener type='company' id={params.data?.project?.ign_companies?.id} label={params.value} capitalize={true} /> : null;
  }, []);

  const ActionRenderer = params => {
    return <CustomActionRenderer item={params.data} options={[]} otherButtons={otherActionOption} />;
  };

  const ColorRenderer = useCallback(
    params => {
      return <ColorDropdowns colors={colors} onChange={colorId => handleProjectColorChange(params.data?.primaryId)(colorId)} selectedColor={params.data?.color_id} />;
    },
    [colors]
  );
  const ProjectNumberRenderer = useCallback(params => {
    return params.value ? <RouteOpener type='project' id={params.data.id} label={params.value} capitalize={false} /> : null;
  }, []);

  const rowData = useMemo(() => {
    return data.map(elem => {
      return {
        ...elem,
        ...elem.project,
        primaryId: elem?.id,
        days_open: calculateNumberOfDays(elem?.project?.created_at),
        industry: elem?.project?.ign_industries?.name,
        created_by: elem?.project?.creator?.name
        // actions: <CustomActionRenderer item={elem} options={[]} otherButtons={otherActionOption}></CustomActionRenderer>,
      };
    });
  }, [data]);
  return (
    <Stack id='myGrid' className='ag-theme-alpine workbench-table'>
      <AgGridWrapper
        defaultColumnDefs={defaultColumnDefs}
        gridType={gridType}
        columnDefs={[...columns]}
        rowData={rowData}
        suppressRowClickSelection={true}
        paginationPageSize={50}
        sortModel={savedSortModel}
        filterModel={savedFilterModel}
        isRowSelectable={() => {
          return true;
        }}
        rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
        enableRangeSelection={true}
        showHeaderMenu={true}
        defaultColDef={{
          resizable: true,
          filter: true,
          sortable: true
        }}
        {...sx}
        onSelectionChanged={onSelect}
        columnMenu={true}
        onGridReady={onGridReady}
        enableAdvancedFilter={true}
        rowGroupPanelShow='always'
        saveGridSetting={true}
        components={{
          companyRenderer: CompanyRenderer,
          actionRenderer: ActionRenderer,
          colorRenderer: ColorRenderer,
          projectNumberRenderer: ProjectNumberRenderer
        }}
      />
    </Stack>
  );
}

ProjectGrid.propTypes = {
  colors: PropTypes.array,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  gridType: PropTypes.string,
  gridState: PropTypes.array,
  gridApiRef: PropTypes.func,
  savedFilterModel: PropTypes.object,
  savedSortModel: PropTypes.array
};

export default ProjectGrid;
