//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import _ from 'lodash';
import '../../../../../components/ViewSearch/Components/InvoiceInfo/Components/index.scss';
//----------------------------------------------// Internal Imports // -------------------------------------------------

// import InputField from '../../../../common/InputField'
import { Checkbox } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import CustomButton from '../../../../../components/common/CustomButton';
import CustomConfirmationPopup from '../../../../../components/common/CustomConfirmationPopup';
import CustomPopup from '../../../../../components/common/CustomPopup';
import RichText from '../../../../../components/common/RichText';
import { requireMessage } from '../../../../../services/MessageService';
import { ERROR, INVOICE_VALIDATION } from '../../../../../services/constantService';
import { formatDate, formatTime } from '../../../../../utils/date';

export default function ConfirmProjectedDatePopup(props) {
  const { onClose, projectedData, invoiceList, updateField, setInvoiceList, setValue, watch, currentValues, enqueueSnackbar, loading } = props;
  const [isConfirmPopup, setConfirmPopup] = useState(false);
  const [isValueChanged, setValueChanged] = useState(false);

  console.log('projectedData', projectedData, invoiceList, currentValues);
  const handleClose = () => {
    if (
      projectedData.data?.comments !== watch('project_invoice_info')[projectedData.rowIndex]?.comments ||
      projectedData.data?.projected_bill_date !== watch('project_invoice_info')[projectedData.rowIndex]?.projected_bill_date ||
      projectedData.data?.ready_to_bill !== watch('project_invoice_info')[projectedData.rowIndex]?.ready_to_bill
    )
      setConfirmPopup(true);
    else onClose && onClose();
  };
  const handleConfirmPopup = () => {
    onClose && onClose();
  };

  const handleCancelPopup = () => {
    setConfirmPopup(false);
  };

  useEffect(() => {
    if (currentValues) {
      setInvoiceList(currentValues['project_invoice_info'] || [{}]);
    }
  }, [currentValues]);

  useEffect(() => {
    setValue('project_invoice_info', invoiceList);
  }, [invoiceList, setValue]);

  const onSave = async () => {
    console.log('hello');
    if (!watch('project_invoice_info')[projectedData.rowIndex]?.projected_bill_date) {
      const message = requireMessage('Projected Date', 'is');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (
      projectedData.data.projected_bill_date !== watch('project_invoice_info')[projectedData.rowIndex]?.projected_bill_date ||
      projectedData.data.comments !== watch('project_invoice_info')[projectedData.rowIndex]?.comments ||
      projectedData.data.ready_to_bill !== watch('project_invoice_info')[projectedData.rowIndex]?.ready_to_bill
    ) {
      let res = await updateField(projectedData.rowIndex, 'updating record', 'update');
      if (res) {
        onClose && onClose();
      }
    } else {
      // setValueChanged(true);
      onClose && onClose();
    }
    // if (!watch('project_invoice_info')[projectedData.rowIndex].comments) {
    //   const message = requireMessage('Comment', 'is')
    //   enqueueSnackbar(message, { variant: ERROR })
    //   return
    // }
  };

  const handleCommentsChange = data => {
    let clonedObject = _.cloneDeep(invoiceList);
    clonedObject[projectedData.rowIndex].comments = data;
    setInvoiceList(clonedObject);
    setValueChanged(true);
  };

  const handleReadyToBilledChange = e => {
    let clonedObject = _.cloneDeep(invoiceList);
    clonedObject[projectedData.rowIndex].ready_to_bill = e;
    setInvoiceList(clonedObject);
    setValueChanged(true);
  };

  const handleDateChange = date => {
    let clonedObject = _.cloneDeep(invoiceList);
    // clonedObject[projectedData.rowIndex].projected_bill_date = formatDate(date);
    clonedObject[projectedData.rowIndex].projected_bill_date = date && moment(date).utc().local().isValid() ? moment(date).utc().local().format() : null;
    setInvoiceList(clonedObject);
    setValueChanged(true);
  };

  return (
    <CustomPopup
      loading={loading}
      title='Projected Bill Date'
      onClose={handleClose}
      overlayStyle={{ zIndex: '1401 !important' }}
      className='confirmation-projectDate confirmation-projectDate-popup'
      open={true}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <>
        {isConfirmPopup ? (
          <CustomConfirmationPopup
            open={isConfirmPopup}
            setOpen={setConfirmPopup}
            customMessage={INVOICE_VALIDATION.UNSAVED_DATA}
            onConfirm={handleConfirmPopup}
            onCancel={handleCancelPopup}
            onClose={handleCancelPopup}
            cancelText='No'
            confirmText='Yes'
          />
        ) : null}

        <Box id='education-experiences' className='education-container-projectedDate-popup'>
          <Box className='my-cards flex flex-wrap'>
            <Box className='form-section flex flex-column'>
              {/* {!isProject && <Box className="text-label text-center fs-16 pl-1 pb-1 header-font my-4">
              {t('contacts.addContact.basicInfo')}
            </Box>} */}
              <Box className='flex flex-row flex-wrap d-flex p-2 justify-content-center'>
                <Box className='row d-flex '>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input' sx={{ width: '18vw' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
                          <DatePicker
                            value={dayjs(formatDate(watch('project_invoice_info')[projectedData.rowIndex]?.projected_bill_date || '', 'MM-DD-YYYY') || null)}
                            format='MM/DD/YYYY'
                            // placeholder='mm/dd/yyyy'
                            // onChange={(e) => {
                            // const selectedDate = e?.$d;
                            // setDateRange({ ...dateRange, start: e })
                            // handleChangeExperienceData(index, "start_date", e.selectedDate ? selectedDate : null)
                            // }}
                            // maxDate={dateRange.end}
                            onChange={e => {
                              handleDateChange(e?.$d);
                            }}
                            label={'Projected Bill Date *'}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>
                  <Box className='col-md-6'>
                    <Box className='input-field flex align-items-center d-flex justify-content-end'>
                      <Checkbox
                        className='compensation-checkbox'
                        checked={watch('project_invoice_info')[projectedData.rowIndex]?.ready_to_bill}
                        onChange={e => {
                          handleReadyToBilledChange(e.target.checked);
                        }}
                      ></Checkbox>
                      <Typography className='ml-2 check-box-title'>{'Ready To Bill'}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className='flex flex-row flex-wrap d-flex p-2 justify-content-center'>
                <Box className='row d-flex'>
                  <Box className='col-md-12'>
                    <Box className='education-input-base'>
                      <Box className='' sx={{ width: '36vw' }}>
                        <RichText
                          className='input-form-field text-left'
                          name='comments'
                          defaultValue={watch('project_invoice_info')[projectedData.rowIndex]?.comments || ''}
                          onChange={data => {
                            handleCommentsChange(data);
                          }}
                          customClassName='projected-bill-date-text'
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className='text-left'>
          {' '}
          <Box className='footer-text pl-2'>
            {' '}
            Last updated by{' '}
            <Box component='span' sx={{ fontWeight: 900 }}>
              {projectedData?.data?.user ? projectedData?.data?.user?.first_name + ' ' + projectedData?.data?.user?.last_name : ''}
            </Box>{' '}
            On {formatDate(projectedData?.data?.updated_at ? projectedData?.data?.updated_at : projectedData?.data?.created_at) || ''} , {formatTime(projectedData.data.updated_at) || ''}
          </Box>
        </Box>
        <Box className='d-flex justify-content-end p-2 pt-4'>
          <CustomButton buttonText='Confirm & Save' variant='contained' disabled={!isValueChanged} onClick={onSave} />
        </Box>
      </>
    </CustomPopup>
  );
}

ConfirmProjectedDatePopup.propTypes = {
  onClose: PropTypes.func,
  projectedData: PropTypes.object,
  invoiceList: PropTypes.object,
  updateField: PropTypes.func,
  setInvoiceList: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  loading: PropTypes.bool
};
