import { CircularProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSecuritySelector } from '../selectors';
import { picklistsDataApis } from '../services/ApiService';
import { GET } from '../services/constantService';
import { hasError, isLoading, updateUseSecurity } from '../store/componentPermissionSlice';

const IgnyteGate = ({ children }) => {
  const dispatch = useDispatch();
  const permission = useSelector(state => state.componentPermissionSlice);
  const userData = useSelector(userSecuritySelector);
  const stringToBoolean = str => str.toLowerCase() === 'true';
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        // dispatch(updateUseSecurity(true)); // for testing only
        // return;
        dispatch(isLoading(true));

        if (userData && userData?.roleName === 'admin') {
          dispatch(updateUseSecurity(false));
          return;
        } else {
          const useSecurity = await picklistsDataApis(GET, 'ENFORCE_APP_SECURITY');
          if (useSecurity?.data || useSecurity?.data?.length > 0) {
            dispatch(updateUseSecurity(stringToBoolean(useSecurity?.data[0]?.short_desc) || false));
          }
        }

        dispatch(isLoading(false));
      } catch (err) {
        console.log(err);
        dispatch(hasError('Failed to load permissions.'));
      } finally {
        dispatch(isLoading(false));
      }
    };
    if (userData) {
      fetchPermissions();
    }
  }, [dispatch, userData]);

  // Recursively clone and check permissions for all components

  // Redux dispatch function (currently not in use but included for future use)

  return permission.loading ? (
    <Stack
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}
    >
      <CircularProgress size={36} thickness={4} />
      <Typography>Loading permissions...</Typography>
    </Stack>
  ) : permission.error ? (
    <Stack
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography>{permission.error}</Typography>
    </Stack>
  ) : (
    children
  );
};

IgnyteGate.propTypes = {
  children: PropTypes.node.isRequired
};

export default IgnyteGate;
