import FilterAltIcon from '@mui/icons-material/FilterAlt';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ReportIcon from '@mui/icons-material/Report';
import { Badge, Box, Button, Checkbox, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import CustomTable from '../../../../components/common/CustomTable';
import EchoSenseCreateNewRequest from '../../../../Containers/IgnyteIntelligence/components/EchoSenseCreateNewRequest';
import { GetCandidateScoreApi, PostCandidateScoreApi, UpdateCandidateScoreApi } from '../../../../services/ApiService';
import { STANDARD_ERROR_MESSAGE } from '../../../../services/constantService';
import { showToast } from '../../../../utils/common';
import Loader from '../../../common/Loader';
import { AiRating, BinaryScorecardModal, CommentModal, CriteriaComponent, EvidenceModal, FilterModal, RatingWrapper, RubricModal } from './components';

const initialFilterState = {
  searchQuery: '',
  scoreTypes: {
    label: 'project.candidateScore.scoreTypesLabel',
    ignyteAIRating: { label: 'project.candidateScore.ignyteIntelligenceRating', value: true },
    recruiterRating: { label: 'project.candidateScore.recruiterRating', value: true },
    consultantRating: { label: 'project.candidateScore.consultantRating', value: true }
  },
  criteria: {
    label: 'project.candidateScore.criteria',
    competency: { label: 'project.candidateScore.competencyLabel', value: true },
    experience: { label: 'project.candidateScore.experienceLabel', value: true },
    unclassified: { label: 'project.candidateScore.unclassifiedLabel', value: true }
  },
  criteriaType: {
    label: 'project.candidateScore.criteriaTypeLabel',
    ideal: { label: 'project.candidateScore.idealLabel', value: true },
    minimal: { label: 'project.candidateScore.minimalLabel', value: true }
  },
  averageType: {
    label: 'project.candidateScore.averageTypeLabel',
    visible: { label: 'project.candidateScore.visibleLabel', value: true }
  }
};

function filterRecords(data, searchText = '') {
  const lowerCaseSearchText = _.toLower(searchText);
  if (!_.trim(searchText)) {
    return data;
  }

  const matchesSearchText = value => _.isString(value) && _.toLower(value).includes(lowerCaseSearchText);

  const filterObject = obj => {
    return _.some([
      matchesSearchText(obj.competency),
      matchesSearchText(_.get(obj, 'profile_label.description')),
      matchesSearchText(_.get(obj, 'profile_label.label_subtype')),
      matchesSearchText(obj.recruiter_comments),
      matchesSearchText(obj.consultant_comments)
    ]);
  };

  return {
    EXPERIENCE: _.filter(data.EXPERIENCE, filterObject),
    COMPETENCY: _.filter(data.COMPETENCY, filterObject),
    UNCLASSIFIED: _.filter(data.UNCLASSIFIED, filterObject)
  };
}

function CandidateScore({ contact_id, contactName, candidate_id, project_id, isLogAnActivityOpen }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [manualLoading, setManualLoading] = useState(false);
  const [evidenceLoading, setEvidenceLoading] = useState(false);
  const [rubricLoading, setRubricLoading] = useState(false);
  const [openRubricModal, setOpenRubricModal] = useState(false);
  const [openEvidenceModal, setOpenEvidenceModal] = useState(false);
  const [openBinaryScorecardModal, setOpenBinaryScorecardModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [approvedToPublish, setApprovedToPublish] = useState(false);
  const [copyComments, setCopyComments] = useState(false);
  const [isEchoSenseOpen, setIsEchoSenseOpen] = useState(false);
  const [candidateScoreData, setCandidateScoreData] = useState({
    avgIgnyteRating: 0,
    avgRecruiterRating: 0,
    avgConsultantRating: 0,
    competency: {}
  });
  const [filteredScoreData, setFilteredScoreData] = useState({
    avgIgnyteRating: 0,
    avgRecruiterRating: 0,
    avgConsultantRating: 0,
    competency: {}
  });
  const [tableHeader, setTableHeader] = useState([
    t('project.candidateScore.criteria'),
    t('project.candidateScore.ignyteIntelligenceRating'),
    t('project.candidateScore.recruiterRating'),
    t('project.candidateScore.consultantRating')
  ]);
  const [scoreGenerationInProgress, setScoreGenerationInProgress] = useState(false);
  const [showCandidateScoreFields, setShowCandidateScoreFields] = useState(initialFilterState);
  const [selectedData, setSelectedData] = useState(null);
  const [payloadValue, setPayloadValue] = useState({});
  const [activeFieldKey, setActiveFieldKey] = useState(null);

  const handleFilterConfirm = newFilter => {
    const { scoreTypes, searchQuery } = newFilter;
    const headerData = [t('project.candidateScore.criteria')];
    if (scoreTypes.ignyteAIRating.value) {
      headerData.push(t('project.candidateScore.ignyteIntelligenceRating'));
    }
    if (scoreTypes.recruiterRating.value) {
      headerData.push(t('project.candidateScore.recruiterRating'));
    }
    if (scoreTypes.consultantRating.value) {
      headerData.push(t('project.candidateScore.consultantRating'));
    }
    setTableHeader(headerData);
    const masterData = _.cloneDeep(candidateScoreData);
    if (!newFilter.criteria.experience.value) {
      delete masterData.competency.EXPERIENCE;
    }
    if (!newFilter.criteria.competency.value) {
      delete masterData.competency.COMPETENCY;
    }
    if (!newFilter.criteria.unclassified.value) {
      delete masterData.competency.UNCLASSIFIED;
    }

    let filteredCompetency = _.cloneDeep(masterData.competency);
    if (!newFilter.criteriaType.ideal.value) {
      filteredCompetency = {
        EXPERIENCE: _.filter(masterData.competency.EXPERIENCE, item => _.get(item, 'profile_label.label_requirement') !== 'IDEAL'),
        COMPETENCY: _.filter(masterData.competency.COMPETENCY, item => _.get(item, 'profile_label.label_requirement') !== 'IDEAL'),
        UNCLASSIFIED: _.filter(masterData.competency.COMPETENCY, item => _.get(item, 'profile_label.label_requirement') !== 'IDEAL')
      };
    }
    if (!newFilter.criteriaType.minimal.value) {
      filteredCompetency = {
        EXPERIENCE: _.filter(masterData.competency.EXPERIENCE, item => _.get(item, 'profile_label.label_requirement') !== 'MINIMUM'),
        COMPETENCY: _.filter(masterData.competency.COMPETENCY, item => _.get(item, 'profile_label.label_requirement') !== 'MINIMUM'),
        UNCLASSIFIED: _.filter(masterData.competency.COMPETENCY, item => _.get(item, 'profile_label.label_requirement') !== 'MINIMUM')
      };
    }

    filteredCompetency = filterRecords(filteredCompetency, searchQuery);
    setFilteredScoreData({ ...masterData, competency: filteredCompetency });
    setShowCandidateScoreFields(newFilter);
  };
  const getCandidateInfo = useCallback(async () => {
    try {
      const sub_route = `/candidate-info?candidate_id=${candidate_id}`;
      const { data: payload } = await GetCandidateScoreApi(sub_route);
      const copy_recruiter_score_comments = _.get(payload, 'data.copy_recruiter_score_comments', false);
      const approved_to_publish = _.get(payload, 'data.approved_to_publish', false);
      setCopyComments(copy_recruiter_score_comments);
      setApprovedToPublish(approved_to_publish);
    } catch {
      return;
    }
  }, []);

  const getCandidateScoreDetails = async () => {
    try {
      setLoading(true);
      const sub_route = `/score?project_id=${project_id}&candidate_id=${candidate_id}`;
      const { status, data: payload } = await GetCandidateScoreApi(sub_route);
      if (payload.error) {
        showToast(payload.msg, 'error');
        setLoading(false);
        return;
      }
      if (status === 200) {
        if (payload.data.noScore) {
          if (payload.data.inProgress) {
            setScoreGenerationInProgress(true);
          }
        } else {
          setScoreGenerationInProgress(false);
          const dbData = {
            avgIgnyteRating: _.get(payload, 'data.avgIgnyteRating', 0),
            avgRecruiterRating: _.get(payload, 'data.avgRecruiterRating', 0),
            avgConsultantRating: _.get(payload, 'data.avgConsultantRating', 0),
            competency: _.get(payload, 'data.competency', {})
          };
          setCandidateScoreData(dbData);
          setFilteredScoreData(dbData);
        }
        setLoading(false);
      }
    } catch (error) {
      showToast(error.msg);
      setLoading(false);
    }
  };
  const fetchTotalRatings = useCallback(async () => {
    const sub_route = `/score?project_id=${project_id}&candidate_id=${candidate_id}`;
    try {
      const { data: payload } = await GetCandidateScoreApi(sub_route);
      setCandidateScoreData(prevScore => {
        return { ...prevScore, ...(payload.data || {}) };
      });
    } catch (error) {
      return;
    }
  }, []);

  // Fetch after 5 seconds delay since its a heavy calculation operation
  const debounceRatingFetch = useCallback(_.debounce(fetchTotalRatings, 5000), []);

  const updateMasterData = useCallback(
    async (updatedRecord, categoryKey) => {
      const key = _.toUpper(categoryKey);
      const updatedMasterCompetency = _.map(candidateScoreData.competency[key], record => (record.id === updatedRecord.id ? { ...record, ...updatedRecord } : record));
      const newMasterData = {
        ...candidateScoreData,
        competency: {
          ...candidateScoreData.competency,
          [key]: updatedMasterCompetency
        }
      };

      setCandidateScoreData(newMasterData);
      const updatedCompetency = _.map(filteredScoreData.competency[key], record => (record.id === updatedRecord.id ? { ...record, ...updatedRecord } : record));
      const newFilteredData = {
        ...filteredScoreData,
        competency: {
          ...filteredScoreData.competency,
          [key]: updatedCompetency
        }
      };
      setFilteredScoreData(newFilteredData);

      await debounceRatingFetch();
    },
    [candidateScoreData, filteredScoreData, debounceRatingFetch]
  );

  const handleAddManualScore = useCallback(async () => {
    setManualLoading(true);
    try {
      const { data: payload } = await PostCandidateScoreApi('/manual-score', {
        project_id,
        candidate_id
      });
      if (payload.error) {
        showToast(payload.msg, 'error');
        setManualLoading(false);
        return;
      }
      const dbData = {
        avgIgnyteRating: _.get(payload, 'data.avgIgnyteRating', 0),
        avgRecruiterRating: _.get(payload, 'data.avgRecruiterRating', 0),
        avgConsultantRating: _.get(payload, 'data.avgConsultantRating', 0),
        competency: _.get(payload, 'data.competency', {})
      };
      setCandidateScoreData(dbData);
      setFilteredScoreData(dbData);
      setManualLoading(false);
    } catch (error) {
      showToast(STANDARD_ERROR_MESSAGE, 'error');
      setManualLoading(false);
    }
  }, []);

  useEffect(() => {
    getCandidateScoreDetails();
    getCandidateInfo();
  }, []);

  const getCandidateScoreEvidence = useCallback(async payload => {
    try {
      setSelectedData({ heading: payload.evidence_text, evidence: [] });
      setEvidenceLoading(true);
      setOpenEvidenceModal(true);
      const sub_route = `/evidence?project_id=${payload.project_id}&candidate_id=${payload.candidate_id}&competency=${payload.competency}`;
      const { status, data } = await GetCandidateScoreApi(sub_route);

      if (status === 200) {
        setSelectedData({ heading: payload.evidence_text, evidence: data.data });
        setEvidenceLoading(false);
      }
    } catch (error) {
      showToast(error.message, 'error');
    } finally {
      setEvidenceLoading(false);
    }
  }, []);

  const getCandidateScoreRubrics = useCallback(async payload => {
    try {
      setRubricLoading(true);
      if (payload.profile_label.label_type === 'BINARY') {
        setOpenBinaryScorecardModal(true);
      } else {
        setOpenRubricModal(true);
      }
      const sub_route = `/rubric?label_id=${payload.competency_label_id}`;
      const { status, data } = await GetCandidateScoreApi(sub_route);

      if (status === 200) {
        if (data.error) {
          showToast(data.msg, 'error');
          setOpenBinaryScorecardModal(false);
          setOpenRubricModal(false);
        } else {
          setSelectedData(data.data);
        }
      }
    } catch (error) {
      showToast(error.message, 'error');
      setOpenBinaryScorecardModal(false);
      setOpenRubricModal(false);
    } finally {
      setRubricLoading(false);
    }
  }, []);

  const handleRubricModal = async data => {
    try {
      setSelectedData(null);
      await getCandidateScoreRubrics(data);
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  const handleEvidenceModal = async data => {
    try {
      setSelectedData(null);
      await getCandidateScoreEvidence(data);
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  const handleOpenCommentModal = (data, key, category) => {
    const payload = { id: data.id, category };
    if (key === 'recruiter') {
      payload.recruiter_comments = data.recruiter_comments;
    } else if (key === 'consultant') {
      payload.consultant_comments = data.consultant_comments;
    }
    setActiveFieldKey(key);
    setPayloadValue(payload);
    setOpenCommentModal(true);
  };

  const handlePublishApproval = event => {
    setApprovedToPublish(event.target.checked);
    UpdateCandidateScoreApi('/copy-comments', { candidate_id, project_id, shouldCopy: copyComments, approvedToPublish: event.target.checked });
  };

  const handleCopyRecruiterComments = async event => {
    try {
      setCopyComments(event.target.checked);
      const { status, data: payload } = await UpdateCandidateScoreApi('/copy-comments', { candidate_id, project_id, shouldCopy: event.target.checked });
      if (status === 200) {
        const dbData = {
          avgIgnyteRating: _.get(payload, 'data.avgIgnyteRating', 0),
          avgRecruiterRating: _.get(payload, 'data.avgRecruiterRating', 0),
          avgConsultantRating: _.get(payload, 'data.avgConsultantRating', 0),
          competency: _.get(payload, 'data.competency', {})
        };
        setCandidateScoreData(dbData);
        setFilteredScoreData(dbData);
      }
    } catch (err) {
      showToast(err?.msg || 'Failed to copy recruiter comments', 'error');
    }
  };

  const renderRows = (key, data) => {
    return (
      showCandidateScoreFields.criteria[key]?.value &&
      _.sortBy(data, 'profile_label.label_subtype').map(row => (
        <TableRow key={row.id} className='overflow-hidden border box-criteria-tab-row'>
          <TableCell className='input-field-data' sx={{ width: '40%' }}>
            <CriteriaComponent data={row} index='criteria' handleModal={handleRubricModal} showCriteriaUIFields={showCandidateScoreFields.criteriaType} />
          </TableCell>
          {showCandidateScoreFields.scoreTypes.ignyteAIRating.value && (
            <TableCell className='justify-content-center' sx={{ backgroundColor: '#F8FCFF', width: '20%' }}>
              <RatingWrapper
                value={row.ignyte_intelligence_rating || 0}
                type={row.profile_label.label_type || 'N/A'}
                handleModal={handleEvidenceModal}
                isIgnyteAiRating
                data={row}
                fieldKey='ignyte'
                category={key}
                updateMasterData={updateMasterData}
              />
            </TableCell>
          )}
          {showCandidateScoreFields.scoreTypes.recruiterRating.value && (
            <TableCell className='justify-content-center' sx={{ backgroundColor: '#F8FCFF', width: '20%' }}>
              <RatingWrapper
                value={row.recruiter_rating || 0}
                type={row.profile_label.label_type || 'N/A'}
                handleModal={handleOpenCommentModal}
                comments={row.recruiter_comments}
                data={row}
                fieldKey='recruiter'
                category={key}
                updateMasterData={updateMasterData}
              />
            </TableCell>
          )}
          {showCandidateScoreFields.scoreTypes.consultantRating.value && (
            <TableCell className='justify-content-center' sx={{ backgroundColor: '#F8FCFF', width: '20%' }}>
              <RatingWrapper
                value={row.consultant_rating || 0}
                type={row.profile_label.label_type || 'N/A'}
                handleModal={handleOpenCommentModal}
                comments={row.consultant_comments}
                data={row}
                fieldKey='consultant'
                category={key}
                updateMasterData={updateMasterData}
              />
            </TableCell>
          )}
        </TableRow>
      ))
    );
  };

  const renderAverageRow = data => {
    if (_.isEmpty(data)) {
      return null;
    }
    const averageIgnyte = _.round(data?.reduce((acc, curr) => acc + curr.ignyte_intelligence_rating, 0) / data?.length, 2);
    const averageConsultant = _.round(data?.reduce((acc, curr) => acc + curr.consultant_rating, 0) / data?.length, 2);
    const averageRecruiter = _.round(data?.reduce((acc, curr) => acc + curr.recruiter_rating, 0) / data?.length, 2);
    return (
      <TableRow
        className='overflow-hidden'
        sx={{
          backgroundColor: '#C2DEEC',
          borderTop: '1px solid #CCC'
        }}
      >
        <TableCell
          className='input-field-data'
          sx={{
            paddingLeft: '40px',
            paddingRight: '40px',
            color: '#003956'
          }}
        >
          <Box className='average-rating'>{t('project.candidateScore.averageText')}</Box>
        </TableCell>
        {showCandidateScoreFields.scoreTypes.ignyteAIRating.value && (
          <TableCell className='input-field-data p-2'>
            <Box className='average-rating text-center'>{averageIgnyte || '~ - ~'}</Box>
          </TableCell>
        )}
        {showCandidateScoreFields.scoreTypes.recruiterRating.value && (
          <TableCell className='p-2 justify-content-center'>
            <Box className='average-rating text-center'>{averageRecruiter || '~ - ~'}</Box>
          </TableCell>
        )}
        {showCandidateScoreFields.scoreTypes.consultantRating.value && (
          <TableCell className='p-2 justify-content-center'>
            <Box className='average-rating text-center'>{averageConsultant || '~ - ~'}</Box>
          </TableCell>
        )}
      </TableRow>
    );
  };

  const noData = useMemo(() => _.isEmpty(filteredScoreData.competency) || _.every(filteredScoreData.competency, _.isEmpty), [filteredScoreData]);
  const filterEnabled = useMemo(() => !_.isEqual(showCandidateScoreFields, initialFilterState), [showCandidateScoreFields]);

  return (
    <React.Fragment>
      <div id='view-candidate-score' className='custom-scrollbar py-2 px-2 view-candidate-score'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '4px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '3px',
              border: '1px solid #DDD',
              padding: '2px 10px',
              height: 32,
              marginTop: '6px'
            }}
            onClick={() => setOpenFilterModal(true)}
          >
            <Badge color='secondary' variant='dot' invisible={!filterEnabled}>
              <FilterAltIcon sx={{ color: '#1FA890', fontSize: '0.8rem' }} />
            </Badge>
            <Typography variant='filter' sx={{ ml: 1, color: '#555555', fontWeight: 500, fontSize: '12px' }}>
              {t('project.candidateScore.filterText')}
            </Typography>
          </Box>
          <Stack direction='row' alignItems='center'>
            <Checkbox disabled={noData} checked={approvedToPublish} onChange={handlePublishApproval} />
            <Typography variant='approvedToPublish' sx={{ fontSize: '12px' }}>
              {t('project.candidateScore.approvedToPublish')}
            </Typography>
            <Checkbox checked={copyComments} disabled={noData} onChange={handleCopyRecruiterComments} />
            <Typography variant='copyRecruiterComments' sx={{ fontSize: '12px' }}>
              {t('project.candidateScore.copyRecruiterComments')}
            </Typography>
          </Stack>
        </Box>

        <AiRating
          igNyteRating={candidateScoreData?.avgIgnyteRating}
          recruiterRating={candidateScoreData?.avgRecruiterRating}
          consultantRating={candidateScoreData?.avgConsultantRating}
          showAiRatingFields={showCandidateScoreFields.scoreTypes}
          isLogAnActivityOpen={isLogAnActivityOpen}
        />
        {loading ? (
          <Box my='80px' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <Loader loadingMessage={t('project.candidateScore.fetchingCandidateScore')} show={loading} className='loader-override' />
          </Box>
        ) : !noData ? (
          <CustomTable
            headerData={tableHeader}
            isShowTitle={false}
            headerClassName={`table-header-background title-content ${isLogAnActivityOpen ? 'contact-details-table-header' : ''}`}
            showExtraHeader={false}
            showAddAnotherButton={false}
            tableContainerClassName='custom-table-override'
            stickyHeader={true}
            tableAriaLabel='customized table'
          >
            {Object.keys(candidateScoreData.competency)
              .sort()
              .map(key => {
                if (showCandidateScoreFields.criteria[_.toLower(key)]?.value) {
                  return (
                    <React.Fragment key={key}>
                      {renderRows(_.toLower(key), candidateScoreData.competency[key])}
                      {showCandidateScoreFields.averageType?.visible?.value && renderAverageRow(candidateScoreData.competency[key])}
                    </React.Fragment>
                  );
                }
              })}
          </CustomTable>
        ) : scoreGenerationInProgress ? (
          <Box my='80px' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <QueryBuilderIcon style={{ height: 100, width: 100 }} />
            <p>{t('project.candidateScore.pleaseWait')}</p>
          </Box>
        ) : filterEnabled ? (
          <Box my='80px' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <ReportIcon style={{ height: 100, width: 100 }} />
            <p>{t('project.candidateScore.noCandidateScoreFound')}</p>
            <p>{t('project.candidateScore.pleaseAdjustFilters')}</p>
          </Box>
        ) : (
          <Box my='80px' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <ReportIcon style={{ height: 100, width: 100 }} />
            <p>{t('project.candidateScore.candidateScoreNotAvailable')}</p>
            <p>
              <Button variant='text' sx={{ textTransform: 'capitalize', color: '#23cea7' }} onClick={() => setIsEchoSenseOpen(true)}>
                {t('project.candidateScore.clickHere')}
              </Button>
              {t('project.candidateScore.toUploadInterviewTranscript')}
            </p>
            <p>{t('common.or')}</p>
            <LoadingButton
              loading={manualLoading}
              loadingPosition='end'
              disabled={manualLoading}
              size='small'
              color='primary'
              variant='contained'
              className='text-transform-none'
              onClick={handleAddManualScore}
              style={{ width: 220, marginTop: 10 }}
            >
              {t('project.candidateScore.addManualScore')}
            </LoadingButton>
          </Box>
        )}
        <RubricModal open={openRubricModal} setOpen={setOpenRubricModal} rubrics={selectedData || []} rubricLoading={rubricLoading} />
        <EvidenceModal open={openEvidenceModal} setOpen={setOpenEvidenceModal} data={selectedData || {}} evidenceLoading={evidenceLoading} />
        <BinaryScorecardModal open={openBinaryScorecardModal} setOpen={setOpenBinaryScorecardModal} rubrics={selectedData || []} rubricLoading={rubricLoading} />
        <CommentModal open={openCommentModal} setOpen={setOpenCommentModal} fieldKey={activeFieldKey} payloadValue={payloadValue || {}} updateMasterData={updateMasterData} />
        <FilterModal open={openFilterModal} setOpen={setOpenFilterModal} filterHandler={handleFilterConfirm} defaultFilters={initialFilterState} />
      </div>
      <EchoSenseCreateNewRequest
        subRoute={'transcribe'}
        open={isEchoSenseOpen}
        isFromLogAnActivity
        defaultCandidate={{ candidate_id, contact_id: contact_id, full_name: contactName }}
        onSubmitSuccess={() => {
          showToast(t('project.candidateScore.generatedShortly'), 'success');
        }}
        setOpen={setIsEchoSenseOpen}
        processingList={[]}
        completedList={[]}
      />
    </React.Fragment>
  );
}

CandidateScore.propTypes = {
  contactName: PropTypes.string,
  contact_id: PropTypes.string,
  candidate_id: PropTypes.string,
  project_id: PropTypes.string,
  isLogAnActivityOpen: PropTypes.bool
};

export default CandidateScore;
