//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const TermOfInvoice = props => {
  const { label = 'Terms Of Invoice', isDisabled = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const terms_of_invoice = useSelector(state => state.commonReducer.terms_of_invoices);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!terms_of_invoice) {
      dispatch(fetchPickLists('TERMS_OF_INVOICE', 'terms_of_invoices'));
    } else {
      setOptions(terms_of_invoice);
    }
  }, [terms_of_invoice, dispatch]);

  return (
    <CustomDropdown
      {...rest}
      disabled={isDisabled}
      options={options}
      label={label}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return options?.find(item => item?.field_value === option || item?.short_desc === option)?.short_desc;
        }
        return option.short_desc;
      }}
    />
  );
};

TermOfInvoice.propTypes = {
  label: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default TermOfInvoice;
