import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/common/Footer';
import CustomInputField from '../../components/common/StyledComponents/CustomInputField';

export default function ListSave() {
  const { t } = useTranslation();

  const [share, setShare] = useState('');
  const handleChange = event => {
    setShare(event.target.value);
  };

  return (
    <div id='side-pop-up' className='p-4'>
      <form>
        <Stack spacing={2}>
          {/* <FormLabel className="fs-14">
            {t('actions.saveAs')}
          </FormLabel> */}
          <Box>
            <CustomInputField
              id='inputTextStyle'
              color='heading'
              fullWidth
              hiddenLabel
              //placeholder={t('utils.addName')}
              label={t('actions.saveAs')}
              variant='outlined'
            />
          </Box>
          <FormLabel className='fs-14'>{t('reports.share')}</FormLabel>
          <RadioGroup name='share' value={share} onChange={handleChange} row>
            <FormControlLabel control={<Radio />} label={t('reports.shareWithIgnyteUser')} value='Share with Ignyte User' />
            <FormControlLabel control={<Radio />} label={t('utils.privateContact')} value='Private Contact' />
          </RadioGroup>
          <Box>
            <Footer></Footer>
          </Box>
        </Stack>
      </form>
    </div>
  );
}

ListSave.propTypes = {
  location: PropTypes.object
};
