import * as React from 'react';
const KeyBoardSVGComponent = props => (
  <svg width={16} height={17} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <mask
      id='mask0_158_4668'
      style={{
        maskType: 'luminance'
      }}
      maskUnits='userSpaceOnUse'
      x={0}
      y={0}
      width={16}
      height={17}
    >
      <path d='M0 0.136009H16V16.8633H0V0.136009Z' fill='white' />
    </mask>
    <g mask='url(#mask0_158_4668)'>
      <path
        d='M7.96875 12.3857H2.5C1.46447 12.3857 0.625 11.5081 0.625 10.4255V6.5704C0.625 5.4878 1.46447 4.61017 2.5 4.61017H7.96875'
        stroke='black'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.9375 4.61017H13.5C14.5355 4.61017 15.375 5.4878 15.375 6.5704V10.4255C15.375 11.5081 14.5355 12.3857 13.5 12.3857H12.9375'
        stroke='black'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.9375 0.789507C9.31822 0.789507 10.4375 1.95966 10.4375 3.40314V13.5963C10.4375 15.0398 9.31822 16.21 7.9375 16.21'
        stroke='black'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.9375 0.789507C11.5568 0.789507 10.4375 1.95966 10.4375 3.40314V13.5963C10.4375 15.0398 11.5568 16.21 12.9375 16.21'
        stroke='black'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);
export default KeyBoardSVGComponent;
