//-----------------------------------------------------------// In-built Imports // ------------------------------
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
// import { connect } from "react-redux";
import { useSnackbar } from 'notistack';
import { notFoundMessage } from '../../../services/MessageService';
import { renderRichText, SCROLL_TIMEOUT } from '../../../utils/common';
import { DateRenderer, DateTimeRenderer } from '../../Commons/Utils';
import { columnDefs, CustomLoadingOverlayComponent, SUCCESS_STATUS_CODE } from './utils';
// import { fetchUserList } from "../../../actions";
import { Link, useNavigate } from 'react-router-dom';
import { GET, WARNING } from '../../../services/constantService';
// import './index.scss'
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/common/Loader';
import { invoiceSummaryApiIGN } from '../../../services/ApiService';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import AgGridWrapper from '../../../utils/AgGridWrapper';
import { RichTextPopupRendered } from '../../../utils/common';
import './index.scss';
//-----------------------------------------------------------// Internal Imports // ------------------------------
let gridApi, columnApi;
const ProjectApproval = props => {
  const { searchType, setOpen } = props;
  const navigate = useNavigate();
  // const [/* rowCount,  */setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // let gridApi;
  const setGridApi = useRef(null);
  const setColumnApi = useRef(null);
  const contactGridRef = useRef(null);
  const dispatch = useDispatch();
  const [isGridReady, setIsGridReady] = useState(false);
  const [showedNoDataMessage, setShowedNoDataMessage] = useState(false);
  const getApprovals = async () => {
    setIsLoading(true);
    const paginationString = `limit=${20000}&page=${1}`;
    const url = `/approval?${paginationString}&searchApprovalType=${searchType}`;
    const { status, data } = await invoiceSummaryApiIGN(GET, '', {}, url);
    if (status === SUCCESS_STATUS_CODE && data?.data) {
      if (data?.paging?.totalCount === 0 && !showedNoDataMessage) {
        const message = notFoundMessage('records');
        enqueueSnackbar(message, { variant: WARNING });
        setShowedNoDataMessage(true);
      } else if (data?.paging?.totalCount > 0) {
        setShowedNoDataMessage(false);
      }
      setRowData(data?.data);
      setIsLoading(false);
    } else {
      setRowData([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getApprovals();
  }, [searchType]);
  // const onGridReady = params => {
  //   params.columnApi.autoSizeColumns();
  //   gridApi = params.api;
  // };
  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    contactGridRef.current = params;
    setColumnApi.current = params.columnApi;

    dispatch(updateGlobalSearchData({ key: 'gridApi', data: setGridApi }));
    // gridApi?.setGridOption('datasource', dataSource);

    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    // setIsGridReady(true);
    if (!isGridReady) {
      gridApi?.setGridOption('datasource', rowData);
      setIsGridReady(true);
    }
  };
  const idRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.id}`,
          state: { isFromApproval: true }
          //   previousPath: props?.location?.pathname
        }}
        rel='noopener noreferrer'
        onClick={() => {
          setOpen(false);
        }}
      >
        <Typography className='text-link' color='primary'>
          {params.value}
        </Typography>
      </Link>
    );
  };

  const titleRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.id}/Billing/Billing%20Info`,
          state: { isFromApproval: true }
          //   previousPath: props?.location?.pathname
        }}
        rel='noopener noreferrer'
        onClick={() => {
          setOpen(false);
        }}
      >
        <Typography className='text-link' color='primary'>
          {params.value}
          {params?.data?.is_confidential === true ? ' (Confidential)' : ''}
        </Typography>
      </Link>
    );
  };
  // const billingRender = params => {
  //   return <Box className='fs-12'>{params.value}</Box>;
  // };

  const billingRender = params => {
    return renderRichText(params?.value);
  };

  return (
    <Box className='all-candidates d-flex flex-column search-approval-container' id='project-approval'>
      <Loader show={isLoading} />
      <Box className={'d-flex flex-column team-information-table  h-100'}>
        <Box className='grid-container'>
          <Box className='list-view' id='project-approval-grid'>
            <Box className='ag-theme-alpine'>
              <AgGridWrapper
                onGridReady={onGridReady}
                defaultColDef={{
                  minWidth: 1501,
                  resizable: true,
                  sortable: true,
                  filter: true
                }}
                rowHeight={70}
                components={{
                  CustomLoadingOverlayComponent,
                  IdRenderer: idRenderer,
                  TitleRenderer: titleRenderer,
                  DateTimeRenderer,
                  DateRenderer,
                  RichTextPopupRendered,
                  BillingRender: billingRender
                }}
                getRowNodeId={data => data.id}
                blockLoadDebounceMillis={SCROLL_TIMEOUT}
                scrollbarWidth={12}
                rowModelType={'clientSide'}
                rowData={rowData}
                columnDefs={columnDefs()}
                paginationPageSize={50}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                saveGridSetting={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProjectApproval.propTypes = {
  searchType: PropTypes.string,
  setOpen: PropTypes.func
};

export default ProjectApproval;
