import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import Loader from '../../../../components/common/Loader';
import RichText from '../../../../components/common/RichText';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { getUserSelector } from '../../../../selectors';
import { ClientDispositionMap, ClientSuiteDataApi, ignContactDataApi, picklistsDataApis, ProjectCandidateApi } from '../../../../services/ApiService';
import { CLIENT_SUITE_FEEDBACL_SUCCESS, DROP_DOWN_PICKLIST, GET, PATCH, POST } from '../../../../services/constantService';
import { formatDateWithMomentTimeZone, formatTime } from '../../../../utils/date';
import useApi from '../../../../utils/Hooks/useApiHook';
import './../../index.scss';
import ResonTags from './ResonTags';
import SetContent from './SetContent';
function Modal({ data, theme, isLoader = false, clientId, selectedProject, selectedCandidate, onCancel = () => {}, onSave = () => {} }) {
  const { data: dispositionButton } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'DISPOSITION_NAME');
    }
  });
  const userData = useSelector(getUserSelector);
  const [selectedButton, setSelectedButton] = useState('');
  const [loader, setLoader] = useState(false);
  const ACCEPT = 'approved';
  const REJECT = 'rejected';
  const [nextStep, setNextStep] = useState('');
  const [reason, setReason] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [rejectReasons, setRejectReason] = useState([]);
  const [themeColor, setThemeColor] = useState({});
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackloader, setFeedbackloader] = useState(false);
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const [defaultValue, setDefaultValue] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [feedbackEditValues, setFeedbackEditValues] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [userID, setUserID] = useState('');

  useEffect(() => {
    if (userData) {
      setUserID(userData.id);
    }
  }, [userData]);
  const fetchRejectReason = async () => {
    try {
      setLoader(true);
      const res = await picklistsDataApis(GET, DROP_DOWN_PICKLIST.REJECT_REASON);
      setRejectReason(res?.data && res?.data.length > 0 ? res?.data : []);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleFeedBackSave = async data => {
    try {
      setLoader(true);
      let payload = {};
      if (data?.type === 'rejected') {
        let listOfReasons = data.reason ? JSON.stringify(data.reason.map(item => item.short_desc)) : '[]';
        payload = {
          project_id: selectedProject,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          internal_attendees: null,
          external_attendees: null,
          contact_id: selectedCandidate ?? '',
          rating: data?.rating,
          outcome_reason: listOfReasons,
          client_feedback: 'rejected'
        };
      } else {
        payload = {
          project_id: selectedProject,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          internal_attendees: null,
          external_attendees: null,
          contact_id: selectedCandidate ?? '',
          rating: data?.rating,
          outcome_reason: data?.nextStep,
          client_feedback: 'approved'
        };
      }

      const res = await ignContactDataApi(POST, '', payload, 'create-activity');

      //candidate disposition
      const disposition = await ClientDispositionMap(GET, `?project_id=${selectedProject}&disposition_name=${data?.type}`);

      const dispositionStageArray = disposition?.data;
      if (dispositionStageArray.length > 0) {
        const stage = dispositionStageArray[0];
        const updateStagePayload = {
          id: selectedCandidate,
          stage: stage?.stage?.stage_name
        };

        const candidateStageResponse = await ProjectCandidateApi(PATCH, '', updateStagePayload);
      }

      // setFeedbackModal(false);
      enqueueSnackbar(CLIENT_SUITE_FEEDBACL_SUCCESS, 'success');
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      resetForm();
      setLoader(false);
      setFeedbackList(null);
      setFeedbackloader(true);
      fetchFeedbackData(clientId.id);
      setReason([]);
    }
  };

  const resetForm = () => {
    setNextStep('');
    setRating(0);
    setSelectedButton('');
  };

  const handleSave = () => {
    const feedback = {
      type: selectedButton,
      rating,
      comment,
      reason,
      nextStep
    };
    //onSave(feedback);
    handleFeedBackSave(feedback);
  };

  const editFeedbackOnClick = feedback => {
    setFeedbackEditValues(feedback); //
    setNextStep(feedback?.outcome_reason);
    setRating(feedback?.rating);
    setSelectedButton('');
    setDefaultValue(feedback?.notes);
    setComment(feedback?.notes); // to set default value same as edit value
    setEditMode(true); //
    setActiveItem(feedback?.id);

    if (feedback.client_feedback == 'rejected') {
      const outcomeReason = JSON.parse(feedback?.outcome_reason);
      const outcomeReasonArray = outcomeReason?.map(item => ({
        short_desc: item
      }));
      const filteredRejectReasons = rejectReasons?.filter(rejectReason => outcomeReasonArray?.some(item => item.short_desc === rejectReason?.short_desc));
      setReason(filteredRejectReasons);
    }

    feedback?.client_feedback == 'approved' ? setSelectedButton('approved') : setSelectedButton('rejected');
  };

  const handleUpdate = () => {
    const feedbackUpdatedData = {
      type: selectedButton,
      rating,
      comment,
      reason,
      nextStep
    };
    handleFeedBackUpdate(feedbackUpdatedData);
    setLoader(true);
  };

  const handleFeedBackUpdate = async data => {
    try {
      setLoader(true);
      let payloadUpdate = {};
      if (data?.type === 'rejected') {
        let listOfReasons = data.reason ? JSON.stringify(data.reason.map(item => item.short_desc)) : '[]';
        payloadUpdate = {
          project_id: feedbackEditValues.project_id,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          internal_attendees: null,
          external_attendees: null,
          contact_id: feedbackEditValues.contact_id ?? '',
          rating: data?.rating,
          outcome_reason: listOfReasons,
          client_feedback: 'rejected',
          id: feedbackEditValues.id
        };
      } else {
        payloadUpdate = {
          project_id: feedbackEditValues.project_id,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          internal_attendees: null,
          external_attendees: null,
          contact_id: feedbackEditValues.contact_id ?? '',
          rating: data?.rating,
          outcome_reason: data?.nextStep,
          client_feedback: 'approved',
          id: feedbackEditValues.id
        };
      }

      const res = await ignContactDataApi(POST, '', payloadUpdate, 'create-activity');

      //candidate disposition
      const disposition = await ClientDispositionMap(GET, `?project_id=${selectedProject}&disposition_name=${data?.type}`);
      //console.log({ disposition });

      const dispositionStageArray = disposition?.data;
      if (dispositionStageArray.length > 0) {
        const stage = dispositionStageArray[0];
        const updateStagePayload = {
          id: selectedCandidate,
          stage: stage?.stage?.stage_name
        };

        const candidateStageResponse = await ProjectCandidateApi(PATCH, '', updateStagePayload);
      }

      // setFeedbackModal(false);
      enqueueSnackbar(CLIENT_SUITE_FEEDBACL_SUCCESS, 'success');
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      resetForm();
      setLoader(false);
      setFeedbackList(null);
      setFeedbackloader(true);
      fetchFeedbackData(clientId.id);

      //edit;
      setFeedbackEditValues(null); //
      setNextStep(null);
      setRating(null);
      setSelectedButton('');
      setDefaultValue(null);
      setEditMode(false); //
      setComment(null);
      setActiveItem('');
      setReason([]);
    }
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    fetchRejectReason();
  }, []);

  useEffect(() => {
    setLoader(isLoader);
  }, [isLoader]);

  const fetchFeedbackData = async client_id => {
    try {
      setLoader(true);
      const summary = await ClientSuiteDataApi(POST, '', {
        id: client_id,
        tabName: ['SUMMARY']
      });
      if (summary?.data?.rows[0]) {
        const allfeedbackdata = summary?.data?.rows[0]?.contact?.contact_notes_all;
        const feedbackdata = allfeedbackdata?.filter(item => item.notes_type === 'client_feedback');
        const sortedFeedbackData = feedbackdata?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setFeedbackList(sortedFeedbackData);
        setFeedbackloader(false);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      setFeedbackloader(true);
      fetchFeedbackData(clientId.id);
      setFeedbackList(null);
    }
  }, [clientId]);

  return (
    <div className='pt-2 pb-2 w-100 position-relative' style={{ maxHeight: '500px', maxWidth: '1050px' }}>
      <Loader show={loader} />
      <Box className='d-flex flex-row'>
        <Box className='d-flex flex-column w-100 min-400'>
          <Box className='d-flex'>
            {dispositionButton &&
              dispositionButton?.data &&
              dispositionButton?.data?.map(button => (
                <>
                  <CustomButton
                    key={button?.id}
                    buttonText={button?.short_desc}
                    variant={'text'}
                    onClick={() => setSelectedButton(button?.field_value)}
                    type='tertiary-error mr-1 border-red'
                    style={
                      selectedButton == button?.field_value
                        ? { color: '#fff', backgroundColor: themeColor ? theme.secondary_color : '', borderColor: themeColor ? theme.secondary_color : '' }
                        : { color: themeColor ? theme.secondary_color : '', borderColor: themeColor ? theme.secondary_color : '' }
                    }
                  />
                </>
              ))}
          </Box>
          <Box className='mt-4'>
            {selectedButton == ACCEPT && <CustomInputField value={nextStep} label='Next Steps' className='w-100' onChange={e => setNextStep(e.target.value)} />}
            {selectedButton == REJECT && (
              <>
                <CustomDropdown value={reason} options={rejectReasons} label='Reason' className={'w-100'} onChange={(event, value) => setReason(value)} multiple={true} isCheckBox={true} />
              </>
            )}
          </Box>
          <Box className='mt-2'>
            <Box className='fs-12'>Rating</Box>
            <Rating
              name='simple-controlled'
              className='mt-1'
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              size='large'
            />
          </Box>
          <Box className='mt-2 client-portal-rating-modal'>
            <Box className='mb-1 fs-12'>Comments</Box>
            {loader ? <Box className='d-flex w-100' sx={{ height: '180px' }}></Box> : <RichText defaultValue={defaultValue} style={{ height: '180px' }} onChange={val => setComment(val)} />}
          </Box>
          <Box className='mt-5 d-flex justify-content-end align-items-end'>
            <CustomButton buttonText={'Cancel'} variant={'text'} onClick={onCancel} type={'tertiary-error mr-1 '} style={{ color: theme.secondary_color }} />
            <CustomButton
              buttonText={editMode ? 'Update' : 'Save'}
              variant={'text'}
              onClick={editMode ? handleUpdate : handleSave}
              type={'tertiary-error border-red'}
              style={{ backgroundColor: themeColor ? theme.secondary_color : '', color: '#fff' }}
            />
          </Box>
        </Box>
        <Box className='d-flex flex-column w-40p pr-0'>
          <Typography variant='body2' className='fs-12'>
            <strong>Feedback History</strong>
          </Typography>
          <Box className='scroll-box-fh'>
            <Loader show={feedbackloader} />
            {feedbackList?.length > 0 ? (
              <>
                {feedbackList?.map((feedback, index) => (
                  <Card key={index} sx={{ marginBottom: 2, backgroundColor: feedback.id === activeItem ? alpha(theme.secondary_color, 0.05) : '' }}>
                    <CardContent>
                      <Box className='d-flex flex-row justify-content-between'>
                        <Rating name='half-rating' defaultValue={feedback.rating} precision={0.5} readOnly />
                        <Chip
                          label={feedback?.client_feedback}
                          size='small'
                          color='primary'
                          key={index}
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: '13px',
                            background: themeColor ? themeColor?.secondary_color : ''
                          }}
                        />
                      </Box>
                      {feedback?.client_feedback == 'rejected' ? (
                        <>
                          <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                            Reason
                          </Typography>
                          <ResonTags data={feedback?.outcome_reason} />
                        </>
                      ) : (
                        <>
                          {feedback?.outcome_reason && (
                            <>
                              <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                                Next Step
                              </Typography>
                              <Typography variant='body2' className='fs-13' sx={{ mb: 2, mt: 1 }}>
                                {feedback?.outcome_reason}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                      {feedback?.notes && (
                        <>
                          <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                            Comment
                          </Typography>
                          <SetContent note={feedback?.notes} />
                        </>
                      )}

                      <Box className='d-flex flex-row w-100'>
                        <Box className='d-flex flex-column w-100'>
                          <Typography variant='body2' sx={{ color: 'text.secondary' }} className='fs-11 d-flex flex-row align-items-center'>
                            <PersonIcon className='fs-11 mr-1' /> {feedback?.created_user?.name}
                          </Typography>
                          <Typography variant='body2' sx={{ color: 'text.secondary' }} className='fs-11 d-flex flex-row align-items-center'>
                            <ScheduleIcon className='fs-11 mr-1' />
                            {formatDateWithMomentTimeZone(feedback?.created_at)} - {formatTime(feedback?.created_at)}
                          </Typography>
                        </Box>
                        {userID === feedback?.created_by && (
                          <Box className='d-flex'>
                            <IconButton aria-label='delete' onClick={() => editFeedbackOnClick(feedback)}>
                              <EditIcon className='fs-16' />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </>
            ) : (
              <p className='no-feedback'>No feedback shared yet </p>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

Modal.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isLoader: PropTypes.bool,
  theme: PropTypes.object,
  clientId: PropTypes.string,
  selectedProject: PropTypes.string,
  selectedCandidate: PropTypes.string
};

export default Modal;
