import React /* useState */ from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { Box } from '@mui/material';
import BillingForm from './Components/BillingForm';
// import PercentageOfBase from '../../AddBD/BillingDetails/Components/PercentageOfBase';
import { useState } from 'react';
import { currencyWithCommas, getCurrencyPrefix } from '../../../Containers/Commons/Utils';
import { BILLING_VALIDATIONS } from '../../../services/constantService';
import { getFixedDecimal } from '../../../utils/common';
import variables from '../../../variables.scss';
import TotalRevenue from './Components/EstimatedRevenue';
import IndirectFee from './Components/IndirectFee';
import PercentageOfBase from './Components/PercentageOfBase';

const BillingDetails = props => {
  console.log({ props });
  const { register, watch, setValue, currentValues, currencyDetails, isHide, isEditing } = props;
  const [totalEstimate, setTotalEstimate] = useState(0);
  const fe_Cap_amount = watch('fee_cap_amount');
  const is_fee_cap = watch('is_fee_cap');

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix(watch('bd_currency_values.code') || '');
    const percentage =
      data?.reduce((prev, curr) => {
        if (curr[key]) prev = prev + Number(curr[key]);
        return prev;
      }, 0) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage };
  };

  const getGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('estimated_percentage_bases'), 'calculated_estimated_amt', false);
    const totalIndirectFee = getTotalPercentageOfBase(watch('project_indirect_fees'), 'calculation_amt');
    setValue('estimated_percentage_base', totalPercentageOfBase.number);
    setValue('estimated_indirect_total_fee', totalIndirectFee.number);
    setValue('estimated_revenue', totalPercentageOfBase?.number + totalIndirectFee?.number);
    setTotalEstimate(totalPercentageOfBase?.number + totalIndirectFee?.number);
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  };

  const getValueWithCurrency = data => {
    return `${getCurrencyPrefix(watch('bd_currency_values.code') || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`;
  };
  return (
    <Box>
      <BillingForm register={register} setValue={setValue} watch={watch} currentValues={currentValues} currencyDetails={currencyDetails} isHide={isHide} isEditing={isEditing} />
      <Box className='mt-5 w-50 ml-3'></Box>

      <Box id='team-info' className='d-flex justify-content-center mt-5'>
        <Box className='mr-3' sx={{ width: '49vw' }}>
          <PercentageOfBase
            register={register}
            setValue={setValue}
            currentValues={currentValues}
            watch={watch}
            fee_type={watch('fee_type')}
            type='estimated'
            disabled={isHide}
            getValueWithCurrency={getValueWithCurrency}
          />
        </Box>
        <Box className='w-25'>
          <TotalRevenue watch={watch} getTotalPercentageOfBase={getTotalPercentageOfBase} getGrandTotal={getGrandTotal} disabled={isHide} />
          {is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && (
            <Box sx={{ marginTop: '23px', marginLeft: '15px' }}>
              <Box component='span' sx={{ color: variables.error, marginTop: '20px' }}>
                {BILLING_VALIDATIONS.FEE_CAP_AMOUNT}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className='d-flex justify-content-center'>
        <Box id='team-info' className='mt-5' sx={{ width: '70vw' }}>
          <IndirectFee
            register={register}
            setValue={setValue}
            currentValues={currentValues}
            watch={watch}
            getTotalPercentageOfBase={getTotalPercentageOfBase}
            disabled={isHide}
            getValueWithCurrency={getValueWithCurrency}
          />
        </Box>
      </Box>
    </Box>
  );
};

BillingDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object,
  isHide: PropTypes.bool,
  isEditing: PropTypes.bool
};

export default BillingDetails;
