//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { columnDefs, CustomLoadingOverlayComponent, PAGE_LIMIT, SUCCESS_STATUS_CODE } from './utils';

// -------------------------------------------------// Internal Imports// -------------------------------------------------

import { useDispatch, useSelector } from 'react-redux';
import { DateRenderer, DateTimeRenderer } from '../../../Containers/Commons/Utils';
import { CompanyNameRenderer } from '../../../Containers/Companies/Utils';
import { contactDataApi } from '../../../services/ApiService';
import { GET, WARNING } from '../../../services/constantService';
import { notFoundMessage } from '../../../services/MessageService';
import { contactActions } from '../../../store/contactSlice';

export const SearchIdRenderer = params => {
  return (
    <Link to={`/searches/${params.value?.id}/active-candidates`} target='_blank' rel='noopener noreferrer'>
      <Typography className='text-link' color='primary'>
        {params.value?.job_title}
      </Typography>
    </Link>
  );
};

let gridApi;
function Attributes(props) {
  const { contact } = props;
  const [rowCount, setRowCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const contact_attribute = useSelector(state => state.contactReducer.contact_attribute);
  const dataSource = {
    getRows: async params => {
      if (!contact_attribute || params.sortModel?.length) {
        gridApi.showLoadingOverlay();
        let sub_route = `attributes?&limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
        if (params.sortModel?.length) {
          sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`);
        }
        const { status, data } = await contactDataApi(GET, contact.id, '', sub_route);
        if (status === SUCCESS_STATUS_CODE) {
          dispatch(contactActions.updateContactAttributes(data));
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage('records');
            enqueueSnackbar(message, { variant: WARNING });
          }
          setRowCount(data.paging?.totalCount);
          params.successCallback(data.data, data.paging?.totalCount);
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } else {
        if (contact_attribute?.paging?.totalCount === 0) {
          const message = notFoundMessage('records');
          enqueueSnackbar(message, { variant: WARNING });
        }
        setRowCount(contact_attribute.paging?.totalCount);
        params.successCallback(contact_attribute.data, contact_attribute.paging?.totalCount);
      }
    },
    rowCount: null
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const onGridReady = params => {
    gridApi = params.api;
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params.data?.company} />;
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-end'>
        <div className='d-flex  align-items-center' style={{ padding: '15px 25px 0 25px' }}>
          <div className='action-container' style={{ minWidth: 0 }} onClick={() => resetSort()}>
            <span className='action-text'>Reset Sort</span>
          </div>
          <Typography>Total count: {rowCount}</Typography>
        </div>
      </div>
      <div id='myGrid' className='ag-theme-alpine'>
        <AgGridReact
          onGridReady={onGridReady}
          enableBrowserTooltips={true}
          defaultColDef={{
            minWidth: 20,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc', null]
          }}
          tooltipShowDelay={0}
          scrollbarWidth={12}
          suppressHorizontalScroll={false}
          cacheBlockSize={PAGE_LIMIT}
          loadingOverlayComponent={'CustomLoadingOverlayComponent'}
          frameworkComponents={{
            SearchIdRenderer,
            CustomLoadingOverlayComponent,
            NameRenderer: NameRenderer,
            DateTimeRenderer,
            DateRenderer
          }}
          rowModelType={'infinite'}
          datasource={dataSource}
          columnDefs={columnDefs}
          paginationPageSize={20}
          colResizeDefault={'shift'}
        />
      </div>
    </React.Fragment>
  );
}

Attributes.propTypes = {
  contact: PropTypes.object
};

export default Attributes;
