import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FluidLayoutComponent from '../../../../src/components/common/FluidLayoutComponent';
import security_icon from '../../../assets/icons/security_icon.svg';

import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import DocumentCard from '../../../../src/components/common/FunctionalComponents/DocumentCard';
import { ERROR, GET, IGN_API, PATCH, POST, SUCCESS } from '../../../services/constantService';

import { enqueueSnackbar } from 'notistack';
import CustomButton from '../../../components/common/CustomButton';
import Loader from '../../../components/common/Loader';
import { AllDocumentsApi } from '../../../services/ApiService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { splitLastIfMultiple } from '../../../utils/common';
import AddProjectDocuments from './AddProjectDocument';
// import React, { useContext, useEffect, useState } from "react";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as AttachmentsService from '../../../services/AttachmentsService.js';
import { ProjectDataContext } from './Context/index.js';

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const ProjectDocuments = props => {
  const { register = () => {}, unregister = () => {}, setValue = () => {} } = props;
  const { projectData } = useContext(ProjectDataContext);
  const projectId = projectData.id;
  let query = useQuery();
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translateMessage } = useCustomMessageHook();
  const [data, setData] = useState(null);
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setIsEdit(false);
  };
  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };
  useEffect(() => {
    fetchData();
  }, [projectId]);

  const fetchData = async () => {
    try {
      const sub_route = `${IGN_API.project}/${projectId ? projectId : query.get('id')}/documents`;
      const { data } = await AllDocumentsApi(GET, sub_route);
      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const download = async index => {
    // const token = await getAccessToken();
    let attachmentId = data[index].id;
    try {
      // const sub_route = `/documents/${attachmentId}/download`
      const url = await AttachmentsService.downloadProjectDocuments(attachmentId);
      console.log('>>>>>>>>>>>>>>>>>>>>>>>', url);
      // const url = `http://localhost:8080/api/v1/project/documents/${attachmentId}/download?token=${token}`
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);
        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        const message = translateMessage('UnableMessage', false, 'Document', 'download');

        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
    }
  };

  const uploadDocuments = async (document, item) => {
    if (!document) {
      enqueueSnackbar('Attachment data not fill', { variant: ERROR });
      return;
    }

    if (!document.doctype_code && !document.file_name) {
      const message = translateMessage('Required', ...splitLastIfMultiple(['Type', 'Name']));
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!document.doctype_code) {
      const message = translateMessage('Required', false, 'Type');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!document.file_name) {
      const message = translateMessage('Required', false, 'Name');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!isEdit) {
      if (!document.file) {
        const message = translateMessage('SelectFile', false, '');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    let payload = {};
    if (isEdit) {
      payload = {
        id: item.id,
        project_id: item.project_id,
        doctype_code: document.doctype_code,
        file_name: document.file_name,
        document_visibilities: document.document_visibilities
      };
      const sub_route = `${IGN_API.project}/documents/${projectId}`;
      const { status, data } = await AllDocumentsApi(PATCH, sub_route, payload);
      console.log(status, data);
      if (status === 200) {
        const message = translateMessage('Successfully', false, 'Attachment', 'uploaded');
        enqueueSnackbar(message, { variant: SUCCESS });
        fetchData();
        setIsPopupOpen(false);
      } else {
        enqueueSnackbar(data?.message || 'Attachment', { variant: ERROR });
      }
    } else {
      let fileNameSplit = document.file?.name?.split('.');
      let bodyFormData = new FormData();
      bodyFormData.append('file_name', `${document.file_name}.${fileNameSplit[fileNameSplit.length - 1]}`);
      bodyFormData.append('doctype_code', document.doctype_code);
      bodyFormData.append('file', document.file);
      bodyFormData.append('document_visibilities', JSON.stringify(document.document_visibilities));
      const sub_route = `${IGN_API.project}/${projectId}/documents`;
      const { status, data } = await AllDocumentsApi(POST, sub_route, bodyFormData);
      processUpload(status, data, document.file.name);
    }
  };

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      fetchData();
      const message = translateMessage('Successfully', false, `${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsPopupOpen(false);
    } else {
      const message = translateMessage('UnableMessage', false, `${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const colSize = data?.length > 9 ? 3 : 4;
  return (
    <>
      <Loader show={!data ? true : false} />
      {data ? (
        <>
          <Loader show={loading} />
          <Box id='document' className='final-page-layout'>
            <Typography className='compensation-info text-label mb-3 pt-3'>{t('utils.documents')}</Typography>
            <Box className='second-page-layout h-auto custom-scroll'>
              <Box className='card-container d-flex pl-3 pr-3 doc-container'>
                <FluidLayoutComponent>
                  {data?.map((card, cardIndex) => (
                    <>
                      <Col key={cardIndex} xs={12} sm={12} md={6} lg={4} xl={colSize} className='p-3'>
                        <DocumentCard
                          data={card}
                          index={cardIndex}
                          editItems={openPopupEditing}
                          download={download}
                          downloadIcon={<CloudDownloadIcon />}
                          addIcon={card.is_confidential ? security_icon : ''}
                          styleData={{
                            titleSize: 13,
                            textSize: 11,
                            height: 125,
                            width: colSize === 3 ? 400 : 300
                          }}
                          setLoading={setLoading}
                          type={'project_document'}
                          getContact={fetchData}
                        ></DocumentCard>
                      </Col>
                    </>
                  ))}
                </FluidLayoutComponent>
              </Box>
              <Box className='add-document ml-3 pl-3 pt-2'>
                <Box className='add-another pl-0'>
                  {/* <AddButton title="Add another" /> */}
                  <CustomButton
                    variant='outlined'
                    type={'secondary'}
                    size={'medium'}
                    buttonText={data?.length === 0 ? t('actions.add') : t('actions.addAnotherActivity')}
                    customWidth={120}
                    iconLeft={<AddIcon sx={{ width: 14 }} />}
                    onClick={handleOpenPopup}
                  />
                </Box>
                {isPopupOpen && (
                  <AddProjectDocuments
                    title={`${isEdit ? `${t('actions.updateDocument')}` : `${t('actions.addDocument')}`} `}
                    setIsPopupOpen={setIsPopupOpen}
                    isPopupOpen={isPopupOpen}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    register={register}
                    unregister={unregister}
                    handleSave={uploadDocuments}
                    setValue={setValue}
                    data={isEdit ? data[indexValue] : null}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};

ProjectDocuments.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func
};

export default ProjectDocuments;
