import { Box, Checkbox, Typography } from '@mui/material';
import * as React from 'react';
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../Containers/Commons/DatePickerStyle/index.scss';
import { formatDate, formatDateUtcLocal } from '../../../../utils/date';
import CustomPopup from '../../../common/CustomPopup';
import DatePickerCommon from '../../../common/DatePicker/DatePicker';
import CountrySelection from '../../../common/FunctionalComponents/CountrySelection';
import LevelOfEducationTypeSelection from '../../../common/FunctionalComponents/LevelOfEducationTypeSelection';
import Loader from '../../../common/Loader';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
export default function AddEductionAndExperience(props) {
  const { t } = useTranslation();

  const { title, setIsPopupOpen, isPopupOpen, register = () => {}, unregister = () => {}, handleSave = () => {}, setValue = () => {}, data, setIsEdit, isEditing } = props;
  const [isWorking, setIsWorking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [educationEndDate, setEducationEndDate] = useState(null);

  const handleWorkingCheckChange = event => {
    setIsWorking(event.target.checked);
    setValue('is_present', event.target.checked, { shouldDirty: true });
  };
  useEffect(() => {
    setIsWorking((data?.is_present && data.is_present) || false);
  }, [data]);

  React.useEffect(() => {
    register('degree_name');
    register('major');
    register('school_name');
    register('country_id');
    register('start_date');
    register('end_date');
    register('is_present');
    return () => {
      unregister('contact_job_functions');
      unregister('degree_name');
      unregister('major');
      unregister('school_name');
      unregister('country_id');
      unregister('start_date');
      unregister('end_date');
      unregister('is_present');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (isEditing) {
      setValue('degree_name', data?.degree_name);
      setValue('major', data?.major);
      setValue('school_name', data?.school_name);
      setValue('country_id', data?.country_id);
      setValue('start_date', data?.start_date);
      setValue('end_date', data?.end_date);
      setValue('is_present', isWorking);
    }
  }, [isEditing]);
  useEffect(() => {
    setValue('is_present', isWorking, { shouldDirty: true });
    setValue('major', '-', { shouldDirty: true });
  }, []);
  useEffect(() => {
    if (isWorking) {
      setValue('end_date', null, { shouldDirty: true });
    } else {
      setValue('end_date', educationEndDate ? educationEndDate : data?.end_date, { shouldDirty: true });
    }
  }, [isWorking]);

  const saveData = async () => {
    setIsLoading(true);
    await handleSave();
    setIsLoading(false);
  };
  const handleClose = () => {
    setIsPopupOpen(false);
    setIsEdit(false);
  };

  return (
    <CustomPopup title={title} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={saveData}>
      <Box id='education-experiences' className='education-container-experience'>
        <Loader show={isLoading} />
        <Box className='my-cards flex flex-wrap'>
          <Box className='form-section flex flex-column'>
            <Typography className='text-label body-header-title'>{t('utils.levelOfEducation')}</Typography>
            <Box className='section-content flex flex-row flex-wrap d-flex pt-2 pb-3 justify-content-between'>
              <Box className='row d-flex pl-3'>
                <Box className='col-md-6'>
                  <Box className='education-input-base'>
                    {/* <Typography className="pb-2" variant="body2">
                      {t('utils.levelOfEducation')}
                    </Typography> */}
                    <Box className='education-input'>
                      <LevelOfEducationTypeSelection
                        onChange={(e, data) => {
                          setValue('degree_name', data?.value, { shouldDirty: true });
                        }}
                        label={t('utils.levelOfEducation')}
                        defaultValue={data?.degree_name || data?.education_level}
                        required={true}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className='col-md-6'>
                  {/* <Box className='education-input-base'> */}
                  <Box className='education-input'>
                    {/* <FeildOfStudySelection
                        onChange={(e, data) => {
                          setValue('major', data?.value, { shouldDirty: true });
                        }}
                        label={t('utils.fieldOfStudy')}
                        defaultValue={data?.study_type || data?.major}
                        required={true}
                      /> */}
                  </Box>
                  {/* </Box> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='my-cards flex flex-wrap'>
          <Box className='form-section flex flex-column'>
            <Typography className='text-label pt-2 body-header-title'>{t('contacts.addContact.basicInfo')}</Typography>
            <Box className='section-content'>
              <Box className='flex flex-row flex-wrap d-flex p-2 pl-3 justify-content-between'>
                <Box className='row d-flex '>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      {/* <Typography className="pb-2" variant="body2">
                        {t('utils.schoolName')}
                      </Typography> */}
                      <Box className='education-input'>
                        <CustomInputField
                          className='name-input-box'
                          onChange={e => {
                            setValue('school_name', e.target.value, { shouldDirty: true });
                          }}
                          label={t('utils.schoolName')}
                          defaultValue={data?.school_name}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      {/* <Typography className="pb-2" variant="body2">
                        {t('contacts.addContact.country')}
                      </Typography> */}
                      <Box className='education-input'>
                        <CountrySelection
                          onChange={(e, data) => {
                            setValue('country_id', data?.id, { shouldDirty: true });
                          }}
                          label={t('contacts.addContact.country')}
                          defaultValue={data?.country}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className='flex flex-row flex-wrap d-flex p-2'>
                <Box className='row d-flex pl-2'>
                  <Box className='col-md-6'>
                    {/* <Typography className="pb-2" variant="body2">
                      {t('utils.from')}
                    </Typography> */}
                    <Box className='flex justify-center align-center'>
                      <DatePickerCommon
                        value={formatDate(data?.start_date, 'DD-MM-YYYY' || null)}
                        onChange={e => {
                          const selectedDate = e?.$d;
                          // setValue("start_date", selectedDate ? selectedDate : null, { shouldDirty: true })
                          setValue('start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                          setDateRange({ ...dateRange, start: e });
                        }}
                        maxDate={dateRange.end}
                        label={t('utils.from')}
                        disableFuture
                      />
                      {/* </Box> */}
                    </Box>
                    {/* {!showError.isValid && showError.place === "education" && <Box className="error-message fs-12">{`${showError.field === FROM ? showError.message : ""}`}</Box>} */}
                  </Box>
                  <Box className='col-md-6'>
                    {/* <Typography className="pb-2" variant="body2">
                      {t('utils.to')}
                    </Typography> */}

                    <Box className='flex justify-center align-center'>
                      <DatePickerCommon
                        value={formatDate(data?.end_date, 'DD-MM-YYYY')}
                        onChange={e => {
                          const selectedDate = e?.$d;
                          setEducationEndDate(selectedDate);
                          // setValue("end_date", selectedDate ? selectedDate : null, { shouldDirty: true });
                          setValue('end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                          setDateRange({ ...dateRange, end: e });
                        }}
                        label={t('utils.to')}
                        minDate={dateRange.start}
                        disabled={isWorking}
                      />
                      {/* </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className='flex flex-row flex-wrap d-flex p-2'>
                <Box className=' row d-flex pl-1 w-100'>
                  <Box className='col-md-6'>
                    <Typography className='pb-2' variant='body2'>
                      {t('utils.timePeriod')}
                    </Typography>
                    <Box className='input-field flex align-items-center d-flex'>
                      <Checkbox className='compensation-checkbox' checked={isWorking} onChange={handleWorkingCheckChange}></Checkbox>
                      <Typography className='ml-2 check-box-title'>{t('utils.currentlyEnrolled')}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomPopup>
  );
}

AddEductionAndExperience.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  watch: PropTypes.func,
  data: PropTypes.object,
  setIsEdit: PropTypes.func,
  isEditing: PropTypes.bool
};
