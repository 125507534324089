import { Box, Button, Checkbox, CircularProgress, Dialog, Grid, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPdlAndContactDataApi, pdlContactUpdateApi, pdlDataRefreshApi } from '../../../services/ApiService';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import ActionButtonGroup from './ActionButtonGroup';
import DialogHeader from './DialogHeader';
import './EnrichContact.scss';

const EnrichContact = ({ open, onClose, data }) => {
  const { t } = useTranslation();
  const { t: tv2 } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pdlResponse, setPdlResponse] = useState(null);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState(null);
  const [isPrimaryPhoneNumber, setIsPrimaryPhoneNumber] = useState(false);
  // States for the Job Title
  const [currentRecordForJobTitle, setCurrentRecordForJobTitle] = useState({
    jobTitle: ''
  });
  const [isSaving, setIsSaving] = useState(false);
  const [enrichedRecordsForJobTitle, setEnrichedRecordsForJobTitle] = useState('');
  const [pdlRecordForJobTitle, setPdlRecordForJobTitle] = useState({
    jobTitle: ''
  });
  const [addedFromJobTitle, setAddedFromJotTitle] = useState('current');

  // States for the Industry
  const [currentRecordForIndustry, setCurrentRecordForIndustry] = useState({
    industry: ''
  });
  const [enrichedRecordsForIndustry, setEnrichedRecordsForIndustry] = useState([]);
  const [pdlRecordForIndustry, setPdlRecordForIndustry] = useState({
    industry: ''
  });
  const [addedFromIndustry, setAddedFromIndustry] = useState({});

  // States for Job Functions
  const [currentRecordForFunctions, setCurrentRecordForFunctions] = useState({
    jobFunctions: ''
  });
  const [enrichedRecordsForFunctions, setEnrichedRecordsForFunctions] = useState([]);
  const [pdlRecordForFunctions, setPdlRecordForFunctions] = useState({
    jobFunctions: ''
  });
  const [addedFromFunctions, setAddedFromFunctions] = useState({});

  // States for Countries
  const [currentRecordForCountry, setCurrentRecordForCountry] = useState({
    country: ''
  });
  const [enrichedRecordsForCountry, setEnrichedRecordsForCountry] = useState('');
  const [pdlRecordForCountry, setPdlRecordForCountry] = useState({
    country: ''
  });
  const [addedFromCountry, setAddedFromCountry] = useState('current');

  // States for LinkedIn URL
  const [currentRecordForLinkedIn, setCurrentRecordForLinkedIn] = useState({
    linkedInUrl: ''
  });
  const [enrichedRecordsForLinkedIn, setEnrichedRecordsForLinkedIn] = useState('');
  const [pdlRecordForLinkedIn, setPdlRecordForLinkedIn] = useState({
    linkedInUrl: ''
  });
  const [addedFromLinkedIn, setAddedFromLinkedIn] = useState('current');

  // States for Job History
  const [currentRecordForJobHistory, setCurrentRecordForJobHistory] = useState([]);
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [enrichedRecordForJobHistory, setEnrichedRecordForJobHistory] = useState([]);
  const [pdlRecordForJobHistory, setPdlRecordForJobHistory] = useState([]);
  const [selectedCurrentJobHistory, setSelectedCurrentJobHistory] = useState([]);
  const [selectedPdlJobHistory, setSelectedPdlJobHistory] = useState([]);
  const [addedJobHistory, setAddedJobHistory] = useState({});
  // States for First Name , Last Name , current Company

  // States for phone Number
  const [currentPhoneNumbers, setCurrentPhoneNumbers] = useState([]);
  const [enrichedPhoneNumbers, setEnrichedPhoneNumbers] = useState([]);
  const [pdlPhoneNumbers, setPdlPhoneNumbers] = useState([]);
  const [selectedCurrentPhoneNumbers, setSelectedCurrentPhoneNumbers] = useState([]);
  const [selectedPdlPhoneNumbers, setSelectedPdlPhoneNumbers] = useState([]);
  // const [selectedEnrichedPhoneNumbers, setSelectedEnrichedPhoneNumbers] = useState([]);
  const [addedPhoneNumbers, setAddedPhoneNumbers] = useState({});

  // States for Email
  const [currentEmail, setCurrentEmail] = useState([]);
  const [enrichedEmail, setEnrichedEmail] = useState([]);
  const [pdlEmail, setPdlEmail] = useState([]);
  const [selectedCurrentEmail, setSelectedCurrentEmail] = useState([]);
  const [selectedPdlEmail, setSelectedPdlEmail] = useState([]);
  const [addedEmails, setAddedEmails] = useState({});

  // States for Educational Details
  const [currentEducation, setCurrentEducation] = useState([]);
  const [enrichedEducation, setEnrichedEducation] = useState([]);
  const [pdlEducation, setPdlEducation] = useState([]);
  const [addedEducation, setAddedEducation] = useState({});
  const [selectedCurrentEducation, setSelectedCurrentEducation] = useState([]);
  const [selectedPdlEducation, setSelectedPdlEducation] = useState([]);
  // const [selectedEnrichedEducation, setSelectedEnrichedEducation] = useState([]);
  const [generalDetails, setGeneralDetails] = useState({
    first_name: '',
    last_name: '',
    current_company: ''
  });
  const [generalPdlDetails, setGeneralPdlDetails] = useState({
    first_name: '',
    last_name: '',
    current_company: '',
    linkedin_username: '',
    pdl_id: ''
  });

  const [selectedEnrichedEmail, setSelectedEnrichedEmail] = useState({
    current: [],
    pdl: []
  });
  const [selectedEnrichedEmailSource, setSelectedEnrichedEmailSource] = useState({
    current: false,
    pdl: false
  });
  const [selectedEnrichedEducation, setSelectedEnrichedEducation] = useState({
    current: [],
    pdl: []
  });
  const [selectedEnrichedEducationSource, setSelectedEnrichedEducationSource] = useState({
    current: false,
    pdl: false
  });
  const [selectedEnrichedJobHistory, setSelectedEnrichedJobHistory] = useState({
    current: [],
    pdl: []
  });
  const [selectedEnrichedJobHistorySource, setSelectedEnrichedJobHistorySource] = useState({
    current: false,
    pdl: false
  });
  const [selectedEnrichedPhoneNumbers, setSelectedEnrichedPhoneNumbers] = useState({
    current: [],
    pdl: []
  });
  const [selectedEnrichedPhoneNumberSource, setSelectedEnrichedPhoneNumberSource] = useState({
    current: false,
    pdl: false
  });
  // Functionalities for the Job title
  useEffect(() => {
    setEnrichedRecordsForJobTitle(currentRecordForJobTitle.jobTitle);
  }, [currentRecordForJobTitle]);
  const addJobTitleToEnrich = (record, source) => {
    if (!enrichedRecordsForJobTitle.includes(record)) {
      setEnrichedRecordsForJobTitle(record);
      setAddedFromJotTitle(source);
    }
  };
  const handlePrimaryPhoneChange = index => {
    setPrimaryPhoneNumber(prevIndex => (prevIndex === index ? null : index));
    setEnrichedPhoneNumbers(prevNumbers =>
      prevNumbers.map((num, idx) => ({
        ...num,
        isPrimary: idx === index
      }))
    );
  };

  const removeJobTitleFromEnrichd = () => {
    setEnrichedRecordsForJobTitle('');
    setAddedFromJotTitle('current');
  };
  // Functions for Industry
  const addIndustryToEnrich = (record, source) => {
    setEnrichedRecordsForIndustry(prevRecords => {
      if (!prevRecords.includes(record)) {
        return [...prevRecords, record];
      }
      return prevRecords;
    });
    setAddedFromIndustry(prev => ({ ...prev, [record]: source }));
  };

  const removeIndustryFromEnriched = record => {
    setEnrichedRecordsForIndustry(prevRecords => prevRecords.filter(industry => industry !== record));
    setAddedFromIndustry(prev => {
      const newState = { ...prev };
      delete newState[record];
      return newState;
    });
  };
  // Functions for Job functions
  const addFunctionToEnrich = (record, source) => {
    setEnrichedRecordsForFunctions(prevRecords => {
      if (!prevRecords.includes(record)) {
        return [...prevRecords, record];
      }
      return prevRecords;
    });
    setAddedFromFunctions(prev => ({ ...prev, [record]: source }));
  };

  const removeFunctionFromEnriched = record => {
    setEnrichedRecordsForFunctions(prevRecords => prevRecords.filter(func => func !== record));
    setAddedFromFunctions(prev => {
      const newState = { ...prev };
      delete newState[record];
      return newState;
    });
  };
  // Functions for country
  useEffect(() => {
    setEnrichedRecordsForCountry(currentRecordForCountry.country);
  }, [currentRecordForCountry]);
  const addCountryToEnrich = (record, source) => {
    if (!enrichedRecordsForCountry.includes(record)) {
      setEnrichedRecordsForCountry(record);
      setAddedFromCountry(source);
    }
  };
  const removeCountryFromEnrichd = () => {
    setEnrichedRecordsForCountry('');
    setAddedFromCountry('current');
  };

  // Functions for LinkedIn URL
  useEffect(() => {
    setEnrichedRecordsForLinkedIn(currentRecordForLinkedIn.linkedInUrl);
  }, [currentRecordForLinkedIn]);
  const addLinkedInToEnrich = (record, source) => {
    if (!enrichedRecordsForLinkedIn.includes(record)) {
      setEnrichedRecordsForLinkedIn(record);
      setAddedFromLinkedIn(source);
    }
  };
  const removeLinkedInFromEnrichd = () => {
    setEnrichedRecordsForLinkedIn('');
    setAddedFromLinkedIn('current');
  };

  useEffect(() => {
    // Industry
    setEnrichedRecordsForIndustry([currentRecordForIndustry.industry]);
    setAddedFromIndustry({ [currentRecordForIndustry.industry]: 'current' });

    // Job Functions
    setEnrichedRecordsForFunctions([currentRecordForFunctions.jobFunctions]);
    setAddedFromFunctions({ [currentRecordForFunctions.jobFunctions]: 'current' });

    // Job History
    // setEnrichedRecordForJobHistory(currentRecordForJobHistory.map(job => ({ ...job, source: 'current' })));
    setEnrichedRecordForJobHistory(currentRecordForJobHistory.map(job => ({ ...job, source: 'current' })));
    const initialJobHistory = {};
    currentRecordForJobHistory.forEach(job => {
      const key = `${job.company_name}-${job.title}-${job.start_year}`;
      initialJobHistory[key] = 'current';
    });
    setAddedJobHistory(initialJobHistory);

    // Phone Numbers
    setEnrichedPhoneNumbers(currentPhoneNumbers.map((number, index) => ({ number, source: 'current', isPrimary: index === isPrimaryPhoneNumber })));
    const initialPhoneNumbers = {};
    currentPhoneNumbers.forEach(number => {
      initialPhoneNumbers[number] = 'current';
    });
    setAddedPhoneNumbers(initialPhoneNumbers);

    // Email
    setEnrichedEmail(currentEmail.map(email => ({ ...email, source: 'current' })));
    const initialEmails = {};
    currentEmail.forEach(email => {
      initialEmails[email.address] = 'current';
    });
    setAddedEmails(initialEmails);

    // Educational Details
    setEnrichedEducation(currentEducation.map(edu => ({ ...edu, source: 'current' })));
    const initialEducation = {};
    currentEducation.forEach(edu => {
      const key = `${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`;
      initialEducation[key] = 'current';
    });
    setAddedEducation(initialEducation);
  }, [currentRecordForIndustry, currentRecordForFunctions, currentRecordForJobHistory, currentPhoneNumbers, currentEmail, currentEducation]);
  const handleCurrentJobHistorySelect = index => {
    setSelectedCurrentJobHistory(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handlePdlJobHistorySelect = index => {
    setSelectedPdlJobHistory(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const addSelectedJobHistoryToEnrich = source => {
    const selectedJobs = source === 'current' ? selectedCurrentJobHistory : selectedPdlJobHistory;
    const jobList = source === 'current' ? currentRecordForJobHistory : pdlRecordForJobHistory;

    const newJobs = selectedJobs.map(index => ({
      ...jobList[index],
      source: source
    }));

    setEnrichedRecordForJobHistory(prev => {
      const updatedJobs = [...prev];
      newJobs.forEach(job => {
        const key = `${job.company_name}-${job.title}-${job.start_year}`;
        if (!updatedJobs.some(existingJob => existingJob.company_name === job.company_name && existingJob.title === job.title && existingJob.start_year === job.start_year)) {
          updatedJobs.push(job);
          setAddedJobHistory(prev => ({ ...prev, [key]: source }));
        }
      });
      return updatedJobs;
    });

    if (source === 'current') {
      setSelectedCurrentJobHistory([]);
    } else {
      setSelectedPdlJobHistory([]);
    }
  };
  const parseYear = dateString => {
    if (typeof dateString === 'number') return dateString;
    if (!dateString) return '';
    if (dateString.includes('-')) {
      // Handle "YYYY-MM" format
      return dateString.split('-')[0];
    }
    // Handle full date string
    const date = new Date(dateString);
    return isNaN(date.getFullYear()) ? '' : date.getFullYear().toString();
  };

  const handleEnrichedJobHistorySelect = (index, source) => {
    setSelectedEnrichedJobHistory(prev => {
      const newSelection = { ...prev };
      if (newSelection[source].includes(index)) {
        newSelection[source] = newSelection[source].filter(i => i !== index);
      } else {
        newSelection[source] = [...newSelection[source], index];
      }

      setSelectedEnrichedJobHistorySource({
        current: newSelection.current.length > 0,
        pdl: newSelection.pdl.length > 0
      });

      return newSelection;
    });
  };
  const removeSelectedJobHistoryFromEnriched = source => {
    setEnrichedRecordForJobHistory(prev => {
      const updatedJobs = prev.filter((job, index) => job.source !== source || !selectedEnrichedJobHistory[source].includes(index));
      const removedJobs = prev.filter((job, index) => selectedEnrichedJobHistory[source].includes(index) && job.source === source);
      setAddedJobHistory(prev => {
        const updated = { ...prev };
        removedJobs.forEach(job => {
          const key = `${job.company_name}-${job.title}-${job.start_year}`;
          delete updated[key];
        });
        return updated;
      });
      return updatedJobs;
    });
    setSelectedEnrichedJobHistory(prev => ({ ...prev, [source]: [] }));
    setSelectedEnrichedJobHistorySource(prev => ({ ...prev, [source]: false }));
  };

  // Functions for Phone Numbers
  const handleCurrentPhoneNumberSelect = index => {
    setSelectedCurrentPhoneNumbers(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handlePdlPhoneNumberSelect = index => {
    setSelectedPdlPhoneNumbers(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleEnrichedPhoneNumberSelect = (index, source) => {
    setSelectedEnrichedPhoneNumbers(prev => {
      const newSelection = { ...prev };
      if (newSelection[source].includes(index)) {
        newSelection[source] = newSelection[source].filter(i => i !== index);
      } else {
        newSelection[source] = [...newSelection[source], index];
      }

      setSelectedEnrichedPhoneNumberSource({
        current: newSelection.current.length > 0,
        pdl: newSelection.pdl.length > 0
      });

      return newSelection;
    });
  };

  const addSelectedPhoneNumbersToEnrich = source => {
    const selectedNumbers = source === 'current' ? selectedCurrentPhoneNumbers : selectedPdlPhoneNumbers;
    const numberList = source === 'current' ? currentPhoneNumbers : pdlPhoneNumbers;

    const newNumbers = selectedNumbers.map(index => ({ number: numberList[index], source }));
    setEnrichedPhoneNumbers(prev => {
      const updatedNumbers = [...prev];
      newNumbers.forEach(numberObj => {
        if (!updatedNumbers.some(existingNumber => existingNumber.number === numberObj.number)) {
          updatedNumbers.push(numberObj);
          setAddedPhoneNumbers(prev => ({ ...prev, [numberObj.number]: source }));
        }
      });
      return updatedNumbers;
    });

    if (source === 'current') {
      setSelectedCurrentPhoneNumbers([]);
    } else {
      setSelectedPdlPhoneNumbers([]);
    }
  };
  // Update the removeSelectedPhoneNumbersFromEnriched function
  const removeSelectedPhoneNumbersFromEnriched = source => {
    setEnrichedPhoneNumbers(prev => {
      const updatedNumbers = prev.filter((number, index) => number.source !== source || !selectedEnrichedPhoneNumbers[source].includes(index));
      const removedNumbers = prev.filter((number, index) => selectedEnrichedPhoneNumbers[source].includes(index) && number.source === source);
      setAddedPhoneNumbers(prev => {
        const updated = { ...prev };
        removedNumbers.forEach(numberObj => {
          delete updated[numberObj.number];
        });
        return updated;
      });
      return updatedNumbers;
    });
    setSelectedEnrichedPhoneNumbers(prev => ({ ...prev, [source]: [] }));
    setSelectedEnrichedPhoneNumberSource(prev => ({ ...prev, [source]: false }));
  };
  // Functions for Email
  const handleCurrentEmailSelect = index => {
    setSelectedCurrentEmail(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handlePdlEmailSelect = index => {
    setSelectedPdlEmail(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleEnrichedEmailSelect = (index, source) => {
    setSelectedEnrichedEmail(prev => {
      const newSelection = { ...prev };
      if (newSelection[source].includes(index)) {
        newSelection[source] = newSelection[source].filter(i => i !== index);
      } else {
        newSelection[source] = [...newSelection[source], index];
      }

      setSelectedEnrichedEmailSource({
        current: newSelection.current.length > 0,
        pdl: newSelection.pdl.length > 0
      });

      return newSelection;
    });
  };
  // const handlePrimaryEmailChange = index => {
  //   setPrimaryEmail(index);
  // };
  const handlePrimaryEmailChange = index => {
    setPrimaryEmail(index);
    setEnrichedEmail(prevEmails =>
      prevEmails.map((email, idx) => ({
        ...email,
        is_primary: idx === index
      }))
    );
  };
  const addSelectedEmailToEnrich = source => {
    const selectedEmails = source === 'current' ? selectedCurrentEmail : selectedPdlEmail;
    const emailList = source === 'current' ? currentEmail : pdlEmail;

    const newEmails = selectedEmails.map(index => ({ ...emailList[index], source }));
    setEnrichedEmail(prev => {
      const updatedEmails = [...prev];
      newEmails.forEach(emailObj => {
        if (!updatedEmails.some(existingEmail => existingEmail.address === emailObj.address)) {
          updatedEmails.push(emailObj);
          setAddedEmails(prev => ({ ...prev, [emailObj.address]: source }));
        }
      });
      // Set the first email as primary if no primary email is set
      if (primaryEmail === null && updatedEmails.length > 0) {
        setPrimaryEmail(0);
      }
      return updatedEmails;
    });

    if (source === 'current') {
      setSelectedCurrentEmail([]);
    } else {
      setSelectedPdlEmail([]);
    }
  };

  const removeSelectedEmailFromEnriched = source => {
    setEnrichedEmail(prev => {
      const updatedEmails = prev.filter((email, index) => email.source !== source || !selectedEnrichedEmail[source].includes(index));
      const removedEmails = prev.filter((email, index) => selectedEnrichedEmail[source].includes(index) && email.source === source);
      setAddedEmails(prev => {
        const updated = { ...prev };
        removedEmails.forEach(emailObj => {
          delete updated[emailObj.address];
        });
        return updated;
      });
      return updatedEmails;
    });
    setSelectedEnrichedEmail(prev => ({ ...prev, [source]: [] }));
    setSelectedEnrichedEmailSource(prev => ({ ...prev, [source]: false }));
  };
  // Functions for Education
  const handleCurrentEducationSelect = index => {
    setSelectedCurrentEducation(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handlePdlEducationSelect = index => {
    setSelectedPdlEducation(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  const handleEnrichedEducationSelect = (index, source) => {
    setSelectedEnrichedEducation(prev => {
      const newSelection = { ...prev };
      if (newSelection[source].includes(index)) {
        newSelection[source] = newSelection[source].filter(i => i !== index);
      } else {
        newSelection[source] = [...newSelection[source], index];
      }

      setSelectedEnrichedEducationSource({
        current: newSelection.current.length > 0,
        pdl: newSelection.pdl.length > 0
      });

      return newSelection;
    });
  };

  const addSelectedEducationToEnrich = source => {
    const selectedEducations = source === 'current' ? selectedCurrentEducation : selectedPdlEducation;
    const educationList = source === 'current' ? currentEducation : pdlEducation;

    const newEducations = selectedEducations.map(index => ({ ...educationList[index], source }));
    setEnrichedEducation(prev => {
      const updatedEducations = [...prev];
      newEducations.forEach(educationObj => {
        const key = `${educationObj.school_name}-${educationObj.degree_name}-${educationObj.major}-${educationObj.degree_year}`;
        if (
          !updatedEducations.some(
            existingEducation =>
              existingEducation.school_name === educationObj.school_name &&
              existingEducation.degree_name === educationObj.degree_name &&
              existingEducation.major === educationObj.major &&
              existingEducation.degree_year === educationObj.degree_year
          )
        ) {
          updatedEducations.push(educationObj);
          setAddedEducation(prev => ({ ...prev, [key]: source }));
        }
      });
      return updatedEducations;
    });

    if (source === 'current') {
      setSelectedCurrentEducation([]);
    } else {
      setSelectedPdlEducation([]);
    }
  };

  // Update this function
  const removeSelectedEducationFromEnriched = source => {
    setEnrichedEducation(prev => {
      const updatedEducations = prev.filter((education, index) => education.source !== source || !selectedEnrichedEducation[source].includes(index));
      const removedEducations = prev.filter((education, index) => selectedEnrichedEducation[source].includes(index) && education.source === source);
      setAddedEducation(prev => {
        const updated = { ...prev };
        removedEducations.forEach(eduObj => {
          const key = `${eduObj.school_name}-${eduObj.degree_name}-${eduObj.major}-${eduObj.degree_year}`;
          delete updated[key];
        });
        return updated;
      });
      return updatedEducations;
    });
    setSelectedEnrichedEducation(prev => ({ ...prev, [source]: [] }));
    setSelectedEnrichedEducationSource(prev => ({ ...prev, [source]: false }));
  };
  const resetAllStates = () => {
    setCurrentRecordForJobTitle({ jobTitle: '' });
    setEnrichedRecordsForJobTitle('');
    setPdlRecordForJobTitle({ jobTitle: '' });
    setAddedFromJotTitle('current');

    setCurrentRecordForIndustry({ industry: '' });
    setEnrichedRecordsForIndustry([]);
    setPdlRecordForIndustry({ industry: '' });
    setAddedFromIndustry({});

    setCurrentRecordForFunctions({ jobFunctions: '' });
    setEnrichedRecordsForFunctions([]);
    setPdlRecordForFunctions({ jobFunctions: '' });
    setAddedFromFunctions({});

    setCurrentRecordForCountry({ country: '' });
    setEnrichedRecordsForCountry('');
    setPdlRecordForCountry({ country: '' });
    setAddedFromCountry('current');

    setCurrentRecordForLinkedIn({ linkedInUrl: '' });
    setEnrichedRecordsForLinkedIn('');
    setPdlRecordForLinkedIn({ linkedInUrl: '' });
    setAddedFromLinkedIn('current');

    setCurrentRecordForJobHistory([]);
    setEnrichedRecordForJobHistory([]);
    setSelectedEnrichedJobHistory({
      current: [],
      pdl: []
    });
    setPdlRecordForJobHistory([]);
    setSelectedCurrentJobHistory([]);
    setSelectedPdlJobHistory([]);
    setAddedJobHistory({});

    setCurrentPhoneNumbers([]);
    setEnrichedPhoneNumbers([]);
    setPdlPhoneNumbers([]);
    setSelectedCurrentPhoneNumbers([]);
    setSelectedPdlPhoneNumbers([]);
    setSelectedEnrichedPhoneNumbers({
      current: [],
      pdl: []
    });
    setAddedPhoneNumbers({});

    setCurrentEmail([]);
    setEnrichedEmail([]);
    setPdlEmail([]);
    setSelectedCurrentEmail([]);
    setSelectedPdlEmail([]);
    // setSelectedEnrichedEmail([]);
    setSelectedEnrichedEmail({
      current: [],
      pdl: []
    });
    setAddedEmails({});

    setCurrentEducation([]);
    setEnrichedEducation([]);
    setPdlEducation([]);
    setSelectedCurrentEducation([]);
    setSelectedPdlEducation([]);
    // setSelectedEnrichedEducation([]);
    setSelectedEnrichedEducation({
      current: [],
      pdl: []
    });
    setGeneralDetails({
      first_name: '',
      last_name: '',
      current_company: ''
    });
    setGeneralPdlDetails({
      first_name: '',
      last_name: '',
      current_company: '',
      linkedin_username: '',
      pdl_id: ''
    });
  };
  // Api Integration
  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response = null;
      if (data.id && data.linkedin_url) {
        let pdlRefreshPayload = [];
        pdlRefreshPayload.push({
          id: data.id,
          linkedin_url: data.linkedin_url
        });
        await pdlDataRefreshApi(pdlRefreshPayload);
        response = await getPdlAndContactDataApi(data.id);
        setPdlResponse(response);
        if ((response && response.data && response.data.dbContact) || response.data.pdlContact) {
          const { dbContact, pdlContact } = response.data;
          if (dbContact.current_job_title) {
            setCurrentRecordForJobTitle({
              jobTitle: dbContact.current_job_title
            });
          } else {
            setCurrentRecordForJobTitle({
              jobTitle: ''
            });
          }
          if (pdlContact.current_job_title) {
            setPdlRecordForJobTitle({
              jobTitle: pdlContact.current_job_title
            });
          } else {
            setPdlRecordForJobTitle({
              jobTitle: ''
            });
          }
          if (dbContact.contact_industries && dbContact.contact_industries.length > 0) {
            const industriesName = dbContact.contact_industries.map(item => item.industries.name);
            setCurrentRecordForIndustry({
              industry: industriesName.join(', ')
            });
          }
          // Set PDL industry
          if (pdlContact.company_industries && pdlContact.company_industries.length > 0) {
            setPdlRecordForIndustry({
              industry: pdlContact.company_industries.join(', ')
            });
          }
          // LinkedN uRL
          setCurrentRecordForLinkedIn({
            linkedInUrl: dbContact.linkedin_url
          });
          setPdlRecordForLinkedIn({
            linkedInUrl: pdlContact.linkedin_url
          });
          setGeneralDetails({
            first_name: dbContact.first_name,
            last_name: dbContact.last_name,
            current_company: dbContact.contact_company != null ? dbContact.contact_company.name : ''
          });
          setGeneralPdlDetails({
            first_name: pdlContact.first_name,
            last_name: pdlContact.last_name,
            current_company: pdlContact.contact_company.name,
            linkedin_username: pdlContact.linkedin_username,
            pdl_id: pdlContact.pdl_id
          });
          if (dbContact.contact_work_experience && dbContact.contact_work_experience.length > 0 && dbContact.contact_work_experience[0].country) {
            setCurrentRecordForCountry({
              country: dbContact.contact_work_experience[0].country.name
            });
          }
          if (pdlContact.contact_company) {
            setPdlRecordForCountry({
              country: pdlContact.location_country
            });
          } else {
            setPdlRecordForCountry({
              country: ''
            });
          }
          if (dbContact.contact_job_functions && dbContact.contact_job_functions.length > 0) {
            const jobFunctions = dbContact.contact_job_functions.map(item => item.job_functions.name);
            setCurrentRecordForFunctions({
              jobFunctions: jobFunctions.join(', ')
            });
          } else {
            setCurrentRecordForFunctions({
              jobFunctions: ''
            });
          }
          if (pdlContact.contact_job_functions && pdlContact.contact_job_functions.length > 0) {
            setPdlRecordForFunctions({
              jobFunctions: pdlContact.contact_job_functions.join(', ')
            });
          } else {
            setPdlRecordForFunctions({
              jobFunctions: ''
            });
          }
          if (pdlContact.pdl_emails && pdlContact.pdl_emails.length > 0) {
            setPdlEmail(pdlContact.pdl_emails);
          } else {
            setPdlEmail([]);
          }
          if (dbContact.contact_emails && dbContact.contact_emails.length > 0) {
            setCurrentEmail(
              dbContact.contact_emails.map(email => ({
                address: email.email,
                type: email.email_type,
                is_primary: email.is_primary
              }))
            );
          } else {
            setCurrentEmail([]);
          }
          if (dbContact.contact_phones && dbContact.contact_phones.length > 0) {
            setCurrentPhoneNumbers(dbContact.contact_phones.map(phone => phone.phone_number));
            const primaryPhone = dbContact.contact_phones.find(phone => phone.is_primary);
            if (primaryPhone) {
              setIsPrimaryPhoneNumber(dbContact.contact_phones.indexOf(primaryPhone));
            } else {
              setIsPrimaryPhoneNumber(null);
            }
          } else {
            setCurrentPhoneNumbers([]);
            setIsPrimaryPhoneNumber(null);
          }
          if (pdlContact.contact_phones && pdlContact.contact_phones.length > 0) {
            setPdlPhoneNumbers(pdlContact.contact_phones);
          } else {
            setPdlPhoneNumbers([]);
          }
          if (dbContact.contact_work_experience && dbContact.contact_work_experience.length > 0) {
            const mappedJobHistory = dbContact.contact_work_experience.map(job => {
              const mappedJob = {
                company_name: job.company?.name || job.name || '',
                title: job.title,
                start_year: job.start_date ? new Date(job.start_date).getFullYear() : '',
                end_year: job.end_date ? new Date(job.end_date).getFullYear() : '',
                size: job?.company?.size || '',
                location: job?.company?.location?.name || '',
                website: job?.company?.website || '',
                linkedin_url: job?.company?.linkedin_url || ''
              };
              return mappedJob;
            });

            setCurrentRecordForJobHistory(mappedJobHistory);
          } else {
            setCurrentRecordForJobHistory([]);
          }

          if (pdlContact.contact_work_experience && pdlContact.contact_work_experience.length > 0) {
            setPdlRecordForJobHistory(
              pdlContact.contact_work_experience.map(job => ({
                company_name: job.company?.name || '',
                title: job.title,
                start_year: job.start_year ? new Date(job.start_year).getFullYear() : '',
                end_year: job.end_year ? new Date(job.end_year).getFullYear() : '',
                size: job?.company?.size,
                location: job?.company?.location?.name,
                website: job?.company?.website,
                linkedin_url: job?.company?.linkedin_url
              }))
            );
          } else {
            setPdlRecordForJobHistory([]);
          }
          if (dbContact.contact_education_details && dbContact.contact_education_details.length > 0) {
            setCurrentEducation(
              dbContact.contact_education_details.map(edu => ({
                school_name: edu.school_name,
                degree_name: edu.degree_name || '',
                major: edu.major || '',
                degree_year: edu.end_date ? new Date(edu.end_date).getFullYear().toString() : ''
              }))
            );
          } else {
            setCurrentEducation([]);
          }

          if (pdlContact.contact_education_details && pdlContact.contact_education_details.length > 0) {
            setPdlEducation(
              pdlContact.contact_education_details.map(edu => ({
                school_name: edu.school_name,
                degree_name: edu.degrees.length > 0 ? edu.degrees[0] : '',
                major: edu.majors.length > 0 ? edu.majors[0] : '',
                degree_year: edu.degree_end_date ? new Date(edu.degree_end_date).getFullYear().toString() : ''
              }))
            );
          } else {
            setPdlEducation([]);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Error loading contact data', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      if (!data.linkedin_url || data.linkedin_url.trim() === '') {
        setShowErrorDialog(true);
      }
    }
  }, [open, data.linkedin_url, pdlResponse]);

  const saveEnrichData = async () => {
    setIsSaving(true);
    const payload = {
      data: {
        id: data.id,
        current_job_title: enrichedRecordsForJobTitle,
        industries: enrichedRecordsForIndustry,
        job_functions: enrichedRecordsForFunctions,
        country: [enrichedRecordsForCountry],
        linkedin_url: enrichedRecordsForLinkedIn,
        job_history: enrichedRecordForJobHistory.map(job => ({
          company: {
            name: job.company_name
          },
          title: {
            name: job.title
          },
          is_primary: false,
          start_year: job.start_year ? job.start_year.toString() : null,
          end_year: job.end_year ? job.end_year.toString() : null,
          website: job.website,
          size: job.size,
          linkedin_url: job.linkedin_url
        })),
        first_name: generalDetails.first_name,
        last_name: generalDetails.last_name,
        current_company_id: data.current_company_id,
        current_company: generalDetails.current_company,
        // pdl_phone_numbers: enrichedPhoneNumbers.map(phone => phone.number),
        pdl_phone_numbers: enrichedPhoneNumbers
          .sort((a, b) => {
            if (a.number === enrichedPhoneNumbers[primaryPhoneNumber]?.number) return -1;
            if (b.number === enrichedPhoneNumbers[primaryPhoneNumber]?.number) return 1;
            return 0;
          })
          .map(phone => phone.number),

        pdl_emails: enrichedEmail.map(email => ({
          address: email.address,
          type: email.type ? email.type.toLowerCase() : ''
        })),
        education_details: enrichedEducation.map(edu => ({
          school_name: edu.school_name,
          degrees: [edu.degree_name],
          majors: [edu.major],
          degree_start_date: null,
          degree_end_date: edu.degree_year
        })),
        pdl_id: generalPdlDetails.pdl_id,
        pdl_facebook_id: null,
        pdl_facebook_username: null,
        linkedin_username: generalPdlDetails.linkedin_username
      }
    };
    try {
      const response = await pdlContactUpdateApi(payload);
      if (response.status === 200) {
        setTimeout(() => {
          setIsSaving(false);
          onClose();
          // resetAllStates();
        }, 1000);
        enqueueSnackbar('Enriched Successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Something Went Wrong', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error);
      setIsSaving(false);
    }
  };
  const handleClose = () => {
    resetAllStates();
    onClose();
  };

  const capitalizeFirstLetter = string => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };
  useEffect(() => {
    if (open && (!data.linkedin_url || data.linkedin_url.trim() === '')) {
      setShowErrorDialog(true);
    }
  }, [open, data.linkedin_url]);

  const handleErrorDialogClose = () => {
    setShowErrorDialog(false);
    onClose();
  };
  useEffect(() => {
    if (open) {
      resetAllStates();
      fetchData();
    }
  }, [open]);
  return (
    <>
      <Dialog open={showErrorDialog} onClose={handleErrorDialogClose}>
        <Box sx={{ p: 3 }}>
          <Typography variant='h6' gutterBottom>
            LinkedIn URL Missing
          </Typography>
          <Typography variant='body1' paragraph>
            Please input the LinkedIn URL first to enrich the data.
          </Typography>
          <Button variant='contained' onClick={handleErrorDialogClose}>
            Close
          </Button>
        </Box>
      </Dialog>
      {!showErrorDialog && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={'95vw'}
          PaperProps={{
            sx: {
              height: '90vh',
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                backgroundColor: '#f5f5f5'
              },
              '&::-webkit-scrollbar': {
                // width: '6px',
                // backgroundColor: '#f5f5f5'
                display: 'none'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#1d034b'
              }
            }
          }}
        >
          <DialogHeader onClose={onClose} />
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(90vh - 64px)',
                flexDirection: 'column',
                gap: 2
              }}
            >
              <CircularProgress size={40} />
              <Typography variant='body1' color='textSecondary'>
                Loading contact information...
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid container alignItems='center' p={2} spacing={1} sx={{ marginTop: '3px' }}>
                <Grid item xs={3}>
                  <Box sx={{ width: '70%', marginLeft: '8%' }}>
                    {/* {renderContentOrSkeleton( */}
                    <CustomInputField
                      style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                      value={`${capitalizeFirstLetter(generalDetails.first_name)} ${capitalizeFirstLetter(generalDetails.last_name)}`}
                    />
                    {/* )} */}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {data.pdlStatus?.updatedBy?.last_name ? (
                    <Typography
                      variant='inherit'
                      sx={{
                        backgroundColor: 'var(--palette-primary-opacity-20)',
                        padding: '8px 16px',
                        borderRadius: '20px',
                        textAlign: 'center',
                        display: 'inline-block',
                        margin: '0 auto',
                        width: '53%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      fontSize={{
                        // xs: '14px',
                        // lg: '16px'
                        xs: 'clamp(10px, 2.5vw, 14px)',
                        lg: 'clamp(12px, 2.5vw, 16px)'
                      }}
                    >
                      {`Enriched on ${new Date(data.updated_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s/g, '  ')} by `}
                      <strong>{`${data.pdlStatus.updatedBy.first_name} ${data.pdlStatus.updatedBy.last_name}`}</strong>
                    </Typography>
                  ) : (
                    <Typography
                      variant='inherit'
                      sx={{
                        // backgroundColor: '#253B55',
                        backgroundColor: 'rgba(37, 59, 85, 0.2)',
                        color: 'primary.text',
                        padding: '8px 16px',
                        borderRadius: '20px',
                        textAlign: 'center',
                        display: 'inline-block',
                        margin: '0 auto',
                        width: '53%'
                      }}
                      fontSize={{
                        xs: '14px',
                        lg: '16px'
                      }}
                    >
                      Not enriched
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }}>
                  <Button
                    variant='contained'
                    bgcolor={'secondary.main'}
                    sx={{
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      width: '150px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      backgroundColor: 'secondary.main'
                    }}
                    fontSize={{
                      xs: '14px',
                      lg: '16px'
                    }}
                    onClick={saveEnrichData}
                  >
                    {isSaving ? <CircularProgress size={20} color='inherit' /> : t('actions.save')}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                className='enrich-contact-container'
                container
                sx={{
                  color: 'white',
                  padding: '20px',
                  paddingLeft: '14px',
                  paddingRight: '0',
                  width: '104%',
                  margin: 0,
                  boxSizing: 'border-box'
                }}
              >
                <Box className='container-fluid' style={{ height: '40px', marginTop: '-20px', width: '104%' }}>
                  <Box
                    className='row text-center'
                    sx={{
                      marginBottom: '10px',
                      backgroundColor: 'var(--palette-primary-opacity-50)',
                      padding: '10px 0',
                      borderRadius: '4px',
                      width: '104%'
                    }}
                  >
                    <Box className='col first-col' style={{ marginRight: '0px', marginLeft: '-25px' }}>
                      <strong>CURRENT RECORDS</strong>
                    </Box>
                    <Box className='col middle-col' style={{ marginRight: '0px' }}>
                      <strong>ENRICHED RECORD</strong>
                    </Box>
                    <Box className='col last-col' style={{ marginRight: '0px' }}>
                      <strong>ENRICHMENT SOURCE</strong>
                    </Box>
                  </Box>
                </Box>
                {/*Current Job Title */}
                <Box className='container-fluid'>
                  <Box className='row'>
                    {/* Current Record */}
                    <Box className='col first-col'>
                      <Box className='contact_card'>
                        <Box className='content_box'>
                          <p style={{ textDecoration: addedFromJobTitle !== 'pdl' ? 'line-through' : 'none' }}>
                            {currentRecordForJobTitle.jobTitle && currentRecordForJobTitle.jobTitle.charAt(0).toUpperCase() + currentRecordForJobTitle.jobTitle.slice(1)}
                          </p>
                        </Box>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addJobTitleToEnrich(currentRecordForJobTitle.jobTitle, 'current')}
                            onRemove={() => removeJobTitleFromEnrichd(currentRecordForJobTitle.jobTitle)}
                            disableRemove={addedFromJobTitle === 'pdl'}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {/* Enriched Record */}
                    <Box className='col middle-col'>
                      <Box className='enriched_contact_card'>
                        <Box className='enriched_content_box'>
                          <p className='title_enriched_card' style={{ color: 'primary.main' }}>
                            Current Job Title
                          </p>
                          <p>{enrichedRecordsForJobTitle && enrichedRecordsForJobTitle.charAt(0).toUpperCase() + enrichedRecordsForJobTitle.slice(1)}</p>
                        </Box>
                      </Box>
                    </Box>
                    {/* PDL */}
                    <Box className='col last-col'>
                      <Box className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addJobTitleToEnrich(pdlRecordForJobTitle.jobTitle, 'pdl')}
                            onRemove={() => removeJobTitleFromEnrichd(pdlRecordForJobTitle.jobTitle)}
                            disableRemove={addedFromJobTitle === 'current'}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          <p style={{ textDecoration: addedFromJobTitle !== 'current' ? 'line-through' : 'none' }}>
                            {pdlRecordForJobTitle.jobTitle && pdlRecordForJobTitle.jobTitle.charAt(0).toUpperCase() + pdlRecordForJobTitle.jobTitle.slice(1)}
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* Industry*/}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p style={{ textDecoration: enrichedRecordsForIndustry.includes(currentRecordForIndustry.industry) ? 'line-through' : 'none' }}>
                            {currentRecordForIndustry.industry
                              .split(', ')
                              .map(industry => industry.charAt(0).toUpperCase() + industry.slice(1))
                              .join(', ')}
                          </p>
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addIndustryToEnrich(currentRecordForIndustry.industry, 'current')}
                            onRemove={() => removeIndustryFromEnriched(currentRecordForIndustry.industry)}
                            disableAdd={enrichedRecordsForIndustry.includes(currentRecordForIndustry.industry)}
                            disableRemove={!enrichedRecordsForIndustry.includes(currentRecordForIndustry.industry)}
                          />
                        </Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Industry</p>
                          <p>{enrichedRecordsForIndustry.map(industry => industry.charAt(0).toUpperCase() + industry.slice(1)).join(', ')}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <div className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addIndustryToEnrich(pdlRecordForIndustry.industry, 'pdl')}
                            onRemove={() => removeIndustryFromEnriched(pdlRecordForIndustry.industry)}
                            disableAdd={enrichedRecordsForIndustry.includes(pdlRecordForIndustry.industry)}
                            disableRemove={!enrichedRecordsForIndustry.includes(pdlRecordForIndustry.industry)}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          <p style={{ textDecoration: enrichedRecordsForIndustry.includes(pdlRecordForIndustry.industry) ? 'line-through' : 'none' }}>
                            {pdlRecordForIndustry.industry && pdlRecordForIndustry.industry.charAt(0).toUpperCase() + pdlRecordForIndustry.industry.slice(1)}
                          </p>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* Job Function*/}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p style={{ textDecoration: enrichedRecordsForFunctions.includes(currentRecordForFunctions.jobFunctions) ? 'line-through' : 'none' }}>
                            {currentRecordForFunctions.jobFunctions
                              ? currentRecordForFunctions.jobFunctions
                                  .split(', ')
                                  .map(func => func.charAt(0).toUpperCase() + func.slice(1))
                                  .join(', ')
                              : ''}
                          </p>
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addFunctionToEnrich(currentRecordForFunctions.jobFunctions, 'current')}
                            onRemove={() => removeFunctionFromEnriched(currentRecordForFunctions.jobFunctions)}
                            disableAdd={enrichedRecordsForFunctions.includes(currentRecordForFunctions.jobFunctions)}
                            disableRemove={!enrichedRecordsForFunctions.includes(currentRecordForFunctions.jobFunctions)}
                          />
                        </Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Job Functions</p>
                          <p>{enrichedRecordsForFunctions.map(func => func.charAt(0).toUpperCase() + func.slice(1)).join(', ')}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <div className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addFunctionToEnrich(pdlRecordForFunctions.jobFunctions, 'pdl')}
                            onRemove={() => removeFunctionFromEnriched(pdlRecordForFunctions.jobFunctions)}
                            disableAdd={enrichedRecordsForFunctions.includes(pdlRecordForFunctions.jobFunctions)}
                            disableRemove={!enrichedRecordsForFunctions.includes(pdlRecordForFunctions.jobFunctions)}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          <p style={{ textDecoration: enrichedRecordsForFunctions.includes(pdlRecordForFunctions.jobFunctions) ? 'line-through' : 'none' }}>
                            {pdlRecordForFunctions.jobFunctions && pdlRecordForFunctions.jobFunctions.charAt(0).toUpperCase() + pdlRecordForFunctions.jobFunctions.slice(1)}
                          </p>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
                {/*Country */}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p style={{ textDecoration: addedFromCountry !== 'pdl' ? 'line-through' : 'none' }}>
                            {currentRecordForCountry.country ? currentRecordForCountry.country.charAt(0).toUpperCase() + currentRecordForCountry.country.slice(1) : ''}
                          </p>
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addCountryToEnrich(currentRecordForCountry.country, 'current')}
                            onRemove={() => removeCountryFromEnrichd(currentRecordForCountry.country)}
                            disableRemove={addedFromCountry === 'pdl'}
                          />
                        </Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Country</p>
                          <p>{enrichedRecordsForCountry ? enrichedRecordsForCountry.charAt(0).toUpperCase() + enrichedRecordsForCountry.slice(1) : ''}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <div className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addCountryToEnrich(pdlRecordForCountry.country, 'pdl')}
                            onRemove={() => removeCountryFromEnrichd(pdlRecordForCountry.country)}
                            disableRemove={addedFromCountry === 'current'}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          <p style={{ textDecoration: addedFromCountry !== 'current' ? 'line-through' : 'none' }}>
                            {pdlRecordForCountry.country && pdlRecordForCountry.country.charAt(0).toUpperCase() + pdlRecordForCountry.country.slice(1)}
                          </p>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
                {/*Phone Number */}
                <Box className='container-fluid'>
                  <div className='row'>
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          {currentPhoneNumbers.map((number, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedCurrentPhoneNumbers.includes(index)}
                                onChange={() => handleCurrentPhoneNumberSelect(index)}
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', background: '#8795a2', borderRadius: '8px' }}>
                                <Typography variant='body2' style={{ textDecoration: addedPhoneNumbers[number] === 'current' ? 'line-through' : 'none', color: 'black' }}>
                                  <Typography component='span' className='phone-label' sx={{ color: 'secondary.main' }}>
                                    Number:
                                  </Typography>{' '}
                                  <Typography component='span' className='phone-label' sx={{ color: '#353535' }}>
                                    {number}
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedPhoneNumbersToEnrich('current')}
                            disableAdd={selectedCurrentPhoneNumbers.length === 0}
                            onRemove={() => removeSelectedPhoneNumbersFromEnriched('current')}
                            disableRemove={!selectedEnrichedPhoneNumberSource.current}
                          />
                        </Box>
                      </div>
                    </div>
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Phone Number</p>
                          {enrichedPhoneNumbers.map((numberObj, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedEnrichedPhoneNumbers[numberObj.source].includes(index)}
                                onChange={() => handleEnrichedPhoneNumberSelect(index, numberObj.source)}
                                defaultChecked
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', borderRadius: '8px', background: '#e3eef6' }}>
                                <Typography variant='body2'>
                                  <Typography component='span' className='phone-label' sx={{ color: 'secondary.main' }}>
                                    Number:
                                  </Typography>{' '}
                                  <Typography className='phone-label' component='span' sx={{ color: '#353535' }}>
                                    {numberObj.number}
                                  </Typography>
                                </Typography>
                                <Button
                                  variant={numberObj.isPrimary ? 'contained' : 'outlined'}
                                  color='primary'
                                  size='small'
                                  onClick={() => handlePrimaryPhoneChange(index)}
                                  sx={{
                                    background: numberObj.isPrimary ? '#00DFA6' : 'inherit',
                                    display: 'table',
                                    marginLeft: 'auto',
                                    padding: '0px 8px',
                                    fontSize: '12px',
                                    height: 'auto',
                                    width: 'auto',
                                    boxShadow: 'none',
                                    borderRadius: '4px',
                                    '&:hover': {
                                      border: numberObj.isPrimary ? 'none' : '1px solid #1976d2',
                                      background: numberObj.isPrimary ? '#00DFA6' : 'rgba(0, 0, 0, 0.04)'
                                    }
                                  }}
                                >
                                  {numberObj.isPrimary ? 'Primary' : 'Set as Primary'}
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='col last-col'>
                      <div className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedPhoneNumbersToEnrich('pdl')}
                            disableAdd={selectedPdlPhoneNumbers.length === 0}
                            onRemove={() => removeSelectedPhoneNumbersFromEnriched('pdl')}
                            disableRemove={!selectedEnrichedPhoneNumberSource.pdl}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          {pdlPhoneNumbers.map((number, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedPdlPhoneNumbers.includes(index)}
                                onChange={() => handlePdlPhoneNumberSelect(index)}
                                defaultChecked
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', borderRadius: '8px', borderColor: 'gray' }}>
                                <Typography variant='body2' style={{ textDecoration: addedPhoneNumbers[number] === 'pdl' ? 'line-through' : 'none' }}>
                                  <Typography component='span' className='phone-label' sx={{ color: 'secondary.main' }}>
                                    Number:
                                  </Typography>{' '}
                                  <Typography component='span' className='phone-label' sx={{ color: '#353535' }}>
                                    {number}
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* Email */}
                <Box className='container-fluid'>
                  <div className='row'>
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          {currentEmail.map((email, index) => (
                            <React.Fragment key={index}>
                              <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <Checkbox
                                  checked={selectedCurrentEmail.includes(index)}
                                  onChange={() => handleCurrentEmailSelect(index)}
                                  color='success'
                                  sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                                />
                                <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', background: '#8795a2', borderRadius: '8px' }}>
                                  <Typography variant='body2' style={{ textDecoration: addedEmails[email.address] === 'current' ? 'line-through' : 'none', color: 'black' }}>
                                    <span className='email-label' style={{ color: '#3855b4' }}>
                                      Address:
                                    </span>{' '}
                                    <span style={{ color: '#353535' }}>{email.address}</span>
                                  </Typography>
                                  <Typography variant='body2' style={{ textDecoration: addedEmails[email.address] === 'current' ? 'line-through' : 'none', color: 'black' }}>
                                    <span className='email-label' style={{ color: '#3855b4' }}>
                                      Type:
                                    </span>{' '}
                                    <span style={{ color: '#353535' }}>{email.type}</span>
                                  </Typography>
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedEmailToEnrich('current')}
                            disableAdd={selectedCurrentEmail.length === 0}
                            onRemove={() => removeSelectedEmailFromEnriched('current')}
                            disableRemove={!selectedEnrichedEmailSource.current}
                          />
                        </Box>
                      </div>
                    </div>
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Email</p>
                          {enrichedEmail.map((emailObj, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedEnrichedEmail[emailObj.source].includes(index)}
                                onChange={() => handleEnrichedEmailSelect(index, emailObj.source)}
                                defaultChecked
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', borderRadius: '8px', background: '#e3eef6' }}>
                                <Typography variant='body2'>
                                  <span className='email-label'>Address:</span> {emailObj.address}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='email-label'>Type:</span> {emailObj.type || 'Others'}
                                </Typography>
                                <Button
                                  variant={emailObj.is_primary ? 'contained' : 'outlined'}
                                  color='primary'
                                  size='small'
                                  onClick={() => handlePrimaryEmailChange(index)}
                                  sx={{
                                    background: emailObj.is_primary ? '#00DFA6' : 'inherit',
                                    display: 'table',
                                    marginLeft: 'auto',
                                    padding: '0px 8px',
                                    fontSize: '12px',
                                    height: 'auto',
                                    width: 'auto',
                                    boxShadow: 'none',
                                    borderRadius: '4px',
                                    '&:hover': {
                                      border: emailObj.is_primary ? 'none' : '1px solid #1976d2',
                                      background: emailObj.is_primary ? '#00DFA6' : 'rgba(0, 0, 0, 0.04)'
                                    }
                                  }}
                                >
                                  {emailObj.is_primary ? 'Primary' : 'Set as Primary'}
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='col last-col'>
                      <div className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            background: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedEmailToEnrich('pdl')}
                            disableAdd={selectedPdlEmail.length === 0}
                            onRemove={() => removeSelectedEmailFromEnriched('pdl')}
                            disableRemove={!selectedEnrichedEmailSource.pdl}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          {pdlEmail.map((email, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedPdlEmail.includes(index)}
                                onChange={() => handlePdlEmailSelect(index)}
                                defaultChecked
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', borderRadius: '8px', borderColor: 'gray' }}>
                                <Typography variant='body2' style={{ textDecoration: addedEmails[email.address] === 'pdl' ? 'line-through' : 'none' }}>
                                  <span className='email-label'>Address:</span> {email.address}
                                </Typography>
                                <Typography variant='body2' style={{ textDecoration: addedEmails[email.address] === 'pdl' ? 'line-through' : 'none' }}>
                                  <span className='email-label'>Type:</span> {email.type ? email.type.charAt(0).toUpperCase() + email.type.slice(1) : 'N/A'}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* Job History */}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <Box
                      className='col first-col'
                      sx={{
                        backgroundColor: 'var(--palette-primary-opacity-30) !important'
                      }}
                    >
                      <div className='contact_card'>
                        <div className='content_box'>
                          {currentRecordForJobHistory.map((job, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedCurrentJobHistory.includes(index)}
                                onChange={() => handleCurrentJobHistorySelect(index)}
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', marginLeft: '10px', flex: 1, borderRadius: '8px', background: '#8795a2' }}>
                                <Typography
                                  variant='body2'
                                  style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'current' ? 'line-through' : 'none', color: 'black' }}
                                >
                                  <span className='job-history-label' style={{ color: '#3855b4' }}>
                                    Company Name:
                                  </span>
                                  <span style={{ color: '#353535' }}>{job.company_name ? job.company_name.charAt(0).toUpperCase() + job.company_name.slice(1) : ''}</span>
                                </Typography>
                                <Typography
                                  variant='body2'
                                  style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'current' ? 'line-through' : 'none', color: 'black' }}
                                >
                                  <span className='job-history-label' style={{ color: '#3855b4' }}>
                                    Title:
                                  </span>
                                  <span style={{ color: '#353535' }}>{job.title ? job.title.charAt(0).toUpperCase() + job.title.slice(1) : ''}</span>
                                </Typography>
                                <Typography
                                  variant='body2'
                                  style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'current' ? 'line-through' : 'none', color: 'black' }}
                                >
                                  <span className='job-history-label' style={{ color: '#3855b4' }}>
                                    Start Year:
                                  </span>
                                  <span style={{ color: '#353535' }}>{job.start_year}</span>
                                </Typography>
                                <Typography
                                  variant='body2'
                                  style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'current' ? 'line-through' : 'none', color: 'black' }}
                                >
                                  <span className='job-history-label' style={{ color: '#3855b4' }}>
                                    End Year:
                                  </span>
                                  <span style={{ color: '#353535' }}>{job.end_year}</span>
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            position: 'sticky',
                            bottom: 0,
                            padding: '10px 0',
                            zIndex: 1,
                            marginTop: 'auto',
                            pr: '0 !important',
                            pl: '0 !important',
                            width: '50px',
                            display: 'flex'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedJobHistoryToEnrich('current')}
                            disableAdd={selectedCurrentJobHistory.length === 0}
                            onRemove={() => removeSelectedJobHistoryFromEnriched('current')}
                            disableRemove={!selectedEnrichedJobHistorySource.current}
                          />
                        </Box>
                      </div>
                    </Box>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Job History</p>
                          {enrichedRecordForJobHistory.map((job, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedEnrichedJobHistory[job.source].includes(index)}
                                onChange={() => handleEnrichedJobHistorySelect(index, job.source)}
                                defaultChecked
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', marginLeft: '10px', flex: 1, borderRadius: '8px', background: '#e3eef6', borderColor: 'gray' }}>
                                <Typography variant='body2'>
                                  <span className='job-history-label'>Company Name:</span> {job.company_name ? job.company_name.charAt(0).toUpperCase() + job.company_name.slice(1) : ''}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='job-history-label'>Title:</span> {job.title ? job.title.charAt(0).toUpperCase() + job.title.slice(1) : ''}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='job-history-label'>Start Year:</span> {job.start_year}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='job-history-label'>End Year:</span> {job.end_year}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <Box className='col last-col'>
                      <Box
                        className='enriched_contact_card'
                        sx={{
                          display: 'flex',
                          height: '100%',
                          position: 'relative',
                          backgroundColor: 'var(--palette-primary-opacity-30) !important'
                        }}
                      >
                        <Box
                          className='action_btn'
                          sx={{
                            position: 'sticky',
                            bottom: 0,
                            padding: '10px 0',
                            zIndex: 1,
                            marginTop: 'auto',
                            pr: '0 !important',
                            pl: '0 !important',
                            width: '50px',
                            display: 'flex',
                            alignSelf: 'stretch',
                            top: 0,
                            alignItems: 'flex-start',
                            float: 'left'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedJobHistoryToEnrich('pdl')}
                            disableAdd={selectedPdlJobHistory.length === 0}
                            onRemove={() => removeSelectedJobHistoryFromEnriched('pdl')}
                            disableRemove={!selectedEnrichedJobHistorySource.pdl}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            flex: 1,
                            backgroundColor: 'transparent',
                            position: 'relative'
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'var(--palette-primary-opacity-10) !important',
                              zIndex: 0
                            }}
                          />
                          <Box
                            sx={{
                              position: 'relative',
                              zIndex: 1
                            }}
                          >
                            {pdlRecordForJobHistory.map((job, index) => (
                              <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <Checkbox
                                  checked={selectedPdlJobHistory.includes(index)}
                                  onChange={() => handlePdlJobHistorySelect(index)}
                                  defaultChecked
                                  color='success'
                                  sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                                />
                                <Box sx={{ border: '1px solid #ccc', padding: '10px', marginLeft: '10px', flex: 1, borderRadius: '8px', borderColor: 'gray' }}>
                                  <Typography variant='body2' style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'pdl' ? 'line-through' : 'none' }}>
                                    <span className='job-history-label'>Company Name:</span> {job.company_name ? job.company_name.charAt(0).toUpperCase() + job.company_name.slice(1) : 'N/A'}
                                  </Typography>
                                  <Typography variant='body2' style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'pdl' ? 'line-through' : 'none' }}>
                                    <span className='job-history-label'>Title:</span> {job.title ? job.title.charAt(0).toUpperCase() + job.title.slice(1) : 'N/A'}
                                  </Typography>
                                  <Typography variant='body2' style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'pdl' ? 'line-through' : 'none' }}>
                                    <span className='job-history-label'>Start Year:</span> {job.start_year || ''}
                                  </Typography>
                                  <Typography variant='body2' style={{ textDecoration: addedJobHistory[`${job.company_name}-${job.title}-${job.start_year}`] === 'pdl' ? 'line-through' : 'none' }}>
                                    <span className='job-history-label'>End Year:</span> {job.end_year || ''}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>

                {/* Educational Deatils */}
                <Box className='container-fluid'>
                  <div className='row'>
                    <Box
                      className='col first-col'
                      sx={{
                        backgroundColor: 'var(--palette-primary-opacity-30) !important'
                      }}
                    >
                      <div className='contact_card'>
                        <div className='content_box'>
                          {currentEducation.map((edu, index) => (
                            <React.Fragment key={index}>
                              <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <Checkbox
                                  checked={selectedCurrentEducation.includes(index)}
                                  onChange={() => handleCurrentEducationSelect(index)}
                                  color='success'
                                  sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                                />
                                <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', background: '#8795a2', borderRadius: '8px' }}>
                                  <Typography
                                    variant='body2'
                                    style={{
                                      textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'current' ? 'line-through' : 'none',
                                      color: 'black'
                                    }}
                                  >
                                    <span className='education-label' style={{ color: '#3855b4' }}>
                                      School Name:
                                    </span>
                                    <span style={{ color: '#353535' }}>{edu.school_name ? edu.school_name.charAt(0).toUpperCase() + edu.school_name.slice(1) : ''}</span>
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    style={{
                                      textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'current' ? 'line-through' : 'none',
                                      color: 'black'
                                    }}
                                  >
                                    <span className='education-label' style={{ color: '#3855b4' }}>
                                      Degree Name:
                                    </span>
                                    <span style={{ color: '#353535' }}>{edu.degree_name ? edu.degree_name.charAt(0).toUpperCase() + edu.degree_name.slice(1) : ''}</span>
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    style={{
                                      textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'current' ? 'line-through' : 'none',
                                      color: 'black'
                                    }}
                                  >
                                    <span className='education-label' style={{ color: '#3855b4' }}>
                                      Major:
                                    </span>
                                    <span style={{ color: '#353535' }}>{edu.major ? edu.major.charAt(0).toUpperCase() + edu.major.slice(1) : ''}</span>
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    style={{
                                      textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'current' ? 'line-through' : 'none',
                                      color: 'black'
                                    }}
                                  >
                                    <span className='education-label' style={{ color: '#3855b4' }}>
                                      Degree Year:
                                    </span>
                                    <span style={{ color: '#353535' }}>{edu.degree_year}</span>
                                  </Typography>
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            position: 'sticky',
                            bottom: 0,
                            padding: '10px 0',
                            zIndex: 1,
                            marginTop: 'auto',
                            pr: '0 !important',
                            pl: '0 !important',
                            width: '50px',
                            display: 'flex'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedEducationToEnrich('current')}
                            disableAdd={selectedCurrentEducation.length === 0}
                            onRemove={() => removeSelectedEducationFromEnriched('current')}
                            disableRemove={!selectedEnrichedEducationSource.current}
                          />
                        </Box>
                      </div>
                    </Box>
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Education Details</p>
                          {enrichedEducation.map((eduObj, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                              <Checkbox
                                checked={selectedEnrichedEducation[eduObj.source].includes(index)}
                                onChange={() => handleEnrichedEducationSelect(index, eduObj.source)}
                                defaultChecked
                                color='success'
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              />
                              <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', borderRadius: '8px', background: '#e3eef6' }}>
                                <Typography variant='body2'>
                                  <span className='education-label'>School Name:</span> {eduObj.school_name ? eduObj.school_name.charAt(0).toUpperCase() + eduObj.school_name.slice(1) : ''}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='education-label'>Degree Name:</span> {eduObj.degree_name ? eduObj.degree_name.charAt(0).toUpperCase() + eduObj.degree_name.slice(1) : ''}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='education-label'>Major:</span> {eduObj.major ? eduObj.major.charAt(0).toUpperCase() + eduObj.major.slice(1) : ''}
                                </Typography>
                                <Typography variant='body2'>
                                  <span className='education-label'>Degree Year:</span> {eduObj.degree_year}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <Box className='col last-col'>
                      <Box
                        className='enriched_contact_card'
                        sx={{
                          display: 'flex',
                          height: '100%',
                          position: 'relative',
                          backgroundColor: 'var(--palette-primary-opacity-30) !important'
                        }}
                      >
                        <Box
                          className='action_btn'
                          sx={{
                            position: 'sticky',
                            bottom: 0,
                            padding: '10px 0',
                            zIndex: 1,
                            marginTop: 'auto',
                            pr: '0 !important',
                            pl: '0 !important',
                            width: '50px',
                            display: 'flex',
                            top: 0,
                            alignItems: 'flex-start',
                            float: 'left'
                          }}
                        >
                          <ActionButtonGroup
                            onAdd={() => addSelectedEducationToEnrich('pdl')}
                            disableAdd={selectedPdlEducation.length === 0}
                            onRemove={() => removeSelectedEducationFromEnriched('pdl')}
                            disableRemove={!selectedEnrichedEducationSource.pdl}
                            frompdl={true}
                          />
                        </Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            flex: 1,
                            backgroundColor: 'transparent',
                            position: 'relative'
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'var(--palette-primary-opacity-10) !important',
                              zIndex: 0
                            }}
                          />
                          <Box
                            sx={{
                              position: 'relative',
                              zIndex: 1
                            }}
                          >
                            {pdlEducation.map((edu, index) => (
                              <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <Checkbox
                                  checked={selectedPdlEducation.includes(index)}
                                  onChange={() => handlePdlEducationSelect(index)}
                                  defaultChecked
                                  color='success'
                                  sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                                />
                                <Box sx={{ border: '1px solid #ccc', padding: '10px', flex: 1, marginLeft: '10px', borderRadius: '8px', borderColor: 'gray' }}>
                                  <Typography
                                    variant='body2'
                                    style={{ textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'pdl' ? 'line-through' : 'none' }}
                                  >
                                    <span className='education-label'>School Name:</span> {edu.school_name ? edu.school_name.charAt(0).toUpperCase() + edu.school_name.slice(1) : 'N/A'}
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    style={{ textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'pdl' ? 'line-through' : 'none' }}
                                  >
                                    <span className='education-label'>Degree Name:</span> {edu.degree_name ? edu.degree_name.charAt(0).toUpperCase() + edu.degree_name.slice(1) : 'N/A'}
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    style={{ textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'pdl' ? 'line-through' : 'none' }}
                                  >
                                    <span className='education-label'>Major:</span> {edu.major}
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    style={{ textDecoration: addedEducation[`${edu.school_name}-${edu.degree_name}-${edu.major}-${edu.degree_year}`] === 'pdl' ? 'line-through' : 'none' }}
                                  >
                                    <span className='education-label'>Degree Year:</span> {edu.degree_year}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Box>
                {/*First Name */}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p>{generalDetails.first_name ? generalDetails.first_name.charAt(0).toUpperCase() + generalDetails.first_name.slice(1) : ''}</p>
                        </div>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>First Name</p>
                          <p>{generalDetails.first_name ? generalDetails.first_name.charAt(0).toUpperCase() + generalDetails.first_name.slice(1) : ''}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <Box className='enriched_contact_card'>
                        {/* <div className='action_btn'></div> */}
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          {generalPdlDetails.first_name ? generalPdlDetails.first_name.charAt(0).toUpperCase() + generalPdlDetails.first_name.slice(1) : 'N/A'}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
                {/*Last Name */}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p>{generalDetails.last_name ? generalDetails.last_name.charAt(0).toUpperCase() + generalDetails.last_name.slice(1) : ''}</p>
                        </div>
                        {/* <div className='action_btn'></div> */}
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Last Name</p>
                          <p>{generalDetails.last_name ? generalDetails.last_name.charAt(0).toUpperCase() + generalDetails.last_name.slice(1) : ''}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <Box className='enriched_contact_card'>
                        {/* <div className='action_btn'></div> */}
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          {generalPdlDetails.last_name ? generalPdlDetails.last_name.charAt(0).toUpperCase() + generalPdlDetails.last_name.slice(1) : 'N/A'}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
                {/*Current company */}
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p>{generalDetails.current_company ? generalDetails.current_company.charAt(0).toUpperCase() + generalDetails.current_company.slice(1) : ''}</p>
                        </div>
                        {/* <div className='action_btn'></div> */}
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>Current Company</p>
                          <p>{generalDetails.last_name ? generalDetails.current_company.charAt(0).toUpperCase() + generalDetails.current_company.slice(1) : ''}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <Box className='enriched_contact_card'>
                        {/* <div className='action_btn'></div> */}
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          {generalPdlDetails.current_company ? generalPdlDetails.current_company.charAt(0).toUpperCase() + generalPdlDetails.current_company.slice(1) : 'N/A'}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
                <Box className='container-fluid'>
                  <div className='row'>
                    {/* Current Record */}
                    <div className='col first-col'>
                      <div className='contact_card'>
                        <div className='content_box'>
                          <p>{currentRecordForLinkedIn.linkedInUrl}</p>
                        </div>
                        {/* <div className='action_btn' style={{ padding: '0' }}></div> */}
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                      </div>
                    </div>
                    {/* Enriched Record */}
                    <div className='col middle-col'>
                      <div className='enriched_contact_card'>
                        <div className='enriched_content_box'>
                          <p className='title_enriched_card'>LinkedIn URL</p>
                          <p>{enrichedRecordsForLinkedIn}</p>
                        </div>
                      </div>
                    </div>
                    {/* PDL */}
                    <div className='col last-col'>
                      <div className='enriched_contact_card'>
                        <Box
                          className='action_btn'
                          sx={{
                            pr: '0 !important',
                            pl: '0 !important',
                            backgroundColor: 'var(--palette-primary-opacity-30) !important',
                            minHeight: '60px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        ></Box>
                        <Box
                          className='enriched_content_box'
                          sx={{
                            backgroundColor: 'var(--palette-primary-opacity-10) !important'
                          }}
                        >
                          <p style={{ textDecoration: addedFromLinkedIn !== 'current' ? 'line-through' : 'none' }}>{pdlRecordForLinkedIn.linkedInUrl}</p>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          )}
        </Dialog>
      )}
    </>
  );
};

EnrichContact.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  linkedin_url: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default EnrichContact;
