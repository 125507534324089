import { Button, Paper } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { createIgnLanguage } from '../../services/ApiService';
import CustomMuiDropdown from '../common/CustomMuiDropdown';

const LanguageTypeSelection = props => {
  // forked from LevelOfEducationTypeSelection
  const { onChange = () => {}, label = '', value, size = 'small', callback = () => {}, options, ...res } = props;

  const [searchValue, setSearchValue] = React.useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);

  const createLanguage = async name => {
    const reqBody = {
      name: name || ''
    };
    const response = await createIgnLanguage(reqBody);
    return response;
  };

  const onAddEducationFunction = async value => {
    setIsLoaded(true);
    if (value === '' || value === null || options.find(option => option.title === value)) {
      setIsLoaded(false);
      enqueueSnackbar(`'${value}' language already exists`, { variant: 'error' });
      return;
    }
    const data = await createLanguage(value);
    await callback();
    if (data?.id && data?.name) {
      enqueueSnackbar('Language created successfully', { variant: 'success' });
    }
    onChange(null, {
      id: data?.id,
      name: data?.name
    });
    setIsLoaded(false);
  };

  const PaperComponent = props => {
    return (
      <Paper {...props}>
        {props.children}
        <Button onMouseDown={() => onAddEducationFunction(searchValue)} fullWidth variant='text'>
          + Add Language
        </Button>
      </Paper>
    );
  };
  PaperComponent.propTypes = {
    children: PropTypes.node
  };

  return (
    <CustomMuiDropdown
      isLoading={isLoaded}
      label={label}
      options={options} // options in the form of {title: ..., value: ...}
      value={value}
      getOptionLabel={option => option.name} // Display the title field in the dropdown
      onChange={(e, d) => {
        onChange(e, d);
      }}
      name={label}
      autoCompleteProps={{
        disableCloseOnSelect: true,
        fullWidth: true,
        // eslint-disable-next-line no-use-before-define
        PaperComponent: PaperComponent
      }}
      textFieldProps={{
        // fullWidth: true,
        // required: required,
        size: size,
        fullWidth: true,
        value: searchValue,
        onChange: e => {
          setSearchValue(e.target.value);
        }
      }}
      {...res}
    />
  );
};

LanguageTypeSelection.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  callback: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object
};

export default LanguageTypeSelection;
