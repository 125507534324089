import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import { Box, CircularProgress, IconButton, List, ListItem, Paper, Stack, Tab, Tabs, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import propTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRecentActivities } from '../../../services/ApiService';
import { homeActions } from '../../../store/HomeSlice';
import { REFETCH_TIME_DELAY_MIN } from '../../../utils/common';
import style from '../Home.module.scss';
import { createEnglishStatements } from '../utils';
import RecentActivityDialog from './Popup/RecentActivityDialog';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function RightSection({ userId, setContactId }) {
  const { storeActivities = [], lastUpdatedAt } = useSelector(state => ({
    storeActivities: state.homeReducer.recentActivities,
    lastUpdatedAt: state.homeReducer.lastUpdatedAt
  }));
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [openActivityDialog, setOpenActivityDialog] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [recentActivities, setRecentActivities] = React.useState(storeActivities);
  const handleChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  const getAllRecentActivity = async (userId, updatedAt) => {
    try {
      if (updatedAt && dayjs().diff(updatedAt, 'minutes') <= REFETCH_TIME_DELAY_MIN) {
        return;
      }
      if (!storeActivities.length) {
        setLoading(true);
      }
      const reqBody = {
        limit: 10,
        filterable: true,
        createdBy: userId
        // sortBy: ['activity_added_at', 'DESC']
      };
      const result = await getRecentActivities(reqBody);
      if (result && result.data) {
        const data = createEnglishStatements(result.data);
        if (data && data.length > 0) {
          const dataToShow = data.filter(activity => Object.values(activity).every(value => value !== null));
          setRecentActivities(dataToShow);
          dispatch(homeActions.updateHomeRecentActivities(dataToShow));
        }
      }
    } catch (err) {
      console.error('Error in getAllRecentActivity:', err);
      setRecentActivities([]);
      dispatch(homeActions.updateHomeRecentActivities([]));
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllRecentActivity(userId, lastUpdatedAt);
  }, []);
  return (
    <>
      <Paper elevation={2} className={`${style.recent_scroll} ${style.paper_custom}`}>
        <Box height={'100%'}>
          <Box className={style.border_bottom}>
            <div className={style.tab_bar}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
                color={theme.palette.heading.main}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: theme.palette.heading.main
                  }
                }}
              >
                <Tab label='Recent Activities' {...a11yProps(0)} style={{ color: theme.palette.heading.main }} />
              </Tabs>
              <IconButton>
                <OpenInFullOutlinedIcon className={style.clickable} onClick={() => setOpenActivityDialog(true)} />
              </IconButton>
              <RecentActivityDialog open={openActivityDialog} setOpen={setOpenActivityDialog} />
            </div>
          </Box>

          <Box className={style.activities_list_custom}>
            {loading ? (
              <Stack justifyContent='center' alignItems='center' height='-webkit-fill-available'>
                <CircularProgress />
              </Stack>
            ) : (
              <List component='nav' aria-label='Recent activities'>
                {recentActivities.map((activity, index) => {
                  const getActivityContent = () => {
                    switch (activity?.activity_type) {
                      case 'candidate_stage_change':
                        return (
                          <>
                            <span>{activity.name} </span>
                            <span>{activity.action}</span>
                            <span className={style.activity_reference} onClick={() => navigate(activity.deeplink)}>
                              #{activity.reference}.
                            </span>
                          </>
                        );
                      case 'project_stage_update':
                        return (
                          <>
                            <span className={style.activity_reference} onClick={() => navigate(activity.deeplink)}>
                              #{activity.reference}.
                            </span>
                            <span>{activity.action}</span>
                            <span style={{ fontWeight: 'bold' }}>{activity.stage_to}</span>
                          </>
                        );
                      case 'NOTE':
                        return (
                          <>
                            <span onClick={() => navigate(activity.deeplink)}>{activity.name} </span>
                            <span>{activity.action}</span>
                            <span className={style.activity_reference} onClick={() => navigate(activity.deeplink)}>
                              #{activity.reference}.
                            </span>
                          </>
                        );
                      default:
                        return (
                          <>
                            <span>{activity.name} </span>
                            <span>{activity.action}</span>
                            <span className={style.activity_reference} onClick={() => navigate(activity.deeplink)}>
                              #{activity.reference}.
                            </span>
                          </>
                        );
                    }
                  };
                  return (
                    <React.Fragment key={index}>
                      <ListItem className={`fs-10 ${style.list}`}>{getActivityContent()}</ListItem>
                    </React.Fragment>
                  );
                })}
              </List>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
}

RightSection.propTypes = {
  setContactId: propTypes.func,
  userId: propTypes.string
};

export default RightSection;
