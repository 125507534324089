import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomButton from '../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import RichText from '../../../../components/common/RichText';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
};

function RejectReasonModal({ open, onSubmit, reasons, setReasons, onClose }) {
  const [rejectReasonsOptions, setRejectReasonsOptions] = useState([]);
  const rejectReason = useSelector(state => state.commonReducer.rejectReason);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!rejectReason) {
      dispatch(fetchPickLists('REJECT_REASON', 'rejectReason'));
    } else {
      setRejectReasonsOptions(rejectReason);
    }
  }, [rejectReason, dispatch]);

  const handleChange = (event, newValue) => {
    const uniqueData = Array.from(new Map(newValue.map(item => [item.id, item])).values());
    setReasons({
      ...reasons,
      reasons: uniqueData
    });
  };

  const handleModalClose = event => {
    setReasons({
      reasons: [],
      comments: ''
    });
    onClose(event);
  };

  const handleSubmit = event => {
    onSubmit(reasons, event);
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box sx={{ ...modalStyle, width: '40rem', border: 'none' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #ebedf1',
            paddingBottom: '.6rem',
            marginBottom: '1rem',
            alignItems: 'center'
          }}
        >
          <Typography variant='h3' style={{ fontWeight: 'bolder', fontSize: '1rem' }}>
            {t('project.reject_reason.rejectReasonHeaderText')}
          </Typography>
          <IconButton onClick={handleModalClose} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <CustomMuiDropdown
            getOptionLabel={option => option['field_value']}
            value={reasons.reasons}
            label={t('project.reject_reason.reasonPlaceholderText')}
            options={rejectReasonsOptions}
            onChange={handleChange}
            isDrawer={false}
            required={false}
            size='small'
            multiple
          />
          <Box className='pl-1 hover-border'>
            <Box className='title-color fs-12'>{t('project.reject_reason.commentsPlaceholderText')}</Box>
            <Box className='rich-text-modal-action-box'>
              <RichText
                defaultValue={reasons.comments || ''}
                onChange={value => {
                  setReasons({ ...reasons, comments: value });
                }}
                style={{ fontSize: '10px', border: 'none' }}
                readOnly={false}
              />
            </Box>
          </Box>
        </Box>
        <CustomButton type='primary' onClick={handleSubmit} buttonText={t('actions.confirm')} size='medium' disabled={reasons.reasons?.length === 0} boxClassName='w-full mt-4' fullWidth />
      </Box>
    </Modal>
  );
}

RejectReasonModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reasons: PropTypes.object.isRequired,
  setReasons: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RejectReasonModal;
