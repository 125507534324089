import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ButtonDropDown = props => {
  const {
    buttonStyle = {},
    show = true,
    options = [],
    MenuItemStyle = {},
    fontWeight,
    variant = 'outlined',
    buttonText = '',
    iconLeft,
    iconRight,
    className = '',
    renderPropertyName,
    MenuStyle,
    ellipsis = false,
    ellipsisWidth = '110px'
  } = props;
  if (!show) return <></>;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option, e, close = true) => {
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick(option, e);
    }
    if (close) {
      handleClose();
    }
  };

  return (
    <Box className={className}>
      <Button color='primary' style={buttonStyle} variant={variant} onClick={handleClick} size='small' disabled={props.disabled ? props.disabled : false}>
        <Box fontWeight={fontWeight} className={'d-flex align-items-center justify-content-center'}>
          {iconLeft}{' '}
          <Tooltip title={buttonText}>
            <Box sx={ellipsis ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: ellipsisWidth } : {}}>{buttonText}</Box>
          </Tooltip>
          {iconRight}
        </Box>
      </Button>
      <Menu anchorEl={anchorEl} open={open} sx={MenuStyle} onClose={handleClose} MenuListProps={{ sx: { padding: 0 } }}>
        {options.map((option, index) => (
          <MenuItem onClick={e => handleMenuItemClick(option, e, option?.close)} key={index} sx={MenuItemStyle}>
            {renderPropertyName ? option[renderPropertyName] : option?.component ? option?.component : option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

ButtonDropDown.propTypes = {
  buttonText: PropTypes.string,
  options: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  show: PropTypes.bool,
  className: PropTypes.string,
  renderPropertyName: PropTypes.string,
  MenuItemStyle: PropTypes.object,
  MenuStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  ellipsis: PropTypes.bool,
  ellipsisWidth: PropTypes.string
};

export { ButtonDropDown };
