import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { columnDefs, SwitchRenderer, createNestedArray, NameRenderer } from './utils';
import { roleResourcesApi } from '../../../../services/ApiService';
import { POST, PUT } from '../../../../services/constantService';
import AgGridWrapper from '../../../../utils/AgGridWrapper';

const ManageResourcesGrid = props => {
  const { roleResourceData, allResourcesData, roleId, refetchRoleResource } = props;

  if (allResourcesData.error || roleResourceData.error) return null;

  const { toggleResource, togglePermissions } = ManageResourcesApis({
    roleId,
    refetchRoleResource
  });

  // This will be intersction of roleResourceData and allResourcesData,
  // we have to show only those resouces which are assigned to user,
  // but all the resources of that role should be shown in grid
  // and switch should be on/off based on roleResourceData
  const [gridResources, setGridResources] = React.useState([]);

  useEffect(() => {
    if (!allResourcesData || !roleResourceData) return;

    const data = allResourcesData;
    const parentName = 'Root';
    const nestedResources = createNestedArray(data, parentName);

    // From NestedArray check if the first object exists in roleResourceData, if not remove the whole array
    const filteredNestedResources = nestedResources.filter(nestedResource => {
      const resourceId = nestedResource[0].id;
      const resourceData = roleResourceData.find(roleResource => {
        return roleResource.resource_id === resourceId;
      });

      if (!resourceData) return false;
      return true;
    });

    // Add resourceData to each resource for which it exists, to display switch on/off
    const resourcesWithUsrData = filteredNestedResources.map(nestedResource => {
      return nestedResource.map(resource => {
        const resourceData = roleResourceData.find(roleResource => {
          return roleResource.resource_id === resource.id;
        });

        if (!resourceData) return resource;

        return {
          ...resource,
          resourceData: resourceData
        };
      });
    });

    setGridResources(resourcesWithUsrData);
  }, [allResourcesData, roleResourceData]);

  return (
    <div>
      {gridResources.map(resource => {
        return (
          <div key={resource[0].id} className='mb-3'>
            <div className='ag-theme-alpine text-center' id='myGrid'>
              <AgGridWrapper
                onGridReady={params => params.api.refreshCells()}
                rowData={resource}
                columnDefs={columnDefs}
                domLayout='autoHeight'
                components={{
                  switchRenderer: props => <SwitchRenderer {...props} togglePermissions={togglePermissions} />,
                  nameRenderer: props => {
                    return <NameRenderer {...props} toggleResource={toggleResource} />;
                  }
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

ManageResourcesGrid.propTypes = {
  rolesData: PropTypes.array,
  userRolesData: PropTypes.array,
  roleResourceData: PropTypes.array,
  allResourcesData: PropTypes.array,
  roleId: PropTypes.string,
  refetchRoleResource: PropTypes.func
};

export default ManageResourcesGrid;

const ManageResourcesApis = props => {
  const { roleId, refetchRoleResource } = props;

  // This will be used to toggle Checkbox and turn on/off individual resources as whole
  const toggleResource = async (resourceId, state) => {
    if (state) {
      const data = [
        {
          resource_id: resourceId,
          role_id: roleId,
          can_create: true,
          can_read: true,
          can_update: true,
          can_delete: true
        }
      ];

      await roleResourcesApi(POST, '', {
        resources: data
      }).then(() => {
        refetchRoleResource();
      });
    } else {
      await roleResourcesApi(POST, 'deleteByRoleAndResource', {
        resources: [
          {
            role_id: roleId,
            resource_id: resourceId
          }
        ]
      }).then(() => {
        refetchRoleResource();
      });
    }
  };

  const togglePermissions = async (resourceId, data) => {
    await roleResourcesApi(PUT, `updateById/${resourceId}`, data);
  };

  return { toggleResource, togglePermissions };
};

ManageResourcesApis.propTypes = {
  rolesData: PropTypes.array,
  userRolesData: PropTypes.array,
  roleResourceData: PropTypes.array,
  allResourcesData: PropTypes.array,
  roleId: PropTypes.string,
  refetchRoleResource: PropTypes.func
};
