import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { formatDateWithMomentTimeZone } from '../../../../utils/date';

function SetNote(props) {
  const item = props?.item;
  const themeColor = props.theme;
  const [active, setActive] = useState(false);
  const [wordLimitExceed, setWordLimitExceed] = useState(false);

  useEffect(() => {
    const words = item?.notes.split(/\s+/);
    if (words.length > 46) {
      setWordLimitExceed(true);
    } else {
      setWordLimitExceed(false);
    }
  }, [props.item]);

  return (
    <>
      <Box className='mb-4'>
        <Typography variant='body2' component='p' className='fs-14 mt-2 '>
          {active ? (
            <>
              <div
                className='fs-14'
                dangerouslySetInnerHTML={{
                  __html: item?.notes
                }}
              />
              {wordLimitExceed && (
                <button onClick={() => setActive(false)} className='border-0 mb-1 mt-1 p-0 fs-12' style={{ background: 'transparent', color: themeColor ? themeColor.secondary_color : '' }}>
                  View less
                </button>
              )}
            </>
          ) : (
            <>
              <div
                className='fs-14 multiline-elipsis'
                dangerouslySetInnerHTML={{
                  __html: item?.notes
                }}
              />
              {wordLimitExceed && (
                <button onClick={() => setActive(true)} className='border-0 mb-1 mt-1 p-0 fs-12' style={{ background: 'transparent', color: themeColor ? themeColor.secondary_color : '' }}>
                  View more
                </button>
              )}
            </>
          )}
        </Typography>
        <Typography variant='body2' component='span' className='d-flex fs-12 mt-2 text-muted'>
          {formatDateWithMomentTimeZone(item?.updated_at)}
        </Typography>
      </Box>
    </>
  );
}

SetNote.propTypes = {
  item: PropTypes.object,
  theme: PropTypes.object
};
export default SetNote;
