//-----------------------------------------------------------// In-built Imports // ------------------------------

import * as momentTimeZone from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrandConfigsData } from '../../actions';
//-----------------------------------------------------------// External Imports // ------------------------------

import { getUserSelector } from '../../selectors';
import { userConfigs } from '../../services/ApiService';
import { POST } from '../../services/constantService';
import { updateDateFormats, updateLanguage, updateTimeFormats, updateTimeZoneFormat } from '../../store/profileFormatSlice';
import { defaultTheme } from '../../theme';
const convertThemeConfig = (config = {}, theme) => {
  const newTheme = { ...theme };
  const configList = [
    { srcKey: 'primary', dbKey: 'primary_color' },
    { srcKey: 'secondary', dbKey: 'secondary_color' },
    { srcKey: 'text', dbKey: 'text_color' },
    { srcKey: 'heading', dbKey: 'heading_color' },
    { srcKey: 'error', dbKey: 'error_color' },
    { srcKey: 'background', dbKey: 'background_color' }
  ];
  for (const element of configList) {
    newTheme[element.srcKey] = config[element.dbKey] || newTheme[element.srcKey];
  }
  return newTheme;
};
export const useThemeHook = (fetchUserConfig = true) => {
  const [theme, setTheme] = useState(defaultTheme());
  const [colorData, setColorData] = useState({});
  const dispatch = useDispatch();
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const userData = useSelector(getUserSelector);
  const profileFormat = useSelector(state => state.profileFormatSlice);
  const getCurrentLocalUserTimeZone = () => {
    return momentTimeZone.tz.guess();
  };
  const getUserConfigInfo = async () => {
    try {
      if (!userData) return;
      const res = await userConfigs(POST, {
        userId: userData.id || '',
        time_zone: getCurrentLocalUserTimeZone(),
        date_format: 'DD/MM/YYYY',
        time_format: '12-hours',
        language: 'en'
      });

      const userConfig = res?.data?.userConfig || {};

      if (userConfig.date_format) {
        dispatch(
          updateDateFormats({
            label: userConfig.dateFormat || 'DD/MM/YYYY',
            value: userConfig.dateFormat || 'DD/MM/YYYY'
          })
        );
      }
      if (userConfig.time_format) {
        dispatch(
          updateTimeFormats({
            label: userConfig.timeFormat || '12-hours',
            value: userConfig.timeFormat || '12-hours'
          })
        );
      }
      if (userConfig.time_zone) {
        dispatch(
          updateTimeZoneFormat({
            label: userConfig.timeZoneFormat || 'UTC',
            value: userConfig.timeZoneFormat || 'UTC'
          })
        );
      }
      if (userConfig.language) {
        const languageOptions = [
          { label: 'English', value: 'en' },
          { label: 'Spanish', value: 'es' },
          { label: 'Portuguese', value: 'esp' }
        ];
        dispatch(
          updateLanguage({
            label: languageOptions.find(ele => ele.value === userConfig.language)?.label || 'English',
            value: userConfig.language || 'en'
          })
        );
      }
      return res;
    } catch (e) {
      console.log('error', e);
    }
  };
  useEffect(() => {
    if (fetchUserConfig) {
      getUserConfigInfo();
    }
  }, [userData]);

  useEffect(() => {
    if (fetchUserConfig) {
      getUserConfigInfo();
    }
    if (!brandConfigs) {
      /* Pass the Brand Config Version dynamically once the UI Setup integration done */
      dispatch(fetchBrandConfigsData('brandConfigs', 'is_default=true'));
    } else {
      let themeData = {
        primary: '#009d81',
        secondary: '#23cea7',
        text: '#333',
        heading: '#1b4965',
        error: '#c3423f',
        background: '#f0f1f6',
        coolGray: '#959fb7'
      };
      const newThemeData = convertThemeConfig(brandConfigs, themeData);
      setColorData(newThemeData);
      // themeData = null;
      // const newTheme = defaultTheme(newThemeData);
      // setTheme(newTheme);
    }
  }, [brandConfigs]);

  useEffect(() => {
    const newTheme = defaultTheme(colorData, {
      dateFormat: profileFormat?.dateFormat?.value || 'DD/MM/YYYY',
      timeFormat: profileFormat?.timeFormats?.value || '12-hours',
      timeZoneFormat: profileFormat?.timeZoneFormat?.value || 'UTC'
    });
    setTheme(newTheme);
  }, [colorData]);

  return { theme, colorData };
};
