import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../../../../components/common/Loader';
import BeginnerBadgeIcon from '../../../../components/Icons/BeginnerBadgeIcon';
import CheckIcon from '../../../../components/Icons/CheckIcon';
import CompetentBadgeIcon from '../../../../components/Icons/CompetentBadgeIcon';
import DevelopingBadgeIcon from '../../../../components/Icons/DevelopingBadgeIcon';
import ExpertBadgeIcon from '../../../../components/Icons/ExpertBadgeIcon';
import ProficientBadgeIcon from '../../../../components/Icons/ProficientBadgeIcon';
import ThumbsDownIcon from '../../../../components/Icons/ThumbsDownIcon';
import ThumbsUpIcon from '../../../../components/Icons/ThumbsUpIcon';
import { labelRubricsDataApi, picklistsDataApis } from '../../../../services/ApiService';
import { ERROR, GET, PICKLISTS, POST, PUT } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';

function RubricPreview({ labelId, modalOpen, onClose, rubricType, rubricBinaryRatings, rubricGradedRatings }) {
  const [rubricData, setRubricData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedItem, setEditedItem] = useState({ rubric_title: '', rubric_requirements: '' });
  const [rubricBinaryTypes, setRubricBinaryTypes] = useState(rubricBinaryRatings);
  const [rubricGradedTypes, setRubricGradedTypes] = useState(rubricGradedRatings);
  const [loading, setLoading] = useState(false);
  const sortRubricTypes = types =>
    types
      .map(type => {
        const [splitKey, shortDescManipulated] = type.short_desc.split('-').map(part => part?.trim());
        return { ...type, splitKey: Number(splitKey), shortDescManipulated };
      })
      .sort((a, b) => a.splitKey - b.splitKey);

  useEffect(() => {
    // Sort props data if available
    if (rubricBinaryRatings?.length) {
      setRubricBinaryTypes(sortRubricTypes(rubricBinaryRatings));
    }
    if (rubricGradedRatings?.length) {
      setRubricGradedTypes(sortRubricTypes(rubricGradedRatings));
    }
  }, [rubricBinaryRatings, rubricGradedRatings]);

  useEffect(() => {
    const fetchRubricTypes = async () => {
      const fetchData = async endpoint => {
        const { data } = await useApi({
          queryFn: () => picklistsDataApis(GET, endpoint)
        });
        return data?.data || [];
      };

      if (!rubricBinaryRatings?.length) {
        const binaryTypes = await fetchData(PICKLISTS.RUBRIC_RATING_BINARY);
        setRubricBinaryTypes(sortRubricTypes(binaryTypes));
      }
      if (!rubricGradedRatings?.length) {
        const gradedTypes = await fetchData(PICKLISTS.RUBRIC_RATING_GRADE);
        setRubricGradedTypes(sortRubricTypes(gradedTypes));
      }
    };

    fetchRubricTypes();
  }, [rubricBinaryRatings, rubricGradedRatings]);

  const fetchRubricData = async () => {
    try {
      setLoading(true);
      const response = await labelRubricsDataApi(GET, `label/${labelId}`, null);
      if (response.status !== 200) {
        enqueueSnackbar('Error fetching label rubrics', { variant: ERROR });
        return;
      }
      setLoading(false);
      setRubricData(response.data || []);
    } catch (error) {
      enqueueSnackbar('Error fetching label rubrics', { variant: ERROR });
    }
  };

  useEffect(() => {
    fetchRubricData();
  }, [labelId]);

  const handleEditStart = index => {
    setEditingIndex(index);
    setEditedItem({
      rubric_title: rubricData[index]?.rubric_title || '',
      rubric_requirements: rubricData[index]?.rubric_requirements || []
    });
  };

  const handleSave = async (index, rowCount) => {
    const updatedData = [...rubricData];
    const label = rowCount === 2 ? rubricBinaryTypes[index]?.shortDescManipulated : rubricGradedTypes[index]?.shortDescManipulated;
    const updatedItem = {
      ...updatedData[index],
      label_id: labelId,
      rubric_rating: label,
      rubric_title: editedItem.rubric_title,
      rubric_requirements: editedItem.rubric_requirements
    };
    updatedData[index] = updatedItem;
    try {
      setLoading(true);
      const method = updatedItem.id ? PUT : POST;
      const response = await labelRubricsDataApi(method, updatedItem.id, updatedItem);
      if (response.status >= 300) {
        enqueueSnackbar('Error updating label', { value: ERROR });
        return;
      }
      setRubricData(updatedData);
      setEditingIndex(null);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar('Error updating label', { value: ERROR });
      return;
    }
  };

  const getAvatarLabel = (index, totalRows) => {
    const label = totalRows === 2 ? rubricBinaryTypes[index]?.shortDescManipulated : rubricGradedTypes[index]?.shortDescManipulated;

    const isNeedsImprovement = label === 'Needs Improvement';
    const formattedLabel = isNeedsImprovement ? (
      <>
        <Typography variant='caption' fontWeight='bold' color='#23cea7'>
          Needs
        </Typography>
        <Typography variant='caption' fontWeight='bold' color='#23cea7'>
          Improvement
        </Typography>
      </>
    ) : (
      <Typography variant='caption' fontWeight='bold' color='#23cea7'>
        {label?.toUpperCase()}
      </Typography>
    );

    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='100px'
        height='100px'
        border='0px solid #ccc'
        boxShadow='0 4px 6px rgba(0, 0, 0, 0.1)'
        borderRadius='8px'
        padding='10px 8px'
        textAlign='center'
      >
        {/* Icons */}
        {label === 'Ideal' && totalRows === 2 && <ThumbsUpIcon />}
        {label === 'Needs Improvement' && totalRows === 2 && <ThumbsDownIcon />}
        {label === 'Expert' && totalRows === 5 && <ExpertBadgeIcon />}
        {label === 'Proficient' && totalRows === 5 && <ProficientBadgeIcon />}
        {label === 'Competent' && totalRows === 5 && <CompetentBadgeIcon />}
        {label === 'Developing' && totalRows === 5 && <DevelopingBadgeIcon />}
        {label === 'Needs Improvement' && totalRows === 5 && <BeginnerBadgeIcon />}

        {/* Label */}
        {formattedLabel}
      </Box>
    );
  };

  const renderRows = () => {
    const rowCount = rubricType.toLowerCase() === 'binary' ? 2 : 5;
    const sortedRubricData = rubricData.sort((a, b) => {
      const levelA = parseInt(a.level, 10);
      const levelB = parseInt(b.level, 10);
      return levelB - levelA;
    });
    let displayData = [...sortedRubricData];
    if (displayData.length < rowCount) {
      const emptyRows = Array.from({ length: rowCount - displayData.length }, () => ({
        icon: null,
        rubric_title: '',
        rubric_requirements: []
      }));
      displayData = [...displayData, ...emptyRows];
    }

    return displayData.map((item, index) => (
      <>
        <Box
          key={index}
          display='flex'
          alignItems='center'
          sx={{
            width: '100%',
            mb: 2,
            position: 'relative',
            marginTop: 1,
            '&:hover .edit-icon': {
              opacity: 1,
              transition: 'opacity 0.3s ease'
            }
          }}
        >
          {/* Left Column - Avatar with Label */}
          <Box
            sx={{
              width: '15%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1
            }}
          >
            {getAvatarLabel(index, rowCount)}
          </Box>

          {/* Middle Column - Details */}
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {editingIndex === index ? (
              <>
                <TextField
                  value={editedItem.rubric_title}
                  onChange={e =>
                    setEditedItem(prev => ({
                      ...prev,
                      rubric_title: e.target.value
                    }))
                  }
                  variant='outlined'
                  size='small'
                  fullWidth
                  placeholder='Enter Rubric Title'
                  sx={{ mb: 1, width: '100%' }}
                />
                <ReactQuill
                  value={editedItem?.rubric_requirements?.length ? `<ul>${editedItem.rubric_requirements.map(req => `<li>${req}</li>`).join('')}</ul>` : '<ul><li></li></ul>'}
                  modules={{
                    toolbar: false
                  }}
                  placeholder='Enter Rubric Requirements'
                  style={{ width: '100%' }}
                  onKeyDown={event => {
                    if (event.key === 'Enter' && event.target.tagName === 'LI') {
                      const selection = window.getSelection();
                      const range = selection.getRangeAt(0);
                      const currentLi = range.commonAncestorContainer.closest('li');

                      if (currentLi && currentLi === currentLi.parentNode.lastElementChild) {
                        event.preventDefault();
                        const newLi = document.createElement('li');
                        currentLi.parentNode.appendChild(newLi);

                        // Move cursor to new li
                        const newRange = document.createRange();
                        newRange.selectNodeContents(newLi);
                        newRange.collapse(true);
                        selection.removeAllRanges();
                        selection.addRange(newRange);
                      }
                    }
                  }}
                  onChange={(content, delta, source, editor) => {
                    if (source === 'user') {
                      const tempDiv = document.createElement('div');
                      tempDiv.innerHTML = content;

                      const updatedRequirements = Array.from(tempDiv.querySelectorAll('li'))
                        .map(li => li.innerHTML)
                        .filter(text => text.trim() !== '');

                      setEditedItem(prev => ({
                        ...prev,
                        rubric_requirements: updatedRequirements.length ? updatedRequirements : []
                      }));
                    }
                  }}
                />
              </>
            ) : (
              <>
                {item?.rubric_title?.length > 0 ? (
                  <TextField
                    value={item.rubric_title || ''}
                    variant='outlined'
                    size='small'
                    fullWidth
                    placeholder='Enter Rubric Title'
                    sx={{
                      mb: 1,
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        border: 'none'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      },
                      '& .MuiInputBase-input': {
                        fontWeight: 'bold'
                      }
                    }}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                ) : (
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      fontStyle: 'italic',
                      textAlign: 'center',
                      py: 1
                    }}
                  >
                    No title added yet.
                  </Typography>
                )}
                <Box
                  sx={{
                    width: '100%',
                    borderRadius: 1
                  }}
                >
                  {item?.rubric_requirements?.length ? (
                    <List dense>
                      {item.rubric_requirements.map((requirement, idx) => (
                        <ListItem key={idx}>
                          <ListItemIcon sx={{ minWidth: '20px' }}>
                            <CheckIcon />
                          </ListItemIcon>
                          <ListItemText primary={requirement} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{
                        fontStyle: 'italic',
                        textAlign: 'center',
                        py: 1
                      }}
                    >
                      No requirements added yet.
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>

          {/* Edit Icon */}
          <Box
            className='edit-icon'
            sx={{
              width: '5%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              opacity: 0
            }}
          >
            <IconButton onClick={() => (editingIndex === index ? handleSave(index, rowCount) : handleEditStart(index))} size='small'>
              {editingIndex === index ? <SaveIcon fontSize='small' /> : <EditIcon fontSize='small' />}
            </IconButton>
          </Box>
        </Box>
        <Divider />
      </>
    ));
  };

  return (
    <CustomModalWithHeader
      label='Preview Scorecard'
      isOpen={modalOpen}
      onClose={onClose}
      closeIcon={true}
      headerClass='modal-header-background p-2 rounded-top mb-0'
      style={{
        padding: 0,
        minWidth: '70%',
        maxHeight: '85%',
        overflowY: 'auto'
      }}
    >
      <Loader show={loading} />
      {renderRows()}
    </CustomModalWithHeader>
  );
}

RubricPreview.propTypes = {
  labelId: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  rubricType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  rubricBinaryRatings: PropTypes.array,
  rubricGradedRatings: PropTypes.array
};

export default RubricPreview;
