import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ColorDropdowns = ({ colors, onChange, selectedColor }) => {
  const [selectedValue, setSelectedValue] = useState(selectedColor);

  const { t } = useTranslation();

  const renderValue = value => {
    if (value === 'NA')
      return (
        <Box sx={{ width: 12, height: 12, display: 'flex', alignItems: 'center' }}>
          <Typography>--</Typography>
        </Box>
      );
    const selectedColor = colors.find(color => color.id === value);
    return <Box marginTop={'2px'} border={'0.1px solid black'} bgcolor={selectedColor?.color_code || 'transparent'} width={12} height={12} />;
  };

  return (
    <FormControl fullWidth>
      <Select
        style={{
          fontSize: '12px'
        }}
        labelId='color-select-label'
        value={selectedValue === null ? 'NA' : selectedValue}
        onChange={event => {
          const colorId = event.target.value || null;
          setSelectedValue(colorId);
          onChange(colorId);
        }}
        size='small'
        sx={{
          fontSize: '12px',
          '& .MuiSelect-icon': {
            color: 'black',
            fontSize: '1.2rem'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={renderValue}
      >
        <Button>
          <Typography sx={{ textTransform: 'none' }} fontWeight={'bold !important'} fontSize={'12px !important'}>
            {t('actions.clearFilter')}
          </Typography>
        </Button>
        <MenuItem key={'NA'} sx={{ visibility: 'hidden' }} value={'NA'}></MenuItem>
        {colors.map(color => (
          <MenuItem key={color?.id} value={color?.id}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1}>
              <Box border={'0.1px solid black'} bgcolor={color?.color_code} width={12} height={12} />{' '}
              <Typography sx={{ fontSize: '12px !important' }}>{color?.workbench_labels?.[0]?.label || '--'}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ColorDropdowns.propTypes = {
  colors: PropTypes.array,
  onChange: PropTypes.func,
  selectedColor: PropTypes.string
};

export default ColorDropdowns;
