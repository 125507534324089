import React from 'react';
import RouteOpener from '../../../../../components/common/RouteToNewPage';

export const queryContactColumn = [
  {
    field: 'contact_name',
    headerName: 'Contact Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.contact_name ? <RouteOpener type='contact' id={params.data?.id} label={params.data?.contact_name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.contact_name || null;
    }
  },
  {
    field: 'tags',
    headerName: 'Tags',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'company_name',
    headerName: 'Company Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.company_name ? <RouteOpener type='company' id={params.data?.company_id} label={params.data?.company_name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.company_name || null;
    }
  },
  {
    field: 'country',
    headerName: 'Country',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'job_function',
    headerName: 'Job Function',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'industry',
    headerName: 'Industry',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'lastActivity',
    headerName: 'Last Activity',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  }
];

export const queryProjectColumn = [
  {
    field: 'project_name',
    headerName: 'Project Name',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.helia_title ? <RouteOpener type='project' id={params.data?.id} label={params.data?.helia_title} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.helia_title || null;
    }
  },
  {
    field: 'tags',
    headerName: 'Tags',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'project_type',
    headerName: 'Type',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'engagement_type',
    headerName: 'Service Offering',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'geography',
    headerName: 'Geography',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'industry',
    headerName: 'Industry',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'date_opened',
    headerName: 'Date Opened',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'Stage',
    headerName: 'Stage',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  }
];

export const queryCompanyColumn = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.name ? <RouteOpener type='company' id={params.data?.id} label={params.data?.name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.name || null;
    }
  },
  {
    field: 'tags',
    headerName: 'Tags',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'industry',
    headerName: 'Industry',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'employee_range',
    headerName: 'Employee Range',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'revenue_range',
    headerName: 'Revenue Range',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'company_status',
    headerName: 'Stage',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  }
];

export const queryCandidateColumn = [
  {
    field: 'name',
    headerName: 'Candidate Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.name ? <RouteOpener type='contact' id={params.data?.contact?.id} label={params.data?.name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.name || null;
    }
  },
  {
    field: 'contactTags',
    headerName: 'Contact Tags',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'projectTags',
    headerName: 'Project Tags',
    minWidth: 200,
    flex: 1,
    filter: 'agMultiColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'project_name',
    headerName: 'Project Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.project_name ? <RouteOpener type='project' id={params?.data?.project?.id} label={params?.data?.project_name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.project_name || null;
    }
  },
  {
    field: 'project_industry',
    headerName: 'Project Industry',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'stage',
    headerName: 'Stage',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'current_company_name',
    headerName: 'Current Company Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params =>
      params?.data?.current_company_name ? <RouteOpener type='company' id={params.data?.contact?.contact_company?.id} label={params.data?.current_company_name} capitalize={true} /> : null,
    valueGetter: params => {
      return params?.data?.current_company_name || null;
    }
  },
  {
    field: 'previous_company_name',
    headerName: 'Previous Company Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRenderer: params => (params?.data?.previous_company_name ? <RouteOpener type='company' id={params.data?.pastCompanyId} label={params.data?.previous_company_name} capitalize={true} /> : null),
    valueGetter: params => {
      return params?.data?.previous_company_name || null;
    }
  },
  {
    field: 'current_job_title',
    headerName: 'Current Job Title',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'previous_job_title',
    headerName: 'Previous Job Title',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'industry',
    headerName: 'Industry',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'lastActivity',
    headerName: 'Last Activity Date',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'lastActivityBy',
    headerName: 'Last Activity By',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  }
];
