import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import { getAccessToken } from '../../../../services/cognitoService';
import { API } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

export default function MultipleUserSelection(props) {
  const { placeholder, onChange = () => {}, label, defaultValue = [], ...rest } = props;

  const users = useSelector(state => state.commonReducer.ign_user);

  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const setUserData = async () => {
      if (users?.data?.length) {
        const token = await getAccessToken();
        const formattedOptions = users.data.map(ele => ({
          id: ele.id,
          label: ele.name,
          logo: `${API.users}/${ele.id}/profileimage?token=${token}`
        }));
        setOptions(formattedOptions);
      }
    };

    if (!users?.data) {
      dispatch(fetchPickListData('user?name=', 'ign_user'));
    } else {
      setUserData();
    }
  }, [users, dispatch]);

  useEffect(() => {
    if (options.length > 0 && values.length === 0 && defaultValue.length > 0) {
      const selectedValues = defaultValue
        .map(x => {
          const matchedOption = options.find(option => option.id === x.id);
          return matchedOption;
        })
        .filter(Boolean);

      setValues(selectedValues);
      onChange(selectedValues);
    }
  }, [options, defaultValue]);

  const handleChange = (e, selectedItems) => {
    setValues(selectedItems);
    onChange(selectedItems);
  };

  return <CustomDropdown options={options} label={label} multiple={true} isCheckBox={true} disableCloseOnSelect={true} placeholder={placeholder} onChange={handleChange} value={values} {...rest} />;
}

MultipleUserSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.array
};
