import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const IndustrySelection = props => {
  const {
    // value,
    name = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    placeholder,
    disableCloseOnSelect = false,
    defaultValue,
    className = '',
    isCheckBox = false,
    label = ''
    //selectedList,
  } = props;

  const [options, setOptions] = useState([]);
  const industries = useSelector(state => state.commonReducer.industries);
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);

  useEffect(() => {
    if (!industries) {
      dispatch(fetchPickListData('industries-picklist', 'industries'));
    } else {
      const filteredIndustries = industries.filter(ind => ind.name.length > 1);
      setOptions(filteredIndustries);
    }
  }, [industries, dispatch]);
  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      const industryName = x?.industries ? x?.industries?.name : x?.name;
      const index = options && options.findIndex(obj => obj?.name === industryName);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };
  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };
  return (
    <>
      <CustomDropdown
        options={options}
        value={option ? values : defaultValue ? getValueOfSelected() : []}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        isDrawer={isDrawer}
        multiple={multiple}
        placeholder={placeholder}
        disableCloseOnSelect={disableCloseOnSelect}
        className={className}
        isCheckBox={isCheckBox}
        label={label}
        selectedValue={defaultValue}
      ></CustomDropdown>
    </>
  );
};
IndustrySelection.propTypes = {
  defaultValue: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  selectedList: PropTypes.array,
  industry: PropTypes.array
};

export default IndustrySelection;
