import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const DescriptionRender = params => {
  return (
    <div className='description-tooltip'>
      <Tooltip placement='right' arrow title={params?.data?.description}>
        <p>{params?.valueFormatted}</p>
      </Tooltip>
    </div>
  );
};

DescriptionRender.propTypes = {
  params: PropTypes.object
};

export default DescriptionRender;
