import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ProjectStagesDataApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const SearchStageSelection = props => {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const {
    className = 'SingleSelectStyle',
    onChange = () => {},
    isDrawer = false,
    label = '',
    defaultValue = [],
    required = false,
    disabled = false,
    value = [],
    isMultiSelect = false,
    size = 'small',
    InputLabelProps = {},
    ...rest
  } = props;

  const fetchData = async () => {
    try {
      const res = await ProjectStagesDataApi(GET);

      if (res?.data) {
        const formattedOptions = res.data.map(stage => ({
          id: stage.id,
          label: stage.name,
          name: stage.name
        }));
        setOptions(formattedOptions);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  useEffect(() => {
    if (options.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  // Handle dropdown value change
  const handleChange = (e, val) => {
    setSelectedValues(val);
    onChange(e, val);
  };

  return (
    <CustomDropdown
      {...rest}
      value={selectedValues}
      label={label}
      options={options}
      multiple={isMultiSelect}
      isCheckBox={isMultiSelect}
      className={className}
      onChange={handleChange}
      isDrawer={isDrawer}
      required={required}
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
      InputLabelProps={InputLabelProps}
    />
  );
};

// PropTypes validation
SearchStageSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.array,
  size: PropTypes.string,
  style: PropTypes.object,
  isMultiSelect: PropTypes.bool,
  InputLabelProps: PropTypes.object
};

export default SearchStageSelection;
