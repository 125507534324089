import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import DynamicTabLayout from '../../../DynamicTabs/TabLayout';
import ViewCompanyProfile from './ViewCompanyProfile';
// import ViewAssociatedCompanies from './ViewAssociatedContacts';
import ViewAssociatedCompanies from './ViewAssociatedCompanies';
import ViewAssociatedContacts from './ViewAssociatedContacts';
import ViewCompanyDocuments from './ViewCompanyDocuments';
import ViewEngagement from './ViewEngagement';
import ViewInvoices from './ViewInvoices';

const CompanyDetails = props => {
  const {
    updateField,
    getValues,
    data,
    detailsData,
    id,
    watch,
    register,
    unregister,
    setValue = () => {},
    onTabChange,
    isHeaderNav,
    contactData,
    getContactDetails,
    setLoading = () => {},
    tabIndex,
    setTabIndex,
    activeTab,
    setActiveTab,
    defaultEngagementTab = 'projects'
  } = props;
  // const id = "";
  // const [activeTab, setActiveTab] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);
  const TabData = [
    {
      label: 'Profile',
      content: '',
      subTabs: []
    },
    {
      label: 'Associated Contacts',
      content: '',
      subTabs: []
    },
    {
      label: 'Associated Companies',
      content: '',
      subTabs: []
    },
    {
      label: 'Engagement',
      content: '',
      subTabs: []
    },
    {
      label: 'Invoices',
      content: '',
      subTabs: []
    },
    {
      label: 'Documents',
      content: '',
      subTabs: []
    }
  ];

  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };
  const handleTabClick = async tabName => {
    onTabChange(tabName);
  };
  return (
    <Box className='all-company-tabs'>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={`/companies/all-companies/${id}`}
        isDrawer={true}
        isDrawerCss={true}
        onTabChange={handleTabClick}
        isHeaderNav={isHeaderNav}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <ViewCompanyProfile
          index='Profile'
          updateField={updateField}
          renderActions={renderActions}
          data={data}
          register={register}
          unregister={unregister}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          detailsData={detailsData}
          isHeaderNav={isHeaderNav}
          // values={defaultValues}
        ></ViewCompanyProfile>
        <ViewAssociatedContacts
          index='Associated Contacts'
          companyData={data}
          contactData={contactData}
          getContactDetails={getContactDetails}
          updateField={updateField}
          register={register}
          unregister={unregister}
          setValue={setValue}
          isHeaderNav={isHeaderNav}
        ></ViewAssociatedContacts>
        <ViewAssociatedCompanies
          index='Associated Companies'
          detailsData={detailsData}
          register={register}
          unregister={unregister}
          updateField={updateField}
          setValue={setValue}
          isHeaderNav={isHeaderNav}
        ></ViewAssociatedCompanies>
        <ViewEngagement
          index='Engagement'
          companyData={data}
          setLoading={setLoading}
          defaultEngagementTab={defaultEngagementTab}
          isHeaderNav={isHeaderNav}
          //detailsData={detailsData}
        ></ViewEngagement>
        <ViewCompanyDocuments isHeaderNav={isHeaderNav} index='Documents' data={detailsData} companyData={data} getContactDetails={getContactDetails} setLoading={setLoading}></ViewCompanyDocuments>
        <ViewInvoices index='Invoices' companyData={data} isHeaderNav={isHeaderNav}></ViewInvoices>
      </DynamicTabLayout>
    </Box>
  );
};
CompanyDetails.propTypes = {
  updateField: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  onTabChange: PropTypes.func,
  isHeaderNav: PropTypes.string,
  detailsData: PropTypes.object,
  contactData: PropTypes.object,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func,
  getValues: PropTypes.func,
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  defaultEngagementTab: PropTypes.string
};

export default CompanyDetails;
