import { enqueueSnackbar } from 'notistack';
import { downloadHeliaReports } from '../../../services/ApiService';
import { successMessage } from '../../../services/MessageService';

const DownloadAthenaReport = async (contact_id, project_id, language) => {
  try {
    const report = await GenerateAthenaReport(contact_id, project_id, language);
    if (report.status == '200') {
      const pdfBlob = new Blob([report.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'Athena_Report.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar(successMessage('Downloaded', 'Report'));
    }
  } catch (err) {
    enqueueSnackbar(err);
  }
};

const GenerateAthenaReport = async (contact_id, project_id, language) => {
  try {
    const payload = {
      contact_id,
      project_id,
      language,
      report_code: 'IGN_ATHENA',
      file_type: 'pdf'
    };
    const res = await downloadHeliaReports(payload);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export { DownloadAthenaReport, GenerateAthenaReport };
