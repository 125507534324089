//----------------------------------------------// In-built Imports // -------------------------------------------------
import * as React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import Box from '@mui/material/Box';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import PropTypes from 'prop-types';
import '../index.scss';
import ActivityHistoryDetail from './ActivityHistoryDetail';

const ActivityHistory = React.memo(props => {
  return (
    <Box id='ActivityHistory_container'>
      <ActivityHistoryDetail
        activityDetails={props.activityDetails}
        isHeaderNav={props.isHeaderNav}
        setActivityComponent={props.setActivityComponent}
        getActivityDetails={props.getActivityDetails}
        activityState={props.activityState}
      />
    </Box>
  );
});

ActivityHistory.propTypes = {
  isHeaderNav: PropTypes.string,
  activityDetails: PropTypes.object,
  setActivityComponent: PropTypes.func,
  getActivityDetails: PropTypes.func,
  activityState: PropTypes.string
};

export default ActivityHistory;
