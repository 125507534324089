import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';
import { addHttps } from '../../utils/string';
import { LinksRenderer, getFilterQuery } from '../Commons/Utils';
import NamePopover from '../Contacts/NamePopover';
import CustomHeader from './components/CustomHeader';
const columnDefs = () => {
  let columns = [
    // {
    //   headerName: '',
    //   field: 'check_box',
    //   colId: 'check_box',
    //   sortable: false,
    //   filter: false,
    //   maxWidth: 60,
    //   minWidth: 60,
    //   headerComponent: 'HeaderCheckbox',
    //   headerCheckboxSelectionFilteredOnly: false,
    //   checkboxSelection: true,
    //   pinned: 'left',
    //   lockPinned: true,
    //   lockPosition: true,
    //   visible: true,
    //   suppressColumnsToolPanel: true
    // },
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: 'NameRenderer',
      colId: 'name',
      maxWidth: 400,
      minWidth: 250,
      resizable: true,
      sortable: true,
      pinned: 'left',
      sortingOrder: ['asc', 'desc', null],
      headerComponentFramework: CustomHeader,
      filter: 'agTextColumnFilter',
      visible: true,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'location',
      headerName: 'Location',
      colId: 'location_description',
      maxWidth: 250,
      minWidth: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'LocationRenderer',
      cellClass: 'industry-content',
      headerComponentFramework: CustomHeader,
      filter: 'agTextColumnFilter',
      visible: true,
      filterParams: {
        maxNumConditions: 1
      },
      valueGetter: params => {
        if (!params?.data?.location_description) {
          return '';
        }
        return params?.data?.location_description;
      }
    },
    {
      field: 'company_status',
      colId: 'company_status',
      headerName: 'Company Status',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      filter: 'agTextColumnFilter',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'industry',
      colId: 'industries',
      headerName: 'Industry',
      maxWidth: 250,
      cellClass: 'industry-content',
      minWidth: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'IndustriesRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      visible: true,
      valueGetter: params => {
        const directIndustries = params?.data?.industries;
        if (directIndustries && Array.isArray(directIndustries)) {
          return directIndustries.slice(0, 2).join(' ');
        }
        const companyIndustries = params?.data?.company_industries;
        if (companyIndustries && companyIndustries.length) {
          const industryNames = companyIndustries.map(item => item?.industries?.name).slice(0, 2);
          return industryNames.join(' ');
        }
        return '';
      }
    },
    {
      field: 'revenue',
      colId: 'revenue_range',
      headerName: 'Revenue Range',
      maxWidth: 300,
      minWidth: 300,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'RevenueRange',
      headerComponentFramework: CustomHeader,
      filter: 'agTextColumnFilter',
      visible: true,
      filterParams: {
        maxNumConditions: 1
      },
      valueGetter: params => {
        if (!params?.data?.revenue_range_to) {
          return '';
        }
        return params?.data?.revenue_range_to;
      }
    },
    {
      field: 'employee',
      colId: 'employee_range',
      headerName: 'Employee Range',
      maxWidth: 300,
      minWidth: 300,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'EmployeeRangeRenderer',
      headerComponentFramework: CustomHeader,
      filter: 'agTextColumnFilter',
      visible: true,
      filterParams: {
        maxNumConditions: 1
      },
      valueGetter: params => {
        if (!params?.data?.employee_range) {
          return '';
        }
        return params?.data?.employee_range;
      }
    },
    {
      field: 'capital',
      colId: 'capital_structure',
      headerName: ' Capital Structure',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'CapitalStructureRenderer',
      filter: 'agTextColumnFilter',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      },
      valueGetter: params => {
        if (!params?.data?.capital_structure) {
          return '';
        }
        return params?.data?.capital_structure;
      }
    },
    {
      field: 'owner',
      colId: 'created_user.first_name',
      headerName: 'Owner',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'OwnerRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      valueGetter: params => {
        if (!params?.data?.created_user?.name) {
          return '';
        }
        return params?.data?.created_user?.name;
      }
    },
    {
      field: 'tags',
      colId: 'company_tags',
      headerName: 'Tags',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      hide: false,
      suppressToolPanel: true,
      cellRenderer: 'TagRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      visible: true,
      valueGetter: params => {
        if (!params?.data?.company_tags) {
          return '';
        }
        const primaryTag = params?.data?.company_tags?.map(({ ign_tag }) => ign_tag?.name);
        return primaryTag.length ? primaryTag.join(', ') : '';
      }
    },
    {
      field: 'comments',
      colId: 'comments',
      headerName: 'Comments',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      suppressToolPanel: true,
      cellRenderer: 'CommentRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      visible: true,
      valueGetter: params => {
        if (!params?.data?.comments) return '';
        const temp = document.createElement('div');
        temp.innerHTML = params?.data?.comments;
        return temp?.textContent || temp?.innerText || '';
      }
    },
    {
      field: 'phone',
      colId: 'company_phones',
      headerName: 'Phone',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'PhoneRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      suppressColumnsToolPanel: false,
      getQuickFilterText: params => {
        console.log('Quick filter params:', params);
        const primaryPhone = params.data?.company_phones?.find(phoneObj => phoneObj.is_primary === true);
        return primaryPhone?.phone_number || '';
      },
      valueFormatter: params => {
        console.log('Value formatter params:', params);
        const primaryPhone = params.data?.company_phones?.find(phoneObj => phoneObj.is_primary === true);
        return primaryPhone?.phone_number || '';
      },
      valueGetter: params => {
        if (!params?.data?.company_phones) {
          return '';
        }
        const primaryPhone = params?.data?.company_phones?.find(phoneObj => phoneObj?.is_primary === true);
        return primaryPhone?.phone_number || '';
      }
    },
    {
      field: 'email',
      colId: 'company_emails',
      headerName: 'Email',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'EmailRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      suppressColumnsToolPanel: false,
      getQuickFilterText: params => {
        console.log('Quick filter params:', params);
        const primaryEmail = params.data?.company_emails?.find(emailObj => emailObj.is_primary === true);
        return primaryEmail?.email || '';
      },
      valueFormatter: params => {
        console.log('Value formatter params:', params);
        const primaryEmail = params.data?.company_emails?.find(emailObj => emailObj.is_primary === true);
        return primaryEmail?.email || '';
      },
      valueGetter: params => {
        if (!params?.data?.company_emails) {
          return '';
        }
        const primaryEmail = params?.data?.company_emails?.find(emailObj => emailObj?.is_primary === true);
        return primaryEmail?.email || '';
      }
    },
    {
      field: 'address',
      colId: 'company_address',
      headerName: 'Address',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'AddressRenderer',
      headerComponentFramework: CustomHeader,
      filter: false,
      valueGetter: params => {
        if (!params?.data?.location_description) {
          return '';
        }
        return params?.data?.location_description;
      }
    },
    {
      field: 'website_url',
      colId: 'website_url',
      headerName: 'Website URL',
      maxWidth: 350,
      minWidth: 350,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'WebsiteUrlRenderer',
      headerComponentFramework: CustomHeader,
      filter: 'agTextColumnFilter',
      visible: true,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'linkedin_url',
      colId: 'linkedin_url',
      headerName: 'LinkedIn URL',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      filter: 'agTextColumnFilter',
      cellRenderer: 'WebsiteUrlRenderer',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'currency_unit',
      colId: 'currency_unit',
      headerName: 'Currency Unit',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      filter: 'agTextColumnFilter',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'currency_code',
      colId: 'currency_code',
      headerName: 'Currency Code',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      filter: 'agTextColumnFilter',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'created_at',
      colId: 'created_at',
      headerName: 'Created At',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'DateRenderer',
      filter: 'agTextColumnFilter',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'updated_at',
      colId: 'updated_at',
      headerName: 'Updated At',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      cellRenderer: 'DateRenderer',
      filter: 'agTextColumnFilter',
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      }
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      filter: false,
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      },
      cellRenderer: params => params?.data?.created_user?.name,
      valueGetter: params => {
        if (!params?.data?.created_user?.name) return '';
        const created_by = params?.data?.created_user?.name;
        return created_by;
      }
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      maxWidth: 300,
      minWidth: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: false,
      visible: false,
      suppressToolPanel: true,
      filter: false,
      headerComponentFramework: CustomHeader,
      filterParams: {
        maxNumConditions: 1
      },
      cellRenderer: params => params?.data?.created_user?.name,
      valueGetter: params => {
        if (!params?.data?.created_user?.name) return '';
        const created_by = params?.data?.created_user?.name;
        return created_by;
      }
    }
  ];
  columns.push({
    headerName: '',
    field: 'action',
    colId: 'action',
    sortable: false,
    maxWidth: 80,
    minWidth: 80,
    cellRenderer: 'GetActionRenderer',
    filter: false,
    floatingFilter: false,
    pinned: 'right',
    lockPinned: true,
    suppressColumnsToolPanel: true, //for not displaying column in column selector
    suppressHeaderMenuButton: true //for disabling there menu dots on column header
  });

  return columns;
};
export { columnDefs };
export const createSchema = yup.object().shape({
  name: yup.string().required()
});
export const createRequiredField = [{ fieldName: 'name', label: 'Company Name', type: String }];
export const defaultColumns = ['name', 'freeze_dropdown'];
export const WebsiteRenderer = params => {
  const color = params.data?.off_limits || (params.data?.search && params.data?.search?.length > 0) ? 'red' : 'primary';
  return LinksRenderer(params, addHttps(params.value), '_blank', color);
};
export const CompanyNameRenderer = props => {
  const { company, hidePopover } = props;
  if (company) {
    if (hidePopover === true) {
      return <>{company.name || ''}</>;
    }
    return (
      <NamePopover company={company}>
        <Link to={`/companies/${company?.id}/details`} target='_blank' rel='noopener noreferrer'>
          <Typography className='text-link' color={company.off_limits ? 'error' : 'primary'}>
            {company.name || ''}
          </Typography>
        </Link>
      </NamePopover>
    );
  }
  return '';
};
export const getFilterParamString = (data, prefix) => {
  const queryAliases = { industries: 'industry' };
  const queryValueTypes = {
    country: { field: 'name' },
    industry: { field: 'name' }
  };
  return getFilterQuery(data, queryAliases, queryValueTypes, prefix);
};

export const getQueryParamsString = data => {
  let paramsString = '';
  if (data.currentCompany) {
    data.currentCompany.forEach(data => {
      paramsString = paramsString.concat(`&id=${data.id}`);
    });
  }

  if (data.country) {
    data.country.forEach(name => {
      paramsString = paramsString.concat(`&country=${name}`);
    });
  }
  if (data.fromDate) {
    paramsString = paramsString.concat(`&created_at_from=${data.fromDate}`);
  }
  if (data.toDate) {
    paramsString = paramsString.concat(`&created_at_to=${data.toDate}`);
  }
  if (data.industries) {
    data.industries.map(industryId => {
      if (industryId.includes('&')) {
        industryId = industryId.replace('&', '%26');
      }
      paramsString = paramsString.concat(`&industry=${industryId}`);
      return paramsString;
    });
  }
  if (data.vcPe) {
    data.vcPe.forEach(vcPe => {
      paramsString = paramsString.concat(`&vc_pe=${vcPe.id}`);
    });
  }
  if (data.revenueFrom) {
    paramsString = paramsString.concat(`&revenue_from=${data.revenueFrom}`);
  }
  if (data.revenueTo) {
    paramsString = paramsString.concat(`&revenue_to=${data.revenueTo}`);
  }
  return paramsString;
};

CompanyNameRenderer.propTypes = {
  params: PropTypes.object,
  company: PropTypes.object,
  hidePopover: PropTypes.bool
};
