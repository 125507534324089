import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Container, Switch, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
import { capitalize, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { deleteStageAction, deleteStageTransition, updateStageAction } from '../../api';
import '../../index.scss';
import DeleteTransitionPopup from './DeleteTransitionPopup';

function calculateTableHeight(length) {
  const baseHeight = 100;
  const additionalHeight = 42 * 2;

  if (length <= 1) {
    return baseHeight;
  } else {
    return baseHeight + (length - 1) * additionalHeight;
  }
}
const StageRenderer = ({ label, value }) => {
  return (
    <Box className='d-flex flex-row' alignItems='center'>
      <Typography color='primary'>{label}</Typography>
      <Box border='1px solid black' padding={0.5} px={2} ml={1} minWidth={150}>
        <Typography fontWeight='700' color='primary'>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

StageRenderer.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

const defaultColumnOptions = {
  resizable: true,
  flex: 1,
  cellStyle: params => {
    return { display: 'block !important', 'text-overflow': 'ellipsis', 'white-space': 'nowrap !important', overflow: 'hidden', background: params.data.action_disabled ? '#d8d8d8' : '#fff' };
  },
  tooltip: params => params.value,
  filter: false
};

const columnDefs = [
  {
    field: 'action_description',
    headerName: 'Description',
    valueFormatter: p => (!p.value ? '-' : p.value),
    ...defaultColumnOptions
  },
  { field: 'action_type', headerName: 'Type', ...defaultColumnOptions },
  { field: 'recipient_type', headerName: 'Recipient Type', ...defaultColumnOptions },
  { field: 'recipients_or_url', headerName: 'Recipients/URL', ...defaultColumnOptions, cellRenderer: 'recipientRenderer' },
  { field: 'template_name', headerName: 'Message Template/Payload', ...defaultColumnOptions, cellRenderer: 'templateRenderer' },
  {
    field: 'action',
    headerName: 'Actions',
    colId: 'action',
    ...defaultColumnOptions,
    cellRenderer: 'getActionRenderer',
    width: 150,
    maxWidth: 150,
    minWidth: 100,
    pinned: 'right',
    lockPinned: true,
    suppressHeaderMenuButton: true
  }
];

const RecipientRenderer = params => {
  if (params.data.recipient_type === 'CANDIDATE') {
    return <p>Project Candidate</p>;
  }
  if (params.data?.formattedRecipients) {
    let recipients = '-';
    if (!isEmpty(params.data?.formattedRecipients)) {
      recipients = params.data?.formattedRecipients
        .slice(0)
        .map(recipient => `${capitalize(recipient?.first_name)} ${capitalize(recipient?.last_name)}`)
        .join(', ');
    }
    return (
      <div className='description-tooltip'>
        <Tooltip placement='right' arrow title={recipients}>
          <p>{recipients}</p>
        </Tooltip>
      </div>
    );
  }
  if (params.data.recipient_type === 'PROJECT_TEAM' || params.data.recipient_type === 'CLIENT_TEAM') {
    return <p>{capitalize(params.value)}</p>;
  }
  return (
    <div className='description-tooltip'>
      <Tooltip placement='right' arrow title={params.value}>
        <p>{params.value}</p>
      </Tooltip>
    </div>
  );
};

const TemplateRenderer = params => {
  if (params.data.action_type === 'WEBHOOK') {
    return (
      <div className='description-tooltip'>
        <Tooltip placement='right' arrow title={params.data.payload}>
          <p>{params.data.payload}</p>
        </Tooltip>
      </div>
    );
  }
  return <p>{params.value}</p>;
};

const WorkflowConfig = ({ workflow, refetch, setEditWorkFlowId }) => {
  const gridRef = useRef(null);
  const [workflowAction, setActions] = useState(workflow.workflowActions);
  const [deleteTransitionOpen, setDeleteTransitionOpen] = useState(false);
  const [deleteActionId, setDeleteActionId] = useState(null);
  const [toggleActionData, setToggleActionData] = useState(null);
  const [popupLoading, setPopupLoading] = useState(false);

  const handlePopupClose = () => {
    setDeleteTransitionOpen(false);
    setTimeout(() => {
      setDeleteActionId(null);
      setToggleActionData(null);
    }, 300);
  };
  const handleDeleteTransition = async actionId => {
    setPopupLoading(true);
    if (actionId) {
      await deleteStageAction(actionId);
      setActions(prevState => prevState.filter(action => action.id !== actionId));
    } else {
      await deleteStageTransition(workflow.id);
    }
    setPopupLoading(false);
    refetch(true);
    setDeleteTransitionOpen(false);
  };

  const handleToggleTransition = async ({ actionId, action_disabled }) => {
    setPopupLoading(true);
    await updateStageAction(actionId, { action_disabled });
    setPopupLoading(false);
    refetch(true);
    setDeleteTransitionOpen(false);
  };

  const toggleAction = (actionId, action_disabled) => {
    setToggleActionData({ actionId, action_disabled });
    setTimeout(() => {
      setDeleteTransitionOpen(true);
    }, 0);
  };
  const handleDeleteAction = actionId => {
    setDeleteActionId(actionId);
    setTimeout(() => {
      setDeleteTransitionOpen(true);
    }, 0);
  };

  useEffect(() => {
    if (workflow.workflowActions.length === 0) {
      gridRef.current?.api?.showNoRowsOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
      setTimeout(() => {
        gridRef.current?.api?.sizeColumnsToFit();
      }, 300);
    }
  }, [workflow.workflowActions]);

  const ActionRenderer = params => {
    const actionId = params.data.id;
    return (
      <>
        <Tooltip placement='right' arrow title={params.data.action_disabled ? 'Activate' : 'Deactivate'}>
          <Switch
            checked={!params.data.action_disabled}
            onChange={e => {
              toggleAction(actionId, !e.target.checked);
            }}
            color='primary'
          />
        </Tooltip>
        <IconButton aria-label='delete' onClick={() => handleDeleteAction(actionId)} size='small'>
          <DeleteIcon color='error' fontSize='inherit' />
        </IconButton>
      </>
    );
  };

  return (
    <Box display='flex' flexDirection='column' flex={1} paddingY={2} paddingX={5} border='1px solid grey'>
      <Container disableGutters className='d-flex flex-column' style={{ flex: 1 }}>
        <Box display='flex' flexDirection='row' flex={1} alignItems='center'>
          <Typography color='primary'>
            Name:&nbsp;&nbsp;<strong>{workflow.setup_name}</strong>
          </Typography>
          <Container disableGutters className='d-flex flex-row justify-content-space-evenly align-item-center' style={{ flex: 0.95, margin: '0 0 10px' }}>
            <StageRenderer label='From Stage' value={workflow.from_stage_code || 'Any'} />
            <StageRenderer label='To Stage' value={workflow.to_stage_code} />
          </Container>
          <Box display='flex' flex={0.05} justifyContent='flex-end'>
            <IconButton onClick={() => setEditWorkFlowId(workflow.id)} aria-label='edit' size='small'>
              <EditIcon color='info' fontSize='inherit' />
            </IconButton>
            <IconButton aria-label='delete' onClick={() => setDeleteTransitionOpen(true)} size='small'>
              <DeleteIcon color='error' fontSize='inherit' />
            </IconButton>
          </Box>
        </Box>
        <div className='ag-theme-alpine text-center over-flow-hide stage-workflow' style={{ height: calculateTableHeight(workflowAction.length) }}>
          <AgGridWrapper
            ref={gridRef}
            enableTextSelection
            pagination={false}
            columnDefs={columnDefs}
            showSidebar={false}
            disableStaticMarkup
            rowData={workflowAction}
            overlayNoRowsTemplate={'<div style="margin-top: 40px;">No Actions defined!</div>'}
            aggregateOnlyChangedColumns
            components={{
              recipientRenderer: RecipientRenderer,
              templateRenderer: TemplateRenderer,
              getActionRenderer: ActionRenderer
            }}
          />
        </div>
      </Container>
      <DeleteTransitionPopup
        isLoading={popupLoading}
        deleteActionId={deleteActionId}
        toggleActionData={toggleActionData}
        onDelete={handleDeleteTransition}
        onToggleConfirm={handleToggleTransition}
        isPopupOpen={deleteTransitionOpen}
        handlePopupClose={handlePopupClose}
      />
    </Box>
  );
};

WorkflowConfig.propTypes = {
  workflow: PropTypes.object,
  refetch: PropTypes.func,
  setEditWorkFlowId: PropTypes.func
};

export default WorkflowConfig;
