import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import ProjectTypeSelection from '../../../../components/common/FunctionalComponents/ProjectTypeSelection';

const TranscriptHeaderInputs = props => {
  const { t } = useTranslation();
  const {
    projectMandatory = false,
    project = null,
    setProject = () => {},
    listOfProjectCandidates = [],
    projectCandidate = null,
    setProjectCandidate = () => {},
    isFromLogAnActivity = false,
    listOfProject = []
  } = props;
  return (
    <Box className='col-6 row'>
      {!isFromLogAnActivity && (
        <Box className='col-6 px-2'>
          <Box className='bold my-2 fs-14'>{t('module.project')}</Box>
          <Box>
            <ProjectTypeSelection
              required={projectMandatory}
              project_id={project?.id}
              defaultValue={project}
              label={t('project.projectNumber')}
              onChange={(e, data) => {
                setProject(data);
              }}
              className={'w-100 autocomplete-outside'}
            />
          </Box>
        </Box>
      )}
      <Box className='col-6 px-2'>
        <Box className='bold my-2 fs-14'>Candidate</Box>
        <Box>
          <CustomMuiDropdown
            disabled={listOfProjectCandidates.length == 0}
            size='small'
            options={listOfProjectCandidates}
            value={projectCandidate}
            getOptionLabel={option => option.full_name}
            onChange={(e, data) => {
              setProjectCandidate(data);
            }}
          />
        </Box>
      </Box>
      {isFromLogAnActivity && (
        <Box className='col-6 px-2'>
          <Box className='bold my-2 fs-14'>Linked {t('module.project')}</Box>
          <Box>
            <CustomMuiDropdown
              disabled={listOfProject.length == 0}
              size='small'
              options={listOfProject}
              value={project}
              getOptionLabel={option => option.name}
              onChange={(e, data) => {
                setProject(data);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

TranscriptHeaderInputs.propTypes = {
  projectMandatory: PropTypes.bool,
  project: PropTypes.object,
  setProject: PropTypes.func,
  listOfProjectCandidates: PropTypes.array,
  projectCandidate: PropTypes.object,
  setProjectCandidate: PropTypes.func,
  isFromLogAnActivity: PropTypes.bool,
  listOfProject: PropTypes.array
};

export default TranscriptHeaderInputs;
