import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomPopup from '../../../components/common/CustomPopup';
import '../../ProductSetup/index.scss';
import TagForm from './TagForm';

const AddTag = props => {
  const { setIsPopupOpen, isPopupOpen, saveData, setValue, register, unregister, watch } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <CustomPopup dropdown={true} title={`${t('actions.add')} Tags`} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={saveData}>
      <TagForm watch={watch} unregister={unregister} register={register} setValue={setValue}></TagForm>
    </CustomPopup>
  );
};
AddTag.propTypes = {
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  saveData: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func
};

export default AddTag;
