//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Suspense, useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { ErrorBoundary } from '@sentry/react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Header from './components/Header';
import SideNav from './components/SideNav';
// import CompaniesList from "./Containers/Companies/List";
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import ClientSuiteDashboard from './Containers/ClientSuite/ClientSuite';
import Navbar from './Containers/ClientSuite/components/Navbar/Navbar';
import TokenValidator from './Containers/Commons/TokenValidator';
import { getSystemParams } from './Containers/Commons/Utils';
import CompaniesList from './Containers/Companies/Container';
import ContactsList from './Containers/Contacts/Container';
import Home from './Containers/Home/Home';
import InvoiceSummary from './Containers/InvoiceSummary/List';
import JobBoard from './Containers/JobBoard/JobBoard';
import MessageTemplates from './Containers/ProductSetup/MessageTemplates/MessageTemplates';
import SetupPage from './Containers/ProductSetup/SetupPage';
import TagSetup from './Containers/ProductSetup/TagSetup';
import AddProject from './Containers/Searches/AddProject/AddProject';
import MySearchPage from './Containers/Searches/MySearchPage';
import PipeLineSearches from './Containers/Searches/PipeLineSearches';
import ViewProject from './Containers/Searches/ViewProject/ViewProject';
import ResourcesSecurity from './Containers/Security/Resources';
import RolesSecurity from './Containers/Security/Roles';
import SecurityPage from './Containers/Security/SecurityPage';
import UserSecurity from './Containers/Security/Users';
// import RolesSecurity from "./Containers/Security/Roles";
// import ResourcesSecurity from "./Containers/Security/Resources";
// import Unauthorized from "./Containers/Commons/Unauthorized";
import AthenaWrap from './Containers/Athena/Athena';
import IgnyteIntelligence from './Containers/IgnyteIntelligence';
import BilledInvoice from './Containers/InvoiceSummary/BilledInvoice';
import NextTenDays from './Containers/InvoiceSummary/NextTenDays';
import Overdue from './Containers/InvoiceSummary/Overdue';
import ReadyToBilled from './Containers/InvoiceSummary/ReadyToBilled';
import YetToBeBilled from './Containers/InvoiceSummary/YetToBeBilled';
import Privacy from './Containers/PrivacyPage/Privacy';
import ActivityTypesSetup from './Containers/ProductSetup/ActivityTypes';
import CandidateProjectStatusSetup from './Containers/ProductSetup/CandidateProjectStatusSetup';
import LabelConfig from './Containers/ProductSetup/LabelConfig';
import ReportTemplateSetup from './Containers/ProductSetup/ReportTemplateSetup';
import Stage from './Containers/ProductSetup/Stage';
import TranslateValueSetup from './Containers/ProductSetup/TranslateValueSetup/index';
import ProfilePage from './Containers/Profile/ProfilePage';
import ReportPage from './Containers/Reports/ReportPage';
import TranslateValues from './Containers/TranslateValues/List';
import WorkbenchContainer from './Containers/Workbench/WorkbenchContainer';
import Workbenches from './Containers/Workbenches/List';
import Fallback from './Fallback';
import AddBD from './components/AddBD';
import AddContact from './components/AddContact';
import AddWorkbench from './components/AddWorkbench';
import CreateBD from './components/CreateBD';
import { GlobalSearch } from './components/GlobalSearch';
import Login from './components/Login';
import MasterSearch from './components/MasterSearch';
import ViewContact from './components/ViewContact';
import Loader from './components/common/Loader';
import { getUserSelector } from './selectors';
import { PUBLIC_BASE_ROUTES, PUBLIC_SUB_ROUTES, ROUTES, ROUTE_KEY, ROUTE_TYPE } from './services/constantService';
// import { UseUserSecurityHook } from "./utils/Hooks/UseUserSecurityHook";

function Routers() {
  /* UseUserSecurityHook(); */

  const userData = useSelector(getUserSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showChatBotInSidebar, setShowChatBotInSidebar] = useState(false);

  useEffect(() => {
    const fetchSystemParams = async () => {
      const body = { key: 'IGNYTE_CHATBOT' };
      const params = await getSystemParams(body);
      if (params?.param_value_1000 === 'true') {
        setShowChatBotInSidebar(true);
      } else {
        setShowChatBotInSidebar(false);
      }
    };
    if (userData) {
      fetchSystemParams();
    }
  }, [userData]);

  const withErrorBoundary = (props, key = String(Date.now())) => {
    return (
      <ErrorBoundary key={key} fallback={Fallback}>
        {props}
      </ErrorBoundary>
    );
  };

  const IgnyteApp = () => {
    return (
      <>
        <Header />
        <div className='content-wrapper d-flex w-100'>
          <SideNav showChatBot={showChatBotInSidebar} />
          <div className='main-content'>
            <Outlet />
          </div>
        </div>
      </>
    );
  };

  const ClientSuite = () => {
    return (
      <>
        <Navbar />
        <div className='content-wrapper d-flex w-100'>
          {/* <SideNav clientSuite={true} /> */}
          <div className='main-content'>
            <Outlet />
          </div>
        </div>
      </>
    );
  };

  const JobPortalWrap = () => {
    return (
      <>
        <div className='content-wrapper2 d-flex w-100'>
          <div className='main-content'>
            <Outlet />
          </div>
        </div>
      </>
    );
  };

  if (!userData) {
    return (
      <div>
        <Loader show={true} />
      </div>
    );
  }

  return (
    <div className='main-wrapper d-flex flex-column h-100'>
      <Routes>
        <Route
          element={withErrorBoundary(
            <Suspense fallback={<Loader show={true} />}>
              <IgnyteApp />
            </Suspense>
          )}
        >
          <Route path={ROUTES.login} element={withErrorBoundary(<Login />, ROUTE_KEY.login)} />
          <Route path={ROUTES.masterSearch} element={withErrorBoundary(<MasterSearch />, ROUTE_KEY.masterSearch)} />
          <Route path={`${ROUTES.globalSearch}/:selectedTabId`} element={withErrorBoundary(<GlobalSearch />, ROUTE_KEY.globalSearch)} />
          <Route path={`${ROUTES.globalSearch}/:selectedTabId/:search`} element={withErrorBoundary(<GlobalSearch />, ROUTE_KEY.globalSearch)} />
          <Route path={ROUTES.profile} element={withErrorBoundary(<ProfilePage />, ROUTE_KEY.globalSearch)} />
          <Route path={ROUTES.addCompany} errorElement={<Fallback />} element={withErrorBoundary(<CompaniesList />, ROUTE_KEY.companiesList)} />
          <Route path={ROUTES.viewCompany} errorElement={<Fallback />} element={withErrorBoundary(<CompaniesList />, ROUTE_KEY.companiesList)} />
          <Route path={ROUTES.companiesList} element={withErrorBoundary(<CompaniesList />, ROUTE_KEY.companiesList)} />
          <Route path={ROUTES.companies} element={<Navigate to={ROUTES.companiesList} />} />
          {/* <Route path={ROUTES.createSearch} element={withErrorBoundary(<CreateSearch />, ROUTE_KEY.createSearch)} /> */}
          {/* <Route path={ROUTES.createSearchWithoutCurrentTab} element={withErrorBoundary(<CreateSearch />, ROUTE_KEY.createSearch)} /> */}
          <Route path={ROUTES.pSearches} element={withErrorBoundary(<PipeLineSearches />, ROUTE_KEY.pSearches)} />

          <Route path={ROUTES.mySearchesKey} element={withErrorBoundary(<MySearchPage />, ROUTE_KEY.mySearches)} />

          <Route path={ROUTES.mySearches} element={withErrorBoundary(<MySearchPage />, ROUTE_KEY.mySearches)} />

          <Route path={ROUTES.addProject} element={withErrorBoundary(<AddProject />, ROUTE_KEY.addProject)} />

          {/* <Route path={ROUTES.cloneProject} element={withErrorBoundary(<CloneProject />, ROUTE_KEY.cloneProject)} /> */}

          <Route path={ROUTES.vProject} element={withErrorBoundary(<ViewProject />, ROUTE_KEY.vProject)} />
          <Route path={ROUTES.viewProjectWithContactId} element={withErrorBoundary(<ViewProject />, ROUTE_KEY.viewProjectWithContactId)} />

          {/* <Route
          path={ROUTES.viewProjectWithContactIdWithoutChild}
          element={withErrorBoundary(<ViewProject />, ROUTE_KEY.viewProjectWithContactIdWithoutChild)}
        /> */}

          <Route path={ROUTES.viewProject} element={withErrorBoundary(<ViewProject />, ROUTE_KEY.viewProject)} />
          <Route path={ROUTES.viewProjectWithDeepLink} element={withErrorBoundary(<ViewProject />, ROUTE_KEY.viewProjectWithDeepLink)} />

          <Route path={ROUTES.viewProjectWithoutChild} element={withErrorBoundary(<ViewProject />, ROUTE_KEY.viewProjectWithoutChild)} />

          <Route path={ROUTES.validateToken} element={<TokenValidator />} />

          {/* <Route path={ROUTES.kgpSearches} element={withErrorBoundary(<KGPSearchesList />, ROUTE_KEY.mySearches)} /> */}

          {/* <Route path={ROUTES.myPlacements} element={withErrorBoundary(<MyPlacements />, ROUTE_KEY.myPlacements)} /> */}

          {/* <Route path={ROUTES.kgpPlacements} element={withErrorBoundary(<KGPPlacements />, ROUTE_KEY.kgpPlacements)} /> */}

          {/* <Route path={ROUTES.billedInvoice} element={withErrorBoundary(<BilledInvoice />, ROUTE_KEY.billedInvoice)} /> */}

          {/* <Route path={ROUTES.yetToBeBilled} element={withErrorBoundary(<YetToBeBilled />, ROUTE_KEY.yetToBeBilled)} /> */}

          {/* <Route path={ROUTES.nextTenDays} element={withErrorBoundary(<NextTenDays />, ROUTE_KEY.nextTenDays)} /> */}

          {/* <Route path={ROUTES.overdue} element={withErrorBoundary(<Overdue />, ROUTE_KEY.overdue)} /> */}

          <Route path={ROUTES.addBd} element={withErrorBoundary(<AddBD />, ROUTE_KEY.addBd)} />
          <Route path={ROUTES.addBdByCompany} element={withErrorBoundary(<CreateBD />, ROUTE_KEY.createBd)} />
          <Route path={ROUTES.createBd} element={withErrorBoundary(<CreateBD />, ROUTE_KEY.createBd)} />

          {/* <Route path={ROUTES.addTranslateValue} element={withErrorBoundary(<AddTranslateValue />, ROUTE_KEY.addTranslateValue)} /> */}

          {/* <Route path={ROUTES.addDocumentType} element={withErrorBoundary(<AddDocumentType />, ROUTE_KEY.addDocumentType)} /> */}

          {/* <Route path={ROUTES.readOnlyActivityLog} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyActivityLog)} /> */}

          {/* <Route path={ROUTES.readOnlyActivityLogWithoutCurrentTab} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyActivityLog)} /> */}

          {/* <Route path={ROUTES.activityLog} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.activityLog)} /> */}

          {/* <Route path={ROUTES.activityLogWithoutCurrentTab} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.activityLog)} /> */}

          {/* <Route path={ROUTES.viewSearchWithActivetab} element={withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)} /> */}

          {/* <Route path={ROUTES.viewSearch} element={withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)} /> */}

          {/* <Route path={ROUTES.viewSearchWithoutCurrentTab} element={withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)} /> */}

          <Route path={ROUTES.searches} element={<Navigate to={ROUTES.mySearches} />} />

          {/* <Route
          path={ROUTES.addCompany}
          element={withErrorBoundary(<AddCompany />, ROUTE_KEY.addCompany)}
        /> */}

          {/* <Route
          path={ROUTES.comMyClients}
          element={withErrorBoundary(<CompaniesMyClients />, ROUTE_KEY.comMyClients)}
        /> */}

          {/* <Route
          path={ROUTES.comMyTargets}
          element={withErrorBoundary(<CompaniesMyTargets />, ROUTE_KEY.comMyTargets)}
        /> */}

          {/* <Route
          path={ROUTES.viewCompany}
          element={withErrorBoundary(<ViewCompany />, ROUTE_KEY.viewCompany)}
        />

        <Route
          path={ROUTES.viewCompanyWithoutCurrentTab}
          element={withErrorBoundary(<ViewCompany />, ROUTE_KEY.viewCompany)}
        />

        {/* <Route path={ROUTES.companies} element={withErrorBoundary(<CompaniesList />, ROUTE_KEY.companies)} /> */}
          {/* <Route
          path={ROUTES.companies}
          element={withErrorBoundary(<InProgressPage />, ROUTE_KEY.companies)}
        /> */}

          <Route path={ROUTES.addContact} element={withErrorBoundary(<AddContact />, ROUTE_KEY.addContact)} />
          <Route path={ROUTES.addContactWithoutChild} element={withErrorBoundary(<AddContact />, ROUTE_KEY.addContactWithoutChild)} />

          <Route path={ROUTES.addContactWithoutCurrentTab} element={withErrorBoundary(<AddContact />, ROUTE_KEY.addContact)} />

          <Route path={ROUTES.contactListWithID} element={withErrorBoundary(<ContactsList enqueueSnackbar={enqueueSnackbar} closeSnackbar={closeSnackbar} />, ROUTE_KEY.contactList)} />

          <Route path={ROUTES.contactList} element={withErrorBoundary(<ContactsList enqueueSnackbar={enqueueSnackbar} closeSnackbar={closeSnackbar} />, ROUTE_KEY.contactList)} />

          {/* <Route path={ROUTES.conClients} element={withErrorBoundary(<MyClientsList />, ROUTE_KEY.conClients)} />

        <Route path={ROUTES.conMyTarget} element={withErrorBoundary(<MyBDTargetsList />, ROUTE_KEY.conMyTarget)} /> */}

          {/* Need to replace all unnecessary contact routes to new requirement routes  */}
          {/* <Route path={ROUTES.linkedinContacts} element={withErrorBoundary(<MyLinkedinContacts />, ROUTE_KEY.linkedinContacts)} />

        <Route path={ROUTES.readOnlyContactActivityLog} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyContactActivityLog)} />

        <Route path={ROUTES.contactActivityLog} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.contactActivityLog)} />

        <Route path={ROUTES.readOnlyContactActivityLogWithoutCurrentTab} element={withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyContactActivityLog)} />

        <Route path={ROUTES.contactActivityLogWithoutCurrentTab} element={withErrorBoundary(<activityLog />, ROUTE_KEY.contactActivityLog)} /> */}

          {/* <Route path={ROUTES.viewContact} element={withErrorBoundary(<ViewContact />, ROUTE_KEY.viewContact)} /> */}

          <Route path={ROUTES.viewContactWithoutCurrentTab} element={withErrorBoundary(<ViewContact />, ROUTE_KEY.viewContact)} />

          <Route path={ROUTES.contactListWithoutTab} errorElement={<Fallback />} element={<Navigate to={ROUTES.contactListWithoutCurrentTab} />} />

          {/* {isAdmin && <Route path={ROUTES.userAdd} element={withErrorBoundary(<AddUser />, ROUTE_KEY.userAdd)} />}
        {isAdmin && <Route path={ROUTES.users} element={withErrorBoundary(<UsersList />, ROUTE_KEY.users)} />}
        {isAdmin && <Route path={ROUTES.usersWithId} element={withErrorBoundary(<UsersList />, ROUTE_KEY.users)} />} */}
          <Route path={ROUTES.contactView} errorElement={<Fallback />} element={<Navigate to={ROUTE_KEY.contactViewb} />} />
          {/* <Route path={ROUTES.legacyArByPartnerDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_AR_BY_PARTNER} />, ROUTE_KEY.legacyArByPartnerDashboard)} /> */}

          {/* <Route path={ROUTES.legacySalesByPartnerDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_SALES_BY_PARTNER} />, ROUTE_KEY.legacySalesByPartnerDashboard)} />

        <Route path={ROUTES.legacyCashReportDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_CASH_REPORT} />, ROUTE_KEY.legacyCashReportDashboard)} />

        <Route path={ROUTES.legacyRegionalSalesDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_REGIONAL_SALES} />, ROUTE_KEY.legacyRegionalSalesDashboard)} /> */}
          {/* 
        <Route path={ROUTES.arByPartnerDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.AR_BY_PARTNER} showReport={true} />, ROUTE_KEY.arByPartnerDashboard)} />

        <Route path={ROUTES.cashReportDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.CASH_REPORT} showReport={true} />, ROUTE_KEY.cashReportDashboard)} />

        <Route path={ROUTES.salesByPartnerDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.SALES_BY_PARTNER} showReport={true} />, ROUTE_KEY.salesByPartnerDashboard)} />

        <Route path={ROUTES.bDReportDashboard} element={withErrorBoundary(<Dashboard viewName={DASHBOARD.BD_REPORT} showReport={true} />, ROUTE_KEY.bdReportDashboard)} /> */}

          {/* <Route path={ROUTES.defaultRoute} errorElement={<Fallback />} element={<Navigate to={ROUTES.arByPartnerDashboard} />} /> */}
          {/* <Redirect to={ROUTES.arByPartnerDashboard} /> */}

          {/* <Route path={ROUTES.documentTypeProductSetup} element={withErrorBoundary(<DocumentTypes />, ROUTE_KEY.documentTypeProductSetup)} /> */}

          <Route path={ROUTES.productSetup} element={withErrorBoundary(<SetupPage />, ROUTE_KEY.productSetup)} />
          <Route path={ROUTES.reports} element={withErrorBoundary(<ReportPage />, ROUTE_KEY.reports)} />

          <Route path={ROUTES.tagSetup} element={withErrorBoundary(<TagSetup />, ROUTE_KEY.tagSetup)} />
          <Route path={ROUTES.stageSetup} element={withErrorBoundary(<Stage />, ROUTE_KEY.stageSetup)} />
          <Route path={ROUTES.security} element={withErrorBoundary(<SecurityPage />, ROUTE_KEY.security)} />
          <Route path={ROUTES.labelConfig} element={withErrorBoundary(<LabelConfig />, ROUTE_KEY.labelConfig)} />

          <Route path={ROUTES.userSecurity} element={withErrorBoundary(<UserSecurity />, ROUTE_KEY.userSecurity)} />

          <Route path={ROUTES.roleSecurity} element={withErrorBoundary(<RolesSecurity />, ROUTE_KEY.roleSecurity)} />

          <Route path={ROUTES.resourceSecurity} element={withErrorBoundary(<ResourcesSecurity />, ROUTE_KEY.resourceSecurity)} />

          <Route path={ROUTES.messageTemplates} element={withErrorBoundary(<MessageTemplates />, ROUTE_KEY.messageTemplates)} />

          <Route path={ROUTES.activityTypes} element={withErrorBoundary(<ActivityTypesSetup />, ROUTE_KEY.activityTypes)} />
          <Route path={ROUTES.activityTypesWithId} element={withErrorBoundary(<ActivityTypesSetup />, ROUTE_KEY.activityTypesWithId)} />

          <Route path={ROUTES.translateValueSetup} element={withErrorBoundary(<TranslateValueSetup />, ROUTE_KEY.translateValueSetup)} />

          <Route path={ROUTES.candidateProjectSetup} element={withErrorBoundary(<CandidateProjectStatusSetup />, ROUTE_KEY.translateValueSetup)} />
          <Route path={ROUTES.reportTemplateSetup} element={withErrorBoundary(<ReportTemplateSetup />, ROUTE_KEY.reportTemplateSetup)} />

          {/* {billingAccess && <Route path={ROUTES.searchApprovalPending} element={withErrorBoundary(<SearchApprovalPending />, ROUTE_KEY.searchApprovalPendidng)} />}
          {billingAccess && <Route path={ROUTES.searchApprovalRejected} element={withErrorBoundary(<SearchApprovalRejected />, ROUTE_KEY.searchApprovalRejected)} />} */}
          <Route path={ROUTES.invoiceSummary} element={withErrorBoundary(<InvoiceSummary />, ROUTE_KEY.invoiceSummary)} />
          <Route path={ROUTES.invoiceSummaryBilledInvoice} element={withErrorBoundary(<BilledInvoice />, ROUTE_KEY.invoiceSummaryBilledInvoice)} />
          <Route path={ROUTES.invoiceSummaryYetToBilled} element={withErrorBoundary(<YetToBeBilled />, ROUTE_KEY.invoiceSummaryYetToBilled)} />
          <Route path={ROUTES.invoiceSummaryOfNextTenDays} element={withErrorBoundary(<NextTenDays />, ROUTE_KEY.invoiceSummaryOfNextTenDays)} />
          <Route path={ROUTES.invoiceSummaryOverDue} element={withErrorBoundary(<Overdue />, ROUTE_KEY.invoiceSummaryOverDue)} />
          <Route path={ROUTES.invoiceSummaryReadyToBill} element={withErrorBoundary(<ReadyToBilled />, ROUTE_KEY.invoiceSummaryReadyToBill)} />

          {/* {isAdmin && <Route path={ROUTES.addSfpaBuilder} element={withErrorBoundary(<AddSfpaBuilder />, ROUTE_KEY.addSfpaBuilder)} />}
          {isAdmin && <Route path={ROUTES.sfpaBuilder} element={withErrorBoundary(<SfpaSearch />, ROUTE_KEY.SearchSfpaReport)} />} */}
          <Route path={ROUTES.translateValueProductSetup} element={withErrorBoundary(<TranslateValues />, ROUTE_KEY.translateValueProductSetup)} />

          <Route path={ROUTES.workbenchAdd} element={withErrorBoundary(<AddWorkbench />, ROUTE_KEY.workbenchAdd)} />

          <Route path={ROUTES.myWorkbenchesContacts} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.userContacts} />, ROUTE_KEY.myWorkbenchesContacts)} />
          {/* 
        <Route path={ROUTES.myWorkbenchesSearches} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.userSearches} />, ROUTE_KEY.myWorkbenchesSearches)} />

        <Route path={ROUTES.myWorkbenches} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.user} />, ROUTE_KEY.myWorkbenches)} />

        <Route path={ROUTES.kgpWorkbenchesContacts} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.kgpContacts} />, ROUTE_KEY.kgpWorkbenchesContacts)} />

        <Route path={ROUTES.kgpWorkbenchesSearches} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.kgpSearches} />, ROUTE_KEY.kgpWorkbenchesSearches)} />

        <Route path={ROUTES.kgpWorkbenches} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.kgp} />, ROUTE_KEY.kgpWorkbenches)} />

        <Route path={ROUTES.archivedWorkbenchesContacts} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.archivedContacts} />, ROUTE_KEY.archivedWorkbenchesContacts)} />

        <Route path={ROUTES.archivedWorkbenchesSearches} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.archivedSearches} />, ROUTE_KEY.archivedWorkbenchesSearches)} />

        <Route path={ROUTES.archivedWorkbenches} element={withErrorBoundary(<Workbenches type={ROUTE_TYPE.archived} />, ROUTE_KEY.archivedWorkbenches)} />

        <Route path={ROUTES.archivedWorkbenches} errorElement={<Fallback />} element={<Workbenches type={ROUTE_TYPE.archived} />} /> */}

          {/* <Route path={ROUTES.viewWorkbench} element={withErrorBoundary(<ViewWorkBench />, ROUTE_KEY.viewWorkbench)} />

        <Route path={ROUTES.viewWorkbench} errorElement={<Fallback />} element={<ViewWorkBench />} />

        <Route path={ROUTES.viewWorkbenchWithoutCurrentTab} element={withErrorBoundary(<ViewWorkBench />, ROUTE_KEY.viewWorkbench)} />

        <Route path={ROUTES.workbenches} errorElement={<Fallback />} element={<Navigate to={ROUTES.myWorkbenches} />} /> */}
          {/* <Redirect to={ROUTES.myWorkbenches} /> */}
          {/* 
        <Route path={ROUTES.allCandidates} element={withErrorBoundary(<ViewSearch />, ROUTE_KEY.allCandidates)} />

        <Route path={ROUTES.clientSuiteReport} element={withErrorBoundary(<ViewSearch />, ROUTE_KEY.clientSuiteReport)} />

        <Route path={ROUTES.myLinkedinCandidates} element={withErrorBoundary(<ViewSearch />, ROUTE_KEY.myLinkedinCandidates)} /> */}

          <Route path={ROUTES.remaining} errorElement={<Fallback />} element={<Navigate to={ROUTES.home} />} />
          <Route path={ROUTES.home} errorElement={<Fallback />} element={<Home />} />
          {/* <Redirect to={ROUTES.searches} /> */}
          <Route path={ROUTES.workbench} element={<WorkbenchContainer />} />
          <Route path={ROUTES.workbenchQuery} element={<WorkbenchContainer />} />
          <Route path={ROUTES.workbenchArchiveQuery} element={<WorkbenchContainer />} />
          <Route path={ROUTES.ignyteIntelligence} element={withErrorBoundary(<IgnyteIntelligence />, ROUTE_KEY.ignyteIntelligence)} />
        </Route>
        <Route
          element={withErrorBoundary(
            <Suspense fallback={<Loader show={true} />}>
              <ClientSuite />
            </Suspense>
          )}
        >
          <Route path={ROUTES.clientSuite} element={<ClientSuiteDashboard />} />
        </Route>

        <Route exact path={PUBLIC_SUB_ROUTES.athenaWithAssessmentId} element={<AthenaWrap />} />
        <Route exact path={PUBLIC_SUB_ROUTES.athenaWithName} element={<AthenaWrap />} />
        <Route element={<JobPortalWrap />}>
          <Route path={PUBLIC_BASE_ROUTES.jobBoard} element={<JobBoard />} />
          <Route path={PUBLIC_SUB_ROUTES.jobBoardWithId} element={<JobBoard />} />
        </Route>
        <Route path={PUBLIC_SUB_ROUTES.privacyPolicy} element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default Routers;
