import React from 'react';

const Pointer = props => {
  return (
    <svg width={16} height={59} viewBox='0 0 16 59' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.41 49.5c.044 5.021 3.541 9.06 7.809 9.022 4.268-.038 7.696-4.134 7.65-9.162C15.836 45.52 11.02 11.995 8.8 1.61a1.125 1.125 0 00-.389-.644 1.095 1.095 0 00-1.4.013c-.196.166-.33.395-.377.65C4.599 12.053.375 45.662.41 49.5zm3.72.903a4.13 4.13 0 01.658-2.274 4.036 4.036 0 011.792-1.52 3.963 3.963 0 012.32-.252 4 4 0 012.068 1.1c.567.566.956 1.29 1.118 2.08.162.791.09 1.613-.209 2.361a4.072 4.072 0 01-1.465 1.846 3.978 3.978 0 01-5.081-.463 4.083 4.083 0 01-.883-1.317 4.135 4.135 0 01-.318-1.56z'
        fill='#373636'
      />
      <circle cx={8} cy={50} r={3} fill='#373636' />
    </svg>
  );
};

export default Pointer;
