import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSelectionComponent from '../../../components/common/CustomSelectionComponent';
import { ignUsersApi, UsersDataApi } from '../../../services/ApiService';
import { DELETE, ERROR, POST, SUCCESS } from '../../../services/constantService';

const columnDefs = [
  {
    field: 'name',
    headerName: 'Name',
    cellRenderer: 'nameRenderer',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    // filter: false,
    floatingFilter: false,
    filter: 'agMultiColumnFilter'
  },
  // Using dot notation to access nested property
  {
    field: 'email',
    headerName: 'Email',
    width: 400,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    // filter: false,
    floatingFilter: false,
    filter: 'agMultiColumnFilter'
  },
  // Show default header name
  // {
  //   field: 'user_roles',
  //   headerName: 'Role',
  //   cellRenderer: 'roleRenderer',
  //   width: 500,
  //   resizable: true,
  //   sortable: false,
  //   filter: 'agMultiColumnFilter'
  // },

  {
    field: 'user_roles',
    headerName: 'Role',
    cellRenderer: 'roleRenderer',
    width: 450,
    resizable: true,
    sortable: false,
    // filter: false,
    floatingFilter: false,
    filter: 'agMultiColumnFilter',
    valueGetter: params => {
      return params?.data?.user_roles?.map(item => item?.roles?.name);
    }
  },
  {
    field: 'action',
    headerName: 'Actions',
    cellRenderer: 'manageRenderer',
    width: 150,
    resizable: false,
    sortable: false,
    pinned: 'right',
    lockPinned: true,
    sortingOrder: ['asc', 'desc', null],
    // filter: false,
    floatingFilter: false,
    filter: false,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true
  }
];

const NameRenderer = props => {
  return (
    <div
      className='py-2 text-left w-100 cursor-pointer'
      onClick={() => {
        props.setCurrentUser(props.data);
        props.setIsDrawerOpen(true);
      }}
    >
      {props.value}
    </div>
  );
};

const RoleRenderer = props => {
  return (
    <div className='py-2 w-100 text-center'>
      {props?.data?.user_roles?.splice(0, 3).map((role, idx) => {
        return idx ? <span key={idx}>, {role.roles.name}</span> : <span key={idx}>{role.roles.name}</span>;
      })}
    </div>
  );
};

const ManageRenderer = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  async function handleDelete() {
    await ignUsersApi(DELETE, `deleteById/${props.data.id}`);
    props.refetch();
  }
  const options = [
    { short_desc: 'Reset Password', field_value: 'reset_password' },
    { short_desc: 'Edit User', field_value: 'edit_user' },
    { short_desc: 'Delete User', field_value: 'delete_user' }
  ];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditUser = () => {
    props.setCurrentUser(props.data);
    props.setIsDrawerOpen(true);
  };

  const handleResetPassword = async () => {
    if (props?.data?.cognito_id) {
      const resetPassRes = await UsersDataApi(POST, null, 'admin-reset-password', { email: props.data.cognito_id });
      if (resetPassRes && resetPassRes.status === 200 && resetPassRes.data.$metadata.httpStatusCode === 200) {
        enqueueSnackbar('User password has been reset, please check your email.', { variant: SUCCESS });
      } else {
        enqueueSnackbar('Failed to reset password for user.', { variant: ERROR });
      }
    } else {
      enqueueSnackbar('Failed to reset password for user.', { variant: ERROR });
    }
  };

  const handleMenuItemClick = item => {
    if (item.field_value === 'reset_password') handleResetPassword();
    else if (item.field_value === 'edit_user') handleEditUser();
    else if (item.field_value === 'delete_user') handleDelete();
    setAnchorEl(null);
  };

  const customButtonForAllContact = <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />;

  return (
    <Box className='w-100 text-center'>
      <CustomSelectionComponent options={options} handleMenuItemClick={handleMenuItemClick} component={customButtonForAllContact} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
    </Box>
  );
};

NameRenderer.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  setCurrentUser: PropTypes.func
};

ManageRenderer.propTypes = {
  refetch: PropTypes.func,
  data: PropTypes.object,
  value: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  setCurrentUser: PropTypes.func
};

RoleRenderer.propTypes = {
  data: PropTypes.object
};

export { columnDefs, ManageRenderer, NameRenderer, RoleRenderer };
