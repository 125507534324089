import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSelectionComponent from '../CustomSelectionComponent';

const actionLabel = {
  label1: 'Assign Tag',
  label2: 'Delete',
  label3: 'Change Status'
};

const CompanyGridActionSelection = props => {
  const { handleAssignTag, data = null, handleDeleteCompany, id = '', handleChangeStatus } = props;
  const companyActionOptions = useSelector(state => state.commonReducer.companyAction);
  const [options] = useState(companyActionOptions);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuItemClick = option => {
    // if (option?.short_desc === actionLabel.label1) handleAddToProject(id);
    if (option?.short_desc === actionLabel.label2) handleDeleteCompany(id, data);
    if (option?.short_desc === actionLabel.label1) handleAssignTag(data);
    if (option?.short_desc === actionLabel.label3) handleChangeStatus(data);
    setAnchorEl(null);
  };
  const customButtonForAllCompany = <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />;
  return (
    <div>
      <CustomSelectionComponent options={options} handleMenuItemClick={handleMenuItemClick} component={customButtonForAllCompany} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
    </div>
  );
};
CompanyGridActionSelection.propTypes = {
  handleAssignTag: PropTypes.func,
  handleDeleteCompany: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  // toggleActivity: PropTypes.func,
  // handleAddToProject: PropTypes.func,
  id: PropTypes.string,
  data: PropTypes.object
};

export default CompanyGridActionSelection;
