import AddIcon from '@mui/icons-material/Add';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AppBar, Box, Checkbox, Link, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React, { useCallback, useMemo, useState } from 'react';
import { columnDefs, createRequiredField, createSchema, getFilterParamString, getQueryParamsString } from './Utils';
// import Loader from '../../components/common/Loader';
import { Helmet } from 'react-helmet';
import CustomButton from '../../components/common/CustomButton';
//import IosShareIcon from "@mui/icons-material/IosShare";
import AddCompany from '../../components/AddCompany';
import CompanyGridSelection from '../../components/common/FunctionalComponents/CompanyGridSelection';
import { addHttps } from '../../utils/string';
import Search from '../Contacts/Search';
import { checkContactFetchingStatus } from '../Contacts/utils';
import './index.scss';
//import AddCompany from "../../components/AddCompany";
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { fetchGridData, fetchPickLists } from '../../actions';
import CompanyGridActionSelection from '../../components/common/CompanyGridActionSelection';
import CustomConfirmationPopup from '../../components/common/CustomConfirmationPopup';
import CustomPopover from '../../components/common/CustomPopover';
import Loader from '../../components/common/Loader';
import ViewCompanyDrawer from '../../components/ViewCompany/ViewCompanyDrawer';
import ValidatePermit from '../../security/ValidatePermit';
import { ignCompanyDataApi } from '../../services/ApiService';
import { DEFAULT_STATUS, DELETE, ERROR, FIELDS, IGN_API, IGNYTE_GLOBALS, POST, REVENUE_MAX_LIMIT, ROUTES, SUB_ROUTES, SUCCESS, WARNING } from '../../services/constantService';
import { notFoundMessage, successMessage } from '../../services/MessageService';
import { updateGlobalSearchData } from '../../store/globalSearchSlice';
import AgGridWrapper from '../../utils/AgGridWrapper';
import { customFormValidator, SCROLL_TIMEOUT, splitLastIfMultiple } from '../../utils/common';
import { dataSourceUtilsContacts } from '../../utils/dataSource';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';
import { SUCCESS_STATUS_CODE } from '../Commons/Utils';
import CompanyAssignTag from './CompanyAssignTag';
import CompanyChangeStatus from './CompanyChangeStatusPopup';

let gridApi, columnApi;
const PAGE_LIMIT = 50;
const List = () => {
  const [isGridReady, setIsGridReady] = useState(false);
  const [, /*isEdited*/ setIsEdited] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});
  const { translateMessage } = useCustomMessageHook();
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [anchorElTagsHoverPopup, setAnchorElTagsHoverPopup] = useState(null);
  const [, /*isAllRowsSelected*/ setIsAllRowsSelected] = useState(false);
  const [tagPopUp, setTagPopUp] = useState([]);
  const [tagDataId, setTagDataId] = useState([]);
  const [tagChange, setTagChange] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [isUpdateCompany, setIsUpdateCompany] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();
  const [isClassicQuery, setIsClassicQuery] = useState(false);
  const [quickQuery, setQuickQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isSelectedFromList, setIsSelectedFromList] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [loadedContactCount, setLoadedContactCount] = useState(0);
  const [gridState, setGridState] = useState([]);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});
  const isSelectedListRef = useRef();
  const quickQueryRef = useRef();
  const isClassicQueryRef = useRef();
  const csvExportRef = useRef();
  isSelectedListRef.current = isSelectedFromList;
  quickQueryRef.current = quickQuery;
  isClassicQueryRef.current = isClassicQuery;
  const [isCompanyAdd, setIsCompanyAdd] = useState(false);
  const [companyStatus, setCompanyStatus] = useState();
  const [isBulkTag, setIsBulkTag] = useState();
  const companyStatusOption = useSelector(state => state.commonReducer.companyStatus);
  const [options, setOptions] = useState(companyStatusOption || []);
  const openTagsViewMore = Boolean(anchorElTagsHoverPopup);
  const [tagList, setTagsList] = useState();
  // const [companyId, setCompanyId] = useState();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const tagListRef = useRef();
  tagListRef.current = tagList;
  const setGridApi = useRef(null);
  const setColumnApi = useRef(null);
  const companyGridRef = useRef(null);
  const defaultColumnDefs = useMemo(() => columnDefs(), []);
  useEffect(() => {
    dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
    dispatch(fetchPickLists('COMPANY_ACTIONS', 'companyAction'));
  }, []);

  useEffect(() => {
    setOptions(companyStatusOption);
  }, [companyStatusOption]);

  useEffect(() => {
    if (location.pathname === ROUTES.addCompany && isComponentLoaded) {
      setIsPopupOpen(true);
    }
  }, [location.pathname, isComponentLoaded]);
  const openDrawer = params => {
    // setIsDrawerOpen(true);
    navigate(`/companies/all-companies/${params.data.id}`);
  };
  useEffect(() => {
    const isContactView = location.pathname.includes(id);
    if (isContactView && !isDrawerOpen && isComponentLoaded) {
      setIsDrawerOpen(true);
    }

    if (state?.isEdited) {
      setIsPopupOpen(true);
    }
  }, [location?.pathname, isComponentLoaded]);
  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };
  useEffect(() => {
    if (tagChange && !isTagPopupOpen) {
      gridApi.deselectAll();
      gridApi && gridApi.onFilterChanged();
      setSelectAllRows(false);
    }
  }, [tagChange, isTagPopupOpen, gridApi]);

  useEffect(() => {
    if (statusChange) {
      gridApi && gridApi.onFilterChanged();
      setStatusChange(false);
    }
  }, [statusChange]);
  useEffect(() => {
    if (isCompanyAdd) {
      setIsUpdateCompany(true);
      setIsCompanyAdd(false);
    } else {
      setIsUpdateCompany(false);
    }
  }, [id]);

  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    setGridApi.current = params.api;
    setColumnApi.current = params.columnApi;
    companyGridRef.current = params;

    // params.columnApi?.getAllColumns().map(col => {
    //   const visibility = gridState.find((ele) => ele?.colId === col?.colId)
    //   console.log('visibility=>>', visibility.colId, visibility.visible);

    //   params.columnApi?.setColumnVisible(col.colId, visibility?.visible);
    // });
    // params.columnApi.autoSizeColumns();
    gridApi?.setGridOption('serverSideDatasource', dataSource);
    dispatch(updateGlobalSearchData({ key: 'gridApi', data: setGridApi }));
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    setIsGridReady(true);
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };
  const headerCheckbox = () => {
    return <Checkbox className='header-checkbox' classes={{ root: 'custom-checkbox-root' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };
  const handleAssignTag = (data, bulkTag = '') => {
    if (bulkTag === 'bulkTag') {
      setTagDataId('');
      setIsBulkTag(true);
      setIsTagPopupOpen(true);
    } else {
      setTagPopUp(data.company_tags);
      setTagDataId(data.id);
      setIsBulkTag(false);
      setIsTagPopupOpen(true);
    }
  };
  const handleChangeStatus = data => {
    setCompanyStatus(data);
    setIsStatusPopupOpen(true);
  };
  const onConfirm = async () => {
    setIsLoading(true);
    const { status } = await ignCompanyDataApi(DELETE, '', companyDetails.id);
    if (status === 200) {
      setIsLoading(false);
      // navigate(`/contacts/list/all-contacts`);
      const message = successMessage('Company', 'Deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      gridApi && gridApi.onFilterChanged();
    }
  };
  const handleDeleteCompany = (id, data) => {
    setOpenConfirmationPopup(true);
    setCompanyDetails(data);
  };
  const getActionRenderer = params => {
    return (
      <CompanyGridActionSelection
        handleChangeStatus={handleChangeStatus}
        handleAssignTag={handleAssignTag}
        handleDeleteCompany={handleDeleteCompany}
        params={params}
        id={params?.data?.id}
        data={params?.data}
      ></CompanyGridActionSelection>
    );
  };
  const nameRenderer = params => {
    return (
      <Box className='d-flex flex-row align-item-center fullHeight'>
        {params.data?.linkedin_url ? (
          <a rel='noopener noreferrer' target='_blank' href={addHttps(params.data?.linkedin_url)} className='linkedin-icon-header company-display mb-1'>
            <LinkedInIcon className='fs-22'></LinkedInIcon>
          </a>
        ) : (
          <LinkedInIcon className='disabled-link fs-22' />
        )}
        <Tooltip title={params.data?.name}>
          <Box
            component={'span'}
            onClick={() => {
              openDrawer(params);
            }}
          >
            <Typography className='ml-2 fs-13 company_name company-display'>{params.data?.name}</Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  };
  const openTagsPopup = (event, tags) => {
    setTagsList(tags);
    setAnchorElTagsHoverPopup(event.currentTarget);
  };
  const closeTagsPopup = () => {
    setAnchorElTagsHoverPopup(null);
  };
  const getTags = params => {
    if (params?.data) {
      return (
        <Box className='tag-container tag-pointer'>
          {params?.data?.company_tags &&
            params?.data?.company_tags?.slice(0, 2)?.map(
              (item, index) =>
                item && (
                  <Box
                    component='div'
                    onMouseOver={e => {
                      openTagsPopup(e, params?.data?.company_tags);
                    }}
                    onMouseLeave={closeTagsPopup}
                    className={'p-1 tag-border'}
                    sx={{
                      color: `${item?.ign_tag?.color}`
                    }}
                    key={index}
                  >
                    {item?.ign_tag?.name}
                  </Box>
                )
            )}
        </Box>
      );
    }
  };
  const employeeRangeRendrer = params => {
    const fromRange = params?.data?.employee_range_from || '';
    const toRange = params?.data?.employee_range_to || '';
    let sign = '';
    if (fromRange && toRange) {
      sign = '-';
    }
    const employeeRange = `${fromRange} ${sign} ${toRange}`;
    return <Box className='w-100 d-flex justify-content-center-1 cell-text'>{employeeRange}</Box>;
  };
  const revenueRange = params => {
    const currency = params.data?.currency_code;
    const unit = params.data?.currency_unit_trans_value?.short_desc;
    const fromRange = params?.data?.revenue_range_from || '';
    const toRange = params?.data?.revenue_range_to || '';
    let sign = '';
    if (fromRange && toRange) {
      sign = '-';
    }
    const revenue = [fromRange, sign, toRange];
    if (fromRange || toRange) {
      revenue.unshift(currency);
      revenue.push(unit);
    }
    const revenueRange = revenue.join(' ');

    return <Box className='w-100 d-flex justify-content-center-1 cell-text'>{revenueRange}</Box>;
  };
  const websiteUrlRenderer = params => {
    if (params.colDef.field === 'linkedin_url') {
      return (
        <Box className='details-width'>
          <Link rel='noopener noreferrer' target='_blank' href={addHttps(params.value)} className='link'>
            {params.value}
          </Link>
        </Box>
      );
    }
    return (
      <Box className='details-width'>
        <Link rel='noopener noreferrer' target='_blank' href={addHttps(params.value)} className='link'>
          {params.data?.website_url}
        </Link>
      </Box>
    );
  };
  const ownerRenderer = params => {
    return <Box className='w-100 d-flex justify-content-center-1 cell-text'>{params?.data?.created_user?.name}</Box>;
  };
  // const emailRenderer = params => {
  //   const primaryEmail = params.data?.company_emails.find(emailObj => emailObj.is_primary === true);
  //   return <Box className='details-width'>{primaryEmail?.email}</Box>;
  // };
  const emailRenderer = params => {
    const primaryEmail = params.data?.company_emails.find(emailObj => emailObj.is_primary === true);
    return <Box className='details-width'>{primaryEmail?.email}</Box>;
  };
  const phoneRenderer = params => {
    const primaryPhone = params.data?.company_phones.find(phoneObj => phoneObj.is_primary === true);
    return <Box className='w-100 d-flex justify-content-center-1 cell-text'>{primaryPhone?.phone_number}</Box>;
  };
  const capitalStructureRenderer = params => {
    return <Box className='w-100 d-flex justify-content-center-1 cell-text'>{params?.data?.capital_struct_trans_value?.short_desc}</Box>;
  };
  const addressRenderer = params => {
    const primaryAddress = params.data?.company_address.find(addressObj => addressObj.is_primary === true);
    const addressArray = [
      primaryAddress?.address_lines || '',
      primaryAddress?.metropolitan_area || '',
      primaryAddress?.city || '',
      primaryAddress?.company_state?.name || '',
      primaryAddress?.company_country?.name || '',
      primaryAddress?.zip_code || ''
    ];
    const newAddress = addressArray.filter(item => item !== '');
    const commaSeparatedAddress = newAddress.join(', ');

    return (
      <Tooltip placement='bottom-start' title={commaSeparatedAddress}>
        <Box className=' details-width'>{commaSeparatedAddress}</Box>
      </Tooltip>
    );
  };
  const dateRenderer = params => {
    if (params?.data) {
      const field = params?.colDef?.colId;
      let date = params?.data[field] ? moment(params?.data[field]).format('LT, DD MMM YYYY') : '-';
      return (
        <Box
          id='contact-status-column'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <Box component='div' className='ml-1 column-width'>
            <Tooltip title={status}>
              <Box component='div' className='header-content header-font p-2 content-wrap'>
                {date}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      );
    }
  };
  const closeAddCompanyPopup = () => {
    setIsPopupOpen(false);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  const saveAddCompanyPopup = async () => {
    const companyId = await saveData();
    if (companyId) {
      setIsPopupOpen(false);
      navigate(`/companies/all-companies/${companyId}`);
      setIsDrawerOpen(true);
      // setIsUpdateCompany(true);
    }
  };
  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags?.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries ? data?.company_industries?.map(industry => ({ industry_id: industry.id })) : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value,
      company_status: DEFAULT_STATUS
    };
    return addCompanyPayload;
  };
  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };
  const isValidatedRevenue = async formValues => {
    if (formValues.revenue_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.revenue_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_to_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_to_massage, { variant: ERROR });
      return false;
    }
    return true;
  };
  const saveData = async () => {
    let formValues = { ...getValues() };
    const requestBody = getPayload(formValues);
    const IsValidRevenueRange = await isValidatedRevenue(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody && IsValidRevenueRange) {
      setIsLoading(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        // setCompanyId(data.id);
        setIsPopupOpen(false);
        reset();
        setIsCompanyAdd(true);
        setIsLoading(false);
        return data.id;
      } else if (status === 409) {
        const message = translateMessage('Duplicate', false, 'Company Name');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setIsLoading(false);
    }
  };
  const handleBulkDelete = async companyData => {
    try {
      setIsLoading(true);
      const ids = companyData?.map(dataItem => dataItem.id);
      const { status, data } = await ignCompanyDataApi(POST, { id: ids }, SUB_ROUTES.bulkDelete);
      if (status === 200) {
        setIsLoading(false);
        const message = successMessage('Companies', 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
        gridApi && gridApi.onFilterChanged();
        setIsRowSelected(false);
      } else {
        const message = translateMessage('UnableMessage', false, 'Companies', 'delete');
        enqueueSnackbar(message, { variant: ERROR });
        setIsLoading(false);
      }
    } catch (e) {
      console.error('Error found in handleBulkDelete::', e);
    }
  };
  const commentRenderer = params => {
    const comments = params.data?.comments ? parse(params.data?.comments) : '';
    return (
      <Tooltip placement='bottom-start' title={comments}>
        <Box className='cell-text content-wrap '>{comments}</Box>
      </Tooltip>
    );
  };
  const locationRenderer = params => {
    const location = params?.data?.location_description;
    return (
      <div className='long-text'>
        <Tooltip title={location}>
          <Box className='multi-line-ellipsis'>{location}</Box>
        </Tooltip>
      </div>
    );
  };
  const addCompany = () => {
    setIsPopupOpen(true);
    navigate(ROUTES.addCompany);
  };
  const industriesRenderer = params => {
    const industriesList = params.data?.company_industries?.map(industry => {
      return industry.industries?.name;
    });
    if (Array.isArray(industriesList)) {
      const industries = industriesList.join(', ');
      return (
        <div className='long-text'>
          <Tooltip title={industries}>
            <Box className='multi-line-ellipsis'>{industries}</Box>
          </Tooltip>
        </div>
      );
    }
    return '';
  };
  const getURLs = {
    listURl: `${IGN_API.company}`,
    quickQuery: `${IGN_API.company}`
  };

  const getRowClass = () => {
    return 'company-row';
  };

  const getGridSettings = useCallback(() => {
    fetchGridData('', 'companyGrid', ({ status, data }) => {
      if (status) {
        const sortOrder = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');
        // if (sortOrder.length) {
        //   let sortedCellColumns = sortOrder?.map(col => {
        //     return col;
        //   });
        //   console.log('sortedCellColumns', sortedCellColumns);
        //   setSavedSortModel([...sortModel]);
        //   setSavedFilterModel({ ...filterModel });
        //   return setGridState([...sortedCellColumns]);
        // }
        if (Array.isArray(sortOrder) && sortOrder.length) {
          const savedConfigMap = sortOrder.reduce((acc, column) => {
            acc[column.colId] = column;
            return acc;
          }, {});

          const updatedColDefs = defaultColumnDefs.map(col => {
            const savedConfig = savedConfigMap[col.colId];
            if (savedConfig) {
              const filteredOutConfig = omit(savedConfig, ['sort', 'sortIndex']);
              return {
                ...col,
                ...filteredOutConfig
              };
            }
            return {
              ...col
            };
          });

          updatedColDefs.sort((a, b) => {
            const indexA = sortOrder.findIndex(column => column.colId === a.colId);
            const indexB = sortOrder.findIndex(column => column.colId === b.colId);
            return indexA - indexB;
          });

          setSavedSortModel([...sortModel]);
          setSavedFilterModel({ ...filterModel });
          return setGridState([...updatedColDefs]);
        } else {
          return setGridState(defaultColumnDefs);
        }
      } else {
        console.error('Error:', data);
        return setGridState(defaultColumnDefs);
      }
    });
  }, [isGridReady]);

  useEffect(() => {
    if (isGridReady && companyGridRef.current) {
      getGridSettings();
    }
  }, [isGridReady]);

  let globalData = [];
  //earlier used data source without ag grid filters
  // const dataSource = {
  //   getRows: async params => {
  //     try {
  //       setIsLoading(true);
  //       const thisValue = {
  //         quickQuery: quickQueryRef.current,
  //         isSelectedFromList: isSelectedListRef.current
  //       };
  //       const { sortModel, filterModel } = params;
  //       if ((filterModel && Object.keys(filterModel).length > 0) || (sortModel && sortModel.length > 0)) {
  //         if (allCompanies.length) {
  //           const { data, count } = applyClientSideSortingAndFiltering(globalData, filterModel, sortModel);
  //           // Set the success callback with the filtered/sorted data
  //           setAllCompanies([...data]);
  //           params.successCallback(data, count);

  //           // Update necessary state variables
  //           setRowCount(count);
  //           setLoadedContactCount(count);
  //           setIsComponentLoaded(true);
  //           setIsLoading(false);
  //           return;
  //         }
  //       }

  //       const obj = {
  //         params: params,
  //         context: thisValue,
  //         pageLimit: PAGE_LIMIT,
  //         url: getURLs,
  //         contactsScreen: true
  //       };

  //       const getData = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
  //       const { status, data } = getData;
  //       const rowData = data?.rows ? data.rows : data.data ? data.data : data?.companies ? data?.companies : [];
  //       if (status === SUCCESS_STATUS_CODE) {
  //         if (data?.paging) {
  //           if (data?.paging?.totalCount === 0) {
  //             const message = notFoundMessage('records');
  //             enqueueSnackbar(message, { variant: WARNING });
  //           }
  //           setAllCompanies([...data.data]);
  //           params.successCallback(data?.data, data?.data?.length);
  //           setRowCount(data?.data?.length);
  //           setLoadedContactCount(0);
  //         } else if (Array.isArray(rowData)) {
  //           const message = notFoundMessage('records');
  //           if (data?.length === 0) {
  //             enqueueSnackbar(message, { variant: WARNING });
  //           }
  //           globalData = [...globalData, ...rowData];
  //           setAllCompanies([...rowData]);
  //           params.successCallback(rowData, data?.total);
  //           setRowCount(data?.total);
  //           setLoadedContactCount(data?.totalRowCount);
  //         }
  //       } else {
  //         params.failCallback();
  //       }
  //       setIsComponentLoaded(true);
  //       setIsLoading(false);
  //     } catch (e) {
  //       console.log('Error found in getRows::', e);
  //     }
  //     // localStorage.removeItem('contactColumns')
  //   },
  //   rowCount: null
  // };

  //data source for ag grid filters
  const dataSource = {
    getRows: async params => {
      try {
        setIsLoading(true);
        const thisValue = {
          quickQuery: quickQueryRef.current,
          isSelectedFromList: isSelectedListRef.current
        };
        const { sortModel, filterModel } = params;

        //removed client side sorting which works on partial data
        // if ((filterModel && Object.keys(filterModel).length > 0) || (sortModel && sortModel.length > 0)) {
        //   if (allCompanies.length) {
        //     const { data, count } = applyClientSideSortingAndFiltering(globalData, filterModel, sortModel);
        //     // Set the success callback with the filtered/sorted data
        //     setAllCompanies([...data]);
        //     params.successCallback(data, count);

        //     // Update necessary state variables
        //     setRowCount(count);
        //     setLoadedContactCount(count);
        //     setIsComponentLoaded(true);
        //     setIsLoading(false);
        //     return;
        //   }
        // }

        const obj = {
          params: params.request,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          contactsScreen: true
        };

        const getData = await dataSourceUtilsContacts(obj, getFilterParamString, getQueryParamsString);
        const { status, data } = getData;
        const rowData = data?.rows ? data.rows : data.data ? data.data : data?.companies ? data?.companies : [];
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            // setAllCompanies([...data.data]);
            params.success({ rowData: data?.data, rowCount: data?.data?.length });
            // params.successCallback(data?.data, data?.data?.length);
            setRowCount(data?.data?.length);
            setLoadedContactCount(0);
          } else if (Array.isArray(rowData)) {
            const message = notFoundMessage('records');
            if (data?.length === 0) {
              enqueueSnackbar(message, { variant: WARNING });
            }
            globalData = [...globalData, ...rowData];
            // setAllCompanies([...rowData]);
            // params.successCallback(rowData, data?.total);
            params.success({ rowData: rowData, rowCount: data?.total });
            setRowCount(data?.total);
            setLoadedContactCount(data?.totalRowCount);
          }
        } else {
          params.failCallback();
        }
        setIsComponentLoaded(true);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error('Error found in getRows:', e);
      }
      // localStorage.removeItem('contactColumns')
    }
    // rowCount: null
  };

  const applyClientSideSortingAndFiltering = (data, filterModel, sortModel) => {
    let modifiedData = [...data];

    if (filterModel && Object.keys(filterModel).length > 0) {
      Object.keys(filterModel).forEach(filterField => {
        const filter = filterModel[filterField];
        const filterValue = filter?.filter?.toLowerCase();

        if (filterValue) {
          modifiedData = modifiedData.filter(row => {
            let rowValue = '';

            if (filterField === 'location') {
              rowValue = (row.location_description || '').toString().toLowerCase();
            } else if (filterField === 'industry') {
              rowValue =
                row.company_industries
                  ?.map(industryObj => industryObj.industries?.name || '')
                  .join(', ')
                  .toLowerCase() || '';
            } else if (filterField === 'name') {
              rowValue = (row.name || '').toString().toLowerCase();
            } else if (filterField === 'employee') {
              rowValue = (row.employee_range || '').toString().toLowerCase();
            } else if (filterField === 'revenue') {
              rowValue = (row.revenue_range || '').toString().toLowerCase();
            } else if (filterField === 'website_url') {
              rowValue = (row.website_url || '').toString().toLowerCase();
            } else if (filterField === 'owner') {
              rowValue = (row.created_user?.name || '').toString().toLowerCase();
            } else {
              rowValue = (row[filterField] || '').toString().toLowerCase(); // Default behavior for other fields
            }

            return rowValue.includes(filterValue);
          });
        }
      });
    }

    if (sortModel && sortModel.length > 0) {
      sortModel.forEach(sortItem => {
        const sortField = sortItem.colId;
        const sortDirection = sortItem.sort; // 'asc' or 'desc'

        modifiedData.sort((a, b) => {
          let aValue = '';
          let bValue = '';

          if (sortField === 'location') {
            aValue = (a.location_description || '').toString().toLowerCase();
            bValue = (b.location_description || '').toString().toLowerCase();
          } else if (sortField === 'industry') {
            aValue =
              a.company_industries
                ?.map(industryObj => industryObj.industries?.name || '')
                .join(', ')
                .toLowerCase() || '';
            bValue =
              b.company_industries
                ?.map(industryObj => industryObj.industries?.name || '')
                .join(', ')
                .toLowerCase() || '';
          } else if (sortField === 'name') {
            aValue = (a.name || '').toString().toLowerCase();
            bValue = (b.name || '').toString().toLowerCase();
          } else if (sortField === 'employee') {
            aValue = (a.employee_range || '').toString().toLowerCase();
            bValue = (b.employee_range || '').toString().toLowerCase();
          } else if (sortField === 'revenue') {
            aValue = (a.revenue_range || '').toString().toLowerCase();
            bValue = (b.revenue_range || '').toString().toLowerCase();
          } else if (sortField === 'website_url') {
            aValue = (a.website_url || '').toString().toLowerCase();
            bValue = (b.website_url || '').toString().toLowerCase();
          } else if (sortField === 'owner') {
            aValue = (a.created_user?.name || '').toString().toLowerCase();
            bValue = (b.created_user?.name || '').toString().toLowerCase();
          } else {
            aValue = (a[sortField] || '').toString().toLowerCase();
            bValue = (b[sortField] || '').toString().toLowerCase();
          }

          if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
          return 0;
        });
      });
    }
    return { data: modifiedData, count: modifiedData.length };
  };

  const handleSearchChange = (event, value) => {
    setIsClassicQuery(false);
    setIsSelectedFromList(false);

    if (typeof value === 'string') {
      setQuickQuery(value);
      setSearchValue(value);
    } else if (value && value.id) {
      setIsSelectedFromList(true);
      setQuickQuery(value.id);
      setSearchValue(value.name);
    }
    if (companyGridRef.current?.api) {
      setQuickQuery(value?.id ? value?.id : value ? value : '');
      companyGridRef.current?.api?.refreshServerSide();
    } else {
      console.error('Grid API is not available');
    }
    resetFilter();
    resetSort();
  };

  const resetFilter = async () => {
    companyGridRef.current?.api?.setFilterModel(null);
  };

  const resetSort = async () => {
    gridApi.applyColumnState({
      state: companyGridRef.current?.api?.getColumnState().map(col => ({ ...col, sort: null }))
    });
  };

  const companyColumnsExports = [
    { key: 'name', label: 'Company Name' },
    { key: 'location_description', label: 'Location' },
    { key: 'company_status', label: 'Status' },
    { key: 'company_tags', label: 'Tags' },
    // { key: 'company_industries', label: 'Company Industries' },
    { key: 'employee_range_to', label: 'Employee Range' },
    { key: 'revenue_range_to', label: 'Revenue Range' },
    { key: 'linkedin_url', label: 'LinkedIn URL' },
    { key: 'website_url', label: 'Website Url' },
    { key: 'created_at', label: 'Created At' },
    { key: 'updated_at', label: 'Updated At' },
    { key: 'created_by', label: 'Created By' },
    { key: 'updated_by', label: 'Updated By' },
    { key: 'comments', label: 'Comments' },
    { key: 'currency_code', label: 'Currency Code' },
    {
      key: 'company_emails',
      label: 'Company Emails'
    },
    {
      key: 'company_phones',
      label: 'Company Phone',
      valueGetter: data => {
        const primaryPhone = data?.company_phones?.find(phone => phone?.is_primary === true);
        return primaryPhone?.phone_number || '';
      }
    }
  ];

  return (
    <Box id='company-tab-container' className='list-contacts p-4 d-flex flex-column'>
      <ValidatePermit parent={'company'} type='message-only' permissionType={'read'}>
        <Loader show={isLoading} />
        <Helmet>
          <title>Companies - {IGNYTE_GLOBALS.BRAND}</title>
        </Helmet>

        <Box className='tool-bar mb-3'>
          <AppBar position='static'>
            <Toolbar className='pb-4'>
              <Search value={searchValue} onChange={handleSearchChange} type={'companies'} className='search-bar' placeholder={'Search'} />

              <Box className='tool-bar' />
              <Stack direction='row' spacing={3}>
                {/* {isRowSelected && selectedRows !== 0 ? (
                <CompanyGridSelection
                  handleAssignTag={handleAssignTag}
                  // handleBulkChangeStatus={handleChangeStatus}
                  handleBulkDelete={handleBulkDelete}
                  //handleAddToProject={handleAddToProject}
                  data={selectedRows}
                  // id={params?.data?.id}
                  //setIsAddToProjectOpen={setIsAddToProjectOpen}
                />
              ) : null} */}
                <CompanyGridSelection handleAssignTag={handleAssignTag} handleBulkDelete={handleBulkDelete} data={selectedRows} disabled={!isRowSelected || selectedRows === 0} />
                {/* <Link
                to="/companies/business-development/add"
              >
                <CustomButton
                  variant="contained"
                  type={"primary"}
                  size={"medium"}
                  buttonText={"Add BD"}
                  customWidth={110}
                  iconLeft={<AddIcon sx={{ width: 20, height: 20 }} />}
                />
              </Link> */}

                {/* <CustomButton
                // onClick={toggleMenu}
                type={"secondary"}
                size={"medium"}
                variant="outlined"
                disabled={!isRowSelected}
                width={42}
                customWidth={42}
                iconLeft={<IosShareIcon sx={{ width: 20, height: 20 }} />}
              /> */}
                {/* <GridHeaderMenu /> */}
                {/* <ExportCSV
                ref={csvExportRef}
                iconHeight={26}
                iconWidth={50}
                columns={companyColumnsExports}
                data={allCompanies}
                arrayKeyMapping={companyExportColumnsArrayMapping}
                fileName='Companies.csv'
              /> */}
                <CustomButton
                  variant='contained'
                  onClick={() => {
                    addCompany();
                  }}
                  type={'primary'}
                  size={'medium'}
                  buttonText={'Add Company'}
                  customWidth={110}
                  iconLeft={<AddIcon sx={{ width: 20, height: 20 }} />}
                />
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
        {/* <Box className='d-flex justify-content-end'>
        <TotalCount rowCount={rowCount} selectedRowsCount={selectedRowsCount} isRowSelected={isRowSelected} loadedContactCount={loadedContactCount} showTotalCount={false} />
      </Box> */}
        {/* <div style={{ maxWidth: '280px' }} className='grid-menu-tab d-flex justify-content-end'>
        <TotalCount rowCount={rowCount} selectedRowsCount={selectedRowsCount} isRowSelected={isRowSelected} loadedContactCount={loadedContactCount} showTotalCount={false} />
        <GridHeaderMenu onExport={() => csvExportRef.current.exportCSV()} gridRef={companyGridRef.current} />
      </div> */}
        <CompanyAssignTag
          tagPopUp={tagPopUp}
          companyId={tagDataId}
          isBulkTag={isBulkTag}
          setIsPopupOpen={setIsTagPopupOpen}
          isPopupOpen={isTagPopupOpen}
          setTagChange={setTagChange}
          //label={t(`utils.selectTag`)}
          selectedRows={selectedRows}
          // isAllRowsSelected={isAllRowsSelected}
        />
        <Box className='list-view'>
          {/* {isGridReady && (
          <ColumnFilter
            columnApi={columnApi}
            defaultColumns={defaultColumns}
            //showFilterCount={showFilterCount}
            //filterModel={filterModel}
          />
        )} */}
          <Box className='ag-theme-alpine header-column-filter'>
            <AgGridWrapper
              gridType='companyGrid'
              rowHeight={50}
              defaultColumnDefs={defaultColumnDefs}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              columnDefs={[...gridState]}
              height='420px'
              paginationPageSize={PAGE_LIMIT}
              rowSelection={{ mode: 'multiRow', selectAll: 'currentPage', groupSelects: 'descendants' }}
              rowModelType='serverSide'
              suppressRowClickSelection={true}
              animateRows={true}
              onGridReady={onGridReady}
              filterModel={savedFilterModel}
              sortModel={savedSortModel}
              components={{
                NameRenderer: nameRenderer,
                EmployeeRangeRenderer: employeeRangeRendrer,
                RevenueRange: revenueRange,
                WebsiteUrlRenderer: websiteUrlRenderer,
                GetActionRenderer: getActionRenderer,
                TagRenderer: getTags,
                IndustriesRenderer: industriesRenderer,
                CommentRenderer: commentRenderer,
                LocationRenderer: locationRenderer,
                EmailRenderer: emailRenderer,
                HeaderCheckbox: headerCheckbox,
                PhoneRenderer: phoneRenderer,
                AddressRenderer: addressRenderer,
                CapitalStructureRenderer: capitalStructureRenderer,
                OwnerRenderer: ownerRenderer
              }}
              //total count props
              selectedRows={selectedRows}
              scrollbarWidth={12}
              rowCount={rowCount}
              selectedRowsCount={selectedRowsCount}
              isRowSelected={isRowSelected}
              loadedContactCount={loadedContactCount}
              showTotalCount={true}
              showHeaderMenu={true}
              showAddContactToWorkbench
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              isRowSelectable={params => {
                const status = checkContactFetchingStatus(params);
                if (status) return false;
                return true;
              }}
              onRowSelected={params => {
                const selectedNode = params?.api?.getSelectedNodes();
                const selectedRows = selectedNode.map(node => node.data);
                // if (!e.node.selected) {
                //   this.setState({ isAllRowsSelected: false })
                // }
                setSelectedRowsCount(selectedRows?.length);
                setIsRowSelected(selectedRows?.length > 0);
                setSelectedRows(selectedRows);
              }}
              disableStaticFilter={true}
              disableStaticMarkup={true}
              getRowClass={getRowClass}
              rowGroupPanelShow='never'
              saveGridSetting={true}
              sideBarColumnToolPanelParams={{
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true
              }}
            />
          </Box>
        </Box>

        <AddCompany
          isPopupOpen={isPopupOpen}
          handleClose={closeAddCompanyPopup}
          handleSubmit={saveAddCompanyPopup}
          setIsLoading={setIsLoading}
          setValue={setValue}
          register={register}
          reset={reset}
          setIsPopupOpen={setIsPopupOpen}
          unregister={unregister}
          watch={watch}
        ></AddCompany>

        <Box id='view-contact-drawer'>
          <ViewCompanyDrawer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            id={id}
            setIsEdited={setIsEdited}
            options={options}
            gridApi={gridApi}
            isUpdateCompany={isUpdateCompany}
            setIsUpdateCompany={setIsUpdateCompany}
          />
        </Box>
        {/* <AddCompany setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen}></AddCompany> */}
        <CustomPopover
          id='mouse-over-popover-tags'
          open={openTagsViewMore}
          anchorEl={anchorElTagsHoverPopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={closeTagsPopup}
          pointerEvents='none'
        >
          <Box onMouseEnter={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
            {tagListRef.current && (
              <Box>
                {tagListRef?.current?.map((tag, index) => (
                  <Box
                    key={index}
                    className={'tag-border m-1 p-1'}
                    sx={{
                      color: `${tag?.ign_tag?.color}`
                    }}
                  >
                    {tag?.ign_tag?.name}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </CustomPopover>
        <CustomConfirmationPopup
          open={openConfirmationPopup}
          onClose={closeConfirmationPopup}
          type='delete'
          deletingItem='Company'
          deletingItemName={companyDetails?.name}
          cancelText='No'
          confirmText='Yes'
          onConfirm={onConfirm}
          setOpen={setOpenConfirmationPopup}
        />
        <CompanyChangeStatus isPopupOpen={isStatusPopupOpen} setIsPopupOpen={setIsStatusPopupOpen} companyStatus={companyStatus} setChange={setStatusChange}></CompanyChangeStatus>
      </ValidatePermit>
    </Box>
  );
};

export default List;
