import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transcribeAudio } from '../../../services/ApiService';
import KeyBoardSVGComponent from '../../Icons/KeyboardAskIgnyte';
import MicSVGComponent from '../../Icons/MicAskIgnyte';
import MuteSVGComponent from '../../Icons/MuteAskIgnyte';
import SpeakerSVGComponent from '../../Icons/SpeakerAskIgnyte';

const IconCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: '30px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  margin: theme.spacing(1, 0),
  width: '240px'
}));

function ChatControls({ isSpeakerOn, onSpeakerToggle, onTypeClick, onBotResponse, onUserTranscription, onRecordingStateChange, onTranscription, audioData }) {
  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputMode, setInputMode] = useState('default');
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const silenceTimeoutRef = useRef(null);
  const streamRef = useRef(null);
  const activeSourceRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContextRef = useRef(null);
  const silenceDetectionIntervalRef = useRef(null);
  const { t } = useTranslation();
  const stopCurrentRecording = useCallback(async () => {
    setIsRecording(false);
    onRecordingStateChange(false);

    // Stop media recorder
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }

    // Stop media stream
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }

    // Clear silence detection interval
    if (silenceDetectionIntervalRef.current) {
      clearInterval(silenceDetectionIntervalRef.current);
      silenceDetectionIntervalRef.current = null;
    }

    // Clear silence timeout
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
    }

    // Transcribe audio
    try {
      if (audioChunksRef.current.length > 0) {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        const response = await transcribeAudio(audioBlob);
        // Extract the transcribed text from the response
        const transcribedText = response.data.data;
        setTranscription(transcribedText);
        onTranscription(transcribedText, true);
      }
    } catch (error) {
      console.error('Error during transcription:', error);
    }

    // Reset state
    audioChunksRef.current = [];
  }, [onRecordingStateChange, onTranscription]);

  const startAudioCommunication = useCallback(async () => {
    stopCurrentRecording();

    try {
      setInputMode('speaking');
      setLoading(false);
      setIsRecording(true);
      onRecordingStateChange(true);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=opus'
      });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.start();

      mediaRecorder.ondataavailable = event => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];
        processAudio(audioBlob);
      };

      // Set up audio analysis for silence detection
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      source.connect(analyser);

      analyser.fftSize = 2048;
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      let silenceStartTime = null;
      let lastActiveTime = Date.now();

      // Create an interval to check for extended silence
      silenceDetectionIntervalRef.current = setInterval(() => {
        analyser.getByteFrequencyData(dataArray);
        const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;

        if (averageVolume < 5) {
          // Threshold for silence
          if (!silenceStartTime) {
            silenceStartTime = Date.now();
          } else if (Date.now() - silenceStartTime >= 2000) {
            // 2 seconds of silence
            mediaRecorder.stop();
            stopCurrentRecording();
          }
        } else {
          silenceStartTime = null;
          lastActiveTime = Date.now();
        }

        // Force stop if no activity for 10 seconds
        if (Date.now() - lastActiveTime >= 10000) {
          mediaRecorder.stop();
          stopCurrentRecording();
        }
      }, 100); // Check every 100ms
    } catch (error) {
      console.error('Error accessing microphone:', error);
      setInputMode('default');
      onRecordingStateChange(false);
    }
  }, [isRecording, stopCurrentRecording]);

  const processAudio = useCallback(
    async audioBlob => {
      setLoading(true);

      const formData = new FormData();
      formData.append('audio', audioBlob);
      try {
        const response = await transcribeAudio(formData);
        const transcriptionText = response.data.data;
        onUserTranscription(transcriptionText);
        onTranscription(transcriptionText, true);
      } catch (error) {
        console.error('Error processing audio:', error);
      } finally {
        setLoading(false);
        setInputMode('default');
      }
    },
    [onUserTranscription, onTranscription]
  );

  const handleTypeClick = useCallback(() => {
    stopCurrentRecording();

    setInputMode('typing');
    onTypeClick();
  }, [stopCurrentRecording, onTypeClick]);

  const handleSpeakClick = useCallback(() => {
    if (inputMode !== 'speaking') {
      startAudioCommunication();
    }
  }, [inputMode, startAudioCommunication]);

  const stopAudioPlayback = useCallback(() => {
    if (activeSourceRef.current) {
      try {
        activeSourceRef.current.stop();
        activeSourceRef.current = null;
        setIsPlaying(false);
      } catch (error) {
        console.error('Error stopping audio:', error);
      }
    }
  }, []);
  const playAudioResponse = useCallback(
    async audioData => {
      if (!isSpeakerOn || !audioData) return;

      try {
        // Stop any currently playing audio
        stopAudioPlayback();

        if (!audioContextRef.current) {
          audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }

        const context = audioContextRef.current;
        const arrayBuffer = new Uint8Array(audioData).buffer;
        setIsPlaying(true);

        const audioBuffer = await context.decodeAudioData(arrayBuffer);
        const source = context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(context.destination);

        // Store the active source
        activeSourceRef.current = source;

        source.onended = () => {
          setIsPlaying(false);
          activeSourceRef.current = null;
        };

        source.start(0);
      } catch (error) {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      }
    },
    [isSpeakerOn, stopAudioPlayback]
  );

  useEffect(() => {
    if (audioData && isSpeakerOn) {
      playAudioResponse(audioData);
    }
  }, [audioData, isSpeakerOn, playAudioResponse]);

  useEffect(() => {
    return () => {
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close();
      }
      // Clean up silence detection interval
      if (silenceDetectionIntervalRef.current) {
        clearInterval(silenceDetectionIntervalRef.current);
      }
    };
  }, []);
  const handleSpeakerToggle = useCallback(() => {
    if (isSpeakerOn) {
      stopAudioPlayback();
    }
    onSpeakerToggle();
  }, [isSpeakerOn, onSpeakerToggle, stopAudioPlayback]);

  return (
    <Grid container spacing={1} alignItems='center' sx={{ padding: '16px 0' }}>
      <Grid item xs>
        <IconButton onClick={handleSpeakerToggle} color='primary'>
          {isSpeakerOn ? (
            <>
              <SpeakerSVGComponent />
              <Typography
                sx={{
                  ml: 1,
                  fontSize: '0.875rem'
                }}
              >
                {t('askIgnyteChat.speakerOn')}
              </Typography>
            </>
          ) : (
            <>
              <MuteSVGComponent />
            </>
          )}
        </IconButton>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <IconCard>
          <IconButton onClick={handleTypeClick} color='primary' disabled={inputMode === 'typing'}>
            <KeyBoardSVGComponent />
            <Typography sx={{ ml: 1 }}>{t('askIgnyteChat.type')}</Typography>
          </IconButton>
          <IconButton onClick={handleSpeakClick} color='primary' disabled={inputMode === 'speaking'}>
            <MicSVGComponent />
            <Typography sx={{ ml: 1 }}>{isRecording ? t('askIgnyteChat.listening') : t('askIgnyteChat.speak')}</Typography>
          </IconButton>
        </IconCard>
      </Grid>
      <Grid item xs>
        {loading ? <Typography sx={{ fontSize: '0.875rem', color: 'gray' }}></Typography> : <Typography sx={{ fontSize: '0.875rem' }}>{transcription}</Typography>}
      </Grid>
    </Grid>
  );
}

ChatControls.propTypes = {
  isSpeakerOn: PropTypes.bool.isRequired,
  onSpeakerToggle: PropTypes.func.isRequired,
  onTypeClick: PropTypes.func.isRequired,
  onBotResponse: PropTypes.func.isRequired,
  onUserTranscription: PropTypes.func.isRequired,
  onRecordingStateChange: PropTypes.func.isRequired,
  onTranscription: PropTypes.func.isRequired,
  audioData: PropTypes.any
};

export default ChatControls;
