import DoneIcon from '@mui/icons-material/Done';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Box, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomModalWithHeader from '../../../../../Containers/Commons/CustomModalWithHeader';
import { CompetentBadgeIcon, DevelopingBadgeIcon, ExpertBadgeIcon, NeedsImprovementBadgeIcon, ProficientBadgeIcon, UnQualifiedBadgeIcon } from '../../../../Icons';
import Loader from '../../../../common/Loader';

function RubricModal({ open, setOpen, rubrics, rubricLoading }) {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <CustomModalWithHeader
      closeIcon={true}
      label={t('project.candidateScore.rubric')}
      onClose={handleClose}
      isOpen={open}
      style={{ height: '90vh', width: '55vw', padding: 0 }}
      subBoxStyle={{
        background: '#23CEA7',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRadius: '4px 4px 0 0'
      }}
      labelStyle={{ color: '#fff', fontSize: '16px' }}
      buttonStyle={{ color: '#fff', cursor: 'pointer' }}
      showDivider={false}
    >
      <Box sx={{ height: '100%', overflow: 'hidden', maxHeight: '90%', overflowY: 'auto' }} display='flex' flexDirection='column'>
        {rubricLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' flex={1}>
            <Loader loadingMessage={t('project.candidateScore.fetchingRubrics')} show className='loader-override' />
          </Box>
        ) : open && isEmpty(rubrics) ? (
          <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' flex={1}>
            <ReportGmailerrorredIcon style={{ height: 100, width: 100 }} />
            <p>{t('project.candidateScore.noRubricDataFound')}</p>
          </Box>
        ) : (
          open &&
          rubrics?.map((rubric, index) => (
            <Box key={index} sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', gap: 4 }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  width: '15%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 1
                }}
              >
                {rubric.level === '0' && <UnQualifiedBadgeIcon />}
                {rubric.level === '1' && <NeedsImprovementBadgeIcon />}
                {rubric.level === '2' && <DevelopingBadgeIcon />}
                {rubric.level === '3' && <CompetentBadgeIcon />}
                {rubric.level === '4' && <ProficientBadgeIcon />}
                {rubric.level === '5' && <ExpertBadgeIcon />}
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '13px !important',
                    color: '#009D81',
                    textAlign: 'center'
                  }}
                >
                  {rubric.rubric_rating}
                </Typography>
              </Box>
              <Box sx={{ marginTop: 1, width: '85%' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px !important',
                    color: '#333333',
                    wordWrap: 'break-word',
                    marginBottom: '2px !important'
                  }}
                >
                  {rubric.rubric_title}
                </Typography>

                {rubric.rubric_requirements.map((point, idx) => (
                  <Box key={idx} sx={{ display: 'flex', gap: 2 }}>
                    <DoneIcon
                      sx={{
                        color: 'green',
                        width: 12,
                        height: 12,
                        background: '#eee',
                        marginTop: '6px'
                      }}
                    />
                    <Typography sx={{ flex: 1, fontSize: '12px !important', color: '#555555' }}>{point}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </CustomModalWithHeader>
  );
}

RubricModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  rubrics: PropTypes.array,
  rubricLoading: PropTypes.bool
};

export default RubricModal;
