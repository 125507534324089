import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ATTACHMENT_VALIDATION, ERROR, IGN_API, NOTE, SUCCESS } from '../../services/constantService';
import MessageTemplatesRT from '../MessageTemplatePopup/MessageTemplatesRT';
import CustomButton from '../common/CustomButton';
import MarkNoteAsSelection from '../common/FunctionalComponents/MarkNoteAsSelection/MarkNoteAsSelection';
import ProjectTypeSelection from '../common/FunctionalComponents/ProjectTypeSelection';

import { useSnackbar } from 'notistack';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../selectors';
import { AllDocumentsApi, logAnActivityApi } from '../../services/ApiService';
import { successMessage } from '../../services/MessageService';
import { POST } from '../../services/constantService';
import AddDocuments from '../AddContact/Components/Documents/AddDocuments';
import CustomPopup from '../common/CustomPopup';
import useTranscribe from '../common/Transcribe';
import './index.scss';

const InitialFormState = {};

const Notes = props => {
  const { isOpen, setIsPopupOpen, contact_id } = props;

  const { t } = useTranslation();

  const [selectedStage, setSelectedStage] = useState('');
  const [projectNumber, setProjectNumber] = useState(null);
  const [isDlPopupOpen, setIsDlPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [doc, setDoc] = useState(null);

  const userData = useSelector(getUserSelector);
  const { TranscribeButton, SummariseButton, ViewTranscribedText, TranscribePopup, transcribedText, setTranscribedText } = useTranscribe({
    defaultTranscribedText: ''
  });

  const bodyRef = useRef(null);
  const { setValue, register, unregister, getValues, reset } = useForm({
    InitialFormState
  });
  const { loading, uploadDocuments, addNote } = useNotesApis({
    contact_id,
    isEdit,
    setValue,
    setIsDlPopupOpen,
    setDoc,
    getValues
  });

  useEffect(() => {
    register('attachment');
    register('activity_added_at');
    register('project_id');
    register('notes_type');
    register('notes');
    register('created_by');
    register('updated_by');
    return () => {
      unregister('attachment');
      unregister('activity_added_at');
      unregister('project_id');
      unregister('notes_type');
      unregister('notes');
      unregister('created_by');
      unregister('updated_by');
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue('created_by', userData.id);
    setValue('updated_by', userData.id);
  }, []);

  const handleOpenPopup = () => {
    setIsDlPopupOpen(true);
  };

  return (
    <CustomPopup
      open={isOpen}
      onClose={() => {
        setIsPopupOpen(false);
        setSelectedStage('');
        setProjectNumber(null);
        reset();
      }}
      title={t('utils.addNoteFor')}
    >
      {isDlPopupOpen && (
        <AddDocuments
          title={`${isEdit ? `${t('actions.updateDocument')}` : `${t('actions.addDocument')}`} `}
          isPopupOpen={isDlPopupOpen}
          setIsPopupOpen={setIsDlPopupOpen}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          register={register}
          unregister={unregister}
          handleSave={uploadDocuments}
          setValue={setValue}
          data={null}
          className='file-uploader'
        ></AddDocuments>
      )}

      <Box className='mb-2'>
        <ProjectTypeSelection
          defaultValue={projectNumber}
          label={t('module.project')}
          onChange={(e, data) => {
            setProjectNumber(data?.name);
            setValue('project_id', data?.id);
          }}
          className={'autocomplete-inside w-100'}
        />
      </Box>
      <Box className='d-flex justify-content-end align-items-center'>
        {/* <AttachFileRounded className="fs-25 cursor-pointer mr-3" /> */}
        {!doc ? (
          <CustomButton
            variant='outlined'
            type={'secondary'}
            size={'small'}
            width={32}
            customWidth={32}
            onClick={handleOpenPopup}
            iconLeft={
              <AttachmentOutlinedIcon
                sx={{
                  width: 18,
                  height: 18,
                  transform: 'rotate(135deg)'
                }}
              />
            }
          />
        ) : (
          <Box className='notes-header'>
            <Box component='div' className='fs-14 m-2 color-primary-main'>
              {t('utils.fileName')}:{doc}
            </Box>
            <CloseIcon
              onClick={() => {
                setDoc(null);
              }}
              className='close-icon-style fs-20'
            />
          </Box>
        )}
        <MarkNoteAsSelection
          selectedOption={selectedStage}
          setSelectedOption={setSelectedStage}
          onChange={noteType => {
            setValue('notes_type', noteType);
          }}
        />
      </Box>

      <Box className='py-2 d-flex justify-content-between'>
        <TranscribeButton />
        <TranscribePopup />
        <div className='d-flex flex-column align-items-end'>
          <SummariseButton />
          <ViewTranscribedText />
        </div>
      </Box>
      <Box className='py-2'>
        <MessageTemplatesRT
          inputRef={bodyRef}
          value={transcribedText || ''}
          onChange={value => {
            setTranscribedText('body', value);
          }}
          height={300}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end'
        }}
      >
        <CustomButton
          buttonText={t('actions.save')}
          onClick={async () => {
            await addNote();
            setIsPopupOpen(false);
            setSelectedStage('');
            setProjectNumber(null);
            reset();
          }}
          iconRight={
            <Spinner
              animation='border'
              size='sm'
              className='ml-2'
              style={{
                display: loading ? 'inline-block' : 'none'
              }}
            />
          }
        />
      </Box>
    </CustomPopup>
  );
};

Notes.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsPopupOpen: PropTypes.func,
  contact_id: PropTypes.string
};

export default Notes;

const useNotesApis = ({ contact_id, isEdit, setValue, setIsDlPopupOpen, setDoc, getValues }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(false);

  const uploadDocuments = async document => {
    if (!document) {
      enqueueSnackbar(ATTACHMENT_VALIDATION.EMPTY, { variant: ERROR });
      return;
    }

    if (!document.file_type || !document.file_name) {
      enqueueSnackbar(ATTACHMENT_VALIDATION.TYPE_NAME, { variant: ERROR });
      return;
    }

    if (!isEdit) {
      if (!document.file) {
        enqueueSnackbar(ATTACHMENT_VALIDATION.NO_FILE, { variant: ERROR });
        return;
      } else {
        setDoc(document.file.name);
      }
      let fileNameSplit = document.file?.name?.split('.');
      let bodyFormData = new FormData();
      bodyFormData.append('is_confidential', document.is_confidential);
      bodyFormData.append('file_name', `${document.file_name}.${fileNameSplit[fileNameSplit.length - 1]}`);
      bodyFormData.append('file_type', document.file_type);
      bodyFormData.append('file', document.file);
      bodyFormData.append('ign_contact_attachment_visibilities', JSON.stringify(document.ign_contact_attachment_visibilities));
      setAttachment(bodyFormData);
      setValue('attachment', bodyFormData);
      setIsDlPopupOpen(false);
    } else {
      enqueueSnackbar('Attachment', { variant: ERROR });
    }
  };

  const addNote = async () => {
    setLoading(true);
    const notesData = getValues();
    const finalPayload = {
      contact_id: contact_id,
      project_id: notesData.project_id,
      notes_type: notesData.notes_type,
      notes: notesData.body,
      activity_added_at: new Date(),
      activity_type: NOTE,
      created_by: notesData.created_by,
      updated_by: notesData.updated_by
    };

    const { status, data } = await logAnActivityApi(POST, null, finalPayload, 'create-activity');

    if (status === 200) {
      const sub_route = `${IGN_API.add_document}/${contact_id}/attachments`;
      await AllDocumentsApi(POST, sub_route, notesData.attachment);
      const message = successMessage('', 'Note Created');

      enqueueSnackbar(message, { variant: SUCCESS });
      setLoading(false);
      return true;
    }
    if (status === 500) {
      enqueueSnackbar(`${data.message}`, { variant: ERROR });
      setLoading(false);
      return false;
    }
    setLoading(false);
  };
  return {
    loading,
    attachment,
    uploadDocuments,
    addNote
  };
};
