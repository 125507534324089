import React, { useEffect } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AgGridReact } from 'ag-grid-react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../selectors';
import { userRolesResourceApi } from '../../../../services/ApiService';
import { DELETE, POST, PUT } from '../../../../services/constantService';
import { NameRenderer, SwitchRenderer, columnDefs } from './utils';

const ManageRolesGrid = props => {
  const { rolesData, userRolesResourcesData, userRolesData, refetchUserRoleResource, currentUser } = props;

  const { toggleResource, togglePermissions } = ManageUserApis({
    refetchUserRoleResource,
    currentUser
  });

  // This will be intersction of rolesData and userRolesResourcesData,
  // we have to show only those roles which are assigned to user,
  // but all the resources of that role should be shown in grid
  // and switch should be on/off based on userRolesResourcesData
  const [gridRoles, setGridRoles] = React.useState([]);

  useEffect(() => {
    if (!rolesData || !userRolesResourcesData || !userRolesData || userRolesData?.length === 0) return;

    if (rolesData && userRolesResourcesData && userRolesData) {
      const gridData = rolesData.filter(role => {
        // return userRolesResourcesData.some(userRole => {
        //   return role.id === userRole.role_id;
        // });
        return userRolesData?.some(userRole => {
          return role.id === userRole.role_id;
        });
      });

      const gridDataWithUsrData = gridData.map(role => {
        const resources = role.resources.map(resource => {
          const resourceData = userRolesResourcesData.find(userRole => {
            return userRole.resource_id === resource.id;
          });

          if (!resourceData) return resource;

          return {
            ...resource,
            usrResourceData: resourceData
          };
        });

        return {
          ...role,
          resources: resources
        };
      });

      setGridRoles(gridDataWithUsrData);
    }
  }, [rolesData, userRolesResourcesData, userRolesData]);

  return (
    <div>
      {gridRoles.map(role => {
        return (
          <Accordion key={role.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className='header-font fs-18'>
              {role.name}
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div className='ag-theme-alpine' id='myGrid'>
                  <AgGridReact
                    disableStaticMarkup={true}
                    rowData={role.resources}
                    columnDefs={columnDefs}
                    domLayout='autoHeight'
                    components={{
                      switchRenderer: props => <SwitchRenderer {...props} userRolesData={userRolesResourcesData} togglePermissions={togglePermissions} />,
                      nameRenderer: props => <NameRenderer {...props} toggleResource={toggleResource} roleId={role.id} />
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

ManageRolesGrid.propTypes = {
  rolesData: PropTypes.array,
  userRolesResourcesData: PropTypes.array,
  roleId: PropTypes.string,
  refetchUserRoleResource: PropTypes.func,
  userRolesData: PropTypes.array,
  currentUser: PropTypes.object
};

export default ManageRolesGrid;

const ManageUserApis = props => {
  const { refetchUserRoleResource, currentUser } = props;
  const userData = useSelector(getUserSelector);

  // This will be used to toggle Checkbox and turn on/off individual resources as whole
  const toggleResource = async (resourceId, roleId, state) => {
    if (state) {
      const data = {
        userEmail: userData.email,
        roleId: roleId,
        resourceData: {
          resource_id: resourceId,
          can_create: true,
          can_read: true,
          can_update: true,
          can_delete: true
        }
      };

      await userRolesResourceApi(POST, 'addResource', data).then(() => {
        refetchUserRoleResource();
      });
    } else {
      await userRolesResourceApi(DELETE, `deleteUserByEmailRoleResource/${currentUser.email}/${roleId}/${resourceId}`).then(() => {
        refetchUserRoleResource();
      });
    }
  };

  const togglePermissions = async (resourceId, data) => {
    await userRolesResourceApi(PUT, `updateUserById/${resourceId}`, data);
  };

  return {
    toggleResource,
    togglePermissions
  };
};

ManageUserApis.propTypes = {
  roleId: PropTypes.string,
  refetchUserRoleResource: PropTypes.func,
  usrRoleRsrcId: PropTypes.string,
  currentUser: PropTypes.object
};
