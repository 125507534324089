import React, { useEffect, useState } from 'react';

import { Share } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../components/common/CustomButton';
import { TemplateGroupsApi } from '../../../../../services/ApiService';
import { ERROR, GET } from '../../../../../services/constantService';
import { ReportOption } from './ReportOption';
import ShareTemplatePopup from './ShareTemplatePopup';
import { templateOptions as TemplateOptions } from './utils';

const TemplateScreen = ({ show = null, openTemplateScreen, loading, setLoading, template, templateFormData, stagesFormData, setTemplateFormData, setStagesFormData }) => {
  if (show === null) return null;
  const { t } = useTranslation();
  const [stages, setStages] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [shareTemplatePopup, setShareTemplatePopup] = useState(false);

  const getDefaultStagesValues = (data = []) => {
    let stagesFormData = {};

    data.map(ele => {
      stagesFormData[ele?.id] = {
        parentId: null,
        enabled: true
      };
      ele?.report_stages?.map(nestEle => {
        stagesFormData[nestEle.id] = {
          parentId: ele?.id,
          enabled: true
        };
        if (nestEle?.report_stages?.length > 0) {
          stagesFormData = { ...stagesFormData, ...getDefaultStagesValues(nestEle?.report_stages) };
        }
      });
    });

    return stagesFormData;
  };

  function getDefaultValuesForSections(obj) {
    let templateFormData = {};
    Object.keys(obj).forEach(key => {
      templateFormData[key] = true;
      const copyObj = structuredClone(obj[key]);
      if (Object.keys(copyObj)?.length > 0) {
        templateFormData = { ...templateFormData, ...getDefaultValuesForSections(copyObj) };
      }
    });
    templateFormData['candidate_details_information_show_comments'] = false;
    return templateFormData;
  }

  const getDefaultTemplateValues = templateOptions => {
    let templateFormData = {};
    Object.keys(templateOptions).forEach(sectionKey => {
      const values = getDefaultValuesForSections(templateOptions[sectionKey]);
      templateFormData = { ...templateFormData, ...values };
    });
    return templateFormData;
  };

  async function fetchTemplateOptions() {
    setLoading(true);
    try {
      /** Fetch template and candidate stages */
      const [stagesRes, templateRes] = await Promise.all([
        TemplateGroupsApi(GET, '/external-groups'),
        // TODO: Replace with API to fetch template options
        new Promise(resolve => setTimeout(() => resolve(TemplateOptions), 1000))
      ]);

      let defaultStageValues, defaultTemplateValues;

      /** Set default template values [defaultStageValues] start*/
      const stagesData = stagesRes?.data;
      if (stagesRes?.status === 200) {
        if (show === 'new') {
          defaultStageValues = getDefaultStagesValues(stagesData);
          setStagesFormData(defaultStageValues);
        }
      } else {
        throw new Error(stagesRes?.message);
      }
      setStages(stagesData || []);
      /** Set default template values [defaultStageValues] end*/

      /** Set default template values [defaultTemplateValues] start*/
      const templateOptions = templateRes;
      if (show === 'new') {
        defaultTemplateValues = getDefaultTemplateValues(templateOptions);
        setTemplateFormData(defaultTemplateValues);
      }
      setTemplateOptions(templateOptions);

      /** Set default template values [defaultTemplateValues] end*/
    } catch (error) {
      enqueueSnackbar(`Failed to create new Template: ${error?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTemplateOptions();
  }, []);

  const DisplayCandidateStageOptions = ({ candidateStages = [], parentStageId, isNested = false }) => {
    const handleOnChange = stage => {
      const currOptionId = stage?.id;
      const nestedStageIds = stage?.report_stages;

      if (nestedStageIds?.length > 0) {
        const allIds = [...nestedStageIds, { id: currOptionId }];

        const updatedStagesFormData = structuredClone(stagesFormData);
        allIds.forEach(({ id }) => {
          updatedStagesFormData[id] = {
            parentId: id === currOptionId ? null : currOptionId,
            enabled: !updatedStagesFormData?.[currOptionId]?.enabled
          };
        });

        setStagesFormData(updatedStagesFormData);
      } else {
        const id = currOptionId;
        if (parentStageId === currOptionId) {
          setStagesFormData(prev => ({
            ...prev,
            [id]: {
              parentId: null,
              enabled: !prev?.[id]?.enabled
            }
          }));
          return;
        }

        const isAllInactive = Object.keys(stagesFormData || {})?.filter(key => stagesFormData[key]?.parentId === parentStageId && stagesFormData?.[key]?.enabled)?.length === 1;

        setStagesFormData(prev => {
          if (prev?.[id]?.enabled) {
            return {
              ...prev,
              [id]: {
                parentId: parentStageId,
                enabled: false
              },
              [parentStageId]: {
                parentId: null,
                enabled: !isAllInactive
              }
            };
          } else {
            return {
              ...prev,
              [id]: {
                parentId: parentStageId,
                enabled: true
              },
              [parentStageId]: {
                parentId: null,
                enabled: true
              }
            };
          }
        });
      }
    };

    return candidateStages?.map(stage => {
      let title, checked;
      if (isNested) {
        title = stage?.stage?.stage_label;
      } else {
        title = stage?.report_group_name;
      }

      checked = stagesFormData?.[stage?.id]?.enabled;

      const nestedStages = stage?.report_stages || [];

      return (
        <ReportOption key={stage?.id} onChange={() => handleOnChange(stage, parentStageId)} checked={checked} title={title?.toUpperCase()}>
          {nestedStages?.length > 0 && <DisplayCandidateStageOptions isNested parentStageId={stage?.id} candidateStages={nestedStages} />}
        </ReportOption>
      );
    });
  };

  function getNestedValue(obj, path) {
    return path?.split('.')?.reduce((acc, key) => acc && acc[key], obj);
  }

  const DisplayReportOptions = ({ optionKeys = [], isNested = false, sectionKey = null }) => {
    const handleOnChange = (sectionKey, id) => {
      let nestedIds,
        parentId = id;
      if (isNested) {
        const nestedValue = getNestedValue(templateOptions, sectionKey);
        nestedIds = Object.keys(nestedValue?.[id]);
      } else {
        const optionValue = templateOptions[sectionKey][id];
        nestedIds = Object.keys(optionValue);
      }

      if (nestedIds?.length > 0) {
        const allIds = [...nestedIds, id];

        const updatedTemplateFormData = structuredClone(templateFormData);

        allIds.forEach(id => {
          updatedTemplateFormData[id] = !updatedTemplateFormData?.[parentId];
        });

        setTemplateFormData(updatedTemplateFormData);
      } else {
        const parentStageId = Object.keys(templateFormData)?.find(key => parentId?.includes(key));
        if (parentStageId === parentId) {
          const updatedTemplateFormData = { ...templateFormData, [parentId]: !templateFormData?.[parentId] };
          if (parentId === 'candidate_details_information_show_linkedin') {
            updatedTemplateFormData['candidate_details_information_show_comments'] = !updatedTemplateFormData['candidate_details_information_show_linkedin'];
          }
          if (parentId === 'candidate_details_information_show_comments') {
            updatedTemplateFormData['candidate_details_information_show_linkedin'] = !updatedTemplateFormData['candidate_details_information_show_comments'];
          }
          setTemplateFormData(updatedTemplateFormData);
          return;
        }

        const isAllInactive = Object.keys(templateFormData)?.filter(key => key?.includes(parentStageId) && templateFormData[key])?.length === 2;
        setTemplateFormData(prev => {
          if (prev?.[parentId]) {
            return { ...prev, [parentId]: false, [parentStageId]: !isAllInactive };
          }
          if (parentId === 'candidate_details_information_show_linkedin' && prev?.['candidate_details_information_show_linkedin']) {
            prev['candidate_details_information_show_comments'] = true;
          } else {
            prev['candidate_details_information_show_comments'] = false;
          }

          if (parentId === 'candidate_details_information_show_comments' && prev?.['candidate_details_information_show_comments']) {
            prev['candidate_details_information_show_linkedin'] = true;
          } else {
            prev['candidate_details_information_show_linkedin'] = false;
          }

          return { ...prev, [parentId]: true, [parentStageId]: true };
        });
      }
    };

    return optionKeys?.map(optionKey => {
      let isActive, nestedTemplateOptions;
      if (isNested) {
        const nestedValue = getNestedValue(templateOptions, sectionKey);
        nestedTemplateOptions = structuredClone(nestedValue?.[optionKey]);
      } else {
        const optionValue = templateOptions[sectionKey][optionKey];
        nestedTemplateOptions = structuredClone(optionValue);
      }

      isActive = templateFormData?.[optionKey];

      return (
        <ReportOption key={optionKey} onChange={() => handleOnChange(sectionKey, optionKey)} checked={isActive} title={t(`reports.${optionKey}`)?.toUpperCase()}>
          {Object.keys(nestedTemplateOptions)?.length > 0 && <DisplayReportOptions isNested sectionKey={`${sectionKey}.${optionKey}`} optionKeys={Object.keys(nestedTemplateOptions)} />}
        </ReportOption>
      );
    });
  };

  DisplayReportOptions.propTypes = {
    isNested: PropTypes.bool,
    optionKeys: PropTypes.array,
    sectionKey: PropTypes.string
  };

  return (
    <>
      <ShareTemplatePopup template={template} show={shareTemplatePopup} setShareTemplatePopup={setShareTemplatePopup} />
      <Box display={'flex'} alignItems={'center'} paddingX={3} paddingY={openTemplateScreen === 'edit' ? 4 : 1} gap={8}>
        <CustomButton
          show={openTemplateScreen === 'edit'}
          onClick={() => setShareTemplatePopup(true)}
          iconRight={<Share fontSize='20px' sx={{ marginLeft: 1 }} />}
          type={'primary'}
          buttonText={t('reports.share')}
        />
      </Box>

      <Box display={'grid'} gridTemplateColumns={'1fr 1fr'}>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          {Object.keys(templateOptions).map(key => {
            const title = t(`reports.${key}`);
            return (
              <Box key={key} display={'flex'} flexDirection={'column'} paddingX={3} gap={2}>
                <Typography sx={{ fontFamily: 'sans-serif !important', fontSize: 14, fontWeight: 800 }}>{title}</Typography>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  <DisplayReportOptions sectionKey={key} optionKeys={Object.keys(templateOptions[key])} />
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box display={'flex'} flexDirection={'column'} paddingX={3} gap={2}>
          {!loading && <Typography sx={{ fontFamily: 'sans-serif !important', fontSize: 14, fontWeight: 800 }}>{t('reports.candidate_stages_to_be_shown')}</Typography>}
          <Box display={'flex'} flexDirection={'column'} gap={2} paddingBottom={4}>
            <DisplayCandidateStageOptions candidateStages={stages} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { TemplateScreen };

TemplateScreen.propTypes = {
  show: PropTypes.string,
  setStagesFormData: PropTypes.func,
  setTemplateFormData: PropTypes.func,
  stagesFormData: PropTypes.object,
  templateFormData: PropTypes.object,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  template: PropTypes.object,
  openTemplateScreen: PropTypes.string
};
