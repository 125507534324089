import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
export const DATE_FORMAT_WITHOUT_YEAR = 'DD MMM';
export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
export const DEFAULT_DATE_UTC_LOCAL_FORMAT = '';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const LEGACY_SKILLS_AND_ATTRIBUTE_PRIOR_DATE = new Date('2020-01-01');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return null;
  }
  return moment(date).tz('Asia/Kolkata').format(format);
};

export const formatDateUtcLocal = (date, format = DEFAULT_DATE_UTC_LOCAL_FORMAT) => {
  if (!date) {
    return null;
  }
  return moment(date).utc().local().format(format);
};

export const formatDateWithMomentTimeZone = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return null;
  }
  return momentTimeZone(date).format(format);
};
export const formatTime = (date, format = DEFAULT_TIME_FORMAT) => {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
};

export const getAge = date => {
  return date ? moment().diff(moment(date), 'days') : null;
};

export const getDifferenceInDays = (date1, date2) => {
  if (!date1 || !date2) {
    return null;
  }
  return moment(date1).diff(moment(date2), 'days');
};

export const validateStartYearEndYear = value => {
  if (value && Array.isArray(value)) {
    for (let x of value) {
      if (x.start_year && x.end_year) {
        return value.find(ele => ele?.start_year && ele?.end_year && ele?.end_year !== 'Present' && ele?.start_year > ele?.end_year) || {};
      } else if (x.start_date && x.end_date) {
        return value.find(ele => ele?.start_date && ele?.end_date && ele?.end_date !== 'Present' && ele?.start_date > ele?.end_date) || {};
      } else {
        return null;
      }
    }
  } else if (value && (value.start_year || value.start_date) && (value.end_year || value.end_date)) {
    if ((value.start_year || value.start_date) > (value.end_year || value.end_date) && (value.end_year !== 'Present' || value.end_date !== 'Present')) {
      return value;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getTheDaysDifference = data => {
  let days;
  if (!data.off_hold_date && data.on_hold_date) {
    days = getDifferenceInDays(new Date(), data.on_hold_date);
  }
  if (data.on_hold_date && data.off_hold_date) {
    days = getDifferenceInDays(data.off_hold_date, data.on_hold_date);
  }
  if (!days && days !== 0) {
    return '--';
  }
  return days;
};

export const getTheDaysDifferences = data => {
  let days;
  if (data.start_date && data.close_date && !data.on_hold_date) {
    days = getDifferenceInDays(data.close_date, data.start_date);
  }
  if (data.start_date && !data.close_date && !data.on_hold_date) {
    days = getDifferenceInDays(new Date(), data.start_date);
  }
  if (data.on_hold_date && !data.off_hold_date) {
    days = getDifferenceInDays(data.on_hold_date, data.start_date);
  }
  if (data.start_date && !data.close_date && data.on_hold_date && data.off_hold_date) {
    days = getDifferenceInDays(new Date(), data.start_date) - getTheDaysDifference(data);
  }
  if (data.start_date && data.close_date && data.on_hold_date && data.off_hold_date) {
    days = getDifferenceInDays(data.close_date, data.start_date) - getTheDaysDifference(data);
  }
  if (!days && days !== 0) {
    return '--';
  }
  return days;
};

export const checkForPriorLegacySkillsAndAttribute = date => {
  const convertedDate = new Date(date);
  return convertedDate >= LEGACY_SKILLS_AND_ATTRIBUTE_PRIOR_DATE;
};

export const validateDate = date => {
  if (!date) return true;
  const validDate = new Date(date);
  return !isNaN(validDate.getTime()) && validDate.getFullYear() < 2100 && validDate.getFullYear() > 1900;
};

// export const compareDateWithCurrentDate =(date) => {
//   if(date){
//     if (new Date(date) < new Date())
//       return date ;
//   }
//   return new Date();
// }

export const calculateNumberOfDays = date => {
  if (!date) return 0;
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const curDate = new Date();
  const newDate = new Date(date);
  return Math.round((curDate - newDate) / ONE_DAY);
};

export const convertToLocalTimeAndDate = (utcDate, skipTime = false, timezone) => {
  let dateWithTz = dayjs(utcDate);
  if (timezone) {
    dateWithTz = dayjs(utcDate).tz(timezone);
  }

  if (skipTime) {
    return dateWithTz.format('L'); // Localized date format
  }

  return dateWithTz.format('L LT'); // Localized date and time format
};

export const getDateFormatFromLocale = () => {
  const userLocale = navigator.language || 'default'; // Fallback to 'default'
  const parts = new Intl.DateTimeFormat(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }).formatToParts();

  return parts
    .map(part => {
      if (part.type === 'year') return 'YYYY';
      if (part.type === 'month') return 'MM';
      if (part.type === 'day') return 'DD';
      return part.value; // Include separators like '/' or '-'
    })
    .join('');
};
