import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import CustomPopup from '../../../components/common/CustomPopup';
import { ROUTES } from '../../../services/constantService';
import successPhoto from './../../../assets/images/project_create_success.svg';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '8px'
};
function SuccessModal(props) {
  const { projectId, isLoading, isMessage = false, isLive = true } = props;
  const navigate = useNavigate();
  //Translation
  const { t } = useTranslation();

  const handleViewButton = () => {
    let pathWithId = ROUTES.viewProjectWithDeepLink.replace(':id', projectId);
    pathWithId = pathWithId.replace(':parentTab', 'Roadmap');
    pathWithId = pathWithId.replace(':subTab', 'Position Profile');
    navigate(pathWithId);
  };
  return (
    <CustomPopup open={props.open} onClose={props.handleOpen}>
      <Box sx={{ ...modalStyle, width: '40vw' }} className='p-4'>
        <Stack direction='row' justifyContent={'flex-end'}>
          {/* {!isMessage && <CloseIcon onClick={props.handleOpen} />} */}
        </Stack>
        <Stack direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
          <img style={{ width: '6vw' }} src={successPhoto} />
          {isLive && (
            <Typography variant='subtitle1' textAlign={'center'} sx={{ fontWeight: 'bold' }}>
              {t('project.projectCreated')}
            </Typography>
          )}
          {!isMessage && isLoading && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              {t('utils.ignyteIntelligenceRoadmapBuilding')}
            </Typography>
          )}
          {!isMessage && !isLoading && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              {t('utils.roadmapReady')}
            </Typography>
          )}
          {isMessage && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              {t('utils.generatingPositionProfile')}
            </Typography>
          )}
          {isLoading && <CircularProgress color={'primary'} className='loader-class' />}

          {!isMessage && !isLoading && (
            <>
              <CustomButton type={'primary'} onClick={handleViewButton} buttonText={t('utils.reviewSearchRoadmap')} />
              <Link to={ROUTES.mySearches}>
                <Typography
                  variant='subtitle2'
                  textAlign={'center'}
                  sx={{
                    textDecoration: 'underline !important'
                  }}
                  color={'primary.main'}
                >
                  {t('activityTypeSetup.all-projects')}
                </Typography>
              </Link>
            </>
          )}
        </Stack>
      </Box>
    </CustomPopup>
  );
}

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  isLoading: PropTypes.bool,
  isMessage: PropTypes.bool,
  isLive: PropTypes.bool
};

export default SuccessModal;
