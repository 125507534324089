import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MinimizeIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function WindowControls({ isMinimized, isMaximized, onMinimize, onMaximize, onClose }) {
  const handleMaximizeOrRestore = () => {
    onMaximize();
  };
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {!isMinimized && (
        <IconButton
          aria-label='minimize'
          onClick={onMinimize}
          sx={{
            color: isMinimized ? '#4CAF50' : 'rgba(0, 0, 0, 0.54)',
            padding: '4px',
            '& .MuiSvgIcon-root': { fontSize: '20px' }
          }}
        >
          <MinimizeIcon />
        </IconButton>
      )}
      <IconButton
        aria-label={isMinimized ? 'restore' : 'maximize'}
        onClick={handleMaximizeOrRestore}
        sx={{
          color: isMaximized ? '#4CAF50' : 'rgba(0, 0, 0, 0.54)',
          padding: '4px',
          '& .MuiSvgIcon-root': { fontSize: '20px' }
        }}
      >
        <OpenInFullIcon />
      </IconButton>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          color: 'rgba(0, 0, 0, 0.54)',
          padding: '4px',
          '& .MuiSvgIcon-root': { fontSize: '20px' }
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}

WindowControls.propTypes = {
  isMinimized: PropTypes.bool.isRequired,
  isMaximized: PropTypes.bool.isRequired,
  onMinimize: PropTypes.func.isRequired,
  onMaximize: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default WindowControls;
