import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../../components/common/CustomButton';
import MultipleBrandSelection from '../../../../../../components/common/FunctionalComponents/BrandSelection/multipleBrandSelection';
import CustomDateRangePicker from '../../../../../../components/common/FunctionalComponents/CustomDateRangePicker';
import IndustrySelection from '../../../../../../components/common/FunctionalComponents/IndustrySelection';
import SearchStageSelection from '../../../../../../components/common/FunctionalComponents/ProjectStageSelection';
import MultipleRevenueMarketSelection from '../../../../../../components/common/FunctionalComponents/RevenueMarketSelection/MultipleMarketSelection';
import MultiSelectSearchType from '../../../../../../components/common/FunctionalComponents/SearchTypeSelection';
import CustomTagSelection from '../../../../../../components/common/FunctionalComponents/TagSelection/CustomTagSelection';
import MultipleGeoLocationInput from '../../../../../../components/common/GeoLocation/MultipleGeoLocation';
import CustomInputField from '../../../../../../components/common/StyledComponents/CustomInputField';
import { ignWorkbenchQueryApi } from '../../../../../../services/ApiService';
import { PUT } from '../../../../../../services/constantService';
import { getInputLabelPropsBasicDetails } from '../../../../../../utils/common';

export function ProjectFilterModal({ data, setFilterOptions, setQueryResponse }) {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState(data?.projectName || null);
  const [projectType, setProjectType] = useState([]);
  const [engagementType, setEngagementType] = useState(data?.engagementType || []);
  const [market, setMarket] = useState(data?.geography || []);
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState(data?.industry || []);
  const [range, setRange] = useState([]);
  const [lastActivityAt, setLastActivityAt] = useState({
    startDate: null,
    endDate: null
  });
  const [tags, setTags] = useState([]);

  const [stage, setStage] = useState(data?.stage || []);

  const handleProjectType = (e, val) => {
    setProjectType(val);
  };

  const handleTagChange = val => {
    setTags(val);
  };

  const handleEngagementType = (e, data) => {
    setEngagementType(data);
  };

  const handleMarket = (e, val) => {
    setMarket(val);
  };

  const handleIndustryChange = value => {
    setIndustries(value);
  };

  const handleLocationChange = (e, val) => {
    setLocations(val);
  };

  const handleDateChange = value => {
    setRange(value);
  };

  useEffect(() => {
    setLastActivityAt({
      startDate: range?.[0] ? dayjs.utc(range[0]) : null,
      endDate: range?.[1] ? dayjs.utc(range[1]) : null
    });
  }, [range]);

  const handleSaveAndSearch = async () => {
    const filterOptionPayload = {
      projectName: projectName,
      projectType: projectType?.map(item => item?.short_desc),
      engagementType: engagementType?.map(item => item?.field_value),
      geography: market?.map(item => item.id),
      industry: industries?.map(item => item.id),
      location: locations?.map(item => item?.description),
      lastActivity: lastActivityAt,
      stage: stage?.map(item => item?.name || item?.label),
      tags: tags?.map(item => item?.name)
    };
    setFilterOptions(filterOptionPayload);
    const updateResponse = await ignWorkbenchQueryApi(PUT, filterOptionPayload, `${data.id}`);
    setQueryResponse(updateResponse?.data);
  };

  return (
    <Accordion className='query-accordion-container'>
      <AccordionSummary className='query-accordion-summary' expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body2'>{t('utils.startQuery')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} className='mt-2'>
          <Grid item xs={3}>
            <CustomInputField label={'Project Name'} defaultValue={data?.projectName} color={null} onChange={event => setProjectName(event.target.value)} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectSearchType
              value={projectType}
              defaultValues={data?.projectType}
              onChange={handleProjectType}
              multiple={true}
              label={'Project Type'}
              className={'w-100'}
              size='small'
              InputLabelProps={{
                style: getInputLabelPropsBasicDetails()
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleBrandSelection
              onChange={handleEngagementType}
              value={engagementType}
              label={'Engagement Type'}
              className={'w-100'}
              size='small'
              isMultiSelect={true}
              InputLabelProps={{
                style: getInputLabelPropsBasicDetails()
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleRevenueMarketSelection
              value={market}
              onChange={handleMarket}
              isMultiSelect={true}
              label={'Geography'}
              className={'w-100'}
              size='small'
              InputLabelProps={{
                style: getInputLabelPropsBasicDetails()
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <IndustrySelection
              multiple={true}
              onChange={value => {
                handleIndustryChange(value);
              }}
              defaultValue={industries}
              disableCloseOnSelect
              isCheckBox={true}
              label={'Industry Selection'}
              className='w-100'
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleGeoLocationInput
              val={locations}
              defaultValues={data?.location}
              onChange={handleLocationChange}
              label={'Location'}
              className='w-100'
              size='small'
              InputLabelProps={{
                style: getInputLabelPropsBasicDetails()
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomDateRangePicker placeholder={t('Date Opened')} onChange={handleDateChange} range={range} setRange={setRange} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={3}>
            <SearchStageSelection
              isMultiSelect={true}
              value={stage}
              onChange={(e, selectedItems) => setStage(selectedItems)}
              label={'Stage'}
              className='w-100'
              size='small'
              InputLabelProps={{
                style: getInputLabelPropsBasicDetails()
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTagSelection label={'Tags'} className='w-100' size='small' optionType={'project'} onChange={handleTagChange} defaultValues={data?.tags} />
          </Grid>
        </Grid>
        <Grid container spacing={2} className='mt-2' justifyContent='flex-end'>
          <Grid item>
            <CustomButton variant='outlined' buttonText={t('actions.apply')} onClick={handleSaveAndSearch} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

ProjectFilterModal.propTypes = {
  data: PropTypes.object,
  setFilterOptions: PropTypes.func,
  setQueryResponse: PropTypes.func
};
