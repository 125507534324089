import * as React from 'react';
const MicSVGComponent = props => (
  <svg width={15} height={17} viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.5 1.61905C6.89158 1.61905 6.30808 1.87491 5.87786 2.33036C5.44765 2.78581 5.20595 3.40352 5.20595 4.04762V8.90476C5.20595 9.54886 5.44765 10.1666 5.87786 10.622C6.30808 11.0775 6.89158 11.3333 7.5 11.3333C8.10842 11.3333 8.69192 11.0775 9.12214 10.622C9.55235 10.1666 9.79405 9.54886 9.79405 8.90476V4.04762C9.79405 3.40352 9.55235 2.78581 9.12214 2.33036C8.69192 1.87491 8.10842 1.61905 7.5 1.61905ZM7.5 0C8.0021 0 8.49928 0.104695 8.96315 0.308107C9.42703 0.511519 9.84852 0.809664 10.2036 1.18552C10.5586 1.56138 10.8402 2.00758 11.0324 2.49866C11.2245 2.98974 11.3234 3.51608 11.3234 4.04762V8.90476C11.3234 9.97826 10.9206 11.0078 10.2036 11.7669C9.48653 12.5259 8.51403 12.9524 7.5 12.9524C6.48597 12.9524 5.51347 12.5259 4.79644 11.7669C4.07941 11.0078 3.67659 9.97826 3.67659 8.90476V4.04762C3.67659 2.97412 4.07941 1.9446 4.79644 1.18552C5.51347 0.426444 6.48597 0 7.5 0ZM0 10.493L1.50031 10.1749C1.77895 11.6421 2.52883 12.962 3.62253 13.9102C4.71624 14.8584 6.08632 15.3766 7.5 15.3766C8.91368 15.3766 10.2838 14.8584 11.3775 13.9102C12.4712 12.962 13.2211 11.6421 13.4997 10.1749L15 10.4922C14.3026 14.2031 11.2102 17 7.5 17C3.78976 17 0.69739 14.2039 0 10.493Z'
      fill='#23CEA7'
    />
  </svg>
);
export default MicSVGComponent;
