import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import Loader from './components/common/Loader';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import reduxStore from './store/index';
import i18n from './translations';
import { clarityTracker, shouldTrackOnClarity } from './utils/clarityJs';
// import configureAmplify, {withAuthenticator} from './amplify';
// const reduxStore = configureStore({});

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN_STRING });

if (shouldTrackOnClarity()) {
  clarityTracker.initiate();
}
// configureAmplify().then(() => {
//   ReactDOM.render(withAuthenticator(<ReduxProvider store={reduxStore}><App /></ReduxProvider>), document.getElementById('root'));
// })
const app = document.getElementById('root');

const root = createRoot(app);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
      // staleTime: 1000 * 60 * 5
    }
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <ReduxProvider store={reduxStore}>
        <StyledEngineProvider injectFirst>
          <Suspense fallback={<Loader show={true} />}>
            <App />
          </Suspense>
        </StyledEngineProvider>
      </ReduxProvider>
    </I18nextProvider>
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
