import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Checkbox, IconButton, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModalWithHeader from '../../../../../Containers/Commons/CustomModalWithHeader';

function FilterModal({ open, setOpen, filterHandler, defaultFilters }) {
  const [filters, setFilters] = useState(defaultFilters);
  const [searchQuery, setSearchQuery] = useState(defaultFilters.searchQuery);
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  const handleCheckboxChange = (category, key) => {
    setFilters(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: {
          ...prev[category][key],
          value: !prev[category][key].value
        }
      }
    }));
  };

  const handleApply = () => {
    filterHandler({ ...filters, searchQuery });
    handleClose();
  };

  const handleSearchChange = event => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const renderCheckboxes = (category, data) => {
    const filteredItems = Object.entries(data).filter(([key]) => key !== 'label');

    if (filteredItems.length === 0) {
      return null;
    }

    return (
      <Box
        key={category}
        sx={{
          px: '12px',
          py: '6px',
          borderBottom: '1px solid rgba(55, 65, 81, 0.1)'
        }}
      >
        <Typography
          sx={{
            color: 'rgba(55, 65, 81, 0.5)',
            fontSize: 10,
            marginBottom: '10px'
          }}
        >
          {t(data.label)}
        </Typography>
        {filteredItems.map(([key, item]) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginBottom: '6px'
            }}
          >
            <Checkbox
              checked={item.value}
              onChange={() => handleCheckboxChange(category, key)}
              sx={{
                width: '14px',
                height: '14px',
                borderRadius: '3px'
              }}
            />
            <Typography sx={{ fontSize: '13px !important' }}>{t(item.label)}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <CustomModalWithHeader
      closeIcon
      label={t('project.candidateScore.filterText')}
      onClose={handleClose}
      isOpen={open}
      style={{ maxHeight: '100vh', minWidth: '40vw', padding: 0 }}
      subBoxStyle={{
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRadius: '4px 4px 0 0'
      }}
      labelStyle={{
        color: '#003c5b',
        fontSize: '16px',
        fontWeight: '600'
      }}
      buttonStyle={{
        color: '#003c5b',
        cursor: 'pointer'
      }}
    >
      <form onSubmit={handleApply}>
        <TextField
          fullWidth
          placeholder={t('common.search')}
          value={searchQuery}
          onChange={handleSearchChange}
          rows={1}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, fontSize: '18px' }} />,
            endAdornment: searchQuery && (
              <IconButton onClick={() => setSearchQuery('')} size='small'>
                <CloseIcon sx={{ fontSize: '18px' }} />
              </IconButton>
            )
          }}
          sx={{
            borderRadius: '3px',
            padding: 2,
            borderBottom: '1px solid rgba(55, 65, 81, 0.1)'
          }}
        />
        {Object.entries(filters).map(([category, data]) => renderCheckboxes(category, data))}
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2
          }}
        >
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{
              borderColor: '#374151',
              color: '#374151'
            }}
          >
            {t('actions.cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={handleApply}
            sx={{
              backgroundColor: '#003c5b'
            }}
          >
            {t('actions.apply')}
          </Button>
        </Box>
      </form>
    </CustomModalWithHeader>
  );
}

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  filterHandler: PropTypes.func.isRequired,
  defaultFilters: PropTypes.object
};

export default FilterModal;
