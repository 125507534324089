import { Box, List, ListItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddContactDrawerAccordion from '../../../AddContactDrawer/component/AddContactDrawerAccordion';
import PersonalityCard from './PersonalityCard';

const ContactList = ({
  projectOptions = [],
  filterContactList = [],
  groupBy,
  filterOptions = {},
  props = {},
  filterByProject,
  setSelectedItem,
  setIsConfirmPopupOpen,
  onPublishHandler,
  PersonalityTabCreateOptions,
  isPublished,
  isFilterApplied,
  currentValues,
  onEditHandler
}) => {
  const { t } = useTranslation();

  const [expandedProjectSections, setExpandedProjectSections] = useState({});
  const [expandedSections, setExpandedSections] = useState({});

  const toggleProjectExpand = index => {
    setExpandedProjectSections(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  const toggleExpand = index => {
    setExpandedSections(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const sections = [
    { title: t('utils.professionalNotes'), filterType: 'professional_notes' },
    { title: t('utils.personalNotes'), filterType: 'personal_notes' },
    { title: t('utils.strength'), filterType: 'strength' },
    { title: t('utils.concern'), filterType: 'concerns' },
    { title: t('actions.screeningNotes'), filterType: 'screening_notes' },
    { title: t('utils.recommendation'), filterType: 'recommendations' }
  ];

  const sectionsFilters = isFilterApplied ? sections.filter(section => filterOptions[section?.filterType]?.value) : sections;

  useEffect(() => {
    if (projectOptions && projectOptions.length > 0) {
      setExpandedProjectSections(
        projectOptions.reduce((acc, _, index) => {
          acc[index] = true; // Set all sections to true (expanded)
          return acc;
        }, {})
      );
    }
  }, [projectOptions]);

  useEffect(() => {
    if (sectionsFilters && sectionsFilters.length > 0) {
      setExpandedSections(
        sectionsFilters.reduce((acc, _, index) => {
          acc[index] = true; // Set all sections to true (expanded)
          return acc;
        }, {})
      );
    }
  }, [sectionsFilters]);

  const hasActiveFilters = Object.values(filterOptions).some(option => option.value);

  const filterNotesType = currentValues?.contact_notes_all?.filter(
    item => item.notes_type && ['professional_notes', 'personal_notes', 'strength', 'concerns', 'screening_notes', 'recommendations'].includes(item.notes_type)
  );

  const filterContactListValues =
    isFilterApplied && isPublished && hasActiveFilters
      ? filterNotesType.filter(item => filterOptions[item?.notes_type]?.value && item.is_partner_approved)
      : isFilterApplied && hasActiveFilters && !isPublished
        ? filterNotesType.filter(item => filterOptions[item?.notes_type]?.value)
        : isPublished
          ? filterNotesType.filter(item => item.is_partner_approved)
          : filterNotesType;

  return (
    <Box className='py-3'>
      {projectOptions.length > 0 || filterContactList.length > 0 ? (
        <List disablePadding sx={{ width: '100%', minHeight: '100%', margin: 0, padding: 0 }}>
          <ListItem
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '16px',
              padding: 0,
              margin: 0
            }}
          >
            {groupBy?.value === 'notes_type'
              ? sectionsFilters.map((section, index) => (
                  <AddContactDrawerAccordion
                    key={index}
                    title={section.title}
                    visible={true}
                    expand={!!expandedSections[index]}
                    setExpand={() => toggleExpand(index)}
                    globalLoading={false}
                    isPersonalityTab={true}
                  >
                    <PersonalityCard
                      {...props}
                      filterOptions={filterOptions}
                      onPublishHandler={onPublishHandler}
                      groupBy={groupBy?.value}
                      filterContactList={filterContactList}
                      setSelectedItem={setSelectedItem}
                      setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                      PersonalityTabCreateOptions={PersonalityTabCreateOptions}
                      isPublished={isPublished}
                      filterByProject={filterByProject}
                      isFilterApplied={isFilterApplied}
                      currentValues={filterContactListValues}
                      filterType={section.filterType}
                      onEditHandler={onEditHandler}
                    />
                  </AddContactDrawerAccordion>
                ))
              : projectOptions.map((section, index) => (
                  <AddContactDrawerAccordion
                    key={index}
                    title={section.projectName}
                    expand={!!expandedProjectSections[index]}
                    setExpand={() => toggleProjectExpand(index)}
                    visible={true}
                    globalLoading={false}
                    isPersonalityTab={true}
                  >
                    <PersonalityCard
                      {...props}
                      filterType={section.project_id}
                      setSelectedItem={setSelectedItem}
                      setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                      groupBy={groupBy?.value}
                      onPublishHandler={onPublishHandler}
                      filterOptions={filterOptions}
                      PersonalityTabCreateOptions={PersonalityTabCreateOptions}
                      isPublished={isPublished}
                      isFilterApplied={isFilterApplied}
                      currentValues={filterContactListValues}
                      onEditHandler={onEditHandler}
                    />
                  </AddContactDrawerAccordion>
                ))}
          </ListItem>
        </List>
      ) : (
        <Box className='d-flex justify-content-center align-items-center text-center fs-12'>{t('utils.noData')}</Box>
      )}
    </Box>
  );
};

ContactList.propTypes = {
  projectOptions: PropTypes.array,
  filterContactList: PropTypes.array,
  groupBy: PropTypes.object,
  filterOptions: PropTypes.object,
  props: PropTypes.object,
  filterByProject: PropTypes.func,
  setSelectedItem: PropTypes.func,
  setIsConfirmPopupOpen: PropTypes.func,
  onPublishHandler: PropTypes.func,
  PersonalityTabCreateOptions: PropTypes.object,
  isPublished: PropTypes.bool,
  isFilterApplied: PropTypes.bool,
  currentValues: PropTypes.object,
  onEditHandler: PropTypes.func
};

export default ContactList;
