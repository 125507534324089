import { alpha, createTheme } from '@mui/material/styles';
import variables from './variables.scss';

export const defaultTheme = (themeConfig, userConfiguration) =>
  createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true
        }
      }
    },
    props: {
      // need to fix this
      MuiDateFormat: {
        short: userConfiguration?.dateFormat || 'dd/MM/yyyy',
        medium: userConfiguration?.dateFormat + ' ' + userConfiguration?.timeFormats || 'dd/MM/yyyy',
        long: userConfiguration?.dateFormat + ' ' + userConfiguration?.timeFormats || 'dd/MM/yyyy'
      },
      MuiTimeFormat: {
        short: userConfiguration?.timeFormats || '12-hours',
        medium: userConfiguration?.timeFormats || '12-hours',
        long: userConfiguration?.timeFormats || '12-hours'
      },
      MuiTimeZoneFormat: {
        short: userConfiguration?.timeZoneFormat || 'UTC',
        medium: userConfiguration?.timeZoneFormat || 'UTC',
        long: userConfiguration?.timeZoneFormat || 'UTC'
      }
    },
    palette: {
      primary: {
        main: themeConfig?.primary || variables?.primary,
        light025: alpha(themeConfig?.primary || variables?.primary, 0.025),
        light05: alpha(themeConfig?.primary || variables?.primary, 0.05),
        light1: alpha(themeConfig?.primary || variables?.primary, 0.1),
        light2: alpha(themeConfig?.primary || variables?.primary, 0.2),
        light3: alpha(themeConfig?.primary || variables?.primary, 0.3),
        light4: alpha(themeConfig?.primary || variables?.primary, 0.4),
        light5: alpha(themeConfig?.primary || variables?.primary, 0.5),
        light6: alpha(themeConfig?.primary || variables?.primary, 0.6),
        light7: alpha(themeConfig?.primary || variables?.primary, 0.7),
        light8: alpha(themeConfig?.primary || variables?.primary, 0.8),
        light9: alpha(themeConfig?.primary || variables?.primary, 0.9)
      },
      secondary: {
        light025: alpha(themeConfig?.secondary || variables?.secondary, 0.025),
        light1: alpha(themeConfig?.secondary || variables?.secondary, 0.1),
        main: themeConfig?.secondary || variables?.secondary
      },
      text: {
        primary: themeConfig?.text || variables?.text,
        white: variables?.white,
        gray: variables?.text,
        white1: alpha(variables?.white, 0.1),
        white2: alpha(variables?.white, 0.2),
        white3: alpha(variables?.white, 0.3),
        white4: alpha(variables?.white, 0.4),
        white5: alpha(variables?.white, 0.5),
        white6: alpha(variables?.white, 0.6),
        white7: alpha(variables?.white, 0.7),
        white8: alpha(variables?.white, 0.8),
        white9: alpha(variables?.white, 0.9)
      },
      heading: {
        main: themeConfig?.heading || variables?.heading
      },
      error: {
        main: themeConfig?.error || variables?.error
      },
      background: {
        main: themeConfig?.background || variables?.background,
        white: variables?.white
      },
      gray: {
        main: themeConfig?.gray || variables?.gray
      },
      titleBarBackground: {
        main: themeConfig?.titleBarBackground || variables?.titleBarBackground
      },
      success: {
        main: themeConfig?.success || variables?.success
      },
      colors: {
        coolGray: themeConfig?.coolGray || variables?.coolGray
      }
    },
    typography: {
      fontFamily: '"open-sans" !important',
      button: {
        textTransform: 'none'
      }
    },
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: themeConfig?.secondary || variables?.secondary
          }
        }
      }
    }
  });
